import React, { useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { nbNO } from '@mui/material/locale';
import { PrivateRoute } from './PrivateRoute';
import { Home } from '../pages/home/Home';
import { NotFound } from '../pages/NotFound';
import {
  RegistrationContext,
  RoleContext,
  UserContext,
  useUserLocalStorage
} from '../../context/AuthContext';
import { Logout } from '../authentication/Logout';
import { Public } from '../pages/PublicPage';
import { DatabaseContext, useLocalStorage } from '../../context/DatabaseContext';
import { store } from '../../store/configureStore';
import { Provider } from 'react-redux';
import { Login } from '../authentication/Login';
import { useKeycloak } from '@react-keycloak/web';
import { HealthCheck } from '../pages/HealthCheck';
import { Fallback } from '../pages/Fallback';
import { CodeContext, useCodeStorage } from '../../context/CodeContext';
import { ProjectContext, useProjectStorage } from '../../context/ProjectContext';
import { customTheme } from '../../css/customTheme';
export const AppRouter = () => {
  const [hasRole, setHasRole] = useState(false);
  const [user, setUser] = useUserLocalStorage('user');
  const [currentDatabase, setCurrentDatabase] = useLocalStorage('databaseValue');
  const [projects, setProjects] = useProjectStorage('projects');
  const [codes, setCodes] = useCodeStorage('codes');
  const [registered, setRegistered] = useState(false);
  const { initialized } = useKeycloak();
  if (!initialized) {
    return <div></div>;
  }
  const errorHandler = (error: any, info: any) => {
    console.log('Error: ', error, info);
  };
  const theme = createTheme(customTheme, nbNO);
  return (
    <ThemeProvider theme={theme}>
      <ErrorBoundary FallbackComponent={Fallback} onError={errorHandler}>
        <Provider store={store}>
          <RoleContext.Provider value={{ hasRole, setHasRole }}>
            <UserContext.Provider value={{ user, setUser }}>
              <DatabaseContext.Provider value={{ currentDatabase, setCurrentDatabase }}>
                <RegistrationContext.Provider value={{ registered, setRegistered }}>
                  <ProjectContext.Provider value={{ projects, setProjects }}>
                    <CodeContext.Provider value={{ codes, setCodes }}>
                      <Router>
                        <Switch>
                          <Route exact path="/" component={Login} />
                          <Route exact path="/login" component={Login} />
                          <Route exact path="/logout" component={Logout} />
                          <PrivateRoute exact path="/public" component={Public} />
                          <PrivateRoute path="/home" component={Home} />
                          <Route path="/health" component={HealthCheck} />
                          <Route path="/" component={NotFound} />
                        </Switch>
                      </Router>
                    </CodeContext.Provider>
                  </ProjectContext.Provider>
                </RegistrationContext.Provider>
              </DatabaseContext.Provider>
            </UserContext.Provider>
          </RoleContext.Provider>
        </Provider>
      </ErrorBoundary>
    </ThemeProvider>
  );
};
