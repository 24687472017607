import React, { useEffect, useState } from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Divider,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Typography
} from '@mui/material';
import useFetchData from '../../../utilities/useFetchData';
import { SingleStoff, StoffI } from '../types/StoffTypes';
import { Dropdown, SlowText } from '../../components/Inputs';
import { useUserContext } from '../../../../context/AuthContext';
import { UserRole } from '../../admin/AdminTypes';

interface NutrientDetailsProps {
  changePath: (e: any) => void;
  setStoffData: (value: StoffI) => void;
  stoffData: StoffI;
  nutrientGroupIdAndName: any[];
  classes: any;
  toggleStoffnummer: (value?: string, searchTypeNumbers?: boolean) => void;
  currentDatabase: any;
  dataIsValid?: boolean;
  setDataIsValid: React.Dispatch<React.SetStateAction<boolean>>;
  stoffIdFromURL: number | undefined;
  owned?: boolean;
}

const StoffDetails = (props: NutrientDetailsProps) => {
  const {
    stoffData,
    setStoffData,
    nutrientGroupIdAndName,
    changePath,
    toggleStoffnummer,
    classes,
    setDataIsValid,
    owned
  } = props;
  const loggedInuser = useUserContext();

  const [ingaarIEnergi, setIngaarIEnergi] = React.useState(false);
  const doseStoffNumberExist = useFetchData({
    loadOnMount: false,
    clearDataOnLoad: false,
    projectURL: 'countStoffs',
    subProjectURL: `${props.currentDatabase?.value}/${stoffData.stoffnrId}`
  });
  const doseSorteingNumberExist = useFetchData({
    loadOnMount: false,
    clearDataOnLoad: false,
    projectURL: 'countSortering',
    subProjectURL: `${props.currentDatabase?.value}/${stoffData.sortering}`
  });
  const doseStoffNameExist = useFetchData({
    loadOnMount: false,
    clearDataOnLoad: false,
    projectURL: 'countStoffNavn',
    subProjectURL: `${props.currentDatabase?.value}/${stoffData.navn}`
  });
  const doseStoffEnglishNameExist = useFetchData({
    loadOnMount: false,
    clearDataOnLoad: false,
    projectURL: 'countStoffEnglishNavn',
    subProjectURL: `${props.currentDatabase?.value}/${stoffData.engelskNavn}`
  });
  const [stoffIsTouched, setStoffIsTouch] = useState(false);
  const [sorteringIsTouched, setSorteringIsTouch] = useState(false);
  const [navnIsTouched, setNavnIsTouch] = useState(false);
  const [englishNavnIsTouched, setEnglishNavnIsTouch] = useState(false);

  const [stoffsError, setStoffsError] = useState({
    error: false,
    message: ''
  });
  const [stoffIdError, setStoffIdError] = useState({
    error: false,
    message: ''
  });
  const [sorteringError, setSorteringError] = useState({
    error: false,
    message: ''
  });
  const [navnError, setNavnError] = useState({
    error: false,
    message: ''
  });
  const [englishNavnError, setEnglishNavnError] = useState({
    error: false,
    message: ''
  });
  const [stoffDesimallerError, setStoffDesimallerError] = useState({
    error: false,
    message: ''
  });
  const [energifaktorError, setEnergifaktorError] = useState({
    error: false,
    message: ''
  });
  const clearAllErrors = () => {
    setStoffsError({
      error: false,
      message: ''
    });
    setStoffIdError({
      error: false,
      message: ''
    });
    setSorteringError({
      error: false,
      message: ''
    });
    setNavnError({
      error: false,
      message: ''
    });
    setEnglishNavnError({
      error: false,
      message: ''
    });
    setStoffDesimallerError({
      error: false,
      message: ''
    });
    setEnergifaktorError({
      error: false,
      message: ''
    });
  };
  const handleValueChangeEvent = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    name: keyof StoffI
  ) => {
    const { value } = e.target;
    if (name === 'stoffnrId') {
      if (isNaN(+value)) {
        setStoffIdError({
          error: true,
          message: 'Ugyldige tegn. Bruk bare tall'
        });
      } else {
        setStoffData({
          ...stoffData,
          [name]: +value
        });
        setStoffIdError({
          error: false,
          message: ''
        });
      }
      setStoffIsTouch(true);
      changePath(+value);
    } else if (name === 'desimaler') {
      if (+value > 5) {
        setStoffDesimallerError({
          error: true,
          message: 'Desimaler kan ikke være større enn 5!'
        });
      } else if (+value < 0) {
        setStoffDesimallerError({
          error: true,
          message: 'Desimaler kan ikke være mindre enn 0!'
        });
      } else {
        setStoffDesimallerError({
          error: false,
          message: ''
        });
      }
      setStoffData({
        ...stoffData,
        [name]: +value
      });
    } else if (name === 'sortering') {
      if (isNaN(+value)) {
        setSorteringError({
          error: true,
          message: 'Ugyldige tegn. Bruk bare tall'
        });
      } else {
        setSorteringError({
          error: false,
          message: ''
        });
        setStoffData({
          ...stoffData,
          [name]: +value
        });
      }
      setSorteringIsTouch(true);
    } else if (name === 'navn') {
      setStoffData({
        ...stoffData,
        [name]: value
      });
      setNavnIsTouch(true);
    } else if (name === 'engelskNavn') {
      setStoffData({
        ...stoffData,
        [name]: value
      });
      setEnglishNavnIsTouch(true);
    } else {
      setStoffData({
        ...stoffData,
        [name]: value
      });
    }
  };
  useEffect(() => {
    clearAllErrors();
  }, [stoffData.stoffnrId]);
  const handleChangeSelect = (name: string, value: string) => {
    setStoffData({
      ...stoffData,
      [name]: value as string
    });
  };

  useEffect(() => {
    if (stoffData.ingaari === null) {
      setIngaarIEnergi(false);
    } else {
      let isChecked = stoffData.ingaari ? true : false;
      setIngaarIEnergi(isChecked);
    }
  }, [stoffData?.ingaari]);

  useEffect(() => {
    if (ingaarIEnergi) {
      setEnergifaktorError({
        error: true,
        message: 'Energifaktor må være angitt hvis stoffet skal inngå i energiberegning'
      });
    } else {
      setEnergifaktorError({
        error: false,
        message: ''
      });
    }
    if (stoffData.energifaktor) {
      setEnergifaktorError({
        error: false,
        message: ''
      });
    }
  }, [ingaarIEnergi, stoffData.energifaktor]);

  /*Here we use a timer to wait for the user complete filling the fields  */

  useEffect(() => {
    const timer = setTimeout(() => {
      if (stoffIsTouched)
        if (stoffData.stoffnrId) {
          if (+stoffData.stoffnrId === +props.stoffIdFromURL) {
            //do nothing
          } else {
            doseStoffNumberExist.loadData(
              `countStoffs`,
              `${props.currentDatabase?.value}/${stoffData.stoffnrId}`
            );
          }
        }
    }, 500);
    return () => clearTimeout(timer);
  }, [stoffData?.stoffnrId]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (sorteringIsTouched)
        if (stoffData.sortering)
          doseSorteingNumberExist.loadData(
            `countSortering`,
            `${props.currentDatabase?.value}/${stoffData.sortering}`
          );
    }, 1000);
    return () => clearTimeout(timer);
  }, [stoffData?.sortering]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (navnIsTouched)
        if (stoffData.navn)
          doseStoffNameExist.loadData(
            `countStoffNavn`,
            `${props.currentDatabase?.value}/${stoffData.navn}`
          );
    }, 1000);
    return () => clearTimeout(timer);
  }, [stoffData?.navn]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (englishNavnIsTouched)
        if (stoffData.engelskNavn)
          doseStoffEnglishNameExist.loadData(
            `countStoffEnglishNavn`,
            `${props.currentDatabase?.value}/${stoffData.engelskNavn}`
          );
    }, 1000);
    return () => clearTimeout(timer);
  }, [stoffData?.engelskNavn]);

  /* Here we set the error message and error states */
  useEffect(() => {
    if (doseStoffNumberExist.data) {
      if (doseStoffNumberExist.data[0]?.COUNT > 0) {
        if (+stoffData.stoffnrId === props.stoffIdFromURL) {
          setStoffsError({
            error: false,
            message: ''
          });
        } else {
          setStoffsError({
            error: true,
            message: 'Stoffnummeret eksisterer fra før.'
          });
        }
      } else {
        setStoffsError({
          error: false,
          message: ''
        });
      }
    }
  }, [doseStoffNumberExist.data]);
  useEffect(() => {
    if (doseSorteingNumberExist.data) {
      if (
        doseSorteingNumberExist.data[0]?.COUNT > 0 &&
        +stoffData.stoffnrId !== props.stoffIdFromURL
      ) {
        setSorteringError({
          error: true,
          message: 'Sorteringsnummeret eksisterer fra før. Bruke et unikt nummer. '
        });
      } else {
        setSorteringError({
          error: false,
          message: ''
        });
      }
    }
  }, [doseSorteingNumberExist.data]);
  useEffect(() => {
    if (doseStoffNameExist.data) {
      if (
        doseStoffNameExist.data[0]?.COUNT > 0 &&
        +stoffData.stoffnrId !== props.stoffIdFromURL
      ) {
        setNavnError({
          error: true,
          message: 'Navnet eksisterer fra før. Bruke et unikt navn.'
        });
      } else {
        setNavnError({
          error: false,
          message: ''
        });
      }
    }
  }, [doseStoffNameExist.data]);
  useEffect(() => {
    if (doseStoffEnglishNameExist.data) {
      if (doseStoffEnglishNameExist.data[0]?.COUNT > 0) {
        setEnglishNavnError({
          error: true,
          message: 'Engelske navnet eksisterer fra før. Bruke et unikt engelsk navn.'
        });
      } else {
        setEnglishNavnError({
          error: false,
          message: ''
        });
      }
    }
  }, [doseStoffEnglishNameExist.data]);
  useEffect(() => {
    if (
      stoffsError.error ||
      sorteringError.error ||
      stoffDesimallerError.error ||
      energifaktorError.error ||
      navnError.error ||
      englishNavnError.error
    ) {
      setDataIsValid(false);
    } else {
      setDataIsValid(true);
    }
  }, [
    stoffsError.error,
    stoffDesimallerError.error,
    energifaktorError.error,
    sorteringError.error,
    navnError.error,
    englishNavnError.error,
    stoffData.stoffnrId
  ]);
  return (
    <Grid>
      <Grid container item xs={12}>
        <Box style={{ fontSize: 'small' }} sx={classes.obligatoriskField}>
          * Obligatorisk felt
        </Box>
      </Grid>
      <Grid container item xs={12} spacing={1} justifyContent={'flex-end'}>
        <Grid item xs={2}>
          <SlowText
            header="Stoffnummer*"
            id="Stoffnummer"
            type="text"
            value={stoffData?.stoffnrId || ''}
            onChange={(e) => handleValueChangeEvent(e, 'stoffnrId')}
            name="stoffnrId"
            //onKeyDown={(e) => { changePath(e) }}
            required
            inputProps={{
              min: 1,
              maxLength: 6,
              inputMode: 'numeric',
              pattern: '[0-9]*'
            }}
            error={stoffIdError.error}
            helperText={stoffIdError.message}
          />
          {/*  <ChatBubbleButtonSingle
              name={'Stoffnummer'}
              handleOpenPopup={() => toggleStoffnummer(undefined, true)}
            /> */}
        </Grid>
        <Grid item xs={4}>
          <SlowText
            header={'Navn*'}
            id={'StoffNavn'}
            name={'navn'}
            value={stoffData?.navn || ''}
            type={'text'}
            onChange={(e) => handleValueChangeEvent(e, 'navn')}
            required
            InputProps={{
              readOnly: !owned || false
            }}
            inputProps={{
              maxLength: 40
            }}
            error={stoffsError.error}
            helperText={stoffsError.message}
          />
          {/*    <ChatBubbleButtonSingle
              name={'Navn'}
              handleOpenPopup={() => toggleStoffnummer(undefined, false)}
            /> */}
        </Grid>
        <Grid item xs={2}>
          <SlowText
            header="Kortnavn*"
            id={'StoffKortnavn'}
            name={'kortnavn'}
            value={stoffData?.kortnavn || ''}
            type={'text'}
            onChange={(e) => handleValueChangeEvent(e, 'kortnavn')}
            required
            InputProps={{
              readOnly: !owned || false
            }}
            inputProps={{
              maxLength: 8
            }}
          />
          {/* <ChatBubbleButtonSingle
              name={'Kortnavn'}
              handleOpenPopup={() => toggleStoffnummer(undefined, false)}
            /> */}
        </Grid>
        <Grid item xs={4}>
          <SlowText
            header={'Engelsk navn*'}
            id={'StoffEngelskNavn'}
            name={'engelskNavn'}
            value={stoffData?.engelskNavn || ''}
            type={'text'}
            onChange={(e) => handleValueChangeEvent(e, 'engelskNavn')}
            required
            InputProps={{
              readOnly: !owned || false
            }}
            error={englishNavnError.error}
            helperText={englishNavnError.message}
          />
        </Grid>
      </Grid>
      <Divider style={{ margin: 15 }} />
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={4}>
          <SlowText
            header="Desimaler*"
            id={'StoffDesimaler'}
            name={'desimaler'}
            value={stoffData?.desimaler}
            type={'number'}
            required
            onChange={(e) => handleValueChangeEvent(e, 'desimaler')}
            InputProps={{
              readOnly: !owned || false
            }}
            inputProps={{
              inputMode: 'numeric',
              pattern: '[0-9]*'
            }}
            error={stoffDesimallerError.error}
            helperText={stoffDesimallerError.message}
          />
        </Grid>
        <Grid item xs={4}>
          <SlowText
            header="Enhet*"
            id={'StoffEnhet'}
            name={'enhet'}
            required
            value={stoffData?.enhet || ''}
            type={'text'}
            onChange={(e) => handleValueChangeEvent(e, 'enhet')}
            InputProps={{
              readOnly: !owned || false
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <SlowText
            classes={classes}
            header="Sorteringsnummer*"
            id={'Sorteringsnr'}
            name={'sortering'}
            value={stoffData?.sortering || ''}
            type={'text'}
            required
            onChange={(e) => handleValueChangeEvent(e, 'sortering')}
            InputProps={{
              readOnly: !owned || false
            }}
            error={sorteringError.error}
            helperText={sorteringError.message}
          />
        </Grid>
      </Grid>
      <br />
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={4}>
          <SlowText
            classes={classes}
            header="Energifaktor"
            id={'StoffEnergifaktor'}
            name={'energifaktor'}
            value={stoffData?.energifaktor || ''}
            type={'number'}
            onChange={(e) => handleValueChangeEvent(e, 'energifaktor')}
            required={ingaarIEnergi ? true : false}
            InputProps={{
              readOnly: !owned || false
            }}
            error={energifaktorError.error}
            helperText={energifaktorError.message}
          />
        </Grid>
        <Grid item xs={4}>
          <div>
            <Dropdown
              header="Regnbart"
              defaultValue="N"
              value={stoffData?.regnbart || ''}
              options={['J', 'N']}
              onChange={(v) => handleChangeSelect('regnbart', v as string)}
              readOnly={!owned || false}
            />
          </div>
        </Grid>
        <Grid item xs={4}>
          {/*This should be disabled until we can allow admins only to change this*/}
          <div>
            <Dropdown
              header="Inngår i energiberegning"
              value={stoffData?.ingaari || ''}
              options={['J', 'N']}
              onChange={(v) => handleChangeSelect('ingaari', v as string)}
              disabled={!loggedInuser.user.roles?.includes(UserRole.Akf)}
            />
          </div>
          <br />
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={12}>
          <SlowText
            classes={classes}
            header="Beskrivelse"
            id={'StoffBeskrivelse'}
            name={'beskrivelse'}
            value={stoffData?.beskrivelse || ''}
            type={'text'}
            onChange={(e) => handleValueChangeEvent(e, 'beskrivelse')}
            inputProps={{
              maxLength: 2000,
              minRows: 5
            }}
            multiline
            InputProps={{
              readOnly: !owned || false
            }}
          />
        </Grid>
      </Grid>

      <Grid container item xs={12} spacing={1}>
        <Grid item style={{ paddingTop: 30 }} xs={12}>
          {stoffData?.stoffnrId ? (
            <>
              <Typography>Stoffgrupper for stoffet</Typography>
              <Table classes="DialogTable">
                <TableHead style={{ backgroundColor: '#4977AF' }}>
                  <TableRow>
                    <TableCell style={{ color: 'white' }}>Stoffgruppeid</TableCell>
                    <TableCell style={{ color: 'white' }}>Navn</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {nutrientGroupIdAndName?.map((row) => (
                    <TableRow hover role="checkbox" key={row.NAVN}>
                      <TableCell>{row.STOFFGRUPPEID}</TableCell>
                      <TableCell>{row.NAVN}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
      <Divider style={{ margin: 15 }} />
    </Grid>
  );
};
export default StoffDetails;
