import React from 'react';
import { BrowserRouter as Router, Redirect } from 'react-router-dom';
import { Brukerliste } from '../admin/Brukerliste';
import { NavLinks } from '../../route/NavLinks';
import { PrivateRoute } from '../../route/PrivateRoute';
import { Prosjekt } from '../kosthold/Prosjekt';
import { ImportkostholdsUndersokelse } from '../kosthold/import/ImportkostholdsUndersokelse';
import { FjernMatvarekoder } from '../kosthold/import/FjernMatvarekoder';
import MatvareContainer from '../matvare/MatvareContainer';
import { MatvaregruppeContainer } from '../matvare/MatvaregruppeContainer';
import Mengdeenheter from '../matvare/Mengdeenheter';
import Matvarer from '../kosthold/Matvarer';
import StoffeMatvarer from '../matvare/StoffeMatvarer';
import { Projekter } from '../rapport/Kostholdsdata/projekter';
import { Undersøkelserprosjektvis } from '../rapport/Kostholdsdata/Undersøkelser-prosjektvis';
import { Definertespørsmålogsvar } from '../rapport/Kostholdsdata/Definerte-spørsmålogsvar';
import { Spørsmålogsvarundersøkelsen } from '../rapport/Kostholdsdata/Spørsmålogsvar-undersøkelsen';
import { Personenssvartilleggsspørsmål } from '../rapport/Kostholdsdata/personenssvar-tilleggsspørsmål';
import { Måltidsinformasjon } from '../rapport/Kostholdsdata/måltidsinformasjon';
import { Korrekturfil } from '../rapport/Kostholdsdata/korrekturfil/Korrekturfil';
import { Utbyttetmatvare } from '../rapport/Kostholdsdata/utbyttet-matvare';
import { MatvarerSortertkode } from '../rapport/Matvaredata/matvarer-sortertkode';
import { MatvarerSortertnavn } from '../rapport/Matvaredata/matvarer-sortertnavn';
import Matvareoppskrifter from '../rapport/Matvaredata/matvareoppskrifter/Matvareoppskrifter';
import { Matvarekoder } from '../rapport/Matvaredata/matvarekoder';
import { Erstatningsmatvarer } from '../rapport/Matvaredata/erstatningsmatvarer';
import { Matvarerdicerse } from '../rapport/Matvaredata/matvarer-dicerse';
import { Altmatvare } from '../rapport/Matvaredata/altmatvare/Altmatvare';
import { Kodebokgruppert } from '../rapport/Matvaredata/kodebok-gruppert';
import { Stoffer } from '../rapport/Matvaredata/stoffer';
import { Stoffgrupperstoffer } from '../rapport/Matvaredata/stoffgrupper-stoffer';
import { Matvaregrupper } from '../rapport/Matvaredata/matvaregrupper';
import { Intro } from './Intro';
import { HentKosthold } from '../kosthold/HentKosthold';
import { Beregning } from '../beregning/Beregning';
import { useKeycloak } from '@react-keycloak/web';
import Stoff from '../stoff/Stoff';
import Stoffgruppe from '../stoffgrupper/Stoffgruppe';
import Tilleggsvariabler from '../kosthold/Tilleggsvariabler';
import { ImportPersoner } from '../kosthold/import/ImportPersoner';
import Footer from '../Footer';
import useStyles from '../../../css/IntroCss';
import useStylesMain from '../../../css/materialtheme';
import Oppskrift from '../matvare/Oppskrift';
import UtvalgteMatvarer from '../matvare/UtvalgteMatvarer';
import HentMatvarer from '../matvare/HentMatvarer';
import { ImportMatvarer } from '../matvare/Import/ImportMatvarer';
import { ImportMatvarefelter } from '../matvare/Import/ImportMatvarefelter';
import { ImportMengdeenheter } from '../matvare/Import/ImportMengdeenheter';
import { ImportOppskrifter } from '../matvare/Import/ImportOppskrifter';
import { ImportStoffkomponenterRAF } from '../matvare/Import/ImportStoffkomponenterRAF';
import { ImportStoff } from '../matvare/Import/ImportStoff';
import { ImportStoffgrupper } from '../matvare/Import/ImportStoffgrupper';
import { ImportKoplingStoff } from '../matvare/Import/ImportKoplingStoff';
import Undersokelse from '../kosthold/Undersokelse';
import { Box } from '@mui/material';
import Retensjonsfaktorer from '../matvare/Retensjonsfaktorer';
import { ImportRetensjonsfaktorer } from '../matvare/Import/ImportRetensjonsfaktorer';
import Defaultverdier from '../admin/Defaultverdier';
import Koderegister from '../admin/Koderegister';
import DefaultAHV from '../admin/DefaultAHV';
import { UserRole } from '../admin/AdminTypes';
import Personer from '../kosthold/Personer';
import InfoPage from '../admin/InfoPage';
import Person from '../kosthold/Person';
import PersonLayout from '../kosthold/PersonLayout';
import UndersokelseTilleggsvariabler from '../kosthold/UndersokelseTilleggsvariabler';
import MatvarerDetaljeTable from '../kosthold/MatvarerDetaljeTable';

export function Home() {
  const [title, setTile] = React.useState('NutriFoodCalc');
  document.title = title;
  const { keycloak } = useKeycloak();
  const classes = useStyles();
  const commonClasses = useStylesMain();
  document.title = title;
  React.useEffect(() => {
    setTile('NutriFoodCalc');
  }, []);
  if (!keycloak?.realmAccess) {
    return <Redirect to="/public" />;
  } else {
    const roles = keycloak?.realmAccess?.roles;
    if (roles === undefined) {
      return <Redirect to="/public" />;
    } else {
      if (
        !(
          roles?.includes(UserRole.Admin) ||
          roles?.includes(UserRole.ReadnWrite) ||
          roles?.includes(UserRole.ReadOnly) ||
          roles?.includes(UserRole.Akf)
        )
      ) {
        return <Redirect to="/public" />;
      }
    }
  }
  return (
    <Router>
      <Box sx={commonClasses.flexContainerColumn}>
        <NavLinks isLogin={false} />
        <Box sx={[commonClasses.flexContainerColumnCenter, classes.mainBackgroundImage]}>
          <PrivateRoute exact path="/home" component={Intro} />
          <PrivateRoute exact path="/" component={Home} />

          {/* Kostholdsdata dropdown */}
          <PrivateRoute
            exact
            path="/home/kostholdsdata/hentkosthold"
            component={HentKosthold}
          />
          <PrivateRoute exact path="/home/kostholdsdata/prosjekt" component={Prosjekt} />
          <PrivateRoute
            exact
            path="/home/kostholdsdata/prosjekt/:projectId"
            component={Prosjekt}
          />
          <PrivateRoute
            exact
            path="/home/kostholdsdata/prosjekt/:projectId/:undersokelseId"
            component={Prosjekt}
          />
          <PrivateRoute
            exact
            path="/home/kostholdsdata/undersokelse/:projectId"
            component={Undersokelse}
          />
          <PrivateRoute
            exact
            path="/home/kostholdsdata/undersokelse/:projectId/:undersokelseId"
            component={Undersokelse}
          />
          <PrivateRoute
            exact
            path="/home/kostholdsdata/prosjekt/matvarer/:prosjektId/:undersokelseId/:undersokelseKortnavn"
            component={Matvarer}
          />
          <PrivateRoute
            exact
            path="/home/kostholdsdata/prosjekt/matvarer/:prosjektId/:undersokelseId/:undersokelseKortnavn/detalje/:kode/:navn"
            component={MatvarerDetaljeTable}
          />
          <PrivateRoute
            exact
            path="/home/kostholdsdata/prosjekt/personer/:prosjektId/:undersokelseId/:undersokelseKortnavn"
            component={Personer}
          />
          <PrivateRoute
            exact
            path="/home/kostholdsdata/prosjekt/person/:prosjektId/:undersokelseId/:undersokelseKortnavn"
            component={PersonLayout}
          />
          <PrivateRoute
            exact
            path="/home/kostholdsdata/prosjekt/person/:prosjektId/:undersokelseId/:undersokelseKortnavn/:personId"
            component={PersonLayout}
          />
          <PrivateRoute
            exact
            path="/home/kostholdsdata/prosjekt/person/:prosjektId/:undersokelseId/:undersokelseKortnavn/:personId/:lopedagId/:maaltidId"
            component={PersonLayout}
          />
          <PrivateRoute
            exact
            path="/home/kostholdsdata/:prosjektId/:undersokelseId/tilleggsvariabler/:variabelId"
            component={UndersokelseTilleggsvariabler}
          />
          <PrivateRoute
            exact
            path="/home/kostholdsdata/tilleggsvariabler"
            component={Tilleggsvariabler}
          />
          <PrivateRoute
            exact
            path="/home/kostholdsdata/tilleggsvariabler/:variabelId"
            component={Tilleggsvariabler}
          />
          <PrivateRoute
            exact
            path="/home/kostholdsdata/import/importKostholdsundersokelse"
            component={ImportkostholdsUndersokelse}
          />
          <PrivateRoute
            exact
            path="/home/kostholdsdata/import/importPersoner"
            component={ImportPersoner}
          />
          <PrivateRoute
            exact
            path="/home/kostholdsdata/import/fjernMatvarekoder"
            component={FjernMatvarekoder}
          />
          {/* Kostholdsdata end */}

          {/* Matvaredata Dropdown */}

          <PrivateRoute exact path="/home/matvaredata" component={Intro} />
          <PrivateRoute
            exact
            path="/home/matvaredata/matvare"
            component={MatvareContainer}
          />
          <PrivateRoute
            path="/home/matvaredata/matvare/:kode"
            component={MatvareContainer}
          />
          <PrivateRoute
            exact
            path="/home/matvaredata/matvaregruppe"
            component={MatvaregruppeContainer}
          />
          <PrivateRoute exact path="/home/matvaredata/stoff" component={Stoff} />
          <PrivateRoute exact path="/home/matvaredata/stoff/:kode" component={Stoff} />
          <PrivateRoute exact path="/home/matvaredata/oppskrift" component={Oppskrift} />
          <PrivateRoute
            exact
            path="/home/matvaredata/oppskrift/:kode/:navn/:vektE"
            component={Oppskrift}
          />
          <PrivateRoute
            exact
            path="/home/matvaredata/mengdeenheter"
            component={Mengdeenheter}
          />
          <PrivateRoute
            exact
            path="/home/matvaredata/mengdeenheter/:kode/:navn"
            component={Mengdeenheter}
          />
          <PrivateRoute
            exact
            path="/home/matvaredata/stoffgruppe"
            component={Stoffgruppe}
          />

          <PrivateRoute
            exact
            path="/home/matvaredata/stoffgruppe/:kode"
            component={Stoffgruppe}
          />
          <PrivateRoute
            exact
            path="/home/matvaredata/stoffer/:kode/:navn/:oppskrift"
            component={StoffeMatvarer}
          />
          <PrivateRoute
            exact
            path="/home/matvaredata/retensjonsfaktorer/:kode/:navn"
            component={Retensjonsfaktorer}
          />
          <PrivateRoute
            exact
            path="/home/matvaredata/utvalgtematvarer"
            component={UtvalgteMatvarer}
          />
          <PrivateRoute
            exact
            path="/home/matvaredata/hentmatvarer"
            component={HentMatvarer}
          />
          <PrivateRoute
            exact
            path="/home/matvaredata/hentmatvarer/:matvarekode/:navn"
            component={HentMatvarer}
          />
          <PrivateRoute
            exact
            path="/home/matvaredata/hentmatvarer/:matvarekode"
            component={HentMatvarer}
          />
          <PrivateRoute
            exact
            path="/home/matvaredata/import/matvarer"
            component={ImportMatvarer}
          />
          <PrivateRoute
            exact
            path="/home/matvaredata/import/matvarefelter"
            component={ImportMatvarefelter}
          />
          <PrivateRoute
            exact
            path="/home/matvaredata/import/mengdeenheter"
            component={ImportMengdeenheter}
          />
          <PrivateRoute
            exact
            path="/home/matvaredata/import/oppskrifter"
            component={ImportOppskrifter}
          />
          <PrivateRoute
            exact
            path="/home/matvaredata/import/stoffkomponenterRAF"
            component={ImportStoffkomponenterRAF}
          />
          <PrivateRoute
            exact
            path="/home/matvaredata/import/retensjonsfaktorer"
            component={ImportRetensjonsfaktorer}
          />
          <PrivateRoute
            exact
            path="/home/matvaredata/import/stoff"
            component={ImportStoff}
          />
          <PrivateRoute
            exact
            path="/home/matvaredata/import/stoffgrupper"
            component={ImportStoffgrupper}
          />
          <PrivateRoute
            exact
            path="/home/matvaredata/import/koplingStoff"
            component={ImportKoplingStoff}
          />
          {/* Matvaredata end */}

          {/*  */}

          <PrivateRoute exact path="/home/beregning" component={Beregning} />
          <PrivateRoute exact path="/home/rapport" component={Intro} />

          <PrivateRoute
            exact
            path="/home/rapport/kostholdsdata/projekter"
            component={Projekter}
          />

          <PrivateRoute
            exact
            path="/home/rapport/kostholdsdata/undersøkelser-prosjektvis"
            component={Undersøkelserprosjektvis}
          />
          <PrivateRoute
            exact
            path="/home/rapport/kostholdsdata/definerte-spørsmålogsvar"
            component={Definertespørsmålogsvar}
          />
          <PrivateRoute
            exact
            path="/home/rapport/kostholdsdata/spørsmålogsvar-undersøkelsen"
            component={Spørsmålogsvarundersøkelsen}
          />
          <PrivateRoute
            exact
            path="/home/rapport/kostholdsdata/personenssvar-tilleggsspørsmål"
            component={Personenssvartilleggsspørsmål}
          />
          <PrivateRoute
            exact
            path="/home/rapport/kostholdsdata/måltidsinformasjon"
            component={Måltidsinformasjon}
          />
          <PrivateRoute
            exact
            path="/home/rapport/kostholdsdata/korrekturfil"
            component={Korrekturfil}
          />
          <PrivateRoute
            exact
            path="/home/rapport/kostholdsdata/korrekturfil/:prosjektId/:undersokelseId/personId/:personIdFra/:personIdTil"
            component={Korrekturfil}
          />
          <PrivateRoute
            exact
            path="/home/rapport/kostholdsdata/Utbyttet-matvare"
            component={Utbyttetmatvare}
          />
          <PrivateRoute
            exact
            path="/home/rapport/matvaredata/matvarer-sortertkode"
            component={MatvarerSortertkode}
          />
          <PrivateRoute
            exact
            path="/home/rapport/matvaredata/matvarer-sortertnavn"
            component={MatvarerSortertnavn}
          />
          <PrivateRoute
            exact
            path="/home/rapport/matvaredata/matvarer-oppskrifter"
            component={Matvareoppskrifter}
          />
          <PrivateRoute
            exact
            path="/home/rapport/matvaredata/matvarekoder"
            component={Matvarekoder}
          />
          <PrivateRoute
            exact
            path="/home/rapport/matvaredata/erstatningsmatvarer"
            component={Erstatningsmatvarer}
          />
          <PrivateRoute
            exact
            path="/home/rapport/matvaredata/matvarer-dicerse"
            component={Matvarerdicerse}
          />
          <PrivateRoute
            exact
            path="/home/rapport/matvaredata/alt-matvare"
            component={Altmatvare}
          />
          <PrivateRoute
            exact
            path="/home/rapport/matvaredata/kodebok-gruppert"
            component={Kodebokgruppert}
          />
          <PrivateRoute
            exact
            path="/home/rapport/matvaredata/matvaregrupper"
            component={Matvaregrupper}
          />
          <PrivateRoute
            exact
            path="/home/rapport/matvaredata/stoffer"
            component={Stoffer}
          />
          <PrivateRoute
            exact
            path="/home/rapport/matvaredata/stoffgrupper-stoffer"
            component={Stoffgrupperstoffer}
          />
          <PrivateRoute
            exact
            path="/home/admin/defaultverdier"
            component={Defaultverdier}
          />
          <PrivateRoute exact path="/home/admin/infopage" component={InfoPage} />
          <PrivateRoute exact path="/home/admin/brukerliste" component={Brukerliste} />
          <PrivateRoute exact path="/home/admin/koderegister" component={Koderegister} />
          <PrivateRoute exact path="/home/admin/defaultAHV" component={DefaultAHV} />
          {/* Added for temporary deployment */}
        </Box>
        <Footer />
      </Box>
    </Router>
  );
}
