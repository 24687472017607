import React, { useState, useContext, useEffect } from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import { MatvareData } from './types/MatvareTypes';
import { DatabaseContext } from '../../../context/DatabaseContext';
import PageContainer from '../home/PageContainer';
import {
  Alert,
  Dialog,
  DialogContentText,
  Grid,
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from '@mui/material';
import useFetchData from '../../utilities/useFetchData';
import AlertComp from '../../utilities/AlertComp';
import { AlertI } from '../../../types/alertTypes';
import PostData from '../../utilities/postData';
import { useKeycloak } from '@react-keycloak/web';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { useHistory } from 'react-router';
import IsOwnedByUser from '../../utilities/Validate';
import { Dropdown, NButton } from '../components/Inputs';
import {
  CalculatedMengdesI,
  StoffGruppeI,
  StoffGrupper,
  StoffKomponent,
  StoffKomponentResultI
} from './types/StoffTypes';
import useStyles from '../../../css/materialtheme';
import moment from 'moment';
import ReactToPrint from 'react-to-print';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import useDidMountEffect from '../../utilities/useDidMountEffect';
import { ProgressBar } from '../../utilities/ProgressBar';
import { useUserContext } from '../../../context/AuthContext';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { StoffSearch } from '../stoff/StoffSearch';
import { SingleStoffTypes, StoffI, StoffType } from '../stoff/types/StoffTypes';
import StoffkomponentTable from './StoffkomponentTable';
import { EnhancedTableHead } from '../../utilities/EnhancedTableHead';
import { HeadCellsSK } from './components/TableHeadersData';
import { AlertWithAction } from '../../utilities/AlertPopUp';

interface Stoffer {
  matvareData: MatvareData;
  currentDatabase?: any;
  setShowStoffer: any;
  showStoffer: boolean;
  match: any;
}
export interface StoffsToPrintProps {
  user: string;
  database: string;
  matvarekode: number;
  navn: string;
  stoffgruppe: string;
  classesTable: any;
  stoffkomponents: StoffKomponent[];
}
const StoffsToPrint = React.forwardRef((props: StoffsToPrintProps, ref: any) => (
  <div ref={ref}>
    <h3>Utskrift av skjermbildet: Stoffinnhold i 100g matvare {props.user}</h3>
    <Grid container spacing={8}>
      <Grid item xs={6}>
        <label>
          Utskriftsdato:
          {moment().format('YYYY-MM-DD hh:mm:ss')}
        </label>
      </Grid>
      <Grid item xs={6}>
        <label>Matvaredatabase: {props.database}</label>
      </Grid>
    </Grid>
    <Grid container spacing={8}>
      <Grid item xs={6}>
        <label>
          Matvare: {props.matvarekode} - {props.navn}
        </label>
      </Grid>
      <Grid item xs={6}>
        <label>Stoffgruppe: {props.stoffgruppe} </label>
      </Grid>
    </Grid>
    <Table sx={props.classesTable.body}>
      <EnhancedTableHead<StoffKomponent> headCells={HeadCellsSK} />
      <TableBody sx={props.classesTable.body}>
        {props.stoffkomponents?.map((row: StoffKomponent) => {
          return (
            <TableRow hover role="checkbox" key={row?.STOFFNRID}>
              <TableCell>{row?.STOFFNRID}</TableCell>
              <TableCell>{row?.NAVN}</TableCell>
              <TableCell>{row?.KORTNAVN}</TableCell>
              <TableCell>{row?.MENGDE}</TableCell>
              <TableCell>{row?.ENHET}</TableCell>
              <TableCell>{row?.KORR}</TableCell>
              <TableCell>{row?.KILDE}</TableCell>
              <TableCell>{row?.REBER}</TableCell>
              <TableCell>{row?.BERIK}</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  </div>
));
export default function StoffeMatvarer(props: Stoffer) {
  const styles = useStyles();
  const history = useHistory();
  const loggedInuser = useUserContext();
  const [title, setTitle] = useState('Stoffer - Nutrifoodcalc');
  const { currentDatabase } = useContext(DatabaseContext);
  const matvarekodeFromURL = props.match.params.kode || 0;
  const navnFromURL = props.match.params.navn || '';
  const matvarenavn = navnFromURL?.replace(/-slash-/g, '/')?.replace(/-percent-/g, '%');
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState<AlertI['alertColor']>('info');
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [showAlertWA, setShowAlertWA] = useState(false);
  const [alertMessageWA, setAlertMessageWA] = useState('');
  const [alertSeverityWA, setAlertSeverityWA] = useState<AlertI['alertColor']>('info');
  const { keycloak } = useKeycloak();
  const token = keycloak?.token;
  const [stoffToDelete, setStoffToDelete] = useState(0);
  const [comfirmDeletingStoff, setComfirmDeletingStoff] = useState<boolean>(false);
  const [owned, setOwned] = useState<boolean>(false);
  const [isDeleted, setIsDeleted] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [progressText, setProgressText] = useState<string>('Loading...');

  const [oppskrift, setOppskrift] = useState<number>(+props.match.params.oppskrift);
  const [stoffGroupList, setStoffGroupList] = useState<StoffGruppeI[]>([]);
  const [showStoffOption, setShowStoffOption] = useState(false);
  const [selectedStoffs, setSelectedStoffs] = useState<StoffI[]>([]);
  const [stofferList, setStofferList] = useState<StoffI[]>([]);
  const [stoffData, setStoffData] = React.useState<StoffKomponent[]>([]);
  const [newRowData, setNewRowData] = useState<Partial<StoffKomponent>>({});
  const [saveOrUpdate, setSaveOrUpdate] = useState(false);

  document.title = title;
  const printRef = React.useRef();
  const handleListOverEnkeltStoff = (show: boolean, searchType: boolean) => {
    setShowStoffOption(show);
  };
  const [stoffgruppe, setStoffgruppe] = useState<StoffGruppeI>({
    navn: 'Alle stoffer',
    stoffgruppeId: 0
  });
  const getStoffs = useFetchData({
    loadOnMount: true,
    clearDataOnLoad: false,
    projectURL: 'getAllStoffs',
    subProjectURL: `${currentDatabase.value}/0`
  });

  const stoffgrupper = useFetchData({
    loadOnMount: true,
    clearDataOnLoad: false,
    projectURL: 'getStoffgrupper',
    subProjectURL: `${currentDatabase?.value}`
  });

  const stoffsInnholdIMatvare = useFetchData({
    loadOnMount: false,
    clearDataOnLoad: false,
    projectURL: 'getStoffInMatvare',
    subProjectURL: `${currentDatabase?.value}/${matvarekodeFromURL}/${stoffgruppe?.stoffgruppeId}`
  });
  const calculatedMengde = useFetchData({
    loadOnMount: false,
    clearDataOnLoad: false,
    projectURL: 'getCalculatedMengde',
    subProjectURL: `${currentDatabase?.value}/${matvarekodeFromURL}`
  });
  useEffect(() => {
    if (stoffgrupper.data) {
      const data: StoffGrupper = stoffgrupper.data as unknown as StoffGrupper;
      setStoffGroupList(data?.stoffgruppe);
      setStoffgruppe(data?.stoffgruppe?.find((s) => s.stoffgruppeId === data?.defaultId));
      fetchStoffs(data.defaultId);
    }
  }, [stoffgrupper.data]);

  // Here we fetch the stoffs that are in the matvare. If the url changes or the stoffgruppe. We Fetch again.
  useDidMountEffect(() => {
    if (matvarekodeFromURL > 0) {
      setTitle(`Stoffer - ${matvarekodeFromURL} - Nutrifoodcalc`);
      setOwned(IsOwnedByUser({ id: matvarekodeFromURL, u: loggedInuser }));
      fetchStoffs(stoffgruppe?.stoffgruppeId);
      //setDoneFetching(true);
    }
  }, [matvarekodeFromURL, stoffgruppe]);
  const fetchStoffs = (stoffgruppeId: number) => {
    if (stoffgruppeId !== undefined) {
      stoffsInnholdIMatvare.loadData(
        'getStoffInMatvare',
        `${currentDatabase?.value}/${matvarekodeFromURL}/${stoffgruppeId}`
      );
    }
  };
  useEffect(() => {
    if (getStoffs.data) {
      const data: StoffI[] = (getStoffs.data as unknown as StoffI[]) || [];
      setStofferList(data);
    }
  }, [getStoffs.data?.length]);
  const handleChangeStoffGruppe = (value: StoffGruppeI) => {
    //const selectedName = event.target.value as string;
    //const id = stoffGrupper.data.find((element) => element.NAVN === selectedName);
    setStoffgruppe({
      ...stoffgruppe,
      navn: value?.navn,
      stoffgruppeId: value?.stoffgruppeId
    });
  };

  // If this stoff allready has some data we need to add it to the array.
  useEffect(() => {
    if (stoffsInnholdIMatvare.data) {
      setShowProgress(false);
      const data: StoffKomponent[] = stoffsInnholdIMatvare.data as unknown as
        | StoffKomponent[]
        | [];
      const modifiedData = data?.map((row: StoffKomponent) => {
        return {
          ...row,
          MENGDE: parseFloat(row.MENGDE.toString()?.replace(/[,]/g, '.'))
        };
      });
      setStoffData(modifiedData);
      if (isDeleted) {
        setIsDeleted(false);
        callAlert('Slettingen er fullført', 'success');
      }
    }
  }, [stoffsInnholdIMatvare.data, isDeleted]);
  useEffect(() => {
    if (stoffsInnholdIMatvare.error) {
      setShowProgress(false);
      callAlert(`Feil ved henting av stoffkomponenter.`, 'error');
    }
  }, [stoffsInnholdIMatvare.error]);
  const isObjectPopulated = (currentValue: any) => {
    return Object.keys(currentValue).length > 0;
  };
  const validateStoff = (stoffnrId: number) => {
    let valid = false;
    if (stoffnrId) {
      const stoffIndex = stoffData.findIndex((stoff) => +stoff.STOFFNRID === +stoffnrId);
      if (stoffIndex > -1) {
        callAlert('Dette stoffet er allerede lagt inn til listen.', 'warning');
      } else if (+stoffnrId === 2) {
        callAlert('Energi  kan ikke legges til listen.', 'error');
      } else if (+stoffnrId === 3) {
        callAlert('Kalories(Energi) kan ikke legges til listen.', 'error');
      } else {
        valid = true;
      }
    }
    return valid;
  };
  const addSelectedStoff = (row?: StoffI) => {
    if (row && validateStoff(row?.stoffnrId)) {
      setNewRowData({
        ...newRowData,
        STOFFNRID: row.stoffnrId,
        NAVN: row.navn,
        KORTNAVN: row.kortnavn
      });
    }
    setShowStoffOption(!showStoffOption);
  };

  useEffect(() => {
    if (!showAlertWA && saveOrUpdate) {
      setSaveOrUpdate(false);
      calculatedMengde.loadData(
        'getCalculatedMengde',
        `${currentDatabase?.value}/${matvarekodeFromURL}`
      );
      /*  setShowProgress(true);
      setProgressText('Beregner stoffkomponenter i matvaretreet...'); */
    }
  }, [saveOrUpdate, showAlertWA]);

  useDidMountEffect(() => {
    if (calculatedMengde.data) {
      const data: CalculatedMengdesI =
        calculatedMengde.data as unknown as CalculatedMengdesI;
      //setShowProgress(false);
      if (data?.MENGDE !== data?.BEREGNETMENGDE) {
        callAlert(
          `Beregnet energi ${data?.BEREGNETMENGDE} er forskjellig fra lagret verdi ${data?.MENGDE}`,
          'warning'
        );
      } else {
        callAlert(`Beregning av oppskrifter er fullført!`, 'success');
      }
      fetchStoffs(stoffgruppe.stoffgruppeId);
    }
  }, [calculatedMengde.data]);
  useEffect(() => {
    if (calculatedMengde.error) {
      callAlert(
        `Feil ved henting av beregnet energi (eller ingen energigivende stoffer)\n Beregning av oppskrifter ikke fullstendig.`,
        'error'
      );
    }
  }, [calculatedMengde.error]);

  const callAlert = (message: string, info: AlertI['alertColor']) => {
    setAlertMessage(message);
    setAlertSeverity(info);
    setShowAlert(true);
  };

  const isInputValid = (value: string | number) => {
    if (value === '') {
      return false;
    } else if (value === undefined) {
      return false;
    } else if (value === null) {
      return false;
    } else {
      return true;
    }
  };
  const isKildeValid = (kortnavn: string, kilde: string | number) => {
    if (kortnavn !== 'Energi' && oppskrift === 0 && kilde === 'B') {
      setErrorMessage('Kilde = B er ikke lov hvis ikke oppskrift!');
      return false;
    } else {
      return true;
    }
  };

  // We need to validate data before we push.
  // We need to know if its to create new stoff or update stoff.

  // We need to fix this there is an error with the stoff is not invalid

  const handleSaveAllStoffs = async () => {
    let isAllStoffValid;
    stoffData?.forEach((stoff) => {
      if (isObjectPopulated(stoff)) {
        isAllStoffValid =
          isInputValid(stoff.STOFFNRID) &&
          isInputValid(stoff.KORTNAVN) &&
          isInputValid(stoff.NAVN) &&
          isInputValid(stoff.MENGDE) &&
          isInputValid(stoff.KILDE) &&
          isKildeValid(stoff.KORTNAVN, stoff.KILDE);
      }
    });
    if (isAllStoffValid) {
      let data: StoffKomponent[] = [];
      const dataurl = `${currentDatabase?.value}/${matvarekodeFromURL}`;
      stoffData?.map((stoff: StoffKomponent) => {
        if (+stoff.STOFFNRID === 2) {
          //We dont update stoffnrid 2 or Energy
        } else if (+stoff.STOFFNRID > 0) {
          const reqbody: StoffKomponent = {
            STOFFNRID: +stoff.STOFFNRID,
            NAVN: stoff.NAVN,
            KORTNAVN: stoff.KORTNAVN,
            MENGDE: +stoff.MENGDE || 0.0,
            ENHET: stoff.ENHET,
            REBER: stoff.REBER || '',
            KILDE: stoff.KILDE,
            BERIK: stoff.BERIK || ''
          };
          data.push(reqbody);
        }
      });
      setShowProgress(true);
      setProgressText('Lagrer/oppdaterer...');

      PostData({ data: data }, 'saveStoffkomponenter', dataurl, 'POST', token).then(
        async (resp) => {
          if (resp.status === 200) {
            setShowProgress(false);
            setProgressText('Loading...');
            const result = (await resp.json()) as unknown as StoffKomponentResultI;
            let message = ``;
            if (result?.saveResult?.notInserted?.length > 0) {
              message = `Følgende stoffkomponenter ble ikke lagret: \n**${result.saveResult?.notInserted.join(
                ','
              )}**`;
            }
            if (result?.saveResult?.notUpdated?.length > 0) {
              message = `${message} \n Følgende stoffkomponenter ble ikke oppdatert: \n**${result.saveResult?.notUpdated.join(
                ','
              )}**`;
            }
            /* if (result?.mengdes?.MENGDE !== result?.mengdes?.BEREGNETMENGDE) {
              callAlert(
                `Lagringen er fullført men Beregnet energi ${result?.mengdes?.BEREGNETMENGDE} er forskjellig fra lagret verdi ${result?.mengdes?.MENGDE}`,
                'warning'
              ); */
            if (message !== '') {
              showAlertMessageWA(message, 'warning');
              //TODO on action call calculate mengde as a separate api, in the mean time show beregner.......
            } else {
              callAlert(`Lagringen er fullført!`, 'success');
            }
            setSaveOrUpdate(true);
          } else if (resp.status === 500) {
            setShowProgress(false);
            setProgressText('Loading...');
            console.log('An error 500', resp.status);
            callAlert(
              'Oppdateringen mislyktes. Det har ikke blitt gjort endringer mot databasen.',
              'error'
            );
          }
        }
      );
    } else {
      callAlert(
        errorMessage || 'Navn, Mengde og Kilde og Stoff Nr kan ikke være tomme.',
        'error'
      );
    }
  };

  const selectStoffToDelete = (stoffnr: number) => {
    if (+stoffnr === 2) {
      alert('Energi kan ikke slettes.');
    } else if (+stoffnr === 3) {
      alert('Kalories(energi) kan ikke slettes.');
    } else {
      setStoffToDelete(stoffnr);
      setComfirmDeletingStoff(true);
    }
  };

  const handleClickJa = async () => {
    deleteSelecteStoff();
    closeDialog();
  };

  const handleClickNei = () => {
    closeDialog();
  };

  const closeDialog = () => {
    setComfirmDeletingStoff(false);
  };
  const deleteSelecteStoff = () => {
    setShowProgress(true);
    setProgressText('Sletter...');
    const dataurl = `${currentDatabase?.value}/${matvarekodeFromURL}/${stoffToDelete}`;
    PostData({}, 'deleteStoffStoffkomponent', dataurl, 'DELETE', token)
      .then((resp) => {
        if (resp.status === 200) {
          stoffsInnholdIMatvare.loadData(
            'getStoffInMatvare',
            `${currentDatabase?.value}/${matvarekodeFromURL}/${stoffgruppe.stoffgruppeId}`
          );
          setShowProgress(false);
          setProgressText('Loading...');
          setIsDeleted(true);
        } else {
          setShowProgress(false);
          setProgressText('Loading...');
          callAlert(
            'Slettingen mislyktes. Ingen sletting er utført mot databasen.',
            'error'
          );
        }
      })
      .catch((err) => {
        console.log('We are in the catch', err);
        callAlert(
          'Slettingen mislyktes. Ingen sletting er utført mot databasen.',
          'error'
        );
      });
  };
  const showAlertMessageWA = (message: string, severity: any) => {
    setShowAlertWA(true);
    setAlertMessageWA(message);
    setAlertSeverityWA(severity);
  };
  const handleRetensjonsfaktorer = () => {
    if (oppskrift === 1) {
      callAlert('Det kan ikke registreres retensjonsfaktorer for oppskrifter.', 'info');
    }
  };
  return (
    <PageContainer>
      <Typography variant="h4">Stoffinnhold i 100g matvare</Typography>
      <Grid container spacing={2}>
        <Grid item xs={6} container alignItems={'flex-start'}>
          <InputLabel style={{ fontSize: 18 }}>
            Matvare: {matvarekodeFromURL} -{matvarenavn || ''}
          </InputLabel>
        </Grid>
        <Grid item xs={6}>
          <Stack direction="row-reverse" spacing={2}>
            <ReactToPrint
              trigger={() => (
                <NButton
                  children="Skriv ut"
                  rightIcon={<PrintOutlinedIcon />}
                  variant="text"
                  disabled={stoffsInnholdIMatvare.data?.length === 0}
                />
              )}
              pageStyle=".styledTable{width:100%;border-collapse:collapse;text-align:left}.styledTable th,.styledTable td{border:2px solid black;padding:0.5em;}"
              content={() => printRef.current}
            />
          </Stack>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={3} alignItems={'center'} justifyContent={'flex-start'}>
          <Box style={{ paddingBottom: 15 }}>
            <NButton
              children="Søk etter stoff"
              leftIcon={<SearchOutlinedIcon />}
              onClick={() => handleListOverEnkeltStoff(true, true)}
              disabled={!(stoffgruppe?.stoffgruppeId === 0 && owned)}
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          {stoffgrupper.isLoaded && (
            <Box display="flex" alignItems={'center'} justifyContent={'flex-start'}>
              <InputLabel style={{ textAlign: 'left' }} sx={styles.InputLabel}>
                Stoffgruppe:{' '}
              </InputLabel>
              <Dropdown
                header=""
                value={stoffgruppe}
                options={stoffGroupList}
                optionLabel={(o) => o.navn}
                onChange={(v) => handleChangeStoffGruppe(v as StoffGruppeI)}
                disableClearable={true}
                width={'70%'}
              />
            </Box>
          )}
        </Grid>
        <Grid container item xs={3} alignItems={'flex-start'} justifyContent={'flex-end'}>
          {oppskrift === 0 ? (
            <Link
              target="_blank"
              href={`/home/matvaredata/retensjonsfaktorer/${matvarekodeFromURL}/${matvarenavn
                ?.replace(/[/]/g, '-slash-')
                ?.replace(/[%]/g, '-percent-')}`}
            >
              <NButton
                onClick={handleRetensjonsfaktorer}
                children="Retensjonsfaktorer"
                variant="text"
                rightIcon={<OpenInNewOutlinedIcon />}
                disabled={stoffsInnholdIMatvare.data?.length === 0}
              />
            </Link>
          ) : (
            <Link>
              <NButton
                onClick={handleRetensjonsfaktorer}
                children="Retensjonsfaktorer"
                rightIcon={<OpenInNewOutlinedIcon />}
                variant="text"
                disabled={stoffsInnholdIMatvare.data?.length === 0}
              />
            </Link>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Alert severity="info" sx={{ marginTop: '1em' }}>
            Bruk punktum(.) i stedet for komma(,) for desimalverdier.
          </Alert>
        </Grid>
      </Grid>
      <Box sx={{ display: 'none' }}>
        <StoffsToPrint
          user={loggedInuser?.user?.username}
          database={currentDatabase['label']}
          matvarekode={matvarekodeFromURL}
          navn={matvarenavn}
          stoffgruppe={stoffgruppe?.navn}
          classesTable={styles}
          stoffkomponents={stoffData}
          ref={printRef}
        />
      </Box>
      {showAlert && (
        <AlertComp
          alertMessage={alertMessage}
          alertColor={alertSeverity}
          showAlert={showAlert}
          setShowAlert={setShowAlert}
        />
      )}
      {showAlertWA && (
        <AlertWithAction
          alertMessage={alertMessageWA}
          alertColor={alertSeverityWA}
          showAlert={showAlertWA}
          setShowAlert={setShowAlertWA}
        />
      )}
      {showProgress && (
        <ProgressBar
          isShowing={showProgress}
          text={progressText}
          hide={() => {
            setShowProgress(!showProgress);
          }}
        />
      )}
      {comfirmDeletingStoff && (
        <Dialog
          maxWidth="xs"
          fullWidth
          open={comfirmDeletingStoff}
          onClose={closeDialog}
          aria-labelledby="sletteMatvare"
        >
          <DialogTitle id="sletteMatvare">Slett stoff</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Vil du slette stoff med stoffnr: <b>{stoffToDelete}</b>?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClickJa} color="primary">
              Ja
            </Button>
            <Button onClick={handleClickNei} color="primary" autoFocus>
              Nei
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {showStoffOption && (
        <StoffSearch
          stofferList={stofferList}
          selectedStoffs={selectedStoffs}
          setSelectedStoffs={setSelectedStoffs}
          stoffType={StoffType.single}
          toggleStoffnummer={addSelectedStoff}
          showStoffOption={showStoffOption}
          databaseId={currentDatabase?.value}
          setStofferList={setStofferList}
          history={history}
          setShowStoffOption={setShowStoffOption}
          singleStoffType={SingleStoffTypes.stoffMat}
        />
      )}
      {stoffsInnholdIMatvare.isLoaded ? (
        <StoffkomponentTable
          owned={owned}
          stoffgruppe={stoffgruppe}
          newRowData={newRowData}
          setNewRowData={setNewRowData}
          stoffData={stoffData}
          allStoffs={stofferList}
          setStoffData={setStoffData}
          handleDelete={selectStoffToDelete}
          validateStoff={validateStoff}
        />
      ) : (
        <Box>Loading...</Box>
      )}
      <Box display={'flex'} sx={{ padding: 2 }}>
        <NButton children="Lagre" onClick={handleSaveAllStoffs} disabled={!owned} />
      </Box>
    </PageContainer>
  );
}
