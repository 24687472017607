import React from 'react';
import useFetchData from '../../../utilities/useFetchData';
import { ProsjektI, UndersokelseI } from '../types/ProjectTypes';
import { useProjectContext } from '../../../../context/ProjectContext';
interface ProjectandUndersokelseI {
  projectId: number;
  refetchProject: boolean;
  refetchSurvey: boolean;
  setRefetchProject: (value: boolean) => void;
  setRefetchSurvey: (value: boolean) => void;
  setShowProgress?: (value: boolean) => void;
  setText?: (value: string) => void;
  setAllUndersokelser?: (objs: UndersokelseI[]) => void;
}
export const FetchProjectandUndersokelse = (props: ProjectandUndersokelseI) => {
  const projs = useProjectContext();
  const {
    projectId,
    refetchProject,
    refetchSurvey,
    setRefetchProject,
    setRefetchSurvey,
    setShowProgress,
    setText,
    setAllUndersokelser
  } = props;
  const projects = useFetchData({
    loadOnMount: true,
    clearDataOnLoad: false,
    projectURL: 'hentProsjekterOgUndersokelser',
    subProjectURL: null
  });
  const surveys = useFetchData({
    loadOnMount: false,
    clearDataOnLoad: false,
    projectURL: 'hentPUndersokelser',
    subProjectURL: `${projectId}`
  });
  React.useEffect(() => {
    if (projs?.projects?.length === 0 || refetchProject) {
      handleProgress('Henter prosjekter...', true);
      projects.loadData('hentProsjekterOgUndersokelser', null);
    }
  }, [refetchProject]);
  React.useEffect(() => {
    if (projects.data) {
      const data = projects.data as unknown as ProsjektI[];
      if (data.length > 0) {
        handleProgress('', false);
        projs?.setProjects(data);
        setRefetchProject(false);
        setRefetchSurvey(true);
      }
    }
  }, [projects.data]);
  React.useEffect(() => {
    if (projectId && projs?.projects) {
      surveys.loadData('hentPUndersokelser', `${projectId}`);
    }
  }, [projectId, refetchSurvey]);
  React.useEffect(() => {
    if (surveys.data) {
      const data = surveys.data as unknown as UndersokelseI[];
      if (data.length > 0) {
        handleProgress('', false);
        setAllUndersokelser && setAllUndersokelser(data);
        let projectToUpdate = projs?.projects.find((p) => p.prosjektId === +projectId);
        if (projectToUpdate) {
          projectToUpdate.undersokelser = data;
        }
        setRefetchSurvey(false);
      }
    }
  }, [surveys.data]);
  const handleProgress = (text: string, status: boolean) => {
    setText && setText(text);
    setShowProgress && setShowProgress(status);
  };
  return <></>;
};
