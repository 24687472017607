import React, { useContext, useEffect } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { RegisterUser } from '../authentication/RegisterUser';
import { useHistory } from 'react-router';
import { Box, Typography, Button } from '@mui/material';
import useStyles from '../../css/IntroCss';
import useStylesMain from '../../css/materialtheme';
import { NavLinks } from '../route/NavLinks';
import Footer from '../pages/Footer';
import { UserRole } from './admin/AdminTypes';
import {
  RegistrationContext,
  UserStatus,
  useUserContext
} from '../../context/AuthContext';
import { NButton } from './components/Inputs';
import useGetKeycloakUser from '../authentication/KeycloakObject';

export const Public = () => {
  const { keycloak } = useKeycloak();
  const commonClasses = useStylesMain();
  const classes = useStyles();
  const [started, setStarted] = React.useState<boolean>(false);
  const status = useContext(RegistrationContext);

  const [message, setMessage] = React.useState<string>('Oppereter brukerkonto...');
  const kcLogoutOptions = {
    redirectUri: process.env.REACT_APP_FRONTEND + '/login'
  };

  useEffect(() => {
    if (status.registered === undefined || status.registered === false) {
      setStarted(true);
    } else if (status.registered === true) {
      setMessage(`Du er nå registrert i systemet. Når kontoen din er klar til bruk, vil du bli
        varslet via e-post. Vær oppmerksom på at dette kan ta noen dager`);
    } else if (status.registered === false) {
      console.log('registered id: ', status.registered);
      setMessage(
        `Bruker registreringen myslyktes. Vennligst rapporter feilmeldingen via nfc-drift@usit.uio.no`
      );
    }
  }, [status.registered]);

  if (started) {
    AuthenticateAndRegister(keycloak, status.registered, setStarted, status);
  }
  return (
    <Box sx={commonClasses.flexContainerColumn}>
      <NavLinks isLogin={true} />
      <Box sx={[commonClasses.flexContainerColumnCenter, classes.mainBackgroundImage]}>
        <Box style={{ width: 500 }}>
          <Typography sx={classes.NFCTitle}>Velkommen til NutriFoodCalc!</Typography>
          <Typography sx={classes.Introdata} align="left">
            {message}
          </Typography>
          <NButton onClick={() => keycloak?.logout(kcLogoutOptions)} children="Logg ut" />
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};
function AuthenticateAndRegister(
  keycloak: Keycloak.KeycloakInstance,
  registered: boolean,
  setStarted: any,
  status: Partial<UserStatus>
) {
  const history = useHistory();
  setStarted(false);

  if (keycloak?.authenticated) {
    if (!keycloak?.realmAccess) {
      status.setRegistered(true);
      console.log('No realm access');
    } else {
      const roles = keycloak?.realmAccess?.roles;
      if (roles === undefined) {
        status.setRegistered(true);
        console.log('Has realm access but no role');
      } else {
        if (
          roles?.includes(UserRole.Admin) ||
          roles?.includes(UserRole.ReadnWrite) ||
          roles?.includes(UserRole.ReadOnly) ||
          roles?.includes(UserRole.Akf)
        ) {
          SetUsername();
          history.push('/home');
          console.log('Has realm access and one of the 3 roles');
        } else {
          if (!registered) {
            RegisterUser(keycloak).then((success) => {
              if (success) {
                status.setRegistered(true);
              } else {
                status.setRegistered(false);
              }
            });
          }
        }
      }
    }
  }
}

function SetUsername() {
  const loggedInuser = useUserContext();
  const { userInfo } = useGetKeycloakUser();
  loggedInuser?.setUser({
    username: userInfo?.username,
    id: 0,
    numberRange: [],
    roles: userInfo?.realm_access.roles
  });
}
