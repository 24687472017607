import React, { useContext, useEffect, useState } from 'react';
import { Grid, Paper, Stack, Typography } from '@mui/material';
import PageContainer from '../home/PageContainer';
import { Dropdown, NButton } from '../components/Inputs';
import useFetchData from '../../utilities/useFetchData';
import { DatabaseContext, useDatabaseContext } from '../../../context/DatabaseContext';
import { StoffGruppeI, StoffGrupper } from '../matvare/types/StoffTypes';
import { DefaultId } from './AdminTypes';
import { AlertI } from '../../../types/alertTypes';
import AlertComp from '../../utilities/AlertComp';
import { ProgressBar } from '../../utilities/ProgressBar';
import { useKeycloak } from '@react-keycloak/web';
import Textarea from '@mui/joy/Textarea';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { FormControl, FormLabel, IconButton } from '@mui/material';
/* import {
  experimental_extendTheme as materialExtendTheme,
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
  THEME_ID as MATERIAL_THEME_ID,
} from '@mui/material/styles'; */
import { CssVarsProvider as JoyCssVarsProvider } from '@mui/joy/styles';
export interface Info {
  id: number;
  navn: string;
  beskrivelse: string;
  dato: string;
}
export default function InfoPage() {
  const { keycloak } = useKeycloak();
  const token = keycloak?.token || 'Mangler tilgang';
  const [title] = React.useState('Informasjon - NutriFoodCalc');
  document.title = title;
  const db = useDatabaseContext();
  const [info, setInfo] = useState<Info[]>([]);
  const dataTOsave: Info = {
    id: 1,
    navn: 'info1',
    beskrivelse: 'we are testing new table',
    dato: 'today'
  };
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = React.useState<string>('');
  const [alertSeverity, setAlertSeverity] = React.useState<AlertI['alertColor']>('info');
  const [postUrl] = React.useState<string>('saveInfo');
  const apiCall = (uri: string, method: string, body?: any) => {
    const fullUri = `${process.env.REACT_APP_BACKEND}/${uri}`;
    return fetch(encodeURI(fullUri), {
      method: method,
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      body: body ? JSON.stringify(body) : undefined
    });
  };
  const getInfo = useFetchData({
    loadOnMount: true,
    clearDataOnLoad: false,
    projectURL: 'readInfo',
    subProjectURL: null
  });

  useEffect(() => {
    if (getInfo.data) {
      const data: Info[] = (getInfo.data as unknown as Info[]) || [];
      setInfo(data);
    }
  }, [getInfo.data]);

  const save = async (url: string, data: Info, isDatabase: boolean) => {
    setShowProgress(true);
    const response = await apiCall(url, 'POST', { data: data });
    if (response.ok) {
      showAlertComponent('Lagringen er fullført', 'success');
    } else {
      showAlertComponent(`Lagringen er ikke fullført`, 'error');
    }
    setShowProgress(false);
  };
  const showAlertComponent = (message: string, severity: any) => {
    setShowAlert(true);
    setAlertMessage(message);
    setAlertSeverity(severity);
  };
  return (
    <PageContainer>
      <Paper style={{ padding: '1.5em 15px 1.5em 15px', boxShadow: 'none' }}>
        <Typography variant="h4">Informasjon</Typography>
        <Grid item xs={12} sx={{ pt: 2 }} container spacing={2} alignItems="center">
          {/*         <MaterialCssVarsProvider theme={{ [MATERIAL_THEME_ID]: materialTheme }}>
           */}{' '}
          <JoyCssVarsProvider>
            {info?.map((i) => {
              return (
                <Textarea
                  key={`text-area-${i.id}`}
                  variant="outlined"
                  color="neutral"
                  maxRows={2}
                  value={i.beskrivelse}
                  sx={{ minWidth: 600, alignItems: 'flex-end' }}
                />
              );
            })}
          </JoyCssVarsProvider>
          {/*       </MaterialCssVarsProvider>
           */}{' '}
          <Stack direction="row" spacing={1}>
            <NButton children="Lagre" onClick={() => save(postUrl, dataTOsave, true)} />
          </Stack>
        </Grid>

        {showAlert && (
          <AlertComp
            alertMessage={alertMessage}
            alertColor={alertSeverity}
            showAlert={showAlert}
            setShowAlert={setShowAlert}
          />
        )}
        {showProgress && (
          <ProgressBar
            isShowing={showProgress}
            hide={() => {
              setShowProgress(!showProgress);
            }}
          />
        )}
      </Paper>
    </PageContainer>
  );
}
