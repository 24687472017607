import { useTheme } from '@mui/material';
import Image from '../../src/images/image2.png';

const imageUrl = window.innerWidth >= 350 ? Image : '';

const useStyles = () => {
  const theme = useTheme();
  return {
    mainBackgroundImage: {
      backgroundImage: `url(${imageUrl})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center'
      /* [theme.breakpoints.between('md', 'lg')]: {
      height: '70.4vh',
      width: '100%',
      backgroundRepeat: 'no-repeat',
      backgroundImage: `url(${imageUrl})`,
      backgroundSize: 'cover'
    } */
    },
    Applogo: {
      height: '80px',
      alignItems: 'left'
    },

    IntroBackground: {
      backgroundImage: `url(${imageUrl})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      height: 'inherit',
      width: '100%',
      backgroundPosition: 'center center'
    },

    Intro: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      WebkitJustifyContent: 'center',
      padding: '0',
      alignContent: 'center'
    },

    NFCTitle: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '42px',
      color: '#000000',
      justifyContent: 'flex-start',
      alignItems: 'left',
      WebkitJustifyContent: 'flex-start',
      justify: 'flex-start',
      maxHeight: '100vh',
      alignContent: 'left'
    },
    Introdata: {
      fontFamily: 'Arial',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '18px',
      [theme.breakpoints.up('sm')]: {
        fontSize: '18px'
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '18px'
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '21px'
      },
      [theme.breakpoints.up('xl')]: {
        fontSize: '21px'
      },

      // lineHeight: "21px",
      color: '#000000',
      align: 'left'
    },
    VelgMatvaredatabase: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '20px',
      lineHeight: '23.44px',
      color: '#000000',
      justifyContent: 'left',
      alignItems: 'left',
      WebkitJustifyContent: 'left',
      justify: 'left',
      maxHeight: '100vh',
      alignContent: 'left',
      wrap: 'wrap'
    },
    Velg: {
      fontFamily: 'Arial',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '16px',
      lineHeight: '21px',
      color: '#000000',
      justifyContent: 'left',
      alignItems: 'left',
      WebkitJustifyContent: 'left',
      alignContent: 'left',
      wrap: 'wrap',
      justify: 'flex'
    },
    ContentPlacement: {
      marginLeft: '30%',
      marginRight: '40%'
    },
    ContentPlacementTitle: {
      position: 'relative',
      top: 229,
      width: 400
    },
    PlacementLoginButton: {
      /* marginBottom: '1vh',
    marginTop: '1vh' */
      /*     marginLeft: '30%',
    marginRight: '40%' */
    }
  };
};
export default useStyles;
