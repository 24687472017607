import React from 'react';
import { OutlinedInput, useTheme } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import { makeStyles } from '@mui/material/styles';
import { FormControl, ListItemIcon } from '@mui/material';

const useStyles = () => {
  const theme = useTheme();
  return {
    formControl: {
      margin: theme.spacing(1),
      width: 500
    },
    indeterminateColor: {
      color: '#f50057'
    },
    selectAllText: {
      fontWeight: 500
    },
    selectedAll: {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.08)'
      }
    }
  };
};
const options = [
  'engelsk navn',
  'kilde',
  'beskrivelse',
  'engelsk beskrivelse',
  'referanse',
  'sokeord',
  'kommentar',
  'status',
  'oppskrift',
  'vektendring',
  'MVT-nr'
];

interface OptionalParamProps {
  selectedOptions: string[];
  setSelectedOptions: (list: string[]) => void;
}
export default function OptionalParameters(props: OptionalParamProps) {
  const { selectedOptions, setSelectedOptions } = props;
  const classes = useStyles();

  const isAllSelected =
    options?.length > 0 && selectedOptions?.length === options?.length;
  const handleChange = (event: { target: { value: any } }) => {
    const value = event.target.value;
    if (value[value.length - 1] === 'all') {
      setSelectedOptions(selectedOptions?.length === options?.length ? [] : options);
      return;
    }
    setSelectedOptions(value);
  };
  return (
    <div>
      <FormControl sx={{ m: 1, width: 600 }}>
        <InputLabel id="checkbox-label">Velg variabler</InputLabel>
        <Select
          labelId="multiple-checkbox-label"
          id="multiple-checkbox"
          multiple
          value={selectedOptions}
          onChange={handleChange}
          input={<OutlinedInput label="include" />}
          renderValue={(selectedOptions: any) => selectedOptions.join(', ')}
          // MenuProps={MenuProps}
        >
          <MenuItem
            value="all"
            sx={{
              root: isAllSelected ? classes.selectedAll : ''
            }}
          >
            <ListItemIcon>
              <Checkbox
                sx={{ indeterminate: classes.indeterminateColor }}
                checked={isAllSelected}
                indeterminate={
                  selectedOptions?.length > 0 && selectedOptions?.length < options?.length
                }
              />
            </ListItemIcon>
            <ListItemText sx={{ primary: classes.selectAllText }} primary="Alle" />
          </MenuItem>
          {options.map((op: string) => (
            <MenuItem key={op} value={op}>
              <Checkbox checked={selectedOptions.indexOf(op) > -1} />
              <ListItemText primary={'Inkl. ' + op} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
