import {
  Paper,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@mui/material';
import React, { ChangeEvent, Dispatch, SetStateAction, useMemo } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useUserContext } from '../../../context/AuthContext';
import { EnhancedTableHead } from '../../utilities/EnhancedTableHead';
import IsOwnedByUser from '../../utilities/Validate';
import { HeadCell } from '../components/commons/CommonTypes';
import { NoScrollTableInput } from '../components/Inputs';
import { PersonI, TilleggssvarI } from './types/PersonTypes';
import { TilleggsvariableSvarI } from './types/TilleggsvariableTypes';

const headCells: HeadCell<TilleggssvarI>[] = [
  {
    id: 'kortnavn',
    align: 'left',
    padding: 'normal',
    label: 'Kortnavn'
  },
  {
    id: 'svarType',
    align: 'left',
    padding: 'normal',
    label: 'Svartype'
  },
  {
    id: 'svar',
    align: 'right',
    padding: 'normal',
    label: 'Svar'
  },
  {
    id: 'kategori',
    align: 'left',
    padding: 'normal',
    label: 'Kategori'
  }
];

interface RouteParams {
  prosjektId: string;
}

interface PersonTilleggssvarTable extends RouteComponentProps<RouteParams> {
  personData: PersonI;
  setPersonData: Dispatch<SetStateAction<PersonI>>;
  svarKategorier: TilleggsvariableSvarI[] | null;
  variabelId: number;
  setVariabelId: Dispatch<SetStateAction<number>>;
  svarErrorArray: number[];
  setSvarErrorArray: Dispatch<SetStateAction<number[]>>;
}

export default function PersonTilleggssvarTable(props: PersonTilleggssvarTable) {
  const {
    personData,
    setPersonData,
    svarKategorier,
    variabelId,
    setVariabelId,
    svarErrorArray,
    setSvarErrorArray
  } = props;
  const { prosjektId } = props.match.params;
  const loggedInuser = useUserContext();
  const owned = useMemo(
    () => IsOwnedByUser({ id: +prosjektId, u: loggedInuser }),
    [prosjektId, loggedInuser]
  );

  function handleChange(
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    id: number
  ) {
    const { value } = event.target;
    setVariabelId(id);
    if (
      svarKategorier?.find((svar) => svar.kode === +value) === undefined &&
      personData?.tilleggssvar?.find((answer) => answer.variabelId === id)?.svarType ===
        'Kategorier' &&
      value !== ''
    ) {
      setSvarErrorArray([...svarErrorArray, id]);
      return;
    } else {
      setSvarErrorArray(svarErrorArray.filter((code) => code !== id));
    }
    const newAnswers = personData?.tilleggssvar?.map((svar) => {
      if (svar.variabelId === id) {
        return {
          ...svar,
          svar: +value,
          kategori: svarKategorier.find((svar) => svar.kode === +value)?.beskrivelse
        };
      }
      return svar;
    });
    setPersonData({ ...personData, tilleggssvar: newAnswers });
  }

  return (
    <TableContainer component={Paper} sx={{ maxHeight: '23rem' }}>
      <Table stickyHeader size="small">
        <EnhancedTableHead<TilleggssvarI>
          headCells={headCells}
          leftColumns={[{ name: 'Velg', padding: 'checkbox' }]}
        />
        <TableBody>
          {personData?.tilleggssvar?.map((row, index) => (
            <TableRow
              key={row.variabelId}
              onClick={() => setVariabelId(row.variabelId)}
              sx={{
                ':not(:hover)': {
                  backgroundColor: '#F1F1F1',
                  '& > :nth-of-type(1), & > :nth-of-type(4)': {
                    backgroundColor: '#FFFFFF'
                  }
                }
              }}
              hover
            >
              <TableCell padding="checkbox">
                <Radio
                  checked={variabelId === row.variabelId}
                  inputProps={{
                    'aria-labelledby': 'table-checkbox-' + index
                  }}
                />
              </TableCell>
              {headCells.map((cell, i) =>
                cell.id === 'svar' ? (
                  <TableCell key={cell.id}>
                    <NoScrollTableInput
                      value={
                        personData?.tilleggssvar
                          ?.find((answer) => answer.variabelId === row.variabelId)
                          .svar?.toString() || ''
                      }
                      type={'number'}
                      onChange={(event) => handleChange(event, row.variabelId)}
                      onFocus={() => setVariabelId(row.variabelId)}
                      error={svarErrorArray.includes(row.variabelId)}
                      helperText={
                        svarErrorArray.includes(row.variabelId) ? 'Ugyldig svar' : ''
                      }
                      disabled={!owned}
                    />
                  </TableCell>
                ) : (
                  <TableCell key={cell.id} padding={cell.padding} align={cell.align}>
                    {row[cell.id]}
                  </TableCell>
                )
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
