import React, { useContext, useEffect, useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { Grid, Paper, Typography } from '@mui/material';
import {
  Checkbox,
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';
import { AlertI } from '../../../../types/alertTypes';
import useStylesTable from '../../../../css/TableCss';
import useStyles from '../../../../css/materialtheme';
import PageContainer from '../../home/PageContainer';
import AlertComp from '../../../utilities/AlertComp';
import { AlertWithAction } from '../../../utilities/AlertPopUp';
import { Matvare } from '../types/matvaregruppeTypes';
import useFetchData from '../../../utilities/useFetchData';
import { DatabaseContext } from '../../../../context/DatabaseContext';
import PostData from '../../../utilities/postData';
import { ImportMatvareResult, ReberegnResult } from './ImportTypes';
import { ConfirmationDialog } from '../../../utilities/ConfirmationDialog';
import { ProgressBar } from '../../../utilities/ProgressBar';
import { GenericImport, NButton } from '../../components/Inputs';
import PollingComponent from '../../components/commons/PollingComponent';

export function ImportStoffkomponenterRAF(props: any) {
  const { keycloak } = useKeycloak();
  const token = keycloak?.token;
  const { currentDatabase } = useContext(DatabaseContext);
  const classes = useStyles();
  const classesTable = useStylesTable();
  const [title] = useState('Import stoffkomponenter(regnearkformat) - Nutrifoodcalc');
  const [showAlert, setShowAlert] = useState(false);
  const [showAlertWA, setShowAlertWA] = useState(false);
  const [alertMessage, setAlertMessage] = React.useState('');
  const [alertSeverity, setAlertSeverity] = React.useState<AlertI['alertColor']>('info');
  const [fileContent, setFileContent] = React.useState<string>(null);
  const [filename, setFilename] = React.useState('');
  const [showMatvareTable, setShowMatvareTable] = useState(false);
  const [matvares, setMatvares] = useState<Matvare[]>([]);
  const [selectedMatvare, setSelectedMatvare] = useState<Matvare>({
    MATVAREKODEID: 0,
    NAVN: ''
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [showCDialog, setShowCDialog] = useState(false);
  const [showProgress, setShowProgress] = useState(false);
  const [dialogText, setDialogText] = useState('');
  const [confirmationStatus, setConfirmationStatus] = useState(false);
  const [confirmationTitle, setConfirmationTitle] = useState('');
  const [selectedRow, setSelectedRow] = useState(null);
  const [checked, setChecked] = React.useState(false);
  //const [showReberegnBtn, setShowReberegnBtn] = useState(false);
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [pollingId, setPollingId] = React.useState('');
  const [failed, setFailed] = React.useState(false);
  const [importResult, setImportResult] = React.useState<ImportMatvareResult>(null);
  document.title = title;
  const reberegn = useFetchData({
    loadOnMount: false,
    clearDataOnLoad: false,
    projectURL: 'reberegnOppskrift',
    subProjectURL: `${currentDatabase?.value}`
  });
  useEffect(() => {
    if (confirmationStatus) {
      reberegn.loadData('reberegnOppskrift', `${currentDatabase?.value}`);
      setShowProgress(true);
    }
    setConfirmationStatus(false);
    setShowCDialog(false);
  }, [confirmationStatus]);
  useEffect(() => {
    if (importResult) {
      processImportResult();
    }
  }, [importResult]);
  useEffect(() => {
    if (failed) {
      showAlertMessage('Importering av undersøkelse mislyktes.', 'error');
    }
  }, [failed]);
  /* useEffect(() => {
    if (reberegn.data) {
      if (showReberegnBtn) {
        const result: ReberegnResult = reberegn.data as unknown as ReberegnResult;
        if (result.SUCCESS) {
          setShowProgress(false);
          showAlertMessage('Stoffkomponenter er beregnet', 'success');
        } else {
          showAlertMessage(
            'Klarte ikke beregne stoffkomponenter i matvaretreet',
            'error'
          );
        }
        setShowProgress(false);
      }
    }
  }, [reberegn.data]); 
  useEffect(() => {
    if (reberegn.error) {
      showAlertMessage('Klarte ikke beregne stoffkomponenter i matvaretreet', 'error');
    }
  }, [reberegn.error]);
*/
  const showAlertMessage = (message: string, severity: any) => {
    setShowAlert(true);
    setAlertMessage(message);
    setAlertSeverity(severity);
  };
  const showAlertMessageWA = (message: string, severity: any) => {
    setShowAlertWA(true);
    setAlertMessage(message);
    setAlertSeverity(severity);
  };

  const validateInput = () => {
    let valid = true;
    if (currentDatabase?.value === 0) {
      valid = false;
    }
    return valid;
  };
  const importFile = () => {
    const validInputs = validateInput();
    if (!validInputs) {
      showAlertMessage('Technical error.', 'error');
      return;
    }
    console.log('import called', fileContent);
    const dataurl = `${currentDatabase?.value}/${checked}`;
    PostData(
      { data: fileContent },
      'importStoffkomponenter',
      dataurl,
      'POST',
      token
    ).then((resp: any) => {
      if (resp.status === 200) {
        resp.json().then((data) => {
          //Start pooling backend
          setPollingId(data?.id);
        });
      } else if (resp.status === 500) {
        setShowProgress(false);
        console.log('An error 500', resp);
        showAlertMessage('Importering av stoffkomponent mislyktes.', 'error');
      }
    });
  };
  const processImportResult = () => {
    //const res = resultData as unknown as ImportMatvareResult;
    setShowProgress(false);
    setMatvares(importResult?.MATVARES);
    if (importResult?.MATVARES?.length > 0) {
      const msg = importResult?.MESSAGE + '. Se matvarene i tabellen nedenfor.';
      setErrorMessage(importResult?.MESSAGE);
      showAlertMessage(msg, 'error');
      setShowMatvareTable(true);
    } else {
      if (importResult?.SUCCESS) {
        showAlertMessage(importResult?.MESSAGE, 'success');
        //setShowReberegnBtn(true);
      } else {
        showAlertMessage(importResult?.MESSAGE, 'error');
      }
      setShowMatvareTable(false);
    }
    setSelectedMatvare({ MATVAREKODEID: 0, NAVN: '' });
  };

  const handleImportBtnClick = () => {
    setShowProgress(true);
    importFile();
  };
  const handleNullstillBtn = () => {
    setMatvares([]);
    setShowMatvareTable(false);
  };
  const handleHentBtn = () => {
    if (selectedMatvare?.MATVAREKODEID) {
      let url = `/home/matvaredata/hentmatvarer/${selectedMatvare.MATVAREKODEID}/${selectedMatvare.NAVN}`;
      if (!selectedMatvare?.NAVN) {
        url = `/home/matvaredata/hentmatvarer/${selectedMatvare.MATVAREKODEID}`;
      }
      window.open(url);
    } else {
      showAlertMessage('Du må velge en matvare for å hente', 'error');
    }
  };
  /* const handleReberegnBtn = () => {
    setShowCDialog(true);
    setConfirmationTitle('Beregn stoffkomponenter');
    setDialogText(
      'Reberegning av oppskrifter kan ta veldig lang tid. Er du sikker på at du vil reberegne?'
    );
  }; */
  const handleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };
  const handleOnClickHelp = () => {
    showAlertMessageWA(
      `Funksjon for import av stoffkomponenter (stoffverdier) for matvarer.\n 
      Filformat:
      CSV-fil (semikolonseparert), CSV UTF-8(på Mac)
      med følgende felter (* angir obligatorisk felt):\n
      *Matvarekodeid 
      *Navn (blir ikke benyttet) 
      For hvert stoff:
      *Stoffnrid 
      *Kilde\n 
      Filen skal inneholde to overskriftlinjer (også separert med semikolon).
      Den første linjen tas ikke hensyn til, 
      den andre skal inneholde teksten Matvarekodeid og Navn i de to første kolonnene,
      deretter stoffnrid i annen hver kolonne for de stoffene som det er verdier for i filen.
      Rekkefølgen på stoffene i overskriftlinjen må være overenstemmende med
      rekkefølgen av stoffverdier i datalinjene.`,
      'info'
    );
  };
  const clear = () => {
    setSelectedFile(null);
  };
  const changeHandler = async (file: File) => {
    setSelectedFile(file);
    console.log('file size...', file?.size);
    setFilename(file?.name);
    if (file) {
      await file
        .text()
        .then((value) => {
          const allLines = value?.split(/\r\n|\n/);
          const firstLine = allLines[0];
          const headers: string[] = firstLine.split(';');
          let idFound = false;
          let navnFound = false;
          for (const header of headers) {
            if (header.toUpperCase() === 'MATVAREKODEID') {
              idFound = true;
            } else if (header.toUpperCase() === 'NAVN') {
              navnFound = true;
            }
          }
          if (idFound && navnFound) {
            if (file?.size > 0) {
              console.log(value);
              setFileContent(value);
            }
          } else {
            showAlertMessage(
              'Filen ' +
                file?.name +
                ' er ikke en gyldig stoffkomponent importfil (mengler matvarekodeid eller navn)!',
              'error'
            );
            setFilename('');
          }
        })
        .catch((err: any) => {
          console.error(err);
        });
    }
  };
  return (
    <PageContainer>
      <Paper style={{ padding: '1.5em 15px 1.5em 15px', boxShadow: 'none' }}>
        <Typography variant="h4" marginBottom="1em">
          Import stoffkomponenter(regnearkformat)
        </Typography>
        <Grid container>
          <Grid item xs={5}>
            <GenericImport
              help={() => handleOnClickHelp()}
              onChange={(f) => changeHandler(f as File)}
              selected={selectedFile?.name}
              accept=".csv"
              actionLabel="prosessere"
            />
            <Grid item xs={6}>
              <FormControlLabel
                label="Overskriv"
                control={
                  <Checkbox
                    checked={checked}
                    onChange={handleCheckBoxChange}
                    defaultChecked={true}
                  />
                }
              />
            </Grid>
          </Grid>
          <Grid item xs={7} />
          <Grid item xs={12} container spacing={1} sx={{ marginTop: 1 }}>
            <Grid item xs={1}>
              <NButton
                children="Utfør"
                disabled={!selectedFile}
                onClick={(e) => handleImportBtnClick()}
              />
            </Grid>
            <Grid item xs={1}>
              <NButton children="Nullstill" variant="outlined" onClick={clear} />
            </Grid>
          </Grid>
        </Grid>
        {/*  <Grid container alignItems="flex-start" justifyContent="flex-start" spacing={1}>
        {showReberegnBtn && (
          <Grid item xs={4}>
            <AnyButton
              name="Reberegn"
              type="button"
              size="medium"
              onClick={handleReberegnBtn}
              disabled={false}
            />
          </Grid>
        )}
      </Grid>  */}
        {showMatvareTable && (
          <Grid container spacing={1} justifyContent="flex-end" alignItems="flex-end">
            <Grid item xs={6}>
              <label>{errorMessage}:</label>
              <Table sx={classesTable.body}>
                <TableHead>
                  <TableRow>
                    <TableCell sx={classesTable.head}>Kode</TableCell>
                    <TableCell sx={classesTable.head}>Navn</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody sx={classesTable.root}>
                  {matvares?.map((row: Matvare) => {
                    const highlighted =
                      selectedRow && selectedRow.MATVAREKODEID === row.MATVAREKODEID;
                    return (
                      <TableRow
                        key={row?.MATVAREKODEID}
                        sx={classesTable.tableRow}
                        onClick={() => {
                          setSelectedMatvare(row);
                          setSelectedRow(highlighted ? null : row);
                        }}
                        style={{
                          backgroundColor: highlighted ? '#d2ecfd' : 'white'
                        }}
                      >
                        <TableCell sx={classesTable.tableRow}>
                          {row?.MATVAREKODEID}
                        </TableCell>
                        <TableCell sx={classesTable.tableRow}>{row?.NAVN}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Grid>
            <Grid item xs={4}>
              <Grid
                container
                spacing={1}
                justifyContent="flex-start"
                alignItems="flex-end"
              >
                <Grid item xs={4}>
                  <NButton children="Hent" onClick={handleHentBtn} disabled={false} />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={1}
                justifyContent="flex-start"
                alignItems="flex-end"
              >
                <Grid item xs={4}>
                  <NButton
                    children="Nullstill"
                    onClick={handleNullstillBtn}
                    disabled={false}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={2}></Grid>
          </Grid>
        )}
        {pollingId && (
          <PollingComponent
            id={pollingId}
            setFailed={setFailed}
            setResult={setImportResult}
          />
        )}
        {showAlert && (
          <AlertComp
            alertMessage={alertMessage}
            alertColor={alertSeverity}
            showAlert={showAlert}
            setShowAlert={setShowAlert}
          />
        )}
        {showAlertWA && (
          <AlertWithAction
            alertMessage={alertMessage}
            alertColor={alertSeverity}
            showAlert={showAlertWA}
            setShowAlert={setShowAlertWA}
          />
        )}
        {showCDialog && (
          <ConfirmationDialog
            title={confirmationTitle}
            text={dialogText}
            setConfirmationResult={setConfirmationStatus}
            hide={() => {
              setShowCDialog(!showCDialog);
            }}
          />
        )}
        {showProgress && (
          <ProgressBar
            isShowing={showProgress}
            hide={() => {
              setShowProgress(!showProgress);
            }}
          />
        )}
      </Paper>
    </PageContainer>
  );
}
