import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  Autocomplete,
  Chip,
  FormControl,
  Stack,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { ItemOwner } from '../types/UndersokelseTypes';
import ErrorMessage from '../../../utilities/ErrorMessage';
import { IntervjuerI, SelectedIntervjuerI } from '../types/ProjectTypes';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

interface UserCodeInputProps {
  users: ItemOwner[];
  selectedUsers: SelectedIntervjuerI[];
  setSelectedUsers: Dispatch<SetStateAction<SelectedIntervjuerI[]>>;
  showError: boolean;
  setShowError: Dispatch<SetStateAction<boolean>>;
  errorMessage: string;
  setErrorMessage: Dispatch<SetStateAction<string>>;
}

export default function UserCodeInput(props: UserCodeInputProps) {
  const {
    users,
    selectedUsers,
    setSelectedUsers,
    showError,
    setShowError,
    errorMessage,
    setErrorMessage
  } = props;
  const [inputValue, setInputValue] = useState<string>('');

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      return;
    }
    if (event.key === ' ') {
      event.preventDefault();
      const newUserCode = event.target.value.trim().toUpperCase();
      if (newUserCode) {
        const isValid = users.map((c) => c.brukerkode).includes(newUserCode);
        const chipData = {
          brukerId: users.find((u) => u.brukerkode === newUserCode)?.id,
          brukerkode: newUserCode,
          isValid: isValid
        };
        setSelectedUsers([...selectedUsers, chipData]);
        setInputValue('');
      }
    }
  };

  useEffect(() => {
    if (selectedUsers.some((user) => !user.isValid)) {
      setShowError(true);
      setErrorMessage('Feil koder er skrevet. Fjern koden og skriv rett kode.');
    }
  }, [selectedUsers]);

  return (
    <FormControl fullWidth>
      <Stack
        direction={'row'}
        spacing={2}
        alignItems="end"
        justifyContent="space-between"
      >
        <Typography>Brukerkoder</Typography>
        {!showError ? (
          <Typography variant="caption">
            Skriv inn korrekt brukerkode og trykk 'space'
          </Typography>
        ) : (
          <ErrorMessage message={errorMessage} showing={showError} />
        )}
      </Stack>
      <Autocomplete
        multiple
        options={[]}
        freeSolo
        value={selectedUsers}
        onChange={(event, newValue) => {
          setSelectedUsers(newValue as SelectedIntervjuerI[]);
          setShowError(false);
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        // disable the options pop-up
        filterOptions={(options, params) => {
          return [];
        }}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              size="small"
              label={option.brukerkode}
              {...getTagProps({ index })}
              icon={
                option.isValid ? (
                  <CheckCircleIcon color="success" />
                ) : (
                  <ErrorOutlineIcon color="error" />
                )
              }
              sx={{
                maxHeight: '1.3rem'
              }}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            placeholder="Gyldige brukerkoder"
            onKeyDown={handleKeyDown}
          />
        )}
      />
    </FormControl>
  );
}
