import React, { useEffect, useState } from 'react';
import {
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Table,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  Grid,
  Skeleton,
  Stack,
  InputLabel,
  CircularProgress,
  Chip,
  FormGroup,
  Checkbox,
  IconButton,
  Box
} from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditIcon from '@mui/icons-material/Edit';
import { useKeycloak } from '@react-keycloak/web';
import useStylesTable from '../../../css/TableCss';
import PageContainer from '../home/PageContainer';
import { Dropdown, MonoSearchField, NButton } from '../components/Inputs';
import useFetchData from '../../utilities/useFetchData';
import { KeycloakUserDetail, RoleDetail, UserDetailI, UserRole } from './AdminTypes';
import DeleteDialog from '../kosthold/components/DeleteDialog';
import { ProgressBar } from '../../utilities/ProgressBar';
import AlertComp from '../../utilities/AlertComp';
import { AlertI } from '../../../types/alertTypes';
import { isObjectInArray } from '../../utilities/ArrayFunctions';
import { useUserContext } from '../../../context/AuthContext';
import { EnhancedTableHead } from '../../utilities/EnhancedTableHead';
import { HeadCell } from '../components/commons/CommonTypes';

export const Brukerliste = () => {
  const { keycloak } = useKeycloak();
  const token = keycloak?.token;
  const loggedInuser = useUserContext();
  const [users, setUsers] = React.useState<UserDetailI[]>([]);
  let [keycloakUsers] = React.useState<KeycloakUserDetail[]>([]);
  const [filteredRows, setFilteredRows] = useState<UserDetailI[]>([]);
  const [availableRoles, setAvailableRoles] = useState<RoleDetail[]>([]);
  const [selectedUser, setSelectedUser] = React.useState<UserDetailI>();
  const [transferToUser, setTransferToUser] = React.useState<UserDetailI>();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [open, setOpen] = React.useState(false);
  const [openSelectUser, setOpenSelectUser] = React.useState(false);
  const [openSelectRole, setOpenSelectRole] = React.useState(false);
  const [reload, setReload] = React.useState(true);
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = React.useState<string>('');
  const [alertSeverity, setAlertSeverity] = React.useState<AlertI['alertColor']>('info');
  const [title] = React.useState('Brukerliste - Nutrifoodcalc');
  const [checkedItems, setCheckedItems] = React.useState<
    { role: RoleDetail; state?: boolean }[]
  >([]);
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = useState<keyof UserDetailI>('brukerkode');
  const defaultRoles = ['default-roles-nfc-test2', 'default-roles-nfc-prod'];
  const classesTable = useStylesTable();
  document.title = title;
  const allUsers = useFetchData({
    loadOnMount: false,
    clearDataOnLoad: false,
    projectURL: 'getAllUsersWithDetails',
    subProjectURL: null
  });
  useEffect(() => {
    if (reload) {
      getKeycloakUsers();
      setReload(false);
    }
  }, [reload]);
  useEffect(() => {
    if (allUsers.data) {
      const data: UserDetailI[] = allUsers.data as unknown as UserDetailI[];
      if (data?.length > 0) {
        const updatedRows: UserDetailI[] = data?.map((d: UserDetailI) => {
          return {
            ...d,
            hasRole: checkRole(d.brukerkode),
            isKeycloakUser: checkKcUser(d.brukerkode)
          };
        });
        setUsers(updatedRows);
        setFilteredRows(updatedRows);
      }
    }
  }, [allUsers.data]);
  const apiCall = (uri: string, method: string, body?: any) => {
    const fullUri = `${process.env.REACT_APP_BACKEND}/${uri}`;
    return fetch(encodeURI(fullUri), {
      method: method,
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      body: body ? JSON.stringify(body) : undefined
    });
  };
  const keycloakApiCall = (uri: string, method: string, body?: any) => {
    const fullUri = `${process.env.REACT_APP_KEYCLOAK}/${uri}`;
    return fetch(encodeURI(fullUri), {
      method: method,
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      body: body ? JSON.stringify(body) : undefined
    });
  };
  const getKeycloakUsers = async () => {
    //gets all realm users
    keycloakApiCall(`admin/realms/${process.env.REACT_APP_REALM}/users`, 'GET').then(
      async (r) => {
        if (r.ok) {
          const users = await r.json();
          if (users?.length > 0) {
            for (const user of users) {
              const userExists = keycloakUsers?.find((u) => u.id === user.id);
              if (!userExists) {
                let kUser: KeycloakUserDetail = {
                  id: user.id,
                  username: user.username?.split('@')[0].toUpperCase() || '',
                  roles: []
                };
                keycloakUsers?.push(kUser);
              }
            }
            await getAVailableRoles();
          } else {
            showAlertMessage(
              'Det har oppstått en feil. Vennligst prøv igjen senere.',
              'error'
            );
          }
        } else {
          showAlertMessage(
            'Det har oppstått en feil. Vennligst prøv igjen senere.',
            'error'
          );
        }
        setShowProgress(false);
      }
    );
  };

  const getAVailableRoles = async () => {
    let allRoles: RoleDetail[] = [];
    //gets all realm roles
    keycloakApiCall(`admin/realms/${process.env.REACT_APP_REALM}/roles`, 'GET').then(
      async (r) => {
        if (r.ok) {
          const roles = await r.json();
          roles?.map((r) => {
            if (Object.values(UserRole)?.includes(r.name)) {
              allRoles.push({
                id: r.id,
                name: r.name
              });
            }
          });
          setAvailableRoles(allRoles);
          await fetchUserRoles();
        } else {
          showAlertMessage(
            'Det har oppstått en feil. Vennligst prøv igjen senere.',
            'error'
          );
        }
      }
    );
  };
  const fetchUserRoles = async () => {
    for (const kcUser of keycloakUsers) {
      //gets roles of a user by id
      keycloakApiCall(
        `admin/realms/${process.env.REACT_APP_REALM}/users/${kcUser.id}/role-mappings`,
        'GET'
      ).then(async (r) => {
        if (r.ok) {
          const rm = await r.json();
          kcUser.roles = rm?.realmMappings?.map((r) => ({ id: r.id, name: r.name }));
        } else {
          showAlertMessage(
            'Det har oppstått en feil. Vennligst prøv igjen senere.',
            'error'
          );
        }
      });
    }
    allUsers.loadData('getAllUsersWithDetails', null);
  };

  const deleteFromkeycloak = async (username: string) => {
    const user = keycloakUsers?.find((ku: KeycloakUserDetail) =>
      ku.username?.includes(username)
    );
    const id = user?.id;
    if (id === undefined || id === '') {
      showAlertMessage('Bruker konto finnes ikke i keycloak!', 'warning');
    } else {
      //Deletes a user with id
      keycloakApiCall(
        `admin/realms/${process.env.REACT_APP_REALM}/users/${id}`,
        'DELETE'
      ).then((r) => {
        if (r.ok) {
          showAlertMessage('Slettingen er fullført!', 'success');
        } else {
          showAlertMessage('Slettingen mislyktes!', 'error');
        }
      });
    }
    allUsers.loadData('getAllUsersWithDetails', null);
    setShowProgress(false);
  };
  const logout = (roles: string[]) => {
    const kcLogoutOptions = {
      redirectUri: process.env.REACT_APP_FRONTEND + '/login'
    };
    if (
      roles?.includes(UserRole.Akf) &&
      selectedUser?.brukerkode === loggedInuser?.user?.username
    ) {
      keycloak?.logout(kcLogoutOptions);
    }
  };
  const handleEditRole = async (
    checkedItems: { role: RoleDetail; state?: boolean }[]
  ) => {
    console.log('user roles: ', selectedUser);
    //assigns a role to the user
    const kId = keycloakUsers?.find((ku) => ku.username === selectedUser?.brukerkode)?.id;
    if (kId === '') {
      console.log('Error in getting keycloak user id.');
      return;
    }
    for (const { role } of checkedItems.filter(
      (a) =>
        a.state === false &&
        getUserRoles(selectedUser?.brukerkode)?.find((b) => b.id === a.role.id)
    )) {
      const body = [
        {
          id: role.id || availableRoles[0]?.id,
          name: role.name || availableRoles[0]?.name
        }
      ];
      if (selectedUser) {
        await keycloakApiCall(
          `admin/realms/${process.env.REACT_APP_REALM}/users/${kId}/role-mappings/realm`,
          'DELETE',
          body
        ).then((r) => {
          if (r.ok) {
            setReload(true);
            showAlertMessage('Rolle fjerningen er fullført.', 'success');
            console.log('Role is unassigned.');
            //logout();
          } else {
            showAlertMessage('Rolle fjerningen mislyktes.', 'error');
            console.log('Error in unassigning role. ');
          }
        });
      }
    }
    for (const { role } of checkedItems.filter(
      (a) =>
        a.state === true &&
        !getUserRoles(selectedUser?.brukerkode)?.find((b) => b.id === a.role.id)
    )) {
      if (selectedUser) {
        const body = [
          {
            id: role.id || availableRoles[0]?.id,
            name: role.name || availableRoles[0]?.name
          }
        ];
        //assigns a realm role to a user by id
        await keycloakApiCall(
          `admin/realms/${process.env.REACT_APP_REALM}/users/${kId}/role-mappings/realm`,
          'POST',
          body
        ).then((r) => {
          if (r.ok) {
            setReload(true);
            showAlertMessage('Rolle tildelingen er fullført.', 'success');
            console.log('Role is assigned.');
            //if assigned role is akf, force logout
          } else {
            showAlertMessage('Rolle tildelingen mislyktes.', 'error');
            console.log('Error in assigning role.');
          }
        });
      }
    }
    logout(checkedItems.map((i) => i.role.name));

    setCheckedItems([]);
    handleCloseSelectRole();
  };
  const deleteWithDataTransfer = () => {
    handleCloseSelectUser();
    setShowProgress(true);
    const data = {
      userType: selectedUser?.type,
      username: selectedUser?.brukerkode,
      userId: selectedUser?.id,
      newUsername: transferToUser?.brukerkode,
      newUserId: transferToUser?.id
    };
    apiCall(`deleteUserWithDataTransfer`, 'DELETE', data).then((r) => {
      if (r.ok) {
        deleteFromkeycloak(selectedUser?.brukerkode);
      } else {
        setShowProgress(false);
        showAlertMessage('Slettingen mislyktes!', 'error');
      }
    });
  };
  const deleteUserAndData = () => {
    handleClose();
    setShowProgress(true);
    const data = {
      userType: selectedUser?.type,
      username: selectedUser?.brukerkode,
      userId: selectedUser?.id
    };
    apiCall(`deleteUserAndData`, 'DELETE', data).then((r) => {
      if (r.ok) {
        deleteFromkeycloak(selectedUser?.brukerkode);
      } else {
        setShowProgress(false);
        showAlertMessage('Slettingen mislyktes!', 'error');
      }
    });
  };
  const handleDeleteInActive = (id: number, brukernavn: string) => {
    setShowProgress(true);
    const data = {
      username: brukernavn,
      userId: id
    };
    apiCall(`deleteInactiveUser`, 'DELETE', data).then((r) => {
      if (r.ok) {
        setShowProgress(false);
        showAlertMessage('Slettingen er fullført!', 'success');
      } else {
        setShowProgress(false);
        showAlertMessage('Slettingen mislyktes!', 'error');
      }
    });
  };
  const getUserRoles = (username: string) => {
    return keycloakUsers?.find((ku) => ku.username === username)?.roles;
  };
  const checkRole = (username: string) => {
    const foundObject = getUserRoles(username)?.some((obj2) =>
      isObjectInArray(obj2, availableRoles)
    );
    return foundObject;
  };
  const checkKcUser = (username: string) => {
    const found = keycloakUsers?.findIndex((ku) => ku.username === username);
    return found > -1;
  };
  const handleUserChange = (user: UserDetailI) => {
    const selected = users?.find((u: UserDetailI) => u.id === user?.id);
    setTransferToUser(selected);
  };
  const handleSearch = (text: string) => {
    if (text === '' && filteredRows?.length !== users?.length) {
      setReload(true);
    } else {
      const searchText = text.toLowerCase();
      const filteredByNavn = searchText
        ? users?.filter((row) => {
            return (
              row.navn.toLowerCase().includes(text.toLowerCase()) ||
              row.brukerkode.toLowerCase().includes(text.toLowerCase())
            );
          })
        : users;
      filteredByNavn?.length === 0
        ? setFilteredRows([])
        : setFilteredRows(filteredByNavn);
      setPage(0);
    }
  };
  const showAlertMessage = (message: string, severity: any) => {
    setShowAlert(true);
    setAlertMessage(message);
    setAlertSeverity(severity);
  };
  const handleCloseSelectUser = () => {
    setOpenSelectUser(false);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpenSelectUser = () => {
    handleClose();
    setOpenSelectUser(true);
  };
  const handleClickOpenSelectRole = () => {
    setOpenSelectRole(true);
  };
  const handleCloseSelectRole = () => {
    setOpenSelectRole(false);
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleRequestSort = (property: keyof UserDetailI) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedRows = [...filteredRows].sort((a, b) => {
    if (b[orderBy] < a[orderBy]) return order === 'asc' ? -1 : 1;
    if (b[orderBy] > a[orderBy]) return order === 'asc' ? 1 : -1;
    return 0;
  });

  const headCells: HeadCell<UserDetailI>[] = [
    { id: 'id', label: 'Id', align: 'left' },
    { id: 'brukerkode', label: 'Brukernavn', align: 'left' },
    { id: 'navn', label: 'Navn', align: 'left' },
    { id: 'epost', label: 'Epost adresse', align: 'left' },
    { id: 'numberFrom', label: 'Nummerområde', align: 'left' },
    { id: 'numberTo', label: 'Rolle', align: 'left' },
    { id: 'type', label: 'Rediger rolle', align: 'left' },
    { id: 'activ', label: '', align: 'left' }
    //we don't need type on the table, so we use the column to display user roles
    //we don't need activ on the table, so we use the column for delete btn
  ];
  const displayRoles = (
    roles: RoleDetail[],
    changes?: { role: RoleDetail; state?: boolean }[]
  ) => {
    return roles.map((role) => {
      const changed = changes?.find((a) => a.role.id === role.id);
      const checked = changed ? changed?.state === true : undefined;
      const userHasRole = Boolean(
        getUserRoles(selectedUser?.brukerkode)?.find((a) => a.id === role.id)
      );
      return (
        <Box style={{ display: 'flex', alignItems: 'center', fontFamily: 'arial' }}>
          <Checkbox
            sx={{ padding: 1, marginX: 1 }}
            checked={checked ?? userHasRole}
            onChange={(e) =>
              handleNodeSelect(role, Boolean(changed) ? undefined : !userHasRole)
            }
            color="primary"
          />
          {role.name}
        </Box>
      );
    });
  };
  const handleNodeSelect = (role: RoleDetail, state?: boolean) => {
    setCheckedItems(
      [
        ...checkedItems.filter((a) => a.role.id !== role.id),
        {
          role: {
            id: role.id,
            name: role.name
          },
          state: state
        }
      ].filter((a) => a.state !== undefined)
    );
  };
  return (
    <PageContainer>
      <Paper style={{ padding: '1.5em 15px 1.5em 15px', boxShadow: 'none' }}>
        <Typography variant="h4">Brukerliste</Typography>
        <Grid container spacing={2}>
          <Grid item container spacing={2}>
            <Grid item xs={6} container alignItems="flex-end">
              <MonoSearchField
                header="Søk etter navn/brukernavn i brukerliste"
                searchFn={(n) => handleSearch(n)}
              />
            </Grid>
            <Grid item xs={6} container alignItems="flex-end" justifyContent={'center'}>
              <Stack direction="column" spacing={1}>
                <InputLabel sx={{ fontWeight: 'bold', m: 1 }}>
                  Antall brukere på KBS: {users.length}
                </InputLabel>
                <InputLabel sx={{ fontWeight: 'bold', m: 1 }}>
                  Aktiv brukere på NFC: {keycloakUsers.length}
                </InputLabel>
              </Stack>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {!allUsers.isLoading || users?.length < 1 ? (
              <>
                <Table size="medium" sx={{ border: '1px solid black' }}>
                  <EnhancedTableHead<UserDetailI>
                    headCells={headCells}
                    sorting={{
                      order: order,
                      orderBy: orderBy,
                      onRequestSort: handleRequestSort
                    }}
                  />
                  <TableBody sx={classesTable.body}>
                    {sortedRows
                      ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      ?.map((row) => (
                        <TableRow key={row.id}>
                          <TableCell>{row.id}</TableCell>
                          <TableCell>{row.brukerkode}</TableCell>
                          <TableCell>{row.navn}</TableCell>
                          <TableCell>{row.epost}</TableCell>
                          <TableCell>
                            {row.numberFrom} - {row.numberTo}
                          </TableCell>
                          <TableCell>
                            {getUserRoles(row.brukerkode)?.map((r) =>
                              !defaultRoles?.includes(r.name) ? (
                                <Chip
                                  label={r.name}
                                  size="small"
                                  sx={{
                                    backgroundColor:
                                      r.name === 'akf' ? 'lightGreen' : 'lightBlue',
                                    margin: '1px'
                                  }}
                                />
                              ) : null
                            )}
                          </TableCell>
                          <TableCell>
                            <IconButton
                              children={<EditIcon />}
                              onClick={() => {
                                setSelectedUser(row);
                                setCheckedItems([]);
                                handleClickOpenSelectRole();
                              }}
                              disabled={!row.isKeycloakUser}
                            />
                          </TableCell>
                          <TableCell>
                            {row.activ === 'J' ? (
                              <NButton
                                children="Slett"
                                rightIcon={<DeleteOutlineOutlinedIcon />}
                                variant="text"
                                disabled={row.id === 28} //admin user
                                onClick={() => {
                                  setOpen(true);
                                  setSelectedUser(row);
                                }}
                              />
                            ) : (
                              <DeleteDialog
                                title="Slett bruker?"
                                message="Brukern er allerede slettet (ikke aktivt)! Vil du slette raden i tilgangstabellen?"
                                deleteFn={() =>
                                  handleDeleteInActive(row.id, row.brukerkode)
                                }
                              />
                            )}
                          </TableCell>{' '}
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
                <TablePagination
                  sx={classesTable.Pagination}
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={filteredRows?.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  showFirstButton={true}
                  showLastButton={true}
                />
              </>
            ) : (
              <>
                <CircularProgress />
                <Skeleton variant="rectangular" height="10em" />
              </>
            )}
          </Grid>
        </Grid>
        {showProgress && (
          <ProgressBar
            isShowing={showProgress}
            hide={() => {
              setShowProgress(!showProgress);
            }}
          />
        )}
        {showAlert && (
          <AlertComp
            alertMessage={alertMessage}
            alertColor={alertSeverity}
            showAlert={showAlert}
            setShowAlert={setShowAlert}
          />
        )}
        <Dialog
          maxWidth="xs"
          fullWidth
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Slett bruker</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Alle data eid av bruker {selectedUser?.brukerkode} vil bli slettet.
              <br />
              Vil du overføre dem til en annen bruker?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Stack direction="row" spacing={1}>
              <NButton
                children="Ja, overfør"
                onClick={() => handleClickOpenSelectUser()}
              />
              <NButton children="Nei, slett" onClick={() => deleteUserAndData()} />
              <NButton children="Avbryt" onClick={() => handleClose()} />
            </Stack>
          </DialogActions>
        </Dialog>
        <Dialog
          maxWidth="xs"
          fullWidth
          open={openSelectUser}
          onClose={handleCloseSelectUser}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Velg bruker</DialogTitle>
          <DialogContent>
            <Grid item xs={12} container spacing={2} alignItems="center">
              <Grid item xs={12}>
                <Dropdown
                  header=""
                  value={transferToUser}
                  options={users}
                  optionLabel={(o) => o.brukerkode}
                  onChange={(v) => handleUserChange(v as UserDetailI)}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Stack direction="row" spacing={1}>
              <NButton
                children="Overfør og Slett"
                disabled={transferToUser?.id === undefined}
                onClick={() => deleteWithDataTransfer()}
              />
              <NButton children="Avbryt" onClick={() => handleCloseSelectUser()} />
            </Stack>
          </DialogActions>
        </Dialog>
        <Dialog
          maxWidth="xs"
          fullWidth
          open={openSelectRole}
          onClose={handleCloseSelectRole}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Rediger rolle</DialogTitle>
          <DialogContent>
            <Grid item xs={12} container spacing={2} alignItems="center">
              <Grid item xs={12}>
                <FormGroup>{displayRoles(availableRoles, checkedItems)}</FormGroup>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Stack direction="row" spacing={1}>
              <NButton
                children={'Lagre'}
                disabled={Object.keys(checkedItems).every((key) => !checkedItems[key])}
                onClick={() => handleEditRole(checkedItems)}
              />
              <NButton
                children="Avbryt"
                variant="text"
                onClick={() => handleCloseSelectRole()}
              />
            </Stack>
          </DialogActions>
        </Dialog>
      </Paper>
    </PageContainer>
  );
};
