import React, { useContext, useState } from 'react';
import { databaseOptions } from '../assets/DatabaseOptions';
import { OptionType } from '../components/pages/components/commons/CommonTypes';

export function returnInitialState(storageKey: string) {
  try {
    // Get from local storage by key
    const item = window.localStorage.getItem(storageKey);
    // Parse stored json or if none return an empty object
    return item ? JSON.parse(item) : {};
  } catch (error) {
    // If error also return an empty object
    console.log(error);
    return {};
  }
}

export function useLocalStorage(storageKey: string) {
  const [currentDatabase, setStoredValue] = useState(returnInitialState(storageKey));

  const setCurrentDatabase = (value) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(currentDatabase) : value;
      // Save to local storage
      window.localStorage.setItem(storageKey, JSON.stringify(valueToStore));
      // Save state
      setStoredValue(valueToStore);
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };
  return [currentDatabase, setCurrentDatabase];
}

export type Database = {
  currentDatabase: OptionType;
  setCurrentDatabase: (value: OptionType) => void;
};
const initialValue: Database = {
  currentDatabase: {
    label: databaseOptions[0]?.label || '',
    value: databaseOptions[0]?.value || 0
  },
  setCurrentDatabase: () => {}
};
export const DatabaseContext = React.createContext<Database>(initialValue);
export const useDatabaseContext = () => useContext(DatabaseContext);
