import React from 'react';
import { Paper, Container, Box } from '@mui/material';

export default function PageContainer(props: any) {
  return (
    <Box
      style={{
        width: '100%',
        minHeight: 'calc(95vh - 12.5rem)',
        marginTop: '8.7rem',
        backgroundColor: '#FAFAFA'
      }}
    >
      <Container maxWidth={props.size || 'xl'}>
        <Paper style={{ padding: '1.5em 15px 1.5em 15px', boxShadow: 'none' }}>
          {props.children}
        </Paper>
      </Container>
    </Box>
  );
}
