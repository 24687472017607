import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { useFetchNonGET } from '../../utilities/useFetchNonGET';
import { useHistory } from 'react-router';
import useFetchData from '../../utilities/useFetchData';
import { AlertI } from '../../../types/alertTypes';
import { SingleStoff, StoffI } from './types/StoffTypes';
import { CountResult } from '../kosthold/types/ProjectTypes';
import DeleteDialog from '../kosthold/components/DeleteDialog';
import { ConfirmationDialog } from '../../utilities/ConfirmationDialog';
interface DeleteStoffProps {
  databaseId: number;
  stoffData: StoffI;
  openSletteStoff: boolean;
  setOpenSletteStoff: (val: boolean) => void;
  showErrorAlert: (message: string, severity: AlertI['alertColor']) => void;
  setShowProgress: (val: boolean) => void;
  postData: any;
}
export default function DeleteStoff(props: DeleteStoffProps) {
  const history = useHistory();
  const backendURI = process.env.REACT_APP_BACKEND;
  const [confirmed, setConfirmed] = useState(false);
  const [showCDialog, setShowCDialog] = React.useState(false);

  const countStoffsInStoffkomponent = useFetchData({
    loadOnMount: true,
    clearDataOnLoad: false,
    projectURL: 'countStoffInSkAndTilgang',
    subProjectURL: `${props.databaseId}/${props.stoffData.stoffnrId}`
  });

  useEffect(() => {
    if (countStoffsInStoffkomponent.isLoaded) {
      if (countStoffsInStoffkomponent.data) {
        const data: CountResult =
          countStoffsInStoffkomponent.data as unknown as CountResult;
        if (data?.COUNT > 0) {
          props.showErrorAlert(
            'Du kan ikke slette dette stoffet fordi det inneholder underliggende informasjon!',
            'error'
          );
          props.setOpenSletteStoff(false);
        } else {
          setShowCDialog(true);
        }
      }
    }
  }, [countStoffsInStoffkomponent.isLoaded]);
  useEffect(() => {
    if (confirmed) {
      setConfirmed(false);
      deleteStoff();
    }
  }, [confirmed]);
  const deleteStoff = async () => {
    let url = `${backendURI}/deleteStoff/${props.databaseId}/${props.stoffData?.stoffnrId}`;
    try {
      props.postData({ data: [] }, url, 'DELETE').then((resp) => {
        if (resp.status === 200) {
          props.showErrorAlert('Slettingen er fullført', 'success');
          const timer = setTimeout(() => {
            history.push('/home/matvaredata/stoff');
          }, 1000);
          return () => clearTimeout(timer);
        } else {
          props.showErrorAlert(
            'Slettingen mislyktes. Det har ikke blitt gjort endringer mot databasen.',
            'error'
          );
        }
      });
    } catch (error) {
      props.showErrorAlert(
        'Det har oppstått en feil. Vennligst prøv igjen senere.',
        'error'
      );
    }
  };

  return (
    <>
      {showCDialog ? (
        <ConfirmationDialog
          title="Slett Stoff"
          text="Vil du slette dette stoffet? Stoffet vil automatisk bli fjernet fra eventuelle stoffgrupper."
          setConfirmationResult={setConfirmed}
          hide={() => setShowCDialog(false)}
        />
      ) : null}
    </>
  );
}
