import React from 'react';
import { Grid } from '@mui/material';
import { MatvareData } from '../types/MatvareTypes';
import { InputFields } from '../../components/InputFields';

interface EnglishNamesProps {
  classes: any;
  matvareData: MatvareData;
  handleMatvareForm: (name: string, value: number | string) => void;
  owned: boolean;
}
export default function EnglishNameOgBeskrivelse(props: EnglishNamesProps) {
  const { classes, matvareData, handleMatvareForm, owned } = props;
  return (
    <Grid container spacing={1}>
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={4}>
          <InputFields
            label={'Engelsk navn*'}
            classes={classes}
            id={'Engelsknavn'}
            name={'NAVN_ENGELSK'}
            value={matvareData?.NAVN_ENGELSK}
            type={'text'}
            handleChange={handleMatvareForm}
            required={true}
            owned={owned}
            //disabled={!owned}
          />
        </Grid>
        <Grid item xs={5}>
          <InputFields
            label={'MVT-navn'}
            classes={classes}
            id={'MVTNavn'}
            name={'OFFISIELT_NAVN'}
            value={matvareData?.OFFISIELT_NAVN}
            type={'text'}
            handleChange={handleMatvareForm}
            owned={owned}
            //required={true}
            //disabled={!owned}
          />
        </Grid>
        <Grid item xs={3}>
          <InputFields
            label={'MVT-nr'}
            classes={classes}
            id={'Senr'}
            name={'SENR'}
            value={matvareData?.SENR}
            type={'tel'}
            handleChange={handleMatvareForm}
            owned={owned}
            //disabled={!owned}
          />
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={12}>
          <InputFields
            label={'Søkeord*'}
            classes={classes}
            id={'sokeord'}
            name={'SOKEORD'}
            value={matvareData?.SOKEORD}
            type={'text'}
            handleChange={handleMatvareForm}
            required={true}
            maxLength={true}
            multiline
            Min={1}
            MaxLength={2000}
            rows={'3'}
            rowsMax={'5'}
            owned={owned}
            //disabled={!owned}
          />
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={6}>
          <InputFields
            label={'Beskrivelse*'}
            classes={classes}
            id={'beskrivelse'}
            name={'BESKRIVELSE'}
            value={matvareData?.BESKRIVELSE}
            type={'text'}
            handleChange={handleMatvareForm}
            maxLength={true}
            Min={1}
            MaxLength={2000}
            multiline
            rows={'3'}
            rowsMax={'5'}
            required={true}
            owned={owned}
            //disabled={!owned}
          />
        </Grid>
        <Grid item xs={6}>
          <InputFields
            label={'Beskrivelse engelsk*'}
            classes={classes}
            id={'beskrivelseEngelsk'}
            name={'BESKRIVELSE_ENGELSK'}
            value={matvareData?.BESKRIVELSE_ENGELSK}
            type={'text'}
            required={true}
            handleChange={handleMatvareForm}
            maxLength={true}
            multiline
            Min={1}
            MaxLength={2000}
            rows={'3'}
            rowsMax={'5'}
            owned={owned}
            //disabled={!owned}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
