import React, { useEffect } from 'react';
import { BeregningObject, ReportFourI } from '../../types/BeregningTypes';
import useDidMountEffect from '../../../../utilities/useDidMountEffect';

const report4Statistics = [
  { DBNAME: 'GJSNITT', NAME: 'Gj.snitt' },
  { DBNAME: 'STDAVV', NAME: 'Std.avvik' },
  { DBNAME: 'MAX', NAME: 'Max' },
  { DBNAME: 'MIN', NAME: 'Min' },
  { DBNAME: 'PERS05', NAME: 'Persentil 5' },
  { DBNAME: 'PERS25', NAME: 'Persentil 25' },
  { DBNAME: 'PERS50', NAME: 'Persentil 50' },
  { DBNAME: 'PERS75', NAME: 'Persentil 75' },
  { DBNAME: 'PERS90', NAME: 'Persentil 90' },
  { DBNAME: 'PERS95', NAME: 'Persentil 95' },
  { DBNAME: 'PERS975', NAME: 'Persentil 97,5' }
];
interface ReportFourProps {
  beregningObject: BeregningObject;
  setReportData: (value: any[]) => void;
  setReportInfo: (value: Array<Array<string>>) => void;
  setErrorReportId: (value: number) => void;
  result: any;
}
export const Report4Result = (props: ReportFourProps) => {
  const [data] = React.useState([]);
  const [info] = React.useState<Array<Array<string>>>([]);
  const { beregningObject, setReportData, setReportInfo, setErrorReportId, result } =
    props;
  useEffect(() => {
    if (result) {
      console.log(' Beregning result.', 'success');
      const data = result as unknown as ReportFourI;
      if (
        data?.reportInfo !== undefined &&
        data?.reportInfo?.beregningInfo !== undefined &&
        data?.mengdes !== undefined &&
        data?.stoffs !== undefined
      ) {
        prepareResult(data);
      }
    }
  }, [result]);

  const prepareResult = (reportFourData: ReportFourI) => {
    let description: string[];
    setErrorReportId(reportFourData?.reportInfo?.reportId);
    for (let r of reportFourData?.reportInfo?.beregningInfo) {
      description = [];
      description.push(r.TEKSTLINJE);
      info.push(description);
    }
    setReportInfo(info);
    let result: any[] = [];
    const shortNames: any[] = [];
    const enhet: any[] = [];
    shortNames.push('Nr');
    shortNames.push('');
    enhet.push('');
    enhet.push('');

    for (let stoff of reportFourData?.stoffs) {
      shortNames.push(stoff.KORTNAVN);
      if (beregningObject?.matvareData?.enhet?.value === 6) {
        enhet.push(stoff.ENHET + '/10MG');
      } else {
        enhet.push(stoff.ENHET);
      }
    }
    data.push(shortNames);
    data.push(enhet);
    data.push(['']);
    data.push(['']);
    for (let s of report4Statistics) {
      result = [];
      result.push(s.NAME);
      result.push(s['']);
      for (let i = 0; i < reportFourData?.stoffs?.length; i++) {
        if (reportFourData?.mengdes?.length > 0) {
          result.push(reportFourData?.mengdes[i][s.DBNAME]);
        }
      }
      data.push(result);
    }
    setReportData(data);
  };

  return <></>;
};
