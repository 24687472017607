import React from 'react';
import Textarea from '@mui/joy/Textarea';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { FormControl, FormLabel, IconButton } from '@mui/material';
import { selectedOptionArrayI } from '../../types/BeregningTypes';
import {
  experimental_extendTheme as materialExtendTheme,
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
  THEME_ID as MATERIAL_THEME_ID
} from '@mui/material/styles';
import { CssVarsProvider as JoyCssVarsProvider } from '@mui/joy/styles';

const materialTheme = materialExtendTheme();
interface DisplayActivatedOptionsProps {
  filterCriteria: string[];
  updateSelectedOptionsOnDelete: (value: selectedOptionArrayI[]) => void;
  activatedOptions: selectedOptionArrayI[];
  setFilterCriteria: (value: string[]) => void;
}
export const DisplayActivatedOptions = (props: DisplayActivatedOptionsProps) => {
  const {
    filterCriteria,
    updateSelectedOptionsOnDelete,
    activatedOptions,
    setFilterCriteria
  } = props;
  const removeItem = (index: number) => {
    const newActivatedOptions = activatedOptions?.filter(
      (op) => op !== activatedOptions[index]
    );
    updateSelectedOptionsOnDelete(newActivatedOptions);
    const newFilterCriteria = filterCriteria?.filter(
      (op) => op !== filterCriteria[index]
    );
    setFilterCriteria(newFilterCriteria);
  };
  return (
    <>
      <MaterialCssVarsProvider theme={{ [MATERIAL_THEME_ID]: materialTheme }}>
        <JoyCssVarsProvider>
          <FormControl>
            <FormLabel>{filterCriteria?.length > 0 ? 'Liste over utvalg' : ''}</FormLabel>
            {filterCriteria?.map((value: string, index: number) => {
              return (
                <Textarea
                  key={`text-area-${index}`}
                  variant="outlined"
                  color="neutral"
                  maxRows={2}
                  value={value}
                  sx={{ minWidth: 600, alignItems: 'flex-end' }}
                  startDecorator={
                    <IconButton color="error" onClick={() => removeItem(index)}>
                      <HighlightOffIcon />
                    </IconButton>
                  }
                />
              );
            })}
          </FormControl>
        </JoyCssVarsProvider>
      </MaterialCssVarsProvider>
    </>
  );
};
