import { MatvaregruppeActionTypes, SET_MATVARELIST } from '../types/actions';
import { MatvareListType, MatvareType } from '../types/reduxMatvaregruppeTypes';

const matvareListReducerState: MatvareListType = {
  matvareList: []
};

export const matvaresInMatvaregruppeReducer = (
  state = matvareListReducerState,
  action: MatvaregruppeActionTypes
): MatvareListType => {
  switch (action.type) {
    case SET_MATVARELIST: {
      if (action.matvareList !== undefined) {
        for (const element of action.matvareList) {
          const index = state.matvareList?.findIndex(
            (mat: MatvareType) =>
              mat?.gruppetypeId === element?.gruppetypeId &&
              mat?.linkup === element?.linkup
          );

          if (index > -1) {
            const x = state.matvareList?.splice(index, 1);
            console.log(x);
          }
          if (element !== undefined) {
            const x = state.matvareList?.push(element);
            console.log(x);
          }
        }
      }
      return state;
    }
    default:
      return state;
  }
};
