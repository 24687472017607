import React, { useEffect, useState } from 'react';
import {
  Checkbox,
  Paper,
  Typography,
  Alert,
  FormControlLabel,
  Grid
} from '@mui/material';
import { ProgressBar } from '../../../utilities/ProgressBar';
import { UndersokelseList } from '../components/UndersokelseList';
import { useKeycloak } from '@react-keycloak/web';
import { ConfirmationDialog } from '../../../utilities/ConfirmationDialog';
import PageContainer from '../../home/PageContainer';
import AlertComp from '../../../utilities/AlertComp';
import { AlertI } from '../../../../types/alertTypes';
import { AlertWithAction } from '../../../utilities/AlertPopUp';
import { Text, NButton, Dropdown, GenericImport } from '../../components/Inputs';
import { NullstillUndersokelse } from './NullstillUndersokelse';
import ProjectInput from '../../components/ProjectInput';
import IsOwnedByUser from '../../../utilities/Validate';
import { useUserContext } from '../../../../context/AuthContext';
import PollingComponent from '../../components/commons/PollingComponent';
import { CountResult } from '../types/ProjectTypes';
import { useProjectContext } from '../../../../context/ProjectContext';
import { FetchProjectandUndersokelse } from '../components/FetchProjectandUndersokelse';

interface personStatus {
  NR: number;
  NAVN: string;
}
const personStatusValue = [
  { NR: 0, NAVN: '' },
  { NR: 1, NAVN: 'Ikke påbegynt' },
  { NR: 2, NAVN: 'Delintervjuet' },
  { NR: 3, NAVN: 'Etterarbeid' },
  { NR: 4, NAVN: 'Alle intervj. ferdig' },
  { NR: 5, NAVN: 'Fullført' },
  { NR: 8, NAVN: 'Trukket seg' },
  { NR: 9, NAVN: 'Annet - notér' },
  { NR: 10, NAVN: 'Deltar ikke' }
];
export const ImportPersoner = () => {
  const { keycloak } = useKeycloak();
  const token = keycloak?.token;
  const loggedInuser = useUserContext();
  const [title] = useState('Import Personer');
  const [showProgress, setShowProgress] = useState(false);
  const [progressText, setProgressText] = useState('Importerer ...');
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState<AlertI['alertColor']>('info');
  const [showAlertWA, setShowAlertWA] = useState(false);
  const [projectId, setProjectId] = useState('');
  const [undersokelseId, setUndersokelseId] = useState('');
  const [refetchProject, setRefetchProject] = React.useState<boolean>(false);
  const [refetchSurvey, setRefetchSurvey] = React.useState<boolean>(false);
  const [showUndersokelseIdList, setShowUndersokelseIdList] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [personStatusOptions] = useState<personStatus[] | undefined>(personStatusValue);
  const [selectedPersonStatus, setSelectedPersonStatus] = useState<
    personStatus | undefined
  >();
  const [selectedLopedagStatus, setSelectedLopedagStatus] = useState<
    personStatus | undefined
  >();
  const [, setIsFilePicked] = useState(false);
  const [showCDialog, setShowCDialog] = useState(false);
  const [dialogText, setDialogText] = useState('');
  const [dialogTitle] = useState('NFC notification!');
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [fileContent, setFileContent] = useState<string>(null);
  const [antallLopedager, setAntallLopedager] = useState(0);
  const [personInfo, setPersonInfo] = useState(false);
  const [personCount, setPersonCount] = useState<number>(0);
  const [importResult, setImportResult] = React.useState<CountResult>({ COUNT: 0 });
  const [pollingId, setPollingId] = React.useState('');
  const projs = useProjectContext();
  const baseUrl = process.env.REACT_APP_BACKEND;
  const [failed, setFailed] = React.useState(false);

  document.title = title;

  useEffect(() => {
    if (importResult?.COUNT > 0) {
      setShowProgress(false);
      setRefetchSurvey(true);
      showAlertMessage(importResult?.COUNT + ' personer importert.', 'success');
    }
  }, [importResult?.COUNT]);
  useEffect(() => {
    if (failed) {
      setShowProgress(false);
      showAlertMessage('Importering av personer mislyktes.', 'error');
    }
  }, [failed]);

  async function postData(data: any, url: string, methods: string) {
    let response = await fetch(encodeURI(url), {
      method: methods,
      headers: {
        Authorization: 'Bearer ' + token
      },
      body: JSON.stringify(data)
    });
    return response;
  }

  const showAlertMessage = (message: string, severity: any) => {
    setShowAlert(true);
    setAlertMessage(message);
    setAlertSeverity(severity);
  };
  const showAlertMessageWA = (message: string, severity: any) => {
    setShowAlertWA(true);
    setAlertMessage(message);
    setAlertSeverity(severity);
  };
  const changeHandler = async (file: File) => {
    console.log(file);
    setSelectedFile(file?.name);
    console.log('file size...', file?.size);
    setIsFilePicked(true);
    if (file) {
      await file
        .text()
        .then((value) => {
          setFileContent(value);
        })
        .catch((err: any) => {
          console.error(err);
        });
    }
  };
  const checkForPreviousImport = () => {
    if (projectId && undersokelseId) {
      fetchData('checkTempImport', ``)
        .then((data) => {
          if (data?.COUNT > 0) {
            showDeleteConfirmation(
              '"Bruker ' +
                data?.BRUKER +
                ' importerer allerede personer.\n Startdato er ' +
                data?.DATO +
                '.\n \n  Vil du kanselere den eksisterende importen?'
            );
          } else {
            doImport();
          }
        })
        .catch((err) => {
          console.log('Error', err);
          showAlertMessage(
            'Det har oppstått en feil. Vennligst prøv igjen senere.',
            'error'
          );
        });
    }
  };
  const doImport = () => {
    setProgressText('Importerer...');
    setShowProgress(true);
    console.log('Importing .....');
    importPersons();
  };

  const importPersons = async () => {
    let url = `${baseUrl}/importPersons/${projectId}/${undersokelseId}/${selectedPersonStatus?.NR}/${antallLopedager}/${selectedLopedagStatus?.NR}/${personInfo}/${selectedFile}`;
    await postData(fileContent, url, 'POST')
      .then((resp) => {
        if (resp.status === 200) {
          console.log('successfully imported');
          resp.json().then((data) => {
            //Start pooling database
            setPollingId(data?.id);
          });
        } else if (resp.status === 500) {
          resp.json().then((data) => {
            console.log('Error happened while importing.');
            showAlertMessage(data, 'error');
          });
          setShowProgress(false);
        }
      })
      .catch((error) => {
        console.log(error);
        showAlertMessage(
          'Import av personer mislyktes. Det har ikke blitt gjort endringer i databasen',
          'error'
        );
        setShowProgress(false);
      });
  };
  async function fetchData(mainurl: string, secondurl: string) {
    const url = `${baseUrl}/${mainurl}/${secondurl ? secondurl : ''}`;
    const response = await fetch(encodeURI(url), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    });
    return response.json();
  }

  const handleOnChangeUndersokelse = (id: string) => {
    //getPersonCount(id);
    setPersonCount(
      projs.projects
        ?.find((p) => p.prosjektId === +projectId)
        ?.undersokelser?.find((u) => u.undersokelseId === +id)?.antallPersoner['ANTALL']
    );
    setUndersokelseId(id);
  };

  const setProject = (id: string) => {
    setProjectId(id);
    setUndersokelseId('');
  };

  const showDeleteConfirmation = (text: string) => {
    setDialogText(text);
    setShowCDialog(true);
  };
  useEffect(() => {
    if (confirmationResult) {
      deleteTempImport();
    } else {
      setShowProgress(false);
      return;
    }
  }, [confirmationResult]);

  const deleteTempImport = async () => {
    let url = `${baseUrl}/deleteTempImport`;
    await postData({}, url, 'DELETE')
      .then((resp) => {
        if (resp.status === 200) {
          console.log('Sletting av gammel import er fullført', 'success');
          doImport();
          setConfirmationResult(null);
        } else if (resp.status === 500) {
          console.log('Error happened while importing.');
          showAlertMessage('Feil ved sletting av gammel import!', 'error');
          setShowProgress(false);
          setConfirmationResult(null);
        }
      })
      .catch((error) => {
        console.log(error);
        showAlertMessage('Feil ved sletting av gammel import!', 'error');
        setConfirmationResult(null);
      });
  };

  const filteredProjectId = projs?.projects?.filter((row) => {
    return IsOwnedByUser({ id: +row.prosjektId, u: loggedInuser }) || null;
  });

  const clear = () => {
    setProject('');
    setFileContent(null);
    setSelectedFile('');
    setAntallLopedager(0);
    setSelectedPersonStatus(personStatusOptions[0]);
    setSelectedLopedagStatus(personStatusOptions[0]);
  };

  const helpOnClick = () => {
    showAlertMessageWA(
      `CSV-fil (semikolonseparert) med følgende felter (* angir obligatorisk felt):\n   *Personid\n  Identitet\n  Kjonn (kode)\n  Bostedtype (kode)\n   Landsdel (kode)\n  Fodselsaar (4 siffer)\n  Hoyde\n  Vekt\n  Notat
        Hvis 'Inkl. personopplysninger' er angitt, kan også følgende felter bli importert: 
        Fornavn\n  Mellomnavn\n  Slektsnavn\n  Adresselinje_1\n  Adresselinje_2\n  Postnr\n  Poststed\n  Fodeland\n  Telefonnr_1\n  Telefonnr_2\n  Mobilnr_1\n  Mobilnr_2\n  Mobilnr_3\n  Epost  
        Hvis ikke 'Inkl. personopplysninger' er angitt, blir ikke navn, addresse, mm. importert selv om feltene finnes i filen.   
        Filen skal inneholde en overskriftlinje (også separert med semikolon) med navn på de kolonner som er med i filen.  
        Rekkefølgen på feltene er likegyldig, men overskriftslinjen må stemme overens med datalinjene.`,
      'info'
    );
  };

  return (
    <PageContainer>
      <Paper
        style={{ padding: '1.5em 15px 1.5em 15px', minHeight: 715, boxShadow: 'none' }}
      >
        <Typography variant="h4" marginBottom="1em">
          Import av personer
        </Typography>
        <FetchProjectandUndersokelse
          projectId={+projectId}
          refetchProject={refetchProject}
          setRefetchProject={setRefetchProject}
          refetchSurvey={refetchSurvey}
          setRefetchSurvey={setRefetchSurvey}
        />
        {filteredProjectId?.length > 0 ? (
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h5">
                Steg 1: Velg prosjekt og undersøkelse til å importere
                kostholdsundersøkelse til
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <ProjectInput
                project={projectId}
                projects={filteredProjectId?.map((o) => o.prosjektId?.toString())}
                setProject={setProject}
                undersokelse={undersokelseId}
                setUndersokelse={handleOnChangeUndersokelse}
              />
            </Grid>
            <Grid item xs={3} />
            <Grid item xs={12}>
              <NullstillUndersokelse
                projectId={projectId}
                undersokelseId={undersokelseId}
                personCount={personCount}
                setPersonCount={setPersonCount}
                setShowProgress={setShowProgress}
                setProgressText={setProgressText}
                showAlertComponent={showAlertMessage}
                setRefetchSurvey={setRefetchSurvey}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h5" style={{ marginTop: '1.5em' }}>
                Steg 2: Sett parametere
              </Typography>
            </Grid>
            <Grid item xs={12} container spacing={2}>
              <Grid item xs={3}>
                <Dropdown
                  header="Personstatus *"
                  value={selectedPersonStatus || personStatusOptions[0]}
                  options={personStatusOptions}
                  optionLabel={(s) => s?.NAVN || ''}
                  onChange={(v) => setSelectedPersonStatus(v as personStatus)}
                  disabled={!undersokelseId}
                />
              </Grid>
              <Grid item xs={3}>
                <Text
                  header="Antall løpedager som skal opprettes *"
                  value={antallLopedager || 2}
                  onChange={(e) => setAntallLopedager(parseInt(e.target.value) || 2)}
                  disabled={!undersokelseId}
                  type="number"
                />
              </Grid>
              <Grid item xs={3}>
                <Dropdown
                  header="Løpedagstatus *"
                  value={selectedLopedagStatus || personStatusOptions[0]}
                  options={personStatusOptions}
                  optionLabel={(s) => s?.NAVN || ''}
                  onChange={(v) => setSelectedLopedagStatus(v as personStatus)}
                  disabled={!undersokelseId}
                />
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel
                  label="Inkl. personopplysninger (navn, adr.)"
                  control={
                    <Checkbox
                      color="primary"
                      checked={personInfo}
                      onChange={(e) => setPersonInfo(e.target.checked)}
                    />
                  }
                  disabled={!undersokelseId}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" style={{ marginTop: '1.5em' }}>
                Steg 3: Last opp en personfil
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <GenericImport
                help={helpOnClick}
                onChange={changeHandler}
                disabled={!undersokelseId}
                selected={selectedFile}
                accept=".csv"
              />
            </Grid>
            <Grid item xs={7} />

            <Grid item xs={12} container spacing={2} style={{ marginTop: '2em' }}>
              <Grid item xs={1}>
                <NButton
                  children="Import"
                  disabled={!selectedFile}
                  onClick={() => checkForPreviousImport()}
                />
              </Grid>
              <Grid item xs={1}>
                <NButton children="Nullstill" variant="outlined" onClick={clear} />
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <>
            <Alert severity="warning" sx={{ width: 'fit-content' }}>
              Du må eie i hvertfall et prosjekt!
            </Alert>
          </>
        )}

        {/* Dialogs */}
        {pollingId && (
          <PollingComponent
            id={pollingId}
            setFailed={setFailed}
            setResult={setImportResult}
          />
        )}
        {showUndersokelseIdList && (
          <UndersokelseList
            searchFeild={'undersøkelsesnummer'}
            showUndersokelseIdList={true}
            isShowing={showUndersokelseIdList}
            populateUndersokelse={setUndersokelseId}
            hide={() => setShowUndersokelseIdList(!showUndersokelseIdList)}
          />
        )}
        {showProgress && (
          <ProgressBar
            isShowing={showProgress}
            text={progressText}
            hide={() => {
              setShowProgress(!showProgress);
            }}
          />
        )}
        {showCDialog && (
          <ConfirmationDialog
            title={dialogTitle}
            text={dialogText}
            setConfirmationResult={setConfirmationResult}
            hide={() => {
              setShowCDialog(!showCDialog);
            }}
          />
        )}
        {showAlert && (
          <AlertComp
            alertMessage={alertMessage}
            alertColor={alertSeverity}
            showAlert={showAlert}
            setShowAlert={setShowAlert}
          />
        )}
        {showAlertWA && (
          <AlertWithAction
            alertMessage={alertMessage}
            alertColor={alertSeverity}
            showAlert={showAlertWA}
            setShowAlert={setShowAlertWA}
          />
        )}
      </Paper>
    </PageContainer>
  );
};
