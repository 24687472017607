import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import { Maaltidskomponent } from '../../types/BeregningTypes';

function Maaltidskomponenter(props: any) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const { maaltidkomponent } = props;
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    newPage: number
  ) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  return (
    <>
      <Typography>Måltidskomponenter</Typography>
      <Table className="DialogTable">
        <TableHead>
          <TableRow>
            <TableCell>Person</TableCell>
            <TableCell>Løpedag</TableCell>
            <TableCell>Måltidnr</TableCell>
            <TableCell>Antall</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {maaltidkomponent
            ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            ?.map((row: Maaltidskomponent) => (
              <TableRow hover role="checkbox" key={row.PERSONID}>
                <TableCell>{row.PERSONID}</TableCell>
                <TableCell>{row.LOPEDAGID}</TableCell>
                <TableCell>{row.MAALTIDNRID}</TableCell>
                <TableCell>{row.ANTALL}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count={maaltidkomponent ? maaltidkomponent?.length : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}
export default Maaltidskomponenter;
