import React, { useContext, useEffect, useState } from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TablePagination,
  Stack,
  Typography
} from '@mui/material';
import { useDispatch } from 'react-redux';
import * as actions from '../../../types/actions';
import { DatabaseContext } from '../../../context/DatabaseContext';
import useStylesTable from '../../../css/TableCss';
import useFetchData from '../../utilities/useFetchData';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { useKeycloak } from '@react-keycloak/web';
import PostData from '../../utilities/postData';
import { AlertI } from '../../../types/alertTypes';
import AlertComp from '../../utilities/AlertComp';
import { SingleInput } from './components/SingleInput';
import { resultatGruppeI } from './types/matvaregruppeTypes';
import { NButton, SlowText } from '../components/Inputs';
import { useUserContext } from '../../../context/AuthContext';
import { IsOwnedById } from '../../utilities/Validate';

export default function ResultgruppeMatvare(props: any) {
  const { openGrupper, setSelectedResultagruppe } = props;
  const dispatch = useDispatch();
  const { keycloak } = useKeycloak();
  const token = keycloak?.token;
  const backendURI = process.env.REACT_APP_BACKEND;
  const loggedInuser = useUserContext();
  const [resultatGruppe, setResultatGruppe] = useState<resultatGruppeI[]>([]);
  const [newResultatGruppe, setNewResultatGruppe] = useState<resultatGruppeI>({
    BESKRIVELSE: '',
    GRUPPETYPEID: 0,
    LANG_BESKRIVELSE: '',
    TILGANGSKODE: 0
  });
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState<AlertI['alertColor']>('info');
  const { currentDatabase } = useContext(DatabaseContext);
  const [selectedRow, setSelectedRow] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const classesTable = useStylesTable();
  const [comfirmDeletingResultGruppe, setComfirmDeletingResultGruppe] = useState(false);
  const [doseResultGruppeHaveUnderlayingInfo, setDoseResultGruppeHaveUnderlayingInfo] =
    useState(false);
  const [copyResultgruppe, setCopyResultgruppe] = useState({ NAVN: '', BESKRIVELSE: '' });
  const [deleteResultgruppeId, setDeleteResultgruppeId] = useState(0);
  const [deleteResultgruppeName, setDeleteResultgruppeName] = useState('');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const getResultGruppe = useFetchData({
    loadOnMount: true,
    clearDataOnLoad: false,
    projectURL: 'getResultatgruppe',
    subProjectURL: `${currentDatabase?.value}`
  });
  const getResultGruppeIDCount = useFetchData({
    loadOnMount: false,
    clearDataOnLoad: false,
    projectURL: 'checkResultatGruppeId',
    subProjectURL: `${currentDatabase?.value}/idToDele`
  });
  const handleChange = (name: string, value: string) => {
    setCopyResultgruppe({
      ...copyResultgruppe,
      [name]: value
    });
  };
  useEffect(() => {
    setResultatGruppe(getResultGruppe.data);
  }, [getResultGruppe.data]);

  useEffect(() => {
    if (getResultGruppeIDCount.data[0]) {
      if (getResultGruppeIDCount.data[0]['COUNT(GRUPPETYPEID)'] > 0) {
        setDoseResultGruppeHaveUnderlayingInfo(true);
      } else {
        setDoseResultGruppeHaveUnderlayingInfo(false);
      }
    }
  }, [getResultGruppeIDCount.data]);

  const handleChangeTable = (name: string, value: string | number, index: number) => {
    setResultatGruppe([
      ...resultatGruppe.map((object: any, oIndex: number) => {
        if (oIndex === index) {
          return {
            ...object,
            [name]: value
          };
        } else {
          return object;
        }
      })
    ]);
  };

  const setGuppeTypeId = (row: resultatGruppeI) => {
    dispatch({
      type: actions.SET_RESULTAT_GRUPPE_TYPE,
      resultatgruppeType: row
    });
    setSelectedResultagruppe({
      NAVN: row?.BESKRIVELSE,
      GRUPPETYPEID: row?.GRUPPETYPEID,
      TILGANGSKODE: row?.TILGANGSKODE
    });
  };

  const handleNewTable = (name: string, value: string | number, index?: number) => {
    setNewResultatGruppe({ ...newResultatGruppe, [name]: value as string });
  };

  const callAlert = (message: string, info: AlertI['alertColor']) => {
    setAlertMessage(message);
    setAlertSeverity(info);
    setShowAlert(true);
  };

  const saveMatvare = async () => {
    // If we add a name to the create new talbe line we will do a creat new.
    if (newResultatGruppe.BESKRIVELSE !== '') {
      const reqbody = {
        beskrivelse: newResultatGruppe.BESKRIVELSE || '',
        langBeskrivelse: newResultatGruppe.LANG_BESKRIVELSE || '',
        sporregruppe: 0 // This might need to change
      };
      const dataurl = `${currentDatabase?.value}`;
      PostData(reqbody, 'createResultatGruppe', dataurl, 'POST', token).then(
        (resp: any) => {
          if (resp.status === 200) {
            callAlert(
              `Lagrer resultatgruppe ${newResultatGruppe.BESKRIVELSE}...`,
              'success'
            );
          } else if (resp.status === 500) {
            console.log('An error 500', resp.status);
            callAlert(
              'Oppdateringen mislyktes. Det har ikke blitt gjort endringer mot databasen.',
              'error'
            );
          }
        }
      );
      // Clear the new line.
      setNewResultatGruppe({
        BESKRIVELSE: '',
        GRUPPETYPEID: 0,
        LANG_BESKRIVELSE: '',
        TILGANGSKODE: 0
      });

      await addResultGruppeToUngroupped();

      getResultGruppe.loadData('getResultatgruppe', `${currentDatabase?.value}`);
    }
    // We check our arrays if they have change we need to update them
    testIfResultatGruppeIsChanged(resultatGruppe, getResultGruppe.data);
  };

  const testIfResultatGruppeIsChanged = (stateArray: any, fetchedArray: any) => {
    //First check if there is the same length.
    //They should always be the same length for this to work.
    if (stateArray.length === fetchedArray.length) {
      stateArray.forEach((el: any, index: number) => {
        let testBESKRIVELSE = el.BESKRIVELSE === fetchedArray[index].BESKRIVELSE;
        if (testBESKRIVELSE === false) {
          //They are not same we need to update!
          const dataurl = `${currentDatabase?.value}/${el.GRUPPETYPEID}`;
          const reqbody = {
            beskrivelse: el.BESKRIVELSE || '',
            langBeskrivelse: el.LANG_BESKRIVELSE || '',
            sporregruppe: 0 // This might need to change
          };

          PostData(reqbody, 'updateResultatGruppe', dataurl, 'PUT', token).then(
            (resp: any) => {
              if (resp.status === 200) {
                callAlert('Oppdatering er fullført', 'success');
              } else if (resp.status === 500) {
                callAlert(
                  'Oppdateringen mislyktes. Det har ikke blitt gjort endringer mot databasen.',
                  'error'
                );
              }
            }
          );
        }
        if (el.LANG_BESKRIVELSE) {
          let testLANGBESKRIVELSE =
            el.LANG_BESKRIVELSE === fetchedArray[index].LANG_BESKRIVELSE;

          if (testLANGBESKRIVELSE === false) {
            //They are not same we need to update!
            const dataurl = `${currentDatabase?.value}/${el.GRUPPETYPEID}`;
            const reqbody = {
              beskrivelse: el.BESKRIVELSE || '',
              langBeskrivelse: el.LANG_BESKRIVELSE || '',
              sporregruppe: 0 // This might need to change
            };
            PostData(reqbody, 'updateResultatGruppe', dataurl, 'PUT', token).then(
              (resp: any) => {
                if (resp.status === 200) {
                  callAlert('Oppdatering er fullført', 'success');
                } else if (resp.status === 500) {
                  console.log('An error 500', resp.status);
                  callAlert(
                    'Oppdateringen mislyktes. Det har ikke blitt gjort endringer mot databasen.',
                    'error'
                  );
                }
              }
            );
          }
        }
      });
    }
  };

  const handleDeleteConfirmation = (gruppeID: number, name: string) => {
    setDeleteResultgruppeId(gruppeID);
    setDeleteResultgruppeName(name);
    getResultGruppeIDCount.loadData(
      'checkResultatGruppeId',
      `${currentDatabase?.value}/${gruppeID}`
    );
    setComfirmDeletingResultGruppe(true);
  };

  const deleteWithDelay = () => {
    const timer = setTimeout(() => {
      const dataurl = `${backendURI}/deleteResultatGruppe/${currentDatabase?.value}/${deleteResultgruppeId}`;
      let deleteM = postData({}, dataurl, 'DELETE');

      deleteM.then((response) => {
        if (response.rowsAffected >= 1) {
          callAlert(`Slettingen er fullført`, 'success');
        } else {
          callAlert(
            `Slettingen mislyktes. Ingen sletting er utført mot databasen.`,
            'error'
          );
        }
      });

      getResultGruppe.loadData('getResultatgruppe', `${currentDatabase?.value}`);
    }, 300);

    return () => clearTimeout(timer);
  };
  const deleteResultgruppe = async () => {
    const deleteGruppeelementurl = `${backendURI}/deleteGruppeelement/${currentDatabase?.value}/${deleteResultgruppeId}`;
    await postData({}, deleteGruppeelementurl, 'DELETE');

    const deleteMatvaregruppeurl = `${backendURI}/deleteMatvaregruppe/${currentDatabase?.value}/${deleteResultgruppeId}`;
    await postData({}, deleteMatvaregruppeurl, 'DELETE');

    deleteWithDelay();
  };

  const handleClickJa = async () => {
    await deleteResultgruppe();
    closeDialog();
  };

  const handleClickNei = () => {
    setDeleteResultgruppeId(0);
    closeDialog();
  };

  const closeDialog = () => {
    setComfirmDeletingResultGruppe(false);
    props.setConfirmKopi(false);
  };

  const handleClickJaCopy = async () => {
    if (copyResultgruppe.NAVN === '') {
      callAlert('Navn må våre utfylt', 'error');
    } else {
      const copyUrl = `${backendURI}/copyResultgruppe/${currentDatabase?.value}/${selectedRow.GRUPPETYPEID}`;

      let body = {
        beskrivelse: copyResultgruppe.NAVN,
        langBeskrivelse: copyResultgruppe.BESKRIVELSE || ''
      };

      let repost = await postData(body, copyUrl, 'POST');
      if (repost.data === 'BeskrivelseExists') {
        callAlert(
          'Det valgte navnet er brukt fra før. Vennligst angi et nytt navn.',
          'error'
        );
      } else {
        getResultGruppe.loadData('getResultatgruppe', `${currentDatabase?.value}`);
        setCopyResultgruppe({ NAVN: '', BESKRIVELSE: '' });
        closeDialog();
      }
    }
  };

  async function fetchData(url: string, methods: string) {
    let response = await fetch(encodeURI(url), {
      method: methods,
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
      //body: JSON.stringify(data)
    });

    return await response.json();
  }

  async function postData(bodyData: any, url: string, methods: string) {
    let response = await fetch(encodeURI(url), {
      method: methods,
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      body: JSON.stringify(bodyData)
    });

    return await response.json();
  }

  const addResultGruppeToUngroupped = async () => {
    /*
    This is a terrible function both here and in KBS its overly complex and strange.
    And needs allot more work. 
    */

    let nameAndDate = ''; // We need the string to include name timestamp. e.g. 'ROBERAHA 2022-03-30 12:41:38'
    let gruppeId = [];

    //First We need to create a  string with username and timestamp.
    let url = `${backendURI}/getNameSysDate/${currentDatabase?.value}`;
    nameAndDate = await fetchData(url, 'GET');

    //Next we need to INSERT INTO uspesifisertgruppe or the ungrupped list
    let addToUGruppedUrl = `${backendURI}/addToUgruppert/${currentDatabase?.value}/${nameAndDate}`;
    await postData({}, addToUGruppedUrl, 'POST');

    //this count needs to happe later maybe?
    let countGruppeId = `${backendURI}/countGruppeId/${currentDatabase?.value}/${nameAndDate}`;
    let count = await fetchData(countGruppeId, 'GET');

    //This update is needed for adding the
    if (count > 0) {
      let updateToUGruppedUrl = `${backendURI}/updateUgruppert/${currentDatabase?.value}/${nameAndDate}`;
      await postData({}, updateToUGruppedUrl, 'PUT');
    }

    let insertToMatvaregruppe = `${backendURI}/insertToMatvaregruppe/${currentDatabase?.value}/${nameAndDate}`;
    await postData({}, insertToMatvaregruppe, 'POST');

    let getGruppAndNoenrid = `${backendURI}/getGruppAndNoenrid/${currentDatabase?.value}/${nameAndDate}`;
    gruppeId = await fetchData(getGruppAndNoenrid, 'GET');

    for (let i = 0; i < gruppeId.length; i++) {
      let insertToGruppelement = `${backendURI}/insertToGruppelement/${currentDatabase?.value}/${gruppeId[i].GRUPPETYPEID}/${gruppeId[i].NODENRID}`;
      await postData({}, insertToGruppelement, 'POST');
    }

    //Then delete in ugrupped
    let deleteUspesifisertgruppe = `${backendURI}/deleteUspesifisertgruppe/${currentDatabase?.value}/${nameAndDate}`;
    await postData({}, deleteUspesifisertgruppe, 'DELETE');
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      {showAlert && (
        <AlertComp
          alertMessage={alertMessage}
          alertColor={alertSeverity}
          showAlert={showAlert}
          setShowAlert={setShowAlert}
        />
      )}

      {props.comfirmKopi && selectedRow && (
        <Dialog
          maxWidth="xs"
          fullWidth
          open={props.comfirmKopi}
          onClose={closeDialog}
          aria-labelledby="kopiMatvare"
        >
          <DialogTitle id="kopiMatvare">Kopier resultatgruppe</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description"></DialogContentText>
            <Typography>{`Kopi av resultatgruppen ${selectedRow?.BESKRIVELSE} med all underliggende informasjon?`}</Typography>
            <Stack direction={'row'} spacing={2}>
              <SlowText
                // classes={classes}
                header={'Navn'}
                id={'NAVN'}
                type={'text'}
                name={'NAVN'}
                value={copyResultgruppe?.NAVN || ''}
                onChange={(e) => handleChange('NAVN', e.target.value)}
                required={true}
              />
              <SlowText
                //classes={classes}
                header={'Beskrivelse'}
                id={'BESKRIVELSE'}
                type={'text'}
                name={'BESKRIVELSE'}
                value={copyResultgruppe?.BESKRIVELSE || ''}
                onChange={(e) => handleChange('BESKRIVELSE', e.target.value)}
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClickJaCopy} color="primary">
              Kopier
            </Button>
            <Button onClick={handleClickNei} color="primary" autoFocus>
              Avbryt
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {comfirmDeletingResultGruppe && (
        <Dialog
          maxWidth="xs"
          fullWidth
          open={comfirmDeletingResultGruppe}
          onClose={closeDialog}
          aria-labelledby="sletteMatvare"
        >
          <DialogTitle id="sletteMatvare">Slett Resultatgruppe</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {doseResultGruppeHaveUnderlayingInfo
                ? `Resultatgruppen inneholder underliggende informasjon. Vil du slette denne resultatgruppen? ${deleteResultgruppeName}`
                : `Vil du slette den markerte resultatgruppen? ${deleteResultgruppeName}`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClickJa} color="primary">
              Ja
            </Button>
            <Button onClick={handleClickNei} color="primary" autoFocus>
              Nei
            </Button>
          </DialogActions>
        </Dialog>
      )}

      <Box style={{ padding: 15 }}>
        <Table>
          <TableHead sx={classesTable.head}>
            <TableRow>
              <TableCell>Navn</TableCell>
              <TableCell>Beskrivelse</TableCell>
              <TableCell>Slett</TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={classesTable.root}>
            {resultatGruppe
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.map((row, tableIndex) => {
                const highlighted =
                  selectedRow && selectedRow?.GRUPPETYPEID === row.GRUPPETYPEID;
                return (
                  <>
                    <TableRow
                      key={`resultatGruppe${row.GRUPPETYPEID}`}
                      hover
                      role="checkbox"
                      onClick={() => {
                        setSelectedRow(highlighted ? null : row);
                        props.setIsNotSelected(false);
                        setGuppeTypeId(row);
                        openGrupper();
                      }}
                      style={{
                        backgroundColor: highlighted ? '#d2ecfd' : 'white'
                      }}
                      sx={classesTable.tableRow}
                    >
                      <SingleInput
                        value={row.BESKRIVELSE}
                        name={'BESKRIVELSE'}
                        owned={props.owned}
                        handleChangeTable={handleChangeTable}
                        size={'30%'}
                        index={tableIndex}
                      />
                      <SingleInput
                        value={row.LANG_BESKRIVELSE}
                        name={'LANG_BESKRIVELSE'}
                        owned={props.owned}
                        handleChangeTable={handleChangeTable}
                        size={'70%'}
                        index={tableIndex}
                      />

                      <TableCell
                        style={{ borderRight: '1px solid lightgrey', padding: 0 }}
                      >
                        <NButton
                          rightIcon={<DeleteOutlineOutlinedIcon />}
                          variant="text"
                          disabled={
                            !IsOwnedById({
                              tilgangskode: row.TILGANGSKODE,
                              u: loggedInuser
                            })
                          }
                          onClick={() =>
                            handleDeleteConfirmation(row.GRUPPETYPEID, row.BESKRIVELSE)
                          }
                        />
                      </TableCell>
                    </TableRow>
                    {row.GRUPPETYPEID ===
                    resultatGruppe[resultatGruppe?.length - 1].GRUPPETYPEID ? (
                      <TableRow
                        onClick={() => {
                          setSelectedRow(null);
                          props.setIsNotSelected(true);
                        }}
                      >
                        <SingleInput
                          value={newResultatGruppe?.BESKRIVELSE || ''}
                          name={'BESKRIVELSE'}
                          owned={true}
                          handleChangeTable={handleNewTable}
                          size={'30%'}
                          keyDown={false}
                          placeholderText={'Legg til ny resultatgruppe'}
                        />
                        <SingleInput
                          value={newResultatGruppe?.LANG_BESKRIVELSE || ''}
                          name={'LANG_BESKRIVELSE'}
                          owned={true}
                          handleChangeTable={handleNewTable}
                          size={'70%'}
                          keyDown={false}
                          placeholderText={'Legg til resultatgruppe beskrivelse'}
                        />
                        <TableCell>
                          <NButton
                            children="Nullstill"
                            variant="outlined"
                            onClick={() =>
                              setNewResultatGruppe({
                                BESKRIVELSE: '',
                                LANG_BESKRIVELSE: '',
                                GRUPPETYPEID: 0,
                                TILGANGSKODE: 0
                              })
                            }
                          />
                        </TableCell>
                      </TableRow>
                    ) : null}
                  </>
                );
              })}
          </TableBody>
        </Table>
        <TablePagination
          sx={classesTable.Pagination}
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={resultatGruppe?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          showFirstButton={true}
          showLastButton={true}
        />
      </Box>
      <Stack direction={'row'} spacing={2} sx={{ padding: 2 }}>
        <NButton children="Lagre" onClick={saveMatvare} />
      </Stack>
    </>
  );
}
