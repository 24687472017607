import React, { useState, useEffect, useMemo } from 'react';
import useFetchData from '../../utilities/useFetchData';
import { useKeycloak } from '@react-keycloak/web';
import PageContainer from '../home/PageContainer';
import { AlertI } from '../../../types/alertTypes';
import { ProgressBar } from '../../utilities/ProgressBar';
import AlertComp from '../../utilities/AlertComp';
import { Paper, Grid, Typography, Stack, CircularProgress, Chip } from '@mui/material';
import { NButton } from '../components/Inputs';
import ReactToPrint from 'react-to-print';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import ProjectNoLink from './components/ProjectNoLink';
import { PersonIUndersokelse, ItemOwner } from './types/UndersokelseTypes';
import IsOwnedByUser from '../../utilities/Validate';
import { useUserContext } from '../../../context/AuthContext';
import PersonerTable from './PersonerTable';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { Box } from '@mui/system';
import PersonerFilterModal from './components/modals/PersonerFilterModal';
import { RouteComponentProps } from 'react-router';
import { useCodeContext } from '../../../context/CodeContext';

type Property =
  | 'personStatus'
  | 'skjemaStatus'
  | 'status1'
  | 'status2'
  | 'tilbakemeldingsSkjema';

interface Filter {
  property: Property;
  values: any[];
}

const statusNames: Property[] = [
  'personStatus',
  'skjemaStatus',
  'status1',
  'status2',
  'tilbakemeldingsSkjema'
];
const displayNames = [
  'Personstatus',
  'Skjema',
  'Dag 1 status',
  'Dag 2 status',
  'Tilbakemelding'
];

interface RouteParams {
  prosjektId: string;
  undersokelseId: string;
  undersokelseKortnavn: string;
}

interface PersonerProps extends RouteComponentProps<RouteParams> {}

export default function Personer(props: PersonerProps) {
  const {} = props;
  const { prosjektId, undersokelseId, undersokelseKortnavn } = props.match.params;
  const [selectedStatuses, setSelectedStatuses] = useState<Filter[]>([]);
  const [allStatuses, setAllStatuses] = useState<Filter[]>([]);
  const [foundStatuses, setFoundStatuses] = useState<Filter[]>([]);
  const [selectedIntervjuer, setSelectedIntervjuer] = useState<string>('alle');
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [updateData, setUpdateData] = useState<boolean>(false);
  const [isDataEmpty, setIsDataEmpty] = useState<boolean>(false);
  const [bareDeltakere, setBareDeltakere] = useState<boolean>(false);
  const allCodes = useCodeContext();
  const loggedInuser = useUserContext();
  const [owned, setOwned] = useState(false);
  const [personerData, setPersonerData] = useState<PersonIUndersokelse[]>([]);
  const [filteredData, setFilteredData] = useState<PersonIUndersokelse[]>([]);
  const [showCDialog, setShowCDialog] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState<AlertI['alertColor']>('info');
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [isFetched, setIsFetched] = useState<boolean>(false);
  const [showProgress, setShowProgress] = useState(false);
  const printRef = React.useRef();
  document.title = `Personer - Nutrifoodcalc`;

  const getPersoner = useFetchData({
    loadOnMount: true,
    clearDataOnLoad: false,
    projectURL: 'hentPersoner',
    // subProjectURL: `${undersokelseData.projectId}/${undersokelseData.undersokelseId}/${lopedag1}/${lopedag2}/${personStatus}/${unntattStatus}/${skjemaStatus}/${unntattSkjema}/${tilbakemeldingStatus}/${untattTilbakemelding}/${interviewer}/${status1}/${status2}/${bareDeltagere}`
    subProjectURL: `${prosjektId}/${undersokelseId}/1/2/0/N/0/N/0/N/0/0/0/J`
  });

  //fetch data
  useEffect(() => {
    if (getPersoner.data) {
      const data: PersonIUndersokelse[] = getPersoner.data;
      if (data?.length > 0) {
        setPersonerData(data);
        setFilteredData(data);
        setOwned(IsOwnedByUser({ id: +prosjektId, u: loggedInuser }));
        setShowProgress(false);
      }
      if (getPersoner.isLoaded && data?.length === 0) {
        setShowProgress(false);
        setIsFetched(true);
      }
    }
  }, [getPersoner.data, getPersoner.isLoaded]);

  useEffect(() => {
    if (getPersoner.error) {
      setIsFetched(true);
      showAlertComponent('Feil ved henting av personer i undersøkelsen.', 'error');
    }
  }, [getPersoner.error]);

  const intervjuer1 = useMemo(
    () =>
      [...new Set<string>(personerData.map((item) => item.intervjuer1))].filter(
        (item) => item !== undefined
      ),
    [personerData]
  );
  const intervjuer2 = useMemo(
    () =>
      [...new Set<string>(personerData.map((item) => item.intervjuer2))].filter(
        (item) => item !== undefined
      ),
    [personerData]
  );
  const allIntervjuere: string[] = useMemo(
    () => [...new Set(['alle', ...intervjuer1, ...intervjuer2])],
    [intervjuer1, intervjuer2]
  );

  const getStatusValues = (field: number) => {
    return allCodes.codes.filter((f) => f.value === field).map((item) => item.label);
  };

  useEffect(() => {
    const statuses: Filter[] = [
      { property: 'personStatus', values: getStatusValues(10) },
      { property: 'skjemaStatus', values: getStatusValues(16) },
      { property: 'status1', values: getStatusValues(11) },
      { property: 'status2', values: getStatusValues(11) },
      { property: 'tilbakemeldingsSkjema', values: getStatusValues(17) }
    ];
    setAllStatuses(statuses);
  }, [allCodes]);

  useEffect(() => {
    const newFoundValues = [];
    for (let i of statusNames) {
      const allUnique = [
        ...new Set<string | number>(personerData.map((item) => item[i]))
      ].filter((item) => item !== undefined);
      newFoundValues.push({ property: i, values: allUnique });
    }
    setFoundStatuses(newFoundValues);
  }, [personerData]);

  useEffect(() => {
    setSelectedStatuses(foundStatuses);
  }, [foundStatuses]);

  const showAlertComponent = (message: string, severity: any) => {
    setShowAlert(true);
    setAlertMessage(message);
    setAlertSeverity(severity);
  };

  const getChipLabels = (filter: Filter, index: number) => {
    const foundValues = foundStatuses.find((f) => f.property === filter.property)?.values;
    if (filter.values.length === foundValues.length) {
      return (
        <>
          <b>{displayNames[index]}:</b> alle
        </>
      );
    } else if (
      filter.values.length === foundValues.length - 1 ||
      filter.values.length === foundValues.length - 2
    ) {
      const missingValues = foundValues.filter((f) => !filter.values.includes(f));
      return (
        <>
          <b>{displayNames[index]}:</b> alle unntatt "{missingValues.join('", "')}"
        </>
      );
    } else {
      return (
        <>
          <b>{displayNames[index]}:</b> "{filter.values.join('", "')}"
        </>
      );
    }
  };

  const handleChipDelete = (filter: Filter) => () => {
    if (filter === selectedStatuses[0]) {
      setBareDeltakere(false);
    }
    setSelectedStatuses((prev) =>
      prev.map((f) =>
        f.property === filter.property
          ? {
              property: filter.property,
              values: foundStatuses.find((s) => s.property === filter.property).values
            }
          : f
      )
    );
    setUpdateData(true);
  };

  useEffect(() => {
    if (updateData) {
      setIsDataEmpty(false);

      let newFilteredData: PersonIUndersokelse[] = personerData;

      if (selectedIntervjuer !== 'alle') {
        newFilteredData = newFilteredData.filter(
          (item) =>
            item.intervjuer1 === selectedIntervjuer ||
            item.intervjuer2 === selectedIntervjuer
        );
      }

      if (bareDeltakere) {
        newFilteredData = newFilteredData.filter(
          (item) =>
            item.personStatus !== 'Deltar ikke' &&
            item.personStatus !== 'Ikke påbegynt' &&
            item.personStatus !== 'Trukket seg'
        );
      }

      newFilteredData = newFilteredData.filter(
        (item) =>
          selectedStatuses.every(
            (filter) =>
              filter.values.includes(item[filter.property]) || !item[filter.property]
          ) && selectedStatuses.some((filter) => item[filter.property] !== undefined)
      );

      if (newFilteredData.length === 0) {
        setIsDataEmpty(true);
      }
      setFilteredData(newFilteredData);
    }
    setUpdateData(false);
  }, [updateData]);

  useEffect(() => {
    console.log('selectedStatuses', selectedStatuses);
  }, [selectedStatuses]);

  return (
    <PageContainer>
      <Paper style={{ padding: '1.5em 15px 1.5em 15px', boxShadow: 'none' }}>
        <Grid container spacing={2}>
          <Grid item xs={9}>
            <Typography variant="h4" gutterBottom>
              Personer i undersøkelsen
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Stack direction="row-reverse" spacing={1}>
              <ReactToPrint
                trigger={() => (
                  <NButton
                    children="Skriv ut"
                    rightIcon={<PrintOutlinedIcon />}
                    variant="text"
                    disabled={!personerData?.length}
                  />
                )}
                pageStyle=".styledTable{width:100%;border-collapse:collapse;text-align:left}.styledTable th,.styledTable td{border:2px solid black;padding:0.5em;}"
                content={() => printRef.current}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} container spacing={2}>
            <ProjectNoLink
              projectId={prosjektId}
              undersokelseId={undersokelseId}
              kortnavn={undersokelseKortnavn}
            />
          </Grid>
        </Grid>

        <Stack spacing={2} marginTop={3}>
          <Stack direction={'row'} flexWrap={'wrap'} columnGap={1} rowGap={1}>
            <Box>
              <NButton
                children="Filtrering for visning"
                leftIcon={<FilterAltOutlinedIcon />}
                variant="outlined"
                onClick={() => setShowFilters(true)}
                sx={{ px: 1 }}
              />
            </Box>
            {selectedStatuses?.map((item, index) => (
              <Chip
                key={index}
                label={getChipLabels(item, index)}
                onDelete={
                  item.values.length ===
                  foundStatuses?.find((f) => f.property === item.property).values.length
                    ? null
                    : handleChipDelete(item)
                }
              />
            ))}
            <Chip
              label={
                <>
                  <b>Intervjuer:</b> {selectedIntervjuer}
                </>
              }
              onDelete={
                selectedIntervjuer === 'alle'
                  ? null
                  : () => {
                      setSelectedIntervjuer('alle');
                      setUpdateData(true);
                    }
              }
            />
            {bareDeltakere && (
              <Chip
                label={
                  <>
                    <b>Bare deltakere</b>
                  </>
                }
                onDelete={() => {
                  setBareDeltakere(false);
                  setSelectedStatuses((prev) =>
                    prev.map((f) =>
                      f.property === selectedStatuses[0].property
                        ? {
                            property: selectedStatuses[0].property,
                            values: foundStatuses.find(
                              (s) => s.property === selectedStatuses[0].property
                            ).values
                          }
                        : f
                    )
                  );
                  setUpdateData(true);
                }}
              />
            )}
          </Stack>
          {filteredData?.length > 0 ? (
            <PersonerTable
              filteredData={filteredData}
              prosjektId={+prosjektId}
              undersokelseId={+undersokelseId}
              undersokelseKortnavn={undersokelseKortnavn}
            />
          ) : isDataEmpty ? (
            <Stack justifyContent={'center'} alignItems={'center'} height={'33rem'}>
              <Typography variant="body1">
                Ingen personer funnet med valgte filter.
              </Typography>
            </Stack>
          ) : isFetched ? (
            <Stack justifyContent={'center'} alignItems={'center'} height={'33rem'}>
              <Typography variant="body1">
                Ingen personer funnet i denne undersøkelsen.
              </Typography>
            </Stack>
          ) : (
            <Stack justifyContent={'center'} alignItems={'center'} height={'33rem'}>
              <CircularProgress />
            </Stack>
          )}
        </Stack>

        {/* Dialogs */}

        {showFilters && (
          <PersonerFilterModal
            showing={showFilters}
            hide={() => setShowFilters(false)}
            selectedStatuses={selectedStatuses}
            setSelectedStatuses={setSelectedStatuses}
            allStatuses={allStatuses}
            foundStatuses={foundStatuses}
            allIntervjuere={allIntervjuere}
            selectedIntervjuer={selectedIntervjuer}
            setSelectedIntervjuer={setSelectedIntervjuer}
            bareDeltakere={bareDeltakere}
            setBareDeltakere={setBareDeltakere}
            statusNames={statusNames}
            displayNames={displayNames}
            setUpdateData={setUpdateData}
            setFoundStatuses={setFoundStatuses}
          />
        )}

        {showAlert && (
          <AlertComp
            alertMessage={alertMessage}
            alertColor={alertSeverity}
            showAlert={showAlert}
            setShowAlert={setShowAlert}
          />
        )}
        {showProgress && (
          <ProgressBar
            text={'text'}
            isShowing={showProgress}
            hide={() => {
              setShowProgress(!showProgress);
            }}
          />
        )}
      </Paper>
    </PageContainer>
  );
}
