import { OptionType } from '../components/pages/components/commons/CommonTypes';

export const codeTypes: OptionType[] = [
  { label: 'Kjønn', value: 1 },
  { label: 'Bostedtype', value: 2 },
  { label: 'Måltidstype', value: 3 },
  { label: 'Matvarestatus', value: 4 },
  { label: 'Mengdeenhet', value: 5 },
  { label: 'Kjønn/tilstand', value: 6 },
  { label: 'Aldersgruppe', value: 8 },
  { label: 'Personstatus', value: 10 },
  { label: 'Løpedagstatus', value: 11 },
  { label: 'Spisested', value: 12 },
  { label: 'Oppringingstatus', value: 13 },
  { label: 'Intervjusted', value: 14 },
  { label: 'Landsdel', value: 15 },
  { label: 'Skjemastatus', value: 16 },
  { label: 'Tilbakemeldingstatus', value: 17 },
  { label: 'Årsak uvanlig dag', value: 18 },
  { label: 'Spist måltid sammen med', value: 19 }
];
