import React, { useEffect, useState } from 'react';
import { ProgressBar } from '../../../utilities/ProgressBar';
import { UndersokelseList } from '../components/UndersokelseList';
import { useKeycloak } from '@react-keycloak/web';
import { ConfirmationDialog } from '../../../utilities/ConfirmationDialog';
import { DatabaseContext } from '../../../../context/DatabaseContext';
import { NullstillUndersokelse } from './NullstillUndersokelse';
import PageContainer from '../../home/PageContainer';
import moment from 'moment';
import AlertComp from '../../../utilities/AlertComp';
import { AlertWithAction } from '../../../utilities/AlertPopUp';
import { AlertI } from '../../../../types/alertTypes';
import { Paper, Stack, Typography, IconButton, Alert, Grid } from '@mui/material';
import { GenericImport, NButton } from '../../components/Inputs';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import ProjectInput from '../../components/ProjectInput';
import IsOwnedByUser from '../../../utilities/Validate';
import { useUserContext } from '../../../../context/AuthContext';
import { databaseOptions } from '../../../../assets/DatabaseOptions';
import PollingComponent from '../../components/commons/PollingComponent';
import { ImportUndersokelseResultI } from '../types/ImportTypes';
import { FetchProjectandUndersokelse } from '../components/FetchProjectandUndersokelse';
import { useProjectContext } from '../../../../context/ProjectContext';

const initialData: ImportUndersokelseResultI = {
  count: 0,
  errors: [],
  lines: []
};

export const ImportkostholdsUndersokelse = () => {
  const { keycloak } = useKeycloak();
  const token = keycloak?.token;
  const loggedInuser = useUserContext();
  const { currentDatabase } = React.useContext(DatabaseContext);
  const [title] = React.useState('Import kostholdsundersøkelse');
  const [showProgress, setShowProgress] = React.useState(false);
  const [progressText, setProgressText] = React.useState('Importerer ...');
  const [showAlert, setShowAlert] = useState(false);
  const [showAlertWA, setShowAlertWA] = useState(false);
  const [alertMessage, setAlertMessage] = React.useState('');
  const [alertSeverity, setAlertSeverity] = React.useState<AlertI['alertColor']>('info');
  const [projectId, setProjectId] = React.useState('');
  const [undersokelseId, setUndersokelseId] = React.useState('');
  const [refetchProject, setRefetchProject] = React.useState<boolean>(false);
  const [refetchSurvey, setRefetchSurvey] = React.useState<boolean>(false);
  const [showUndersokelseIdList, setShowUndersokelseIdList] = React.useState(false);
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [, setIsFilePicked] = React.useState(false);
  const [showCDialog, setShowCDialog] = React.useState(false);
  const [failed, setFailed] = React.useState(false);
  const [dialogText, setDialogText] = React.useState('');
  const [dialogTitle] = React.useState('NFC notification!');
  const [confirmationResult, setConfirmationResult] = React.useState(null);
  const [importResult, setImportResult] =
    React.useState<ImportUndersokelseResultI>(initialData);
  const [fileContent, setFileContent] = React.useState<string>(null);
  const [downloadErrorLink, setDownloadErrorLink] = React.useState('');
  const [downloadBadLink, setDownloadBadLink] = React.useState('');
  const [personCount, setPersonCount] = React.useState(0);
  const [dbId, setDbId] = React.useState(0);
  const [pollingId, setPollingId] = React.useState('');
  const projs = useProjectContext();

  const baseUrl = process.env.REACT_APP_BACKEND;
  document.title = title;
  useEffect(() => {
    if (importResult?.count > 0) {
      setShowProgress(false);
      //update project
      setRefetchSurvey(true);
      showAlertMessage(`Fil importert, med ${importResult?.count} feil `, 'success');
      downloadErrorFile();
      downloadBadFile();
    }
  }, [importResult?.count]);
  useEffect(() => {
    if (failed) {
      showAlertMessage('Importering av undersøkelse mislyktes.', 'error');
    }
  }, [failed]);
  const downloadErrorFile = async () => {
    const prepareData = [];
    for (const item of importResult?.errors) {
      //for (const e of item) {
      prepareData.push(item.LOKASJON);
      prepareData.push(item.MELDING);
      prepareData.push(' ');
      //}
    }
    const data = new Blob([prepareData.join('\n')], { type: 'text/plain' });
    // this part avoids memory leaks
    if (downloadErrorLink !== '') window.URL.revokeObjectURL(downloadErrorLink);
    // update the download link state
    setDownloadErrorLink(window.URL.createObjectURL(data));
  };
  const downloadBadFile = async () => {
    const prepareData = [];
    for (const item of importResult?.lines) {
      const initialChar = item.LINJE?.substring(0, 1);
      if (initialChar === '#' && prepareData.length === 0) {
        prepareData.push(item.LINJE);
      } else if (initialChar !== '#') {
        prepareData.push(item.LINJE);
      }
    }
    const data = new Blob([prepareData.join('\n')], { type: 'text/plain' });
    if (downloadBadLink !== '') window.URL.revokeObjectURL(downloadBadLink);
    setDownloadBadLink(window.URL.createObjectURL(data));
  };

  const prepareDataForImport = async () => {
    if (projectId !== '' && undersokelseId !== '' && fileContent !== '') {
      clearVariables();
      setShowProgress(true);
      await importKostoholdU(fileContent);
    }
  };
  const clearVariables = () => {
    setProgressText('Importerer...');
    setImportResult(initialData);
  };

  async function postData(data: any, url: string, methods: string) {
    let response = await fetch(encodeURI(url), {
      method: methods,
      headers: {
        Authorization: 'Bearer ' + token
      },
      body: JSON.stringify(data)
    });
    return response;
  }

  const importKostoholdU = async (data: {}) => {
    const newDate = moment().format('YYYY-MM-DDThh:mm');
    const nameAndTime = `${selectedFile}-${newDate}`;
    let url = `${baseUrl}/importKostholdUndersokelse/${projectId}/${undersokelseId}/${nameAndTime}`;
    await postData(data, url, 'POST')
      .then((resp) => {
        if (resp.status === 200) {
          resp.json().then((data) => {
            //Start pooling backend
            setPollingId(data?.id);
          });
        } else if (resp.status === 500) {
          console.log('Error happened while importing.');
          showAlertMessage(
            'Fil importering mislyktes. Det har ikke blitt gjort endringer mot databasen.',
            'error'
          );
          setShowProgress(false);
        }
      })
      .catch((error) => {
        console.log(error);
        showAlertMessage(
          'Fil importering mislyktes. Det har ikke blitt gjort endringer mot databasen.',
          'error'
        );
      });
  };
  const showAlertMessage = (message: string, severity: any) => {
    setShowAlert(true);
    setAlertMessage(message);
    setAlertSeverity(severity);
  };
  const showAlertMessageWA = (message: string, severity: any) => {
    setShowAlert(true);
    setAlertMessage(message);
    setAlertSeverity(severity);
  };

  const handleOnChangeUndersokelse = (id: string) => {
    setUndersokelseId(id);
    const undersokelse = projs.projects
      ?.find((p) => p.prosjektId === +projectId)
      ?.undersokelser?.find((u) => u.undersokelseId === +id);
    setDbId(undersokelse?.matvareDatabaseId);
    setPersonCount(undersokelse?.antallPersoner['ANTALL']);
  };

  const setProject = (id: string) => {
    setProjectId(id);
    setUndersokelseId('');
  };
  const changeHandler = async (file: File) => {
    setSelectedFile(file?.name);
    console.log('file size...', file?.size);
    setIsFilePicked(true);
    if (file) {
      await file
        .text()
        .then((value) => {
          const initialChar = value.substring(0, 5);
          if (initialChar === '#AKFP') {
            setFileContent(value);
          } else {
            showAlertMessage(
              'Filen ' +
                file?.name +
                ' er ikke en gyldig KBS importfil (starter ikke med #AKFP)!',
              'error'
            );
            setSelectedFile('');
          }
        })
        .catch((err: any) => {
          console.error(err);
        });
    }
  };
  const showUndersokelseConfirmation = (text: string) => {
    setDialogText(text);
    setShowCDialog(true);
  };

  useEffect(() => {
    if (confirmationResult) {
      prepareDataForImport();
    } else {
      setShowProgress(false);
    }
  }, [confirmationResult]);

  const doImport = () => {
    if (undersokelseId) {
      if (dbId !== currentDatabase?.value) {
        showAlertMessageWA(
          'Matvaredatabase for undersøkelse (' +
            databaseOptions?.find((d) => d.value === dbId)?.label +
            ') er forskjellig fra valgt matvaredatabase (' +
            currentDatabase?.label +
            '). Velg matvaredatabase som samsvarer med undersøkelsen fra rullegardinmenyen øverst i skjemaet for å fortsette.',
          'warning'
        );
      } else {
        if (personCount > 0) {
          showUndersokelseConfirmation(
            'Denne undersøkelsen inneholder kostholdsdata fra før! Data i filen vil bli lagt til, eksisterende data overskrives IKKE.'
          );
        } else {
          prepareDataForImport();
        }
      }
    } else {
      console.log('invalid survey id');
    }
  };
  const filteredProjectId = projs?.projects?.filter((row) => {
    return IsOwnedByUser({ id: +row.prosjektId, u: loggedInuser }) || null;
  });
  const clear = () => {
    setProject('');
    setFileContent(null);
    setSelectedFile('');
    setPersonCount(0);
    setImportResult(initialData);
  };
  return (
    <PageContainer>
      <Paper style={{ padding: '1.5em 15px 1.5em 15px', boxShadow: 'none' }}>
        <Typography variant="h4" marginBottom="1em">
          Importer kostholdsundersøkelse
        </Typography>
        <FetchProjectandUndersokelse
          projectId={+projectId}
          refetchProject={refetchProject}
          setRefetchProject={setRefetchProject}
          refetchSurvey={refetchSurvey}
          setRefetchSurvey={setRefetchSurvey}
        />
        {filteredProjectId?.length > 0 ? (
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h5">
                Steg 1: Velg prosjekt og undersøkelse til å importere
                kostholdsundersøkelse til
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <ProjectInput
                project={projectId}
                projects={filteredProjectId?.map((o) => o.prosjektId?.toString())}
                setProject={setProject}
                undersokelse={undersokelseId}
                setUndersokelse={handleOnChangeUndersokelse}
              />
            </Grid>
            <Grid item xs={3} />
            <Grid item xs={12}>
              <NullstillUndersokelse
                projectId={projectId}
                undersokelseId={undersokelseId}
                personCount={personCount}
                setPersonCount={setPersonCount}
                setShowProgress={setShowProgress}
                setProgressText={setProgressText}
                showAlertComponent={showAlertMessage}
                setRefetchSurvey={setRefetchSurvey}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" style={{ marginTop: '1.5em' }}>
                Steg 2: Last opp en fil med kostholdsundersøkelse
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <GenericImport
                help={() => showAlertMessageWA('Ingen hjelp her', 'info')}
                onChange={changeHandler}
                disabled={!undersokelseId}
                selected={selectedFile}
                accept=".txt"
              />
            </Grid>
            <Grid item xs={7} />

            <Grid item xs={12} container spacing={2} sx={{ marginTop: '2em' }}>
              <Grid item xs={1}>
                <NButton children="Import" disabled={!selectedFile} onClick={doImport} />
              </Grid>
              <Grid item xs={1}>
                <NButton children="Nullstill" variant="outlined" onClick={clear} />
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <>
            <Alert severity="warning" sx={{ width: 'fit-content' }}>
              Du må eie i hvertfall et prosjekt!
            </Alert>
          </>
        )}

        {/*   {importResult?.count > 0 && (
          <Stack marginY="2em">
            <a download="Error.txt" href={downloadErrorLink}>
              <IconButton>
                <FileDownloadOutlinedIcon />
              </IconButton>
              Vis feilene i fil
            </a>
            <a download="Bad.txt" href={downloadBadLink}>
              <IconButton>
                <FileDownloadOutlinedIcon />
              </IconButton>
              Vis linje til feilene i fil
            </a>
          </Stack>
        )} */}
        {importResult?.count > 0 ? (
          <Grid item xs={12} sx={{ marginTop: 10 }}>
            <Typography variant="h6">Prossesert fil for nedlasting:</Typography>
            <Stack marginY="2em" spacing={3} direction={'row'}>
              <a download="Error.txt" href={downloadErrorLink}>
                <NButton
                  children="Last ned Error.txt"
                  leftIcon={<FileDownloadOutlinedIcon />}
                />
              </a>

              <a download="Bad.txt" href={downloadBadLink}>
                <NButton
                  children="Last ned Bad.txt"
                  variant="outlined"
                  leftIcon={<FileDownloadOutlinedIcon />}
                />
              </a>
            </Stack>
          </Grid>
        ) : null}
        {/* Dialogs */}
        {pollingId && (
          <PollingComponent
            id={pollingId}
            setFailed={setFailed}
            setResult={setImportResult}
          />
        )}
        {showUndersokelseIdList && (
          <UndersokelseList
            searchFeild={'undersøkelsesnummer'}
            showUndersokelseIdList={true}
            isShowing={showUndersokelseIdList}
            populateUndersokelse={setUndersokelseId}
            hide={() => {
              setShowUndersokelseIdList(!showUndersokelseIdList);
            }}
          />
        )}
        {showProgress && (
          <ProgressBar
            isShowing={showProgress}
            text={progressText}
            hide={() => {
              setShowProgress(!showProgress);
            }}
          />
        )}
        {showCDialog && (
          <ConfirmationDialog
            title={dialogTitle}
            text={dialogText}
            setConfirmationResult={setConfirmationResult}
            hide={() => {
              setShowCDialog(!showCDialog);
            }}
          />
        )}
        {showAlert && (
          <AlertComp
            alertMessage={alertMessage}
            alertColor={alertSeverity}
            showAlert={showAlert}
            setShowAlert={setShowAlert}
          />
        )}
        {showAlertWA && (
          <AlertWithAction
            alertMessage={alertMessage}
            alertColor={alertSeverity}
            showAlert={showAlertWA}
            setShowAlert={setShowAlertWA}
          />
        )}
      </Paper>
    </PageContainer>
  );
};
