import React from 'react';
import { TableCell, TableRow, TableBody, Table, TableHead, Box } from '@mui/material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import moment from 'moment';
import { ConfirmationDialog } from '../../../utilities/ConfirmationDialog';
import useFetchData from '../../../utilities/useFetchData';
import ReactToPrint from 'react-to-print';
import { SletteTilleggsvariable } from '../SletteTilleggsvariable';
import { CopyTilleggsvariable } from './CopyTilleggsvariable';
import {
  TilleggsvariableI,
  TilleggsvariableSvarI,
  VariabelIdI
} from '../types/TilleggsvariableTypes';
import { NButton } from '../../components/Inputs';
import { useUserContext } from '../../../../context/AuthContext';
interface printTilleggsvariabelProps {
  t: TilleggsvariableI;
  user: string;
  ref: any;
}
const ComponentToPrint = React.forwardRef(
  (props: printTilleggsvariabelProps, ref: any) => (
    <div ref={ref}>
      <b>Utskrift av skjermbildet: Definisjon av tilleggsvariabel ({props.user})</b>
      <p>
        Utskriftsdato:
        {moment().format('YYYY-MM-DD hh:mm:ss')}
      </p>
      <p>Kortnavn: {props.t?.kortnavn}</p>
      <p>Spørsmål: {props.t?.sporsmal}</p>
      <p>Svartype: {props.t?.svartype}</p>
      <p>Prosjektnr: {props.t?.prosjektnr}</p>
      <p>Opprettet av: {props.t?.brukernavn}</p>
      <p>Beskrivelse: {props.t?.beskrivelse} </p>
      <b> Definisjon av svarkategorier</b>
      <Table size="small" sx={{ border: '1px solid black' }}>
        <TableHead>
          <TableRow>
            <TableCell>Kode</TableCell>
            <TableCell>Beskrivelse</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.t.tilleggssvar?.map((row: TilleggsvariableSvarI) => {
            return (
              <TableRow
                key={row?.kode}
                sx={{ '&:nth-of-type(odd)': { background: '#bababa' } }}
              >
                <TableCell>{row?.kode}</TableCell>
                <TableCell>{row?.beskrivelse}</TableCell>
              </TableRow>
            );
          })}
          <TableRow>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  )
);
interface tillegsvariableIconsProps {
  //newVariableId: VariabelIdI;
  tilleggsvariable: TilleggsvariableI;
  tilleggsvariablerList: TilleggsvariableI[];
  //svarKatergoriList: TilleggsvariableSvarI[];
  ownedVariable: boolean;
  owned: boolean;
  setProgressBar: (value: boolean) => void;
  setRefetchVariableList: (value: boolean) => void;
  showAlert: (message: string, severity: any) => void;
  clear: () => void;
}
const TilleggsVariableIcons = (props: tillegsvariableIconsProps) => {
  const {
    // newVariableId,
    tilleggsvariable,
    tilleggsvariablerList,
    //svarKatergoriList,
    setProgressBar,
    setRefetchVariableList,
    showAlert,
    ownedVariable,
    owned,
    clear
  } = props;
  const loggedInuser = useUserContext();
  const currentUser = loggedInuser?.user?.username;
  const [showCDialog, setShowCDialog] = React.useState<boolean>(false);
  const [dialogText, setDialogText] = React.useState<string>('');
  const [dialogTitle, setDialogTitle] = React.useState<string>('');
  const [showSlette, setShowSlette] = React.useState<boolean>(false);
  const [showCopy, setShowCopy] = React.useState<boolean>(false);
  const [confirmationResult, setConfirmationResult] = React.useState(null);
  const componentRef = React.useRef();
  const [beskrivelseArray, setBeskrivelseArray] = React.useState<string[]>([]);

  const checkIfVariableIsUsed = useFetchData({
    loadOnMount: false,
    clearDataOnLoad: false,
    projectURL: 'checkIfVariableIsUsed',
    subProjectURL: `${tilleggsvariable?.variabelId}`
  });
  const handleClickSlette = () => {
    if (tilleggsvariable?.variabelId) {
      checkIfVariableIsUsed.loadData(
        'checkIfVariableIsUsed',
        `${tilleggsvariable?.variabelId}`
      );
      setProgressBar(true);
    }
  };

  React.useEffect(() => {
    setBeskrivelseArray(tilleggsvariable?.beskrivelse?.split(/\r?\n/));
  }, [tilleggsvariable]);

  const handleClickCopy = () => {
    setShowCopy(!showCopy);
  };

  React.useEffect(() => {
    if (checkIfVariableIsUsed.data) {
      let data = checkIfVariableIsUsed.data[0];
      if (data) {
        let count = data.COUNT;
        if (count === -1) {
          showAlert('Det har oppstått en feil. Vennligst prøv igjen senere.', 'error');
        } else if (count > 0) {
          showDeleteConfirmation('Kan ikke slette tilleggsvariabel som er i bruk.');
        } else {
          showDeleteConfirmation('Vil du slette denne tilleggsvariabelen?');
        }
      }
    }
  }, [checkIfVariableIsUsed.data]);

  React.useEffect(() => {
    if (checkIfVariableIsUsed.error) {
      showAlert('Det har oppstått en feil. Vennligst prøv igjen senere.', 'error');
    }
  }, [checkIfVariableIsUsed.error]);

  const showDeleteConfirmation = (text: string) => {
    setDialogTitle('Slett tilleggsvariabel');
    setDialogText(text);
    setProgressBar(false);
    setShowCDialog(true);
    checkIfVariableIsUsed.clearData();
  };

  React.useEffect(() => {
    if (confirmationResult) {
      setShowSlette(true);
    } else {
      setShowSlette(false);
      setProgressBar(false);
    }
  }, [confirmationResult]);

  return (
    <>
      <Box sx={{ display: 'none' }}>
        <ComponentToPrint t={tilleggsvariable} user={currentUser} ref={componentRef} />
      </Box>
      <ReactToPrint
        trigger={() => (
          <NButton
            children="Skriv ut"
            rightIcon={<PrintOutlinedIcon />}
            variant="text"
            disabled={!tilleggsvariable.kortnavn}
          />
        )}
        pageStyle="@page { size: auto; margin: 0mm; } @media print { body { -webkit-print-color-adjust: exact; padding: 40px !important; } }"
        content={() => componentRef.current}
      />
      <NButton
        children="Kopi"
        rightIcon={<ContentCopyOutlinedIcon />}
        variant="text"
        onClick={handleClickCopy}
        disabled={!tilleggsvariable.kortnavn}
      />
      {showCopy && (
        <CopyTilleggsvariable
          //newVariableId={newVariableId}
          tilleggsvariablerList={tilleggsvariablerList}
          showCopy={showCopy}
          setShowCopy={setShowCopy}
          tilleggsvariable={tilleggsvariable}
          setProgressBar={setProgressBar}
          setRefetchVariableList={setRefetchVariableList}
          showAlert={showAlert}
        />
      )}
      <NButton
        children="Slett"
        rightIcon={<DeleteOutlinedIcon />}
        variant="text"
        onClick={handleClickSlette}
        disabled={!(tilleggsvariable.kortnavn && (owned || ownedVariable))}
      />
      {showCDialog && (
        <ConfirmationDialog
          title={dialogTitle}
          text={dialogText}
          setConfirmationResult={setConfirmationResult}
          hide={() => {
            setShowCDialog(!showCDialog);
          }}
        />
      )}
      {showSlette && (
        <SletteTilleggsvariable
          isShowing={showSlette}
          variabelId={tilleggsvariable?.variabelId}
          confirmationResult={confirmationResult}
          setConfirmationResult={setConfirmationResult}
          setProgressBar={setProgressBar}
          //setProgressText={setProgressText}
          showAlert={showAlert}
          clear={clear}
          hide={() => {
            setShowSlette(!showSlette);
          }}
        />
      )}
    </>
  );
};
export default TilleggsVariableIcons;
