export enum UserRole {
  Admin = 'administrator',
  ReadnWrite = 'read-and-write',
  ReadOnly = 'read-only',
  Akf = 'akf'
}
export interface DefaultId {
  ID: number;
}
export interface NumberRange {
  from: number;
  to: number;
  id: number;
}
export interface UserValues {
  numberRange: NumberRange;
  databaseId: number;
}
export interface UserDetailI {
  id: number;
  brukerkode: string;
  navn?: string;
  telefon?: string;
  epost?: string;
  numberFrom?: number;
  numberTo?: number;
  type?: string;
  //dateCreated?: Date;
  activ?: string;
  roles?: RoleDetail[];
  hasRole?: boolean;
  isKeycloakUser?: boolean;
  isAdmin?: string;
}

export interface KeycloakUserDetail {
  id: string;
  username: string;
  roles: RoleDetail[];
}
export interface RoleDetail {
  id: string;
  name: string;
}
