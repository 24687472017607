import React from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import useStylesTable from '../../../../../css/TableCss';
import { Padding } from '@mui/icons-material';

export const EmptyMatvareTable = (props: any) => {
  const classesTable = useStylesTable();
  const rows = new Array(20).fill(null);
  return (
    <TableContainer component={Paper} sx={{ paddingTop: 2 }}>
      <Table>
        <TableHead sx={classesTable.head}>
          <TableRow>
            <TableCell>Kode</TableCell>
            <TableCell>Navn</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((_, index) => (
            <TableRow key={index}>
              <TableCell />
              <TableCell />
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
