import React, { useRef, forwardRef } from 'react';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { Grid, Box, Stack } from '@mui/material';
import {
  BeregnignInput,
  BeregningObject,
  DefaultBeregningCounts,
  MatvareUtvalg,
  StoffUtvalg,
  UtvalgTableData
} from '../types/BeregningTypes';
import useDidMountEffect from '../../../utilities/useDidMountEffect';
import { NButton } from '../../components/Inputs';
interface IconsMenuProps {
  classes: any;
  beregningObject: BeregningObject;
  showAlertMessage: (message: string, severity: any) => void;
  mapBeregningInputToObject: (obj: BeregnignInput) => void;
  personFilterCriteria: string[];
  setPersonFilterCriteria: (value: string[]) => void;
  konsumFilterCriteria: string[];
  setKonsumFilterCriteria: (value: string[]) => void;
  totalCounts: DefaultBeregningCounts;
  setTotalCounts: (values: DefaultBeregningCounts) => void;
  utvalgData: UtvalgTableData;
}

const IconsMenu = (props: IconsMenuProps) => {
  const {
    classes,
    beregningObject,
    showAlertMessage,
    mapBeregningInputToObject,
    personFilterCriteria,
    konsumFilterCriteria,
    setPersonFilterCriteria,
    setKonsumFilterCriteria,
    totalCounts,
    setTotalCounts,
    utvalgData
  } = props;

  const input = forwardRef(() => (
    <input
      style={{ display: 'none' }}
      ref={inputRef}
      type="file"
      onChange={importInput}
    />
  ));
  const inputRef = useRef(null);
  const [filename, setFilename] = React.useState('');
  const matvareUtvalg: MatvareUtvalg = beregningObject?.matvareData;
  const stoffUtvalg: StoffUtvalg = beregningObject?.stoffData;
  useDidMountEffect(() => {
    matvareUtvalg.individualMatvares = utvalgData?.matvaredata;
    matvareUtvalg.excludedMatvares = utvalgData?.fjerndeMatvareData;
    matvareUtvalg.matvaregroups = utvalgData?.matvaregroupData;
    stoffUtvalg.individualStoffs = utvalgData?.stoffData;
    stoffUtvalg.stoffGroup = utvalgData?.stoffGroupData;
  }, [utvalgData]);
  const dataToSave: BeregnignInput = {
    kostholdData: beregningObject?.kostholdData,
    matvareData: matvareUtvalg,
    stoffData: stoffUtvalg,
    numberOfPersons: beregningObject?.numberOfPersons,
    personFilterCriteria: personFilterCriteria,
    konsumFilterCriteria: konsumFilterCriteria,
    totalCounts: totalCounts
  };
  const exportInput = () => {
    if (dataToSave !== undefined) {
      const element = document.createElement('a');
      const file = new Blob([JSON.stringify(dataToSave)], {
        type: `text/json;chatset=utf-8`
      });
      element.href = URL.createObjectURL(file);
      element.download = 'beregningInput.json';
      element.click();
    } else {
      showAlertMessage('Kan ikke lagre utvalgene.', 'error');
    }
  };
  const assignImportedDataToObjects = (data: BeregnignInput) => {
    mapBeregningInputToObject(data);
    setPersonFilterCriteria(data?.personFilterCriteria);
    setKonsumFilterCriteria(data?.konsumFilterCriteria);
    setTotalCounts(data?.totalCounts);
    showAlertMessage(`Henting av utvalgene er ferdig!`, 'success');
  };
  const handleHent = () => {
    inputRef.current.click();
  };
  const importInput = async (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const target = event.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];
    setFilename(file?.name);
    await file
      .text()
      .then((value) => {
        assignImportedDataToObjects(JSON.parse(value));
      })
      .catch((err: any) => {
        console.error(err);
      });
  };
  return (
    <Grid container sx={classes.IconBoxContainer}>
      <Grid item sx={classes.IconBoxContainer} xs={6}>
        <Stack direction={'row'} spacing={2}>
          <NButton
            children="Lagre"
            rightIcon={<SaveOutlinedIcon />}
            variant="text"
            onClick={exportInput}
            disabled={beregningObject?.kostholdData?.projectId?.value === 0}
          />
          <Box>
            <input
              style={{ display: 'none' }}
              ref={inputRef}
              type="file"
              onChange={importInput}
            />
            <NButton
              children="Hent"
              rightIcon={<DownloadOutlinedIcon />}
              variant="text"
              onClick={handleHent}
            />
          </Box>
        </Stack>
      </Grid>
    </Grid>
  );
};
export default IconsMenu;
