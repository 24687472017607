import { useEffect, useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';

export const useFetch = () => {
  const { keycloak } = useKeycloak();
  const token = keycloak?.token;
  const [state, setState] = useState({ data: null, loading: false, error: false });
  const [url, setUrl] = useState('');
  let status: number;

  const [refetchIndex, setRefetchIndex] = useState(0);
  const [refetchIndexU, setRefetchIndexU] = useState(0);
  const [refetchIndexO, setRefetchIndexO] = useState(0);
  const [refetchIndexS, setRefetchIndexS] = useState(0);
  const [refetchIndexI, setRefetchIndexI] = useState(0);

  const refetch = () => setRefetchIndex((prevRefetchIndex) => prevRefetchIndex + 1);
  const refetchU = () => setRefetchIndexU((prevRefetchIndex) => prevRefetchIndex + 1);
  const refetchO = () => setRefetchIndexO((prevRefetchIndex) => prevRefetchIndex + 1);
  const refetchS = () => setRefetchIndexS((prevRefetchIndex) => prevRefetchIndex + 1);
  const refetchI = () => setRefetchIndexI((prevRefetchIndex) => prevRefetchIndex + 1);
  useEffect(() => {
    async function fetchData(url: string) {
      if (!url) {
        return;
      }
      setState((state) => ({ data: state.data, loading: true, error: false }));
      await fetch(encodeURI(url), {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + token
        }
      })
        .then((res) => {
          status = res.status;
          return res.json();
        })
        .then((data) => {
          if (status === 200) {
            setState({ data: data['rows'], loading: false, error: false });
          } else {
            setState({ data: state.data, loading: false, error: true });
          }
        })
        .catch((err) => {
          console.log(err);
          setState({ data: state.data, loading: false, error: true });
        });
    }
    fetchData(url);
  }, [
    url,
    setState,
    refetchIndex,
    refetchIndexU,
    refetchIndexS,
    refetchIndexO,
    refetchIndexI
  ]);
  return [state, setUrl, refetch, refetchU, refetchO, refetchS, refetchI] as const;
};
