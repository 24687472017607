import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ClearIcon from '@mui/icons-material/Clear';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import { StoffGruppeI, StoffKomponent } from './types/StoffTypes';
import { NButton, TableInput } from '../components/Inputs';
import { StoffI } from '../stoff/types/StoffTypes';
import { EnhancedTableHead } from '../../utilities/EnhancedTableHead';
import { HeadCellsSK } from './components/TableHeadersData';
interface StoffkomponentTableProps {
  owned: boolean;
  stoffgruppe: StoffGruppeI;
  newRowData: Partial<StoffKomponent>;
  setNewRowData: (s: Partial<StoffKomponent>) => void;
  stoffData: StoffKomponent[];
  allStoffs: StoffI[];
  setStoffData: (data: StoffKomponent[]) => void;
  handleDelete: (stoffnr: number) => void;
  validateStoff: (stoffnr: number) => boolean;
}

export default function StoffkomponentTable(props: StoffkomponentTableProps) {
  const {
    owned,
    stoffgruppe,
    newRowData,
    stoffData,
    allStoffs,
    setNewRowData,
    setStoffData,
    handleDelete,
    validateStoff
  } = props;
  const [editingRowId, setEditingRowId] = useState<number | null>(null);
  const [editingRowData, setEditingRowData] = useState<Partial<StoffKomponent>>({});
  const [errorArray, setErrorArray] = useState<string[]>([]);
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = useState<keyof StoffKomponent>('STOFFNRID');
  // Effect to reset newRowData when owned becomes false
  useEffect(() => {
    if (!owned) {
      setNewRowData({});
    }
  }, [owned]);

  const handleRequestSort = (property: keyof StoffKomponent) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const sortedRows = [...stoffData].sort((a, b) => {
    if (b[orderBy] < a[orderBy]) return order === 'asc' ? -1 : 1;
    if (b[orderBy] > a[orderBy]) return order === 'asc' ? 1 : -1;
    return 0;
  });
  const validateField = (prop: keyof StoffKomponent, value: any): string | null => {
    const regex = /^[1-9]\d*$/;
    if (prop === 'STOFFNRID') {
      if (!regex.test(value)) {
        return 'STOFFNRID_INVALID';
      }
    } else if (prop === 'MENGDE') {
      if (value === '' || isNaN(value)) {
        return 'MENGDE_INVALID';
      }
    }
    return null;
  };
  // Function to handle changes in the new row's data
  const handleNewRowChange =
    (prop: keyof StoffKomponent) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      const updatedValue =
        HeadCellsSK.find((cell) => cell.id === prop)?.align === 'right'
          ? value === ''
            ? ''
            : value
          : value;
      setNewRowData({ ...newRowData, [prop]: updatedValue });
      const error = validateField(prop, updatedValue);
      if (error) {
        setErrorArray((prev) => [...prev, error]);
      } else {
        setErrorArray((prev) => prev.filter((e) => e !== `${prop}_INVALID`));
      }
    };

  // Function to add the new row to the table
  const addNewItem = () => {
    if (!validateStoff(newRowData.STOFFNRID)) {
      return;
    }
    if (!validateRowData(newRowData)) {
      return;
    }
    const newStoffData = [...stoffData, newRowData as StoffKomponent];
    setNewRowData({});
    setErrorArray([]);
    setStoffData(newStoffData);
  };
  const handleBlur =
    (prop: keyof StoffKomponent) => (event: React.FocusEvent<HTMLInputElement>) => {
      const value = event.target.value;
      if (value) {
        let stoff: StoffI = undefined;
        if (prop === 'STOFFNRID') {
          const stoffNrId = parseInt(value, 10);
          stoff = allStoffs.find((s) => s.stoffnrId === stoffNrId);
        }
        if (prop === 'KORTNAVN') {
          stoff = allStoffs.find(
            (s) => s.kortnavn?.toLowerCase() === value?.toLowerCase()
          );
        }
        if (prop === 'NAVN') {
          stoff = allStoffs.find((s) => s.navn?.toLowerCase() === value?.toLowerCase());
        }
        if (stoff) {
          setNewRowData({
            ...newRowData,
            STOFFNRID: stoff.stoffnrId,
            NAVN: stoff.navn,
            KORTNAVN: stoff.kortnavn
          });
        } else {
          setNewRowData({ ...newRowData, [prop]: value });
        }
      } else {
        setNewRowData({ ...newRowData, [prop]: value });
      }
    };

  const handleEdit = (row: StoffKomponent) => {
    setEditingRowId(row.STOFFNRID as number);
    setEditingRowData({ ...row });
  };

  const handleChange =
    (prop: keyof StoffKomponent) => (event: React.ChangeEvent<HTMLInputElement>) => {
      if (prop === 'STOFFNRID' || prop === 'NAVN' || prop === 'KORTNAVN') {
        return; // Prevent changes to non-editable fields
      }
      const value = event.target.value;
      const updatedValue =
        HeadCellsSK.find((cell) => cell.id === prop)?.align === 'right'
          ? value === ''
            ? ''
            : value
          : value;
      setEditingRowData({ ...editingRowData, [prop]: updatedValue });
      const error = validateField(prop, updatedValue);
      if (error) {
        setErrorArray((prev) => [...prev, error]);
      } else {
        setErrorArray((prev) => prev.filter((e) => e !== `${prop}_INVALID`));
      }
    };

  const handleSave = () => {
    if (validateRowData(editingRowData)) {
      const updatedData = stoffData.map((row) =>
        row.STOFFNRID === editingRowId ? (editingRowData as StoffKomponent) : row
      );
      console.log('editingRowId', editingRowId);

      setStoffData(updatedData);
      setEditingRowId(null);
      setEditingRowData({});
      setErrorArray([]);
    }
  };

  const validateRowData = (data: Partial<StoffKomponent>) => {
    const errors: string[] = [];
    const { STOFFNRID, NAVN, MENGDE, KILDE } = data;

    if (!STOFFNRID) errors.push('STOFFNRID');
    if (!NAVN) errors.push('NAVN');
    if (!MENGDE) errors.push('MENGDE');
    if (!KILDE) errors.push('KILDE');

    if (MENGDE === undefined || MENGDE === +'') {
      errors.push('MENGDE');
    } else if (isNaN(MENGDE as number)) {
      errors.push('MENGDE_INVALID');
    }

    setErrorArray(errors);
    return errors.length === 0;
  };

  const getErrorMessage = (field: string) => {
    if (field === 'MENGDE_INVALID' || field === 'STOFFNRID_INVALID') {
      return 'Ugyldig verdi!';
    } else return 'Obligatorisk!';
  };
  return (
    <TableContainer component={Paper} sx={{ maxHeight: '55vh' }}>
      <Table stickyHeader size="small">
        <EnhancedTableHead<StoffKomponent>
          headCells={HeadCellsSK}
          rightColumns={owned ? [{ name: 'Rediger' }, { name: 'Slett' }] : []}
          sorting={{
            order: order,
            orderBy: orderBy,
            onRequestSort: handleRequestSort
          }}
        />
        <TableBody>
          {sortedRows?.map((row) => (
            <TableRow key={row.STOFFNRID} hover>
              {HeadCellsSK.map((cell, i) => (
                <TableCell key={cell.id} padding={cell.padding} align={cell.align}>
                  {editingRowId === row.STOFFNRID &&
                  cell.id !== 'STOFFNRID' &&
                  cell.id !== 'NAVN' &&
                  cell.id !== 'KORTNAVN' ? (
                    <TextField
                      value={editingRowData[cell.id] || ''}
                      variant="standard"
                      onChange={handleChange(cell.id as keyof StoffKomponent)}
                      error={
                        errorArray.includes(cell.id) ||
                        errorArray.includes(`${cell.id}_INVALID`)
                      }
                      helperText={
                        errorArray.includes(cell.id)
                          ? getErrorMessage(cell.id)
                          : errorArray.includes(`${cell.id}_INVALID`)
                          ? getErrorMessage(`${cell.id}_INVALID`)
                          : ''
                      }
                      size="small"
                      fullWidth
                    />
                  ) : row[cell.id] === 0 ? (
                    0
                  ) : row[cell.id] ? (
                    row[cell.id]
                  ) : (
                    ''
                  )}
                </TableCell>
              ))}
              {owned && (
                <>
                  <TableCell>
                    {row.STOFFNRID === editingRowId ? (
                      <IconButton aria-label="save" onClick={handleSave}>
                        <CheckOutlinedIcon />
                      </IconButton>
                    ) : (
                      <IconButton
                        aria-label="edit"
                        onClick={() => handleEdit(row)}
                        disabled={row.KILDE === 'B'}
                      >
                        <EditOutlinedIcon />
                      </IconButton>
                    )}
                  </TableCell>
                  <TableCell style={{ borderRight: '1px solid lightgrey' }}>
                    <NButton
                      rightIcon={<DeleteOutlineOutlinedIcon />}
                      variant="text"
                      onClick={() => handleDelete(row.STOFFNRID as number)}
                      disabled={!(owned && row.KILDE !== 'B')}
                    />
                  </TableCell>
                </>
              )}
            </TableRow>
          ))}
          {owned && stoffgruppe?.stoffgruppeId === 0 && (
            <TableRow>
              {HeadCellsSK.map((cell, i) => (
                <TableCell key={cell.id || i} padding="normal">
                  {cell.id ? (
                    <TableInput
                      value={newRowData[cell.id as keyof StoffKomponent] || ''}
                      onChange={handleNewRowChange(cell.id as keyof StoffKomponent)}
                      onBlur={handleBlur(cell.id as keyof StoffKomponent)}
                      error={
                        errorArray.includes(cell.id) ||
                        errorArray.includes(`${cell.id}_INVALID`)
                      }
                      helperText={
                        errorArray.includes(cell.id) && newRowData.STOFFNRID
                          ? getErrorMessage(cell.id)
                          : errorArray.includes(`${cell.id}_INVALID`) &&
                            newRowData.STOFFNRID
                          ? getErrorMessage(`${cell.id}_INVALID`)
                          : ''
                      }
                    />
                  ) : (
                    <>&nbsp;</>
                  )}
                </TableCell>
              ))}
              {newRowData?.STOFFNRID ? (
                <TableCell colSpan={2}>
                  <IconButton aria-label="done" onClick={addNewItem} disabled={false}>
                    <CheckOutlinedIcon />
                  </IconButton>
                  <IconButton
                    aria-label="clear"
                    onClick={() => {
                      setNewRowData({});
                      setErrorArray([]);
                    }}
                    disabled={false}
                  >
                    <ClearIcon />
                  </IconButton>
                </TableCell>
              ) : null}
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
