import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import useFetchData from '../../utilities/useFetchData';
import { ProsjektI } from '../../../types/prosjektTypes';
import { useSelector } from 'react-redux';
import * as actions from '../../../types/actions';
import { useDispatch } from 'react-redux';
import { AlertPopUp } from '../../utilities/AlertPopUp';
import useStylesTable from '../../../css/TableCss';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TablePagination,
  Typography
} from '@mui/material';
import { useHistory } from 'react-router';
//import useStyles from '../../../css/materialtheme';

export function HentUndersokelse(props: any) {
  const dispatch = useDispatch();
  const prosjektState = useSelector((state: ProsjektI) => state['prosjekts']);
  const selectedProsjektId = prosjektState['kostholdProsjektId'];
  const fetchedData = useFetchData({
    loadOnMount: true,
    clearDataOnLoad: false,
    projectURL: 'hentUndersokelser',
    subProjectURL: selectedProsjektId
  });
  const [showAlert, setShowAlert] = React.useState(false);
  const [radioButtonSelect, setRadioButtonSelect] = React.useState('');
  const classesTable = useStylesTable();
  const history = useHistory();

  //const classes = useStyles();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  React.useEffect(() => {
    if (fetchedData.error === true) {
      console.log('We have an error' + fetchedData.errorMessage);
      showErrorAlert();
    }
  }, [fetchedData.error]);
  React.useEffect(() => {
    if (selectedProsjektId && radioButtonSelect) {
      history.push(
        `/home/kostholdsdata/undersokelse/${selectedProsjektId}/${radioButtonSelect}`
      );
    }
  }, [radioButtonSelect]);

  const showErrorAlert = () => {
    dispatch({
      type: actions.SET_ALERT_MESSAGE,
      alertMessage: 'Det har oppstått en feil. Vennligst prøv igjen senere.'
    });
    dispatch({ type: actions.SET_ALERT_COLOR, alertColor: 'error' });
    setShowAlert(true);
  };
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    newPage: number
  ) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  return (
    <>
      {showAlert && (
        <AlertPopUp
          isShowing={showAlert}
          hide={() => {
            setShowAlert(!showAlert);
          }}
        />
      )}

      <Typography>Undersøkelser</Typography>
      <Table sx={classesTable.head}>
        <TableHead>
          <TableRow>
            <TableCell>Velg</TableCell>
            <TableCell>Nummer</TableCell>
            <TableCell>Kortnavn</TableCell>
            <TableCell>MatvDB</TableCell>
            <TableCell>Antall pers.</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {fetchedData.data
            ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            ?.map((row, index) => (
              <TableRow
                hover
                role="checkbox"
                key={row.UNDERSOKELSEID}
                /*                 onClick={() => {
                  setRadioButtonSelect(row.UNDERSOKELSEID);
                }} */
                style={index % 2 ? { background: '#C9D7E3' } : { background: 'white' }}
                sx={classesTable.tableRow}
              >
                <TableCell>
                  <FormControl component="fieldset">
                    <RadioGroup
                      key={row.UNDERSOKELSEID}
                      value={radioButtonSelect}
                      onChange={() => setRadioButtonSelect(row.UNDERSOKELSEID)}
                    >
                      <FormControlLabel
                        value={row.UNDERSOKELSEID}
                        control={<Radio />}
                        label=""
                      />
                    </RadioGroup>
                  </FormControl>
                </TableCell>
                <TableCell>{row.UNDERSOKELSEID}</TableCell>
                <TableCell>{row.KORTNAVN}</TableCell>
                <TableCell>{row.NAVN}</TableCell>
                <TableCell>{row.COUNT}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <TablePagination
        //sx={classesTable.Pagination}
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count={fetchedData.data ? fetchedData.data?.length : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}
