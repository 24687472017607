import React, { useEffect } from 'react';
import { StatusTypes } from './CommonTypes';
import { useKeycloak } from '@react-keycloak/web';
import postData from '../../../utilities/postData';

interface PollingProps {
  id: string;
  setFailed: (val: boolean) => void;
  setResult: (result: any) => void;
  setResponse?: (result: any) => void;
}

const PollingComponent: React.FC<PollingProps> = ({
  id,
  setFailed,
  setResult,
  setResponse
}) => {
  const { keycloak } = useKeycloak();
  const token = keycloak?.token;
  useEffect(() => {
    const callApiUntilResultReady = async () => {
      try {
        const baseUrl = process.env.REACT_APP_BACKEND;
        const url = `${baseUrl}/getResultById/${id}`;
        const response = await fetch(encodeURI(url), {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
          }
        });
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        } else {
          const data = await response.json();
          setResponse && setResponse(data);
          if (response.status === 200) {
            if (data.status === StatusTypes.Failed) {
              setFailed(true);
              return;
            } else if (data.status === StatusTypes.New) {
              setFailed(false);
              console.log('Result is not ready yet, retrying in 5 seconds...');
            } else if (data.status === StatusTypes.Completed) {
              setFailed(false);
              console.log('Result is ready:', data.result);
              if (data.result) {
                setResult(data.result);
              }
              await deleteResult(id, token);
              return; // Exit polling if result is ready
            }
          }
        }
      } catch (error) {
        console.error('Error calling API:', error);
        setFailed(true);
        return;
      }
      // Wait for 5 seconds and then call the function again recursively
      await new Promise((resolve) => setTimeout(resolve, 5000));
      await callApiUntilResultReady();
    };

    callApiUntilResultReady();

    // Cleanup function (optional)
    return () => {
      // Add any cleanup logic here
    };
  }, [id]);

  return null; // Since this component is only for functionality, it doesn't render anything
};

export default PollingComponent;

const deleteResult = async (id: string, token: string) => {
  const url = `deleteResultById`;
  const data = {};
  await postData(data, url, id, 'DELETE', token).then((resp) =>
    console.log('resp', resp)
  );
};
