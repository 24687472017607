import React, { useState, useContext, useEffect } from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import { MatvareData } from './types/MatvareTypes';
import { DatabaseContext } from '../../../context/DatabaseContext';
import PageContainer from '../home/PageContainer';
import {
  Alert,
  Dialog,
  DialogContentText,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import useFetchData from '../../utilities/useFetchData';
import AlertComp from '../../utilities/AlertComp';
import { AlertI } from '../../../types/alertTypes';
import PostData from '../../utilities/postData';
import { useKeycloak } from '@react-keycloak/web';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { useFetchNonGET } from '../../utilities/useFetchNonGET';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IsOwnedByUser from '../../utilities/Validate';
import {
  RetensjonsfaktorerI,
  CalculatedMengdesI,
  TilberedningValues
} from './types/StoffTypes';
import useDidMountEffect from '../../utilities/useDidMountEffect';
import moment from 'moment';
import ReactToPrint from 'react-to-print';
import { NButton } from '../components/Inputs';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import useStyles from '../../../css/materialtheme';
import { useUserContext } from '../../../context/AuthContext';
import RetensjonsfaktorerTable from './RetensjonsfaktorerTable';
import { HeadCellsRF } from './components/TableHeadersData';

interface RetensjonsfaktorerProps {
  matvareData: MatvareData;
  currentDatabase?: any;
  setShowStoffer: any;
  showStoffer: boolean;
  match: any;
  owned: boolean;
}
export interface RetentionFactorsToPrintProps {
  user: string;
  database: string;
  matvarekode: number;
  navn: string;
  classesTable: any;
  retFactorData: RetensjonsfaktorerI[];
}
const RetentionFactorsToPrint = React.forwardRef(
  (props: RetentionFactorsToPrintProps, ref: any) => (
    <div ref={ref}>
      <h3>Utskrift av skjermbildet: Retensjonsfaktorer for matvare {props.user}</h3>
      <Grid container spacing={8}>
        <Grid item xs={6}>
          <label>
            Utskriftsdato:
            {moment().format('YYYY-MM-DD hh:mm:ss')}
          </label>
        </Grid>
        <Grid item xs={6}>
          <label>Matvaredatabase: {props.database}</label>
        </Grid>
      </Grid>
      <Grid container spacing={8}>
        <Grid item xs={6}>
          <label>
            Matvare: {props.matvarekode} - {props.navn}
          </label>
        </Grid>
      </Grid>
      <Table sx={props.classesTable.body}>
        <TableHead sx={props.classesTable.head}>
          <TableRow>
            {HeadCellsRF?.map((header) => {
              <TableCell sx={props.classesTable.head}>{header}</TableCell>;
            })}
          </TableRow>
        </TableHead>
        <TableBody sx={props.classesTable.body}>
          {props.retFactorData?.map((row: RetensjonsfaktorerI) => {
            return (
              <TableRow hover role="checkbox" key={row?.STOFFNRID}>
                <TableCell>{row?.STOFFNRID}</TableCell>
                <TableCell>{row?.NAVN}</TableCell>
                <TableCell>{row?.KORTNAVN}</TableCell>
                <TableCell>{row?.MENGDE}</TableCell>
                <TableCell>{row?.KOKING}</TableCell>
                <TableCell>{row?.STEKING}</TableCell>
                <TableCell>{row?.BAKING}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  )
);
export default function Retensjonsfaktorer(props: RetensjonsfaktorerProps) {
  const [title, setTitle] = useState('Retensjonsfaktorer - Nutrifoodcalc');
  const styles = useStyles();
  const loggedInuser = useUserContext();
  const { currentDatabase } = useContext(DatabaseContext);
  const matvarekodeFromURL = props.match.params.kode || 0;
  const navnFromURL = props.match.params.navn || '';
  const matvarenavn = navnFromURL?.replace(/-slash-/g, '/')?.replace(/-percent-/g, '%');
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState<AlertI['alertColor']>('info');
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const { keycloak } = useKeycloak();
  const token = keycloak?.token;
  const backendURI = process.env.REACT_APP_BACKEND;
  const [deleteRetFactor, setDeleteRetFactor] = useFetchNonGET();
  const [retFactorToDelete, setRetFactorToDelete] = useState(0);
  const [comfirmDeletingRetFactor, setComfirmDeletingRetFactor] = useState(false);
  const [owned, setOwned] = useState(false);
  const [doneFetching, setDoneFetching] = useState<boolean>(false);
  const [retFactorData, setRetFactorData] = React.useState<RetensjonsfaktorerI[]>([]);

  document.title = title;
  const printRef = React.useRef();

  const retensjonsfaktorIMatvare = useFetchData({
    loadOnMount: true,
    clearDataOnLoad: false,
    projectURL: 'getRetensjonsfaktorerIMatvare',
    subProjectURL: `${currentDatabase?.value}/${matvarekodeFromURL}`
  });
  const calculatedMengde = useFetchData({
    loadOnMount: false,
    clearDataOnLoad: false,
    projectURL: 'getCalculatedMengde',
    subProjectURL: `${currentDatabase?.value}/${matvarekodeFromURL}`
  });
  // Here we fetch the stoffs that are in the matvare. If the url changes or the stoffgruppe. We Fetch again.
  useEffect(() => {
    if (matvarekodeFromURL > 0 && !doneFetching) {
      setTitle(`Retensjonsfaktorer - ${matvarekodeFromURL} - Nutrifoodcalc`);
      setOwned(IsOwnedByUser({ id: matvarekodeFromURL, u: loggedInuser }));
      retensjonsfaktorIMatvare.loadData(
        'getRetensjonsfaktorerIMatvare',
        `${currentDatabase?.value}/${matvarekodeFromURL}`
      );
      setDoneFetching(true);
    }
  }, [matvarekodeFromURL, retensjonsfaktorIMatvare]);

  useEffect(() => {
    if (retensjonsfaktorIMatvare.data && retensjonsfaktorIMatvare.isLoaded) {
      const data: RetensjonsfaktorerI[] = retensjonsfaktorIMatvare.data as unknown as
        | RetensjonsfaktorerI[]
        | [];
      const modifiedData =
        data?.map((row: RetensjonsfaktorerI) => {
          return {
            ...row,
            MENGDE: parseFloat(row?.MENGDE?.toString()?.replace(/[,]/g, '.')),
            KOKING: parseFloat(row?.KOKING?.toString()?.replace(/[,]/g, '.')),
            STEKING: parseFloat(row?.STEKING?.toString()?.replace(/[,]/g, '.')),
            BAKING: parseFloat(row?.BAKING?.toString()?.replace(/[,]/g, '.'))
          };
        }) || [];
      setRetFactorData(modifiedData);
    }
  }, [retensjonsfaktorIMatvare.data]);

  const isObjectPopulated = (currentValue: any) => {
    return Object.keys(currentValue).length > 0;
  };

  useDidMountEffect(() => {
    if (calculatedMengde.data && calculatedMengde.isLoaded) {
      const data: CalculatedMengdesI =
        calculatedMengde.data as unknown as CalculatedMengdesI;
      if (data?.MENGDE !== data?.BEREGNETMENGDE) {
        callAlert(
          `Lagringen er fullført men Beregnet energi ${data?.BEREGNETMENGDE} er forskjellig fra lagret verdi ${data?.MENGDE}`,
          'warning'
        );
      } else {
        callAlert(`Lagringen er fullført!`, 'success');
      }
    }
  }, [calculatedMengde.data]);
  useEffect(() => {
    if (calculatedMengde.error) {
      callAlert(
        `Lagringen er fullført men feil ved henting av beregnet energi (eller ingen energigivende stoffer)`,
        'warning'
      );
    }
  }, [calculatedMengde.error]);

  const callAlert = (message: string, info: AlertI['alertColor']) => {
    setAlertMessage(message);
    setAlertSeverity(info);
    setShowAlert(true);
  };

  const isRetFactorValid = (value: number) => {
    if (value > 1) {
      return false;
    } else {
      return true;
    }
  };
  const isInputValid = (value: string | number) => {
    if (value === '') {
      return false;
    } else if (value === undefined) {
      return false;
    } else if (value === null) {
      return false;
    } else {
      return true;
    }
  };
  const handleSaveRetentionFactors = async () => {
    let isValid: boolean;
    for (const rf of retFactorData) {
      if (isObjectPopulated(rf)) {
        isValid =
          isInputValid(rf.KORTNAVN) &&
          isInputValid(rf.MENGDE) &&
          isRetFactorValid(+rf.KOKING) &&
          isRetFactorValid(+rf.STEKING) &&
          isRetFactorValid(+rf.BAKING);
      }
      if (!isValid) {
        if (+rf.KOKING > 1) {
          callAlert(
            `Retensjonsfaktor ${rf.KOKING} for koking kan ikke større enn 1!`,
            'error'
          );
        } else if (+rf.STEKING > 1) {
          callAlert(
            `Retensjonsfaktor ${rf.STEKING} for steking kan ikke større enn 1!`,
            'error'
          );
        } else if (+rf.BAKING > 1) {
          callAlert(
            `Retensjonsfaktor ${rf.BAKING} for baking kan ikke større enn 1!`,
            'error'
          );
        } else {
          callAlert(`Stoffnrid, kortnavn og mengde kan ikke være tomt!`, 'error');
        }
        return;
      }
    }
    if (isValid) {
      let data: TilberedningValues[] = [];
      const dbId = currentDatabase?.value;
      const dataurl = `${dbId}/${matvarekodeFromURL}`;
      retFactorData.map((retFactor: RetensjonsfaktorerI) => {
        if (retFactor.STOFFNRID > 0) {
          const reqbody: TilberedningValues = {
            STOFFNRID: retFactor.STOFFNRID,
            KOKING: retFactor.KOKING,
            STEKING: retFactor.STEKING,
            BAKING: retFactor.BAKING
          };
          data.push(reqbody);
        }
      });
      PostData({ data: data }, 'saveRetensjonsfaktorer', dataurl, 'POST', token).then(
        (resp) => {
          if (resp.status === 200) {
            calculatedMengde.loadData(
              'getCalculatedMengde',
              `${dbId}/${matvarekodeFromURL}`
            );
          } else if (resp.status === 500) {
            console.log('An error 500', resp.status);
            callAlert(
              'Lagringen mislyktes. Det har ikke blitt gjort endringer mot databasen.',
              'error'
            );
          }
        }
      );
    }
  };
  const selectRetFactorToDelete = (stoffnr: number) => {
    setRetFactorToDelete(stoffnr);
    setComfirmDeletingRetFactor(true);
  };
  const waitAndFetchRetentionFactors = async () => {
    const timer = setTimeout(() => {
      retensjonsfaktorIMatvare.loadData(
        'getRetensjonsfaktorerIMatvare',
        `${currentDatabase?.value}/${matvarekodeFromURL}`
      );
    }, 500);
    return () => clearTimeout(timer);
  };

  const deleteRetentionFactor = async () => {
    let url = `${backendURI}/deleteRetensjonsfaktorer/${currentDatabase?.value}/${matvarekodeFromURL}/${retFactorToDelete}`;
    let method = 'DELETE';
    let data = {};
    setDeleteRetFactor({ url, data, method });
  };

  const removeFromMatvareLocal = async () => {
    setRetFactorData((prevState) =>
      prevState.filter((retFactor) => {
        if (retFactor.STOFFNRID === retFactorToDelete) {
          retFactor.BAKING = null;
          retFactor.STEKING = null;
          retFactor.KOKING = null;
        }
      })
    );
  };
  const handleClickJa = async () => {
    await deleteRetentionFactor();
    await removeFromMatvareLocal();
    waitAndFetchRetentionFactors();
    closeDialog();
  };
  const handleClickNei = () => {
    closeDialog();
  };
  const closeDialog = () => {
    setComfirmDeletingRetFactor(false);
  };

  useEffect(() => {
    if (deleteRetFactor.status === 200) {
      callAlert('Slettingen er fullført', 'success');
    } else if (deleteRetFactor.status > 400) {
      callAlert('Slettingen mislyktes. Ingen sletting er utført mot databasen.', 'error');
    }
  }, [deleteRetFactor.status]);

  return (
    <PageContainer>
      <Typography variant="h4">Retensjonsfaktorer for matvare</Typography>
      <Grid container spacing={2}>
        <Grid item xs={6} container alignItems={'flex-start'}>
          <InputLabel style={{ fontSize: 18 }}>
            Matvare: {matvarekodeFromURL} - {matvarenavn || ''}
          </InputLabel>
        </Grid>
        <Grid item xs={6}>
          <Stack direction="row-reverse" spacing={2}>
            <ReactToPrint
              trigger={() => (
                <NButton
                  children="Skriv ut"
                  rightIcon={<PrintOutlinedIcon />}
                  variant="text"
                  disabled={retFactorData?.length === 0}
                />
              )}
              pageStyle=".styledTable{width:100%;border-collapse:collapse;text-align:left}.styledTable th,.styledTable td{border:2px solid black;padding:0.5em;}"
              content={() => printRef.current}
            />
          </Stack>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Alert severity="info" sx={{ marginTop: '1em' }}>
            Bruk punktum(.) i stedet for komma(,) for desimal verdier.
          </Alert>
        </Grid>
      </Grid>
      <Box sx={{ display: 'none' }}>
        <RetentionFactorsToPrint
          user={loggedInuser?.user?.username}
          database={currentDatabase['label']}
          matvarekode={matvarekodeFromURL}
          navn={matvarenavn}
          classesTable={styles}
          retFactorData={retFactorData}
          ref={printRef}
        />
      </Box>
      {showAlert && (
        <AlertComp
          alertMessage={alertMessage}
          alertColor={alertSeverity}
          showAlert={showAlert}
          setShowAlert={setShowAlert}
        />
      )}
      {comfirmDeletingRetFactor && (
        <Dialog
          maxWidth="xs"
          fullWidth
          open={comfirmDeletingRetFactor}
          onClose={closeDialog}
          aria-labelledby="slettRetensjonsfaktorer"
        >
          <DialogTitle id="slettRetensjonsfaktorer">Slett retensjonsfaktorer</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Vil du slette alle retensjonsfaktorene til StoffId:{' '}
              <b>{retFactorToDelete}</b> ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClickJa} color="primary">
              Ja
            </Button>
            <Button onClick={handleClickNei} color="primary" autoFocus>
              Nei
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {retensjonsfaktorIMatvare.isLoaded ? (
        <RetensjonsfaktorerTable
          retFactorData={retFactorData}
          setRetFactorData={setRetFactorData}
          handleDelete={selectRetFactorToDelete}
          owned={owned}
        />
      ) : (
        <Box>Loading...</Box>
      )}
      <Box display={'flex'} sx={{ padding: 2 }}>
        <NButton
          children="Lagre"
          onClick={handleSaveRetentionFactors}
          disabled={!owned}
        />
      </Box>
    </PageContainer>
  );
}
