import { TableCellProps } from '@mui/material';
import { AlignPropertyParams } from '@mui/material/styles/cssUtils';
import { PaddingProps } from '@mui/system';

export interface ItemOwner {
  id: number;
  brukerkode: string;
}
export interface EndringsloggI {
  sekvens_nr: number;
  tidspunkt: string;
  brukerkode: string;
  tekst: string;
}
export interface OptionType {
  value: number;
  label: string;
}
export interface OptionTypeS {
  value: string;
  label: string;
}
export interface CodeRegisterI {
  nr: number;
  label: string;
  value?: number;
}
export interface TabI {
  idx: number;
  tabType: string;
  label: string;
}
export enum StatusTypes {
  New = 'new',
  Processing = 'processing',
  Completed = 'completed',
  Failed = 'failed'
}
export const statusTypesNorsk = {
  [StatusTypes.New]: 'Lagt i kø',
  [StatusTypes.Processing]: 'Beregner',
  [StatusTypes.Completed]: 'Ferdig',
  [StatusTypes.Failed]: 'Feil'
};

// export interface HeadCell<T> {
//   id: Extract<keyof T, string>;
//   label: string;
//   numeric?: boolean;
//   disablePadding?: boolean;
//   width?: number;
// }

export interface HeadCell<T> {
  id: Extract<keyof T, string>;
  label: string;
  padding?: TableCellProps['padding'];
  align?: TableCellProps['align'];
  sx?: TableCellProps['sx'];
  width?: number;
}

export interface NewMatvare {
  newMatvarekode: number;
  newNavn: string;
}
