import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import useFetchData from '../../utilities/useFetchData';
import { HentUndersokelse } from './HentUndersokelse';
import { useDispatch } from 'react-redux';
import * as actions from '../../../types/actions';
import {
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  //Paper,
  Radio,
  RadioGroup,
  TablePagination,
  Typography
} from '@mui/material';
import AlertComp from '../../utilities/AlertComp';
import EmptyTable from './components/EmptyTable';
import useStylesTable from '../../../css/TableCss';
//import useStyles from '../../../css/materialtheme';
import { AlertI } from '../../../types/alertTypes';
import PageContainer from '../home/PageContainer';

export function HentKosthold(props: any) {
  const fetchedData = useFetchData({
    loadOnMount: true,
    clearDataOnLoad: false,
    projectURL: 'hentProsjekter',
    subProjectURL: null
  });
  const [showUndersokelse, setShowUndersokelse] = React.useState(false);
  const [showAlert, setShowAlert] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selectedRow, setSelectedRow] = React.useState(null);
  const dispatch = useDispatch();
  const classesTable = useStylesTable();
  //const classes = useStyles();

  const [radioButtonSelect, setRadioButtonSelect] = React.useState('');
  const [alertMessage, setAlertMessage] = React.useState('');
  const [alertSeverity, setAlertSeverity] = React.useState<AlertI['alertColor']>('info');
  const [title, setTile] = React.useState('Nutrifoodcalc');
  document.title = title;

  React.useEffect(() => {
    setTile('Kosthold data - Nutrifoodcalc');
  }, []);

  React.useEffect(() => {
    if (fetchedData.isLoaded) {
      showInfoAlert();
      if (fetchedData.data?.length > 0) {
        setRadioButtonSelect(fetchedData.data[0]?.PROSJEKTID);
        selectProsjekt(fetchedData.data[0]?.PROSJEKTID);
      }
    }
  }, [fetchedData.isLoaded]);

  React.useEffect(() => {
    if (fetchedData.error === true) {
      console.log('We have an error' + fetchedData.errorMessage);
      showErrorAlert();
    }
  }, [fetchedData.error]);

  const showErrorAlert = () => {
    setAlertSeverity('error');
    setAlertMessage(
      'System error! Please contact technical administrator. ' + fetchedData.errorMessage
    );
    setShowAlert(true);
  };

  const selectProsjekt = (prosjektId: number) => {
    dispatch({
      type: actions.SET_KOSTHOLD_PROSJEKT_ID,
      kostholdProsjektId: prosjektId
    });
    setShowUndersokelse(true);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    newPage: number
  ) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const showInfoAlert = () => {
    setAlertSeverity('info');
    setAlertMessage('Klikk på prosjektnummer til å se undersøkelser');
    setShowAlert(true);
  };

  return (
    <PageContainer>
      <Container
        id="HentKosthold"
        style={{
          paddingTop: 55
        }}
      >
        {showAlert && (
          <AlertComp
            alertMessage={alertMessage}
            alertColor={alertSeverity}
            showAlert={showAlert}
            setShowAlert={setShowAlert}
          />
        )}
        <Typography variant="h4">Hent kostholdsdata</Typography>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Typography>Prosjekter</Typography>
            <Table sx={classesTable.head}>
              <TableHead>
                <TableRow>
                  <TableCell>Velg</TableCell>
                  <TableCell>Nummer</TableCell>
                  <TableCell>Kortnavn</TableCell>
                  <TableCell>Antall undersøk.</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {fetchedData.data
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((row, index) => {
                    const highlighted =
                      selectedRow && selectedRow.PROSJEKTID === row.PROSJEKTID;
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        key={row.PROSJEKTID}
                        onClick={() => {
                          setSelectedRow(highlighted ? null : row);
                          selectProsjekt(row.PROSJEKTID);
                          setRadioButtonSelect(row.PROSJEKTID);
                        }}
                        style={
                          index % 2 ? { background: '#C9D7E3' } : { background: 'white' }
                        }
                      >
                        <TableCell>
                          <FormControl component="fieldset">
                            <RadioGroup
                              key={row.PROSJEKTID}
                              value={radioButtonSelect}
                              onChange={() => {
                                setRadioButtonSelect(row.PROSJEKTID);
                              }}
                            >
                              <FormControlLabel
                                value={row.PROSJEKTID}
                                control={<Radio />}
                                label=""
                              />
                            </RadioGroup>
                          </FormControl>
                        </TableCell>
                        <TableCell>{row.PROSJEKTID}</TableCell>
                        <TableCell>{row.KORTNAVN}</TableCell>
                        <TableCell>{row.COUNT}</TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 100]}
              component="div"
              count={fetchedData.data ? fetchedData.data?.length : 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Grid>
          <Grid item xs={6}>
            {showUndersokelse ? (
              <HentUndersokelse prosjekId={selectedRow?.PROSJEKTID} />
            ) : (
              <EmptyTable />
            )}
          </Grid>
        </Grid>
      </Container>
    </PageContainer>
  );
}
