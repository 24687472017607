import React from 'react';
import * as XLSX from 'xlsx';
import DownloadIcon from '@mui/icons-material/Download';
import { Link, Stack } from '@mui/material';
import { NButton } from '../components/Inputs';
import { useUserContext } from '../../../context/AuthContext';

interface StoffVerdierProps {
  currentDatabase: any;
  currentUser: string;
  singleNutrient: any[];
  stoffVerdier: any[];
}

export default function StoffVerdier(props: StoffVerdierProps) {
  const getStoffVerdier = async () => {
    const currentTime = getTime();
    const xcelFileName = props.currentUser + currentTime;
    const stuffVerdierArray = await convertObjToArray(props.stoffVerdier);

    const xcelFileInfo = [
      [`Stoffverdier for alle matvarer for ${props.singleNutrient[0]?.NAVN}`],
      [`Matvaredatabase ${props.currentDatabase['label']}`]
    ];
    await exportToExcel(xcelFileName, xcelFileInfo, stuffVerdierArray);
  };

  const convertObjToArray = async (object: any) => {
    let newArray = await object.map((row: any) => {
      return Object.values(row);
    });
    newArray.unshift(['Matvarekode', 'Navn', 'g', 'Kilde', 'Oppskrift']);
    return newArray;
  };
  /* Export To Excel */

  const exportToExcel = async (user: string, info: any, data: any) => {
    const worksheet = XLSX.utils.aoa_to_sheet(info);
    XLSX.utils.sheet_add_aoa(worksheet, data, { origin: -1 });
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'SheetJS');
    //generate XLSX file and send to client
    XLSX.writeFile(workbook, user + '.xlsx');
  };

  const getTime = () => {
    const now = new Date();
    const day = now.getDate(); //19
    const month = now.getMonth() + 1; //1
    const year = now.getFullYear(); //2021
    const hours = now.getHours(); //10
    const minutes = now.getMinutes(); //
    const timeFormat = `_${hours}${minutes}-${day}-${month}-${year}`;
    return timeFormat;
  };
  return (
    <>
      <Stack spacing={1} position="sticky" top="10rem">
        <Link onClick={getStoffVerdier} sx={{ width: '100%', color: 'inherit' }}>
          <NButton
            children="Matvarer stoffverdier"
            rightIcon={<DownloadIcon />}
            variant="text"
            color="inherit"
            fullWidth
          />
        </Link>
      </Stack>
    </>
  );
}
