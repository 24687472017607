import React, { useRef } from 'react';
import ReactToPrint from 'react-to-print'; //, { useReactToPrint }
import { DialogBox } from '../../../utilities/DialogBox';
import MatvareReport from './MatvareRapport';
import useFetchData from '../../../utilities/useFetchData';
import { IconButton } from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import * as XLSX from 'xlsx';
import moment from 'moment';
import SingleMatvareReport from './MatvareRapport';
import GetAppIcon from '@mui/icons-material/GetApp';
import { useUserContext } from '../../../../context/AuthContext';

interface MatvarePrintProps {
  showReports: any;
  setShowReports: any;
  currentDatabase: any;
  matvareKodeId: number | string;
}

export default function MatvarePrintReport(props: MatvarePrintProps) {
  const componentRef = useRef(null);

  const getMatvareReport = useFetchData({
    loadOnMount: true,
    clearDataOnLoad: false,
    projectURL: 'getMatvareReport',
    subProjectURL: `${props.currentDatabase?.value}/${props.matvareKodeId}/${props.matvareKodeId}`
  });

  const getMatvaregruppeReport = useFetchData({
    loadOnMount: true,
    clearDataOnLoad: false,
    projectURL: 'getMatvaregruppeReport',
    subProjectURL: `${props.currentDatabase?.value}/${props.matvareKodeId}`
  });

  const getMengdeenheter = useFetchData({
    loadOnMount: true,
    clearDataOnLoad: false,
    projectURL: 'fetchMengdeenheter',
    subProjectURL: `${props.currentDatabase?.value}/${props.matvareKodeId}`
  });

  const getMatvareIngredienser = useFetchData({
    loadOnMount: true,
    clearDataOnLoad: false,
    projectURL: 'getMatvareIngredienser',
    subProjectURL: `${props.currentDatabase?.value}/${props.matvareKodeId}`
  });
  const getMatvareStoff = useFetchData({
    loadOnMount: true,
    clearDataOnLoad: false,
    projectURL: 'getMatvareStoffer',
    subProjectURL: `${props.currentDatabase?.value}/${props.matvareKodeId}`
  });

  const hideReport = () => {
    props.setShowReports(false);
  };

  const getTime = () => {
    const now = new Date();
    const day = now.getDate(); //19
    const month = now.getMonth() + 1; //1
    const year = now.getFullYear(); //2021
    const hours = now.getHours(); //10
    const minutes = now.getMinutes(); //
    const timeFormat = `_${hours}${minutes}-${day}-${month}-${year}`;
    return timeFormat;
  };

  const convertObjToArray = async (object: any, type: string) => {
    if (type === 'mengdeenheter') {
      let newArray = await object.map((row: any) => {
        return [row.MENGDEENHETID, row.MENGDE];
      });

      return newArray;
    } else if (type === 'matvareGrupper') {
      let newArray = await object.map((row: any) => {
        return [row.GRUPPETYPEID, row.BESKRIVELSE, row.NODENRID, row.NAVN];
      });

      return newArray;
    } else if (type === 'ingredienser') {
      let newArray = await object.map((row: any) => {
        return [row.INGREDIENSID, row.NAVN, row.MENGDE, row.MENGDEENHET];
      });

      return newArray;
    } else if (type === 'stoff') {
      let newArray = await object.map((row: any) => {
        return [row.KORTNAVN, row.MENGDE, row.EMHET, row.KILDE];
      });
      return newArray;
    }
  };

  const createExcelData = async (currentUser: string) => {
    // Create Headers and metadata for the files
    const currentTime = getTime();
    const xcelFileName = currentUser + currentTime;
    const M: SingleMatvareReport = getMatvareReport.data[0];

    let mainFields = [
      [
        'Kode',
        M['MATVAREKODEID'],
        'Navn',
        M['NAVN'],
        'Opprettet',
        moment(M['OPPRETTET'], 'DD-MM-YYYY').format('DD-MM-YYYY'),
        'Endret',
        moment(M['ENDRET'], 'DD-MM-YYYY').format('DD-MM-YYYY')
      ],
      ['Beskrivelse', M['BESKRIVELSE']],
      ['Referense', M['REFERANSE']],
      [
        'Maks.mengde:',
        M['MAKSIMALMENGDE'],
        'Spiselig del:',
        M['SPISELIG'],
        'Vektendring:',
        M['VEKTENDRING']
      ],
      ['MVT-nr', M['REFERANSE'], 'SSBnr', M['SSBNR'], 'Sedon', M['SEDON']]
    ];
    let mengdeenheterItems = await convertObjToArray(
      getMengdeenheter.data,
      'mengdeenheter'
    );
    let mengdeenheter = [['Mengdeenheter'], ...mengdeenheterItems];

    let matvareGrupperItems = await convertObjToArray(
      getMatvaregruppeReport.data,
      'matvareGrupper'
    );
    let matvareGrupper = [['Matvaregrupper'], ...matvareGrupperItems];

    let matvareIngredienserItems = await convertObjToArray(
      getMatvareIngredienser.data,
      'ingredienser'
    );
    let matvareIngrediense = [['Ingredienser'], ...matvareIngredienserItems];

    let matvareStoffItems = await convertObjToArray(getMatvareStoff.data, 'stoff');

    let matvareStoff = [
      ['Stoffkomponenter pr 100 g matvare'],
      [['Navn'], ['Innhold'], [], ['Referanse']],
      ...matvareStoffItems
    ];

    let allData = [
      ...mainFields,
      ...mengdeenheter,
      ...matvareGrupper,
      ...matvareIngrediense,
      ...matvareStoff
    ];

    const xcelFileInfo = [
      [`Alt om en matvare`],
      [`Matvaredatabase ${props.currentDatabase['label']}`]
    ];
    await exportToExcel(xcelFileName, xcelFileInfo, allData);
  };

  const exportToExcel = async (user: string, info: any, data: any) => {
    const worksheet = XLSX.utils.aoa_to_sheet(info);
    XLSX.utils.sheet_add_aoa(worksheet, data, { origin: -1 });

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'SheetJS');
    //generate XLSX file and send to client
    XLSX.writeFile(workbook, user + '.xlsx');
  };
  const loggedInuser = useUserContext();

  return (
    <DialogBox title={'Rapport'} isShowing={props.showReports} hide={hideReport}>
      <div style={{ width: 600 }}>
        <div style={{ display: 'none' }}>
          {/*Its a littlebit ugly but they all need to be loaded before we can access the Report*/}
          {getMatvareReport.isLoaded &&
            getMengdeenheter.isLoaded &&
            getMatvaregruppeReport.isLoaded &&
            getMatvareIngredienser.isLoaded &&
            getMatvareStoff.isLoaded && (
              <MatvareReport
                ref={componentRef}
                reportData={getMatvareReport.data}
                reportMengdeenheter={getMengdeenheter.data}
                reportMatvaregruppe={getMatvaregruppeReport.data}
                reportMatvareIngredianse={getMatvareIngredienser.data}
                reportMatvareStoff={getMatvareStoff.data}
              />
            )}
        </div>
        <div style={{ padding: 15 }}>
          <div>
            Hent excel fil
            <IconButton
              onClick={() => createExcelData(loggedInuser?.user?.username)}
              color="primary"
            >
              <GetAppIcon />
            </IconButton>
          </div>
        </div>

        <div style={{ padding: 15 }}>
          Skriv ut PDF rapport
          <IconButton aria-label="print" color="primary">
            <ReactToPrint
              trigger={() => <PrintIcon />}
              pageStyle="@page { size: auto; padding: 55} @media print { body { page-break-before: always; -webkit-print-color-adjust: exact; } }"
              content={() => componentRef.current}
            />
          </IconButton>
        </div>
        <div></div>
      </div>
    </DialogBox>
  );
}
