import React from 'react';
import useFetchData from '../../../utilities/useFetchData';
import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  Radio,
  RadioGroup,
  TextField
} from '@mui/material';
import { DialogBox } from '../../../utilities/DialogBox';
import { ConfirmationDialog } from '../../../utilities/ConfirmationDialog';
import { useKeycloak } from '@react-keycloak/web';
import { Dropdown, NButton, SlowText } from '../../components/Inputs';
import IsOwnedByUser from '../../../utilities/Validate';
import { useUserContext } from '../../../../context/AuthContext';
import { useProjectContext } from '../../../../context/ProjectContext';
import { CountResult, ProsjektI } from '../types/ProjectTypes';
import useDidMountEffect from '../../../utilities/useDidMountEffect';
interface ProjectData {
  PROSJEKTID: string;
  KORTNAVN: string;
  PROSJEKTNAVN: string;
}

interface CopyUndersokelseProps {
  projectId: string;
  undersokelseId: string;
  projectName: string;
  undersokelseName: string;
  setRefetchSurvey: (value: boolean) => void;
  setProgressBar: (a: boolean) => void;
  showProgressAlert: (a: string, b: string) => void;
  navigateToUnd: (a: number, b: number) => void;
  isShowing: boolean;
  hide: () => void;
}

export const CopyUndersokelse = (props: CopyUndersokelseProps) => {
  const {
    navigateToUnd,
    projectId,
    undersokelseId,
    projectName,
    undersokelseName,
    setRefetchSurvey,
    setProgressBar,
    showProgressAlert,
    isShowing,
    hide
  } = props;
  const loggedInuser = useUserContext();
  const [projects, setProjects] = React.useState<ProjectData[]>([]);
  const [copyOptionsValue, setCopyOptionsValue] = React.useState('1');
  const [confirmationResult, setConfirmationResult] = React.useState(false);
  const [newProjectId, setNewProjectId] = React.useState(projectId);
  const [defaultProject, setDefaultProject] = React.useState(projectId);
  const [newUndersokelseId, setNewUndersokelseId] = React.useState('');
  const [showCDialog, setShowCDialog] = React.useState(false);
  const [dialogText, setDialogText] = React.useState('');
  const [dialogTitle, setDialogTitle] = React.useState('');
  const [notification, setNotification] = React.useState({ error: null, message: '' });
  const [isTouched, setIsTouched] = React.useState(false);
  const { keycloak } = useKeycloak();
  const token = keycloak.token || 'Mangler token';
  const projs = useProjectContext();

  React.useEffect(() => {
    const url = `${process.env.REACT_APP_BACKEND}/listOverProsjekter/1`;

    fetch(encodeURI(url), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    })
      .then((resp) => resp.json())
      .then((resp) => setProjects(resp.rows));
  }, []);

  const doesUndersokelseExist = useFetchData({
    loadOnMount: false,
    clearDataOnLoad: false,
    projectURL: 'checkIfUndersokelseExists',
    subProjectURL: `${projectId}/${undersokelseId}`
  });

  const allProjects = useFetchData({
    loadOnMount: false,
    clearDataOnLoad: false,
    projectURL: 'hentProsjekterOgUndersokelser',
    subProjectURL: null
  });
  React.useEffect(() => {
    if (allProjects.data) {
      const data = allProjects.data as unknown as ProsjektI[];
      if (data.length > 0) {
        projs.setProjects(data);
      }
    }
  }, [allProjects]);

  useDidMountEffect(() => {
    if (doesUndersokelseExist.data) {
      const data = doesUndersokelseExist.data as unknown as CountResult;
      if (data.COUNT > 0) {
        showProgressAlert(
          'Det er ikke lov til å kopiere en undersøkelse inn til en eksisterende undersøkelse.',
          'error'
        );
      } else {
        console.log('ingen');
        let currentId = defaultProject;
        if (newProjectId !== projectId) {
          currentId = newProjectId;
        }
        setNewProjectId(currentId);
        showCopyConfirmation(
          `Vil du virkelig kopiere undersøkelse ${undersokelseId} under prosjekt ${projectId} til undersøkelse ${newUndersokelseId} under prosjekt ${currentId}?`
        );
      }
    }
  }, [doesUndersokelseExist.data]);

  React.useEffect(() => {
    if (doesUndersokelseExist.error) {
      showProgressAlert(
        'Det har oppstått en feil. Vennligst prøv igjen senere.',
        'error'
      );
      hide();
    }
  }, [doesUndersokelseExist.error]);

  React.useEffect(() => {
    if (confirmationResult) {
      let url = '';
      if (projectId && undersokelseId && newUndersokelseId) {
        if (copyOptionsValue === '1') {
          url = `copyUndersokelse/${newProjectId}/${newUndersokelseId}/${projectId}/${undersokelseId}/false`;
        } else if (copyOptionsValue === '2') {
          url = `copyUndersokelse/${newProjectId}/${newUndersokelseId}/${projectId}/${undersokelseId}/true`;
        }
        setProgressBar(true);
        setShowCDialog(false);
        copy(url);
      } else {
        setShowCDialog(false);
        setProgressBar(false);
      }
    }
    setConfirmationResult(false);
  }, [confirmationResult, copyOptionsValue]);

  const copy = (url: string) => {
    if (url !== '') {
      const fullUri = `${process.env.REACT_APP_BACKEND}/${url}`;
      fetch(encodeURI(fullUri), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token
        }
      })
        .then((resp) => {
          if (resp.status === 200) {
            setShowCDialog(false);
            showProgressAlert('Kopieringen er fullført.', 'success');
            setProgressBar(false);
            setNewProjectId('');
            setNewUndersokelseId('');
            hide();
            //TODOFix navigation to the copied undersokelse
            //navigateToUnd(+newProjectId, +newUndersokelseId);
          } else {
            setShowCDialog(false);
            showProgressAlert('Kopieringen er IKKE fullført.', 'error');
            setProgressBar(false);
          }
        })
        .catch((err) => {
          console.log('We are in the catch', err);
          setShowCDialog(false);
          showProgressAlert('Kopieringen er IKKE fullført.', 'error');
          setProgressBar(false);
        });
    }
  };
  React.useEffect(() => {
    if (newUndersokelseId === '') {
      setNotification({
        error: false,
        message: ''
      });
      setIsTouched(false);
    } else {
      setIsTouched(true);
    }
  }, [newUndersokelseId]);
  React.useEffect(() => {
    const regex = /^[0-9]*$/;
    if (!newUndersokelseId && isTouched) {
      setNotification({
        error: true,
        message: 'Kan ikke bli tom'
      });
    } else if (!regex.test(newUndersokelseId) && isTouched) {
      setNotification({
        error: true,
        message: 'Kun tall'
      });
    } else {
      setNotification({
        error: false,
        message: ''
      });
    }
  }, [newUndersokelseId, isTouched]);
  const hadleOnClickCopy = () => {
    if (projectId && undersokelseId) {
      let currentId = defaultProject;
      if (newProjectId !== projectId) {
        currentId = newProjectId;
      }
      setNewProjectId(currentId);
      doesUndersokelseExist.loadData(
        `checkIfUndersokelseExists`,
        `${currentId}/${newUndersokelseId}`
      );
    }
  };
  const showCopyConfirmation = (text: string) => {
    setDialogTitle('Kopi Undersøkelse');
    setDialogText(text);
    setProgressBar(false);
    setShowCDialog(true);
  };
  const handleChangeCopyOptions = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCopyOptionsValue((event.target as HTMLInputElement).value);
  };

  const filteredProjectId = projects
    ? projects?.filter((row) => {
        return IsOwnedByUser({ id: +row.PROSJEKTID, u: loggedInuser });
      })
    : null;

  const getDefaultPId = () => {
    let currentPId = IsOwnedByUser({ id: +projectId, u: loggedInuser });
    if (!currentPId) {
      setDefaultProject(filteredProjectId[0]?.PROSJEKTID);
    }
  };

  React.useEffect(() => {
    if (filteredProjectId !== null) {
      getDefaultPId();
    }
  }, [filteredProjectId]);
  const style = {
    width: 800,
    height: 400
  };

  return (
    <>
      {showCDialog && (
        <ConfirmationDialog
          title={dialogTitle}
          text={dialogText}
          setConfirmationResult={setConfirmationResult}
          hide={() => {
            setShowCDialog(!showCDialog);
          }}
        />
      )}
      {isShowing && (
        <DialogBox title="Kopi undersøkelse" isShowing={isShowing} hide={hide}>
          <div id="copyOptionsDiv" style={style}>
            <div>
              <InputLabel>
                Prosjekt: {projectId} {projectName}
              </InputLabel>
              <InputLabel>
                Undersøkelse: {undersokelseId} {undersokelseName}
              </InputLabel>
            </div>
            <Divider />
            <Box borderColor="secondary.main" padding={2}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Kopieringsmetode</FormLabel>
                <RadioGroup
                  aria-label="position"
                  name="copyOptions"
                  value={copyOptionsValue}
                  onChange={handleChangeCopyOptions}
                >
                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="Kopi av innholdet i skjermbildet"
                  />
                  <FormControlLabel
                    value="2"
                    control={<Radio />}
                    label="Kopi av hele undersøkelsen med underliggende informasjon"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
            <Grid container item xs={12} spacing={1} alignItems="flex-end">
              <Grid item xs={4}>
                <Dropdown
                  header="Prosjektsnummer *"
                  value={newProjectId}
                  options={filteredProjectId?.map((o) => o.PROSJEKTID?.toString())}
                  onChange={(v) => setNewProjectId(v as string)}
                />
              </Grid>
              <Grid item xs={4}>
                <InputLabel>Undersøkelsesnummer *</InputLabel>
                <TextField
                  //label="Undersøkelsesnummer *"
                  value={newUndersokelseId}
                  onChange={(e) => {
                    setNewUndersokelseId(e.target.value);
                    setIsTouched(true);
                  }}
                  size="small"
                  disabled={!newProjectId}
                  error={notification.error}
                  helperText={notification.message}
                />
              </Grid>
              <Grid item xs={4}>
                <NButton
                  children="Kopier"
                  onClick={hadleOnClickCopy}
                  fullWidth
                  disabled={!(newProjectId && newUndersokelseId)}
                />
              </Grid>
            </Grid>
          </div>
        </DialogBox>
      )}
    </>
  );
};
