import React from 'react';
import DialogGeneral from '../../../../utilities/DialogGeneral';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Stack, Typography } from '@mui/material';
import { NButton } from '../../../components/Inputs';
import HelpIcon from '@mui/icons-material/Help';

interface PersonDeleteProps {
  showing: boolean;
  hide: () => void;
  handleDeletePerson: () => void;
}

export default function PersonDeleteModal(props: PersonDeleteProps) {
  const { showing, hide, handleDeletePerson } = props;

  return (
    <DialogGeneral
      title="Vil du slette denne personen?"
      open={showing}
      hide={hide}
      titleIcon={<HelpIcon sx={{ color: 'white' }} />}
      fullWidth
      maxWidth="xs"
    >
      <Typography variant="body1" paddingBottom={2}>
        Objektet inneholder underliggende informasjon.
      </Typography>
      <Stack direction={'row'} spacing={1} justifyContent="end">
        <NButton
          children="Ja"
          onClick={() => {
            handleDeletePerson();
            hide();
          }}
        />
        <NButton children="Nei" variant="outlined" onClick={() => hide()} />
      </Stack>
    </DialogGeneral>
  );
}
