import React, { ReactNode } from 'react';
import { Grid, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useKeycloak } from '@react-keycloak/web';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { DialogBox } from '../../../utilities/DialogBox';
import { NButton, SlowText } from '../Inputs';
import { AlertI } from '../../../../types/alertTypes';
import { EndringsloggI } from './CommonTypes';
interface EndringsloggProps<T> {
  header: ReactNode;
  getUrl: string;
  postUrl: string;
  owned: boolean;
  showing: boolean;
  showAlertComponent: (a: string, b: AlertI['alertColor']) => void;
  setProgressBar: (a: boolean) => void;
  hide: () => void;
}
export default function Endringslogg<T>(props: EndringsloggProps<T>) {
  const { keycloak } = useKeycloak();
  const token = keycloak?.token || 'Mangler tilgang';
  const apiCall = (uri: string, method: string, body?: any) => {
    const fullUri = `${process.env.REACT_APP_BACKEND}/${uri}`;

    return fetch(encodeURI(fullUri), {
      method: method,
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      body: body ? JSON.stringify(body) : undefined
    });
  };

  const { header, showAlertComponent, owned, setProgressBar, getUrl, postUrl } = props;
  const [endringslogData, setEndringslogData] = React.useState<EndringsloggI[]>([]);
  const [reload, setReload] = React.useState<boolean>(true);
  const [newText, setNewText] = React.useState('');

  React.useEffect(() => {
    if (reload) {
      apiCall(getUrl, 'GET')
        .then((resp) => resp.json())
        .then((resp) => setEndringslogData(resp));
    }
  }, [reload]);

  const saveChanges = async () => {
    setReload(false);
    setProgressBar(true);
    var failed = 0;
    if (!failed && newText !== '') {
      const response = await apiCall(postUrl, 'POST', { data: newText });

      if (!response.ok) {
        failed += 1;
      }
      setNewText('');
    }

    if (!failed && newText !== '') {
      showAlertComponent('Lagringen er fullført', 'success');
      setReload(true);
    } else {
      showAlertComponent(`Lagringen er ikke fullført`, 'error');
    }
    setProgressBar(false);
  };
  return (
    <DialogBox title="Endringslogg" isShowing={props.showing} hide={props.hide} size="md">
      <Grid container spacing={1}>
        <Grid item xs={8} container spacing={1}>
          {header}
        </Grid>

        {owned ? (
          <Grid item xs={12} container spacing={1} alignItems="flex-end">
            <Grid item xs={10}>
              <SlowText
                header="Ny endringslogg"
                value={newText}
                onChange={(e) => setNewText(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={2}>
              <NButton
                children="Legg til"
                leftIcon={<AddOutlinedIcon />}
                variant="outlined"
                fullWidth
                onClick={() => saveChanges()}
                disabled={!owned || newText === ''}
              />
            </Grid>
          </Grid>
        ) : null}

        <Grid item xs={12}>
          <Table size="small" sx={{ border: '1px solid black' }}>
            <TableHead>
              <TableRow sx={{ height: '3em' }}>
                <TableCell sx={{ fontWeight: 'bold' }}>Tidspunkt</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Bruker</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Tekst</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {endringslogData?.map((row) => (
                <TableRow key={row.sekvens_nr} sx={{ height: '3em' }} hover>
                  <TableCell>{row.tidspunkt}</TableCell>
                  <TableCell>{row.brukerkode}</TableCell>
                  <TableCell>{row.tekst}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </DialogBox>
  );
}
