import React, { ChangeEvent, MouseEvent, ReactNode, useEffect, useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import {
  Checkbox,
  Grid,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from '@mui/material';
import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
import PersonRemoveOutlinedIcon from '@mui/icons-material/PersonRemoveOutlined';
import GroupRemoveOutlinedIcon from '@mui/icons-material/GroupRemoveOutlined';
import { HeadCell, ItemOwner } from './CommonTypes';
import { NButton } from '../Inputs';
import { AlertI } from '../../../../types/alertTypes';
import { EnhancedTableHead } from '../../../utilities/EnhancedTableHead';
import DialogGeneral from '../../../utilities/DialogGeneral';
import InputValidator from '../../kosthold/components/InputValidator';
import { SelectedIntervjuerI } from '../../kosthold/types/ProjectTypes';

const headCells: HeadCell<ItemOwner>[] = [
  {
    id: 'brukerkode',
    align: 'left',
    padding: 'none',
    label: 'Bruker'
  },
  {
    id: 'id',
    align: 'left',
    padding: 'none',
    label: 'Id'
  }
];

interface BrukertilgangProps {
  showing: boolean;
  hide: () => void;
  header: ReactNode;
  getUrl: string;
  deleteUrl: string;
  postUrl: string;
  showAlertComponent: (a: string, b: AlertI['alertColor']) => void;
  owned: boolean;
  setProgressBar: (a: boolean) => void;
}

function Brukertilgang(props: BrukertilgangProps) {
  const {
    showing,
    hide,
    header,
    getUrl,
    deleteUrl,
    postUrl,
    showAlertComponent,
    owned,
    setProgressBar
  } = props;
  const [users, setUsers] = useState<ItemOwner[]>([]);
  const [current, setCurrent] = useState<ItemOwner[]>([]);
  const [state, setState] = useState<ItemOwner[]>([]);
  const [selected, setSelected] = useState<number[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<SelectedIntervjuerI[]>([]);
  const [showError, setShowError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { keycloak } = useKeycloak();
  const token = keycloak?.token || 'Mangler tilgang';

  const apiCall = (uri: string, method: string, body?: any) => {
    const fullUri = `${process.env.REACT_APP_BACKEND}/${uri}`;
    return fetch(encodeURI(fullUri), {
      method: method,
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      body: body ? JSON.stringify(body) : undefined
    });
  };

  useEffect(() => {
    apiCall('getUsers', 'GET')
      .then((resp) => resp.json())
      .then((resp) => setUsers(resp));
  }, []);

  useEffect(() => {
    apiCall(getUrl, 'GET')
      .then((resp) => resp.json())
      .then((resp) =>
        setCurrent(resp?.map((r) => ({ brukerkode: r.brukerkode, id: r.id })))
      );
  }, [getUrl]);

  useEffect(() => setState(current), [current]);

  const saveChanges = async () => {
    setProgressBar(true);

    const removed = current?.filter((a) => !state?.find((b) => a.id === b.id));
    const added = state?.filter((a) => !current?.find((b) => a.id === b.id));

    if ((!removed?.length && !added?.length) || !owned) {
      showAlertComponent('Ingen endringer gjort', 'warning');
      setProgressBar(false);
      setCurrent(state);
      return;
    }

    var failed = 0;
    removed?.map(async (u) => {
      const response = await apiCall(`${deleteUrl}`, 'DELETE', { data: [u.id] });
      if (!response.ok) {
        failed++;
      }
    });
    if (!failed) {
      const response = await apiCall(postUrl, 'POST', {
        data: added?.map((u) => u.id)
      });

      if (!response.ok) {
        failed += added?.length;
      }
    }

    if (!failed) {
      showAlertComponent('Lagret brukertilganger', 'success');
      setCurrent(state);
    } else {
      showAlertComponent(`Klarte ikke å lagre brukertilganger (${failed} feil)`, 'error');
    }
    setProgressBar(false);
  };

  const handleLeggeTil = () => {
    if (!selectedUsers) return;
    const newUsers: ItemOwner[] = selectedUsers
      .filter((s) => s.isValid)
      .map((u) => ({
        id: u.brukerId,
        brukerkode: u.brukerkode
      }));
    const hasDuplicates = state?.some((i) => newUsers.some((j) => j.id === i.id));
    if (hasDuplicates) {
      setShowError(true);
      setErrorMessage('En eller flere av brukerkodene er allerede lagt til.');
      return;
    }
    setState([...state, ...newUsers]);
    setSelectedUsers([]);
  };

  const handleRemoveAccess = (users: number[]) => {
    setState((prev) => prev.filter((p) => !users.includes(p.id)));
    setSelected([]);
  };

  const handleClick = (event: MouseEvent<unknown>, id: number) => {
    let newSelected: number[] = [];
    if (!selected.includes(id)) {
      newSelected = [...selected, id];
    } else {
      newSelected = selected.filter((item) => item !== id);
    }
    setSelected(newSelected);
  };

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = state.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  return (
    <DialogGeneral
      title="Brukertilgang"
      open={showing}
      hide={hide}
      fullWidth
      maxWidth="lg"
    >
      <Grid container spacing={1}>
        <Grid item xs={8} container>
          {header}
        </Grid>
        <Grid item xs={6} />
        <Grid item xs={12}>
          <Typography variant="h6" children="Brukere som er gitt tilgang" />
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table size="small">
              <EnhancedTableHead<ItemOwner>
                headCells={headCells}
                rightColumns={[{ name: 'Fjern', align: 'right' }]}
                checkbox={{
                  onSelectAllClick: (event) => handleSelectAllClick(event),
                  numSelected: selected.length,
                  rowCount: state?.length
                }}
              />
              <TableBody>
                {state?.map((row) => (
                  <TableRow key={row.id} hover>
                    <TableCell
                      padding="checkbox"
                      onClick={(event) => {
                        handleClick(event, row.id);
                      }}
                    >
                      <Checkbox color="primary" checked={selected?.includes(row.id)} />
                    </TableCell>
                    {headCells.map((cell) => (
                      <TableCell
                        key={cell.id}
                        padding={cell.padding}
                        align={cell.align}
                        onClick={(event) => {
                          handleClick(event, row.id);
                        }}
                      >
                        {row[cell.id]}
                      </TableCell>
                    ))}
                    <TableCell align="right" sx={{ pb: 0, pt: 0 }}>
                      <IconButton
                        children={<PersonRemoveOutlinedIcon />}
                        onClick={() => handleRemoveAccess([row.id])}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12} marginTop={2}>
          <Stack direction={'row'} spacing={1} alignItems="end">
            <InputValidator
              users={users}
              selectedUsers={selectedUsers}
              setSelectedUsers={setSelectedUsers}
              showError={showError}
              setShowError={setShowError}
              errorMessage={errorMessage}
              setErrorMessage={setErrorMessage}
            />
            <NButton
              leftIcon={<PersonAddOutlinedIcon />}
              children="Legg til"
              variant="outlined"
              onClick={handleLeggeTil}
              sx={{ flexBasis: '18rem' }}
              disabled={!owned}
            />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" spacing={1}>
            <NButton
              children="Lagre"
              disabled={!owned || current === state}
              onClick={saveChanges}
            />
            <NButton
              leftIcon={<GroupRemoveOutlinedIcon />}
              children="Fjern valgte"
              variant="outlined"
              onClick={() => handleRemoveAccess(selected)}
              disabled={!owned || !selected?.length}
            />
          </Stack>
        </Grid>
      </Grid>
    </DialogGeneral>
  );
}
export default Brukertilgang;
