import { MatvaregruppeActionTypes, SET_MATVAREGRUPPELIST } from '../types/actions';
import {
  MatvaregruppeListType,
  MatvaregruppeType
} from '../types/reduxMatvaregruppeTypes';

const matvaregruppeReducerState: MatvaregruppeListType = {
  matvaregruppeList: []
};

export const matvaregruppeReducer = (
  state = matvaregruppeReducerState,
  action: MatvaregruppeActionTypes
): MatvaregruppeListType => {
  switch (action.type) {
    case SET_MATVAREGRUPPELIST: {
      if (action.matvaregruppeList !== undefined) {
        for (const element of action.matvaregruppeList) {
          const index = state.matvaregruppeList?.findIndex(
            (mat: MatvaregruppeType) =>
              mat?.gruppetypeId === element?.gruppetypeId &&
              mat?.linkup === element?.linkup
          );

          if (index > -1) {
            const x = state.matvaregruppeList?.splice(index, 1);
            console.log(x);
          }
          if (element !== undefined) {
            const x = state.matvaregruppeList?.push(element);
            console.log(x);
          }
        }
      }
      return state;
    }
    default:
      return state;
  }
};
