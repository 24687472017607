import React, { useEffect } from 'react';
import { BeregningObject, ReportThreeI } from '../../types/BeregningTypes';
import useDidMountEffect from '../../../../utilities/useDidMountEffect';
interface ReportThreeProps {
  beregningObject: BeregningObject;
  setReportData: (value: any[]) => void;
  setReportInfo: (value: Array<Array<string>>) => void;
  setErrorReportId: (value: number) => void;
  result: any;
}
export const Report3Result = (props: ReportThreeProps) => {
  const [data] = React.useState([]);
  const [info] = React.useState<Array<Array<string>>>([]);
  const { beregningObject, setReportData, setReportInfo, setErrorReportId, result } =
    props;
  useEffect(() => {
    if (result) {
      console.log(' Beregning result.', 'success');
      const data = result as unknown as ReportThreeI;
      if (
        data?.reportInfo !== undefined &&
        data?.reportInfo?.beregningInfo !== undefined &&
        data?.mengdeAndDecimals !== undefined &&
        data?.persons !== undefined &&
        data?.stoffs !== undefined
      ) {
        prepareResult(data);
      }
    }
  }, [result]);

  const prepareResult = (reportThreedata: ReportThreeI) => {
    let description: string[];
    setErrorReportId(reportThreedata?.reportInfo?.reportId);
    for (let r of reportThreedata?.reportInfo?.beregningInfo) {
      description = [];
      description.push(r.TEKSTLINJE);
      info.push(description);
    }
    setReportInfo(info);
    let result: any[] = [];
    const shortNames: any[] = [];
    const enhet: any[] = [];
    shortNames.push('Nr');
    shortNames.push('Id');
    enhet.push('');
    enhet.push('');
    for (let stoff of reportThreedata?.stoffs) {
      shortNames.push(stoff.KORTNAVN);
      if (beregningObject?.matvareData?.enhet?.value === 6) {
        enhet.push(stoff.ENHET + '/10MG');
      } else {
        enhet.push(stoff.ENHET);
      }
    }
    data.push(shortNames);
    data.push(enhet);
    let n = 0;
    for (let person of reportThreedata?.persons) {
      result = [];
      result.push(person.PERSONID);
      result.push(person.IDENTITET);
      for (let m = 0; m < reportThreedata?.stoffs?.length; m++) {
        if (reportThreedata?.mengdeAndDecimals?.length > 0) {
          result.push(reportThreedata?.mengdeAndDecimals[n].MENGDE);
          n++;
        }
      }
      data.push(result);
    }
    setReportData(data);
  };
  return <></>;
};
