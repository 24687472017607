import React, { useEffect, useState, useContext } from 'react';
import {
  Grid,
  Box,
  Paper,
  ListItemProps,
  ListItem,
  Typography,
  Stack,
  Link
} from '@mui/material';
import NutrientDetails from './components/StoffDetails';
import useFetchData from '../../utilities/useFetchData';
import { DatabaseContext } from '../../../context/DatabaseContext';
import useStyles from '../../../css/materialtheme';
import StoffIconsMenu from './components/StoffIconsMenu';
import AlertComp from '../../utilities/AlertComp';
import { AlertI } from '../../../types/alertTypes';
import PageContainer from '../home/PageContainer';
import { useHistory } from 'react-router';
import CopyStoffPopup from './CopyStoffPopup';
import { useKeycloak } from '@react-keycloak/web';
import StoffVerdier from './StoffVerdier';
import IsOwnedByUser from '../../utilities/Validate';
import {
  SingleStoff,
  SingleStoffTypes,
  StoffI,
  StoffType,
  StoffgruppeI
} from './types/StoffTypes';
import { NButton } from '../components/Inputs';
import Brukertilgang from '../components/commons/Brukertilgang';
import { ProgressBar } from '../../utilities/ProgressBar';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import DownloadIcon from '@mui/icons-material/Download';
import Endringslogg from '../components/commons/Endringslogg';
import StoffNoLink from './components/StoffNoLink';
import { useUserContext } from '../../../context/AuthContext';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { StoffSearch } from './StoffSearch';
import { hasDataChanged } from '../../utilities/ArrayFunctions';

//TODO Update Stoff.
// LAGA ingaar I engeri það á að vera "N" eða "J" og Beskrivelse er alltí einu null en ekki ""
// Split up Stoff into Create stoff and update stoff in the react router.
// much easier logic.

export default function Stoff(props: any) {
  const classes = useStyles();
  const history = useHistory();
  const { currentDatabase } = useContext(DatabaseContext);
  const loggedInuser = useUserContext();
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState<AlertI['alertColor']>('info');
  const [title, setTitle] = useState('Stoff - Nutrifoodcalc');
  const [showStoffnummer, setShowStoffnummer] = useState(false);
  const [showCopyStoff, setShowCopyStuff] = useState(false);
  //const [searchTypeNumbers, setSearchTypeNumbers] = useState(true);
  // const [searchValue, setSearchValue] = useState('');
  const [stofferList, setStofferList] = useState<StoffI[]>([]);
  const [selectedStoffs, setSelectedStoffs] = useState<StoffI[]>([]);
  const backendURI = process.env.REACT_APP_BACKEND;
  const [showProgress, setShowProgress] = React.useState<boolean>(false);
  const [owned, setOwned] = useState(false);
  const [dataIsValid, setDataIsValid] = useState(false);
  document.title = title;
  const initialStoffData = {
    stoffnrId: 0,
    kortnavn: '',
    navn: '',
    engelskNavn: '',
    beskrivelse: '',
    desimaler: 0,
    enhet: '',
    sortering: 0,
    regnbart: '',
    ingaari: '',
    energifaktor: 0
  };
  const [stoffData, setStoffData] = useState<StoffI>(initialStoffData);
  const [originalStoffCopy, setOriginalStoffCopy] = useState<StoffI>(initialStoffData);
  const { keycloak } = useKeycloak();
  const token = keycloak?.token;
  const stoffIdFromURL = +props.match.params.kode; // This is stoffId that is in our URL
  const [isEndringslogShowing, setIsEndringslogShowing] = React.useState(false);
  const [showBrukertilgang, setShowBrukertilgang] = React.useState<boolean>(false);
  const stoffDetail = useFetchData({
    loadOnMount: false,
    clearDataOnLoad: false,
    projectURL: 'getStoffDetail',
    subProjectURL: null
  });

  const nutrientGroupIdAndName = useFetchData({
    loadOnMount: false,
    clearDataOnLoad: false,
    projectURL: 'getStoffGroupIdAndName',
    subProjectURL: null
  });

  const stoffVerdier = useFetchData({
    loadOnMount: false,
    clearDataOnLoad: false,
    projectURL: 'getStoffVerdier',
    subProjectURL: `${currentDatabase?.value}`
  });

  const changePath = (value: any) => {
    if (value) {
      //let value = e.target.value;
      history.push(`/home/matvaredata/stoff/${value}`);
    }
  };
  /*We use this effect to load the fetched data to a local state */
  useEffect(() => {
    if (stoffDetail.data) {
      const data: StoffI = stoffDetail.data as unknown as StoffI;
      if (data.kortnavn) {
        setStoffData(data);
        setOriginalStoffCopy(data);
        nutrientGroupIdAndName.loadData(
          'getStoffGroupIdAndName',
          `${currentDatabase?.value}/${data.stoffnrId}`
        );
        stoffVerdier.loadData(
          'getStoffVerdier',
          `${currentDatabase?.value}/${data?.stoffnrId}`
        );
      } else if (data.stoffnrId) {
        setStoffData({ ...initialStoffData, stoffnrId: data.stoffnrId });
        nutrientGroupIdAndName.clearAll();
        stoffVerdier.clearAll();
        history.push('/home/matvaredata/stoff/0');
      }
    }
  }, [stoffDetail.data]);

  useEffect(() => {
    if (stoffDetail.error) {
      console.log('We have an error' + stoffDetail.errorMessage);
      showAlertMessage(
        'Det har oppstått en feil. Vennligst prøv igjen senere.' +
          stoffDetail.errorMessage,
        'error'
      );
      history.push('/home/matvaredata/stoff');
    }
    if (nutrientGroupIdAndName.error) {
      console.log('We have an error' + nutrientGroupIdAndName.errorMessage);
      showAlertMessage(
        `Det har oppstått en feil. Vennligst prøv igjen senere. ${nutrientGroupIdAndName.errorMessage}`,
        'error'
      );
    }
  }, [stoffDetail.error, nutrientGroupIdAndName.error]);

  useEffect(() => {
    stoffDetail.clearAll();
    if (stoffIdFromURL > 0) {
      stoffDetail.loadData(
        'getStoffDetail',
        `${currentDatabase?.value}/${stoffIdFromURL}`
      );
      setTitle(`Stoff - ${stoffIdFromURL} - Nutrifoodcalc`);
    }
    setOwned(
      IsOwnedByUser({ id: stoffIdFromURL | stoffData?.stoffnrId, u: loggedInuser })
    );
  }, [stoffIdFromURL]);

  useEffect(() => {
    if (stoffData?.stoffnrId > 0) {
      setOwned(IsOwnedByUser({ id: stoffData?.stoffnrId, u: loggedInuser }));
    }
  }, [stoffData?.stoffnrId]);

  const clear = () => {
    setStoffData(initialStoffData);
    nutrientGroupIdAndName.clearData();
    stoffDetail.clearData();
    history.push(`/home/matvaredata/stoff`);
    /* const timer = setTimeout(() => {
      history.push(`/home/matvaredata/stoff`);
    }, 1000);
    return () => clearTimeout(timer); */
  };

  const showErrorAlert = (message: string, severity: AlertI['alertColor']) => {
    setAlertSeverity(severity);
    setAlertMessage(message);
    setShowAlert(true);
  };

  const toggleStoffnummer = (value?: string, searchTypeNumbers?: boolean) => {
    setShowStoffnummer(!showStoffnummer);
  };

  const toggleCopyStoff = () => {
    setShowCopyStuff(!showCopyStoff);
  };

  async function postData(data: any, url: string, methods: string) {
    try {
      const response = await fetch(encodeURI(url), {
        method: methods,
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token
        },
        body: JSON.stringify(data)
      });
      return response;
    } catch (error) {
      const resp = {
        status: 500
      };
      return resp;
    }
  }

  const navigateToStoff = (stoffid?: any) => {
    // set a timer for the history.push because it will trigger a page refresh
    // and set showAlert to false, removing the Alert
    stoffDetail.clearAll();
    const timer = setTimeout(() => {
      if (stoffid) {
        history.push(`/home/matvaredata/stoff/${stoffid}`);
      } else {
        history.push(`/home/matvaredata/stoff/${stoffData.stoffnrId}`);
      }
    }, 1000);
    return () => clearTimeout(timer);
  };

  const createStoff = async (data: StoffI) => {
    const url = `${backendURI}/postCreateStoff/${currentDatabase?.value}`;
    try {
      postData({ data: data }, url, 'POST').then((resp) => {
        if (resp.status === 200) {
          showErrorAlert('Lagringen er fullført', 'success');
          navigateToStoff(stoffData?.stoffnrId);
        } else {
          showAlertMessage(
            'Lagringen mislyktes. Det har ikke blitt gjort endringer mot databasen.',
            'error'
          );
        }
      });
    } catch (error) {
      showAlertMessage('Det har oppstått en feil. Vennligst prøv igjen senere.', 'error');
    }
  };

  const updateStoff = (data: StoffI) => {
    let url = `${backendURI}/postUpdateStoff/${currentDatabase?.value}`;
    try {
      postData({ data: data }, url, 'POST').then((resp) => {
        if (resp.status === 200) {
          showErrorAlert('Oppdateringen er fullført', 'success');
          navigateToStoff(stoffData?.stoffnrId);
        } else {
          showAlertMessage(
            'Opppdatering mislyktes. Det har ikke blitt gjort endringer mot databasen.',
            'error'
          );
        }
      });
    } catch (error) {
      showAlertMessage('Det har oppstått en feil. Vennligst prøv igjen senere.', 'error');
    }
  };

  const hideEndringslog = () => {
    setIsEndringslogShowing(false);
  };
  const handleLagre = () => {
    if (
      !stoffData?.stoffnrId ||
      !stoffData?.sortering ||
      !stoffData?.kortnavn ||
      !stoffData?.navn ||
      stoffData?.desimaler === undefined ||
      !stoffData?.enhet ||
      !stoffData?.engelskNavn
    ) {
      showAlertMessage('Obligatoriske felter kan ikke være tomme.', 'error');
    } else {
      if (hasDataChanged(stoffData, originalStoffCopy)) {
        console.log('Data has changed!');
        const stoffDataForm: StoffI = {
          stoffnrId: stoffData?.stoffnrId || +'',
          navn: stoffData?.navn || '',
          kortnavn: stoffData?.kortnavn || '',
          engelskNavn: stoffData?.engelskNavn || '',
          desimaler: stoffData?.desimaler || +'',
          enhet: stoffData?.enhet || '',
          sortering: stoffData?.sortering || +'',
          energifaktor: stoffData?.energifaktor || +'',
          regnbart: stoffData?.regnbart || '',
          ingaari: stoffData?.ingaari || '',
          beskrivelse: stoffData?.beskrivelse || ''
        };
        stoffIdFromURL && owned ? updateStoff(stoffDataForm) : createStoff(stoffDataForm);
      } else {
        showAlertMessage(
          `Det er ikke blitt gjort noen endringer i skjermbildet. Lagring er ikke nødvendig.`,
          'info'
        );
      }
    }
  };
  const showAlertMessage = (message: string, severity: any) => {
    setShowAlert(true);
    setAlertMessage(message);
    setAlertSeverity(severity);
  };
  return (
    <PageContainer>
      {showAlert && (
        <AlertComp
          alertMessage={alertMessage}
          alertColor={alertSeverity}
          showAlert={showAlert}
          setShowAlert={setShowAlert}
        />
      )}
      {showProgress && (
        <ProgressBar
          isShowing={showProgress}
          hide={() => {
            setShowProgress(!showProgress);
          }}
        />
      )}
      {isEndringslogShowing && (
        <Endringslogg
          header={<StoffNoLink stoff={stoffData} />}
          showAlertComponent={showErrorAlert}
          owned={owned}
          setProgressBar={setShowProgress}
          showing={isEndringslogShowing}
          hide={() => hideEndringslog}
          getUrl={`getSEndringslogg/${currentDatabase?.value}/${stoffData.stoffnrId}`}
          postUrl={`createSEndringslogg/${currentDatabase?.value}/${stoffData.stoffnrId}`}
        />
      )}
      {showBrukertilgang && (
        <Brukertilgang
          header={<StoffNoLink stoff={stoffData} />}
          showAlertComponent={showErrorAlert}
          owned={owned}
          setProgressBar={setShowProgress}
          showing={showBrukertilgang}
          hide={() => setShowBrukertilgang(false)}
          getUrl={`getSBrukerTilgang/${currentDatabase?.value}/${stoffData?.stoffnrId}`}
          deleteUrl={`deleteSBrukertilgang/${currentDatabase?.value}/${stoffData?.stoffnrId}`}
          postUrl={`createSBrukertilgang/${currentDatabase?.value}/${stoffData?.stoffnrId}`}
        />
      )}
      <Box style={{ padding: '0 15px 0 15px' }}>
        {showStoffnummer && (
          <StoffSearch
            stofferList={stofferList}
            selectedStoffs={selectedStoffs}
            setSelectedStoffs={setSelectedStoffs}
            stoffType={StoffType.single}
            setShowStoffOption={setShowStoffnummer}
            showStoffOption={showStoffnummer}
            databaseId={currentDatabase?.value}
            setStofferList={setStofferList}
            history={history}
            toggleStoffnummer={toggleStoffnummer}
            singleStoffType={SingleStoffTypes.stoffDef}
          />
        )}
        {showCopyStoff && (
          <CopyStoffPopup
            showCopyStoff={showCopyStoff}
            setShowCopyStuff={setShowCopyStuff}
            toggleCopyStoff={toggleCopyStoff}
            stoffData={stoffData}
            postData={postData}
            showErrorAlert={showErrorAlert}
            navigateToStoff={navigateToStoff}
            setShowProgress={setShowProgress}
          />
        )}
        <Grid container spacing={1}>
          <Grid item xs={9}>
            <Box id="Stoff">
              <Typography variant="h4">Stoff</Typography>
              <StoffIconsMenu
                classes={classes}
                singleNutrient={stoffDetail}
                stoffData={stoffData}
                nutrientGroupIdAndName={nutrientGroupIdAndName || []}
                owned={owned}
                currentDatabase={currentDatabase}
                toggleCopyStoff={toggleCopyStoff}
                showErrorAlert={showErrorAlert}
                setShowProgress={setShowProgress}
                postData={postData}
                stoffIdFromURL={stoffIdFromURL}
              />
            </Box>
            <Grid item xs={4}>
              <NButton
                children="Søk etter stoff"
                leftIcon={<SearchOutlinedIcon />}
                onClick={() => toggleStoffnummer()}
              />
            </Grid>
            <NutrientDetails
              changePath={changePath}
              setStoffData={setStoffData}
              stoffData={stoffData}
              nutrientGroupIdAndName={nutrientGroupIdAndName.data}
              classes={classes}
              toggleStoffnummer={toggleStoffnummer}
              currentDatabase={currentDatabase}
              setDataIsValid={setDataIsValid}
              stoffIdFromURL={stoffIdFromURL}
              owned={owned}
            />
            <Grid display="flex" style={{ paddingBottom: 15 }}>
              <Stack direction={'row'} spacing={2}>
                <NButton
                  children={stoffIdFromURL ? 'Lagre' : 'Opprett'}
                  onClick={handleLagre}
                  disabled={!(stoffData?.stoffnrId && owned)}
                />
                <NButton children="Nullstill" variant="outlined" onClick={clear} />
              </Stack>
            </Grid>
          </Grid>
          <Grid item xs={3} container spacing={2} position="relative">
            <Stack
              spacing={1}
              padding={1}
              position="sticky"
              top="10rem"
              paddingLeft={3}
              marginLeft={2}
              sx={{
                background: 'rgba(200,200,200, 0.2)',
                width: '100%',
                height: 'fit-content'
              }}
            >
              {stoffDetail.isLoaded && stoffVerdier.isLoaded ? (
                <StoffVerdier
                  singleNutrient={stoffDetail.data}
                  currentUser={loggedInuser?.user?.username}
                  currentDatabase={currentDatabase}
                  stoffVerdier={stoffVerdier.data}
                ></StoffVerdier>
              ) : (
                <NButton
                  children="Matvarer stoffverdier"
                  rightIcon={<DownloadIcon />}
                  variant="text"
                  color="inherit"
                  disabled={!stoffDetail.isLoaded}
                />
              )}
              <Link
                target="_blank"
                href={`/home/matvaredata/stoffgruppe`}
                sx={{ width: '100%', color: 'inherit' }}
              >
                <NButton
                  children="Stoffgrupper"
                  rightIcon={<OpenInNewOutlinedIcon />}
                  variant="text"
                  color="inherit"
                  fullWidth
                  disabled={!stoffDetail.isLoaded}
                />
              </Link>

              <NButton
                children={`Brukertilgang${!owned ? ' (Utilgjengelig)' : ''}`}
                variant="text"
                color="inherit"
                onClick={() => owned && setShowBrukertilgang(true)}
                disabled={!stoffDetail.isLoaded}
              />
              <NButton
                children="Endringslogg"
                variant="text"
                color="inherit"
                fullWidth
                onClick={() => setIsEndringslogShowing(!isEndringslogShowing)}
                disabled={!stoffDetail.isLoaded}
              />
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </PageContainer>
  );
}
