import React from 'react';
import { TextField, InputLabel } from '@mui/material';
interface InputFeildsProps {
  classes: any;
  label: string;
  id: string;
  type: string;
  name: string;
  value: string | number;
  handleChange?: (name: string, value: any) => void;
  keyDown?: boolean;
  handleOnEnterKeyPress?: (event: React.KeyboardEvent<HTMLElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  variant?: any;
  required?: boolean;
  disabled?: boolean;
  maxLength?: boolean;
  Min?: number;
  MaxLength?: number;
  Pattern?: string;
  multiline?: boolean;
  rows?: string;
  rowsMax?: string;
  error?: boolean;
  errorMessage?: string;
  autoComplete?: string;
  helperText?: any;
  owned?: boolean;
}

export function InputFields(props: InputFeildsProps) {
  return (
    <>
      <InputLabel sx={props.classes.InputLabel}>{props.label}</InputLabel>
      <TextField
        error={props.error || false}
        helperText={props.error ? props.errorMessage : ''}
        id={props.id}
        type={props.type}
        name={props.name}
        value={props.value || ''}
        onChange={(e) => props.handleChange(e.target.name, e.target.value)}
        //onKeyDown={(e) => props.handleOnEnterKeyPress(e)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            props.keyDown ? props.handleOnEnterKeyPress(e) : null;
          }
        }}
        onFocus={props.onFocus}
        title="Not Valid"
        required={props.required || false}
        size="small"
        variant={props.variant || 'outlined'}
        style={{ width: '100%', paddingTop: 0, marginTop: 0 }}
        InputLabelProps={{
          shrink: true
        }}
        InputProps={{
          readOnly: !props.owned || false,
          classes: { input: props.classes.resize }
        }}
        disabled={props.disabled}
        multiline={props.multiline || false}
        minRows={props.rows || '1'}
        maxRows={props.rowsMax || '1'}
        autoComplete={props.autoComplete || 'No'}
      />
    </>
  );
}
