import {
  SET_ALERT_MESSAGE,
  SET_ALERT_COLOR,
  SET_ALERT_CLOSED,
  AlertActionTypes
} from '../types/actions';
import { AlertI } from '../types/alertTypes';

const alertReducerDefaultState: AlertI = {
  alertMessage: '',
  alertColor: 'error',
  alertClosed: false
};

export const alertReducer = (
  state = alertReducerDefaultState,
  action: AlertActionTypes
): AlertI => {
  switch (action.type) {
    case SET_ALERT_MESSAGE:
      return { ...state, alertMessage: action.alertMessage };
    case SET_ALERT_COLOR:
      return { ...state, alertColor: action.alertColor };
    case SET_ALERT_CLOSED:
      return { ...state, alertClosed: action.alertClosed };
    default:
      return state;
  }
};
