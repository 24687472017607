import React, { useState, useContext, useEffect } from 'react';
import { Grid, Link, Stack } from '@mui/material';
import useStyles from '../../../css/materialtheme';
import Matvare from './Matvare';
import { DatabaseContext } from '../../../context/DatabaseContext';
import { useKeycloak } from '@react-keycloak/web';
import { useHistory } from 'react-router';
import PlasseringMatvaregrupper from './PlasseringMatvaregrupper';
import Inngaari from './Inngaari';
import { MatvareData } from './types/MatvareTypes';
import PageContainer from '../home/PageContainer';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import DownloadIcon from '@mui/icons-material/Download';
import MatvarePrintReport from './Reports/MatvarePrintReport';
import AlertComp from '../../utilities/AlertComp';
import { AlertI } from '../../../types/alertTypes';
import IsOwnedByUser from '../../utilities/Validate';
import { NButton } from '../components/Inputs';
import Endringslogg from '../components/commons/Endringslogg';
import MatvareNoLink from './components/MatvareNoLink';
import { ProgressBar } from '../../utilities/ProgressBar';
import { useUserContext } from '../../../context/AuthContext';

export default function MatvareContainer(props: any) {
  const classes = useStyles();
  const history = useHistory();
  const { keycloak } = useKeycloak();
  const { currentDatabase } = useContext(DatabaseContext);
  const initialMatvareData: MatvareData = {
    MATVAREKODEID: 0,
    NAVN: '',
    KORTNAVN: '',
    NAVN_ENGELSK: '',
    SENR: '',
    OFFISIELT_NAVN: '',
    SOKEORD: '',
    MAKSIMALMENGDE: 0,
    VEKTENDRING: null,
    OPPSKRIFT: '0',
    SPISELIG: 0,
    BRUTTOBEREGNING: 0,
    UTBYTTING_SPERRET: 0,
    STATUS: 0,
    BESKRIVELSE: '',
    BESKRIVELSE_ENGELSK: '',
    REFERANSE: '',
    KOMMENTAR: ''
  };
  const token = keycloak?.token;
  const kodeFromURL = props.match.params.kode;
  const getStateFromUrl = props.location.state;
  const loggedInuser = useUserContext();
  const [alertMessage, setAlertMessage] = React.useState('');
  const [alertSeverity, setAlertSeverity] = React.useState<AlertI['alertColor']>('info');
  const [showAlert, setShowAlert] = React.useState(false);
  const [showPlassering, setShowPlassering] = useState(false);
  const [showEndringsLog, setShowEndringslog] = useState(false);
  const [showReports, setShowReports] = useState(false);
  const [matvareData, setMatvareData] = useState<MatvareData>(initialMatvareData);
  const [originalMatvareCopy, setOriginalMatvareCopy] =
    useState<MatvareData>(initialMatvareData);

  const [owned, setOwned] = React.useState(false);
  const [showProgress, setShowProgress] = React.useState(false);
  const [originalVektE, setOriginalVektE] = useState(null);
  const [isLoaded, setIsloaded] = useState(false);
  const [refreshUnmodified, setRefreshUnmodified] = React.useState(0);
  const [matvarekodeError, setMatvarekodeIdError] = React.useState({
    error: false,
    message: ''
  });
  const [title, setTitle] = React.useState(
    kodeFromURL ? `Matvare - ${kodeFromURL} - Nutrifoodcalc` : 'Matvare - Nutrifoodcalc'
  );

  /*   const sessionNumber = useFetchData({
    loadOnMount: true,
    clearDataOnLoad: false,
    projectURL: 'checkSession',
    subProjectURL: `${currentDatabase?.value}`
  }); */
  React.useEffect(() => {
    if (/[a-zA-Z -]/.test(matvareData.MATVAREKODEID.toString())) {
      setMatvarekodeIdError({
        error: true,
        message: 'Ugyldige tegn. Bruk bare tall'
      });
    } else {
      setMatvarekodeIdError({
        error: false,
        message: ''
      });
      setTitle(
        matvareData.MATVAREKODEID
          ? `Matvare - ${matvareData.MATVAREKODEID} - Nutrifoodcalc`
          : 'Matvare - Nutrifoodcalc'
      );
    }
  }, [matvareData.MATVAREKODEID]);
  const handleMatvareForm = (name: string, value: string | number) => {
    setMatvareData({
      ...matvareData,
      [name]: value
    });

    if (name === 'MATVAREKODEID') {
      setOwned(IsOwnedByUser({ id: +value, u: loggedInuser }));
    }
  };

  const unmodifiedMatvareData: MatvareData = React.useMemo(
    () => ({ ...matvareData }),
    [refreshUnmodified]
  );

  useEffect(() => {
    //If we are refreshing a page we need to refetch data or get from url changes.
    if (kodeFromURL > 0) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      getStateFromUrl
        ? (setMatvareData({ ...getStateFromUrl.matvareData }), setIsloaded(true))
        : loadMatvare(kodeFromURL);
      // sessionNumber.loadData('checkSession', `${currentDatabase?.value}`);

      new Promise((resolve) => setTimeout(resolve, 1000)).then(() =>
        setRefreshUnmodified(Math.random())
      );
    }
    //setTitle(kodeFromURL ? `Matvare - ${kodeFromURL} - Nutrifoodcalc` : 'Matvare - Nutrifoodcalc');
    setOwned(IsOwnedByUser({ id: kodeFromURL, u: loggedInuser }));
  }, [kodeFromURL]);

  const clearMatvareData = () => {
    setMatvareData(initialMatvareData);
    setIsloaded(false);
    history.push('/home/matvaredata/matvare');
  };

  async function fetchData(mainurl: string, secondurl: string) {
    const url = `${process.env.REACT_APP_BACKEND}/${mainurl}/${
      secondurl ? secondurl : ''
    }`;
    const response = await fetch(encodeURI(url), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    });
    return response.json();
  }
  const loadMatvare = (id: number) => {
    fetchData('getMatvareByKode', `${currentDatabase?.value}/${id}`)
      .then((data: MatvareData) => {
        if (!(data.NAVN === undefined || data.NAVN === '')) {
          const updatedData = { ...initialMatvareData, ...data, MATVAREKODEID: id };
          setMatvareData(updatedData);
          setOriginalMatvareCopy(updatedData);
          setIsloaded(true);
          setOriginalVektE(data.VEKTENDRING);
        } else {
          setIsloaded(false);
          setMatvareData({ ...initialMatvareData, MATVAREKODEID: id });
          showAlertComponent('Matvaren eksisterer ikke', 'error');
          setMatvarekodeIdError({
            error: true,
            message: ''
          });
        }
      })
      .catch((err) => console.log(err));
  };
  const handleOnEnterKeyPress = (
    e: React.KeyboardEvent<HTMLDivElement>,
    name: string
  ) => {
    if (name === 'MATVAREKODEID') {
      fetchData(
        'getMatvareByKode',
        `${currentDatabase?.value}/${matvareData.MATVAREKODEID}`
      )
        .then((data: MatvareData) => {
          if (data.NAVN !== '') {
            history.push(`/home/matvaredata/matvare/${matvareData.MATVAREKODEID}`);
            setOriginalVektE(data.VEKTENDRING);
          } else {
            setIsloaded(false);
            setMatvareData({
              ...initialMatvareData,
              MATVAREKODEID: matvareData.MATVAREKODEID
            });
            showAlertComponent('Matvaren eksisterer ikke', 'error');
            setMatvarekodeIdError({
              error: true,
              message: ''
            });
          }
        })
        .catch((err) => console.log('Error', err));
    }
    e.preventDefault();
  };
  const showAlertComponent = (message: string, severity: any) => {
    setShowAlert(true);
    setAlertMessage(message);
    setAlertSeverity(severity);
  };

  const showOppskriftAlert = () => {
    showAlertComponent(
      'Vektendringsprosent (evt. 0) må være registrert hvis oppskrift skal lages!',
      'warning'
    );
  };

  return (
    <PageContainer>
      <Grid container spacing={1}>
        <Grid item xs={9}>
          {showAlert && (
            <AlertComp
              alertMessage={alertMessage}
              alertColor={alertSeverity}
              showAlert={showAlert}
              setShowAlert={setShowAlert}
            />
          )}
          {showProgress && (
            <ProgressBar
              isShowing={showProgress}
              text={'Loading...'}
              hide={() => {
                setShowProgress(!showProgress);
              }}
            />
          )}
        </Grid>
        <Grid item xs={9} className="PopUpGrid">
          <Matvare
            params={props.match.params}
            currentDatabase={currentDatabase}
            handleMatvareForm={handleMatvareForm}
            loadMatvare={loadMatvare}
            matvareData={matvareData}
            owned={owned}
            setOwned={setOwned}
            showProgress={showProgress}
            setShowProgress={setShowProgress}
            handleOnEnterKeyPress={handleOnEnterKeyPress}
            clearMatvareData={clearMatvareData}
            token={token}
            originalMatvareCopy={originalMatvareCopy}
            refreshUnmodified={() => setRefreshUnmodified(Math.random())}
            showAlertComponent={showAlertComponent}
            matvarekodeError={matvarekodeError}
            title={title}
          />
        </Grid>
        <Grid item xs={3} container spacing={2} position="relative">
          <Stack
            spacing={1}
            padding={1}
            position="sticky"
            top="10rem"
            paddingLeft={3}
            marginLeft={2}
            sx={{
              background: 'rgba(200,200,200, 0.2)',
              width: '100%',
              height: 'fit-content'
            }}
          >
            <NButton
              children="Plassering"
              variant="text"
              color="inherit"
              onClick={() => setShowPlassering(!showPlassering)}
              disabled={!isLoaded}
            />
            <NButton
              children="Rapport"
              variant="text"
              color="inherit"
              onClick={() => setShowReports(true)}
              disabled={!isLoaded}
            />
            {originalVektE === null || originalVektE === undefined ? (
              <NButton
                children="Oppskrift"
                rightIcon={<OpenInNewOutlinedIcon />}
                variant="text"
                color="inherit"
                onClick={() => showOppskriftAlert()}
                disabled={!isLoaded}
              />
            ) : (
              <Link
                target="_blank"
                href={`/home/matvaredata/oppskrift/${
                  matvareData?.MATVAREKODEID
                }/${matvareData?.NAVN?.replace(/[/]/g, '-slash-')?.replace(
                  /[%]/g,
                  '-percent-'
                )}/${matvareData.VEKTENDRING}`}
                sx={{ width: '100%', color: 'inherit' }}
              >
                <NButton
                  children="Oppskrift"
                  rightIcon={<OpenInNewOutlinedIcon />}
                  variant="text"
                  color="inherit"
                  fullWidth
                  disabled={!isLoaded}
                />
              </Link>
            )}
            {isLoaded ? (
              <Inngaari
                currentDatabase={currentDatabase}
                matvareData={matvareData}
                showAlertComponent={showAlertComponent}
                classes={classes}
              />
            ) : (
              <NButton
                children="Inngår i"
                rightIcon={<DownloadIcon />}
                variant="text"
                color="inherit"
                disabled={!isLoaded}
              />
            )}
            {isLoaded ? (
              <Link
                target="_blank"
                href={`/home/matvaredata/stoffer/${
                  matvareData?.MATVAREKODEID
                }/${matvareData?.NAVN?.replace(/[/]/g, '-slash-')?.replace(
                  /[%]/g,
                  '-percent-'
                )}/${matvareData?.OPPSKRIFT}`}
                sx={{ width: '100%', color: 'inherit' }}
              >
                <NButton
                  children="Stoffer"
                  rightIcon={<OpenInNewOutlinedIcon />}
                  variant="text"
                  color="inherit"
                  fullWidth
                  disabled={!isLoaded}
                />
              </Link>
            ) : (
              <NButton
                children="Stoffer"
                rightIcon={<OpenInNewOutlinedIcon />}
                variant="text"
                color="inherit"
                fullWidth
                disabled={!isLoaded}
              />
            )}
            {isLoaded ? (
              <Link
                target="_blank"
                href={`/home/matvaredata/mengdeenheter/${
                  matvareData?.MATVAREKODEID
                }/${matvareData?.NAVN?.replace(/[/]/g, '-slash-')?.replace(
                  /[%]/g,
                  '-percent-'
                )}`}
                sx={{ width: '100%', color: 'inherit' }}
              >
                <NButton
                  children="Mengdeenheter"
                  rightIcon={<OpenInNewOutlinedIcon />}
                  variant="text"
                  color="inherit"
                  fullWidth
                  disabled={!isLoaded}
                />
              </Link>
            ) : (
              <NButton
                children="Mengdeenheter"
                rightIcon={<OpenInNewOutlinedIcon />}
                variant="text"
                color="inherit"
                fullWidth
                disabled={!isLoaded}
              />
            )}
            <NButton
              children="Endringslogg"
              variant="text"
              color="inherit"
              onClick={() => setShowEndringslog(true)}
              disabled={!isLoaded}
            />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          {showPlassering && (
            <PlasseringMatvaregrupper
              currentDatabase={currentDatabase}
              matvareData={matvareData}
              showPlassering={showPlassering}
              setShowPlassering={setShowPlassering}
            />
          )}
          {showEndringsLog && (
            <Endringslogg
              header={<MatvareNoLink matvare={matvareData} />}
              showAlertComponent={showAlertComponent}
              owned={owned}
              setProgressBar={setShowProgress}
              showing={showEndringsLog}
              hide={() => setShowEndringslog(false)}
              getUrl={`getMEndringslogg/${currentDatabase?.value}/${matvareData.MATVAREKODEID}`}
              postUrl={`createMEndringslogg/${currentDatabase?.value}/${matvareData.MATVAREKODEID}`}
            />
          )}

          {showReports && (
            <MatvarePrintReport
              showReports={showReports}
              setShowReports={setShowReports}
              currentDatabase={currentDatabase}
              matvareKodeId={matvareData.MATVAREKODEID}
            />
          )}
        </Grid>
      </Grid>
    </PageContainer>
  );
}
