import React, { useState } from 'react';
import { Box, Grid, InputLabel, TextField } from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { NButton } from '../../components/Inputs';
import MatvareSearch from '../MatvareSearch';
import { useHistory } from 'react-router';
import { MatvareResult } from '../types/MatvareTypes';
import useDidMountEffect from '../../../utilities/useDidMountEffect';
interface KodesKortNavnProps {
  matvareData: MatvareResult;
  classes: any;
  showProgress: boolean;
  matvarekodeError: {
    error: boolean;
    message: string;
  };
  handleMatvareForm: (item: string, value: string) => void;
  handleOnEnterKeyPress: (e: React.KeyboardEvent<HTMLDivElement>, value: string) => void;
  setEnterPressed: (value: boolean) => void;
  setShowProgress: (value: boolean) => void;
  //owned: boolean;
}
export default function KodesKortNavn(props: KodesKortNavnProps) {
  const {
    matvareData,
    classes,
    matvarekodeError,
    handleMatvareForm,
    handleOnEnterKeyPress,
    setEnterPressed,
    showProgress,
    setShowProgress
    //owned
  } = props;
  const [showMatvareSearch, setShowMatvareSearch] = useState<boolean>(false);
  const history = useHistory();
  const [searchCode, setSearchCode] = useState<string>('');
  //console.log('matvareData-', matvareData)
  useDidMountEffect(() => {
    if (!(searchCode === '0' || searchCode === ''))
      history.push(`/home/matvaredata/matvare/${matvareData.MATVAREKODEID}`);
  }, [searchCode]);
  return (
    <Grid>
      <Grid container item xs={12}>
        <Grid item xs={4}>
          <NButton
            children="Søk etter matvare"
            leftIcon={<SearchOutlinedIcon />}
            onClick={() => setShowMatvareSearch(true)}
            fullWidth
          />
        </Grid>
        {showMatvareSearch ? (
          <MatvareSearch
            setMatvare={(id) => {
              setSearchCode(id.toString());
              handleMatvareForm('MATVAREKODEID', id.toString());
            }}
            matvarekode={matvareData?.MATVAREKODEID.toString()}
            navn={matvareData?.NAVN}
            showing={showMatvareSearch}
            showProgress={showProgress}
            setShowProgress={setShowProgress}
            hide={() => setShowMatvareSearch(false)}
          />
        ) : null}

        <Grid container item xs={12}>
          <Box style={{ fontSize: 'small' }} sx={classes.obligatoriskField}>
            * Obligatorisk felt
          </Box>
        </Grid>
      </Grid>
      <Grid item xs={12} container spacing={1} alignItems="flex-end">
        <Grid item xs={4}>
          <InputLabel sx={classes.InputLabel}>Matvarekode*</InputLabel>
          <TextField
            id="Kode"
            type="tel"
            maxRows="1"
            value={matvareData.MATVAREKODEID || ''}
            onChange={(e) => handleMatvareForm('MATVAREKODEID', e.target.value)}
            name="MATVAREKODEID"
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                setEnterPressed(true);
                handleOnEnterKeyPress(e, 'MATVAREKODEID');
              }
            }}
            style={{ width: '100%', paddingTop: 0, marginTop: 0 }}
            required
            variant="outlined"
            inputProps={{
              min: 1,
              maxLength: 6,
              inputMode: 'numeric',
              pattern: '[0-9]*'
            }}
            InputProps={{
              classes: { input: classes.resize }
            }}
            size="small"
            error={matvarekodeError.error}
            helperText={matvarekodeError.message}
          />
        </Grid>
        <Grid item xs={5}>
          <InputLabel sx={classes.InputLabel}>Navn*</InputLabel>
          <TextField
            id="navn"
            type="text"
            value={matvareData.NAVN || ''}
            onChange={(e) => handleMatvareForm('NAVN', e.target.value)}
            name="NAVN"
            size="small"
            inputProps={{ maxLength: 25 }}
            style={{ width: '100%', paddingTop: 0, marginTop: 0 }}
            /*  onKeyDown={(e) => {
               if (e.key === 'Enter') {
                 setEnterPressed(true);
                 handleOnEnterKeyPress(e, 'NAVN');
               }
             }} */
            required
            variant="outlined"
          />
        </Grid>
        <Grid item xs={3}>
          <InputLabel sx={classes.InputLabel}>Kortnavn</InputLabel>
          <TextField
            id={'kortnavn'}
            name="KORTNAVN"
            size="small"
            InputProps={{
              readOnly: true
            }}
            value={`V${matvareData?.MATVAREKODEID}`}
            type={'text'}
            variant="outlined"
            disabled
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
