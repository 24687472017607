import { Grid, TextField, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import React, { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import DialogGeneral from '../../../../utilities/DialogGeneral';
import { Dropdown, FormInput, NButton } from '../../../components/Inputs';
import { ProsjektI } from '../../types/ProjectTypes';
import { OppringingI, PersonI } from '../../types/PersonTypes';
import { useCodeContext } from '../../../../../context/CodeContext';
import { CodeRegisterI } from '../../../components/commons/CommonTypes';
import selectedDropdown from '../SelectedDropdown';
import ErrorMessage from '../../../../utilities/ErrorMessage';
import { AlertI } from '../../../../../types/alertTypes';
import StyledTextarea from '../../../components/StyledTextarea';

interface RouteParams {
  prosjektId: string;
  undersokelseKortnavn: string;
}

interface PersonOppringingModalProps extends RouteComponentProps<RouteParams> {
  owned: boolean;
  showing: boolean;
  hide: () => void;
  checkAndHide: () => void;
  personData: PersonI;
  currentModalLopedag: number;
  currentOppringingId: number;
  projectObject: ProsjektI;
  ringOpp: boolean;
  setActiveTab: Dispatch<SetStateAction<number>>;
  startTime: Date | null;
  setStartTime: Dispatch<SetStateAction<Date | null>>;
  handleSaveOppringing: (oppringing: OppringingI, lopedagId: number) => void;
  oppringingModalChanges: boolean;
  setOppringingModalChanges: Dispatch<SetStateAction<boolean>>;
  oppringingData: OppringingI;
  setOppringingData: Dispatch<SetStateAction<OppringingI>>;
  callAlert: (message: string, severity: AlertI['alertColor']) => void;
}

export default function PersonOppringingModal(props: PersonOppringingModalProps) {
  const {
    owned,
    showing,
    hide,
    checkAndHide,
    personData,
    currentModalLopedag,
    currentOppringingId,
    projectObject,
    ringOpp,
    setActiveTab,
    startTime,
    setStartTime,
    handleSaveOppringing,
    oppringingModalChanges,
    setOppringingModalChanges,
    oppringingData,
    setOppringingData,
    callAlert
  } = props;
  const { prosjektId, undersokelseKortnavn } = props.match.params;
  const [showError, setShowError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const allCodes = useCodeContext();
  const currentModalLopedagData = personData?.lopedager?.find(
    (l) => l.lopedagId === currentModalLopedag
  );

  useEffect(() => {
    if (ringOpp) {
      setOppringingData((prev) => ({
        ...prev,
        nr: currentOppringingId,
        lopedagId: currentModalLopedag,
        dato: getDateFormat(new Date()),
        varighet: 0
      }));
    } else {
      setOppringingData(
        personData?.oppringinger?.find((o) => o.nr === currentOppringingId)
      );
    }
  }, [personData]);

  const getDateFormat = (date: Date) => {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${day}.${month}.${year} ${hours}:${minutes}`;
  };

  function handleLagre() {
    if (oppringingData?.status === 9 && !oppringingData?.kommentar) {
      setShowError(true);
      setErrorMessage('Kommentar må fylles ut');
      return;
    }
    const newOppringing: OppringingI = {
      ...oppringingData,
      // dato: getDateFormat(new Date()),
      status: oppringingData?.status,
      kommentar: oppringingData?.kommentar,
      varighet: oppringingData?.varighet
      // varighet: oppringingData?.status === 1 && ringOpp ? 0 : oppringingData?.varighet
    };
    handleSaveOppringing(newOppringing, currentModalLopedag);
    hide();
  }

  function handleStartTid() {
    const newOppringing: OppringingI = {
      ...oppringingData,
      // dato: getDateFormat(new Date()),
      status: 1,
      kommentar: oppringingData?.kommentar
    };
    handleSaveOppringing(newOppringing, currentModalLopedag);
    setStartTime(new Date());
    hide();
    setActiveTab(currentModalLopedag);
    window.scrollTo(0, 0);
  }

  const handleChange = (
    field: Extract<keyof OppringingI, string | number>,
    value: string | number
  ) => {
    if (field === 'varighet' && value < 0) {
      callAlert('Varighet kan ikke være negativ', 'error');
    }
    setOppringingData((prev) => ({ ...prev, [field]: value }));
    setOppringingModalChanges(true);
  };

  return (
    <DialogGeneral
      title="Oppringing"
      open={showing}
      hide={checkAndHide}
      fullWidth
      maxWidth="md"
    >
      <Stack spacing={2}>
        <Stack spacing={1}>
          <Typography variant="body1">
            <b>Prosjekt: </b> {projectObject?.prosjektnavn} - {prosjektId}
          </Typography>
          <Typography variant="body1">
            <b>Undersøkelse: </b> {undersokelseKortnavn}
          </Typography>
          <Stack direction={'row'} spacing={2}>
            <Typography variant="body1">
              <b>Person: </b>
              {personData?.personId} - {personData?.identitet}
            </Typography>
            <Typography variant="body1">
              <b>Løpedag: </b>
              {currentModalLopedag}
              {currentModalLopedagData?.ukedag && ' - ' + currentModalLopedagData?.ukedag}
            </Typography>
          </Stack>
        </Stack>
        <Grid container>
          <Grid item xs={5} rowSpacing={1} paddingRight={2}>
            <Stack spacing={1}>
              <Typography variant="body1">
                <b>Oppringing: </b>
                {oppringingData?.nr}
              </Typography>
              <Typography variant="body1">
                <b>Dato: </b>
                {oppringingData?.dato}
              </Typography>
            </Stack>
            <Stack spacing={2} marginTop={2}>
              <Dropdown
                header="Status *"
                value={selectedDropdown(oppringingData?.status, 13)}
                options={allCodes.codes?.filter((f) => f.value === 13)}
                optionLabel={(option: CodeRegisterI) => option?.label}
                onChange={(value: any) => handleChange('status', value?.nr)}
              />
              <Box sx={{ width: '50%' }}>
                {/* <TextField
                  variant="outlined"
                  type="number"
                  value={oppringingData?.varighet ?? ''}
                  onChange={(
                    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                  ) => handleChange('varighet', +event.target.value)}
                  disabled={ringOpp}
                  // onKeyDown={(event) =>
                  //   event.key === 'Enter' && handleCodeSubmit(newRowData?.kode)
                  // }
                  // onBlur={() => handleCodeSubmit(newRowData?.kode)}
                  inputProps={{
                    style: {
                      textAlign: 'right'
                    }
                  }}
                /> */}
                <FormInput
                  header="Varighet (min.)"
                  type="number"
                  value={oppringingData?.varighet ?? ''}
                  onChange={(
                    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                  ) => handleChange('varighet', +event.target.value)}
                  disabled={ringOpp}
                  owned={owned}
                />
              </Box>
            </Stack>
          </Grid>
          <Grid item container xs={7}>
            <StyledTextarea
              header="Kommentar"
              name="kommentar"
              value={oppringingData?.kommentar || ''}
              onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                handleChange('kommentar', event.target.value);
                setShowError(false);
              }}
              disabled={!oppringingData?.status}
              owned={owned}
            />
            <ErrorMessage showing={showError} message={errorMessage} />
          </Grid>
        </Grid>

        <Stack direction="row" alignItems="end" spacing={2}>
          <NButton
            onClick={handleLagre}
            sx={{ maxWidth: 'max-content' }}
            disabled={
              !oppringingData?.status ||
              oppringingData?.varighet < 0 ||
              (!ringOpp && !oppringingModalChanges)
            }
          >
            Lagre
          </NButton>
          {ringOpp && (
            <Stack>
              {startTime && (
                <Typography variant="caption" color={'text.secondary'}>
                  {`Allerede startet: ${getDateFormat(startTime)}`}
                </Typography>
              )}
              <NButton
                variant="outlined"
                onClick={handleStartTid}
                disabled={
                  !ringOpp ||
                  startTime !== null ||
                  (oppringingData?.status !== 1 && oppringingData?.status !== undefined)
                }
                sx={{ maxWidth: 'max-content' }}
              >
                Ja til intervju (Åpne intervjudetaljer og start tidsregistrering)
              </NButton>
            </Stack>
          )}
        </Stack>
      </Stack>
    </DialogGeneral>
  );
}
