import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import { EmptyTableRowMengde } from './components/EmptyTableRowMengde';
import { Mengdeenhet } from './types/MatvareTypes';

/*
For this component we need to do a couple of things.
First: there are tow tables one that contains information about current stoff in this matvare.
and then we need a new line to allow the user to add a stoff.(also need to add stoff using the list)
*/

const useStyles = () => {
  return {
    table: {
      minWidth: 650
    },
    input: {
      padding: 0
    }
  };
};
interface InputTableMengde {
  setMengdeenheterData: (value: Mengdeenhet[]) => void;
  mengdeenheterData: Mengdeenhet[];
  selectMendeToDelete: (mengde: string) => void;
  owned: boolean;
}
/*
  The InputTable component is the table that will display all the stoffinnehold data.
  It allows the user to fetch stoff and then add mengde and kilde. The table it self
  will always create a new line once the table is full. 
*/
export function InputTableMengde(props: InputTableMengde) {
  const classes = useStyles();
  const handleChangeTable = (name: string, value: string | number, index: number) => {
    props.setMengdeenheterData([
      ...props.mengdeenheterData?.map((object: any, oIndex: number) => {
        if (oIndex === index) {
          if (name === 'sortering') {
            return {
              ...object,
              [name]: +value
            };
          } else if (name === 'mengde') {
            return {
              ...object,
              [name]: parseFloat(value?.toString()?.replace(/[,]/g, '.'))
            };
          } else {
            return {
              ...object,
              [name]: value
            };
          }
        } else {
          return object;
        }
      })
    ]);
  };
  return (
    <div>
      <TableContainer
        id="mainTable"
        style={{
          width: '100%',
          backgroundColor: 'white',
          height: props.mengdeenheterData?.length > 23 ? 800 : 'auto'
        }}
      >
        <Table
          sx={classes.table}
          size="small"
          aria-label="a dense sticky table"
          stickyHeader
        >
          <TableHead>
            <TableRow>
              <TableCell style={{ background: '#4977AF', color: 'white' }}>
                <b>Sort</b>
              </TableCell>
              <TableCell style={{ background: '#4977AF', color: 'white' }}>
                <b>Enhet</b>
              </TableCell>
              <TableCell style={{ background: '#4977AF', color: 'white' }}>
                <b>g/enhet</b>
              </TableCell>
              <TableCell style={{ background: '#4977AF', color: 'white' }}>
                <b>Referanse</b>
              </TableCell>
              <TableCell style={{ background: '#4977AF', color: 'white' }}>
                <b>Slett</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.mengdeenheterData?.map((item: any, index: number) => (
              <EmptyTableRowMengde
                key={`emptyRow${index}`}
                newRowData={item}
                index={index}
                handleChangeTable={handleChangeTable}
                selectMendeToDelete={props.selectMendeToDelete}
                owned={props.owned}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
