import React, { useContext, useEffect, useState } from 'react';
import PageContainer from '../../../home/PageContainer';
import {
  Alert,
  CircularProgress,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Typography
} from '@mui/material';
import { useDispatch } from 'react-redux';
import * as actions from '../../../../../types/actions';
import { AlertI } from '../../../../../types/alertTypes';
import { useKeycloak } from '@react-keycloak/web';
import AlertComp from '../../../../utilities/AlertComp';
import { AlertWithAction } from '../../../../utilities/AlertPopUp';
import { DatabaseContext } from '../../../../../context/DatabaseContext';
import { KorrekturfilI } from '../../RapportTypes';
import ExcelReport from './ExcelRapport';
import HTMLRapport from './HtmlRapport';
import { Dropdown, NButton, SlowText } from '../../../components/Inputs';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import UndersokelseSearch from '../../../kosthold/UndersokelseSearch';
import IsOwnedByUser from '../../../../utilities/Validate';
import { useUserContext } from '../../../../../context/AuthContext';
import { UndersokelseI } from '../../../kosthold/types/ProjectTypes';
import PollingComponent from '../../../components/commons/PollingComponent';
import { RouteComponentProps, useHistory } from 'react-router-dom';
interface RouteParams {
  prosjektId: string;
  undersokelseId?: string;
  personIdFra?: string;
  personIdTil?: string;
}

interface KorrekturfilProps extends RouteComponentProps<RouteParams> {}
export const Korrekturfil = (p: KorrekturfilProps) => {
  const { prosjektId, undersokelseId, personIdFra, personIdTil } = p.match.params;
  const dispatch = useDispatch();
  const history = useHistory();
  const { keycloak } = useKeycloak();
  const { currentDatabase } = useContext(DatabaseContext);
  const token = keycloak?.token;
  const defaultKorrekturData = {
    databasenavn: '',
    personer: [],
    prosjekt: { id: -1, navn: '', kortnavn: '' },
    undersokelse: { id: -1, navn: '', kortnavn: '' }
  };

  const redirectTo = (
    prosjektId: number,
    undersokelse: number,
    fra: number,
    til: number
  ) =>
    history.push(
      `/home/rapport/kostholdsdata/korrekturfil/${prosjektId}/${undersokelse}/personId/${fra}/${til}`
    );
  const title = 'Korrektur';
  document.title = title;
  const loggedInuser = useUserContext();
  const [showReport, setShowReport] = useState(false);
  const [reportData, setReportData] = useState<KorrekturfilI>(defaultKorrekturData);

  const [reportFormat, setReportFormat] = React.useState<'HTML' | 'XLSX'>('XLSX');

  const [showAlert, setShowAlert] = useState(false);
  const [showAlertWA, setShowAlertWA] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState<AlertI['alertColor']>('info');
  const [pollingId, setPollingId] = React.useState('');
  const [failed, setFailed] = React.useState(false);

  const showAlertMessage = (message: string, severity: any) => {
    setShowAlert(true);
    setAlertMessage(message);
    setAlertSeverity(severity);
  };

  const [loading, setLoading] = React.useState<boolean>(false);
  const [project, setProject] = useState(null);
  const [survey, setSurvey] = useState<UndersokelseI>(null);
  const [showSurveyIdList, setShowSurveyIdList] = useState(false);
  const [intervalStart, setIntervalStart] = useState(1);
  const [intervalEnd, setIntervalEnd] = useState(10 ** 6 - 1);

  const setUrlParams = () => {
    redirectTo(+prosjektId, +undersokelseId, +personIdFra, +personIdTil);
    const p = { PROSJEKTID: +prosjektId };
    selectProject(p);
    setSurvey({ ...survey, undersokelseId: +undersokelseId });
    setIntervalStart(+personIdFra);
    setIntervalEnd(+personIdTil);
  };
  const selectProject = (project: any) => {
    setProject(project);
    setSurvey(null);
    dispatch({ type: actions.SET_PROSJEKT_ID, prosjektId: project.PROSJEKTID });
  };
  useEffect(() => {
    if (reportData) {
      setLoading(false);
    }
  }, [reportData]);
  useEffect(() => {
    if (failed) {
      showAlertMessage('Rapport lagging mislyktes.', 'error');
    }
  }, [failed]);
  async function fetchData(mainurl: string, secondurl: string) {
    const url = `${process.env.REACT_APP_BACKEND}/${mainurl}/${
      secondurl ? secondurl : ''
    }`;
    const response = await fetch(encodeURI(url), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    });
    return await response.json();
  }

  const getUndersokelse = async () => {
    if (project && survey) {
      fetchData(
        'prosjektUndersokelseDataById',
        `${project?.PROSJEKTID}/${survey?.undersokelseId}`
      )
        .then((data) => {
          if (data.rows?.length > 0) {
          } else if (data.rows?.length === 0) {
            showAlertMessage('Denne undersøkelsen eksisterer ikke.', 'error');
            setSurvey(null);
          }
        })
        .catch((err) => {
          console.log('Error', err);
          showAlertMessage(
            'Det har oppstått en feil. Vennligst prøv igjen senere.',
            'error'
          );
        });
    }
  };

  const [projects, setProjects] = useState([]);

  useEffect(() => {
    const e = async () => {
      const response = await fetchData('listOverProsjekter', '1');

      setProjects(
        response.rows.filter((row) => {
          return Boolean(IsOwnedByUser({ id: row.PROSJEKTID, u: loggedInuser }));
        })
      );
      setProjects(response.rows);
    };
    if (prosjektId && undersokelseId && personIdFra) setUrlParams();
    e();
  }, []);

  const [surveys, setSurveys] = useState<UndersokelseI[]>([]);

  useEffect(() => {
    if (!project) {
      setSurveys([]);
      return;
    }
    const e = async () => {
      const response = await fetchData(
        'hentPUndersokelser',
        project.PROSJEKTID.toString()
      );
      setSurveys(response);
      // setReportData(null);
      setShowReport(false);
    };
    e();
  }, [project]);

  useEffect(() => {
    const g = async () => await getUndersokelse();
    if (survey) {
      g();
    }
  }, [survey]);

  const doRequest = async (type: 'PDF' | 'XLSX') => {
    const matvaredbId = currentDatabase?.value;
    const projectName = `${project?.PROSJEKTID} - ${project?.PROSJEKTNAVN}`;
    const surveyName = `${survey?.undersokelseId} - ${survey?.kortNavn}`;
    setLoading(true);
    const r = await fetchData(
      'getKorrekturFilData',
      [matvaredbId, projectName, surveyName, intervalStart, intervalEnd].join('/')
    );
    setPollingId(r?.id);
  };

  React.useEffect(() => {
    const g = async () => await doRequest('XLSX');
    if (survey) {
      g();
      if (intervalEnd) {
        redirectTo(
          +project?.PROSJEKTID,
          +survey.undersokelseId,
          intervalStart,
          intervalEnd
        );
      }
    }
  }, [survey, intervalStart, intervalEnd]);

  return (
    <PageContainer>
      <Paper style={{ padding: '1.5em 15px 1.5em 15px', boxShadow: 'none' }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4">Korrekturfil</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5">Velg prosjekt og undersøkelse</Typography>
          </Grid>
          <Grid item xs={9} container spacing={2} alignItems="flex-end">
            <Grid item xs={4}>
              <Dropdown
                header="Prosjektsnummer"
                value={project}
                options={projects}
                optionLabel={(o) => o?.PROSJEKTID.toString()}
                onChange={(v) => selectProject(v)}
              />
            </Grid>
            <Grid item xs={4}>
              <Dropdown
                header="Undersøkelsesnummer"
                value={survey}
                options={surveys}
                optionLabel={(o) => o?.undersokelseId.toString()}
                onChange={(v) => setSurvey(v as UndersokelseI)}
                disabled={!project}
              />
            </Grid>
            <Grid item xs={4}>
              <NButton
                leftIcon={<SearchOutlinedIcon />}
                children="Søk etter undersøkelse"
                onClick={() => setShowSurveyIdList(true)}
                disabled={!project}
                fullWidth
              />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h5">Velg personintervall</Typography>
          </Grid>
          <Grid item xs={6} container spacing={2} alignItems="center">
            <Grid item xs={6}>
              <SlowText
                header="Fra personId"
                value={intervalStart}
                onChange={(e) => setIntervalStart(parseInt(e.target.value))}
                disabled={!survey}
                type="number"
              />
            </Grid>
            <Grid item xs={6}>
              <SlowText
                header="Til personId"
                value={intervalEnd}
                onChange={(e) => setIntervalEnd(parseInt(e.target.value))}
                disabled={!survey}
                type="number"
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">
              {reportData?.personer?.length || 0} av{' '}
              {(survey?.antallPersoner ? Object.values(survey?.antallPersoner)[0] : 0) ||
                reportData?.personer?.length}{' '}
              personer er eksportet
            </Typography>
          </Grid>

          <Grid item xs={6} />

          <Grid item xs={12}>
            <Typography variant="h5">Velg eksportformat</Typography>
          </Grid>
          <Grid item xs={6}>
            <RadioGroup
              value={reportFormat}
              onChange={(e) => setReportFormat((e.target.value as 'XLSX') || 'HTML')}
            >
              <FormControlLabel
                label="XLSX"
                value="XLSX"
                control={<Radio disabled={!survey} />}
              />
              <FormControlLabel
                label="HTML"
                value="HTML"
                control={<Radio disabled={!survey} />}
              />
            </RadioGroup>
          </Grid>
          <Grid item xs={6} />

          <Grid item xs={9} container spacing={2}>
            {!loading && reportData.databasenavn && survey ? (
              <>
                <Grid item xs={12}>
                  <Typography variant="h5">Korrekturfil</Typography>
                </Grid>
                {reportFormat === 'XLSX' ? (
                  <ExcelReport data={reportData} />
                ) : (
                  <HTMLRapport data={reportData} />
                )}
              </>
            ) : loading && survey ? (
              <Grid item xs={4}>
                <CircularProgress />
              </Grid>
            ) : !reportData.databasenavn && survey ? (
              <Grid item xs={8}>
                <Alert severity="info">
                  <Typography>Søket ga ingen treff</Typography>
                  <Typography variant="body2">
                    Hint:
                    <ul>
                      <li>Prøv andre parametere for personId</li>
                    </ul>
                  </Typography>
                </Alert>
              </Grid>
            ) : null}
          </Grid>
        </Grid>

        {/* Dialogs */}
        {pollingId && (
          <PollingComponent
            id={pollingId}
            setFailed={setFailed}
            setResult={setReportData}
          />
        )}
        {showSurveyIdList ? (
          <UndersokelseSearch
            projectId={project?.PROSJEKTID}
            setUndersokelse={(i) =>
              setSurvey(surveys.find((s) => `${s?.undersokelseId}` === `${i}`))
            }
            showing={showSurveyIdList}
            hide={() => setShowSurveyIdList(false)}
          />
        ) : null}
        {showAlert ? (
          <AlertComp
            alertMessage={alertMessage}
            alertColor={alertSeverity}
            showAlert={showAlert}
            setShowAlert={setShowAlert}
          />
        ) : null}
        {showAlertWA ? (
          <AlertWithAction
            alertMessage={alertMessage}
            alertColor={alertSeverity}
            showAlert={showAlertWA}
            setShowAlert={setShowAlertWA}
          />
        ) : null}
      </Paper>
    </PageContainer>
  );
};
