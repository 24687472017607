import React from 'react';
import { Grid, InputLabel, TextField } from '@mui/material';

export default function SupplamentaryData(props: any) {
  const { matvareData, handleMatvareForm, classes, owned } = props;
  return (
    <Grid container spacing={1}>
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={4}>
          <InputLabel sx={classes.InputLabel}>Vektendring (%)</InputLabel>
          <TextField
            id="Vektendring"
            type="tel"
            maxRows="1"
            value={
              matvareData?.VEKTENDRING === undefined || matvareData?.VEKTENDRING === null
                ? ''
                : matvareData?.VEKTENDRING
            }
            name="VEKTENDRING"
            onChange={(e) => handleMatvareForm('VEKTENDRING', e.target.value)}
            variant="outlined"
            size="small"
            style={{ width: '100%', paddingTop: 0, marginTop: 0 }}
            InputProps={{
              readOnly: !owned,
              classes: { input: classes.resize }
            }}
            //disabled={!owned}
          />
        </Grid>
        <Grid item xs={4}>
          <InputLabel sx={classes.InputLabel}>Grenseverdi ved intervju</InputLabel>
          <TextField
            id="Maksmengde"
            type="number"
            maxRows="1"
            name="MAKSIMALMENGDE"
            value={matvareData?.MAKSIMALMENGDE || ''}
            onChange={(e) => handleMatvareForm('MAKSIMALMENGDE', e.target.value)}
            variant="outlined"
            size="small"
            style={{ width: '100%', paddingTop: 0, marginTop: 0 }}
            InputProps={{
              readOnly: !owned,
              classes: { input: classes.resize }
            }}
            //disabled={!owned}
          />
        </Grid>
        <Grid item xs={4}>
          <InputLabel sx={classes.InputLabel}>Spiselig</InputLabel>
          <TextField
            id="Spiselig"
            type="number"
            maxRows="1"
            name="SPISELIG"
            value={matvareData?.SPISELIG || ''}
            onChange={(e) => handleMatvareForm('SPISELIG', e.target.value)}
            variant="outlined"
            size="small"
            style={{ width: '100%', paddingTop: 0, marginTop: 0 }}
            InputProps={{
              readOnly: !owned,
              classes: { input: classes.resize }
            }}
            //disabled={!owned}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
