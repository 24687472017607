import React from 'react';
import {
  Container,
  TextField,
  MenuItem,
  TableBody,
  TableHead,
  TableRow,
  Table,
  TableCell,
  InputLabel
} from '@mui/material';
import useStyles from '../../../css/materialtheme';
import useFetchData from '../../utilities/useFetchData';
import { MatvareData } from './types/MatvareTypes';
import useStylesTable from '../../../css/TableCss';
import { DialogBox } from '../../utilities/DialogBox';
import { resultatGruppeI } from './types/matvaregruppeTypes';
import { useErrorHandler } from 'react-error-boundary';

interface PlasseringMatvare {
  currentDatabase?: any;
  matvareData: MatvareData;
  showPlassering: boolean;
  setShowPlassering: any;
}

export default function PlasseringMatvaregrupper(props: PlasseringMatvare) {
  const classes = useStyles();
  const classesTable = useStylesTable();
  const { matvareData, currentDatabase } = props;
  const [selectedRow, setSelectedRow] = React.useState(null);
  const [gruppetypeid, setgruppetypeid] = React.useState<string>('2');
  const [resultatGruppe, setResultatGruppe] = React.useState<resultatGruppeI[]>([]);
  const [gruppeNivaaList, setGruppeNivaaList] = React.useState([]);
  const handleError = useErrorHandler();
  const gruppeList = useFetchData({
    loadOnMount: false,
    clearDataOnLoad: false,
    projectURL: 'getListgruppe',
    subProjectURL: `${currentDatabase?.value}/${matvareData.MATVAREKODEID}/${gruppetypeid}`
  });

  const resultatgruppe = useFetchData({
    loadOnMount: true,
    clearDataOnLoad: false,
    projectURL: 'getResultatgruppe',
    subProjectURL: `${currentDatabase?.value}`
  });

  React.useEffect(() => {
    if (matvareData.MATVAREKODEID)
      gruppeList.loadData(
        'getListgruppe',
        `${currentDatabase?.value}/${matvareData.MATVAREKODEID}/${gruppetypeid}`
      );
  }, [matvareData.MATVAREKODEID, gruppetypeid]);
  React.useEffect(() => {
    if (resultatgruppe.data) setResultatGruppe(resultatgruppe.data);
  }, [resultatgruppe.data]);
  React.useEffect(() => {
    if (gruppeList.data)
      try {
        setGruppeNivaaList(gruppeList.data);
      } catch (e) {
        handleError(e);
      }
  }, [gruppeList.data]);

  const hidePlassering = () => {
    props.setShowPlassering(false);
  };
  return (
    <DialogBox
      title={'Plassering'}
      isShowing={props.showPlassering}
      hide={hidePlassering}
    >
      <Container id="PlasseringMatvareGruppe" style={{ minWidth: 800 }}>
        <InputLabel sx={classes.InputLabel}>
          Matvare:{' '}
          <b>
            {matvareData.MATVAREKODEID} - {matvareData.NAVN}
          </b>
        </InputLabel>
        <div>
          <InputLabel sx={classes.InputLabel}>Resultatgruppe:</InputLabel>
          {resultatgruppe.isLoaded && (
            <TextField
              id="outlined-select-status"
              select
              sx={classes.selectMdb}
              defaultValue={2}
              value={gruppetypeid}
              onChange={(e) => setgruppetypeid(e.target.value)}
              InputLabelProps={{
                shrink: true
              }}
              variant="outlined"
              size="small"
              style={{ width: '100%' }}
              InputProps={{ sx: { input: classes.resize } }}
            >
              {resultatGruppe?.map((row: resultatGruppeI) => (
                <MenuItem
                  key={`gruppePlassering ${row.GRUPPETYPEID}`}
                  value={`${row.GRUPPETYPEID}`}
                >
                  {row.BESKRIVELSE}
                </MenuItem>
              ))}
            </TextField>
          )}
        </div>
        <div>
          <Table sx={classesTable.body}>
            <TableHead>
              <TableRow sx={{ fontWeight: 'bold' }}>
                <TableCell>Nivå</TableCell>
                <TableCell>Navn</TableCell>
              </TableRow>
            </TableHead>
            {
              <TableBody>
                {gruppeList.isLoaded &&
                  gruppeNivaaList?.map((row, index) => {
                    const highlighted = selectedRow && selectedRow.NIVAA === row.NIVAA;
                    return (
                      <TableRow
                        key={row.NIVAA}
                        onClick={() => {
                          setSelectedRow(highlighted ? null : row);
                        }}
                        sx={classesTable.tableRow}
                      >
                        <TableCell sx={classesTable.tableRow}>{row.NIVAA}</TableCell>
                        <TableCell sx={classesTable.tableRow}>{row.NAVN}</TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            }
          </Table>
        </div>
      </Container>
    </DialogBox>
  );
}
