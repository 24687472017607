import React, { useState, useEffect, useCallback } from 'react';
import { Prompt, useHistory } from 'react-router-dom';

const useChangesWarning = (
  message = 'Du har ulagrede endringer. Vil du navigere bort?'
) => {
  const [changes, setChanges] = useState(false);
  // const [showDialog, setShowDialog] = useState<boolean>(false);
  // const [nextLocation, setNextLocation] = useState(null);
  const history = useHistory();
  // const location = useLocation();

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (changes) {
        event.preventDefault();
        event.returnValue = message;
      }
    };
    if (changes) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    } else {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    }
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [changes, message]);

  useEffect(() => {
    const unblock = history.block((nextLocation) => {
      if (changes && !window.confirm(message)) {
        // setNextLocation(nextLocation);
        // setShowDialog(true);
        return false;
      }
      return true;
    });
    return () => {
      unblock();
    };
  }, [changes, message, history]);

  // const handleCancelNavigation = () => {
  //   setShowDialog(false);
  // };

  // const handleConfirmNavigation = () => {
  //   setShowDialog(false);
  //   setChanges(false);
  //   if (nextLocation) {
  //     history.push(nextLocation.pathname);
  //   }
  // };

  const promptDialog = <Prompt when={changes} message={message} />;
  // const promptDialog = () => (
  //   <DialogGeneral
  //     title="Ulagret innhold"
  //     open={showDialog}
  //     titleIcon={<HelpOutlineIcon sx={{ color: 'white' }} />}
  //     hide={() => setShowDialog(false)}
  //     fullWidth
  //     maxWidth="xs"
  //   >
  //     <Typography>Det er blitt gjort endringer. Vil du lagre disse?</Typography>
  //     <Stack direction={'row'} justifyContent="end" spacing={1} paddingTop={4}>
  //       <Button variant="contained" onClick={handleConfirmNavigation}>
  //         Ja
  //       </Button>
  //       <Button variant="outlined" onClick={handleConfirmNavigation}>
  //         Nei
  //       </Button>
  //       <Button variant="outlined" onClick={() => setShowDialog(false)}>
  //         Avbryt
  //       </Button>
  //     </Stack>
  //   </DialogGeneral>
  // );
  const onChanges = useCallback(() => setChanges(true), []);
  const onSaved = useCallback(() => setChanges(false), []);

  return { promptDialog, onChanges, onSaved };
};

export default useChangesWarning;
