import { ProgressI } from '../types/progressTypes';
import { SET_PROGRESS_STATUS, ProgressActionTypes } from '../types/actions';

const progressReducerDefaultState: ProgressI = { showProgress: false };

export const progressReducer = (
  state = progressReducerDefaultState,
  action: ProgressActionTypes
): ProgressI => {
  switch (action.type) {
    case SET_PROGRESS_STATUS:
      return { ...state, showProgress: action.showProgress };
    default:
      return state;
  }
};
