import { useTheme } from '@mui/material';
const useStyles = () => {
  const theme = useTheme();
  return {
    root: {
      textTransform: 'none',
      '&.Mui-disabled': {
        pointerEvents: 'auto'
      },
      '&.MuiBreadcrumbs-li': {
        '&:active': {
          boxShadow: theme.shadows[2],
          color: 'black'
        }
      },
      /*This is used with forms */
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: 200,
        backgroundColor: theme.palette.background.paper,
        textTransform: 'none'
      },

      '& .MuiTabPanel-root': {
        flexGrow: 1,
        textTransform: 'none',
        backgroundColor: theme.palette.background.paper
      },
      '& > * + *': {
        margin: theme.spacing(1)
        // height:'100%',
      },
      '& .TextField': {
        width: 412,
        textTransform: 'none'
      },
      '& .MuiIconButton-root': {
        paddingLeft: theme.spacing(1),
        paddingTop: theme.spacing(2)
      },
      details: {
        alignItems: 'center',
        textTransform: 'none'
      },
      '& .MuiInputBase-input.Mui-disabled': {
        color: '#000000'
      }
    },
    typography: {
      button: {
        textTransform: 'none'
      }
    },

    button: {
      textTransform: 'none',
      fontFamily: 'font-family: Arial, Helvetica, sans-serif',
      margin: theme.spacing(1)
    },
    details: {
      alignItems: 'center',
      textTransform: 'none',
      paddingLeft: theme.spacing(0.2),
      paddingright: theme.spacing(0.2)
    },
    tabs: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
      textTransform: 'none',
      minWidth: 22,
      paddingLeft: theme.spacing(0),
      paddingright: theme.spacing(0),
      transform: 'none'
    },
    h6: {
      textSizeAdjust: 'small',
      fontFamily: 'Arial',
      fontStyle: 'normal',
      fontSize: '14px',
      lineHeight: '16px',
      color: '#1C5081'
    },
    flexContainerColumn: {
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column'
    },
    flexContainerColumnCenter: {
      flex: 1,
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
      alignItems: 'center',
      justifyContent: 'center'
    },
    flexContainerColumnContent: {
      flex: 1,
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap'
      /* alignItems: 'center', */
      /* justifyContent: 'center' */
    },
    number: {
      width: 100
    },
    resize: {
      fontSize: '18px',
      color: 'black'
    },
    selectMdb: {
      backgroundColor: theme.palette.background.paper,
      minWidth: 100,
      maxWidth: 400,
      width: 230,
      margin: theme.spacing(1),
      height: 50,
      fontSize: '18px'
    },
    Stofftabs: {
      backgroundColor: '#4977AF'
    },

    selectMdbMatvare: {
      backgroundColor: theme.palette.background.paper,
      minWidth: 248,
      maxWidth: 300,
      width: 300,
      margin: theme.spacing(1),
      height: 36,
      borderRadius: 3,
      fontSize: '18px',
      paddingTop: 0,
      marginTop: 0
    },
    table: {
      width: 50,
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: 50,
        backgroundColor: theme.palette.background.paper
      },
      '& .TextField': {
        width: 50
      }
    },
    Listicon: {
      paddingBottom: theme.spacing(1)
    },
    Label: {
      fontSize: '18px',
      padding: theme.spacing(0),
      margin: theme.spacing(1)
    },
    TextField: {
      // width: 412,
      textTransform: 'none',
      fontSize: 14,

      width: 'auto'
    },
    ExpansionDetail: {
      // backgroundColor: "#f3f7fa",
    },
    Form: {
      //  backgroundColor: "#f3f7fa ",
    },
    IconBoxContainer: {
      display: 'flex',
      justifyContent: 'right',
      alignItems: 'right',
      WebkitJustifyContent: 'flex-end',
      // backgroundColor: "#f3f7fa",
      color: '#1C5081'
    },
    InfoContainer: {
      paddingTop: '20px',
      justify: 'space-between'
    },
    HelperText: {
      marginBlock: '0%',
      display: 'flex',
      justifyContent: 'right',
      fontSize: '14px'
    },
    divider: {
      width: '80%',
      margin: theme.spacing(2)
    },
    selectRepport: {
      backgroundColor: theme.palette.background.paper,
      minWidth: 100,
      width: 400,
      height: 50,
      fontSize: '18px'
    },
    adminTitle: {
      color: '#334b99',
      fontSize: '28px'
    },
    adminTableRow: {
      fontSize: 14,
      [theme.breakpoints.up('sm')]: {
        fontSize: 14
      },
      [theme.breakpoints.up('md')]: {
        fontSize: 14
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: 18
      },
      [theme.breakpoints.up('xl')]: {
        fontSize: 24
      }
    },
    Title: {
      // color: "black",
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '23px'
      //width: '80%'
    },
    numberInfo: {
      color: '#1C5081',
      paddingTop: 0,
      paddingRight: theme.spacing(2),
      float: 'right',
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontSize: '14px'
    },
    obligatoriskField: {
      fontSize: '14px',
      margin: '2%',
      fontFamily: 'Arial',
      fontStyle: 'normal',
      color: '#666666',
      flex: 1
    },

    /*This should be use in one component only. Check MatvareGruppe*/
    ExpansionPanel: {
      backgroundColor: '#1C5081',
      color: 'white',
      maxWidth: '1602px'
    },
    expandedExpansionPanel: {
      backgroundColor: '#C9D7E3',
      color: 'black',
      maxWidth: '1602px'
    },
    /* ATH */

    /* only used in undersokelse. move it. */
    accordionDetail: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    /* ATH */

    buttonmenu: {
      color: 'white',
      fontSize: '18px',
      padding: theme.spacing(1),
      marginLeft: '3%',
      textDecoration: 'none'
    },
    subMenu: {
      marginTop: 0,
      marginLeft: 20
    },
    subMenuMat: {
      marginTop: '-20px',
      marginLeft: '180px'
    },
    RadioButton: {
      fontSize: '20px'
    },
    BeregningMatvareButton: {
      padding: theme.spacing(1),
      margin: theme.spacing(1)
    },
    H3: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '23px',
      align: 'left',
      float: 'left'
    },
    ContainerHeader: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '36px'
    },
    Icons: {
      fontFamily: 'Arial',
      fontStyle: 'normal',
      fontSize: '14px',
      color: '#1C5081'
    },
    InputLabel: {
      /*       paddingTop: theme.spacing(1),
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(0), */
      padding: '5px 0 5px 0',
      fontFamily: 'Arial',
      fontStyle: 'normal',
      fontSize: '16px',
      color: '#000000'
    },
    disabled: {
      color: '#000000'
    },
    /* .MuiInputBase-input.Mui-disabled {
      color: black;
      opacity: 1;
  } */
    arrow: {
      color: 'white'
    },
    Select: {
      minWidth: 140,
      marginLeft: 5
    },
    StoffContainer: {
      marginBottom: 15,
      marginTop: 15
    },

    AlertWindow: {
      position: 'absolute',
      marginLeft: 'auto',
      marginRight: 'auto',
      left: 0,
      right: 0,
      textAlign: 'center'
    },
    MatvareKodeList: {
      width: '80vw',
      maxWidth: 1680,
      maxHeight: '60vh'
    },
    BeregningNavLink: {
      color: 'white',
      textDecoration: 'none'
    }
  };
};

export default useStyles;
