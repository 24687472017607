import { useTheme } from '@mui/material';
import { alpha } from '@mui/material/styles';
const useStylesTable = () => {
  const theme = useTheme();
  return {
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover
      },
      '&.MuiTableHead-root': {
        color: theme.palette.common.white
      },
      height: 56,
      padding: '1%',
      spacing: '1%'
    },
    pageText: {
      fontSize: 28,
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 700
      //paddingBottom: 15
    },
    head: {
      backgroundColor: '#4977AF',
      height: 56,
      '& th': {
        color: 'white',
        fontSize: '1rem',
        fontWeight: 900
      }
    },
    body: {
      fontSize: 14,
      height: 55
    },
    Pagination: {
      fontSize: 14,
      backgroundColor: '#4977AF',
      color: theme.palette.common.white
    },
    PageTitle: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '24px'
    },
    tableRow: {
      '&.Mui-selected, &.Mui-selected:hover': {
        backgroundColor: '#ABEBC6'
      },
      fontSize: 14,
      height: '1%',
      padding: '1%',
      spacing: '1%'
    },
    tableRowRoot: {
      '&$tableRowSelected, &$tableRowSelected:hover': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        )
      }
    },
    tableRowSelected: {
      backgroundColor: alpha(
        theme.palette.primary.main,
        theme.palette.action.selectedOpacity
      )
    }
  };
};
export default useStylesTable;
