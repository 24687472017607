import React from 'react';
import { MatvareAndOppskriftI } from '../../RapportTypes';
import { NButton } from '../../../components/Inputs';
import { Box, Grid } from '@mui/material';

import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import ReactToPrint from 'react-to-print';

const ComponentToPrint = React.forwardRef(
  (p: { data: MatvareAndOppskriftI[]; matvaredb: string }, ref: any) => (
    <div ref={ref} style={{ padding: '1em' }}>
      <div style={{ textAlign: 'center' }}>
        <h1>Matvarer og oppskrifter</h1>
        <h3>Matvaredatabase: {p.matvaredb}</h3>
      </div>

      {p.data.map((matvare, i) => (
        <div className={i && 'pagebreak'}>
          <table width="100%">
            <tr>
              <th>Kode: {matvare.matvarekodeid}</th>
              <th>Navn: {matvare.navn}</th>
              <th>Vektendring: {matvare.vektendring && `${matvare.vektendring}%`}</th>
            </tr>
            <tr>
              <th>Kode (ingrediens)</th>
              <th style={{ width: '60%' }}>Navn (ingrediens)</th>
              <th>Gram</th>
            </tr>
            {matvare.ingredienser.map((ingrediens) => (
              <tr>
                <td>{ingrediens.ingrediensid}</td>
                <td>{ingrediens.navn}</td>
                <td>{ingrediens.mengde}</td>
              </tr>
            ))}
          </table>
        </div>
      ))}
    </div>
  )
);

const HTMLRapport = ({
  data,
  matvaredb
}: {
  data: MatvareAndOppskriftI[];
  matvaredb: string;
}) => {
  const printRef = React.useRef();

  return (
    <>
      <Box sx={{ display: 'none' }}>
        <ComponentToPrint data={data} matvaredb={matvaredb} ref={printRef} />
      </Box>
      <Grid item xs={6}>
        <ReactToPrint
          trigger={() => (
            <NButton
              leftIcon={<PrintOutlinedIcon />}
              children="Skriv ut oppskrifter"
              fullWidth
            />
          )}
          pageStyle="@media print{.pagebreak{page-break-before:always}}*{border:none}table>tr>td{font-size:small}table>tr>th{text-align:start}"
          content={() => printRef.current}
        />
      </Grid>
    </>
  );
};

export default HTMLRapport;
