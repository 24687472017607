import React, { useEffect } from 'react';
import useFetchData from '../../../../utilities/useFetchData';
import {
  BeregningObject,
  ReportResultTableI,
  ReportTenI
} from '../../types/BeregningTypes';
import useDidMountEffect from '../../../../utilities/useDidMountEffect';
import PollingComponent from '../../../components/commons/PollingComponent';
interface ReportTenProps {
  beregningObject: BeregningObject;
  setReportData: (value: any[]) => void;
  setReportInfo: (value: Array<Array<string>>) => void;
  setErrorReportId: (value: number) => void;
  result: any;
}
export const Report10Result = (props: ReportTenProps) => {
  const [data] = React.useState([]);
  const [info] = React.useState<Array<Array<string>>>([]);
  const { beregningObject, setReportData, setReportInfo, setErrorReportId, result } =
    props;
  useEffect(() => {
    if (result) {
      console.log(' Beregning result.', 'success');
      const data = result as unknown as ReportTenI;
      if (
        data?.reportInfo !== undefined &&
        data?.reportInfo?.beregningInfo !== undefined &&
        data?.personsAndMengdes !== undefined &&
        data?.enhet !== undefined &&
        data?.matvares !== undefined &&
        data?.mengdes
      ) {
        prepareResult(data);
      }
    }
  }, [result]);

  const prepareResult = (reportTenData: ReportTenI) => {
    let description: string[];
    setErrorReportId(reportTenData?.reportInfo?.reportId);
    for (let r of reportTenData?.reportInfo?.beregningInfo) {
      description = [];
      description.push(r.TEKSTLINJE);
      info.push(description);
    }
    setReportInfo(info);

    let result: any[] = [];
    const shortNames: any[] = [];
    const names: any[] = [];
    const enames: any[] = [];
    const enhet: any[] = [];
    const headers = ['Nr', 'Id'];
    for (let h of headers) {
      shortNames.push(h);
      names.push('');
      enames.push('');
      enhet.push('');
    }
    shortNames.push('Sum');
    names.push('');
    enhet.push(reportTenData?.enhet);
    for (let mat of reportTenData?.matvares) {
      shortNames.push(mat.KORTNAVN);
      names.push(mat.NAVN);
      enames.push(mat?.NAVN_ENGELSK);
      enhet.push(reportTenData?.enhet);
    }
    data.push(shortNames);
    data.push(names);
    if (beregningObject?.matvareData?.bregningCheckboxes?.engelskNavn) {
      data.push(enames);
    }
    data.push(enhet);
    let i = 0;
    for (let person of reportTenData?.personsAndMengdes) {
      result = [];
      result.push(person?.PERSONID);
      result.push(person?.IDENTITET);
      result.push(person?.MENGDE);
      if (reportTenData?.matvares?.length > 0) {
        for (let m = 0; m < reportTenData?.matvares?.length; m++) {
          result.push(reportTenData?.mengdes[i + m].MENGDE);
        }
        i = i + reportTenData?.matvares?.length;
      }
      data.push(result);
    }
    setReportData(data);
  };
  return <></>;
};
