import React from 'react';
import { ListItemIcon, ListItemText } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { DatabaseContext } from '../../../../context/DatabaseContext';
import { useKeycloak } from '@react-keycloak/web';
import {
  BeregningObject,
  DefaultBeregningCounts,
  MatvareGroupData
} from '../types/BeregningTypes';
import { StyledMenu, StyledMenuItem } from '../../../../css/styledMenu';
interface FjernProps {
  beregningObject: BeregningObject;
  totalCounts: DefaultBeregningCounts;
  anchorEl: any;
  handleCloseFjernMenu: () => void;
  setLoadUtvalgData: (value: boolean) => void;
  setLoadCount: (value: boolean) => void;
  selectedMatvaregroups: MatvareGroupData[];
}
const Fjern = (props: FjernProps) => {
  const backendURI = process.env.REACT_APP_BACKEND;
  const { currentDatabase } = React.useContext(DatabaseContext);
  const { keycloak } = useKeycloak();
  const token = keycloak?.token;
  const {
    beregningObject,
    anchorEl,
    handleCloseFjernMenu,
    setLoadUtvalgData,
    setLoadCount,
    totalCounts,
    selectedMatvaregroups
  } = props;
  const onClickValgteHovedgrupper = () => {
    handleCloseFjernMenu();
    deleteSelectedMGs(
      selectedMatvaregroups?.map((row: MatvareGroupData) => row.NODENRID)
    );
  };
  const onClickMatvarerIgrupper = () => {
    handleCloseFjernMenu();
    //const data = { nodeNrIds: nodeNrIdsStr };
    deleteAllMatvareInMG(
      selectedMatvaregroups?.map((row: MatvareGroupData) => row.NODENRID)
    );
  };
  const onClickAlleHovedgrupper = () => {
    handleCloseFjernMenu();
    deleteHovedgrupper();
  };
  const onClickAlleMatvarer = () => {
    handleCloseFjernMenu();
    deleteAllMatvares();
  };
  const onClickAllMGnMatvare = () => {
    handleCloseFjernMenu();
    deleteAll();
  };
  const onClickFjenMatvarer = () => {
    handleCloseFjernMenu();
    //deleteHovedgrupper();
  };
  const onClickStoffer = () => {
    handleCloseFjernMenu();
    //deleteAllMatvares();
  };
  const onClickStoffgrupper = () => {
    handleCloseFjernMenu();
    //deleteAll();
  };
  const onClickAlt = () => {
    handleCloseFjernMenu();
    //deleteAll();
  };
  async function postData(data: any, url: string, methods: string) {
    let response = await fetch(encodeURI(url), {
      method: methods,
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      body: JSON.stringify(data)
    });
    return response;
  }
  const deleteSelectedMGs = (data: number[]) => {
    if (beregningObject?.utvalgId && data?.length > 0) {
      let url = `${backendURI}/deleteSelectedMGsInButvalg/${encodeURI(
        beregningObject?.utvalgId
      )}`;
      postData({ data: data }, url, 'DELETE')
        .then((resp) => {
          if (resp.status === 200) {
            console.log('Valgte hovedgrupper fjernet fra beregningsutvalg');
            setLoadUtvalgData(true);
          } else if (resp.status === 500) {
            console.log('Error in removing selected MG');
            //TODO: throw error
          }
        })
        .catch((err) => {
          console.log('We are in the catch', err);
          //TODO: throw error
        });
    } else {
      console.log('One or more parameters are empty..');
      //TODO: throw error
    }
  };

  const deleteAllMatvareInMG = (data: number[]) => {
    if (beregningObject?.utvalgId && data?.length > 0) {
      console.log('in delete all');
      let url = `${backendURI}/deleteAllMatvareInMG/${currentDatabase?.value}/${
        beregningObject?.matvareData?.gruppetypeId
      }/${encodeURI(beregningObject?.utvalgId)}`;
      postData({ data: data }, url, 'DELETE')
        .then((resp) => {
          if (resp.status === 200) {
            console.log('Matvarer i valgte grupper fjernet fra beregningsutvalg');
            setLoadUtvalgData(true);
          } else if (resp.status === 500) {
            console.log('Error in removing all');
            //TODO: throw error
          }
        })
        .catch((err) => {
          console.log('We are in the catch', err);
          //TODO: throw error
        });
    } else {
      console.log('Empty id..');
      //TODO: throw error
    }
  };
  const deleteHovedgrupper = () => {
    if (beregningObject?.utvalgId) {
      let url = `${backendURI}/deleteHovedgrupper/${currentDatabase?.value}/${
        beregningObject?.matvareData?.gruppetypeId
      }/${encodeURI(beregningObject?.utvalgId)}`;
      postData({}, url, 'DELETE')
        .then((resp) => {
          if (resp.status === 200) {
            console.log('Hovedgrupper fjernet fra beregningsutvalg');
            setLoadUtvalgData(true);
          } else if (resp.status === 500) {
            console.log('Error in removing all Matvares');
            //TODO: throw error
          }
        })
        .catch((err) => {
          console.log('We are in the catch', err);
          //TODO: throw error
        });
    } else {
      console.log('Empty id..');
      //TODO: throw error
    }
  };

  const deleteAllMatvares = () => {
    if (beregningObject?.utvalgId) {
      let url = `${backendURI}/deleteAllMatvaresInButvalg/${encodeURI(
        beregningObject?.utvalgId
      )}`;
      postData({}, url, 'DELETE')
        .then((resp) => {
          if (resp.status === 200) {
            console.log('Alle matvarer fjernet fra beregningsutvalg');
            setLoadUtvalgData(true);
          } else if (resp.status === 500) {
            console.log('Error in removing all Matvares');
            //TODO: throw error
          }
        })
        .catch((err) => {
          console.log('We are in the catch', err);
          //TODO: throw error
        });
    } else {
      console.log('Empty id..');
      //TODO: throw error
    }
  };
  const deleteAll = () => {
    if (beregningObject?.utvalgId) {
      console.log('in delete all');
      let url = `${backendURI}/deleteMatvareAndMGInButvalg/${encodeURI(
        beregningObject?.utvalgId
      )}`;
      postData({}, url, 'DELETE')
        .then((resp) => {
          if (resp.status === 200) {
            console.log('Removed all successfully');
            setLoadUtvalgData(true);
          } else if (resp.status === 500) {
            console.log('Error in removing all');
            //TODO: throw error
          }
        })
        .catch((err) => {
          console.log('We are in the catch', err);
          //TODO: throw error
        });
    } else {
      console.log('Empty id..');
      //TODO: throw error
    }
  };
  return (
    <>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseFjernMenu}
      >
        <StyledMenuItem
          onClick={onClickValgteHovedgrupper}
          disabled={totalCounts?.TOTALMATVAREGRUPPE === 0}
        >
          <ListItemIcon>
            <DeleteOutlineOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Valgte hovedgrupper" />
        </StyledMenuItem>
        <StyledMenuItem
          onClick={onClickMatvarerIgrupper}
          disabled={
            totalCounts?.TOTALMATVAREGRUPPE === 0 || totalCounts?.TOTALMATVARE === 0
          }
        >
          <ListItemIcon>
            <DeleteOutlineOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Matvarer i valgte grupper" />
        </StyledMenuItem>
        <StyledMenuItem
          onClick={onClickAlleHovedgrupper}
          disabled={totalCounts?.TOTALMATVAREGRUPPE === 0}
        >
          <ListItemIcon>
            <DeleteOutlineOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Alle hovedgrupper" />
        </StyledMenuItem>
        <StyledMenuItem
          onClick={onClickAlleMatvarer}
          disabled={totalCounts?.TOTALMATVARE === 0}
        >
          <ListItemIcon>
            <DeleteOutlineOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Alle Matvarer" />
        </StyledMenuItem>
        <StyledMenuItem
          onClick={onClickAllMGnMatvare}
          disabled={
            totalCounts?.TOTALMATVARE === 0 && totalCounts?.TOTALMATVAREGRUPPE === 0
          }
        >
          <ListItemIcon>
            <DeleteOutlineOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Alle matvaregrupper og matvarer" />
        </StyledMenuItem>
        <StyledMenuItem
          onClick={onClickFjenMatvarer}
          disabled={totalCounts?.TOTALFJERNDEMATVARE === 0}
        >
          <ListItemIcon>
            <DeleteOutlineOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Alle Fjern matvarer" />
        </StyledMenuItem>
        <StyledMenuItem
          onClick={onClickStoffer}
          disabled={totalCounts?.TOTALSTOFFCOUNT === 0}
        >
          <ListItemIcon>
            <DeleteOutlineOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Alle stoffer" />
        </StyledMenuItem>
        <StyledMenuItem
          onClick={onClickStoffgrupper}
          disabled={totalCounts?.TOTALSTOFFCOUNT === 0}
        >
          <ListItemIcon>
            <DeleteOutlineOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Alle stoffgrupper" />
        </StyledMenuItem>
        <StyledMenuItem
          onClick={onClickAlt}
          disabled={
            totalCounts?.TOTALMATVARE === 0 &&
            totalCounts?.TOTALMATVAREGRUPPE === 0 &&
            totalCounts?.TOTALFJERNDEMATVARE === 0 &&
            totalCounts?.TOTALSTOFFCOUNT === 0
          }
        >
          <ListItemIcon>
            <DeleteOutlineOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Alt" />
        </StyledMenuItem>
      </StyledMenu>
    </>
  );
};
export default Fjern;
