import { OptionI } from '../components/pages/beregning/types/BeregningTypes';

export const enhetOption: OptionI[] = [
  {
    value: '0',
    label: ''
  },
  {
    value: '1',
    label: 'gram pr. person pr. dag'
  },
  {
    value: '2',
    label: 'gram pr. 10MJ'
  },
  {
    value: '3',
    label: 'gram pr. person pr. uke'
  },
  {
    value: '4',
    label: 'kilogram pr. person pr. år'
  },
  {
    value: '5',
    label: 'pr. person pr. dag'
  },
  {
    value: '6',
    label: 'pr. 10MJ'
  },
  {
    value: '7',
    label: 'pr. kg kroppsvekt pr. person pr. dag'
  },
  {
    value: '8',
    label: 'pr. kg kroppsvekt pr. person pr. uke'
  },
  {
    value: '9',
    label: 'pr. person pr. uke'
  },
  {
    value: '10',
    label: 'pr. person pr. år'
  },
  {
    value: '11',
    label: 'prosentfordeling'
  },
  {
    value: '12',
    label: 'pr. måltid'
  }
];
