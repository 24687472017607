import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
interface DialogProps {
  title: string;
  message: string;
  open: boolean;
  close: () => void;
  handleClickJa: () => void;
  handleClickNei: () => void;
}
export default function DialogComponent(dialogProps: DialogProps) {
  const { title, message, open, close, handleClickJa, handleClickNei } = dialogProps;
  return (
    <Dialog maxWidth="xs" fullWidth open={open} onClose={close} aria-labelledby="dialog">
      <DialogTitle id="dialog">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message || 'Ingen melding!'}
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClickJa} color="primary">
          Ja
        </Button>
        <Button onClick={handleClickNei} color="primary" autoFocus>
          Nei
        </Button>
      </DialogActions>
    </Dialog>
  );
}
