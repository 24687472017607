import React from 'react';
import { Box, Stack } from '@mui/material';
import NewSletteMatvare from '../NewSletteMatvare';
import CopyMatvare from '../CopyMatvare';
import ReactToPrint from 'react-to-print';
import moment from 'moment';
import { MatvareData } from '../types/MatvareTypes';
import { useUserContext } from '../../../../context/AuthContext';
import { NButton } from '../../components/Inputs';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

interface ComponentToPrintProps {
  currentUser: string;
  matvareData: MatvareData;
  currentDatabase: any;
}

const ComponentToPrint = React.forwardRef((props: ComponentToPrintProps, ref: any) => (
  <div ref={ref}>
    <b>Utskrift av skjermbildet: Matvare ({props.currentUser})</b>
    <br></br>
    <p>
      Utskriftsdato:
      {moment().format('YYYY-MM-DD hh:mm:ss')}
    </p>
    <p>Matvaredatabase : {props.currentDatabase?.label}</p>
    <br></br>
    <p>Kode: {props.matvareData.MATVAREKODEID}</p>
    <p>Kortnavn: V{props.matvareData.MATVAREKODEID}</p>
    <p>Navn: {props.matvareData?.NAVN}</p>
    <p>Engelsk navn: {props.matvareData?.NAVN_ENGELSK}</p>
    <p>Senr: {props.matvareData?.SENR}</p>
    <p>Offisielt navn: {props.matvareData?.OFFISIELT_NAVN}</p>
    <p>Søkeord: {props.matvareData?.SOKEORD}</p>
    <p>Maksimalmengde: {props.matvareData?.MAKSIMALMENGDE}</p>
    <p>Vektendring: {props.matvareData?.VEKTENDRING}</p>
    <p>Spiselig: {props.matvareData?.SPISELIG}</p>
    <p>Status: {props.matvareData?.STATUS}</p>
    <p>Bruttoberegning: {props.matvareData?.BRUTTOBEREGNING}</p>

    <br></br>
    <p>Beskrivelse: {props.matvareData?.BESKRIVELSE}</p>
    <p>Beskrivelse engelsk: {props.matvareData?.BESKRIVELSE_ENGELSK}</p>

    <br></br>
    <p>Referanse: {props.matvareData?.REFERANSE}</p>

    <br></br>
    <p>Kommentar: {props.matvareData?.KOMMENTAR}</p>
  </div>
));
interface IconsMenuMatvareProps {
  token: string;
  classes: any;
  owned: boolean;
  currentDatabase: any;
  matvareData: MatvareData;
  showProgress: boolean;
  setShowProgress: (value: boolean) => void;
  showAlertComponent: (message: string, severity: any) => void;
  setOpenKopiMatvareDiv?: (value: boolean) => void;
  navigateToMatvare: (matvarekode: number) => void;
  //matvareToPrint: MatvareData
}

export default function IconsMenuMatvare(props: IconsMenuMatvareProps) {
  const {
    matvareData,
    owned,
    classes,
    currentDatabase,
    showProgress,
    setShowProgress,
    showAlertComponent,
    navigateToMatvare,
    //matvareToPrint,
    token
  } = props;
  const loggedInuser = useUserContext();
  const currentUser = loggedInuser?.user?.username;
  const [openKopiMatvareDiv, setOpenKopiMatvareDiv] = React.useState(false);
  const [openSletteMatvare, setOpenSletteMatvare] = React.useState(false);
  const componentRef = React.useRef();

  const handleCloseSletteMatvareDiv = () => {
    setOpenSletteMatvare(false);
  };
  const toggleDeletetMavareDiv = () => {
    setOpenSletteMatvare(!openSletteMatvare);
  };

  const toggleShowMatvareDiv = () => {
    setOpenKopiMatvareDiv(!openKopiMatvareDiv);
  };

  return (
    <Stack direction="row-reverse" spacing={2}>
      <ReactToPrint
        trigger={() => (
          <NButton
            children="Skriv ut"
            rightIcon={<PrintOutlinedIcon />}
            variant="text"
            disabled={!matvareData.MATVAREKODEID}
          />
        )}
        pageStyle=".styledTable{width:100%;border-collapse:collapse;text-align:left}.styledTable th,.styledTable td{border:2px solid black;padding:0.5em;}"
        content={() => componentRef.current}
      />
      <Box sx={{ display: 'none' }}>
        <ComponentToPrint
          matvareData={matvareData}
          currentUser={currentUser}
          ref={componentRef}
          currentDatabase={currentDatabase}
        />
      </Box>
      <NButton
        children="Kopier"
        rightIcon={<ContentCopyOutlinedIcon />}
        variant="text"
        onClick={toggleShowMatvareDiv}
        disabled={!matvareData.MATVAREKODEID}
      />
      <CopyMatvare
        openCopyMatvare={openKopiMatvareDiv}
        handleClickCopy={toggleShowMatvareDiv}
        setOpenKopiMatvareDiv={setOpenKopiMatvareDiv}
        matvarekodeId={matvareData.MATVAREKODEID as number}
        matvareNavn={matvareData.NAVN}
        currentDatabase={currentDatabase}
        showProgress={showProgress}
        setShowProgress={setShowProgress}
        showAlertComponent={showAlertComponent}
        navigateToMatvare={navigateToMatvare}
        token={token}
      />
      <NButton
        children="Slett"
        rightIcon={<DeleteOutlineOutlinedIcon />}
        variant={'text'}
        disabled={!(matvareData.MATVAREKODEID && owned)}
        onClick={toggleDeletetMavareDiv}
      />
      {openSletteMatvare && (
        <NewSletteMatvare
          open={openSletteMatvare}
          close={handleCloseSletteMatvareDiv}
          matvarekodeId={matvareData.MATVAREKODEID as number}
        />
      )}
    </Stack>
  );
}
