import React, { useEffect, useState } from 'react';
import { Grid, Paper, Stack, Typography } from '@mui/material';
import PageContainer from '../home/PageContainer';
import { NButton, SlowText } from '../components/Inputs';
import useFetchData from '../../utilities/useFetchData';
import { AlertI } from '../../../types/alertTypes';
import AlertComp from '../../utilities/AlertComp';
import { ProgressBar } from '../../utilities/ProgressBar';
import { useKeycloak } from '@react-keycloak/web';
import { DefaultVerdier } from '../beregning/types/BeregningTypes';
import { UserRole } from './AdminTypes';
import DeleteDialog from '../kosthold/components/DeleteDialog';

export default function DefaultAHV() {
  const { keycloak } = useKeycloak();
  const token = keycloak?.token || 'Mangler tilgang';
  const [title] = React.useState('Defaultverdier alder, høyde, vekt - NutriFoodCalc');
  document.title = title;
  const [defaultValues, setDefaultValues] = useState<DefaultVerdier>({
    DEFAULT1: '0',
    DEFAULT2: '0',
    DEFAULT3: '0',
    DEFAULT4: '0',
    DEFAULT5: '0',
    DEFAULT6: '0',
    DEFAULT7: '0'
  });
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = React.useState<string>('');
  const [alertSeverity, setAlertSeverity] = React.useState<AlertI['alertColor']>('info');
  const [saveUrl] = React.useState<string>('saveDefaultMeasurments');
  const [deleteUrl] = React.useState<string>(`deleteDefaultMeasurments`);
  const apiCall = (uri: string, method: string, body?: any) => {
    const fullUri = `${process.env.REACT_APP_BACKEND}/${uri}`;
    return fetch(encodeURI(fullUri), {
      method: method,
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      body: body ? JSON.stringify(body) : undefined
    });
  };
  const getDefaultValues = useFetchData({
    loadOnMount: true,
    clearDataOnLoad: false,
    projectURL: 'getDefaultMeasurments',
    subProjectURL: null
  });
  useEffect(() => {
    if (getDefaultValues.data) {
      const data: DefaultVerdier = getDefaultValues.data as unknown as DefaultVerdier;
      setDefaultValues(data);
    }
  }, [getDefaultValues.data]);

  const save = async () => {
    setShowProgress(true);
    const response = await apiCall(saveUrl, 'POST', { data: defaultValues });
    if (response.ok) {
      showAlertComponent('Lagringen er fullført', 'success');
    } else {
      showAlertComponent(`Lagringen er ikke fullført`, 'error');
    }
    setShowProgress(false);
  };
  const deleteValues = async () => {
    setShowProgress(true);
    const response = await apiCall(deleteUrl, 'DELETE', { data: {} });
    if (response.ok) {
      clear();
      showAlertComponent('Slettingen er fullført', 'success');
    } else {
      showAlertComponent(`Slettingen er ikke fullført`, 'error');
    }
    setShowProgress(false);
  };
  const clear = async () => {
    setDefaultValues({
      DEFAULT1: '0',
      DEFAULT2: '0',
      DEFAULT3: '0',
      DEFAULT4: '0',
      DEFAULT5: '0',
      DEFAULT6: '0',
      DEFAULT7: '0'
    });
  };
  const handleChange = (name: string, value: number) => {
    setDefaultValues({ ...defaultValues, [name]: value });
  };
  const showAlertComponent = (message: string, severity: any) => {
    setShowAlert(true);
    setAlertMessage(message);
    setAlertSeverity(severity);
  };
  return (
    <PageContainer>
      <Paper style={{ padding: '1.5em 15px 1.5em 15px', boxShadow: 'none' }}>
        <Typography variant="h4">Defaultverdier alder, høyde, vekt</Typography>
        <Grid
          item
          xs={12}
          container
          sx={{ pt: 2 }}
          spacing={2}
          justifyContent={'flex-start'}
        >
          <Grid item xs={3}>
            <Typography variant="h6">{' Standard kroppsvekt: '}</Typography>
          </Grid>
          <Grid item xs={7}>
            <Stack direction="row" spacing={1}>
              <SlowText
                header=""
                value={defaultValues?.DEFAULT1}
                onChange={(e) => handleChange('DEFAULT1', +e.target.value)}
                fullWidth
                disabled={
                  !(
                    keycloak?.hasRealmRole(UserRole.Admin) ||
                    keycloak?.hasRealmRole(UserRole.Akf)
                  )
                }
              />
            </Stack>
          </Grid>
          <Grid item xs={3}></Grid>
        </Grid>
        <Grid item xs={12} sx={{ pt: 2 }} container spacing={2} alignItems="flex-end">
          <Grid item xs={12} spacing={2} alignItems="flex-end">
            <Typography variant="h6">{'Gyldighetsområde'}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="h6">{' Minimum: '}</Typography>
          </Grid>
          <Grid item xs={8}>
            <Stack direction="row" spacing={1}>
              <SlowText
                header="Høyde"
                value={defaultValues?.DEFAULT2}
                onChange={(e) => handleChange('DEFAULT2', +e.target.value)}
                fullWidth
                disabled={
                  !(
                    keycloak?.hasRealmRole(UserRole.Admin) ||
                    keycloak?.hasRealmRole(UserRole.Akf)
                  )
                }
              />
              <SlowText
                header="Vekt"
                value={defaultValues?.DEFAULT3}
                onChange={(e) => handleChange('DEFAULT3', +e.target.value)}
                fullWidth
                disabled={
                  !(
                    keycloak?.hasRealmRole(UserRole.Admin) ||
                    keycloak?.hasRealmRole(UserRole.Akf)
                  )
                }
              />
              <SlowText
                header="Alder"
                value={defaultValues?.DEFAULT4}
                onChange={(e) => handleChange('DEFAULT4', +e.target.value)}
                fullWidth
                disabled={
                  !(
                    keycloak?.hasRealmRole(UserRole.Admin) ||
                    keycloak?.hasRealmRole(UserRole.Akf)
                  )
                }
              />
            </Stack>
          </Grid>
          <Grid item xs={2}></Grid>
        </Grid>
        <Grid item xs={12} container spacing={2} alignItems="flex-end">
          <Grid item xs={2}>
            <Typography variant="h6">{' Maksimum: '}</Typography>
          </Grid>
          <Grid item xs={8}>
            <Stack direction="row" spacing={1}>
              <SlowText
                header=""
                value={defaultValues?.DEFAULT5}
                onChange={(e) => handleChange('DEFAULT5', +e.target.value)}
                fullWidth
                disabled={
                  !(
                    keycloak?.hasRealmRole(UserRole.Admin) ||
                    keycloak?.hasRealmRole(UserRole.Akf)
                  )
                }
              />
              <SlowText
                header=""
                value={defaultValues?.DEFAULT6}
                onChange={(e) => handleChange('DEFAULT6', +e.target.value)}
                fullWidth
                disabled={
                  !(
                    keycloak?.hasRealmRole(UserRole.Admin) ||
                    keycloak?.hasRealmRole(UserRole.Akf)
                  )
                }
              />
              <SlowText
                header=""
                value={defaultValues?.DEFAULT7}
                onChange={(e) => handleChange('DEFAULT7', +e.target.value)}
                fullWidth
                disabled={
                  !(
                    keycloak?.hasRealmRole(UserRole.Admin) ||
                    keycloak?.hasRealmRole(UserRole.Akf)
                  )
                }
              />
            </Stack>
          </Grid>
          <Grid item xs={2}></Grid>
        </Grid>
        <Grid item xs={12} container spacing={2}>
          <Grid item xs={10}>
            <Stack padding={4} direction="row" spacing={1} justifyContent={'flex-end'}>
              <NButton
                children="Lagre"
                onClick={save}
                disabled={
                  !(
                    keycloak?.hasRealmRole(UserRole.Admin) ||
                    keycloak?.hasRealmRole(UserRole.Akf)
                  )
                }
              />
              <DeleteDialog
                title={'Slett default verdiene'}
                message={
                  'Sletting av default verdiene vil føre til at KBS mister litt av sin funksjonalitet. Vil du slette default verdiene?'
                }
                variant={'outlined'}
                deleteFn={deleteValues}
                disabled={
                  !(
                    keycloak?.hasRealmRole(UserRole.Admin) ||
                    keycloak?.hasRealmRole(UserRole.Akf)
                  )
                }
              />
              <NButton
                children="Nullstill"
                variant="outlined"
                onClick={clear}
                disabled={
                  !(
                    keycloak?.hasRealmRole(UserRole.Admin) ||
                    keycloak?.hasRealmRole(UserRole.Akf)
                  )
                }
              />
            </Stack>
          </Grid>
          <Grid item xs={2} alignItems="center"></Grid>
        </Grid>
        {showAlert && (
          <AlertComp
            alertMessage={alertMessage}
            alertColor={alertSeverity}
            showAlert={showAlert}
            setShowAlert={setShowAlert}
          />
        )}
        {showProgress && (
          <ProgressBar
            isShowing={showProgress}
            hide={() => {
              setShowProgress(!showProgress);
            }}
          />
        )}
      </Paper>
    </PageContainer>
  );
}
