import React, { useState } from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  TableContainer,
  Checkbox,
  Grid,
  Link
} from '@mui/material';
import useStylesTable from '../../../../css/TableCss';
import {
  Matvare,
  MatvaregruppeI,
  MatvaregruppeParam,
  TableType
} from '../types/matvaregruppeTypes';
import { MatvareTable } from './MatvareTable';
import { SingleInput } from '../components/SingleInput';
import { NButton } from '../../components/Inputs';
import {
  areAllElementsInArray,
  removeArrayFromAnotherArray,
  someButNotAllElementsInArray
} from '../../../utilities/ArrayFunctions';

interface MGTableProps {
  highLevelCategory: number;
  owned: boolean;
  nivaa: number;
  showMatvare: boolean;
  parent: string;
  matvarerList: Matvare[];
  matvaregruppeList: MatvaregruppeI[];
  move: boolean;
  openUndergrupper: (
    nodeId: number,
    name: string,
    nivaa: number,
    hasNext: boolean,
    undergrupp: number,
    type: string
  ) => void;
  loadMatvare: (
    nodeId: number,
    name: string,
    nivaa: number,
    update: boolean,
    type: string
  ) => void;
  tableType: TableType;
  selectedRow?: MatvaregruppeI;
  setSelectedRow?: (value: MatvaregruppeI) => void;
  setMatvaregruppeList?: (list: MatvaregruppeI[]) => void;
  newGroup?: MatvaregruppeParam;
  setNewGroup?: (object: MatvaregruppeParam) => void;
  selectedMatvares?: Matvare[];
  setSelectedMatvares?: (mat: Matvare[]) => void;
  selectedMatvaregruppes?: MatvaregruppeI[];
  setSelectedMatvaregruppes?: (mg: MatvaregruppeI[]) => void;
  //utvalgData?: UtvalgTableData;
}

export const MatvareGruppeTable = (props: MGTableProps) => {
  const {
    highLevelCategory,
    owned,
    nivaa,
    showMatvare,
    parent,
    matvarerList,
    openUndergrupper,
    loadMatvare,
    selectedRow,
    setSelectedRow,
    matvaregruppeList,
    newGroup,
    setNewGroup,
    move,
    tableType,
    setMatvaregruppeList,
    selectedMatvares,
    setSelectedMatvares,
    selectedMatvaregruppes,
    setSelectedMatvaregruppes
  } = props;
  const classesTable = useStylesTable();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };
  const handleNewRow = (name: string, value: string | number) => {
    setNewGroup({ ...newGroup, [name]: value as string });
  };
  const handleChangeTable = (name: string, value: string | number, index: number) => {
    setMatvaregruppeList([
      ...matvaregruppeList?.map((object: any, oIndex: number) => {
        if (oIndex === index) {
          return {
            ...object,
            [name]: value
          };
        } else {
          return object;
        }
      })
    ]);
  };

  const handleSelectMatvaregruppe = (matvaregruppe: MatvaregruppeI) => {
    const index = selectedMatvaregruppes?.findIndex(
      (n: MatvaregruppeI) => n?.NODENRID === matvaregruppe?.NODENRID
    );
    index > -1
      ? selectedMatvaregruppes?.splice(index, 1)
      : selectedMatvaregruppes?.push(matvaregruppe);
  };

  /**
   *
   * This can be a bit tricky as the selectAll is not for all the data but the sub-data that is currently displayed on the table
   */
  const handleSelectAllMatvaregruppe = (isChecked: boolean) => {
    if (isChecked) {
      const selecteds = removeArrayFromAnotherArray(
        matvaregruppeList,
        selectedMatvaregruppes,
        'NODENRID'
      );
      setSelectedMatvaregruppes([...selectedMatvaregruppes, ...selecteds]);
    } else {
      const updatedMatvaregruppes = removeArrayFromAnotherArray(
        selectedMatvaregruppes,
        matvaregruppeList,
        'NODENRID'
      );
      setSelectedMatvaregruppes(updatedMatvaregruppes);
    }
  };

  const isSelected = (row: MatvaregruppeI) => {
    let selected = false;
    const ids: number[] = selectedMatvaregruppes?.map((u) => u.NODENRID);
    const found = ids?.includes(row.NODENRID);
    if (
      found ||
      (row.NIVAA === 1 && highLevelCategory === 1) ||
      highLevelCategory === 2 ||
      highLevelCategory === 4
    ) {
      selected = true;
    }
    return selected;
  };
  const clearSelectedMgs = () => {
    const updatedMatvaregruppes = removeArrayFromAnotherArray(
      selectedMatvaregruppes,
      matvaregruppeList,
      'NODENRID'
    );
    setSelectedMatvaregruppes(updatedMatvaregruppes);
  };
  const resetSelected = () => {
    setSelectedMatvaregruppes([]);
    setSelectedMatvares([]);
  };
  const resetNothing = () => {
    console.log('This is just avoid getting eslint error...');
  };
  return (
    <div>
      <Box p={1}>
        {showMatvare ? (
          <MatvareTable
            owned={owned}
            matvarerList={matvarerList}
            parent={'Matvarer i ' + parent}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleChangePage={handleChangePage}
            tableType={tableType}
            selectedMatvares={selectedMatvares}
            setSelectedMatvares={setSelectedMatvares}
            highLevelCategory={highLevelCategory}
            // utvalgData={utvalgData}
          />
        ) : (
          <>
            <br />
            {/*this is to align matvare group table to matvare table*/}
            <TableContainer
              style={{
                height: 800,
                overflowY: 'auto'
              }}
              id="matvaregruppeTable"
            >
              <Table sx={classesTable.body}>
                <TableHead sx={classesTable.head}>
                  <TableRow>
                    {tableType === TableType.Utvalg ||
                    tableType === TableType.Beregning ? (
                      <TableCell padding="checkbox">
                        <Checkbox
                          indeterminate={someButNotAllElementsInArray(
                            selectedMatvaregruppes,
                            matvaregruppeList,
                            'NODENRID'
                          )}
                          checked={areAllElementsInArray(
                            matvaregruppeList,
                            selectedMatvaregruppes,
                            'NODENRID'
                          )}
                          onChange={(e) => handleSelectAllMatvaregruppe(e.target.checked)}
                          //disabled={parent!=='Hovedgrupper'}
                        />
                      </TableCell>
                    ) : undefined}
                    <TableCell>Navn</TableCell>
                    {!move ? (
                      <>
                        {' '}
                        <TableCell>Kortnavn</TableCell>
                        <TableCell>Engelsk navn</TableCell>{' '}
                      </>
                    ) : undefined}
                    <TableCell>Undergrupper</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody sx={classesTable.root}>
                  {matvaregruppeList?.map((row: MatvaregruppeI, tableIndex: number) => {
                    const highlighted =
                      selectedRow && selectedRow?.NODENRID === row?.NODENRID;
                    // const isItemSelected = isSelected(row);
                    return (
                      <TableRow
                        key={`grupper${row?.NODENRID}`}
                        hover
                        role="checkbox"
                        onClick={() => {
                          setSelectedRow(highlighted ? null : row);
                        }}
                        style={{
                          backgroundColor: highlighted ? '#d2ecfd' : 'white'
                        }}
                        sx={classesTable.tableRow}
                      >
                        <>
                          {tableType === TableType.Utvalg ||
                          tableType === TableType.Beregning ? (
                            <TableCell padding="checkbox">
                              <Checkbox
                                checked={isSelected(row)}
                                onClick={() => handleSelectMatvaregruppe(row)}
                                disabled={
                                  !(highLevelCategory == 5 || highLevelCategory == 0) &&
                                  tableType === TableType.Beregning
                                }
                              />
                            </TableCell>
                          ) : undefined}
                          <SingleInput
                            value={row?.NAVN}
                            name={'NAVN'}
                            owned={owned}
                            handleChangeTable={handleChangeTable}
                            size={move ? '75%' : '35%'}
                            index={tableIndex}
                          />
                          {!move ? (
                            <>
                              {' '}
                              <SingleInput
                                value={row?.KORTNAVN}
                                name={'KORTNAVN'}
                                owned={owned}
                                handleChangeTable={handleChangeTable}
                                size={'20%'}
                                index={tableIndex}
                              />
                              <SingleInput
                                value={row?.NAVN_ENGELSK}
                                name={'NAVN_ENGELSK'}
                                owned={owned}
                                handleChangeTable={handleChangeTable}
                                size={'35%'}
                                index={tableIndex}
                              />
                            </>
                          ) : undefined}
                          <TableCell
                            style={{ borderRight: '1px solid lightgrey', padding: 0 }}
                          >
                            {row.HARUNDERGRUPP ? (
                              <Link
                                underline={'hover'}
                                component="button"
                                variant="body2"
                                onClick={() => {
                                  openUndergrupper(
                                    row?.NODENRID,
                                    row?.NAVN,
                                    row?.NIVAA,
                                    row?.HARUNDERGRUPP,
                                    row?.UNDERGURPPER?.length,
                                    tableType
                                  );
                                  tableType === TableType.From ||
                                  tableType === TableType.Utvalg
                                    ? resetSelected()
                                    : resetNothing();
                                }}
                              >
                                Vis undergruppe
                              </Link>
                            ) : (
                              <>
                                {row.HARMATVARE ? (
                                  <Link
                                    underline={'hover'}
                                    component="button"
                                    variant="body2"
                                    onClick={() => {
                                      loadMatvare(
                                        row.NODENRID,
                                        row.NAVN,
                                        nivaa,
                                        false,
                                        tableType
                                      );
                                      tableType === TableType.From ||
                                      tableType === TableType.Utvalg
                                        ? resetSelected()
                                        : resetNothing();
                                    }}
                                  >
                                    {row.NAVN === 'Retter (splittes ved beregning)'
                                      ? 'Vis retter(splittes)'
                                      : 'Vis matvarer'}
                                  </Link>
                                ) : (
                                  <>
                                    <Link
                                      underline={'hover'}
                                      component="button"
                                      variant="body2"
                                      onClick={() => {
                                        openUndergrupper(
                                          row?.NODENRID,
                                          row?.NAVN,
                                          row?.NIVAA,
                                          false,
                                          0,
                                          tableType
                                        );
                                        tableType === TableType.From ||
                                        tableType === TableType.Utvalg
                                          ? resetSelected()
                                          : resetNothing();
                                      }}
                                    >
                                      Lag undergruppe
                                    </Link>
                                    {tableType === TableType.To ? (
                                      <Link
                                        underline={'hover'}
                                        component="button"
                                        variant="body2"
                                        onClick={() => {
                                          loadMatvare(
                                            row?.NODENRID,
                                            row?.NAVN,
                                            nivaa + 1,
                                            false,
                                            tableType
                                          );
                                        }}
                                      >
                                        Legg til matvarer
                                      </Link>
                                    ) : undefined}
                                  </>
                                )}
                              </>
                            )}
                          </TableCell>
                        </>
                      </TableRow>
                    );
                  })}
                  {owned && !move ? (
                    <TableRow
                      onClick={() => {
                        setSelectedRow(null);
                      }}
                    >
                      <SingleInput
                        value={newGroup?.NAVN || ''}
                        name={'NAVN'}
                        owned={owned}
                        maxLength={20}
                        handleChangeTable={handleNewRow}
                        size={'35%'}
                        placeholderText={'Legg til ny matvaregruppe navn'}
                      />
                      <SingleInput
                        value={newGroup?.KORTNAVN || ''}
                        name={'KORTNAVN'}
                        owned={owned}
                        maxLength={8}
                        handleChangeTable={handleNewRow}
                        size={'20%'}
                        placeholderText={'Legg til matvaregruppe kortnavn'}
                      />
                      <SingleInput
                        value={newGroup?.NAVN_ENGELSK || ''}
                        name={'NAVN_ENGELSK'}
                        owned={owned}
                        maxLength={20}
                        handleChangeTable={handleNewRow}
                        size={'35%'}
                        placeholderText={'Legg til matvaregruppe engelsk navn'}
                      />
                      <TableCell
                        style={{ borderRight: '1px solid lightgrey', padding: 0 }}
                      ></TableCell>
                    </TableRow>
                  ) : undefined}
                </TableBody>
              </Table>
            </TableContainer>
            {/*   {!(tableType === TableType.From || tableType === TableType.To || tableType === TableType.Show) ? (
            <Grid container padding={4} spacing={1} justifyContent="flex-end" alignItems="flex-end">
              <NButton
                children="Nullstill"
                variant='outlined'
                onClick={clearSelectedMgs}
                disabled={selectedMatvaregruppes?.length === 0}
              />
            </Grid>) : null} */}
          </>
        )}
      </Box>
    </div>
  );
};
