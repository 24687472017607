import { HeadCell } from '../../components/commons/CommonTypes';
import {
  MatvareDetaljeI,
  MatvareIUndersokelseI
} from '../../kosthold/types/UndersokelseTypes';
import { RetensjonsfaktorerI, StoffKomponent } from '../types/StoffTypes';

export const HeadCellsSK: HeadCell<StoffKomponent>[] = [
  {
    id: 'STOFFNRID',
    align: 'right',
    padding: 'normal',
    label: 'S.Nr'
  },
  {
    id: 'KORTNAVN',
    align: 'left',
    padding: 'normal',
    label: 'Kortnavn'
  },
  {
    id: 'NAVN',
    align: 'left',
    padding: 'normal',
    label: 'Navn'
  },
  {
    id: 'MENGDE',
    align: 'right',
    padding: 'normal',
    label: 'Mengde'
  },
  {
    id: 'ENHET',
    align: 'left',
    padding: 'normal',
    label: 'Enhet'
  },
  {
    id: 'KORR',
    align: 'left',
    padding: 'normal',
    label: 'Korr'
  },
  {
    id: 'KILDE',
    align: 'left',
    padding: 'normal',
    label: 'Kilde'
  },
  {
    id: 'REBER',
    align: 'left',
    padding: 'normal',
    label: 'Reber'
  },
  {
    id: 'BERIK',
    align: 'left',
    padding: 'normal',
    label: 'Berik'
  }
];
export const HeadCellsRF: HeadCell<RetensjonsfaktorerI>[] = [
  {
    id: 'STOFFNRID',
    align: 'right',
    padding: 'normal',
    label: 'S.Nr'
  },
  {
    id: 'KORTNAVN',
    align: 'left',
    padding: 'normal',
    label: 'Kortnavn'
  },
  {
    id: 'NAVN',
    align: 'left',
    padding: 'normal',
    label: 'Navn'
  },
  {
    id: 'MENGDE',
    align: 'right',
    padding: 'normal',
    label: 'Mengde'
  },
  {
    id: 'KOKING',
    align: 'left',
    padding: 'normal',
    label: 'Koking'
  },
  {
    id: 'STEKING',
    align: 'left',
    padding: 'normal',
    label: 'Steking'
  },
  {
    id: 'BAKING',
    align: 'left',
    padding: 'normal',
    label: 'Baking'
  }
];
export const headCellsMIU: HeadCell<MatvareIUndersokelseI>[] = [
  { id: 'matvarekode', label: 'Kode', align: 'right' },
  { id: 'navn', label: 'Navn', align: 'left' },
  { id: 'eier', label: 'Eier', align: 'left' },
  { id: 'oppskrift', label: 'Oppskrift', align: 'left' },
  { id: 'vektendring', label: 'Vektendring', align: 'right' },
  { id: 'antall', label: 'Antall', align: 'right' },
  { id: 'komentarer', label: 'Komentarer', align: 'right' },
  { id: 'beskrivelse', label: 'Beskrivelse', align: 'left' }
];
export const headCellsMIUD: HeadCell<MatvareDetaljeI>[] = [
  { id: 'intervjuer', label: 'Intervjuer', align: 'left' },
  { id: 'kommentar', label: 'Kommentar', align: 'left' },
  { id: 'personId', label: 'PersonId', align: 'right' },
  { id: 'identitet', label: 'Identitet', align: 'left' },
  { id: 'lopedagId', label: 'LøpedagId', align: 'right' },
  { id: 'maaltidnrId', label: 'MåltidnrId', align: 'right' },
  { id: 'ordrnrId', label: 'OrdrnrId', align: 'right' }
];
