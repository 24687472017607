import { TableCell, TableRow } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { HeadCell } from '../pages/components/commons/CommonTypes';

interface TableEmptyRowsProps<T> {
  headCells: HeadCell<T>[];
  dataLength: number;
  minRows: number;
  extraCells?: number;
}

export default function TableEmptyRows<T>(props: TableEmptyRowsProps<T>) {
  const { headCells, dataLength, minRows, extraCells } = props;
  const [rowsLessThan, setRowsLessThan] = useState<boolean>(false);
  const [emptyRows, setEmptyRows] = useState<number>();

  useEffect(() => {
    if (dataLength) {
      setRowsLessThan(dataLength < minRows);
      setEmptyRows(minRows - dataLength);
    }
  }, [dataLength]);

  return (
    <>
      {rowsLessThan &&
        Array(emptyRows)
          .fill(0)
          .map((_, index) => (
            <TableRow key={index} sx={{ minHeight: '50' }}>
              {headCells?.map((cell) => (
                <TableCell key={cell.id}>&nbsp;</TableCell>
              ))}
              {extraCells &&
                Array(extraCells)
                  .fill(0)
                  .map((_, index) => <TableCell key={index}>&nbsp;</TableCell>)}
            </TableRow>
          ))}
    </>
  );
}
