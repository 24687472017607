import { FormControl, MenuItem, Select, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useKeycloak } from '@react-keycloak/web';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useProjectContext } from '../../../../../context/ProjectContext';
import { AlertI } from '../../../../../types/alertTypes';
import { ApiCall } from '../../../../utilities/ApiCall';
import DialogGeneral from '../../../../utilities/DialogGeneral';
import { FormInput2, NButton } from '../../../components/Inputs';
import { ProsjektI, UndersokelseI } from '../../types/ProjectTypes';

interface RouteParams {
  prosjektId: string;
  undersokelseId: string;
  undersokelseKortnavn: string;
}

interface CopyVariablerProps extends RouteComponentProps<RouteParams> {
  showing: boolean;
  hide: () => void;
  callAlert: (message: string, severity: AlertI['alertColor']) => void;
  setRefetchVariabler: Dispatch<SetStateAction<boolean>>;
}

export default function UndersokelseCopyVariablerModal(props: CopyVariablerProps) {
  const { showing, hide, callAlert, setRefetchVariabler } = props;
  const { prosjektId, undersokelseId, undersokelseKortnavn } = props.match.params;
  const [undersokelser, setUndersokelser] = useState<UndersokelseI[]>();
  const [selectedProject, setSelectedProject] = useState<ProsjektI['prosjektId']>();
  const [selectedUndersokelse, setSelectedUndersokelse] =
    useState<UndersokelseI['kortNavn']>();
  const projectContext = useProjectContext();
  const projectObject = projectContext.projects?.find(
    (p) => p.prosjektId === +prosjektId
  );
  const { keycloak } = useKeycloak();

  const copyTilleggsvariables = async () => {
    const selectedUndersokelseId = undersokelser?.find(
      (u) => u.kortNavn === selectedUndersokelse
    )?.undersokelseId;
    const response = await ApiCall(
      `copyTilleggsvariables/${prosjektId}/${undersokelseId}/${selectedProject}/${selectedUndersokelseId}`,
      'GET',
      keycloak.token
    );
    if (response?.ok) {
      callAlert('Tilleggsvariabler kopiert', 'success');
      setRefetchVariabler(true);
    } else {
      callAlert('Kopiering av tilleggsvariabler feilet', 'error');
    }
  };

  const getSurveys = async () => {
    if (!selectedProject) return;
    const response = await ApiCall<UndersokelseI[]>(
      `hentPUndersokelser/${selectedProject}`,
      'GET',
      keycloak.token
    );
    if (response?.ok) {
      const json = await response?.json();
      if (json?.length === 0) {
        callAlert('Prosjektet finnes ikke eller har ingen undersøkelser', 'error');
        setSelectedProject(null);
        setUndersokelser(null);
        return;
      }
      setUndersokelser(json);
    } else {
      setSelectedProject(null);
      setUndersokelser(null);
      callAlert('Kunne ikke hente prosjekt', 'error');
    }
  };

  return (
    <>
      <DialogGeneral
        title="Kopi av tilleggsvariabler"
        open={showing}
        hide={hide}
        fullWidth
        maxWidth="sm"
      >
        <Stack spacing={2}>
          <Stack spacing={1} paddingBottom={2}>
            <Typography variant="body1">
              <b>Prosjekt: </b> {projectObject?.prosjektnavn} - {prosjektId}
            </Typography>
            <Typography variant="body1">
              <b>Undersøkelse: </b> {undersokelseKortnavn}
            </Typography>
          </Stack>

          <Stack direction={'row'} spacing={1} alignItems={'end'} useFlexGap>
            <FormInput2
              value={selectedProject || ''}
              header="Prosjekt"
              size="small"
              type="number"
              onChange={(event) => setSelectedProject(+event.target.value)}
              onBlur={getSurveys}
              sx={{ width: '10rem' }}
            />

            <FormControl sx={{ marginRight: 'auto' }}>
              <Typography>Undersøkelse</Typography>
              <Select
                value={selectedUndersokelse || ''}
                onChange={(event) => setSelectedUndersokelse(event.target.value)}
                size="small"
                sx={{ width: '10rem' }}
                disabled={!undersokelser || !selectedProject}
              >
                {undersokelser
                  ?.map((u) => u?.kortNavn)
                  .map((navn, index) => (
                    <MenuItem key={navn + index} value={navn}>
                      {navn}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Stack>
        </Stack>

        <Stack sx={{ paddingTop: '2.5rem' }} spacing={4} justifyContent="space-between">
          {selectedProject > 0 && selectedUndersokelse?.length > 0 && (
            <Typography variant="body1">
              {`Vil du kopiere tilleggsvariablene fra undersøkelse ${selectedUndersokelse} til undersøkelse ${undersokelseKortnavn}?`}
            </Typography>
          )}
          <Stack direction={'row'} spacing={1} justifyContent="end">
            <NButton
              children="Ja"
              onClick={() => {
                copyTilleggsvariables();
                hide();
              }}
              disabled={!selectedUndersokelse || !selectedProject}
            />
            <NButton
              children="Nei"
              variant="outlined"
              onClick={() => hide()}
              disabled={!selectedUndersokelse || !selectedProject}
            />
          </Stack>
        </Stack>
      </DialogGeneral>
    </>
  );
}
