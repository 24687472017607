import React from 'react';
import useFetchData from '../../utilities/useFetchData';
import {
  Checkbox,
  Grid,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import { useKeycloak } from '@react-keycloak/web';
import { ConfirmationDialog } from '../../utilities/ConfirmationDialog';
import TilleggsvariableIcons from './components/TilleggsvariableIcons';
import { TilleggsvariableList } from './components/TilleggsvariableList';
import { ProgressBar } from '../../utilities/ProgressBar';
import PageContainer from '../home/PageContainer';
import { Dropdown, NButton, SlowText } from '../components/Inputs';

import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import IsOwnedByUser from '../../utilities/Validate';
import { useUserContext } from '../../../context/AuthContext';
import { UserRole } from '../admin/AdminTypes';
import AlertComp from '../../utilities/AlertComp';
import { AlertI } from '../../../types/alertTypes';
import {
  TilleggsvariableI,
  TilleggsvariableSvarI,
  VariabelIdI
} from './types/TilleggsvariableTypes';
import { hasDataChanged } from '../../utilities/ArrayFunctions';
import ClearIcon from '@mui/icons-material/Clear';
import { useHistory } from 'react-router-dom';

export default function Tilleggsvariabler(props: any) {
  const backendURI = process.env.REACT_APP_BACKEND;
  const { keycloak } = useKeycloak();
  const token = keycloak?.token;
  const loggedInuser = useUserContext();
  const history = useHistory();
  const variabelIdFromURL: string = props.match.params.variabelId || '';
  const initialTilleggsvariable = {
    variabelId: 0,
    kortnavn: '',
    sporsmal: '',
    svartype: '',
    tilleggssvar: [],
    prosjektnr: 0,
    beskrivelse: '',
    tilgangskode: 0
  };
  const initialSvar = {
    kategoriId: 0,
    kode: +'',
    beskrivelse: '',
    isEditMode: true
  };
  const [originalVariabelCopy, setOriginalVariabelCopy] =
    React.useState<TilleggsvariableI>(initialTilleggsvariable);
  const [tilleggsvariable, setTilleggsvariable] = React.useState<TilleggsvariableI>(
    initialTilleggsvariable
  );
  const [tilleggsvariablerList, setTilleggsvariablerList] = React.useState<
    TilleggsvariableI[]
  >([]);
  const [title, setTitle] = React.useState('Tilleggsvariabler - Nutrifoodcalc');
  //const [svarKatergoriList, setSvarKatergoriList] = React.useState<TilleggsvariableSvarI[]>([] );
  const [newSvarKatergori, setNewSvarKatergori] =
    React.useState<TilleggsvariableSvarI>(initialSvar);
  const [variabelId, setVariabelId] = React.useState<number>(0);
  const [showCDialog, setShowCDialog] = React.useState(false);
  const [dialogText] = React.useState('');
  const [dialogTitle] = React.useState('');
  const [, setConfirmationResult] = React.useState(null);
  const [owned, setOwned] = React.useState(false);
  const [ownedVariable, setOwnedVariable] = React.useState(false);
  //const [newVariableId, setNewVariabelId] = React.useState<VariabelIdI>({ 'id': 0 });
  const [refetchVariableList, setRefetchVariableList] = React.useState(false);
  const [showSvarkategorier, setShowSvarkategorier] = React.useState(false);
  const [showTilleggsvariableList, setShowTilleggsvariableList] = React.useState(false);
  const [showAlert, setShowAlert] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState('');
  const [alertSeverity, setAlertSeverity] = React.useState<AlertI['alertColor']>('info');
  const [showProgress, setShowProgress] = React.useState(false);
  const [kError, setKError] = React.useState('');
  const [error, setError] = React.useState('');
  document.title = title;
  const getVariableList = useFetchData({
    loadOnMount: true,
    clearDataOnLoad: false,
    projectURL: 'getTilleggsvariablerList'
  });
  const getTilleggsvariabel = useFetchData({
    loadOnMount: false,
    clearDataOnLoad: false,
    projectURL: 'getTilleggsvariabel',
    subProjectURL: `${variabelId}`
  });
  React.useEffect(() => {
    if (refetchVariableList) {
      getVariableList.loadData('getTilleggsvariablerList', null);
    }
  }, [refetchVariableList]);
  React.useEffect(() => {
    loadTilleggsvariable();
  }, [variabelId, variabelIdFromURL]);

  const loadTilleggsvariable = () => {
    const id: number = variabelId || +variabelIdFromURL;
    if (id) {
      getTilleggsvariabel.loadData('getTilleggsvariabel', `${id}`);
    }
  };
  React.useEffect(() => {
    if (getVariableList.data) {
      const data: TilleggsvariableI[] =
        getVariableList.data as unknown as TilleggsvariableI[];
      setTilleggsvariablerList(data);
      if (variabelId === 0) {
        const id = data.find(
          (d) => d.kortnavn === tilleggsvariable?.kortnavn
        )?.variabelId;
        setVariabelId(id);
      }
      setRefetchVariableList(false);
    }
  }, [getVariableList.data]);

  React.useEffect(() => {
    if (getTilleggsvariabel.data) {
      const data: TilleggsvariableI =
        getTilleggsvariabel.data as unknown as TilleggsvariableI;
      //setTilleggsvariable(data);
      const updatedData = {
        ...data,
        tilleggssvar: data?.tilleggssvar?.map((svar) => ({ ...svar, isEditMode: false }))
      };
      setTilleggsvariable(updatedData);
      setOriginalVariabelCopy({ ...data });
      if (data?.svartype === 'Kategorier') {
        setShowSvarkategorier(true);
      } else {
        setShowSvarkategorier(false);
      }
      pushVariabelHistory(data.variabelId);
    }
  }, [getTilleggsvariabel.data]);

  React.useEffect(() => {
    if (getTilleggsvariabel.error) {
      showAlertMessage('Det har oppstått en feil. Vennligst prøv igjen senere.', 'error');
    }
  }, [getTilleggsvariabel.error]);
  const pushVariabelHistory = (id?: number) => {
    if (id) {
      history.push({
        pathname: `/home/kostholdsdata/tilleggsvariabler/${id}`
      });
    } else {
      history.push({
        pathname: `/home/kostholdsdata/tilleggsvariabler/0`
      });
    }
    setVariabelId(id);
  };
  async function postData(data: any, url: string, methods: string) {
    let response = await fetch(encodeURI(url), {
      method: methods,
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      body: JSON.stringify(data)
    });
    return response;
  }
  const handleSave = () => {
    if (
      !tilleggsvariable?.kortnavn ||
      !tilleggsvariable?.sporsmal ||
      !tilleggsvariable?.svartype
    ) {
      showAlertMessage('Obligatoriske felter kan ikke være tomme.', 'error');
    } else {
      const tilleggsvariableDataForm: TilleggsvariableI = {
        variabelId: variabelId || 0,
        prosjektnr: tilleggsvariable.prosjektnr || 0,
        kortnavn: tilleggsvariable.kortnavn || '',
        sporsmal: tilleggsvariable.sporsmal || '',
        svartype: tilleggsvariable.svartype || '',
        beskrivelse: tilleggsvariable.beskrivelse || '',
        tilleggssvar: tilleggsvariable.tilleggssvar || []
      };
      if (variabelId > 0) {
        if (ownedVariable && hasDataChanged(tilleggsvariable, originalVariabelCopy)) {
          console.log('Data has changed!');
          updateTilleggsvariabel(tilleggsvariableDataForm);
        } else {
          showAlertMessage(
            `Det er ikke blitt gjort noen endringer i skjermbildet. Lagring er ikke nødvendig.`,
            'info'
          );
        }
      } else {
        createTilleggsvariabel(tilleggsvariableDataForm);
      }
    }
  };

  const showAlertMessage = (message: string, severity: any) => {
    setShowAlert(true);
    setAlertMessage(message);
    setAlertSeverity(severity);
  };

  const updateTilleggsvariabel = (data: TilleggsvariableI) => {
    let url = `${backendURI}/updateTilleggsvariabel/${variabelId}`;
    postData({ data: data }, url, 'PUT')
      .then((resp) => {
        if (resp.status === 200) {
          console.log('Oppdatering er fullført', 'success');
          showAlertMessage('Oppdatering av tilleggsvariablen er fullført', 'success');
          pushVariabelHistory(variabelId);
          //setTimeout(() => pushVariabelHistory(variabelId), 1000);
          setRefetchVariableList(true);
        } else {
          console.log('An error 500', resp.status);
          showAlertMessage(
            'Oppdateringen mislyktes. Det har ikke blitt gjort endringer mot databasen.',
            'error'
          );
        }
      })
      .catch((err) => {
        console.log('An error', err);
        showAlertMessage(
          'Oppdateringen mislyktes. Det har ikke blitt gjort endringer mot databasen.',
          'error'
        );
      });
  };

  const createTilleggsvariabel = (data: TilleggsvariableI) => {
    let url = `${backendURI}/createTilleggsvariabel`;
    postData({ data: data }, url, 'POST')
      .then((resp) => {
        if (resp.status === 200) {
          console.log('Lagringen er fullført', 'success');
          showAlertMessage('Lagringen av tilleggsvariablen er fullført', 'success');
          //pushVariabelHistory(variabelId)
          setTimeout(() => pushVariabelHistory(variabelId), 1000);
          setRefetchVariableList(true);
        } else {
          console.log('Lagringen er ikke fullført', 'error');
          showAlertMessage(
            'Lagringen mislyktes. Det har ikke blitt gjort endringer mot databasen.',
            'error'
          );
        }
      })
      .catch((err) => {
        console.log('We are in the catch', err);
        showAlertMessage(
          'Lagringen mislyktes. Det har ikke blitt gjort endringer mot databasen.',
          'error'
        );
      });
  };

  const deleteSvar = (svar: TilleggsvariableSvarI) => {
    let url = `${backendURI}/deleteTillegsvar/${variabelId}/${svar?.kategoriId}`;
    postData({}, url, 'DELETE')
      .then((resp) => {
        if (resp.status === 200) {
          console.log('Slettingen er fullført', 'success');
          showAlertMessage('Slettingen av svarkategori er fullført', 'success');
          setTimeout(() => pushVariabelHistory(), 1000);
          setRefetchVariableList(true);
        } else {
          console.log('Slettingen er ikke fullført', 'error');
          showAlertMessage(
            'Slettingen mislyktes. Det har ikke blitt gjort endringer mot databasen.',
            'error'
          );
        }
      })
      .catch((err) => {
        console.log('We are in the catch', err);
        showAlertMessage(
          'Slettingen mislyktes. Det har ikke blitt gjort endringer mot databasen.',
          'error'
        );
      });
  };
  const handleChangeKortnavnField = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTilleggsvariable({ ...tilleggsvariable, kortnavn: event.target.value });

    const allKortnavn = tilleggsvariablerList?.map((t) => t.kortnavn?.toLowerCase());
    if (
      allKortnavn?.some(
        (str: string) => str.toLowerCase() === event.target.value?.toLowerCase()
      )
    ) {
      setKError('Denne tilleggsvariabelen eksisterer fra før.');
    } else {
      setKError('');
      const id = tilleggsvariablerList?.find(
        (t) => t.kortnavn === event.target.value
      )?.variabelId;
      setVariabelId(id || 0);
    }
  };
  const clear = () => {
    setKError('');
    setVariabelId(0);
    setTilleggsvariable(initialTilleggsvariable);
    setOwned(false);
    setOwnedVariable(false);
    pushVariabelHistory();
  };
  React.useEffect(() => {
    if (tilleggsvariable.svartype === 'Kategorier') {
      setShowSvarkategorier(true);
    } else {
      setShowSvarkategorier(false);
    }
  }, [tilleggsvariable.svartype]);

  React.useEffect(() => {
    setOwned(IsOwnedByUser({ id: tilleggsvariable.prosjektnr, u: loggedInuser }));
  }, [tilleggsvariable.prosjektnr]);

  React.useEffect(() => {
    let user = loggedInuser?.user?.username;
    if (user === tilleggsvariable?.brukernavn || keycloak?.hasRealmRole(UserRole.Akf)) {
      setOwnedVariable(true);
    } else {
      setOwnedVariable(false);
    }
  }, [tilleggsvariable.kortnavn]);

  const handleChange = (k, v) => setTilleggsvariable({ ...tilleggsvariable, [k]: v });
  const handleChangeEvent = (e) => handleChange(e.target.name, e.target.value);
  const handleNewSvarChange = (k, v) => {
    const regex = /^[0-9]*$/;
    if (v === '' || regex.test(v)) {
      setNewSvarKatergori({ ...newSvarKatergori, [k]: +v });
      setError('');
    } else {
      setError('Kun tall.');
    }
  };
  const toggleEditMode = (item: TilleggsvariableSvarI) => {
    setTilleggsvariable((prevState) => {
      const updatedTilleggssvar = prevState.tilleggssvar.map((svar) => {
        if (svar.kode === item.kode) {
          return { ...svar, isEditMode: !svar.isEditMode };
        }
        return svar;
      });

      return { ...prevState, tilleggssvar: updatedTilleggssvar };
    });
  };

  const handleSvarChange = (item: TilleggsvariableSvarI, text: string) => {
    setTilleggsvariable((prevState) => {
      const updatedTilleggssvar = prevState.tilleggssvar.map((svar) => {
        if (svar.kode === item.kode) {
          return { ...svar, beskrivelse: text };
        }
        return svar;
      });

      return { ...prevState, tilleggssvar: updatedTilleggssvar };
    });
  };

  function addNewSvar(): void {
    if (!newSvarKatergori.kode || !newSvarKatergori.beskrivelse) {
      setError('Både kode og beskrivelse må fylles ut.');
    } else {
      setError('');
      const newTilleggssvar: TilleggsvariableSvarI = {
        kategoriId: 0,
        kode: newSvarKatergori.kode,
        beskrivelse: newSvarKatergori.beskrivelse,
        isEditMode: false
      };
      setTilleggsvariable((prevState) => ({
        ...prevState,
        tilleggssvar: [...prevState.tilleggssvar, newTilleggssvar]
      }));
      setNewSvarKatergori(initialSvar);
    }
  }

  return (
    <PageContainer>
      <Paper style={{ padding: '1.5em 15px 1.5em 15px', boxShadow: 'none' }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="h4">Definering av tilleggsvariabel</Typography>
          </Grid>
          <Grid item xs={6}>
            <Stack direction="row-reverse" spacing={2}>
              <TilleggsvariableIcons
                // newVariableId={newVariableId}
                tilleggsvariable={tilleggsvariable}
                tilleggsvariablerList={tilleggsvariablerList}
                //svarKatergoriList={svarKatergoriList}
                setProgressBar={setShowProgress}
                showAlert={showAlertMessage}
                setRefetchVariableList={setRefetchVariableList}
                ownedVariable={ownedVariable}
                owned={owned}
                clear={clear}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">* Obligatorisk felt</Typography>
          </Grid>
          <Grid item xs={12} container spacing={2} alignItems="center">
            <Grid item xs={6} container spacing={2} alignItems="flex-end">
              <Grid item xs={6}>
                <SlowText
                  header="Kortnavn *"
                  value={tilleggsvariable.kortnavn}
                  onChange={handleChangeKortnavnField}
                  inputProps={{
                    min: 1,
                    maxLength: 8
                  }}
                  required
                  error={!!kError}
                  helperText={kError}
                />
              </Grid>
              <Grid item xs={6}>
                <NButton
                  children="Søk etter tilleggsvariabel"
                  leftIcon={<SearchOutlinedIcon />}
                  onClick={() => setShowTilleggsvariableList(true)}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Typography>
                {tilleggsvariable.brukernavn && 'Opprettet av: '}
                {tilleggsvariable.brukernavn}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={7}>
            <SlowText
              header="Spørsmål *"
              value={tilleggsvariable.sporsmal}
              name="sporsmal"
              onChange={handleChangeEvent}
            />
          </Grid>
          <Grid item xs={5} container spacing={2}>
            <Grid item xs={6}>
              <Dropdown
                header="Svartype *"
                value={tilleggsvariable.svartype}
                options={['Tall', 'Kategorier']}
                onChange={(v) => handleChange('svartype', v)}
              />
            </Grid>
            <Grid item xs={6}>
              <SlowText
                header="Prosjektsnummer"
                value={tilleggsvariable.prosjektnr}
                name="prosjeknr"
                onChange={handleChangeEvent}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <SlowText
              header="Beskrivelse"
              minRows={3}
              maxRows={5}
              multiline
              value={tilleggsvariable.beskrivelse}
              name="beskrivelse"
              onChange={handleChangeEvent}
            />
          </Grid>
          {showSvarkategorier ? (
            <>
              <Grid item xs={12}>
                <Typography variant="h5">Definering av svarkategorier</Typography>
              </Grid>
              <Grid item xs={12}>
                <Table size="small" sx={{ border: '1px solid black' }}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Kode</TableCell>
                      <TableCell>Beskrivelse</TableCell>
                      <TableCell>Rediger</TableCell>
                      <TableCell>Slett</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <>
                      {' '}
                      {tilleggsvariable?.tilleggssvar?.map((r, i) => (
                        <TableRow hover>
                          <TableCell>{r?.kode}</TableCell>
                          <TableCell>
                            {ownedVariable && r.isEditMode ? (
                              <TextField
                                value={`${r.beskrivelse}` || ''}
                                variant="standard"
                                inputProps={{ maxLength: 40 }}
                                onChange={(e) => handleSvarChange(r, e.target.value)}
                              />
                            ) : (
                              `${r.kode} - ${r.beskrivelse}`
                            )}
                          </TableCell>

                          <TableCell>
                            {r.isEditMode ? (
                              <IconButton
                                children={<CheckOutlinedIcon />}
                                onClick={() => toggleEditMode(r)}
                              />
                            ) : (
                              <IconButton
                                children={<EditOutlinedIcon />}
                                onClick={() => toggleEditMode(r)}
                                disabled={!ownedVariable}
                              />
                            )}
                          </TableCell>
                          <TableCell>
                            <NButton
                              children=""
                              leftIcon={<DeleteOutlinedIcon />}
                              variant="outlined"
                              disabled={!ownedVariable}
                              onClick={() => deleteSvar(r)}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                      {ownedVariable ? (
                        <TableRow>
                          <TableCell>
                            <TextField
                              value={newSvarKatergori.kode || ''}
                              variant="standard"
                              name="kode"
                              onChange={(e) =>
                                handleNewSvarChange(e.target.name, e.target.value)
                              }
                              required
                              error={!!error}
                              helperText={error}
                            />
                          </TableCell>
                          <TableCell>
                            <TextField
                              value={newSvarKatergori.beskrivelse || ''}
                              variant="standard"
                              name="beskrivelse"
                              inputProps={{ maxLength: 40 }}
                              onChange={(e) =>
                                setNewSvarKatergori({
                                  ...newSvarKatergori,
                                  beskrivelse: e.target.value
                                })
                              }
                            />
                          </TableCell>
                          <TableCell>
                            <IconButton
                              aria-label="done"
                              onClick={(e) => {
                                addNewSvar();
                              }}
                              disabled={false}
                            >
                              <CheckOutlinedIcon />
                            </IconButton>
                            <IconButton
                              aria-label="clear"
                              onClick={() => {
                                setNewSvarKatergori(initialSvar);
                                setError('');
                              }}
                              disabled={false}
                            >
                              <ClearIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ) : null}
                    </>
                  </TableBody>
                </Table>
              </Grid>
            </>
          ) : null}
          <Grid item xs={6}>
            <Stack direction="row" spacing={1}>
              <NButton
                children={variabelId === 0 ? 'Opprett' : 'Lagre'}
                disabled={
                  !(
                    tilleggsvariable.kortnavn &&
                    !kError &&
                    (ownedVariable ||
                      variabelId === 0 ||
                      tilleggsvariable.brukernavn === loggedInuser.user.username)
                  )
                }
                onClick={handleSave}
              />
              <NButton children="Nullstill" variant="outlined" onClick={clear} />
              {/* {showSvarkategorier ? (
                <NButton
                  children="Slett valgte"
                  leftIcon={<DeleteOutlinedIcon />}
                  variant="outlined"
                  disabled={!(ownedVariable && selected.length)}
                  onClick={delete}
                />
              ) : null} */}
            </Stack>
          </Grid>
          <Grid item xs={6} />
        </Grid>
        {showCDialog && (
          <ConfirmationDialog
            title={dialogTitle}
            text={dialogText}
            setConfirmationResult={setConfirmationResult}
            hide={() => {
              setShowCDialog(!showCDialog);
            }}
          />
        )}
        {showAlert && (
          <AlertComp
            alertMessage={alertMessage}
            alertColor={alertSeverity}
            showAlert={showAlert}
            setShowAlert={setShowAlert}
          />
        )}
        {showProgress && (
          <ProgressBar
            isShowing={showProgress}
            // text={progressText}
            hide={() => {
              setShowProgress(!showProgress);
            }}
          />
        )}
        {showTilleggsvariableList ? (
          <TilleggsvariableList
            tilleggsvariablerList={tilleggsvariablerList}
            showAlertMessage={showAlertMessage}
            setVariabelId={setVariabelId}
            hide={() => setShowTilleggsvariableList(false)}
          />
        ) : null}
      </Paper>
    </PageContainer>
  );
}
