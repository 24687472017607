import React, { useEffect } from 'react';
import { BeregningObject, ReportEightI } from '../../types/BeregningTypes';
import useDidMountEffect from '../../../../utilities/useDidMountEffect';

const reportStatistics = [
  { DBNAME: 'GJSNITT', NAME: 'Gj.snitt' },
  { DBNAME: 'MAX', NAME: 'Max' },
  { DBNAME: 'MIN', NAME: 'Min' }
];
interface ReportEightProps {
  beregningObject: BeregningObject;
  setReportData: (value: any[]) => void;
  setReportInfo: (value: Array<Array<string>>) => void;
  setErrorReportId: (value: number) => void;
  result: any;
}

export const Report8Result = (props: ReportEightProps) => {
  const [data] = React.useState([]);
  const [info] = React.useState<Array<Array<string>>>([]);
  const { beregningObject, setReportData, setReportInfo, setErrorReportId, result } =
    props;
  useEffect(() => {
    if (result) {
      console.log(' Beregning result.', 'success');
      const data = result as unknown as ReportEightI;
      if (
        data?.reportInfo !== undefined &&
        data?.reportInfo?.beregningInfo !== undefined &&
        data?.mengde !== undefined &&
        data?.stoffs !== undefined
      ) {
        prepareResult(data);
      }
    }
  }, [result]);

  const prepareResult = (reportEightData: ReportEightI) => {
    let description: string[];
    setErrorReportId(reportEightData?.reportInfo?.reportId);
    for (let r of reportEightData?.reportInfo?.beregningInfo) {
      description = [];
      description.push(r.TEKSTLINJE);
      info.push(description);
    }
    setReportInfo(info);
    let result: any[] = [];
    const shortNames: any[] = [];
    const enhet: any[] = [];
    shortNames.push('');
    shortNames.push('');
    enhet.push('');
    enhet.push('');

    for (let stoff of reportEightData?.stoffs) {
      shortNames.push(stoff.KORTNAVN);
      enhet.push(stoff.ENHET);
    }
    data.push(['']);
    data.push(shortNames);
    data.push(enhet);
    for (let s of reportStatistics) {
      result = [];
      result.push(s['NAME']);
      result.push(s['']);
      for (let i = 0; i < reportEightData?.mengde?.length; i++) {
        if (reportEightData?.mengde?.length > 0) {
          result.push(reportEightData?.mengde[i][s.DBNAME]);
        }
      }
      data.push(result);
    }
    setReportData(data);
  };

  return <></>;
};
