import React from 'react';
import { Box, Typography } from '@mui/material';
import useStyles from '../../css/IntroCss';
import useStylesMain from '../../css/materialtheme';
import Footer from './Footer';
export const Fallback = () => {
  const commonClasses = useStylesMain();
  const classes = useStyles();
  return (
    <Box sx={commonClasses.flexContainerColumn}>
      <Box sx={[commonClasses.flexContainerColumnCenter, classes.mainBackgroundImage]}>
        <Box style={{ width: 800 }}>
          <Typography sx={classes.Introdata} align="left">
            Noe gikk galt! Vennligst rapporter feilen via nfc-drift@usit.uio.no
          </Typography>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};
