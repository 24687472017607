import { Buffer } from 'buffer';
import { UserDetailI } from '../pages/admin/AdminTypes';
const encodeQuery = (value: string) => {
  const buff = Buffer.from(value);
  console.log(buff.toString('base64'));
  return buff.toString('base64');
};
export const RegisterUser = async (keycloak: Keycloak.KeycloakInstance) => {
  let name, givenName, familyName, email, secondInitial, secondGivenName: string;

  //let  parsedToken: any
  if (!keycloak) {
    console.log('no keycloak');
    //return;
  }
  let parsedToken = keycloak.tokenParsed;
  if (!parsedToken) {
    console.log('no parsed token');
    ///  return;
  }
  name = parsedToken['name'];
  givenName = parsedToken['given_name'];
  familyName = parsedToken['family_name'];
  email = parsedToken['email'];

  if (!name || !givenName || !familyName || !email) {
    // return;
    console.log('no details');
    //return;
  }

  if (name?.length > 25) {
    if (givenName.indexOf(' ')) {
      secondGivenName = givenName.split(' ', 2)[1];
      secondInitial = secondGivenName.charAt(0);
      givenName = givenName.split(' ', 2)[0] + ' ' + secondInitial + ' ';
    }
    name = givenName + familyName;
    //TODO: Check if name length can be >25 without having middlename
  }

  const username = email.split('@')[0].toUpperCase();
  const user: UserDetailI = {
    id: 0,
    brukerkode: username,
    navn: encodeQuery(name),
    epost: email,
    telefon: '00000000'
  };
  const apiUrl = `${process.env.REACT_APP_BACKEND}/createNewUser`;
  try {
    const response = await fetch(encodeURI(apiUrl), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: keycloak ? 'Bearer ' + keycloak.token : undefined
      },
      body: JSON.stringify({ data: user })
    });

    if (response.status === 200) {
      console.log('User is created');
      return true;
    } else {
      console.log('An error occurred');
      return false;
    }
  } catch (error) {
    console.error('An error occurred:', error);
    return false;
  }
};
