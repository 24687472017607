import React, { useEffect } from 'react';
import { BeregningObject, ReportNineI } from '../../types/BeregningTypes';
import useDidMountEffect from '../../../../utilities/useDidMountEffect';
const reportHeaders = [
  { DBNAME: 'PERSONID', NAME: 'Nr' },
  { DBNAME: 'IDENTITET', NAME: 'Id' },
  { DBNAME: 'KJONN', NAME: 'kjønn' },
  { DBNAME: 'ALDER', NAME: 'Alder' },
  { DBNAME: 'VEKT', NAME: 'Vekt' },
  { DBNAME: 'HOYDE', NAME: 'Høyde' },
  { DBNAME: 'BMI', NAME: 'BMI' },
  { DBNAME: 'BMR', NAME: 'BMR' },
  { DBNAME: 'MENGDE', NAME: 'Energiinntak(kJ)' },
  { DBNAME: 'BMRF', NAME: 'BMR-faktor' },
  { DBNAME: 'NOTAT', NAME: 'Notat' }
];
interface ReportSevenProps {
  beregningObject: BeregningObject;
  setReportData: (value: any[]) => void;
  setReportInfo: (value: Array<Array<string>>) => void;
  setErrorReportId: (value: number) => void;
  result: any;
}
export const Report9Result = (props: ReportSevenProps) => {
  const [data] = React.useState([]);
  const [info] = React.useState<Array<Array<string>>>([]);
  const { beregningObject, setReportData, setReportInfo, setErrorReportId, result } =
    props;
  useEffect(() => {
    if (result) {
      console.log(' Beregning result.', 'success');
      const data = result as unknown as ReportNineI;
      if (
        data?.reportInfo !== undefined &&
        data?.reportInfo?.beregningInfo !== undefined &&
        data?.kortnavns !== undefined &&
        data?.persons !== undefined &&
        data?.svars !== undefined
      ) {
        prepareResult(data);
      }
    }
  }, [result]);

  const prepareResult = (reportNineData: ReportNineI) => {
    let description: string[];
    setErrorReportId(reportNineData?.reportInfo?.reportId);
    for (let r of reportNineData?.reportInfo?.beregningInfo) {
      description = [];
      description.push(r.TEKSTLINJE);
      info.push(description);
    }
    setReportInfo(info);
    let result: any[] = [];
    const headers: any[] = [];

    for (let h of reportHeaders) {
      headers.push(h.NAME);
    }
    if (reportNineData?.kortnavns?.length > 0) {
      for (let k of reportNineData?.kortnavns) {
        headers.push(k.KORTNAVN);
      }
    }
    data.push(['']);
    data.push(headers);
    let s = 0;
    for (let i = 0; i < reportNineData?.persons?.length; i++) {
      result = [];
      for (let h of reportHeaders) {
        if (reportNineData?.persons?.length > 0) {
          result.push(reportNineData?.persons[i][h.DBNAME] || ' ');
        }
      }
      if (reportNineData?.svars?.length > 0) {
        for (let k = 0; k < reportNineData?.kortnavns?.length; k++) {
          result.push(reportNineData?.svars[s]?.SVAR);
          s++;
        }
      }
      data.push(result);
    }
    setReportData(data);
  };
  return <></>;
};
