import {
  OptionI,
  filterOptionI
} from '../components/pages/beregning/types/BeregningTypes';
//kodeId here are the variables' codes registered in the database
export const personFilterData: filterOptionI[] = [
  {
    columnName: 'P.PERSONID',
    label: 'PERSONID',
    kodeId: 0,
    hasPredefinedValue: false,
    predefinedValues: []
  },
  {
    columnName: 'P.IDENTITET',
    label: 'IDENTITET',
    kodeId: 0,
    hasPredefinedValue: false,
    predefinedValues: []
  },
  {
    columnName: 'P.KJONN',
    label: 'KJØNN',
    kodeId: 1,
    hasPredefinedValue: true,
    predefinedValues: []
  },
  {
    columnName: 'P.BOSTEDTYPE',
    label: 'BOSTEDTYPE',
    kodeId: 2,
    hasPredefinedValue: true,
    predefinedValues: []
  },
  {
    columnName: 'P.LANDSDEL',
    label: 'LANDSDEL',
    kodeId: 15,
    hasPredefinedValue: true,
    predefinedValues: []
  },
  {
    columnName: 'P.ALDER',
    label: 'ALDER',
    kodeId: 0,
    hasPredefinedValue: false,
    predefinedValues: []
  },
  {
    columnName: 'P.HOYDE',
    label: 'HOYDE',
    kodeId: 0,
    hasPredefinedValue: false,
    predefinedValues: []
  },
  {
    columnName: 'P.VEKT',
    label: 'VEKT',
    kodeId: 0,
    hasPredefinedValue: false,
    predefinedValues: []
  },
  {
    columnName: 'P.BMI',
    label: 'BMI',
    kodeId: 0,
    hasPredefinedValue: false,
    predefinedValues: []
  },
  {
    columnName: 'P.BMR',
    label: 'BMR',
    kodeId: 0,
    hasPredefinedValue: false,
    predefinedValues: []
  },
  {
    columnName: 'P.STATUS',
    label: 'STATUS',
    kodeId: 10,
    hasPredefinedValue: true,
    predefinedValues: []
  },
  {
    columnName: 'P.SKJEMASTATUS',
    label: 'SKJEMASTATUS',
    kodeId: 16,
    hasPredefinedValue: true,
    predefinedValues: []
  },
  {
    columnName: 'P.TILBAKEMELDINGSTATUS',
    label: 'TILBAKEMELDINGSTATUS',
    kodeId: 17,
    hasPredefinedValue: true,
    predefinedValues: []
  },
  {
    columnName: 'T.VARIABELID',
    label: 'TILLEGGSVARIABEL',
    kodeId: 0,
    hasPredefinedValue: true,
    predefinedValues: [],
    svars: []
  },
  {
    columnName: 'T.SVAR',
    label: 'TILLEGGSVARIABEL:SVARS',
    kodeId: 0,
    hasPredefinedValue: true,
    predefinedValues: []
  }
];

export const komsumFilterData: filterOptionI[] = [
  {
    columnName: 'LD.LOPEDAGID',
    label: 'LOPEDAGID',
    kodeId: 0,
    hasPredefinedValue: false,
    predefinedValues: []
  },
  {
    columnName: 'LD.UKEDAG',
    label: 'UKEDAG',
    kodeId: 0,
    hasPredefinedValue: true,
    predefinedValues: []
  },
  {
    columnName: 'LD.VANLIGDAG',
    label: 'VANLIGDAG',
    kodeId: 0,
    hasPredefinedValue: true,
    predefinedValues: []
  },
  {
    columnName: 'LD.STATUS',
    label: 'STATUS',
    kodeId: 11,
    hasPredefinedValue: true,
    predefinedValues: []
  },
  {
    columnName: 'LD.INTERVIVJUSTED_KODE',
    label: 'INTERVIVJUSTED_KODE',
    kodeId: 14,
    hasPredefinedValue: true,
    predefinedValues: []
  },
  {
    columnName: 'LD.BILDEBOK',
    label: 'BILDEBOK',
    kodeId: 0,
    hasPredefinedValue: true,
    predefinedValues: []
  },
  {
    columnName: 'LD.INTERVJUER_ID',
    label: 'INTERVJUER_ID',
    kodeId: 0,
    hasPredefinedValue: true,
    predefinedValues: []
  },
  {
    columnName: 'MT.MAALTIDNRID',
    label: 'MAALTIDNRID',
    kodeId: 0,
    hasPredefinedValue: false,
    predefinedValues: []
  },
  {
    columnName: 'MT.KLOKKESLETT',
    label: 'KLOKKESLETT',
    kodeId: 0,
    hasPredefinedValue: false,
    predefinedValues: []
  },
  {
    columnName: 'MT.MAALTIDSTYPE',
    label: 'MAALTIDSTYPE',
    kodeId: 3,
    hasPredefinedValue: true,
    predefinedValues: []
  },
  {
    columnName: 'MT.SPISESTED',
    label: 'SPISESTED',
    kodeId: 0,
    hasPredefinedValue: false,
    predefinedValues: []
  },
  {
    columnName: 'MT.SPISESTEDKODE',
    label: 'SPISESTEDKODE',
    kodeId: 12,
    hasPredefinedValue: true,
    predefinedValues: []
  },
  {
    columnName: 'MT.SAMMEN_MED',
    label: 'SAMMEN_MED',
    kodeId: 19,
    hasPredefinedValue: true,
    predefinedValues: []
  }
];
export const operators: OptionI[] = [
  { value: '=', label: '=' },
  { value: '!=', label: '!=' },
  { value: '<', label: '<' },
  { value: '<=', label: '<=' },
  { value: '>', label: '>' },
  { value: '>=', label: '>=' },
  { value: 'BETWEEN', label: 'Mellom' }
  //{ value: 'LIKE', label: 'Linger' },
];
export const conjuctions: OptionI[] = [
  { value: 'AND', label: 'Ingen' },
  { value: 'AND', label: 'Og' },
  { value: 'OR', label: 'Eller' }
];
export const weekdays: string[] = [
  'Mandag',
  'Tirsdag',
  'Onsdag',
  'Torsdag',
  'Fredag',
  'Lørdag',
  'Søndag'
];
export const bildebok: OptionI[] = [
  {
    value: 1,
    label: 'Ja (papir)'
  },
  {
    value: 2,
    label: 'Ja (nett)'
  },
  {
    value: 3,
    label: 'Nei'
  }
];
export const vanligdag: OptionI[] = [
  {
    value: 1,
    label: 'Ja'
  },
  {
    value: 2,
    label: 'Nei'
  }
];
