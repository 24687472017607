import React, { ReactElement, ReactNode } from 'react';
import {
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  Stack,
  Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface DialogGeneralProps {
  title?: string;
  titleIcon?: ReactElement;
  children?: ReactNode;
  open: boolean;
  hide: () => void;
  fullWidth?: boolean;
  maxWidth?: DialogProps['maxWidth'];
}

export default function FormDialog(props: DialogGeneralProps) {
  const { title, titleIcon, children, open, hide, fullWidth, maxWidth } = props;

  return (
    <>
      <Dialog
        open={open}
        onClose={hide}
        aria-labelledby="dialog"
        fullWidth={fullWidth}
        maxWidth={maxWidth}
      >
        <DialogTitle
          sx={{
            background: '#4977AF',
            mb: 3
          }}
        >
          <Stack
            // justifyContent="space-between"
            alignItems="center"
            direction="row"
            spacing={2}
            useFlexGap
          >
            {titleIcon}
            <Typography variant="h6" color={'white'}>
              {title}
            </Typography>
            <IconButton sx={{ ml: 'auto' }} onClick={hide} aria-label="close">
              <CloseIcon sx={{ color: 'white' }} />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>{children}</DialogContent>
      </Dialog>
    </>
  );
}
