import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { ProsjektI, UndersokelseI } from './types/ProjectTypes';
import { useKeycloak } from '@react-keycloak/web';
import PageContainer from '../home/PageContainer';
import {
  Grid,
  Box,
  Checkbox,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination
} from '@mui/material';
import { MonoSearchField, NButton, SlowText } from '../components/Inputs';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import ReactToPrint from 'react-to-print';
import moment from 'moment';
import { ProgressBar } from '../../utilities/ProgressBar';
import { SaveBeforeRedirect } from '../../utilities/SaveBeforeRedirect';
import ProjectSearch from './ProjectSearch';
import DeleteDialog from './components/DeleteDialog';
import IsOwnedByUser from '../../utilities/Validate';
import { useUserContext } from '../../../context/AuthContext';
import AlertComp from '../../utilities/AlertComp';
import { AlertI } from '../../../types/alertTypes';
import { databaseOptions } from '../../../assets/DatabaseOptions';
import { useProjectContext } from '../../../context/ProjectContext';
import { FetchProjectandUndersokelse } from './components/FetchProjectandUndersokelse';
import { hasDataChanged } from '../../utilities/ArrayFunctions';

const ComponentToPrint = React.forwardRef((p: any, ref: any) => (
  <div ref={ref} style={{ padding: '1em' }}>
    <table>
      <tr>
        <td>Utskrift av skjermbildet: </td>
        <td>Prosjekt ({p.user})</td>
      </tr>
      <tr>
        <td>Utskriftsdato: </td>
        <td>{moment().format('YYYY-MM-DD hh:mm:ss')}</td>
      </tr>
    </table>

    <h5>Prosjekt informasjon</h5>
    <table className="styledTable">
      <tr>
        <td>Prosjektnr</td>
        <td>{p.projectData?.prosjektId}</td>
      </tr>
      <tr>
        <td>Kortnavn</td>
        <td>{p.projectData?.kortNavn}</td>
      </tr>
      <tr>
        <td>Navn</td>
        <td>{p.projectData?.prosjektnavn}</td>
      </tr>
      <tr>
        <td>Tidsrom</td>
        <td>{p.projectData?.tidsrom}</td>
      </tr>
      <tr>
        <td>Leder</td>
        <td>{p.projectData?.ansvarlig}</td>
      </tr>
      <tr>
        <td>Metode</td>
        <td>{p.projectData?.metode}</td>
      </tr>
      <tr>
        <td>Beskrivelse</td>
        <td>{p.projectData?.beskrivelse}</td>
      </tr>
    </table>

    <h5>Undersøkelser</h5>
    <table className="styledTable">
      <tr>
        {p?.undersokelser?.headers.map((h) => (
          <td>{h}</td>
        ))}
      </tr>
      {p?.undersokelser?.fields?.map((row) => (
        <tr>
          {row.map((f) => (
            <td>{f}</td>
          ))}
        </tr>
      ))}
    </table>
  </div>
));
interface UndersokelseList {
  headers: string[];
  fields: any[];
}
export const Prosjekt = (props: any) => {
  const backendURI = process.env.REACT_APP_BACKEND;
  const { keycloak } = useKeycloak();
  const token = keycloak?.token;
  const loggedInuser = useUserContext();
  const projs = useProjectContext();
  const [title, setTitle] = useState('Prosjekt - Nutrifoodcalc');
  const [text, setText] = useState('Henter prosjekter...');
  const [showAlert, setShowAlert] = useState(false);
  const [alertSeverity, setAlertSeverity] = React.useState<AlertI['alertColor']>('info');
  const [alertMessage, setAlertMessage] = React.useState('');
  const [owned, setOwned] = useState(false);
  const [showProgress, setShowProgress] = useState(false);
  const [showProjectSearch, setShowProjectSearch] = useState<boolean>(false);
  const [isNewProject, setIsNewProject] = useState<boolean>(false);
  const [curentProjectId, setCurrentProjectId] = useState<number>(0);
  const [refetchProject, setRefetchProject] = useState<boolean>(false);
  const [refetchSurvey, setRefetchSurvey] = useState<boolean>(false);
  const history = useHistory();
  const projectIdFromURL: number = props.match.params.projectId || '';
  document.title = title;
  const [undersokelser, setUndersokelser] = React.useState<UndersokelseList>();
  const [allUndersokelser, setAllUndersokelser] = React.useState<UndersokelseI[]>();
  const [reloadUndersokelser, setReloadUndersokelser] = React.useState<number>();
  const [selectedUndersokelser, setSelectedUndersokelser] = React.useState<number[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const undersokelseRedirect = (undersokelse?: string) =>
    history.push(
      `/home/kostholdsdata/undersokelse/${projectData?.prosjektId}/${undersokelse || ''}`
    );

  const [undersokelseNoFilter, setUndersokelseNoFilter] = React.useState<string>('');
  const [undersokelseShortnameFilter, setUndersokelseShortnameFilter] =
    React.useState<string>('');
  const initialProjectData: ProsjektI = {
    prosjektId: '',
    kortNavn: '',
    prosjektnavn: '',
    tidsrom: '',
    ansvarlig: '',
    metode: '',
    beskrivelse: '',
    antallUndersokelser: 0,
    undersokelser: []
  };
  const [projectData, setProjectData] = useState<ProsjektI>(initialProjectData);
  const [originalProsjektCopy, setOriginalProsjektCopy] =
    useState<ProsjektI>(initialProjectData);

  const [projectIdError, setProjectIdError] = useState({
    error: false,
    message: ''
  });
  React.useEffect(() => {
    const pId: number = +projectData?.prosjektId || +projectIdFromURL;
    setCurrentProjectId(pId);
    setOriginalProsjektCopy({ ...projectData });
    setUndersokelser({ headers: [], fields: [] });
  }, [projectData?.prosjektId, reloadUndersokelser, refetchProject]);
  React.useEffect(() => {
    if (+projectIdFromURL !== undefined && +projectIdFromURL > 0) {
      getSelectedProject(+projectIdFromURL);
    } else {
      getSelectedProject();
    }
    //setUndersokelser({ headers: [], fields: [] })
  }, [projectIdFromURL]);

  const setProjectId = (v?: number) => {
    const index = projs.projects?.findIndex((p) => p.prosjektId === v);
    if (index > -1) {
      setIsNewProject(false);
      pushProjectHistory(projs.projects[index]?.prosjektId as number);
      IsOwnedByUser({ id: v, u: loggedInuser }) ? setOwned(true) : setOwned(false);
    } else {
      if (IsOwnedByUser({ id: v, u: loggedInuser })) {
        setIsNewProject(true);
        setOwned(true);
      } else {
        setOwned(false);
        setProjectData(initialProjectData);
        //showAlertMessage((v === 0 || v === undefined) ? 'Prosjektsnummer kan ikke være tom!' : 'Prosjektet eksisterer ikke!', 'error');
      }
    }
  };
  const getSelectedProject = (id?: number) => {
    if (id) {
      IsOwnedByUser({ id: id, u: loggedInuser }) ? setOwned(true) : setOwned(false);
      const index = projs.projects?.findIndex((p) => p.prosjektId === id);
      if (index > -1) {
        setTitle(`Prosjekt ${projectIdFromURL} - Nutrifoodcalc`);
        setProjectData(projs.projects[index]);
      } else {
        setProjectData(initialProjectData);
        setUndersokelser({ headers: [], fields: [] });
      }
    } else {
      setProjectData(initialProjectData);
      setUndersokelser({ headers: [], fields: [] });
    }
  };

  React.useEffect(() => {
    if (/[a-zA-Z -]/.test(projectData?.prosjektId?.toString())) {
      setProjectIdError({
        error: true,
        message: 'Ugyldige tegn. Bruk bare tall'
      });
    } else {
      setProjectIdError({
        error: false,
        message: ''
      });
    }
  }, [projectIdError?.error]);

  const pushProjectHistory = (id?: number) => {
    if (id) {
      history.push({
        pathname: `/home/kostholdsdata/prosjekt/${id}`
      });
    } else {
      history.push({
        pathname: `/home/kostholdsdata/prosjekt/0`
      });
      setOwned(false);
    }
  };

  const handleChangeFields = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setProjectData({
      ...projectData,
      [name]: typeof value === 'string' ? value?.trim() : value
    });
    if (name === 'prosjektId') {
      setProjectId(+value);
    }
  };

  const showAlertMessage = (message: string, severity: any) => {
    setShowAlert(true);
    setAlertMessage(message);
    setAlertSeverity(severity);
  };

  async function postData(data: any, url: string, methods: string) {
    let response = await fetch(encodeURI(url), {
      method: methods,
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      body: JSON.stringify(data)
    });
    return response;
  }

  const updateProject = (data: ProsjektI) => {
    let url = `${backendURI}/updateProject`;
    postData(data, url, 'PUT')
      .then((resp) => {
        if (resp.status === 200) {
          console.log('Oppdatering er fullført', 'success');
          showAlertMessage('Oppdatering er fullført', 'success');
          //fetch from db to include the newly updated data
          //allProjects.loadData('hentProsjekterOgUndersokelser', '');
          setRefetchProject(true);
        } else if (resp.status === 500) {
          console.log('An error 500', resp.status);
          showAlertMessage(
            'Oppdateringen mislyktes. Det har ikke blitt gjort endringer mot databasen.',
            'error'
          );
        }
      })
      .catch((err) => {
        console.log('An error', err);
        showAlertMessage(
          'Oppdateringen mislyktes. Det har ikke blitt gjort endringer mot databasen.',
          'error'
        );
      });
  };

  const createProject = (data: ProsjektI) => {
    let url = `${backendURI}/createProject`;
    postData(data, url, 'POST')
      //TODO: refactor this to add existing id, and display specific errr message
      .then((resp) => {
        if (resp.status === 200) {
          console.log('Lagringen er fullført', 'success');
          showAlertMessage('Lagringen er fullført', 'success');
          //fetch from db to include the newly saved data
          setRefetchProject(true);
          setIsNewProject(false);
          navigateAway();
        } else if (resp.status === 500) {
          console.log('Lagringen er ikke fullført', 'error');
          showAlertMessage(
            'Lagringen mislyktes. Det har ikke blitt gjort endringer mot databasen.',
            'error'
          );
        }
      })
      .catch((err) => {
        console.log('Error in the catch', err);
        showAlertMessage(
          'Lagringen mislyktes. Det har ikke blitt gjort endringer mot databasen.',
          'error'
        );
        navigateAway();
      });
  };

  const navigateAway = () => {
    // set a timer for the history.push because it will trigger a page refresh
    // and set showAlert to false, removing the Alert
    const timer = setTimeout(() => {
      history.push(`/home/kostholdsdata/prosjekt/${projectData?.prosjektId}`);
    }, 1000);
    return () => clearTimeout(timer);
  };
  const submitData = () => {
    if (
      !projectData?.prosjektId ||
      !projectData?.prosjektnavn ||
      !projectData?.kortNavn ||
      !projectData?.tidsrom ||
      !projectData?.ansvarlig ||
      !projectData?.metode ||
      !projectData?.beskrivelse
    ) {
      showAlertMessage('Obligatoriske felter kan ikke være tomme.', 'error');
    } else {
      if (hasDataChanged(projectData, originalProsjektCopy)) {
        console.log('Data has changed!');
        const projectDataForm: ProsjektI = {
          prosjektId: projectData?.prosjektId || +'',
          kortNavn: projectData?.kortNavn || '',
          prosjektnavn: projectData?.prosjektnavn || '',
          tidsrom: projectData?.tidsrom || '',
          ansvarlig: projectData?.ansvarlig || '',
          metode: projectData?.metode || '',
          beskrivelse: projectData?.beskrivelse || ''
        };
        const index = projs.projects?.findIndex(
          (p) => p.prosjektId === projectData?.prosjektId
        );
        index > -1 && owned
          ? updateProject(projectDataForm)
          : createProject(projectDataForm);
      } else {
        showAlertMessage(
          `Det er ikke blitt gjort noen endringer i skjermbildet. Lagring er ikke nødvendig.`,
          'info'
        );
      }
    }
  };

  const tableColumnNames = {
    undersokelseId: 'Undersøkelsenr.',
    kortNavn: 'Kortnavn',
    ansvarlig: 'Leder',
    opprettet: 'Opprettet',
    endret: 'Endret',
    matvareDatabaseId: 'Matvaredatabase',
    antallPersoner: 'Antall pers.'
  };
  const populateUndersokelse = () => {
    const pId = projectData?.prosjektId || +projectIdFromURL;
    const projectUndersokelser: UndersokelseI[] = projs.projects?.find(
      (p) => p.prosjektId === pId
    )?.undersokelser;
    const unds = allUndersokelser || projectUndersokelser;
    if (unds) {
      const handledResponse = {
        headers: Object.values(tableColumnNames),
        fields: unds
          ?.filter((r) =>
            undersokelseNoFilter
              ? r.undersokelseId?.toString()?.includes(undersokelseNoFilter)
              : true
          )
          ?.filter((r) =>
            undersokelseShortnameFilter
              ? r.kortNavn
                  ?.toLowerCase()
                  ?.includes(undersokelseShortnameFilter?.toLowerCase())
              : true
          )
          .map((r) =>
            Object.keys(tableColumnNames)?.map((h) => {
              if (h === 'antallPersoner') {
                return Object.values(r[h]);
              } else if (h === 'matvareDatabaseId') {
                return databaseOptions?.find((d) => d.value === r[h])?.label;
              } else {
                return r[h];
              }
            })
          )
      };
      setUndersokelser(handledResponse);
      setSelectedUndersokelser([]);
    }
    if (!projectData.prosjektId) {
      setUndersokelser({ headers: [], fields: [] });
    }
  };
  React.useEffect(() => {
    populateUndersokelse();
  }, [
    projectData.prosjektId,
    allUndersokelser,
    undersokelseNoFilter,
    undersokelseShortnameFilter
  ]);

  const allUndersokelserSelected =
    selectedUndersokelser.length === (undersokelser?.fields?.length || -1);

  const toggleSelectUndersokelse = (i: number) => {
    if (!owned) return;

    if (selectedUndersokelser.includes(i)) {
      setSelectedUndersokelser(selectedUndersokelser.filter((f) => f !== i));
    } else {
      setSelectedUndersokelser([...selectedUndersokelser, i]);
    }
  };

  const deleteUndersokelser = () => {
    selectedUndersokelser.map((undersokelseIndex) => {
      const selectedUndersokelseId = undersokelser?.fields[undersokelseIndex][0];

      fetch(
        encodeURI(
          `${backendURI}/deleteUndersokelse/${projectData?.prosjektId}/${selectedUndersokelseId}`
        ),
        {
          method: 'DELETE',
          headers: {
            Authorization: 'Bearer ' + token
          }
        }
      ).then((r) => {
        if (r.ok) {
          showAlertMessage(`Undersøkelse ${selectedUndersokelseId} slettet`, 'success');
          //update the table
          //const data = undersokelser.filter((f)=> f.id !== selectedUndersokelseId )
          //fetch from db to remove the newly deleted data
          // surveys.loadData('hentPUndersokelser', `${projectData?.prosjektId}`);
          setRefetchSurvey(true);
        } else {
          showAlertMessage(
            `Klarte ikke å slette undersøkelse ${selectedUndersokelseId}`,
            'error'
          );
        }
      });
    });
    setReloadUndersokelser(Math.random());
  };

  const printRef = React.useRef();
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  return (
    <PageContainer>
      <Paper style={{ padding: '1.5em 15px 1.5em 15px', boxShadow: 'none' }}>
        {/*         <SaveBeforeRedirect canSave={isModified} saveFn={() => submitData()} />*/}
        <FetchProjectandUndersokelse
          projectId={curentProjectId}
          setShowProgress={setShowProgress}
          setText={setText}
          setAllUndersokelser={setAllUndersokelser}
          refetchProject={refetchProject}
          setRefetchProject={setRefetchProject}
          refetchSurvey={refetchSurvey}
          setRefetchSurvey={setRefetchSurvey}
        />
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="h4">Prosjekt</Typography>
          </Grid>
          <Grid item xs={6}>
            <Stack direction="row-reverse" spacing={2}>
              <ReactToPrint
                trigger={() => (
                  <NButton
                    children="Skriv ut"
                    rightIcon={<PrintOutlinedIcon />}
                    variant="text"
                    disabled={isNewProject || !projectData?.prosjektId}
                  />
                )}
                pageStyle=".styledTable{width:100%;border-collapse:collapse;text-align:left}.styledTable th,.styledTable td{border:2px solid black;padding:0.5em;}"
                content={() => printRef.current}
              />
              <DeleteDialog
                title="Slett prosjekt?"
                message=""
                disabled={!(owned && +projectIdFromURL > 0)}
                deleteFn={() => {
                  owned
                    ? fetch(
                        encodeURI(
                          `${backendURI}/deleteProject/${projectData?.prosjektId}`
                        ),
                        {
                          method: 'DELETE',
                          headers: { Authorization: 'Bearer ' + token }
                        }
                      ).then((resp) => {
                        if (resp.ok) {
                          showAlertMessage('Prosjekt slettet', 'success');
                          setTimeout(() => pushProjectHistory(), 1000);
                          //fetch from db to remove the newly deleted data
                          //allProjects.loadData('hentProsjekterOgUndersokelser', '');
                          setRefetchProject(true);
                        } else {
                          showAlertMessage('Klarte ikke å slette prosjekt', 'error');
                        }
                      })
                    : showAlertMessage('Prosjektet er utenfor nummerområde', 'error');
                }}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">* Obligatorisk felt</Typography>
          </Grid>
          <Grid item xs={11} container spacing={2} alignItems="flex-end">
            <Grid item xs={3}>
              <SlowText
                header="Prosjektsnummer *"
                value={projectData?.prosjektId || ''}
                onChange={handleChangeFields}
                //onKeyDown={setProjectId(+projectData?.prosjektId)}
                error={projectIdError.error}
                helperText={projectIdError.message}
                name="prosjektId"
                required={true}
              />
            </Grid>
            <Grid item xs={4}>
              <SlowText
                header="Kortnavn *"
                value={projectData?.kortNavn || ''}
                onChange={handleChangeFields}
                name="kortNavn"
                inputProps={{
                  maxLength: 14
                }}
                InputProps={{
                  readOnly: !owned
                }}
                required={true}
              />
            </Grid>
            <Grid item xs={3}>
              <NButton
                children="Søk etter prosjekt"
                leftIcon={<SearchOutlinedIcon />}
                onClick={() => setShowProjectSearch(true)}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid item xs={11} container spacing={2}>
            <Grid item xs={5}>
              <SlowText
                header="Prosjektnavn *"
                value={projectData?.prosjektnavn || ''}
                onChange={handleChangeFields}
                name="prosjektnavn"
                required={true}
                InputProps={{
                  readOnly: !owned
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <SlowText
                header="Tidsrom *"
                value={projectData?.tidsrom || ''}
                onChange={handleChangeFields}
                name="tidsrom"
                required={true}
                InputProps={{
                  readOnly: !owned
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <SlowText
                header="Ansvarlig *"
                value={projectData?.ansvarlig || ''}
                onChange={handleChangeFields}
                name="ansvarlig"
                required
                InputProps={{
                  readOnly: !owned
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <SlowText
                header="Metode *"
                value={projectData?.metode || ''}
                onChange={handleChangeFields}
                name="metode"
                required
                InputProps={{
                  readOnly: !owned
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={11}>
            <SlowText
              header="Beskrivelse *"
              value={projectData?.beskrivelse || ''}
              onChange={handleChangeFields}
              name="beskrivelse"
              multiline
              minRows={5}
              required
              InputProps={{
                readOnly: !owned
              }}
            />
          </Grid>
          <Grid item xs={12} container>
            <Grid item xs={1}>
              <NButton
                children={isNewProject ? 'Opprett' : 'Lagre'}
                onClick={submitData}
                //disabled={!isModified || !owned}
                disabled={!owned || +projectData.prosjektId === 0}
              />
            </Grid>
            <Grid item xs={1}>
              <NButton
                children="Nullstill"
                variant="outlined"
                onClick={() => pushProjectHistory()}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} container alignItems="flex-end" spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">Undersøkelser i prosjektet</Typography>
            </Grid>
            <Grid item xs={4}>
              <MonoSearchField
                header="Søk etter undersøkelsesnummer"
                searchFn={setUndersokelseNoFilter}
                fullWidth
                //disabled={undersokelser?.fields?.length === 0}
              />
            </Grid>
            <Grid item xs={4}>
              <MonoSearchField
                header="Søk etter kortnavn"
                searchFn={setUndersokelseShortnameFilter}
                fullWidth
                //disabled={undersokelser?.fields?.length === 0}
              />
            </Grid>
            <Grid item xs={4}>
              <NButton
                children="Opprett ny undersøkelse"
                leftIcon={<Typography variant="h5" children="+" />}
                onClick={() => undersokelseRedirect()}
                disabled={!owned || isNewProject || +projectIdFromURL === 0}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid item xs={4} />
          <Grid item xs={12}>
            <Table
              size="small"
              sx={{
                border: '1px solid black'
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Checkbox
                      checked={allUndersokelserSelected}
                      indeterminate={
                        selectedUndersokelser.length > 0 && !allUndersokelserSelected
                      }
                      onChange={() =>
                        !owned
                          ? setSelectedUndersokelser([])
                          : allUndersokelserSelected
                          ? setSelectedUndersokelser([])
                          : setSelectedUndersokelser(
                              undersokelser?.fields?.map((f, i) => i) || []
                            )
                      }
                    />
                  </TableCell>
                  {undersokelser?.headers.map((h) => (
                    <TableCell sx={{ fontWeight: 'bold' }} children={h} />
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {undersokelser?.fields
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((row, i) => (
                    <TableRow hover selected={selectedUndersokelser.includes(i)}>
                      <TableCell>
                        <Checkbox
                          checked={selectedUndersokelser.includes(i)}
                          onChange={() => toggleSelectUndersokelse(i)}
                        />
                      </TableCell>
                      {row.map((f, i) => (
                        <TableCell>
                          {i < 2 ? (
                            <a
                              style={{
                                color: 'blue',
                                textDecoration: 'underline',
                                cursor: 'pointer'
                              }}
                              onClick={() => undersokelseRedirect(row[0])}
                              children={f}
                            />
                          ) : (
                            f
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              //sx={classesTable.Pagination}
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={undersokelser?.fields?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              showFirstButton={true}
              showLastButton={true}
            />
          </Grid>
          <Grid item xs={12}>
            <Stack direction={'row'} spacing={2}>
              <NButton
                children="Slett valgte"
                leftIcon={<DeleteOutlineOutlinedIcon />}
                variant="outlined"
                onClick={() => deleteUndersokelser()}
                disabled={!owned || selectedUndersokelser.length < 1}
              />
            </Stack>
          </Grid>
        </Grid>
      </Paper>

      {/* Dialogs */}

      <Box sx={{ display: 'none' }}>
        <ComponentToPrint
          user={loggedInuser?.user?.username}
          projectData={projectData}
          undersokelser={undersokelser}
          ref={printRef}
        />
      </Box>
      {showProjectSearch ? (
        <ProjectSearch
          setProject={(id) => setProjectId(+id)}
          showing={showProjectSearch}
          hide={() => setShowProjectSearch(false)}
        />
      ) : null}
      {showAlert && (
        <AlertComp
          alertMessage={alertMessage}
          alertColor={alertSeverity}
          showAlert={showAlert}
          setShowAlert={setShowAlert}
        />
      )}
      {showProgress && (
        <ProgressBar
          text={text}
          isShowing={showProgress}
          hide={() => {
            setShowProgress(!showProgress);
          }}
        />
      )}
    </PageContainer>
  );
};
