import React from 'react';
import { Grid, Stack, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useProjectContext } from '../../../../context/ProjectContext';

interface ProjectNoLinkProps {
  projectId: string;
  undersokelseId: string;
  kortnavn: string;
}

function ProjectNoLink(p: ProjectNoLinkProps) {
  const history = useHistory();
  const projectURL = `/home/kostholdsdata/prosjekt/${p.projectId}`;
  const undersokelseURL = `/home/kostholdsdata/undersokelse/${p.projectId}/${p.undersokelseId}`;
  const proj = useProjectContext();
  const pNavn = proj.projects.find((pr) => +pr.prosjektId === +p.projectId)?.kortNavn;
  return (
    <Stack direction={'row'} spacing={4} marginLeft={2}>
      <Typography>
        Prosjekt:{' '}
        <u style={{ cursor: 'pointer' }} onClick={() => history.push(projectURL)}>
          <b>
            {p.projectId} - {pNavn}
          </b>
        </u>
      </Typography>
      <Typography>
        Undersøkelse:{' '}
        <u style={{ cursor: 'pointer' }} onClick={() => history.push(undersokelseURL)}>
          <b>
            {p.undersokelseId} - {p.kortnavn}
          </b>
        </u>
      </Typography>
    </Stack>
  );
}

export default ProjectNoLink;
