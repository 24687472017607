import {
  MenuProps,
  MenuItem,
  Menu,
  styled,
  MenuItemProps,
  DialogTitle,
  DialogTitleProps,
  alpha
} from '@mui/material';
import React from 'react';
/* export const StyledMenu = styled(Menu)<MenuProps>(({ theme }) => ({
  paper: {
    border: '1px solid #d3d4d5',
    backgroundColor: '#4977AF'
  },
    elevation:0,
    getContentAnchorEl: null,
    anchorOrigin:{
      vertical: 'bottom',
      horizontal: 'left'
    },
    transformOrigin: 'left'
  })); */
export const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left'
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    minWidth: 180,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0'
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5)
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        )
      }
    }
  }
}));
export const StyledMenuItem = styled(MenuItem)<MenuItemProps>(({ theme }) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.secondary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white
      }
    }
  }
}));
export const StyledDialog = styled(DialogTitle)<DialogTitleProps>(({ theme }) => ({
  root: {
    padding: theme.spacing(1),
    background: '#4977AF',
    color: 'white',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  closeButton: {
    color: 'white'
  }
}));
