import React from 'react';
import XLSX from 'xlsx';
import { AltOmMatvareI } from '../../RapportTypes';
import { NButton } from '../../../components/Inputs';

import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Grid } from '@mui/material';
import { useUserContext } from '../../../../../context/AuthContext';

const ExcelRapport = ({
  data,
  matvaredb
}: {
  data: AltOmMatvareI[];
  matvaredb: string;
}) => {
  const exportToExcel = (user: string, info: AltOmMatvareI[], db: string) => {
    const workbook = XLSX.utils.book_new();

    const dbsheet = XLSX.utils.aoa_to_sheet([['Matvaredatabase', db]]);
    XLSX.utils.book_append_sheet(workbook, dbsheet, 'Matvaredatabase');

    info.map((matvare) => {
      const sheet = XLSX.utils.aoa_to_sheet([
        ['Kode', matvare.kode],
        ['Navn', matvare.navn],
        ['Beskrivelse', matvare.beskrivelse],
        ['Referanse', matvare.referanse],
        ['Opprettet', matvare.opprettet],
        ['Endret', matvare.endret],
        [],
        ['Maksimal mengde', matvare.maksimalmengde],
        ['MVT-nr', matvare.senr],
        ['Spiselig del', matvare.spiselig && `${matvare.spiselig}%`],
        ['Vektendring', matvare.vektendring && `${matvare.vektendring}%`],
        [],
        ['Mengdeenheter'],
        ...matvare.mengdeenheter.map((enhet) => [enhet.mengdeenhetid, enhet.mengde]),
        [],
        ['Matvaregrupper'],
        ...matvare.matvaregrupper.map((mgruppe) => [
          mgruppe.gruppetypeid,
          mgruppe.beskrivelse,
          mgruppe.nodenrid,
          mgruppe.navn
        ]),
        [],
        ['Ingredienser'],
        ...matvare.ingredienser.map((ingrediens) => [
          ingrediens.ingrediensid,
          ingrediens.navn,
          `${ingrediens.mengde} ${ingrediens.mengdeenhet || 'g'}`
        ]),
        [],
        ['Stoffkomponenter pr 100 g'],
        ...matvare.stoffkomponenter.map((stoff) => [
          stoff.kortnavn,
          stoff.mengde,
          stoff.enhet,
          stoff.kilde
        ])
      ]);
      XLSX.utils.book_append_sheet(workbook, sheet, matvare.kode.toString());
    });

    XLSX.writeFile(workbook, `${user}-altmatvare.xlsx`);
  };
  const loggedInuser = useUserContext();
  const username = loggedInuser?.user?.username.toLowerCase();

  return (
    <Grid item xs={6}>
      <NButton
        leftIcon={<FileDownloadOutlinedIcon />}
        children="Last ned xlsx"
        onClick={() => exportToExcel(username, data, matvaredb)}
        fullWidth
      />
    </Grid>
  );
};

export default ExcelRapport;
