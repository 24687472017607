import { Box, Stack, Tooltip, Typography } from '@mui/material';
import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { OppringingI, PersonI } from './types/PersonTypes';
import AddIcon from '@mui/icons-material/Add';
import { FormInput, NButton } from '../components/Inputs';
import PersonLopeDagerTable from './PersonLopedagerTable';
import { RouteComponentProps } from 'react-router-dom';
import { useUserContext } from '../../../context/AuthContext';
import IsOwnedByUser from '../../utilities/Validate';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';

interface RouteParams {
  prosjektId: string;
}

interface PersonLopedagerProps extends RouteComponentProps<RouteParams> {
  personData: PersonI;
  handleChange: (
    field: Extract<keyof PersonI, string | number>,
    value: string | number
  ) => void;
  handleNewLopedag: () => void;
  setShowOppringing: Dispatch<SetStateAction<boolean>>;
  setCurrentModalLopedag: Dispatch<SetStateAction<number>>;
  setCurrentOppringingId: Dispatch<SetStateAction<number>>;
  setActiveTab: Dispatch<SetStateAction<number>>;
  setRingOpp: Dispatch<SetStateAction<boolean>>;
  oppringingIds: Set<number>;
  handleSaveOppringing: (oppringing: OppringingI, lopedagId: number) => void;
}

export default function PersonLopedager(props: PersonLopedagerProps) {
  const {
    personData,
    handleChange,
    handleNewLopedag,
    setShowOppringing,
    setCurrentModalLopedag,
    setCurrentOppringingId,
    setActiveTab,
    setRingOpp,
    oppringingIds,
    handleSaveOppringing
  } = props;
  const { prosjektId } = props.match.params;
  const loggedInuser = useUserContext();
  const owned = useMemo(
    () => IsOwnedByUser({ id: +prosjektId, u: loggedInuser }),
    [prosjektId, loggedInuser]
  );

  return (
    <Stack sx={{ mt: '2rem' }}>
      <Typography variant="h6" gutterBottom>
        Løpedager:
      </Typography>
      <Box marginBottom={0.5}>
        <PersonLopeDagerTable
          personData={personData}
          setShowOppringing={setShowOppringing}
          setCurrentModalLopedag={setCurrentModalLopedag}
          setCurrentOppringingId={setCurrentOppringingId}
          setActiveTab={setActiveTab}
          setRingOpp={setRingOpp}
          oppringingIds={oppringingIds}
          handleSaveOppringing={handleSaveOppringing}
          {...props}
        />
      </Box>

      <Stack direction={'row'} alignItems={'end'} spacing={4} useFlexGap>
        <Tooltip
          title={
            !owned
              ? 'Mangler rettigheter'
              : !personData?.personId
              ? 'PersonID mangler'
              : ''
          }
        >
          <span>
            <NButton
              sx={{ minWidth: 'max-content' }}
              children="Ny løpedag"
              leftIcon={<AddIcon />}
              variant="outlined"
              onClick={handleNewLopedag}
              disabled={!owned || !personData?.personId}
            />
          </span>
        </Tooltip>

        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Stack
            direction="row"
            spacing={1}
            marginLeft="auto"
            sx={{ flexWrap: { sm: 'wrap', md: 'nowrap' } }}
          >
            <Box>
              <Typography whiteSpace="nowrap">Skjema sendt</Typography>
              <DatePicker
                value={moment(personData?.skjemaSendt, 'DD.MM.YYYY', true) || null}
                onChange={(newValue) =>
                  handleChange('skjemaSendt', newValue?.format('DD.MM.YYYY'))
                }
                format="DD.MM.YYYY"
                slotProps={{
                  textField: {
                    size: 'small',
                    error: false
                  }
                }}
                disableOpenPicker={!owned}
                readOnly={!owned}
              />
            </Box>
            <Box>
              <Typography whiteSpace="nowrap">Purring sendt</Typography>
              <DatePicker
                value={moment(personData?.purringSendt, 'DD.MM.YYYY', true) || null}
                onChange={(newValue) =>
                  handleChange('purringSendt', newValue?.format('DD.MM.YYYY'))
                }
                format="DD.MM.YYYY"
                slotProps={{
                  textField: { size: 'small', error: false }
                }}
                disableOpenPicker={!owned}
                readOnly={!owned}
              />
            </Box>
            <Box>
              <Typography whiteSpace="nowrap">Skjema mottatt</Typography>
              <DatePicker
                value={moment(personData?.skjemaMottatt, 'DD.MM.YYYY', true) || null}
                onChange={(newValue) =>
                  handleChange('skjemaMottatt', newValue?.format('DD.MM.YYYY'))
                }
                format="DD.MM.YYYY"
                slotProps={{
                  textField: { size: 'small', error: false }
                }}
                disableOpenPicker={!owned}
                readOnly={!owned}
              />
            </Box>
            <Box>
              <Typography whiteSpace="nowrap">Tilbakemelding sendt</Typography>
              <DatePicker
                value={
                  moment(personData?.tilbakemeldingSendt, 'DD.MM.YYYY', true) || null
                }
                onChange={(newValue) =>
                  handleChange('tilbakemeldingSendt', newValue?.format('DD.MM.YYYY'))
                }
                format="DD.MM.YYYY"
                slotProps={{
                  textField: {
                    size: 'small',
                    error: false
                  }
                }}
                disableOpenPicker={!owned}
                readOnly={!owned}
              />
            </Box>
          </Stack>
        </LocalizationProvider>
      </Stack>
    </Stack>
  );
}
