import React, { useEffect, useState, useContext } from 'react';
import useStyles from '../../../css/materialtheme';
import useFetchData from '../../utilities/useFetchData';
import { DatabaseContext } from '../../../context/DatabaseContext';
import PageContainer from '../home/PageContainer';
import StoffgrupperFeilds from './StoffgrupperFeilds';
import { useHistory } from 'react-router';
import { useKeycloak } from '@react-keycloak/web';
import { AlertI } from '../../../types/alertTypes';
import AlertComp from '../../utilities/AlertComp';
import CopyStoffgruppePopup from './CopyStoffgruppePopup';
import StoffsTable from './StoffsTable';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import { StoffI, StoffType, StoffgruppeI } from '../stoff/types/StoffTypes';
import { StoffSearch } from '../stoff/StoffSearch';
import { hasDataChanged } from '../../utilities/ArrayFunctions';

export default function Stoffgruppe(props: any) {
  const initialSgData = {
    beskrivelse: '',
    navn: '',
    stoffgruppeId: 0,
    tilgangskode: 0
  };
  const { currentDatabase } = useContext(DatabaseContext);
  const [title, setTitle] = useState('Stoffgrupper');
  document.title = title;
  const { keycloak } = useKeycloak();
  const token = keycloak?.token;
  const history = useHistory();
  const classes = useStyles();
  const backendURI = process.env.REACT_APP_BACKEND;
  const stoffgruppeIdFromURL = +props.match.params.kode;
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState<AlertI['alertColor']>('info');
  const [showStoffOption, setShowStoffOption] = useState(false);
  const [openCopyStoffgruppe, setOpenCopyStoffgruppe] = React.useState(false);
  const [showConfirmationWindow, setShowConfirmationWindow] = React.useState(false);
  const [stoffToDelete, setStoffToDelete] = React.useState<StoffI>();
  const [refetchList, setRefetchList] = useState(true);
  const [stoffGruppeList, setStoffGruppeList] = useState<StoffgruppeI[]>([]);
  const [stoffType, setStoffType] = useState<StoffType>(StoffType.group);
  const [stofferList, setStofferList] = useState<StoffI[]>([]);
  const [selectedStoffs, setSelectedStoffs] = useState<StoffI[]>([]);
  const [stoffGruppe, setStoffGruppe] = useState<StoffgruppeI>(initialSgData);
  const [originalStoffgruppeCopy, setOriginalStoffgruppeCopy] =
    useState<StoffgruppeI>(initialSgData);
  const [navnError, setNavnError] = useState({ error: false, message: '' });

  const allStoffGrupper = useFetchData({
    loadOnMount: false,
    clearDataOnLoad: false,
    projectURL: 'getStoffGroups',
    subProjectURL: `${currentDatabase?.value}/all`
  });

  const getStoffgruppe = useFetchData({
    loadOnMount: true,
    clearDataOnLoad: false,
    projectURL: 'getStoffgruppe',
    subProjectURL: `${currentDatabase?.value}/${stoffgruppeIdFromURL || 0}`
  });

  const getStoffInStoffgruppe = useFetchData({
    loadOnMount: false,
    clearDataOnLoad: false,
    projectURL: 'getAllStoffs',
    subProjectURL: `${currentDatabase?.value}/${stoffgruppeIdFromURL}`
  });

  useEffect(() => {
    if (allStoffGrupper.data) {
      const data: StoffgruppeI[] =
        (allStoffGrupper.data as unknown as StoffgruppeI[]) || [];
      setStoffGruppeList(data);
      if (data?.length > 0 && stoffGruppe.navn) {
        const sg = data?.find((d) => d.navn === stoffGruppe.navn);
        sg?.stoffgruppeId &&
          history.push(`/home/matvaredata/stoffgruppe/${sg?.stoffgruppeId}`);
      }
    }
  }, [allStoffGrupper.data]);

  const toggleCopyStoffgruppe = () => {
    setOpenCopyStoffgruppe(!openCopyStoffgruppe);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    if (name === 'stoffgruppeId') {
      setStoffGruppe({
        ...stoffGruppe,
        [name]: +value
      });
    } else {
      setStoffGruppe({
        ...stoffGruppe,
        [name]: value
      });
    }
  };

  const toggleStoffPopup = () => {
    setStoffType(StoffType.group);
    setShowStoffOption(!showStoffOption);
  };

  async function requests(data: any, url: string, methods: string) {
    try {
      const response = await fetch(encodeURI(url), {
        method: methods,
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token
        },
        body: JSON.stringify(data)
      });
      return response;
    } catch (error) {
      const resp = {
        status: 500
      };
      return resp;
    }
  }

  const navigateToStoffGroupe = (stoffgruppeId?: number) => {
    const timer = setTimeout(() => {
      history.push(`/home/matvaredata/stoffgruppe/${stoffgruppeId}`);
    }, 1000);
    return () => clearTimeout(timer);
  };
  const handleLagre = () => {
    if (!stoffGruppe?.navn) {
      showAlertMessage('error', 'Navn kan ikke være tom.');
    } else {
      if (hasDataChanged(stoffGruppe, originalStoffgruppeCopy)) {
        console.log('Data has changed!');
        /*  const stoffgruppeDataForm: StoffgruppeI = {
          stoffgruppeId: stoffGruppe?.stoffgruppeId || +'',
          navn: stoffGruppe?.navn || '',
          beskrivelse: stoffGruppe?.beskrivelse || ''
        }; */
        saveOrUpdateStoffgruppe();
      } else {
        showAlertMessage(
          'info',
          `Det er ikke blitt gjort noen endringer i skjermbildet. Lagring er ikke nødvendig.`
        );
      }
    }
  };
  const saveOrUpdateStoffgruppe = () => {
    let url = `${backendURI}/saveStoffgruppe/${currentDatabase?.value}`;
    const text = stoffgruppeIdFromURL ? 'Oppdateringen ' : 'Lagringen ';
    try {
      requests({ data: stoffGruppe }, url, 'POST').then((resp) => {
        if (resp.status === 200) {
          showAlertMessage('success', `${text} er fullført`);
          navigateToStoffGroupe(stoffGruppe.stoffgruppeId); //If we navigate to the stoff here we will get the latest from the backend.
        } else {
          showAlertMessage(
            'error',
            `${text} mislyktes. Det har ikke blitt gjort endringer mot databasen.`
          );
        }
      });
    } catch (error) {
      showAlertMessage('error', 'Det har oppstått en feil. Vennligst prøv igjen senere.');
    }
  };

  const showAlertMessage = (severity: AlertI['alertColor'], message: string) => {
    setShowAlert(true);
    setAlertSeverity(severity);
    setAlertMessage(message);
  };

  const clearFields = () => {
    setStoffGruppe(initialSgData);
    history.push('/home/matvaredata/stoffgruppe');
  };

  useEffect(() => {
    setTitle('Stoffgruppe - Nutrifoodcalc');
    setNavnError({ error: false, message: '' });
    if (stoffgruppeIdFromURL > 0 && refetchList) {
      allStoffGrupper.loadData('getStoffGroups', `${currentDatabase?.value}/all`);
      setRefetchList(false);
    }
  }, [refetchList]);

  useEffect(() => {
    if (getStoffgruppe.data) {
      const data = getStoffgruppe.data as unknown as StoffgruppeI;
      if (data.stoffgruppeId) {
        setStoffGruppe(data);
        setOriginalStoffgruppeCopy(data);
      }
    }
  }, [getStoffgruppe.data]);

  useEffect(() => {
    if (+stoffgruppeIdFromURL > 0) {
      getStoffgruppe.loadData(
        'getStoffgruppe',
        `${currentDatabase?.value}/${stoffgruppeIdFromURL}`
      );
      getStoffInStoffgruppe.loadData(
        'getAllStoffs',
        `${currentDatabase?.value}/${stoffgruppeIdFromURL}`
      );
    }
  }, [stoffgruppeIdFromURL]);

  const toggleStoffnummer = (row?: StoffI) => {
    if (row) {
      addStoffToStoffsTable(row);
    }
    setStoffType(StoffType.single);
    setShowStoffOption(!showStoffOption);
  };

  const deleteStoffgruppe = () => {
    const url = `${backendURI}/deleteStoffgruppe/${currentDatabase?.value}/${stoffGruppe.stoffgruppeId}`;
    requests({}, url, 'DELETE').then((resp) => {
      if (resp.status === 200) {
        console.log('Suceess');
        showAlertMessage('success', 'Sletting er fullført');
        clearFields();
      } else {
        console.log('resp.status', resp.status, 'error');
        showAlertMessage(
          'error',
          'Slettingen mislyktes. Det har ikke blitt gjort endringer mot databasen.'
        );
      }
    });
  };

  const removeStoffFromStoffsTable = (stoffId: number) => {
    const url = `${backendURI}/deleteStoffFromStoffgruppe/${currentDatabase?.value}/${stoffGruppe.stoffgruppeId}/${stoffId}`;
    requests({}, url, 'DELETE').then((resp) => {
      if (resp.status === 200) {
        getStoffInStoffgruppe.loadData(
          'getAllStoffs',
          `${currentDatabase?.value}/${stoffgruppeIdFromURL}`
        );
        showAlertMessage('success', 'Oppdatering er fullført');
      } else {
        showAlertMessage('error', 'Oppdateringen mislyktes.');
      }
    });
  };
  const addStoffToStoffsTable = (stoff: StoffI) => {
    const url = `${backendURI}/addStoffToStoffgruppe/${currentDatabase?.value}/${stoffGruppe.stoffgruppeId}/${stoff.stoffnrId}`;
    requests({}, url, 'POST').then((resp) => {
      if (resp.status === 200) {
        getStoffInStoffgruppe.loadData(
          'getAllStoffs',
          `${currentDatabase?.value}/${stoffgruppeIdFromURL}`
        );
        showAlertMessage('success', 'Stoffet lagt til stoffgruppe');
      } else {
        showAlertMessage('error', 'Stoffet ikke lagt til stoffgruppe');
        console.log('resp.status', resp.status, 'error');
      }
    });
  };

  const addStoffToNewStoffgruppe = (newStoffgruppeId: number) => {
    getStoffInStoffgruppe.data?.forEach((element: StoffI) => {
      let url = `${backendURI}/addStoffToStoffgruppe/${currentDatabase?.value}/${newStoffgruppeId}/${element.stoffnrId}`;

      if (stoffGruppe.stoffgruppeId > 0 && element.stoffnrId > 0) {
        requests({}, url, 'POST').then((resp) => {
          if (resp.status === 200) {
            console.log('Suceess', resp);
            showAlertMessage('success', 'Stoff lagt til stoffgruppe');
          } else {
            showAlertMessage('error', 'Stoff ikke lagt til stoffgruppe');
            console.log('resp.status', resp.status);
          }
        });
      }
    });
  };

  const toggleShowConfirmation = (stoff: StoffI) => {
    setShowConfirmationWindow(!showConfirmationWindow);
    setStoffToDelete(stoff);
  };

  const ConfirmDeletingStoff = (props: any) => {
    const handleClickJa = () => {
      removeStoffFromStoffsTable(stoffToDelete.stoffnrId);
      props.setShowConfirmationWindow(false);
    };
    const handleClickNei = () => {
      props.setShowConfirmationWindow(false);
    };

    return (
      <Dialog
        maxWidth="xs"
        fullWidth
        open={props.showConfirmationWindow}
        onClose={() => props.setShowConfirmationWindow(false)}
        aria-labelledby="sletteStoff"
      >
        <DialogTitle id="sletteStoff">Fjern Stoff</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Vil du fjerne stoff med stoffnr: <b>{stoffToDelete?.stoffnrId}</b> fra
            gruppen?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClickJa} color="primary">
            Ja
          </Button>
          <Button onClick={handleClickNei} color="primary" autoFocus>
            Nei
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <PageContainer>
      {showAlert && (
        <AlertComp
          alertMessage={alertMessage}
          alertColor={alertSeverity}
          showAlert={showAlert}
          setShowAlert={setShowAlert}
        />
      )}
      {showConfirmationWindow && (
        <ConfirmDeletingStoff
          showConfirmationWindow={showConfirmationWindow}
          setShowConfirmationWindow={setShowConfirmationWindow}
        />
      )}
      {showStoffOption && (
        <StoffSearch
          stofferList={stofferList}
          selectedStoffs={selectedStoffs}
          setSelectedStoffs={setSelectedStoffs}
          selectedStoffgruppe={stoffGruppe}
          stoffgrupperList={stoffGruppeList}
          setSelectedStoffgruppe={setStoffGruppe}
          stoffType={stoffType}
          setShowStoffOption={setShowStoffOption}
          showStoffOption={showStoffOption}
          databaseId={currentDatabase?.value}
          setStofferList={setStofferList}
          setStoffgrupperList={setStoffGruppeList}
          history={history}
          toggleStoffnummer={toggleStoffnummer}
        />
      )}
      {openCopyStoffgruppe && (
        <CopyStoffgruppePopup
          showCopyStoff={false}
          stoffGruppe={stoffGruppe}
          toggleCopygruppeStoff={toggleCopyStoffgruppe}
          postData={requests}
          showErrorAlert={showAlertMessage}
          navigateToStoffGroupe={navigateToStoffGroupe}
          stoffInStoffgruppe={getStoffInStoffgruppe.data}
          addStoffToNewStoffgruppe={addStoffToNewStoffgruppe}
          setRefetchList={setRefetchList}
        />
      )}
      <form onSubmit={handleLagre}>
        {getStoffgruppe.isLoaded && (
          <StoffgrupperFeilds
            classes={classes}
            stoffGruppe={stoffGruppe}
            handleChange={handleChange}
            toggleStoffPopup={toggleStoffPopup}
            navnError={navnError}
            clearFields={clearFields}
            stoffgruppeIdFromURL={stoffgruppeIdFromURL}
            handleLagre={handleLagre}
            deleteStoffgruppe={deleteStoffgruppe}
            toggleCopyStoffgruppe={toggleCopyStoffgruppe}
            stoffInStoffgruppe={getStoffInStoffgruppe.data}
          />
        )}
      </form>
      {stoffGruppe?.stoffgruppeId > 0 ? (
        <StoffsTable
          stoffGruppe={stoffGruppe}
          tableData={getStoffInStoffgruppe.data}
          removeStoff={toggleShowConfirmation}
          toggleStoffnummer={toggleStoffnummer}
        />
      ) : null}
    </PageContainer>
  );
}
