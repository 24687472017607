//import React from 'react';

export default async function PostData(
  data: any,
  baseUrl: string,
  dataUrl: string,
  methods: string,
  token: any
) {
  const backendURI = process.env.REACT_APP_BACKEND;

  // The dataUrl should look something like this. -> ${dbId}/${matvarekodeFromURL}/${STOFFNRID}
  let url = `${backendURI}/${baseUrl}/${dataUrl}`;
  let response = await fetch(encodeURI(url), {
    method: methods,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
    body: JSON.stringify(data)
  });
  return response;
}
