import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography
} from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';
import { PersonI } from './types/PersonTypes';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PersonOppringingerTable from './PersonOppringingerTable';
import { RouteComponentProps } from 'react-router-dom';

interface RouteParams {
  prosjektId: string;
}

interface PersonLopedagerProps extends RouteComponentProps<RouteParams> {
  personData: PersonI;
  setShowOppringing: Dispatch<SetStateAction<boolean>>;
  setRingOpp: Dispatch<SetStateAction<boolean>>;
  setCurrentModalLopedag: Dispatch<SetStateAction<number>>;
  setCurrentOppringingId: Dispatch<SetStateAction<number>>;
}

export default function PersonLopedager(props: PersonLopedagerProps) {
  const {
    personData,
    setShowOppringing,
    setRingOpp,
    setCurrentModalLopedag,
    setCurrentOppringingId
  } = props;
  const { prosjektId } = props.match.params;

  return (
    <Box sx={{ mt: '2rem' }}>
      <Accordion defaultExpanded sx={{ boxShadow: 'none' }}>
        <AccordionSummary
          sx={{ mb: -1.5, p: 0 }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography variant="h6">Oppringinger:</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ p: 0 }}>
          <PersonOppringingerTable
            personData={personData}
            setShowOppringing={setShowOppringing}
            setRingOpp={setRingOpp}
            setCurrentModalLopedag={setCurrentModalLopedag}
            setCurrentOppringingId={setCurrentOppringingId}
            {...props}
          />
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
