import { useEffect, useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';

export type Data = {
  data: any;
  loading: boolean;
  error: boolean;
};
export const useFetchNonGET = () => {
  const { keycloak } = useKeycloak();
  const token = keycloak?.token;
  const [state, setState] = useState({ status: 0, loading: false, error: false });
  const [param, callAPI] = useState({ url: '', data: {}, method: '' });

  useEffect(() => {
    if (!param.method || !param.data || !param.url) {
      return;
    }

    async function postData() {
      setState((state) => ({ status: state.status, loading: true, error: false }));

      await fetch(encodeURI(param.url), {
        method: param.method,
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token
        },
        body: JSON.stringify(param.data)
      })
        .then((res) => res.status)
        .then((result) => {
          if (result === 200 || result === 201) {
            setState({ status: result, loading: false, error: false });
          } else {
            setState({ status: result, loading: false, error: true });
          }
        })
        .catch((err) => {
          console.log(err);
          setState({ status: 0, loading: false, error: true });
        });
    }

    postData();
  }, [param.url, param.data, param.method]);

  return [state, callAPI] as const;
};
