import React, { useState } from 'react';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Paper from '@mui/material/Paper';
import { Chip, Grid, TableBody, TablePagination, Tooltip } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import useStylesTable from '../../../../css/TableCss';
import {
  BeregningObject,
  BeregningResultInfo,
  ExcludertMatvarer,
  ReportResultTableI
} from '../types/BeregningTypes';
import { StatusTypes, statusTypesNorsk } from '../../components/commons/CommonTypes';
import { NButton } from '../../components/Inputs';
import LinearWithValueLabel from '../../../utilities/LinearProgress';
import useFetchData from '../../../utilities/useFetchData';
import PollingComponent from '../../components/commons/PollingComponent';
import BResultPollingComponent from './BResultPollingComponent';
import { ExportToExcel } from './ExportToExcel';
import ExcludedMatvare from './ExcludedItems/ExcludedMatvare';
import { useUserContext } from '../../../../context/AuthContext';
import { DatabaseContext } from '../../../../context/DatabaseContext';
import useDidMountEffect from '../../../utilities/useDidMountEffect';
import { enhetOption } from '../../../../assets/beregningEnhetOption';
import { Report1Result } from './Reports/Report1Result';
import { useKeycloak } from '@react-keycloak/web';
import { Report2Result } from './Reports/Report2Result';
import { Report3Result } from './Reports/Report3Result';
import { Report4Result } from './Reports/Report4Result ';
import { Report10Result } from './Reports/Report10Result';
import { Report11Result } from './Reports/Report11Result';
import { Report12Result } from './Reports/Report12Result';
import { Report5Result } from './Reports/Report5Result';
import { Report6Result } from './Reports/Report6Result';
import { Report7Result } from './Reports/Report7Result';
import { Report8Result } from './Reports/Report8Result';
import { Report9Result } from './Reports/Report9Result';
import postData from '../../../utilities/postData';

interface ReportResultTableProps {
  startPolling: boolean;
  beregningObject: BeregningObject;
  setStartPolling: (v: boolean) => void;
  //setBeregnReport: (value: boolean) => void;
  setShowProgress: (value: boolean) => void;
  showAlertMessage: (message: string, severity: any) => void;
  updateBeregningObject: (item: string, name: string, value: any) => void;
  setProgressText: (value: string) => void;
  setShowBeregningReport?: (value: boolean) => void;
}

export default function ReportResultTable(props: ReportResultTableProps) {
  const {
    beregningObject,
    setShowProgress,
    //setBeregnReport,
    setShowBeregningReport,
    showAlertMessage,
    updateBeregningObject,
    setProgressText,
    setStartPolling,
    startPolling
  } = props;
  const { keycloak } = useKeycloak();
  const token = keycloak?.token;
  const classesTable = useStylesTable();
  const [beregningResults, setBeregningResults] = React.useState<ReportResultTableI[]>(
    []
  );
  //const [response, setResponse] = React.useState<ReportResultTableI>();
  // const [pollingId, setPollingId] = React.useState('');
  const [failed, setFailed] = React.useState(false);
  const [result, setResult] = React.useState([]);
  const loggedInuser = useUserContext();
  const currentUser = loggedInuser?.user?.username;
  const [reportInfo, setReportInfo] = React.useState<Array<Array<string>>>();
  const [resultReady, setResultReady] = React.useState<boolean>(false);
  const [showExcluded, setShowExcluded] = React.useState<boolean>(false);
  const [showReportOne, setShowReportOne] = React.useState<boolean>(false);
  const [showReportTwo, setShowReportTwo] = React.useState<boolean>(false);
  const [showReportThree, setShowReportThree] = React.useState<boolean>(false);
  const [showReportFour, setShowReportFour] = React.useState<boolean>(false);
  const [showReportFive, setShowReportFive] = React.useState<boolean>(false);
  const [showReportSix, setShowReportSix] = React.useState<boolean>(false);
  const [showReportSeven, setShowReportSeven] = React.useState<boolean>(false);
  const [showReportEight, setShowReportEight] = React.useState<boolean>(false);
  const [showReportNine, setShowReportNine] = React.useState<boolean>(false);
  const [showReportTen, setShowReportTen] = React.useState<boolean>(false);
  const [showReportEleven, setShowReportEleven] = React.useState<boolean>(false);
  const [showReportTwelve, setShowReportTwelve] = React.useState<boolean>(false);
  //const [beregnReport, setBeregnReport] = React.useState<boolean>(beregn);
  /*  const [projectId] = React.useState<number>(
    beregningObject?.kostholdData?.projectId?.value
  );
  const [surveyId] = React.useState<number>(
    beregningObject?.kostholdData?.surveyId?.value
  );
  const [reportId] = React.useState<number>(beregningObject?.matvareData?.rapport?.value); */
  const [unitId] = React.useState<number>(beregningObject?.matvareData?.enhet?.value);
  //let unitId = beregningObject?.matvareData?.enhet?.value;
  /*  const [unitName] = React.useState<string>(enhetOption[unitId]['label']);*/
  const [unitType, setUnitType] = React.useState<string>('g');
  const [reportData, setReportData] = React.useState([]);
  const [databaseId, setDatabaseId] = React.useState<number>();
  const [groupTypeId, setGroupTypeId] = React.useState<number>();
  const [proUndersokelse, setProUndersokelse] = React.useState<number[]>();

  const [error, setError] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>(
    'Beregningen er ikke fullført!'
  );
  const [errorReportId, setErrorReportId] = React.useState<number>(0);
  const [success, setSuccess] = React.useState<boolean>(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const endReport = useFetchData({
    loadOnMount: false,
    clearDataOnLoad: false,
    projectURL: 'endReport',
    subProjectURL: `${errorReportId}/${errorMessage}`
  });
  /*  const getAllBeregningResults = useFetchData({
    loadOnMount: true,
    clearDataOnLoad: false,
    projectURL: 'getAllBeregningResultsByUser',
    subProjectURL: null
  });
  React.useEffect(() => {
    if (getAllBeregningResults.data) {
      const data: ReportResultTableI[] =
        getAllBeregningResults.data as ReportResultTableI[];
      setBeregningResults(data);
      unloadAllReports();
    }
  }, [getAllBeregningResults.data]); */

  useDidMountEffect(() => {
    if (error) {
      endReport.loadData('endReport', `${errorReportId}/${errorMessage}`);
      setErrorMessage('Fikk ikke beregning resultat!');
      showAlertMessage('Beregningen er ikke fullført!', 'error');
      setSuccess(false);
    }
  }, [error]);
  useDidMountEffect(() => {
    if (endReport.error) {
      setError(true);
      console.log('Error in reporting end of beregning.');
      showAlertMessage('Noe gikk galt, beregningen er ikke fullført!', 'error');
      setSuccess(false);
    }
  }, [endReport.error]);
  useDidMountEffect(() => {
    if (reportData?.length > 0) {
      setResultReady(true);
      setShowProgress(false);
      setProgressText('');
    }
  }, [reportData]);
  useDidMountEffect(() => {
    if (success) {
      setShowProgress(false);
      setProgressText('');
      setSuccess(false);
    }
    updateBeregningObject('', 'beregn', false);
    setReportData([]);
    unloadAllReports();
  }, [success]);
  const unloadAllReports = () => {
    setShowReportOne(false);
    setShowReportTwo(false);
    setShowReportThree(false);
    setShowReportFour(false);
    setShowReportFive(false);
    setShowReportSix(false);
    setShowReportSeven(false);
    setShowReportEight(false);
    setShowReportNine(false);
    setShowReportTen(false);
    setShowReportEleven(false);
    setShowReportTwelve(false);
    setResultReady(false);
    setShowExcluded(false);
  };
  const [activeRow, setActiveRow] = useState(null);

  // Function to handle click on the first NButton
  const handleFirstButtonClick = (rowId: string) => {
    setActiveRow(rowId);
  };
  const extractVariablesFromResult = (info: BeregningResultInfo) => {
    const beregningInfo = info?.beregningInfo;
    console.log(beregningInfo);
    const regex1 = /:\s*(\d+)/g;
    const numbers: number[] = [];
    let match1: RegExpExecArray | null;

    while ((match1 = regex1.exec(beregningInfo[1]?.TEKSTLINJE)) !== null) {
      numbers.push(parseInt(match1[1]));
    }
    setProUndersokelse(numbers);
    console.log('numbers-', numbers);
    const regex2 = /\((\d+)\)/;
    const match2 = beregningInfo[2]?.TEKSTLINJE?.match(regex2);
    setDatabaseId(+match2[1]);

    const regex3 = /:(\s*)(\d+)/;
    const match3 = beregningInfo[3]?.TEKSTLINJE?.match(regex3);
    const number = match3 ? match3[2] : '';
    setGroupTypeId(+number);
  };
  const getResult = (reportId: number) => {
    if (unitId === 2) {
      setUnitType('g/10MJ');
    }
    if (reportId === 1) {
      console.log('open one');
      setShowReportOne(true);
    } else if (reportId === 2) {
      console.log('open two');

      setShowReportTwo(true);
    } else if (reportId === 3) {
      setShowReportThree(true);
    } else if (reportId === 4) {
      setShowReportFour(true);
    } else if (reportId === 5) {
      setShowReportFive(true);
    } else if (reportId === 6) {
      setShowReportSix(true);
    } else if (reportId === 7) {
      setShowReportSeven(true);
    } else if (reportId === 8) {
      setShowReportEight(true);
    } else if (reportId === 9) {
      setShowReportNine(true);
    } else if (reportId === 10) {
      setShowReportTen(true);
    } else if (reportId === 11) {
      setShowReportEleven(true);
    } else if (reportId === 12) {
      setShowReportTwelve(true);
    } else {
      setErrorMessage('Feil: Ugyldig rapport!');
      showAlertMessage('Feil: Ugyldig rapport!', 'error');
    }
  };

  const fetchResult = async (id: string, reportId: number) => {
    try {
      const baseUrl = process.env.REACT_APP_BACKEND;
      const url = `${baseUrl}/getResultById/${id}`;
      const response = await fetch(encodeURI(url), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token
        }
      });
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      } else {
        const data = await response.json();
        if (response.status === 200) {
          console.log('Result is ready:', data.result);
          setResult(data.result);
          getResult(+reportId);
          extractVariablesFromResult(data.result?.reportInfo);
        }
      }
    } catch (error) {
      console.error('Error calling API:', error);
      setFailed(true);
      return;
    }
  };
  const deleteResult = async (id: string) => {
    const url = `deleteResultById`;
    const data = {};
    await postData(data, url, id, 'DELETE', token).then((resp) =>
      console.log('resp', resp)
    );
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  return (
    <>
      {startPolling && (
        <BResultPollingComponent
          setFailed={setFailed}
          setResult={setResult}
          setResponse={setBeregningResults}
          startPolling={startPolling}
          setStartPolling={setStartPolling}
        />
      )}

      <Grid container style={{ paddingTop: 10, paddingBottom: 10 }}>
        <Grid container style={{ paddingTop: 10, paddingBottom: 10 }}>
          <TableContainer component={Paper}>
            <Table size="medium" sx={{ border: '1px solid black' }}>
              <TableHead sx={classesTable.head}>
                <TableRow>
                  <TableCell>Beskrivelse</TableCell>
                  <TableCell>Tid</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {beregningResults
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((row: ReportResultTableI, i: number) => (
                    <TableRow key={`${row.id}`}>
                      <TableCell align="center" padding="none">
                        {decodeURI(row.description)}
                      </TableCell>
                      <TableCell align="center" padding="none">
                        {row.created_at}
                      </TableCell>
                      <TableCell align="center" padding="none">
                        <Chip
                          label={statusTypesNorsk[row.status]}
                          size="small"
                          sx={{
                            backgroundColor:
                              row.status === StatusTypes.Completed
                                ? 'lightGreen'
                                : row.status === StatusTypes.Failed
                                ? 'red'
                                : row.status === StatusTypes.New
                                ? 'orange'
                                : 'gray',
                            margin: '1px'
                          }}
                        />
                      </TableCell>
                      <TableCell align="center" padding="none">
                        {row.status === StatusTypes.Completed ? (
                          <>
                            {' '}
                            <NButton
                              children="Last ned rapportfil"
                              rightIcon={<DownloadOutlinedIcon />}
                              variant="text"
                              onClick={() => {
                                fetchResult(row.id, row.report_id);
                                handleFirstButtonClick(row.id);
                              }}
                            />
                          </>
                        ) : row.status === StatusTypes.Processing ? (
                          <LinearWithValueLabel
                            text={row.progress_message || 'Beregner...'}
                          />
                        ) : null}
                      </TableCell>
                      <TableCell align="center" padding="none">
                        {activeRow === row.id && (
                          <Tooltip
                            title={
                              'Enkelte måltidskomponenter ble utelukket fra beregningen.\nVil du se hvilke matvarer dette gjelder?'
                            }
                          >
                            <NButton
                              children="Eksk. matvarer"
                              variant="text"
                              onClick={() => setShowExcluded(true)}
                              disabled={activeRow !== row.id}
                            />
                          </Tooltip>
                        )}
                      </TableCell>
                      <TableCell padding="none">
                        {row.status === StatusTypes.Completed ||
                        row.status === StatusTypes.Failed ? (
                          <NButton
                            children="Slett"
                            rightIcon={<DeleteOutlineOutlinedIcon />}
                            variant="text"
                            onClick={() => deleteResult(row.id)}
                          />
                        ) : null}
                      </TableCell>
                    </TableRow>
                  ))}
                <TableRow sx={{ backgroundColor: '#4977AF' }}></TableRow>
              </TableBody>
            </Table>
            <TablePagination
              // sx={classesTable.Pagination}
              rowsPerPageOptions={[5, 10, 25, 100]}
              component="div"
              count={beregningResults?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Grid>
      </Grid>
      {showReportOne && (
        <Report1Result
          beregningObject={beregningObject}
          setReportData={setReportData}
          setReportInfo={setReportInfo}
          setErrorReportId={setErrorReportId}
          result={result}
        />
      )}
      {showReportTwo && (
        <Report2Result
          beregningObject={beregningObject}
          setReportData={setReportData}
          setReportInfo={setReportInfo}
          setErrorReportId={setErrorReportId}
          result={result}
        />
      )}
      {showReportThree && (
        <Report3Result
          beregningObject={beregningObject}
          setReportData={setReportData}
          setReportInfo={setReportInfo}
          setErrorReportId={setErrorReportId}
          result={result}
        />
      )}
      {showReportFour && (
        <Report4Result
          beregningObject={beregningObject}
          setReportData={setReportData}
          setReportInfo={setReportInfo}
          setErrorReportId={setErrorReportId}
          result={result}
        />
      )}
      {showReportFive && (
        <Report5Result
          beregningObject={beregningObject}
          setReportData={setReportData}
          setReportInfo={setReportInfo}
          setErrorReportId={setErrorReportId}
          result={result}
          unitId={unitId}
        />
      )}
      {showReportSix && (
        <Report6Result
          beregningObject={beregningObject}
          setReportData={setReportData}
          setReportInfo={setReportInfo}
          setErrorReportId={setErrorReportId}
          result={result}
          unitId={unitId}
        />
      )}
      {showReportSeven && (
        <Report7Result
          beregningObject={beregningObject}
          setReportData={setReportData}
          setReportInfo={setReportInfo}
          setErrorReportId={setErrorReportId}
          result={result}
        />
      )}
      {showReportEight && (
        <Report8Result
          beregningObject={beregningObject}
          setReportData={setReportData}
          setReportInfo={setReportInfo}
          setErrorReportId={setErrorReportId}
          result={result}
        />
      )}
      {showReportNine && (
        <Report9Result
          beregningObject={beregningObject}
          setReportData={setReportData}
          setReportInfo={setReportInfo}
          setErrorReportId={setErrorReportId}
          result={result}
        />
      )}
      {showReportTen && (
        <Report10Result
          beregningObject={beregningObject}
          setReportData={setReportData}
          setReportInfo={setReportInfo}
          setErrorReportId={setErrorReportId}
          result={result}
        />
      )}
      {showReportEleven && (
        <Report11Result
          beregningObject={beregningObject}
          setReportData={setReportData}
          setReportInfo={setReportInfo}
          setErrorReportId={setErrorReportId}
          result={result}
        />
      )}
      {showReportTwelve && (
        <Report12Result
          beregningObject={beregningObject}
          setReportData={setReportData}
          setReportInfo={setReportInfo}
          setErrorReportId={setErrorReportId}
          result={result}
        />
      )}
      <div>
        {resultReady && (
          <ExportToExcel
            user={currentUser}
            reportInfo={reportInfo}
            reportData={reportData}
            setError={setError}
            setSuccess={setSuccess}
            setErrorMessage={setErrorMessage}
          />
        )}
      </div>
      <div>
        {showExcluded && (
          <ExcludedMatvare
            projectId={proUndersokelse[0]}
            surveyId={proUndersokelse[1]}
            databaseId={databaseId}
            groupTypeId={groupTypeId}
            showExcluded={showExcluded}
            //setShowBeregningReport={setShowBeregningReport}
            setShowProgress={setShowProgress}
            setProgressText={setProgressText}
            setShowExcluded={setShowExcluded}
            excludedMatvares={[]}
            showAlertMessage={showAlertMessage}
          />
        )}
      </div>
    </>
  );
}
