import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField
} from '@mui/material';
import React, { useState } from 'react';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import { RetensjonsfaktorerI } from './types/StoffTypes';
import { NButton } from '../components/Inputs';
import { EnhancedTableHead } from '../../utilities/EnhancedTableHead';
import { HeadCellsRF } from './components/TableHeadersData';
interface RetensjonsfaktorerTableProps {
  owned: boolean;
  retFactorData: RetensjonsfaktorerI[];
  setRetFactorData: (data: RetensjonsfaktorerI[]) => void;
  handleDelete: (stoffnr: number) => void;
}

export default function RetensjonsfaktorerTable(props: RetensjonsfaktorerTableProps) {
  const { owned, retFactorData, setRetFactorData, handleDelete } = props;
  const [editingRowId, setEditingRowId] = useState<number | null>(null);
  const [editingRowData, setEditingRowData] = useState<Partial<RetensjonsfaktorerI>>({});
  const [errorArray, setErrorArray] = useState<string[]>([]);
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = useState<keyof RetensjonsfaktorerI>('STOFFNRID');

  const handleRequestSort = (property: keyof RetensjonsfaktorerI) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const sortedRows = [...retFactorData].sort((a, b) => {
    if (b[orderBy] < a[orderBy]) return order === 'asc' ? -1 : 1;
    if (b[orderBy] > a[orderBy]) return order === 'asc' ? 1 : -1;
    return 0;
  });
  const validateField = (prop: keyof RetensjonsfaktorerI, value: any): string | null => {
    const regex = /^[1-9]\d*$/;
    if (prop === 'STOFFNRID') {
      if (!regex.test(value)) {
        return 'STOFFNRID_INVALID';
      }
    } else if (prop === 'MENGDE') {
      if (value === '' || isNaN(value)) {
        return 'MENGDE_INVALID';
      }
    }
    return null;
  };
  const handleEdit = (row: RetensjonsfaktorerI) => {
    setEditingRowId(row.STOFFNRID as number);
    setEditingRowData({ ...row });
  };

  const handleChange =
    (prop: keyof RetensjonsfaktorerI) => (event: React.ChangeEvent<HTMLInputElement>) => {
      if (prop === 'STOFFNRID' || prop === 'NAVN' || prop === 'KORTNAVN') {
        return; // Prevent changes to non-editable fields
      }
      const value = event.target.value;
      const updatedValue =
        HeadCellsRF.find((cell) => cell.id === prop)?.align === 'right'
          ? value === ''
            ? ''
            : value
          : value;
      setEditingRowData({ ...editingRowData, [prop]: updatedValue });
      const error = validateField(prop, updatedValue);
      if (error) {
        setErrorArray((prev) => [...prev, error]);
      } else {
        setErrorArray((prev) => prev.filter((e) => e !== `${prop}_INVALID`));
      }
    };

  const handleSave = () => {
    if (validateRowData(editingRowData)) {
      const updatedData = retFactorData.map((row) =>
        row.STOFFNRID === editingRowId ? (editingRowData as RetensjonsfaktorerI) : row
      );
      console.log('editingRowId', editingRowId);

      setRetFactorData(updatedData);
      setEditingRowId(null);
      setEditingRowData({});
      setErrorArray([]);
    }
  };

  const validateRowData = (data: Partial<RetensjonsfaktorerI>) => {
    const errors: string[] = [];
    const { STOFFNRID, NAVN, MENGDE } = data;

    if (!STOFFNRID) errors.push('STOFFNRID');
    if (!NAVN) errors.push('NAVN');
    if (!MENGDE) errors.push('MENGDE');

    if (MENGDE === undefined || MENGDE === +'') {
      errors.push('MENGDE');
    } else if (isNaN(MENGDE as number)) {
      errors.push('MENGDE_INVALID');
    }

    setErrorArray(errors);
    return errors.length === 0;
  };

  const getErrorMessage = (field: string) => {
    if (field === 'MENGDE_INVALID' || field === 'STOFFNRID_INVALID') {
      return 'Ugyldig verdi!';
    } else return 'Obligatorisk!';
  };
  return (
    <TableContainer component={Paper} sx={{ maxHeight: '55vh' }}>
      <Table stickyHeader size="small">
        <EnhancedTableHead<RetensjonsfaktorerI>
          headCells={HeadCellsRF}
          rightColumns={owned ? [{ name: 'Rediger' }, { name: 'Slett' }] : []}
          sorting={{
            order: order,
            orderBy: orderBy,
            onRequestSort: handleRequestSort
          }}
        />
        <TableBody>
          {sortedRows?.map((row) => (
            <TableRow key={row.STOFFNRID} hover>
              {HeadCellsRF.map((cell, i) => (
                <TableCell key={cell.id} padding={cell.padding} align={cell.align}>
                  {editingRowId === row.STOFFNRID &&
                  cell.id !== 'STOFFNRID' &&
                  cell.id !== 'NAVN' &&
                  cell.id !== 'KORTNAVN' ? (
                    <TextField
                      value={editingRowData[cell.id] || ''}
                      variant="standard"
                      onChange={handleChange(cell.id as keyof RetensjonsfaktorerI)}
                      error={
                        errorArray.includes(cell.id) ||
                        errorArray.includes(`${cell.id}_INVALID`)
                      }
                      helperText={
                        errorArray.includes(cell.id)
                          ? getErrorMessage(cell.id)
                          : errorArray.includes(`${cell.id}_INVALID`)
                          ? getErrorMessage(`${cell.id}_INVALID`)
                          : ''
                      }
                      size="small"
                      fullWidth
                    />
                  ) : row[cell.id] === 0 ? (
                    0
                  ) : row[cell.id] ? (
                    row[cell.id]
                  ) : (
                    ''
                  )}
                </TableCell>
              ))}
              {owned && (
                <>
                  <TableCell>
                    {row.STOFFNRID === editingRowId ? (
                      <IconButton aria-label="save" onClick={handleSave}>
                        <CheckOutlinedIcon />
                      </IconButton>
                    ) : (
                      <IconButton aria-label="edit" onClick={() => handleEdit(row)}>
                        <EditOutlinedIcon />
                      </IconButton>
                    )}
                  </TableCell>
                  <TableCell style={{ borderRight: '1px solid lightgrey' }}>
                    <NButton
                      rightIcon={<DeleteOutlineOutlinedIcon />}
                      variant="text"
                      onClick={() => handleDelete(row.STOFFNRID as number)}
                    />
                  </TableCell>
                </>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
