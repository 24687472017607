import React, { useState } from 'react';
import { KeycloakInstance } from 'keycloak-js';
import { returnInitialState } from './DatabaseContext';

export type Auth = {
  keycloak: KeycloakInstance;
  setAuth: (value: KeycloakInstance) => void;
};
export type Role = {
  hasRole: boolean;
  setHasRole: (value: boolean) => void;
};

export type UserStatus = {
  registered: boolean;
  setRegistered: (value: boolean) => void;
};

export const RegistrationContext = React.createContext<Partial<UserStatus>>({});
export const AuthContext = React.createContext<Partial<Auth>>({});
export const RoleContext = React.createContext<Partial<Role>>({ hasRole: false });

export function useUserLocalStorage(storageKey: string) {
  const [user, setStoredValue] = useState(returnInitialState(storageKey));

  const setUser = (value) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(user) : value;
      // Save to local storage
      window.localStorage.setItem(storageKey, JSON.stringify(valueToStore));
      // Save state
      setStoredValue(valueToStore);
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };
  return [user, setUser];
}
export type User = {
  username: string;
  id: number;
  numberRange: number[];
  roles: string[];
};
export type LoggedInUser = {
  user: User;
  setUser: (values: User) => void;
};
const initialValue: LoggedInUser = {
  user: {
    username: '',
    numberRange: [],
    id: 0,
    roles: []
  },
  setUser: () => {}
};
export const UserContext = React.createContext<Partial<LoggedInUser>>(initialValue);
export const useUserContext = () => React.useContext(UserContext);
