import { createContext, useContext, useState } from 'react';
import { returnInitialState } from './DatabaseContext';
import { CodeRegisterI } from '../components/pages/components/commons/CommonTypes';

export function useCodeStorage(storageKey: string) {
  const [codes, setStoredValue] = useState(returnInitialState(storageKey));

  const setCodes = (value) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(codes) : value;
      // Save to local storage
      window.localStorage.setItem(storageKey, JSON.stringify(valueToStore));
      // Save state
      setStoredValue(valueToStore);
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };
  return [codes, setCodes];
}
interface CodeContextProps {
  codes: CodeRegisterI[];
  setCodes: (code: CodeRegisterI[]) => void;
}
const initialValue: CodeContextProps = {
  codes: [],
  setCodes: () => {}
};
export const CodeContext = createContext<CodeContextProps>(initialValue);
export const useCodeContext = () => useContext(CodeContext);
