import React, { useEffect } from 'react';
import { BeregningObject, ReportElevenI } from '../../types/BeregningTypes';
import useDidMountEffect from '../../../../utilities/useDidMountEffect';
const reportHeaders = [
  { DBNAME: 'PERSONID', NAME: 'Nr' },
  { DBNAME: 'IDENTITET', NAME: 'Id' },
  { DBNAME: 'KJONN', NAME: 'kjønn' },
  { DBNAME: 'LOPEDAGID', NAME: 'Løpedag' },
  { DBNAME: 'MAALTIDNRID', NAME: 'Måltidsnr' },
  { DBNAME: 'VANLIGDAG', NAME: 'Vanligdag' },
  { DBNAME: 'UKEDAG', NAME: 'Ukedag' },
  { DBNAME: 'MAALTIDSTYPE', NAME: 'Måltidstype' },
  { DBNAME: 'SPISESTEDKODE', NAME: 'Spisested' },
  { DBNAME: 'KLOKKESLETT', NAME: 'Klokkeslett' },
  { DBNAME: 'SAMMEN_MED', NAME: 'Sammen med' }
];
interface ReportElevenProps {
  beregningObject: BeregningObject;
  setReportData: (value: any[]) => void;
  setReportInfo: (value: Array<Array<string>>) => void;
  setErrorReportId: (value: number) => void;
  result: any;
}
export const Report11Result = (props: ReportElevenProps) => {
  const [data] = React.useState([]);
  const [info] = React.useState<Array<Array<string>>>([]);
  const { beregningObject, setReportData, setReportInfo, setErrorReportId, result } =
    props;
  useEffect(() => {
    if (result) {
      console.log(' Beregning result.', 'success');
      const data = result as unknown as ReportElevenI;
      if (
        data?.reportInfo !== undefined &&
        data?.reportInfo?.beregningInfo !== undefined &&
        data?.mengdeAndDecimals !== undefined &&
        data?.persons !== undefined &&
        data?.stoffs !== undefined
      ) {
        prepareResult(data);
      }
    }
  }, [result]);

  const prepareResult = (reportElevendata: ReportElevenI) => {
    let description: string[];
    setErrorReportId(reportElevendata?.reportInfo?.reportId);
    for (let r of reportElevendata?.reportInfo?.beregningInfo) {
      description = [];
      description.push(r.TEKSTLINJE);
      info.push(description);
    }
    setReportInfo(info);
    let result: any[] = [];
    const headers: any[] = [];
    const enhet: any[] = [];

    for (let h of reportHeaders) {
      headers.push(h.NAME);
      enhet.push('');
    }
    if (reportElevendata?.stoffs?.length > 0) {
      for (let s of reportElevendata?.stoffs) {
        headers.push(s.KORTNAVN);
        enhet.push(s.ENHET);
      }
    }
    data.push(['']);
    data.push(headers);
    data.push(['']);
    data.push(['']);
    data.push(enhet);
    let sm = 0;
    for (let i = 0; i < reportElevendata?.persons?.length; i++) {
      result = [];
      for (let h of reportHeaders) {
        if (reportElevendata?.persons?.length > 0) {
          result.push(reportElevendata?.persons[i][h.DBNAME] || ' ');
        }
      }
      if (reportElevendata?.mengdeAndDecimals?.length > 0) {
        for (let s = 0; s < reportElevendata?.stoffs?.length; s++) {
          result.push(reportElevendata?.mengdeAndDecimals[sm].MENGDE);
          sm++;
        }
      }
      data.push(result);
    }
    setReportData(data);
  };
  return <></>;
};
