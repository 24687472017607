import React from 'react';
import { Grid, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { MatvareData } from '../types/MatvareTypes';

interface MatvareNoLinkProps {
  matvare: MatvareData;
}

function MatvareNoLink(p: MatvareNoLinkProps) {
  return (
    <>
      <Grid item xs={6}>
        <Typography>
          Matvare:{' '}
          <b>
            {p.matvare?.MATVAREKODEID}- {p.matvare?.NAVN}
          </b>
        </Typography>
      </Grid>
    </>
  );
}

export default MatvareNoLink;
