import React from 'react';
import { IconButton } from '@mui/material';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
interface ChatBubbleButtonProps {
  setShowPopup: any;
  showPopup: boolean;
  name: string;
  arialLable?: string;
  isDisabled?: boolean;
}
export function ChatBubbleButton(props: ChatBubbleButtonProps) {
  return (
    <>
      <IconButton
        aria-label={props.arialLable || ''}
        style={{ height: 40 }}
        onClick={() => {
          props.setShowPopup(!props.showPopup);
        }}
        disabled={props.isDisabled || false}
        color={props.isDisabled ? 'inherit' : 'primary'}
      >
        <h6
          style={{
            //fontStyle: 'old',
            fontSize: '1rem',
            lineHeight: '1rem',
            color: props.isDisabled ? 'inherit' : '#1C5081'
          }}
        >
          {props.name}
        </h6>
        <ChatBubbleOutlineIcon
          color={props.isDisabled ? 'inherit' : 'primary'}
          style={{ fontSize: 'medium' }}
        />
      </IconButton>
    </>
  );
}
interface ChatBubbleButtonOneProps {
  name: string;
  arialLable?: string;
  handleOpenPopup: any;
  isDisabled?: boolean;
}
export function ChatBubbleButtonSingle(props: ChatBubbleButtonOneProps) {
  return (
    <>
      <IconButton
        aria-label={props.arialLable || ''}
        style={{ height: 40 }}
        onClick={() => {
          props.handleOpenPopup();
        }}
        disabled={props.isDisabled || false}
      >
        <h6
          style={{
            //textSizeAdjust: 'small',
            fontWeight: 'lighter',
            fontFamily: 'Arial',
            fontStyle: 'normal',
            fontSize: '16px',
            lineHeight: '14px',
            color: '#1C5081'
          }}
        >
          {props.name}
        </h6>
        <ChatBubbleOutlineIcon color="primary" style={{ fontSize: 'medium' }} />
      </IconButton>
    </>
  );
}
