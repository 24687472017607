import React, { useEffect } from 'react';
import { BeregningObject, ReportFiveI } from '../../types/BeregningTypes';
import useDidMountEffect from '../../../../utilities/useDidMountEffect';
interface ReportFiveProps {
  beregningObject: BeregningObject;
  setReportData: (value: any[]) => void;
  setReportInfo: (value: Array<Array<string>>) => void;
  setErrorReportId: (value: number) => void;
  result: any;
  unitId: number;
}
export const Report5Result = (props: ReportFiveProps) => {
  const [data] = React.useState([]);
  const [info] = React.useState<Array<Array<string>>>([]);
  const {
    beregningObject,
    setReportData,
    setReportInfo,
    setErrorReportId,
    result,
    unitId
  } = props;
  useEffect(() => {
    if (result) {
      console.log(' Beregning result.', 'success');
      const data = result as unknown as ReportFiveI;
      if (
        data?.reportInfo !== undefined &&
        data?.reportInfo?.beregningInfo !== undefined &&
        data?.stoffAndMengdes !== undefined &&
        data?.stoffs !== undefined
      ) {
        prepareResult(data);
      }
    }
  }, [result]);

  const prepareResult = (reportFiveData: ReportFiveI) => {
    let description: string[];
    setErrorReportId(reportFiveData?.reportInfo?.reportId);
    for (let r of reportFiveData?.reportInfo?.beregningInfo) {
      description = [];
      description.push(r.TEKSTLINJE);
      info.push(description);
    }
    setReportInfo(info);
    let result: any[] = [];
    const shortNames: any[] = [];
    const enhet: any[] = [];
    shortNames.push('');
    enhet.push('');
    let prevPId = 0;
    let pId = 0;
    if (unitId === 11) {
      for (let stoff of reportFiveData?.stoffs) {
        shortNames.push(stoff.KORTNAVN);
        enhet.push(stoff.ENHET + '/%');
      }
      for (
        let i = 0;
        i < reportFiveData?.stoffAndMengdes?.length;
        i = i + reportFiveData?.stoffs?.length
      ) {
        if (reportFiveData?.stoffAndMengdes?.length > 0) {
          result = [];
          pId = reportFiveData?.stoffAndMengdes[i].PERSONID;
          if (prevPId !== pId) {
            data.push(['']);
            data.push(shortNames);
            data.push(enhet);
            prevPId = pId;
          }
          result.push(reportFiveData?.stoffAndMengdes[i].NAVN);
          for (let s = 0; s < reportFiveData?.stoffs?.length; s++) {
            result.push(reportFiveData?.stoffAndMengdes[i + s].PROSENT);
          }

          data.push(result);
        }
      }
    } else {
      shortNames.push('Matinntak');
      if (reportFiveData?.stoffs?.length > 0) {
        if (unitId === 6) {
          enhet.push(reportFiveData?.stoffs[0].ENHET + '/10MG');
        } else {
          enhet.push(reportFiveData?.stoffs[0].ENHET);
        }
      }
      for (let stoff of reportFiveData?.stoffs) {
        shortNames.push(stoff.KORTNAVN);
        if (unitId === 6) {
          enhet.push(stoff.ENHET + '/10MG');
        } else {
          enhet.push(stoff.ENHET);
        }
      }
      for (
        let i = 0;
        i < reportFiveData?.stoffAndMengdes?.length;
        i = i + reportFiveData?.stoffs?.length + 1
      ) {
        //+1 because we added 'mattintak' to the list of stoff names we got from db
        if (reportFiveData?.stoffAndMengdes?.length > 0) {
          result = [];
          pId = reportFiveData?.stoffAndMengdes[i].PERSONID;
          if (prevPId !== pId) {
            data.push(['']);
            data.push(shortNames);
            data.push(enhet);
            prevPId = pId;
          }
          result.push(reportFiveData?.stoffAndMengdes[i].NAVN);

          for (let s = 0; s < reportFiveData?.stoffs?.length + 1; s++) {
            result.push(reportFiveData?.stoffAndMengdes[i + s].MENGDE);
          }
          data.push(result);
        }
      }
    }
    setReportData(data);
  };
  return <></>;
};
