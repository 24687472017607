import React from 'react';
import { KorrekturfilI, PersonI } from '../../RapportTypes';
import {
  Typography,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Stack,
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from '@mui/material';
import { NButton } from '../../../components/Inputs';
import { DialogBox } from '../../../../utilities/DialogBox';

import FirstPageOutlinedIcon from '@mui/icons-material/FirstPageOutlined';
import NavigateBeforeOutlinedIcon from '@mui/icons-material/NavigateBeforeOutlined';
import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined';
import LastPageOutlinedIcon from '@mui/icons-material/LastPageOutlined';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ReactToPrint from 'react-to-print';

const ComponentToPrint = React.forwardRef(
  (p: { data: KorrekturfilI; pagebreak: boolean }, ref: any) => (
    <div ref={ref} style={{ padding: '1em' }}>
      <div style={{ textAlign: 'center' }}>
        <h1>Korrekturfil</h1>
        <h3>Matvaredatabase: {p.data.databasenavn}</h3>
      </div>
      <table className="simple-table">
        <tr>
          <th>Prosjektnr</th>
          <td>: {p.data.prosjekt.id}</td>
          <th>Kortnavn</th>
          <td>: {p.data.prosjekt.kortnavn}</td>
          <th>Navn</th>
          <td>: {p.data.prosjekt.navn}</td>
        </tr>
        <tr>
          <th>Undersøkelsenr</th>
          <td>: {p.data.undersokelse.id}</td>
          <th>Kortnavn</th>
          <td>: {p.data.undersokelse.kortnavn}</td>
          <th>Navn</th>
          <td>: {p.data.undersokelse.navn}</td>
        </tr>
      </table>
      {p.data.personer?.map((person) => (
        <div className={p.pagebreak && 'pagebreak'}>
          {/* MUI Table shows header on all pages containing the table */}
          <Table size="small">
            <TableHead
              sx={{ '.MuiTableCell-root': { borderBottom: 'none', fontWeight: 'bold' } }}
            >
              <TableRow>
                <TableCell>PersonId: {person.id}</TableCell>
                <TableCell>Identitet: {person.identitet}</TableCell>
                <TableCell>Kjønn: {person.kjonn}</TableCell>
                <TableCell>Alder: {person.alder}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell />
                <TableCell />
                <TableCell>Høyde: {person.hoyde}</TableCell>
                <TableCell>Vekt: {person.vekt}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {person.lopedager?.map((day) => (
                <TableRow>
                  <TableCell colSpan={4}>
                    {day.maaltider?.map((meal) => (
                      <>
                        <div style={{ display: 'flex' }}>
                          <p style={{ flex: 1 }}>
                            Dag: {day.lopedagid} {day.ukedag}
                          </p>
                          <p style={{ flex: 1 }}>
                            Måltid: {meal.id} {meal.navn} {meal.klokkeslett}
                          </p>
                          <p style={{ flex: 1 }}>{meal.spisested}</p>
                          <p style={{ flex: 1 }}>
                            Vanlig dag: {day.vanligdag ? 'Ja' : 'Nei'}
                          </p>
                        </div>
                        <table className="simple-table" style={{ width: '100%' }}>
                          {meal.matvarer?.map((food) => (
                            <tr>
                              <td style={{ textAlign: 'end', width: '20%' }}>
                                {Math.round(food.mengde * 100) / 100}&nbsp;
                                {food.mengdeenhet}
                              </td>
                              <td style={{ width: '60%' }}>{food.navn}</td>
                              <td style={{ width: '20%' }}>{food.kode}</td>
                            </tr>
                          ))}
                        </table>
                      </>
                    ))}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      ))}
    </div>
  )
);

interface SimplePaginationProps {
  goTo: (n: number) => void;
  page: number;
  pageCount: number;
}

const SimplePagination = (p: SimplePaginationProps) => {
  return (
    <Grid item xs={12} container padding={1} alignItems="flex-end">
      <Grid item xs={5}>
        <Stack direction="row" spacing={2}>
          <IconButton disabled={p.page === 0} onClick={() => p.goTo(0)}>
            <FirstPageOutlinedIcon />
          </IconButton>
          <IconButton disabled={p.page - 1 < 0} onClick={() => p.goTo(p.page - 1)}>
            <NavigateBeforeOutlinedIcon />
          </IconButton>
        </Stack>
      </Grid>
      <Grid item xs={2}>
        <Typography variant="h5" children={p.page + 1} />
      </Grid>
      <Grid item xs={5}>
        <Stack direction="row-reverse" spacing={2}>
          <IconButton
            disabled={p.page === p.pageCount}
            onClick={() => p.goTo(p.pageCount)}
          >
            <LastPageOutlinedIcon />
          </IconButton>
          <IconButton
            disabled={p.page + 1 > p.pageCount}
            onClick={() => p.goTo(p.page + 1)}
          >
            <NavigateNextOutlinedIcon />
          </IconButton>
        </Stack>
      </Grid>
    </Grid>
  );
};

const PersonRapport = ({ data }: { data: PersonI }) => {
  return (
    <Grid item xs={12} container>
      <Grid
        item
        xs={12}
        container
        sx={{ fontSize: 'large', color: '#000' }}
        marginBottom={1}
      >
        <Grid item xs={3}>
          PersonId: {data.id}
        </Grid>
        <Grid item xs={3}>
          Identitet: {data.identitet}
        </Grid>
        <Grid item xs={3}>
          Kjønn: {data.kjonn}
        </Grid>
        <Grid item xs={3}>
          Alder: {data.alder}
        </Grid>
        <Grid item xs={6} />
        <Grid item xs={3}>
          Høyde: {data.hoyde}
        </Grid>
        <Grid item xs={3}>
          Vekt: {data.vekt}
        </Grid>
      </Grid>
      {data.lopedager?.map((day) =>
        day.maaltider?.map((meal) => (
          <>
            <Grid item xs={12} container sx={{ fontWeight: 'bold' }}>
              <Grid item xs={3}>
                Dag: {day.lopedagid} {day.ukedag}
              </Grid>
              <Grid item xs={3}>
                Måltid: {meal.id} {meal.navn} {meal.klokkeslett}
              </Grid>
              <Grid item xs={3}>
                {meal.spisested}
              </Grid>
              <Grid item xs={3}>
                Vanlig dag: {day.vanligdag ? 'Ja' : 'Nei'}
              </Grid>
            </Grid>
            {meal.matvarer?.map((food) => (
              <Grid item xs={12} container sx={{ '&:hover': { background: '#f4f4f4' } }}>
                <Grid item xs={2} textAlign="end">
                  {Math.round(food.mengde * 100) / 100}&nbsp;{food.mengdeenhet}
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={4}>
                  {food.navn}
                </Grid>
                <Grid item xs={2} />
                <Grid item xs={3}>
                  {food.kode}
                </Grid>
              </Grid>
            ))}
          </>
        ))
      )}
    </Grid>
  );
};

const HTMLRapport = ({ data }: { data: KorrekturfilI }) => {
  const [showDialog, setShowDialog] = React.useState<boolean>(false);
  const [personOnNewPage, setPersonOnNewPage] = React.useState<boolean>(false);
  const [page, setPage] = React.useState<number>(0);

  const printRef = React.useRef();

  /*   React.useEffect(() => {
    setShowDialog(true)
  }, [data.personer?.length]); */

  return (
    <>
      <Box sx={{ display: 'none' }}>
        <ComponentToPrint data={data} pagebreak={personOnNewPage} ref={printRef} />
      </Box>
      <Grid item xs={4}>
        <NButton
          leftIcon={<VisibilityOutlinedIcon />}
          children="Vis korrekturfil"
          onClick={() => setShowDialog(true)}
          fullWidth
        />
      </Grid>
      <Grid item xs={4}>
        <ReactToPrint
          trigger={() => (
            <NButton
              leftIcon={<PrintOutlinedIcon />}
              children="Skriv ut korrekturfil"
              onClick={() => setShowDialog(true)}
              fullWidth
            />
          )}
          pageStyle="@media print{.pagebreak{page-break-before:always}}.simple-table>tr>td{padding-right:16px}"
          content={() => printRef.current}
        />
      </Grid>
      <Grid item xs={4}>
        <FormControlLabel
          label="Person på ny side"
          control={
            <Checkbox
              checked={personOnNewPage}
              onChange={() => setPersonOnNewPage((b) => !b)}
            />
          }
        />
      </Grid>
      {showDialog ? (
        <DialogBox
          title="Korrekturfil"
          isShowing={showDialog}
          hide={() => setShowDialog(false)}
        >
          <Grid container>
            {personOnNewPage ? (
              <SimplePagination
                goTo={setPage}
                page={page}
                pageCount={data.personer?.length}
              />
            ) : null}
            {!personOnNewPage || page === 0 ? (
              <>
                <Grid item xs={12} container sx={{ fontSize: 'large', color: '#000' }}>
                  <Grid item xs={3}>
                    Matvaredatabase: {data.databasenavn}
                  </Grid>
                  <Grid item xs={3}>
                    ID
                  </Grid>
                  <Grid item xs={3}>
                    Kortnavn
                  </Grid>
                  <Grid item xs={3}>
                    Navn
                  </Grid>
                  <Grid item xs={3}>
                    Prosjekt
                  </Grid>
                  <Grid item xs={3}>
                    {data.prosjekt.id}
                  </Grid>
                  <Grid item xs={3}>
                    {data.prosjekt.kortnavn}
                  </Grid>
                  <Grid item xs={3}>
                    {data.prosjekt.navn}
                  </Grid>
                  <Grid item xs={3}>
                    Undersøkelse
                  </Grid>
                  <Grid item xs={3}>
                    {data.undersokelse.id}
                  </Grid>
                  <Grid item xs={3}>
                    {data.undersokelse.kortnavn}
                  </Grid>
                  <Grid item xs={3}>
                    {data.undersokelse.navn}
                  </Grid>
                  <Grid item xs={3}>
                    Antall personer
                  </Grid>
                  <Grid item xs={3}>
                    {data.personer?.length}
                  </Grid>
                </Grid>
              </>
            ) : null}

            {personOnNewPage ? (
              <>
                {page > 0 ? <PersonRapport data={data.personer[page - 1]} /> : null}
                <SimplePagination
                  goTo={setPage}
                  page={page}
                  pageCount={data.personer?.length + 1}
                />
              </>
            ) : (
              <>
                <Grid item xs={12}>
                  <hr style={{ marginBottom: '2em' }} />
                </Grid>
                {data.personer?.map((p) => (
                  <>
                    <PersonRapport data={p} />
                    <Grid item xs={12}>
                      <hr style={{ marginBottom: '2em' }} />
                    </Grid>
                  </>
                ))}
              </>
            )}
          </Grid>
        </DialogBox>
      ) : null}
    </>
  );
};

export default HTMLRapport;
