import React, { useEffect } from 'react';
import { TextField, Grid, InputLabel } from '@mui/material';
import { KonsumFilter } from './BeregningFilterComponents/KonsumFilter';
import { PersonFilter } from './BeregningFilterComponents/PersonFilter';
import { validateNumber } from '../../../utilities/Validate';
import { ChatBubbleButton } from '../../components/ChatBubbleButton';
import useFetchData from '../../../utilities/useFetchData';
import {
  BeregnFilterValuesI,
  BeregningObject,
  countResult,
  filterQueryDataI,
  OptionI,
  selectedOptionArrayI
} from '../types/BeregningTypes';
import { FieldsValues } from '../types/BeregningTypes';
import useStyles from '../../../../css/materialtheme';
import PostData from '../../../utilities/postData';
import { useKeycloak } from '@react-keycloak/web';
import ProjectSearch from '../../kosthold/ProjectSearch';
import UndersokelseSearch from '../../kosthold/UndersokelseSearch';
import { ProsjektI, UndersokelseI } from '../../kosthold/types/ProjectTypes';
import { useProjectContext } from '../../../../context/ProjectContext';
import { FetchProjectandUndersokelse } from '../../kosthold/components/FetchProjectandUndersokelse';
interface KostholdsDataProps {
  beregningObject: BeregningObject;
  setBeregningObject: (obj: BeregningObject) => void;
  handleOnEnterOnProsjekt: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  handleOnEnterOnSurvey: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  showAlertMessage: (message: string, severity: any) => void;
  beregnFilterValues: BeregnFilterValuesI;
  personFilterCriteria: string[];
  setPersonFilterCriteria: (value: string[]) => void;
  konsumFilterCriteria: string[];
  setKonsumFilterCriteria: (value: string[]) => void;
  updateBeregningObject: (item: string, name: string, value: any) => void;
  cleared: boolean;
  checkSurveyValidity: () => void;
}
const KostholdsData = (props: KostholdsDataProps) => {
  const {
    beregningObject,
    setBeregningObject,
    handleOnEnterOnProsjekt,
    handleOnEnterOnSurvey,
    showAlertMessage,
    beregnFilterValues,
    personFilterCriteria,
    setPersonFilterCriteria,
    konsumFilterCriteria,
    setKonsumFilterCriteria,
    updateBeregningObject,
    cleared,
    checkSurveyValidity
  } = props;
  const { keycloak } = useKeycloak();
  const token = keycloak?.token;
  const classes = useStyles();
  const [projectId] = React.useState<FieldsValues>(
    beregningObject?.kostholdData?.projectId
  );
  const [surveyId] = React.useState<FieldsValues>(
    beregningObject?.kostholdData?.surveyId
  );
  const [showProsjektIdList, setShowProsjektIdList] = React.useState(false);
  const [showUndersokelseIdList, setShowUndersokelseIdList] = React.useState(false);
  const [showPersonfilter, setShowPersonfilter] = React.useState(false);
  const [showKonsumfilter, setShowKonsumfilter] = React.useState(false);
  const [logicalOperator, setLogicalOperator] = React.useState<string>('AND');
  const [personFilterActivatedOptions, setPersonFilterActivatedOptions] = React.useState<
    selectedOptionArrayI[]
  >([]);
  const [konsumFilterActivatedOptions, setKonsumFilterActivatedOptions] = React.useState<
    selectedOptionArrayI[]
  >([]);
  const [personFilterText, setPersonFilterText] = React.useState('Alle');
  const [konsumFilterText, setKonsumFilterText] = React.useState('Alle');
  const [personFilterParams, setPersonFilterParams] = React.useState<filterQueryDataI[]>(
    []
  );
  const [konsumFilterParams, setKonsumFilterParams] = React.useState<filterQueryDataI[]>(
    []
  );
  const [refetchProject, setRefetchProject] = React.useState<boolean>(false);
  const [refetchSurvey, setRefetchSurvey] = React.useState<boolean>(false);
  const getPersonsCount = useFetchData({
    loadOnMount: false,
    clearDataOnLoad: false,
    projectURL: 'getPersonsCount',
    subProjectURL: `${beregningObject?.kostholdData?.projectId?.value}/${
      beregningObject?.kostholdData?.surveyId?.value
    }/${encodeURI(JSON.stringify(personFilterParams))}`
  });

  useEffect(() => {
    if (
      beregningObject?.kostholdData?.projectId?.value > 0 &&
      beregningObject?.kostholdData?.surveyId?.value > 0
    ) {
      getPersonsCount.loadData(
        'getPersonsCount',
        `${beregningObject?.kostholdData?.projectId?.value}/${
          beregningObject?.kostholdData?.surveyId?.value
        }/${encodeURI(JSON.stringify(personFilterParams))}`
      );
    }
  }, [
    personFilterParams,
    konsumFilterParams,
    beregningObject?.kostholdData?.surveyId?.value
  ]);
  // }, [personFilterParams]);
  useEffect(() => {
    if (personFilterActivatedOptions?.length > 0) {
      setPersonFilterActivatedOptions(personFilterActivatedOptions);
    }
  }, [personFilterActivatedOptions]);
  useEffect(() => {
    if (konsumFilterActivatedOptions?.length > 0) {
      setKonsumFilterActivatedOptions(konsumFilterActivatedOptions);
    }
  }, [konsumFilterActivatedOptions]);

  useEffect(() => {
    personFilterCriteria?.length > 0
      ? setPersonFilterText('Spesifisert-utvalg')
      : setPersonFilterText('Alle');
  }, [personFilterCriteria]);
  useEffect(() => {
    konsumFilterCriteria?.length > 0
      ? setKonsumFilterText('Spesifisert-utvalg')
      : setKonsumFilterText('Alle');
  }, [konsumFilterCriteria]);

  useEffect(() => {
    if (konsumFilterActivatedOptions?.length > 0 && !showKonsumfilter) {
      saveKonsumfilter();
    }
  }, [showKonsumfilter]);
  useEffect(() => {
    if (cleared) {
      beregningObject.kostholdData.projectId.value = 0;
      beregningObject.kostholdData.surveyId.value = 0;
      setPersonFilterActivatedOptions([]);
      setKonsumFilterActivatedOptions([]);
      setKonsumFilterCriteria([]);
      setPersonFilterCriteria([]);
    }
  }, [cleared]);
  useEffect(() => {
    const data: countResult = getPersonsCount.data as unknown as countResult;
    //setPersons(data.COUNT);
    setBeregningObject({ ...beregningObject, numberOfPersons: data.COUNT });
  }, [getPersonsCount.data]);
  useEffect(() => {
    if (getPersonsCount.error)
      showAlertMessage('Feil ved henting av antall personer!', 'error');
  }, [getPersonsCount.error]);

  const setSurveyId = (value: number) => {
    const unId: FieldsValues = beregningObject?.kostholdData?.surveyId;
    unId.value = value;
    updateBeregningObject('kosthold', 'surveyId', unId);
  };

  const displayActivatedOptions = (selectedOption: selectedOptionArrayI) => {
    const currentValue = selectedOption.option?.selectedUtvalg?.hasPredefinedValue
      ? selectedOption.option?.variableSvarArray?.svars?.length > 0
        ? selectedOption.option?.variableSvarArray?.variabel?.label +
          ' : ' +
          selectedOption.option?.variableSvarArray?.svars
            ?.map((val: OptionI) => val?.label)
            ?.join(' eller ')
        : selectedOption.option?.categoryValues
            ?.map((val: OptionI) => val?.label)
            ?.join(' eller ')
      : selectedOption.option?.selectedOperatorTwo === undefined
      ? selectedOption.option?.nonCategoryValues?.values?.join(' og ')
      : selectedOption.option?.nonCategoryValues?.values?.join(
          ` ${logicalOperator}  ${selectedOption.option?.selectedOperatorTwo?.label} `
        );
    const value =
      selectedOption.option !== undefined
        ? `${selectedOption.option?.selectedUtvalg?.label} ${
            selectedOption.option?.selectedOperatorOne?.label || '='
          } ${currentValue}`
        : '';
    showPersonfilter
      ? setPersonFilterCriteria([...personFilterCriteria, value])
      : setKonsumFilterCriteria([...konsumFilterCriteria, value]);
  };
  const saveKonsumfilter = () => {
    PostData({ data: konsumFilterParams }, 'saveKonsumfilter', ``, 'POST', token).then(
      (resp: any) => {
        if (resp.status === 200) {
          showAlertMessage('Konsumfilter valgene er lagret.', 'success');
        } else if (resp.status === 500) {
          console.log('An error 500', resp.status);
          showAlertMessage('Lagring av konsumfilter valgene mislyktes.', 'error');
        }
      }
    );
  };
  const handleProjectChange = (value: number) => {
    projectId.value = value;
    updateBeregningObject('kosthold', 'projectId', projectId);
    setSurveyId(0);
    setBeregningObject({ ...beregningObject, numberOfPersons: 0 });
    checkSurveyValidity();
  };

  return (
    <>
      <FetchProjectandUndersokelse
        projectId={beregningObject?.kostholdData?.projectId?.value}
        refetchProject={refetchProject}
        setRefetchProject={setRefetchProject}
        refetchSurvey={refetchSurvey}
        setRefetchSurvey={setRefetchSurvey}
      />
      <div>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Grid container item>
              <Grid item xs={6}>
                <InputLabel sx={classes.InputLabel}>Prosjektnr*</InputLabel>
                <TextField
                  error={
                    (projectId.touched &&
                      !validateNumber(beregningObject?.kostholdData?.projectId?.value)) ||
                    projectId.error
                  }
                  helperText={
                    (projectId.touched &&
                      !validateNumber(beregningObject?.kostholdData?.projectId?.value)) ||
                    projectId.error
                      ? projectId.errorMsg
                      : ''
                  }
                  id="outlined-uncontrolled-Prosjektnr-kosthold"
                  type="tel"
                  maxRows="1"
                  inputProps={{
                    min: 1,
                    maxLength: 6
                  }}
                  value={beregningObject?.kostholdData?.projectId?.value || ''}
                  onChange={(e) => {
                    handleProjectChange(+e.target.value);
                  }}
                  onBlur={(e) => {
                    projectId.touched = true;
                    updateBeregningObject('kosthold', 'projectId', projectId);
                  }}
                  //setProjectId('touched', true )}
                  onFocus={(e) => {
                    //setProjectId('touched', false );
                    //setProjectId('error', false)}
                    projectId.touched = false;
                    projectId.error = false;
                    updateBeregningObject('kosthold', 'projectId', projectId);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleOnEnterOnProsjekt(e);
                    }
                  }}
                  required
                  InputProps={{ sx: { input: classes.resize } }}
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item xs={6}>
                <div style={{ paddingTop: 25 }}>
                  <ChatBubbleButton
                    name={'Prosjektnr'}
                    showPopup={showProsjektIdList}
                    setShowPopup={setShowProsjektIdList}
                  />
                </div>
                {showProsjektIdList && (
                  <ProjectSearch
                    setProject={(id) => handleProjectChange(+id)}
                    showing={showProsjektIdList}
                    hide={() => setShowProsjektIdList(false)}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container item>
              <Grid item xs={6}>
                {' '}
                <InputLabel sx={classes.InputLabel}>Undersøkelsenr*</InputLabel>
                <TextField
                  error={
                    (surveyId.touched &&
                      !validateNumber(beregningObject?.kostholdData?.surveyId?.value)) ||
                    surveyId.error
                  }
                  helperText={
                    (surveyId.touched &&
                      !validateNumber(beregningObject?.kostholdData?.surveyId?.value)) ||
                    surveyId.error
                      ? surveyId.errorMsg
                      : ''
                  }
                  type="tel"
                  maxRows="1"
                  inputProps={{ min: 1, maxLength: 6 }}
                  value={beregningObject?.kostholdData?.surveyId?.value || ''}
                  onChange={(e) => {
                    //setSurveyId({ ...surveyId, value: +e.target.value })
                    surveyId.value = +e.target.value;
                    updateBeregningObject('kosthold', 'surveyId', surveyId);
                  }}
                  onBlur={
                    (e) => {
                      surveyId.touched = true;
                      updateBeregningObject('kosthold', 'surveyId', surveyId);
                    } //setSurveyId({ ...surveyId, touched: true })
                  }
                  onFocus={
                    (e) => {
                      surveyId.touched = false;
                      surveyId.error = false;
                      updateBeregningObject('kosthold', 'surveyId', surveyId);
                    }
                    //setSurveyId({ ...surveyId, error: false, touched: false })
                  }
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleOnEnterOnSurvey(e);
                    }
                  }}
                  variant="outlined"
                  size="small"
                  required
                  InputProps={{ sx: { input: classes.resize } }}
                  disabled={
                    !beregningObject?.kostholdData?.projectId?.value && !surveyId.error
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <div style={{ paddingTop: 25 }}>
                  <ChatBubbleButton
                    name={'Undersøkelsenr'}
                    showPopup={showUndersokelseIdList}
                    setShowPopup={setShowUndersokelseIdList}
                    isDisabled={beregningObject?.kostholdData?.projectId?.value === 0}
                  />
                </div>
                {showUndersokelseIdList && (
                  <UndersokelseSearch
                    setUndersokelse={(u) => {
                      surveyId.value = u;
                      updateBeregningObject('kosthold', 'surveyId', surveyId);
                    }}
                    projectId={beregningObject?.kostholdData?.projectId?.value}
                    showing={showUndersokelseIdList}
                    hide={() => setShowUndersokelseIdList(false)}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Grid container item>
            <Grid item xs={6}>
              <InputLabel sx={classes.InputLabel}>Personfilter</InputLabel>
              <TextField
                type="text"
                maxRows="1"
                inputProps={{ min: 1, maxLength: 6 }}
                value={personFilterText}
                variant="outlined"
                InputProps={{ sx: { input: classes.resize } }}
                disabled={true}
                size="small"
              />
            </Grid>
            <Grid item xs={6}>
              <div style={{ paddingTop: 25 }}>
                <ChatBubbleButton
                  name={'Personfilter'}
                  showPopup={showPersonfilter}
                  setShowPopup={setShowPersonfilter}
                  isDisabled={beregningObject?.kostholdData?.surveyId?.value === 0}
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container item>
            <Grid item xs={6}>
              <InputLabel sx={classes.InputLabel}>Konsumfilter</InputLabel>
              <TextField
                type="text"
                maxRows="1"
                inputProps={{ min: 1, maxLength: 6 }}
                value={konsumFilterText}
                variant="outlined"
                InputProps={{ sx: { input: classes.resize } }}
                disabled={true}
                size="small"
              />
            </Grid>
            <Grid item xs={6}>
              <div style={{ paddingTop: 25 }}>
                <ChatBubbleButton
                  name={'Konsumfilter'}
                  showPopup={showKonsumfilter}
                  setShowPopup={setShowKonsumfilter}
                  isDisabled={beregningObject?.kostholdData?.surveyId?.value === 0}
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {showPersonfilter && (
        <PersonFilter
          beregnFilterValues={beregnFilterValues}
          showPersonfilter={showPersonfilter}
          persons={beregningObject?.numberOfPersons}
          setFilterParams={setPersonFilterParams}
          showAlertMessage={showAlertMessage}
          activatedOptions={personFilterActivatedOptions}
          setActivatedOptions={setPersonFilterActivatedOptions}
          displayActivatedOptions={displayActivatedOptions}
          personFilterCriteria={personFilterCriteria}
          setPersonFilterCriteria={setPersonFilterCriteria}
          logicalOperator={logicalOperator}
          setLogicalOperator={setLogicalOperator}
          updateBeregningObject={updateBeregningObject}
          hide={() => {
            setShowPersonfilter(!showPersonfilter);
          }}
        />
      )}
      {showKonsumfilter && (
        <KonsumFilter
          beregnFilterValues={beregnFilterValues}
          setFilterParams={setKonsumFilterParams}
          //persons={persons}
          showAlertMessage={showAlertMessage}
          activatedOptions={konsumFilterActivatedOptions}
          setActivatedOptions={setKonsumFilterActivatedOptions}
          showKonsumfilter={showKonsumfilter}
          displayActivatedOptions={displayActivatedOptions}
          konsumFilterCriteria={konsumFilterCriteria}
          setKonsumFilterCriteria={setKonsumFilterCriteria}
          logicalOperator={logicalOperator}
          setLogicalOperator={setLogicalOperator}
          updateBeregningObject={updateBeregningObject}
          hide={() => {
            setShowKonsumfilter(!showKonsumfilter);
          }}
        />
      )}
    </>
  );
};
export default KostholdsData;
