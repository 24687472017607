import React, { useEffect, useState } from 'react';
import { DialogBox } from '../../../../utilities/DialogBox';
import { BeregningObject, MatvareData } from '../../types/BeregningTypes';
import {
  Matvare,
  MatvaregruppeI,
  TableType
} from '../../../matvare/types/matvaregruppeTypes';
import { Grid } from '@mui/material';
import { MatvareTable } from '../../../matvare/matvaregruppe/MatvareTable';
import EnhancedTableToolbar from './EnhancedTableToolbar';
import { MatvaregruppeTableContainer } from '../../../matvare/matvaregruppe/MatvaregruppeTableContainer';
import useFetchData from '../../../../utilities/useFetchData';
import { EmptyMatvareTable } from './EmptyMatvareTable';
import { NButton } from '../../../components/Inputs';
import { addObjectArrayIfNotExists } from '../../../../utilities/ArrayFunctions';

interface FjernMatvarerTablePopupProps {
  showFjernMatvarer: boolean;
  setShowFjernMatvarer: (value: boolean) => void;
  fjerndeMatvareList: Matvare[];
  setFjerndeMatvareList: (mat: Matvare[]) => void;
  setShowProgress: (value: boolean) => void;
  updateBeregningObject: (item: string, name: string, value: any) => void;
  beregningObject?: BeregningObject;
  showAlertMessage: (message: string, severity: any) => void;
  setProgressText: (text: string) => void;
}
interface NavigateLevelI {
  nodeId: number;
  name: string;
  n: number;
}
export const FjernMatvarerTablePopup = (props: FjernMatvarerTablePopupProps) => {
  const {
    showFjernMatvarer,
    setShowFjernMatvarer,
    fjerndeMatvareList,
    setFjerndeMatvareList,
    setShowProgress,
    beregningObject,
    updateBeregningObject,
    showAlertMessage,
    setProgressText
  } = props;
  const [parentsList, setParentsList] = useState<NavigateLevelI[]>([
    { nodeId: 0, name: 'Hovedgrupper', n: 1 }
  ]);
  const [matvarerList, setMatvarerList] = useState<Matvare[]>([]);
  const [selectedMatvares, setSelectedMatvares] = useState<Matvare[]>([]);
  const [selectedMatvaregruppes, setSelectedMatvaregruppes] = useState<MatvaregruppeI[]>(
    []
  );
  const [selectedFjerndeMatvares, setSelectedFjerndeMatvares] = useState<Matvare[]>([]);
  const [showMatvare, setShowMatvare] = useState(false);
  const [parent, setParent] = useState('');
  const [matvaregruppeList, setMatvaregruppeList] = useState<MatvaregruppeI[]>([]);
  const [reloadMatvaregruppe] = useState(false);
  const [nivaa, setNivaa] = useState(1);
  const [linkup, setLinkup] = useState(0);
  const [selectedRow, setSelectedRow] = useState<MatvaregruppeI>();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [showMatvaregroups, setShowMatvaregroups] = useState(false);
  const getMatvareInAGroup = useFetchData({
    loadOnMount: false,
    clearDataOnLoad: false,
    projectURL: 'getFjerndeMatvaresInAGroup',
    subProjectURL: `${encodeURI(beregningObject?.utvalgId || '')}`
  });
  useEffect(() => {
    if (getMatvareInAGroup.data) {
      const matvares: Matvare[] = (getMatvareInAGroup.data as unknown as Matvare[]) || [];
      if (matvares?.length > 0) updateFjernedMatvare(matvares);
    }
  }, [getMatvareInAGroup.data]);
  useEffect(() => {
    setFjerndeMatvareList(fjerndeMatvareList);
    const deepCopyFjerndeMatvareList = JSON.parse(JSON.stringify(fjerndeMatvareList));
    setSelectedFjerndeMatvares(deepCopyFjerndeMatvareList);
  }, [fjerndeMatvareList]);
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };
  const handleOnclickLeggTil = () => {
    if (showMatvaregroups) {
      if (selectedMatvares?.length > 0) {
        updateFjernedMatvare(selectedMatvares);
        setSelectedMatvares([]);
      } else if (selectedRow !== undefined) {
        if (selectedRow?.NODENRID !== 0) {
          getMatvareInAGroup.loadData(
            'getFjerndeMatvaresInAGroup',
            `${encodeURI(beregningObject?.utvalgId || '')}/
        ${beregningObject?.databaseId}/${beregningObject?.matvareData?.gruppetypeId}/${
              selectedRow?.NODENRID
            }`
          );
        }
      }
      setSelectedRow(undefined);
    }
  };
  const updateFjernedMatvare = (matvares: MatvareData[]) => {
    const updatedArray = addObjectArrayIfNotExists(
      matvares,
      fjerndeMatvareList,
      'MATVAREKODEID'
    );
    if (updatedArray?.length === fjerndeMatvareList?.length) {
      console.log('Matvaren(e) er i lista fra før.');
      showAlertMessage('Matvaren(e) er i lista fra før.', 'error');
    } else {
      setFjerndeMatvareList(updatedArray);
      showAlertMessage('Valgte matvare(r) er lagt til fjernde matvarer.', 'success');
    }
  };
  const toggleShowFjernMatvarer = () => {
    updateBeregningObject('matvare', 'excludedMatvares', fjerndeMatvareList);
    setShowFjernMatvarer(!showFjernMatvarer);
  };
  return (
    <DialogBox
      title={'Fjerne matvarer'}
      isShowing={showFjernMatvarer}
      hide={toggleShowFjernMatvarer}
    >
      <Grid container justifyContent="space-between">
        <Grid item xs={12}>
          <EnhancedTableToolbar
            selectedFjerndeMatvares={selectedFjerndeMatvares}
            fjerndeMatvareList={fjerndeMatvareList}
            setFjerndeMatvareList={setFjerndeMatvareList}
            showMatvaregroups={showMatvaregroups}
            setShowMatvaregroups={setShowMatvaregroups}
            setSelectedFjerndeMatvares={setSelectedFjerndeMatvares}
          />
        </Grid>
        <Grid
          container
          xs={12}
          spacing={1}
          direction="row"
          justifyItems="center"
          alignItems="center"
        >
          <Grid container xs={showMatvaregroups ? 5 : 12}>
            <Grid item xs={12}>
              <br />
              <br />
              <br />
              {fjerndeMatvareList?.length > 0 ? (
                <MatvareTable
                  owned={true}
                  matvarerList={fjerndeMatvareList}
                  parent={'Fjerne matvarer'}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  handleChangePage={handleChangePage}
                  tableType={TableType.Fjern}
                  selectedMatvares={selectedFjerndeMatvares}
                  setSelectedMatvares={setSelectedFjerndeMatvares}
                />
              ) : (
                <EmptyMatvareTable />
              )}
            </Grid>
          </Grid>
          {showMatvaregroups && (
            <>
              <Grid item xs={1}>
                <NButton
                  children={'<- Legg til'}
                  onClick={handleOnclickLeggTil}
                  disabled={!(selectedMatvares?.length > 0 || selectedRow !== undefined)}
                />
              </Grid>
              <Grid item xs={5}>
                <MatvaregruppeTableContainer
                  databaseId={beregningObject?.databaseId}
                  gruppetypeId={beregningObject?.matvareData?.gruppetypeId}
                  owned={false}
                  selectedRow={selectedRow}
                  setSelectedRow={setSelectedRow}
                  nivaa={nivaa}
                  linkup={linkup}
                  setNivaa={setNivaa}
                  setLinkup={setLinkup}
                  parent={parent}
                  setParent={setParent}
                  parentsList={parentsList}
                  setParentsList={setParentsList}
                  matvaregruppeList={matvaregruppeList}
                  setMatvaregruppeList={setMatvaregruppeList}
                  matvarerList={matvarerList}
                  setMatvarerList={setMatvarerList}
                  move={true}
                  main={false}
                  tableType={TableType.Fjern}
                  setShowProgress={setShowProgress}
                  reloadMatvaregruppe={reloadMatvaregruppe}
                  showMatvare={showMatvare}
                  setShowMatvare={setShowMatvare}
                  selectedMatvares={selectedMatvares}
                  setSelectedMatvares={setSelectedMatvares}
                  selectedMatvaregruppes={selectedMatvaregruppes}
                  setSelectedMatvaregruppes={setSelectedMatvaregruppes}
                  setProgressText={setProgressText}
                ></MatvaregruppeTableContainer>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </DialogBox>
  );
};
