import React, { useEffect } from 'react';
import { BeregningObject, ReportTwelveI } from '../../types/BeregningTypes';
import useDidMountEffect from '../../../../utilities/useDidMountEffect';
interface ReportTwelveProps {
  beregningObject: BeregningObject;
  setReportData: (value: any[]) => void;
  setReportInfo: (value: Array<Array<string>>) => void;
  setErrorReportId: (value: number) => void;
  result: any;
}
export const Report12Result = (props: ReportTwelveProps) => {
  const [data] = React.useState([]);
  const [info] = React.useState<Array<Array<string>>>([]);
  const { beregningObject, setReportData, setReportInfo, setErrorReportId, result } =
    props;
  useEffect(() => {
    if (result) {
      console.log(' Beregning result.', 'success');
      const data = result as unknown as ReportTwelveI;
      if (
        data?.reportInfo !== undefined &&
        data?.reportInfo?.beregningInfo !== undefined &&
        data?.matvares !== undefined &&
        data?.stoffs !== undefined
      ) {
        prepareResult(data);
      }
    }
  }, [result]);

  const prepareResult = (reportTwelvedata: ReportTwelveI) => {
    let description: string[];
    setErrorReportId(reportTwelvedata?.reportInfo?.reportId);
    for (let r of reportTwelvedata?.reportInfo?.beregningInfo) {
      description = [];
      description.push(r.TEKSTLINJE);
      info.push(description);
    }
    setReportInfo(info);
    let result: any[] = [];
    const shortNames: any[] = [];
    const enhet: any[] = [];
    shortNames.push('Kode');
    shortNames.push('Navn');
    enhet.push('');
    enhet.push('');

    if (reportTwelvedata?.stoffs?.length > 0) {
      shortNames.push(reportTwelvedata?.stoffs[0]?.KORTNAVN);
      enhet.push(reportTwelvedata?.stoffs[0].ENHET);
    }

    data.push(shortNames);
    data.push(enhet);
    for (let mat of reportTwelvedata?.matvares) {
      result = [];
      result.push(mat.MATVAREKODEID);
      result.push(mat.NAVN);
      result.push(mat.MENGDE);
      data.push(result);
    }
    setReportData(data);
  };
  return <></>;
};
