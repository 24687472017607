import React from 'react';
import { rapportOption } from '../../../../assets/beregningRapportOption';
import { FormHelperText, Grid, Paper } from '@mui/material';
import { enhetOption } from '../../../../assets/beregningEnhetOption';
import { BeregningObject, FieldsValues, OptionI } from '../types/BeregningTypes';
import { Dropdown } from '../../components/Inputs';
interface RapportAndEnhetProps {
  beregningObject: BeregningObject;
  setMatvareActive: (value: boolean) => void;
  setStoffActive: (value: boolean) => void;
  updateBeregningObject: (item: string, name: string, value: any) => void;
  cleared: boolean;
}
const RapportAndEnhet = (props: RapportAndEnhetProps) => {
  const {
    beregningObject,
    setMatvareActive,
    setStoffActive,
    updateBeregningObject,
    cleared
  } = props;
  const [enhetId, setEnhetId] = React.useState<FieldsValues>(
    beregningObject?.matvareData?.enhet
  );
  const [filteredEnhetOption, setFilteredEnhetOption] = React.useState<OptionI[]>([]);
  const [selectedEnhet, setSelectedEnhet] = React.useState<OptionI>(enhetOption[0]);

  const handleChangeRapport = (selectedReportId: number) => {
    const repId: FieldsValues = {
      value: selectedReportId,
      touched: false,
      error: false,
      errorMsg: ''
    };
    updateBeregningObject('matvare', 'rapport', repId);
    setFilteredEnhetOption([]);
    filterEnhet(selectedReportId);
    enableOptions(selectedReportId);
  };
  React.useEffect(() => {
    if (
      beregningObject?.matvareData?.enhet?.error &&
      !(
        beregningObject?.matvareData?.rapport?.value === 0 ||
        beregningObject?.matvareData?.rapport?.value === 7 ||
        beregningObject?.matvareData?.rapport?.value === 8 ||
        beregningObject?.matvareData?.rapport?.value === 12
      )
    ) {
      setEnhetId(beregningObject?.matvareData?.enhet);
    }
    handleChangeRapport(beregningObject?.matvareData?.rapport?.value);
  }, [beregningObject?.matvareData?.rapport?.value]);
  React.useEffect(() => {
    if (cleared) {
      beregningObject.matvareData.rapport.value = 0;
      beregningObject.matvareData.enhet.value = 0;
    }
  }, [cleared]);

  const clearMatvareAndStoff = () => {
    updateBeregningObject('matvare', 'individualMatvares', []);
    updateBeregningObject('matvare', 'matvaregroups', []);
    updateBeregningObject('matvare', 'highLevelCategory', 0);
    updateBeregningObject('matvare', 'matvaresInGroup', false);
    updateBeregningObject('matvare', 'enhet', {
      value: 0,
      touched: false,
      error: true,
      errorMsg: 'En enhet må være valgt før du kan starte beregningen'
    });
    updateBeregningObject('stoff', 'individualStoffs', []);
    updateBeregningObject('stoff', 'stoffGroup', []);
    updateBeregningObject('', 'inputsSaved', false);
  };
  const filterEnhet = (id: number) => {
    let enhet: OptionI[] = [];
    if (id === 0 || id === 7 || id === 8 || id === 12) {
      enhet.push(enhetOption[0]);
    } else if (id === 1 || id === 2) {
      enhet.push(enhetOption[1], enhetOption[2], enhetOption[3]);
    } else if (id === 3 || id === 4) {
      enhet.push(
        enhetOption[5],
        enhetOption[6],
        enhetOption[7],
        enhetOption[8],
        enhetOption[10]
      );
    } else if (id === 5) {
      enhet.push(enhetOption[5], enhetOption[6], enhetOption[11]);
    } else if (id === 6) {
      enhet.push(enhetOption[5], enhetOption[11]);
    } else if (id === 9) {
      enhet.push(enhetOption[5]);
    } else if (id === 10) {
      enhet.push(enhetOption[5], enhetOption[7]);
    } else if (id === 11) {
      enhet.push(enhetOption[12]);
    } else {
      enhet.push(enhetOption[0]);
    }
    setFilteredEnhetOption([...enhet]);
    setSelectedEnhet(enhet[0]);
    enhetId.value = +enhet[0]?.value;
    enhetId.error = false;
    updateBeregningObject('matvare', 'enhet', enhetId);
  };
  const enableOptions = (id: number) => {
    if (!id) {
      return;
    }
    if (id === 1 || id === 2 || id === 9) {
      setMatvareActive(true);
      setStoffActive(false);
    } else if (id === 3 || id === 4) {
      setStoffActive(true);
      setMatvareActive(false);
    } else {
      setMatvareActive(true);
      setStoffActive(true);
    }
  };
  const handleEnhetChange = (op: OptionI) => {
    setSelectedEnhet(op);
    enhetId.value = +op?.value;
    enhetId.error = false;
    updateBeregningObject('matvare', 'enhet', enhetId);
  };

  return (
    <Paper style={{ padding: '1.5em 15px 1.5em 0', boxShadow: 'none' }}>
      <Grid container spacing={1} alignItems={'center'}>
        <Grid item xs={7}>
          <Dropdown
            header="Rapport*"
            value={rapportOption?.find(
              (x) => (x.value as number) === beregningObject?.matvareData?.rapport?.value
            )}
            options={rapportOption || []}
            optionLabel={(o) => o.label}
            onChange={(e) => {
              clearMatvareAndStoff();
              handleChangeRapport(+(e as OptionI).value);
            }}
          />
          {beregningObject?.matvareData?.rapport?.error ? (
            <FormHelperText sx={{ color: 'red' }}>{enhetId?.errorMsg}</FormHelperText>
          ) : (
            ''
          )}
        </Grid>
        <Grid item xs={5}>
          <Dropdown
            header="Enhet*"
            defaultValue={selectedEnhet}
            value={
              filteredEnhetOption?.find(
                (x) => (x.value as number) === beregningObject?.matvareData?.enhet?.value
              ) || selectedEnhet
            }
            options={filteredEnhetOption || []}
            optionLabel={(o) => o.label}
            onChange={(e) => handleEnhetChange(e as OptionI)}
            disabled={
              beregningObject?.matvareData?.rapport?.value === 7 ||
              beregningObject?.matvareData?.rapport?.value === 8 ||
              beregningObject?.matvareData?.rapport?.value === 12 ||
              beregningObject?.matvareData?.rapport?.value === 0
            }
          />
          {enhetId?.error ? (
            <FormHelperText sx={{ color: 'red' }}>{enhetId?.errorMsg}</FormHelperText>
          ) : (
            ''
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};
export default RapportAndEnhet;
