import React, { useEffect } from 'react';
import { useKeycloak } from '@react-keycloak/web';
interface slettTillegsvariableProps {
  isShowing: boolean;
  variabelId: number;
  confirmationResult: boolean;
  setConfirmationResult: (value: boolean) => void;
  setProgressBar: (value: boolean) => void;
  //setProgressText: (value: string) => void;
  showAlert: (message: string, severity: any) => void;
  clear: () => void;
  hide: (value: boolean) => void;
}
export const SletteTilleggsvariable = (props: slettTillegsvariableProps) => {
  const { keycloak } = useKeycloak();
  const token = keycloak?.token;
  const backendURI = process.env.REACT_APP_BACKEND;

  //const [showAlert, setShowAlert] = useState(false);
  const {
    variabelId,
    isShowing,
    confirmationResult,
    setConfirmationResult,
    setProgressBar,
    //setProgressText,
    showAlert,
    clear
  } = props;

  useEffect(() => {
    if (confirmationResult) {
      deleteUndersokelse();
      setProgressBar(true);
      //setProgressText('Sletter...');
    }
  }, [confirmationResult]);

  const deleteUndersokelse = () => {
    if (variabelId) {
      const url = backendURI + '/deleteTilleggsvariabel' + '/' + variabelId;
      const method = 'DELETE';
      const data = {};
      postData(url, data, method);
    }
  };
  async function postData(url: string, data: {}, method: string) {
    await fetch(encodeURI(url), {
      method: method,
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      body: JSON.stringify(data)
    })
      .then((res) => res.status)
      .then((result) => {
        console.log('status: ', result);
        setProgressBar(false);
        if (result == 200) {
          showAlert('Slettingen er fullført', 'success');
          navigateAway();
        } else {
          showAlert(
            'Slettingen mislyktes. Ingen sletting er utført mot databasen.',
            'error'
          );
        }
      })
      .catch((err) => {
        console.log(err);
        showAlert(
          'Slettingen mislyktes. Ingen sletting er utført mot databasen.',
          'error'
        );
      });
  }

  const navigateAway = () => {
    const timer = setTimeout(() => {
      clear();
      setConfirmationResult(null);
    }, 800);
    return () => clearTimeout(timer);
  };

  return <></>;
};
