import React, { useContext, useEffect, useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { Grid, Paper, Typography } from '@mui/material';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { AlertI } from '../../../../types/alertTypes';
import useStylesTable from '../../../../css/TableCss';
import PageContainer from '../../home/PageContainer';
import AlertComp from '../../../utilities/AlertComp';
import { AlertWithAction } from '../../../utilities/AlertPopUp';
import { Matvare } from '../types/matvaregruppeTypes';
import { DatabaseContext } from '../../../../context/DatabaseContext';
import PostData from '../../../utilities/postData';
import { ImportMatvareResult } from './ImportTypes';
import { ProgressBar } from '../../../utilities/ProgressBar';
import { GenericImport, NButton } from '../../components/Inputs';
import PollingComponent from '../../components/commons/PollingComponent';

export function ImportMengdeenheter(props: any) {
  const { keycloak } = useKeycloak();
  const token = keycloak?.token;
  const { currentDatabase } = useContext(DatabaseContext);
  const classesTable = useStylesTable();
  const [title] = useState('Import Mengdeenheter - Nutrifoodcalc');
  const [showAlert, setShowAlert] = useState(false);
  const [showAlertWA, setShowAlertWA] = useState(false);
  const [alertMessage, setAlertMessage] = React.useState('');
  const [alertSeverity, setAlertSeverity] = React.useState<AlertI['alertColor']>('info');
  const [fileContent, setFileContent] = React.useState<string>(null);
  const [filename, setFilename] = React.useState('');
  const [showMatvareTable, setShowMatvareTable] = useState(false);
  const [matvares, setMatvares] = useState<Matvare[]>([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [showProgress, setShowProgress] = useState(false);
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [pollingId, setPollingId] = React.useState('');
  const [failed, setFailed] = React.useState(false);
  const [importResult, setImportResult] = React.useState<ImportMatvareResult>(null);
  document.title = title;
  useEffect(() => {
    if (importResult) {
      processImportResult();
    }
  }, [importResult]);
  useEffect(() => {
    if (failed) {
      showAlertMessage('Importering av undersøkelse mislyktes.', 'error');
    }
  }, [failed]);
  const showAlertMessage = (message: string, severity: any) => {
    setShowAlert(true);
    setAlertMessage(message);
    setAlertSeverity(severity);
  };
  const showAlertMessageWA = (message: string, severity: any) => {
    setShowAlertWA(true);
    setAlertMessage(message);
    setAlertSeverity(severity);
  };
  const validateInput = () => {
    let valid = true;
    if (currentDatabase?.value === 0) {
      valid = false;
    }
    return valid;
  };
  const importFile = () => {
    const validInputs = validateInput();
    if (!validInputs) {
      showAlertMessage('Technical error.', 'error');
      return;
    }
    console.log('importing data...', fileContent);
    const dataurl = `${currentDatabase?.value}/${filename}`;
    PostData({ data: fileContent }, 'importMengdeenheter', dataurl, 'POST', token).then(
      (resp: any) => {
        if (resp.status === 200) {
          resp.json().then((data) => {
            //Start pooling backend
            setPollingId(data?.id);
          });
        } else if (resp.status === 500) {
          setShowProgress(false);
          console.log('An error 500', resp.status);
          showAlertMessage('Importering av matvarer mislyktes.', 'error');
          deleteImportedFile();
        }
      }
    );
  };
  const processImportResult = () => {
    // const res = resultData as unknown as ImportMatvareResult;
    setShowProgress(false);
    setMatvares(importResult?.MATVARES);
    if (importResult?.MATVARES?.length > 0) {
      const msg = importResult?.MESSAGE + '. Se matvarene i tabellen nedenfor.';
      setErrorMessage(importResult?.MESSAGE);
      showAlertMessage(msg, 'error');
      setShowMatvareTable(true);
    } else {
      importResult?.SUCCESS
        ? showAlertMessage(importResult?.MESSAGE, 'success')
        : showAlertMessage(importResult?.MESSAGE, 'error');
      setShowMatvareTable(false);
    }
    deleteImportedFile();
  };
  const deleteImportedFile = () => {
    PostData({}, 'deleteImportedMengdeenheter', '', 'DELETE', token).then((resp: any) => {
      if (resp.status === 200) {
        console.log('Deleted the imported file successfully.');
      } else if (resp.status === 500) {
        console.log('An error 500', resp.status);
      }
    });
  };
  const handleImportBtnClick = () => {
    setShowProgress(true);
    importFile();
  };
  const handleNullstillBtn = () => {
    setMatvares([]);
    setShowMatvareTable(false);
  };
  const handleOnClickHelp = () => {
    showAlertMessageWA(
      `Filformat:
       CSV-fil (semikolonseparert), CSV UTF-8(på Mac)
       med følgende felter (* angir obligatorisk felt):\n
       *Matvarekodeid
       *Mengdeenhetid
        Mengde 
        Referanse
        Sortering\n
       Filen skal inneholde en overskriftlinje (også separert med semikolon)
       med navn på de kolonner som er med i filen.
       Rekkefølgen på feltene er likegyldig,
       men overskriftslinjen må stemme overens med datalinjene.\n
       Eksisterende mengdeenheter for matvarer i filen vil bli slettet
       før de nye blir lagt inn.`,
      'info'
    );
  };
  const clear = () => {
    setSelectedFile(null);
  };
  const changeHandler = async (file: File) => {
    setSelectedFile(file);
    console.log('file size...', file?.size);
    setFilename(file?.name);
    if (file) {
      await file
        .text()
        .then((value) => {
          const allLines = value?.split(/\r\n|\n/);
          const firstLine = allLines[0];
          const headers: string[] = firstLine.split(';');
          let idFound = false;
          let meidFound = false;
          for (const header of headers) {
            if (header.toUpperCase() === 'MATVAREKODEID') {
              idFound = true;
            } else if (header.toUpperCase() === 'MENGDEENHETID') {
              meidFound = true;
            }
          }
          if (idFound && meidFound) {
            if (file?.size > 0) {
              console.log(value);
              setFileContent(value);
            }
          } else {
            showAlertMessage(
              'Filen ' +
                file?.name +
                ' er ikke en gyldig mengdeenheter importfil (har ikke matvarekodeid eller mengdeenhetid)!',
              'error'
            );
            setFilename('');
          }
        })
        .catch((err: any) => {
          console.error(err);
        });
    }
  };
  return (
    <PageContainer>
      <Paper style={{ padding: '1.5em 15px 1.5em 15px', boxShadow: 'none' }}>
        <Typography variant="h4" marginBottom="1em">
          Import mengdeenheter
        </Typography>
        <Grid container>
          <Grid item xs={5}>
            <GenericImport
              help={() => handleOnClickHelp()}
              onChange={(f) => changeHandler(f as File)}
              selected={selectedFile?.name}
              accept=".csv"
              actionLabel="prosessere"
            />
          </Grid>
          <Grid item xs={7} />
          <Grid item xs={12} container spacing={1} sx={{ marginTop: 1 }}>
            <Grid item xs={1}>
              <NButton
                children="Utfør"
                disabled={!selectedFile}
                onClick={(e) => handleImportBtnClick()}
              />
            </Grid>
            <Grid item xs={1}>
              <NButton children="Nullstill" variant="outlined" onClick={clear} />
            </Grid>
          </Grid>
        </Grid>
        {showMatvareTable && (
          <Grid container spacing={1} justifyContent="flex-end" alignItems="flex-end">
            <Grid item xs={6}>
              <label>{errorMessage}:</label>
              <Table sx={classesTable.body}>
                <TableHead>
                  <TableRow>
                    <TableCell sx={classesTable.head}>Kode</TableCell>
                    <TableCell sx={classesTable.head}>Navn</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody sx={classesTable.root}>
                  {matvares?.map((row: Matvare) => {
                    return (
                      <TableRow key={row?.MATVAREKODEID} sx={classesTable.tableRow}>
                        <TableCell sx={classesTable.tableRow}>
                          {row?.MATVAREKODEID}
                        </TableCell>
                        <TableCell sx={classesTable.tableRow}>{row?.NAVN}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Grid>
            <Grid item xs={4}>
              <NButton
                children="Nullstill"
                variant="outlined"
                onClick={handleNullstillBtn}
                disabled={false}
              />
            </Grid>
            <Grid item xs={2}></Grid>
          </Grid>
        )}
        {pollingId && (
          <PollingComponent
            id={pollingId}
            setFailed={setFailed}
            setResult={setImportResult}
          />
        )}
        {showAlert && (
          <AlertComp
            alertMessage={alertMessage}
            alertColor={alertSeverity}
            showAlert={showAlert}
            setShowAlert={setShowAlert}
          />
        )}
        {showAlertWA && (
          <AlertWithAction
            alertMessage={alertMessage}
            alertColor={alertSeverity}
            showAlert={showAlertWA}
            setShowAlert={setShowAlertWA}
          />
        )}
        {showProgress && (
          <ProgressBar
            isShowing={showProgress}
            hide={() => {
              setShowProgress(!showProgress);
            }}
          />
        )}
      </Paper>
    </PageContainer>
  );
}
