import {
  Box,
  Checkbox,
  TableCell,
  TableCellProps,
  TableHead,
  TableRow,
  TableSortLabel
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import moment from 'moment';
import React, { ChangeEvent } from 'react';
import { HeadCell } from '../pages/components/commons/CommonTypes';

function comparator<T>(a: T, b: T, order: Order, orderBy: keyof T) {
  // handle date values
  const dateFormat = 'DD.MM.YYYY';
  const dateA = moment(a[orderBy], dateFormat, true);
  const dateB = moment(b[orderBy], dateFormat, true);
  if (dateA.isValid() && dateB.isValid()) {
    if (dateA.toDate() < dateB.toDate()) return 1;
    if (dateA.toDate() > dateB.toDate()) return -1;
    return 0;
  }

  // handle æøå characters
  if (typeof a[orderBy] === 'string' && typeof b[orderBy] === 'string') {
    function getIntArray(c: string) {
      let array = [];
      let lowerC = c.toLowerCase();
      for (let i = 0; i < lowerC.length; i++) {
        if (lowerC.charCodeAt(i) === 230) array.push(297);
        else if (lowerC.charCodeAt(i) === 248) array.push(298);
        else if (lowerC.charCodeAt(i) === 229) array.push(299);
        else array.push(lowerC.charCodeAt(i));
      }
      return array;
    }
    let d = getIntArray(a[orderBy].toString());
    let e = getIntArray(b[orderBy].toString());
    for (let i = 0; i < d.length; i++) {
      if (d[i] !== e[i]) {
        return d[i] - e[i];
      }
    }
  }

  // handle undefined values, sort them last
  if (a[orderBy] === undefined) return order === 'desc' ? 1 : -1;
  if (b[orderBy] === undefined) return order === 'desc' ? -1 : 1;

  // handle non-date, non-string values
  if (b[orderBy] < a[orderBy]) return -1;
  if (b[orderBy] > a[orderBy]) return 1;
  return 0;
}

export function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]?: number | string | Date },
  b: { [key in Key]?: number | string | Date }
) => number {
  return order === 'desc'
    ? (a, b) => comparator(a, b, 'desc', orderBy)
    : (a, b) => -comparator(a, b, 'asc', orderBy);
}

export type Order = 'asc' | 'desc';

interface SortProps<T> {
  order: Order;
  orderBy: string;
  onRequestSort: (property: keyof T) => void;
  sortColumns?: Extract<keyof T, string>[];
}

interface CheckboxProps {
  numSelected: number;
  rowCount: number;
  onSelectAllClick: (event: ChangeEvent<HTMLInputElement>, arg1: any) => void;
}

interface AdditionalColumns {
  name: string;
  padding?: TableCellProps['padding'];
  align?: TableCellProps['align'];
  sx?: TableCellProps['sx'];
}

interface EnhancedTableHeadProps<T> {
  headCells: HeadCell<T>[];
  leftColumns?: AdditionalColumns[];
  rightColumns?: AdditionalColumns[];
  sorting?: SortProps<T>;
  checkbox?: CheckboxProps;
}

export function EnhancedTableHead<T>(props: EnhancedTableHeadProps<T>) {
  const { headCells, leftColumns, rightColumns, sorting, checkbox } = props;

  return (
    <TableHead>
      <TableRow>
        {checkbox && (
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={
                checkbox.numSelected > 0 && checkbox.numSelected < checkbox.rowCount
              }
              checked={
                checkbox.rowCount > 0 && checkbox.numSelected === checkbox.rowCount
              }
              onChange={checkbox.onSelectAllClick}
              inputProps={{
                'aria-label': 'select all'
              }}
            />
          </TableCell>
        )}
        {leftColumns &&
          leftColumns.map((column) => (
            <TableCell
              key={column.name}
              padding={column.padding}
              align={column.align}
              sx={column.sx}
            >
              {column.name}
            </TableCell>
          ))}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            variant="head"
            align={headCell.align}
            padding={headCell.padding}
            // sx={{ ...headCell.sx, minWidth: `${headCell.width}px !important` }}
            sx={headCell.sx}
          >
            {sorting &&
            (!sorting.sortColumns || sorting.sortColumns?.includes(headCell.id)) ? (
              <TableSortLabel
                active={sorting.orderBy === headCell.id}
                direction={sorting.orderBy === headCell.id ? sorting.order : 'asc'}
                onClick={() => sorting.onRequestSort(headCell.id)}
              >
                {headCell.label}
                {sorting.orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {sorting.order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
        {rightColumns &&
          rightColumns.map((column) => (
            <TableCell
              key={column.name}
              padding={column.padding}
              align={column.align}
              sx={column.sx}
            >
              {column.name}
            </TableCell>
          ))}
      </TableRow>
    </TableHead>
  );
}
