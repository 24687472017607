import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import useStylesTable from '../../../../css/TableCss';

import {
  /*   FormControl,
  FormControlLabel,
  Radio,
  RadioGroup, */
  TablePagination,
  Typography
} from '@mui/material';
const EmptyTable = () => {
  const classesTable = useStylesTable();
  //const classes = useStyles();

  return (
    <>
      <Typography>Undersøkelser</Typography>
      <Table sx={classesTable.head}>
        <TableHead>
          <TableRow>
            <TableCell>Velg</TableCell>
            <TableCell>Nummer</TableCell>
            <TableCell>Kortnavn</TableCell>
            <TableCell>MatvDB</TableCell>
            <TableCell>Antall pers.</TableCell>
          </TableRow>
        </TableHead>
        <TableBody></TableBody>
      </Table>
      <TablePagination
        //sx={classesTable.Pagination}
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count={0}
        rowsPerPage={5}
        page={0}
        onPageChange={() => {}}
        onRowsPerPageChange={() => {}}
      />
    </>
  );
};
export default EmptyTable;
