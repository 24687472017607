import { UndersokelseI } from '../types/undersokelseTypes';
import {
  SET_UNDERSOKELSE_ID,
  SET_UKORT_NAVN,
  UndersokelseActionTypes
} from '../types/actions';

const undersokelseReducerDefaultState: UndersokelseI = {
  undersokelseId: 0,
  uKortNavn: null
};

export const undersokelseReducer = (
  state = undersokelseReducerDefaultState,
  action: UndersokelseActionTypes
): UndersokelseI => {
  switch (action.type) {
    case SET_UNDERSOKELSE_ID:
      return { ...state, undersokelseId: action.undersokelseId };
    case SET_UKORT_NAVN:
      return { ...state, uKortNavn: action.uKortNavn };
    default:
      return state;
  }
};
