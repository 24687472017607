import React, {
  ChangeEvent,
  Dispatch,
  MouseEvent,
  SetStateAction,
  useEffect,
  useState
} from 'react';
import {
  Table,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  TableContainer,
  Box,
  Typography,
  Paper,
  Stack,
  CircularProgress,
  Tooltip
} from '@mui/material';
import { StoffgruppeI, StoffI } from '../../../stoff/types/StoffTypes';
import useFetchData from '../../../../utilities/useFetchData';
import DialogGeneral from '../../../../utilities/DialogGeneral';
import { MonoSearchField, NButton } from '../../../components/Inputs';
import { HeadCell, TabI } from '../../../components/commons/CommonTypes';
import TabComponent from '../../../../utilities/TabComponent';
import { UtvalgsId } from '../../../beregning/types/BeregningTypes';
import { StoffnMengdeI } from '../../types/PersonTypes';
import PostData from '../../../../utilities/postData';
import { useKeycloak } from '@react-keycloak/web';
import { EnhancedTableHead } from '../../../../utilities/EnhancedTableHead';
import { ApiCall } from '../../../../utilities/ApiCall';

const HeadCellDetail: HeadCell<StoffI>[] = [
  {
    id: 'sortering',
    align: 'right',
    padding: 'normal',
    label: 'Sortering'
  },
  {
    id: 'stoffnrId',
    align: 'right',
    padding: 'normal',
    label: 'Stoffnr.'
  },
  {
    id: 'kortnavn',
    align: 'left',
    padding: 'normal',
    label: 'Kortnavn'
  },
  {
    id: 'navn',
    align: 'left',
    padding: 'normal',
    label: 'Navn'
  },
  {
    id: 'regnbart',
    align: 'left',
    padding: 'normal',
    label: 'Regnbart'
  }
];

const HeadCellGruppe: HeadCell<StoffgruppeI>[] = [
  {
    id: 'navn',
    align: 'left',
    padding: 'normal',
    label: 'Navn'
  },
  {
    id: 'beskrivelse',
    align: 'left',
    padding: 'normal',
    label: 'Beskrivelse'
  },
  {
    id: 'antall',
    align: 'right',
    padding: 'normal',
    label: 'Antall'
  }
];

interface StoffTableProps {
  open: boolean;
  hide: () => void;
  databaseId: number;
  utvalgId: UtvalgsId;
  utvalgData: StoffnMengdeI[];
  setUtvalgData: Dispatch<SetStateAction<StoffnMengdeI[]>>;
  stoffgrupperTemp: number[];
  setStoffgrupperTemp: Dispatch<SetStateAction<number[]>>;
}

export default function LopedagStoffgrupperModal(props: StoffTableProps) {
  const {
    open,
    hide,
    databaseId,
    utvalgId,
    utvalgData,
    setUtvalgData,
    stoffgrupperTemp,
    setStoffgrupperTemp
  } = props;

  const { keycloak } = useKeycloak();
  const token = keycloak?.token;
  const [stoffDetailData, setStoffDetailData] = useState<StoffI[]>([]);
  const [stoffGrupperData, setStoffGrupperData] = useState<StoffgruppeI[]>([]);
  const [selectedDetail, setSelectedDetail] = useState<number[]>([]);
  const [selectedGrupper, setSelectedGrupper] = useState<number[]>([]);
  const [activeTab, setActiveTab] = useState<number>(0);
  const [utvalgDataCopy, setUtvalgDataCopy] = useState<StoffnMengdeI[]>();
  const tabArray: TabI[] = [
    { label: 'Enkeltstoffer', idx: 0, tabType: 'stoffDetailData' },
    { label: 'Stoffgrupper', idx: 1, tabType: 'stoffGrupperData' }
  ];
  const [fetchingData, setFetchingData] = useState<boolean>(false);
  const [finishedLoading, setFinishedLoading] = useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(false);

  useEffect(() => {
    if (utvalgData?.length > 0) {
      const selectedIds = utvalgData.map((u) => u.stoffnrId);
      setSelectedDetail(selectedIds);
      if (stoffgrupperTemp?.length > 0) {
        setSelectedGrupper(stoffgrupperTemp);
      }
    }
    setStoffgrupperTemp([]);
  }, [utvalgData]);

  // STOFF DETAIL
  const getStoffDetail = useFetchData({
    loadOnMount: false,
    clearDataOnLoad: false,
    projectURL: 'getStoffDetail',
    subProjectURL: null
  });

  const getStoffGroups = useFetchData({
    loadOnMount: false,
    clearDataOnLoad: false,
    projectURL: 'getStoffGroups',
    subProjectURL: null
  });

  useEffect(() => {
    getStoffDetail.loadData(`getAllStoffs`, `${databaseId}/0`);
    getStoffGroups.loadData(`getStoffGroups`, `${databaseId}/all`);
  }, [databaseId]);

  useEffect(() => {
    if (getStoffDetail.data) {
      const data: StoffI[] = getStoffDetail.data as unknown as StoffI[];
      setStoffDetailData(data);
      setReload(false);
    }
  }, [getStoffDetail.data, reload]);

  useEffect(() => {
    if (getStoffGroups.data) {
      const data: StoffgruppeI[] = getStoffGroups.data as unknown as StoffgruppeI[];
      setStoffGrupperData(data);
    }
  }, [getStoffGroups.data]);

  function handleOk() {
    setFetchingData(true);
    saveStoffDetail(selectedDetail);
    setStoffgrupperTemp(selectedGrupper);
    setFinishedLoading(true);
  }

  // SAVE STOFF
  const saveStoffDetail = (stoffs: number[]) => {
    console.log('stoffs', stoffs);
    const dataurl = `${encodeURI(utvalgId?.ID)}`;
    const data = stoffs?.filter((s) => s).map((s) => ({ stoffnrId: s }));
    console.log('data', data);
    PostData({ data: data }, 'saveStoffsInBU', dataurl, 'POST', token).then(
      (resp: Response) => {
        if (resp.status === 200) {
          getMaalBeregningStoffs();
          console.log('Successfully saved stoffs');
        } else if (resp.status === 500) {
          console.log('error:', resp.status);
        }
      }
    );
  };

  const saveStoffGroup = async (stoffGroupIds: number[]) => {
    const dataurl = `${encodeURI(utvalgId.ID)}/${databaseId}`;
    await PostData(
      { data: stoffGroupIds },
      'saveStoffGroupsInBU',
      dataurl,
      'POST',
      token
    ).then((resp: Response) => {
      if (resp.status === 200) {
        getMaalBeregningStoffs();
        console.log('Successfully saved stoffs');
      } else if (resp.status === 500) {
        console.log('An error 500', resp.status);
      }
    });
  };

  const getMaalBeregningStoffs = async () => {
    const response = await ApiCall(
      `getMaalBeregningStoffs/${databaseId}/${utvalgId?.ID}`,
      'GET',
      keycloak.token
    );
    if (response?.ok) {
      const data = await response?.json();
      setUtvalgData(data);
      setUtvalgDataCopy(data);
      setFetchingData(false);
    } else {
      console.error('error:', response?.status);
    }
  };

  useEffect(() => {
    //I removed && utvalgData.length > 0 from the following condition to accomodate when response of getMaalBeregningStoffs is empty
    //this can happen when a stoff group that doesn have stoffs is chosen. But I dont know how it can affect the normal process
    if (finishedLoading) {
      hide();
    }
    setFinishedLoading(false);
  }, [finishedLoading]);

  // SEARCH
  const searchStoffById = (id?: string) => {
    if (id) {
      const searchResults = stoffDetailData?.filter((stoff) =>
        stoff.stoffnrId?.toString().includes(id)
      );
      setStoffDetailData(searchResults);
    } else {
      setReload(true);
    }
  };

  const searchStoffByName = (name?: string) => {
    if (name) {
      setStoffDetailData(
        stoffDetailData?.filter((stoff) =>
          stoff.kortnavn?.toString().toLowerCase().includes(name.toString().toLowerCase())
        )
      );
    } else {
      setReload(true);
    }
  };

  // HANDLING
  const handleSelectAllClick = (
    event: ChangeEvent<HTMLInputElement>,
    stoffType: 'detail' | 'group'
  ) => {
    if (stoffType === 'detail') {
      if (event.target.checked) {
        setSelectedDetail(stoffDetailData?.map((d) => d.stoffnrId));
      } else {
        setSelectedDetail([]);
      }
    } else if (stoffType === 'group') {
      if (event.target.checked) {
        setSelectedGrupper(stoffGrupperData?.map((g) => g.stoffgruppeId));
      } else {
        setSelectedGrupper([]);
      }
    }
  };

  const handleClick = (
    event: MouseEvent<unknown>,
    id: number,
    stoffType: 'detail' | 'group'
  ) => {
    if (stoffType === 'detail') {
      let newSelected: number[] = [];
      if (!selectedDetail.includes(id)) {
        newSelected = [...selectedDetail, id];
      } else {
        newSelected = selectedDetail.filter((item) => item !== id);
      }
      setSelectedDetail(newSelected);
    }
    if (stoffType === 'group') {
      let newSelected: number[] = [];
      if (!selectedGrupper.includes(id)) {
        newSelected = [...selectedGrupper, id];
      } else {
        newSelected = selectedGrupper.filter((item) => item !== id);
        setSelectedDetail([]);
      }
      setSelectedGrupper(newSelected);
      saveStoffGroup(newSelected);
    }
  };

  return (
    <DialogGeneral
      title={'Liste over stoffer og stoffgrupper'}
      open={open}
      hide={hide}
      fullWidth
      maxWidth="lg"
    >
      {fetchingData ? (
        <Box sx={{ display: 'grid', minHeight: '45rem', placeItems: 'center' }}>
          <CircularProgress />
        </Box>
      ) : (
        <TabComponent
          tabArray={tabArray}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          panel={(tabType: string) => (
            <Stack spacing={2}>
              <Stack direction={'row'} alignItems="end" spacing={2} useFlexGap>
                <Box>
                  <MonoSearchField
                    header="Søk etter stoffnummer"
                    searchFn={(n) => searchStoffById(n)}
                  />
                </Box>
                <Box>
                  <MonoSearchField
                    header="Søk etter kortnavn"
                    searchFn={(n) => searchStoffByName(n)}
                  />
                </Box>
                <Typography marginLeft={'auto'}>
                  Antall valgte enkeltstoffer: {selectedDetail?.length}
                </Typography>
              </Stack>
              {tabType === 'stoffDetailData' ? (
                <Stack spacing={2}>
                  <TableContainer component={Paper} sx={{ maxHeight: '55vh' }}>
                    <Table size="small">
                      <EnhancedTableHead<StoffI>
                        headCells={HeadCellDetail}
                        checkbox={{
                          onSelectAllClick: (event) =>
                            handleSelectAllClick(event, 'detail'),
                          numSelected: selectedDetail.length,
                          rowCount: stoffDetailData?.length
                        }}
                      />
                      <TableBody>
                        {stoffDetailData?.map((row: StoffI) => (
                          <TableRow
                            key={row.stoffnrId}
                            hover
                            onClick={(event) => {
                              handleClick(event, row.stoffnrId, 'detail');
                            }}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                color="primary"
                                checked={selectedDetail?.includes(row.stoffnrId)}
                              />
                            </TableCell>
                            {HeadCellDetail.map((cell) => (
                              <TableCell
                                key={cell.id}
                                align={cell.align}
                                padding={cell.padding}
                              >
                                {row[cell.id]}
                              </TableCell>
                            ))}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Stack>
              ) : (
                <Stack spacing={2}>
                  <TableContainer component={Paper} sx={{ height: '55vh' }}>
                    <Table size="small">
                      <EnhancedTableHead<StoffgruppeI>
                        headCells={HeadCellGruppe}
                        checkbox={{
                          onSelectAllClick: (event) =>
                            handleSelectAllClick(event, 'group'),
                          numSelected: selectedGrupper.length,
                          rowCount: stoffGrupperData?.length
                        }}
                      />
                      <TableBody>
                        {stoffGrupperData?.map((row: StoffgruppeI) => (
                          <TableRow
                            key={row.stoffgruppeId}
                            hover
                            onClick={
                              row.antall > 0
                                ? (event) => {
                                    handleClick(event, row.stoffgruppeId, 'group');
                                  }
                                : undefined
                            }
                            sx={{
                              backgroundColor: `${row.antall > 0 ? '#FFFFFF' : '#F1F1F1'}`
                            }}
                          >
                            <TableCell padding="checkbox">
                              <Tooltip
                                title={row.antall > 0 ? '' : 'Ingen stoffer i gruppe'}
                              >
                                <span>
                                  <Checkbox
                                    color="primary"
                                    checked={selectedGrupper.includes(row.stoffgruppeId)}
                                    indeterminate={
                                      selectedGrupper.length === 1 &&
                                      selectedGrupper.includes(row.stoffgruppeId) &&
                                      utvalgDataCopy?.filter(
                                        (v) =>
                                          !selectedDetail?.find((u) => u === v.stoffnrId)
                                      ).length > 0
                                    }
                                    disabled={row.antall === 0}
                                  />
                                </span>
                              </Tooltip>
                            </TableCell>
                            {HeadCellGruppe.map((cell) => (
                              <TableCell
                                key={cell.id}
                                padding={cell.padding}
                                align={cell.align}
                              >
                                {row[cell.id]}
                              </TableCell>
                            ))}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Stack>
              )}
              <Stack direction={'row'} justifyContent="space-between">
                <NButton children="Ok" onClick={handleOk} />
                <NButton
                  children="Nullstill valgte stoffer og grupper"
                  variant="outlined"
                  onClick={() => {
                    setSelectedDetail([]);
                    setSelectedGrupper([]);
                  }}
                  disabled={selectedDetail?.length === 0 && selectedGrupper.length === 0}
                />
              </Stack>
            </Stack>
          )}
        />
      )}
    </DialogGeneral>
  );
}
