import React, { useEffect, useState } from 'react';
import DialogComponent from '../../../utilities/DialogComponent';
import PostData from '../../../utilities/postData';
import { MatvaregruppeI } from '../types/matvaregruppeTypes';
interface DeleteProps {
  token: string;
  database: number;
  gruppeTypeId: number;
  selectedMatvaregruppe: MatvaregruppeI;
  setVariablesAndReload: (nivaa: number, linkup: number) => void;
  showAlertComponent: (message: string, severity: any) => void;
  open: boolean;
  close: () => void;
  cleanMatvaregruppeFromReduxStore: (nodeId: number, hNodeId: number) => void;
}
export default function DeleteMatvaregruppe(deleteProps: DeleteProps) {
  const {
    token,
    database,
    gruppeTypeId,
    selectedMatvaregruppe,
    setVariablesAndReload,
    showAlertComponent,
    open,
    close,
    cleanMatvaregruppeFromReduxStore
  } = deleteProps;
  const [title, setTitle] = useState('Slett matvaregruppe');
  const [message, setMesssage] = useState('');

  useEffect(() => {
    if (selectedMatvaregruppe?.LINKUP === 0) {
      setTitle('Slett hovedgruppe');
    }
    if (selectedMatvaregruppe?.KORTNAVN === 'UGRUPP') {
      close();
      showAlertComponent(
        "Matvaregruppen 'Ugruppert - UGRUPP' kan ikke slettes.",
        'error'
      );
    } else if (selectedMatvaregruppe?.HARUNDERGRUPP) {
      close();
      showAlertComponent(
        'Hovedgruppen inneholder underliggende informasjon. Sletting er ikke lov.',
        'error'
      );
      //TODO: here we have to make sure that the matavregruppe doenst have matvare in the nested group before letting delete happen
      //TODO temporarily we will not allow delete at all if it has under group
      /*  setMesssage(
        'Hovedgruppen inneholder underliggende informasjon. Vil du virkelig slette denne hovedgruppen?'
      ); */
    } else if (selectedMatvaregruppe?.HARMATVARE) {
      close();
      showAlertComponent(
        'Hovedgruppen inneholder matvarer. Sletting er ikke lov.',
        'error'
      );
    } else {
      setMesssage('Vil du virkelig slette den markerte hovedgruppen?');
    }
  }, [selectedMatvaregruppe]);

  const deleteMatvaregruppe = () => {
    close();
    const dataurl = `${database}/${gruppeTypeId}/${selectedMatvaregruppe?.NODENRID}`;
    PostData({}, 'deleteMatvaregruppe', dataurl, 'DELETE', token).then((resp: any) => {
      if (resp.status === 200) {
        cleanMatvaregruppeFromReduxStore(selectedMatvaregruppe?.LINKUP, 0);
        showAlertComponent('Slettingen er fullført', 'success');
        setVariablesAndReload(
          selectedMatvaregruppe?.NIVAA,
          selectedMatvaregruppe?.LINKUP
        );
      } else if (resp.status === 500) {
        console.log('An error 500', resp);
        const msg =
          resp.error || 'Slettingen mislyktes. Ingen sletting er utført mot databasen.';
        showAlertComponent(msg, 'error');
      }
    });
  };
  const handleClickJa = () => {
    deleteMatvaregruppe();
  };
  const handleClickNei = () => {
    close();
  };

  return (
    <DialogComponent
      title={title}
      message={message}
      open={open}
      close={close}
      handleClickJa={handleClickJa}
      handleClickNei={handleClickNei}
    />
  );
}
