import {
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Select,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import DialogGeneral from '../../../../utilities/DialogGeneral';
import { NButton } from '../../../components/Inputs';
import { PersonIUndersokelse } from '../../types/UndersokelseTypes';
import HelpIcon from '@mui/icons-material/Help';

type Property =
  | 'personStatus'
  | 'skjemaStatus'
  | 'status1'
  | 'status2'
  | 'tilbakemeldingsSkjema';

interface Filter {
  property: Property;
  values: string[];
}

interface PersonerFilterModalProps {
  showing: boolean;
  hide: () => void;
  selectedStatuses: Filter[];
  setSelectedStatuses: Dispatch<SetStateAction<Filter[]>>;
  allStatuses: Filter[];
  foundStatuses: Filter[];
  setFoundStatuses: Dispatch<SetStateAction<Filter[]>>;
  allIntervjuere: string[];
  selectedIntervjuer: string;
  setSelectedIntervjuer: Dispatch<SetStateAction<string>>;
  bareDeltakere: boolean;
  setBareDeltakere: Dispatch<SetStateAction<boolean>>;
  statusNames: Property[];
  displayNames: string[];
  setUpdateData: Dispatch<SetStateAction<boolean>>;

  // handleHent: () => void;
  //   statuses: any;
  // handleChange: any;
}

export default function PersonerFilterModal(props: PersonerFilterModalProps) {
  const {
    showing,
    hide,
    selectedStatuses,
    setSelectedStatuses,
    allStatuses,
    foundStatuses,
    statusNames,
    displayNames,
    selectedIntervjuer,
    setSelectedIntervjuer,
    bareDeltakere,
    setBareDeltakere,
    allIntervjuere,
    setUpdateData,
    setFoundStatuses
  } = props;
  const [choiceErrorArray, setChoiceErrorArray] = useState<string[]>([]);
  const [tempSelectedStatuses, setTempSelectedStatuses] =
    useState<Filter[]>(selectedStatuses);
  const [tempSelectedIntervjuer, setTempSelectedIntervjuer] =
    useState<string>(selectedIntervjuer);
  const [tempBareDeltakere, setTempBareDeltakere] = useState<boolean>(bareDeltakere);
  const notDeltakerStatuses = ['Deltar ikke', 'Ikke påbegynt', 'Trukket seg'];

  useEffect(() => {
    if (tempSelectedStatuses?.some((status: Filter) => status.values.length === 0)) {
      setChoiceErrorArray(
        tempSelectedStatuses
          .filter((status: Filter) => status.values.length === 0)
          ?.map((status: Filter) => status.property)
      );
    } else {
      setChoiceErrorArray([]);
    }
  }, [tempSelectedStatuses]);

  function handleBareDeltakere() {
    if (!tempBareDeltakere) {
      const newValues = tempSelectedStatuses[0]?.values.filter(
        (v) => !notDeltakerStatuses.includes(v)
      );

      setTempSelectedStatuses((prev) =>
        prev.map((s) =>
          s.property === 'personStatus' ? { property: s.property, values: newValues } : s
        )
      );

      // const newFoundValues = foundStatuses[0]?.values.filter(
      //   (v) => !notDeltakerStatuses.includes(v)
      // );

      // setFoundStatuses((prev) =>
      //   prev.filter((s) =>
      //     s.property === 'personStatus'
      //       ? { property: s.property, values: newFoundValues }
      //       : s
      //   )
      // );
    }
    setTempBareDeltakere(!tempBareDeltakere);
  }

  function handleChange(name: Property, field: string) {
    const values = tempSelectedStatuses?.find(
      (status: Filter) => status.property === name
    )?.values;
    let newValues = [];

    if (values.includes(field)) {
      newValues = values.filter((value) => value !== field);
      if (
        newValues.every((n) => !notDeltakerStatuses.includes(n)) &&
        name === 'personStatus'
      ) {
        setTempBareDeltakere(true);
      }
    } else {
      newValues = [...values, field];
      if (notDeltakerStatuses.includes(field) && name === 'personStatus') {
        setTempBareDeltakere(false);
      }
    }

    setTempSelectedStatuses((prev: Filter[]) => [
      ...prev.map((item: Filter) =>
        item.property === name ? { property: name, values: newValues } : item
      )
    ]);
  }

  function handleSelectAll(name: Property) {
    const foundValues = foundStatuses?.find(
      (status: Filter) => status.property === name
    )?.values;
    const isChecked = foundValues?.every((v: string) =>
      tempSelectedStatuses
        ?.find((status: Filter) => status.property === name)
        ?.values.includes(v)
    );

    if (isChecked) {
      setTempSelectedStatuses((prev: Filter[]) => [
        ...prev.map((item: Filter) =>
          item.property === name ? { property: name, values: [] } : item
        )
      ]);
    } else {
      setTempSelectedStatuses((prev: Filter[]) => [
        ...prev.map((item: Filter) =>
          item.property === name ? { property: name, values: foundValues } : item
        )
      ]);
    }
  }

  return (
    <DialogGeneral title="Filtrering" open={showing} hide={hide} fullWidth maxWidth="lg">
      <Stack spacing={2} divider={<Divider orientation="horizontal" flexItem />}>
        <Stack direction="row" spacing={1} alignItems="end" useFlexGap>
          <FormControl>
            <Typography variant="h6" gutterBottom>
              Intervjuer
            </Typography>
            <Select
              value={tempSelectedIntervjuer}
              onChange={(event) => setTempSelectedIntervjuer(event.target.value)}
              size="small"
              sx={{ width: '15rem' }}
            >
              {allIntervjuere.length !== 0 ? (
                allIntervjuere.map((intervjuer, index) => (
                  <MenuItem key={index} value={intervjuer}>
                    {intervjuer}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>
                  Det finnes ingen intervjuere i denne undersøkelsen
                </MenuItem>
              )}
            </Select>
          </FormControl>
          <FormGroup>
            <Stack direction="row" alignItems="center" spacing={2}>
              <FormControlLabel
                control={
                  <Checkbox checked={tempBareDeltakere} onChange={handleBareDeltakere} />
                }
                label="Bare deltagere"
              />
              <Tooltip title="Fjern 'Deltar ikke', 'Ikke påbegynt', og 'Trukket seg' fra Personstatus">
                <HelpIcon fontSize="small" color="info" />
              </Tooltip>
            </Stack>
          </FormGroup>
          <Stack direction={'row'} spacing={2} marginLeft={'auto'}>
            {choiceErrorArray.length > 0 && (
              <Typography variant="caption" color="error">
                Et eller flere valg mangler
              </Typography>
            )}
            <NButton
              onClick={() => {
                setSelectedStatuses(tempSelectedStatuses);
                setSelectedIntervjuer(tempSelectedIntervjuer);
                setBareDeltakere(tempBareDeltakere);
                setUpdateData(true);
                hide();
              }}
              disabled={choiceErrorArray?.length > 0}
            >
              Hent
            </NButton>
          </Stack>
        </Stack>

        {/* Status */}
        {statusNames.map((name: Property, index) => (
          <Stack key={name}>
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography variant="h6">{displayNames[index]}</Typography>

              {/* Velg alle */}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={foundStatuses[index]?.values.every((v: string) =>
                      tempSelectedStatuses[index]?.values.includes(v)
                    )}
                    onChange={() => handleSelectAll(name)}
                  />
                }
                label="Velg alle"
              />
              {choiceErrorArray.includes(name) && (
                <Typography variant="caption" color="error">
                  Vennligst velg minst en
                </Typography>
              )}
            </Stack>

            {/* Velg statuser */}
            <Grid container>
              <FormControl fullWidth>
                <FormGroup row>
                  {allStatuses[index]?.values.map((field: string) => (
                    <Grid item xs={3} key={field}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              tempSelectedStatuses[index]?.values.includes(field) &&
                              foundStatuses[index]?.values.includes(field)
                            }
                            onChange={(event) => handleChange(name, field)}
                            name={field}
                            disabled={!foundStatuses[index]?.values.includes(field)}
                          />
                        }
                        label={field}
                      />
                    </Grid>
                  ))}
                </FormGroup>
              </FormControl>
            </Grid>
          </Stack>
        ))}
        {/* Hent  */}
        <Stack direction={'row'} spacing={2}>
          <NButton
            onClick={() => {
              setSelectedStatuses(tempSelectedStatuses);
              setSelectedIntervjuer(tempSelectedIntervjuer);
              setBareDeltakere(tempBareDeltakere);
              setUpdateData(true);
              hide();
            }}
            disabled={choiceErrorArray?.length > 0}
          >
            Hent
          </NButton>
          {choiceErrorArray.length > 0 && (
            <Typography variant="caption" color="error">
              Et eller flere valg mangler
            </Typography>
          )}
        </Stack>
      </Stack>
    </DialogGeneral>
  );
}
