import React, { useEffect } from 'react';
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  Radio,
  RadioGroup,
  TextField
} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { ConfirmationDialog } from '../../utilities/ConfirmationDialog';
import { DialogBox } from '../../utilities/DialogBox';
import useFetchData from '../../utilities/useFetchData';
import IsOwnedByUser from '../../utilities/Validate';
import { CountResult } from '../kosthold/types/ProjectTypes';
import useDidMountEffect from '../../utilities/useDidMountEffect';
import { useUserContext } from '../../../context/AuthContext';
import { NButton } from '../components/Inputs';

interface CopyMatvareProps {
  token: string;
  openCopyMatvare: boolean;
  handleClickCopy: () => void;
  matvarekodeId: number;
  currentDatabase: any; //To Do.
  matvareNavn: string;
  showProgress: boolean;
  setShowProgress: (value: boolean) => void; //To Do.
  showAlertComponent: (message: string, severity: any) => void;
  setOpenKopiMatvareDiv?: (value: boolean) => void;
  navigateToMatvare: (matvarekode: number) => void;
}
const style = {
  width: 800,
  height: 600
};

/*
toDo: understand the nRollback function in KBS

for this component to work according to KBS:
  1. Count number of Gruppetypes in Matvaregruppe.
    1.1 If count < 1 then "Det eksisterer ingen matvaregrupper der du kan plassere matvaren."
        or "there are no food groups you can palce the food in."
  2. Then check if "Sjekker at matvarekode og navn er utfylt" -> Kode and Name must be filled
    2.1 "Matvarekode og navn m� v�re utfylt." Message
  3. Then ' Sjekker at matvaren ikke eksisterer fra f�r. Check if this Matvare exists allready.
    3.1 if count > 0. Det er ikke lov til å kopiere en matvare til en eksisterende matvare. Du må endre kode eller navn.  
    3.2 if count = 0. We can copy
      Vil du virkelig kopiere matvaren " & Noquote(@dfnGmlMatvare) & "," & Noquote(@dfstrGmlNavn) & " til matvaren 
      " & Noquote(@dfnMatvarekodeid) & "," & Noquote(@dfstrNavn)"
      "Should this be an Alert or inside the popup?"
    3.3 Message("Kopierer matvare...")
    3.4 Insert into Matvare the copied matvare...
    3.5 Figure out what nRollback = 0 is in KBS
  4. Go through copying steps. Define these later.
    4.1 Count from Gruppeelement
      4.1.1 If count = 0 Insert something into gruppeelement
      4.1.2 If count > 0 Insert something else into gruppeelemnt
  5. Copy all underlaying data. "Kopi av matvare med all underliggende informasjon" Radio button.
    5.1 if rbUnderliggende 1 or true. Insert into Erstatning
    5.2 insert into Omregningsforhold
    5.3 insert into Oppskriftlinje
    5.4 insert into stoffkomponenter
    5.5 If rbUnderliggende 0 or false ? what then? no code found.
  6. If rbUnderliggende = 1 Then 
    6.1 Kopiert all info fra matvare " & @dfnGmlMatvare & " " & @dfstrGmlNavn
    6.2 Kopiert fra matvare " & @dfnGmlMatvare & " " & @dfstrGmlNavn
    6.3 Insert Into kbs_matvare_log Not sure what this is. Some logging I need to check.
  7. If successfull, "Kopieringen er fullf�rt")
    7.1 If it fails Kopieringen mislyktes. Det har ikke blitt gjort endringer mot databasen."

*/

export default function CopyMatvare(props: CopyMatvareProps) {
  const {
    openCopyMatvare,
    handleClickCopy,
    matvarekodeId,
    matvareNavn,
    currentDatabase,
    setShowProgress,
    setOpenKopiMatvareDiv,
    showAlertComponent,
    navigateToMatvare,
    token
  } = props;
  const loggedInuser = useUserContext();
  const [copyOptionsValue, setCopyOptionsValue] = React.useState('1');
  const [newMatvarekode, setNewMatvarekode] = React.useState<string>('');
  const [newMatvareNavn, setNewMatvareNavn] = React.useState<string>('');
  const [notification, setNotification] = React.useState({ error: null, message: '' });
  const [isTouched, setIsTouched] = React.useState(false);
  const [showCDialog, setShowCDialog] = React.useState(false);
  const [dialogText, setDialogText] = React.useState('');
  const [copyMatvareConfirmation, setCopyMatvareConfirmation] = React.useState(false);
  const [owned, setOwned] = React.useState(false);
  const getGruppeidCount = useFetchData({
    loadOnMount: false,
    clearDataOnLoad: false,
    projectURL: 'getGruppetypeidCount',
    subProjectURL: `${currentDatabase?.value}`
  });
  const doesMatvareNumberOrNameExist = useFetchData({
    loadOnMount: false,
    clearDataOnLoad: false,
    projectURL: 'checkIfMatvareExists',
    subProjectURL: `${currentDatabase?.value}/${newMatvarekode}/${newMatvareNavn}`
  });
  useDidMountEffect(() => {
    // Matvare kode and Navn have to be unique.
    if (getGruppeidCount.data) {
      const data = getGruppeidCount.data as unknown as CountResult;
      if (data.COUNT < 1) {
        setOpenKopiMatvareDiv(false);
        showAlertComponent(
          'Det eksisterer ingen matvaregrupper der du kan plassere matvaren.',
          'error'
        );
      } else {
        doesMatvareNumberOrNameExist.loadData(
          'checkIfMatvareExists',
          `${currentDatabase?.value}/${newMatvarekode}/${newMatvareNavn}`
        );
      }
    }
  }, [getGruppeidCount.data]);

  useDidMountEffect(() => {
    // Matvare kode and Navn have to be unique.
    if (doesMatvareNumberOrNameExist.data) {
      const data = doesMatvareNumberOrNameExist.data as unknown as CountResult;
      if (data.COUNT === 0) {
        //If its 0, it does not exist we CAN copy matvare to a new kode.
        console.log('Matvare does not exist we can do stuff');
        setNotification({
          error: false,
          message: ''
        }); // clear error if there is one.
        setShowCDialog(true);
        if (copyOptionsValue === '1') {
          //Kopi av innholdet i skjermbildet
          setDialogText(
            `Vil du virkelig kopiere matvare: kode: ${matvarekodeId} - navn ${matvareNavn}? Til matvare kode: ${newMatvarekode} - navn: ${newMatvareNavn} ?`
          );
        } else if (copyOptionsValue === '2') {
          //Kopi av matvare med all underliggende informasjon
          setDialogText(
            `Vil du virkelig kopiere matvare med all underliggende informasjon: kode: ${matvarekodeId} - navn ${matvareNavn}? Til matvare kode: ${newMatvarekode} - navn: ${newMatvareNavn} ?`
          );
        }
      } else if (data.COUNT > 0) {
        // If its > 0, a Matvare kode or name allready exists. We can not copy.
        console.log('Matvare does exist we can NOT do stuff.');
        setNotification({
          error: true,
          message:
            'Det er ikke lov til å kopiere en matvare til en eksisterende matvare. Du må endre kode eller navn'
        });
        /* setNewMatvareNavn('');
        setNewMatvarekode(''); */
      }
    }
  }, [doesMatvareNumberOrNameExist.data, copyOptionsValue]);

  useEffect(() => {
    if (copyMatvareConfirmation) {
      let url = '';
      if (copyOptionsValue === '1') {
        //Kopi av innholdet i skjermbildet
        url = `copyMatvare/${
          currentDatabase?.value
        }/${matvarekodeId}/${newMatvarekode}/${newMatvareNavn}/${false}`;
      } else if (copyOptionsValue === '2') {
        //Kopi av matvare med all underliggende informasjon
        url = `copyMatvare/${
          currentDatabase?.value
        }/${matvarekodeId}/${newMatvarekode}/${newMatvareNavn}/${true}`;
      }
      setShowProgress(true);
      setShowCDialog(false);
      doesMatvareNumberOrNameExist.clearData();
      copy(url);
    } else {
      setShowCDialog(false);
      setShowProgress(false);
      doesMatvareNumberOrNameExist.clearData();
    }
    setCopyMatvareConfirmation(false);
  }, [copyMatvareConfirmation, copyOptionsValue]);

  const copy = (url: string) => {
    if (url !== '') {
      const fullUri = `${process.env.REACT_APP_BACKEND}/${url}`;
      fetch(encodeURI(fullUri), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token
        }
      })
        .then((resp) => {
          if (resp.status === 200) {
            setOpenKopiMatvareDiv(false);
            showAlertComponent('Kopieringen er fullført.', 'success');
            setShowProgress(false);
            setNewMatvareNavn('');
            setNewMatvarekode('');
            navigateToMatvare(+newMatvarekode);
          } else {
            setOpenKopiMatvareDiv(false);
            showAlertComponent('Kopieringen er IKKE fullført.', 'error');
            setShowProgress(false);
          }
        })
        .catch((err) => {
          console.log('We are in the catch', err);
          setOpenKopiMatvareDiv(false);
          showAlertComponent('Kopieringen er IKKE fullført.', 'error');
          setShowProgress(false);
        });
    }
  };

  const handleChangeCopyOptions = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCopyOptionsValue((event.target as HTMLInputElement).value);
  };

  const hadleCopyMatvare = async () => {
    /*
    Step 1. Test if options value is 1 or 2. 
    Step 2. Test if Kode and name is filled out. the onSubmit will take care of that.
    */
    setNotification({
      error: false,
      message: ''
    }); // clear error if there is one.
    getGruppeidCount.loadData('getGruppetypeidCount', `${currentDatabase?.value}`);
  };
  React.useEffect(() => {
    if (newMatvarekode === '') {
      setNotification({
        error: false,
        message: ''
      });
      setIsTouched(false);
    } else {
      setOwned(IsOwnedByUser({ id: +newMatvarekode, u: loggedInuser }));
      setIsTouched(true);
    }
  }, [newMatvarekode]);

  React.useEffect(() => {
    if (owned && isTouched) {
      setNotification({
        error: false,
        message: ''
      });
    } else if (!owned && isTouched) {
      setNotification({
        error: true,
        message: 'Nummeret er ikke i nummerområdet ditt'
      });
    }
  }, [owned, isTouched]);

  return (
    <>
      {showCDialog && (
        <ConfirmationDialog
          title={'Kopi av matvare'}
          text={dialogText}
          setConfirmationResult={setCopyMatvareConfirmation}
          hide={() => {
            setShowCDialog(!showCDialog);
          }}
        />
      )}
      {openCopyMatvare && (
        <DialogBox title={'Kopi Matvare'} hide={handleClickCopy}>
          <div style={style}>
            <div>
              <InputLabel>Matvare Id : {matvarekodeId}</InputLabel>
              <InputLabel>Matvare Navn : {matvareNavn}</InputLabel>
            </div>
            <Divider />
            <Box borderColor="secondary.main" padding={2}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Kopieringsmetode</FormLabel>
                <RadioGroup
                  aria-label="position"
                  name="copyOptions"
                  value={copyOptionsValue}
                  onChange={handleChangeCopyOptions}
                >
                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="Kopi av innholdet i skjermbildet"
                  />
                  <FormControlLabel
                    value="2"
                    control={<Radio />}
                    label="Kopi av matvare med all underliggende informasjon"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
            <Divider />
            <Grid container spacing={3}>
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                  Kopi disse matvare
                </Grid>
              </Grid>
              <Grid container item xs={12} spacing={2}>
                <TableContainer component={Paper}>
                  <Table arial-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">
                          <b>Matvare Kode</b>
                        </TableCell>
                        <TableCell align="left">
                          <b>Matvare Navn</b>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell align="left">{matvarekodeId}</TableCell>
                        <TableCell align="left">{matvareNavn}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>

              <Grid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                  Til:
                </Grid>
                {/* Extract this form */}
                <form
                  onSubmit={hadleCopyMatvare}
                  style={{ width: 'inherit', display: 'inherit' }}
                  id="copyMatvareForm"
                >
                  <Grid item xs={4}>
                    <InputLabel>Matvarekode *</InputLabel>
                    <TextField
                      id="newMatvareKode"
                      type="text"
                      onChange={(e) => {
                        setNewMatvarekode(e.target.value);
                        setIsTouched(true);
                      }}
                      value={newMatvarekode}
                      variant="outlined"
                      required
                      style={{ width: 256 }}
                      inputProps={{ maxLength: 6 }}
                      size="small"
                      error={notification.error}
                      helperText={notification.message}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <InputLabel>Matvarenavn *</InputLabel>
                    <TextField
                      id="newMatvareNavn"
                      type="text"
                      style={{ width: 256 }}
                      value={newMatvareNavn}
                      onChange={(event) => setNewMatvareNavn(event.target.value)}
                      variant="outlined"
                      size="small"
                      required
                      inputProps={{ maxLength: 25 }}
                      error={notification.error}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      minHeight="80px"
                    >
                      <NButton
                        children="Kopier"
                        onClick={() => hadleCopyMatvare()}
                        disabled={!(owned && newMatvarekode && newMatvareNavn)}
                      />
                    </Box>
                  </Grid>
                </form>
              </Grid>
            </Grid>
          </div>
        </DialogBox>
      )}
    </>
  );
}
