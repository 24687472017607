import React, { useContext, useEffect, useState } from 'react';
import { Grid, Stack } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import useStyles from '../../../css/materialtheme';
import { AlertI } from '../../../types/alertTypes';
import AlertComp from '../../utilities/AlertComp';
import PageContainer from '../home/PageContainer';
import { InputFields } from '../components/InputFields';
import { UtvalgteMatvareTable } from './UtvalgteMat/UtvalgteMatvareTable';
import { Matvare, MatvaregruppeI, resultatGruppeI } from './types/matvaregruppeTypes';
import { Box, Typography } from '@mui/material';
import { ProgressBar } from '../../utilities/ProgressBar';
import { DatabaseContext } from '../../../context/DatabaseContext';
import useFetchData from '../../utilities/useFetchData';
import { UtvalgTableComponent } from '../beregning/components/BeregningUtvalg';
import { MatvareGruppeTablePopup } from './UtvalgteMat/MatvareGruppeTablePopup';
import { ChatBubbleButton } from '../components/ChatBubbleButton';
import PostData from '../../utilities/postData';
import { useKeycloak } from '@react-keycloak/web';
import { UtvalgteMatvarerResult } from './UtvalgteMat/UtvalgteMatvarerResult';
import OptionalParameters from './UtvalgteMat/OptionalParameters';
//import { Stoff, StoffGruppe } from './types/StoffTypes';
import {
  BeregningObject,
  DefaultBeregningCounts,
  InitialBeregningData,
  UtvalgTableData
} from '../beregning/types/BeregningTypes';
import useDidMountEffect from '../../utilities/useDidMountEffect';
import { NButton } from '../components/Inputs';
import { StoffSearch } from '../stoff/StoffSearch';
import { useUserContext } from '../../../context/AuthContext';
import { StoffI, StoffType, StoffgruppeI } from '../stoff/types/StoffTypes';
import { UtvalgTable } from './UtvalgteMat/UtvalgTable';

const initialBeregningObject: BeregningObject = {
  utvalgId: '',
  databaseId: 0,
  matvareData: {
    gruppetypeId: 0,
    resultatGroup: '',
    individualMatvares: [],
    matvaregroups: [],
    matvaresInGroup: false,
    bregningCheckboxes: {
      norskNavn: true,
      engelskNavn: false,
      utenKosttilskudd: false
    },
    highLevelCategory: 0,
    excludedMatvares: []
  },
  stoffData: {
    individualStoffs: [],
    stoffGroup: []
  },
  inputsSaved: false,
  cleanPreviousData: true,
  hent: false
};
export default function UtvalgteMatvarer(props: any) {
  const { keycloak } = useKeycloak();
  const token = keycloak?.token;
  const [beregningObject, setBeregningObject] =
    React.useState<BeregningObject>(initialBeregningObject);
  const loggedInuser = useUserContext();
  const currentUser = loggedInuser?.user?.username;
  const { currentDatabase } = useContext(DatabaseContext);
  const classes = useStyles();
  const [title] = useState('Utvalgte Matvarer - Nutrifoodcalc');
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = React.useState<string>('');
  const [progressText, setProgressText] = React.useState('Henter resultat...');
  const [alertSeverity, setAlertSeverity] = React.useState<AlertI['alertColor']>('info');
  const [addedMatvarerList, setAddedMatvarerList] = useState<Matvare[]>([]);
  const [removedMatvare, setRemovedMatvare] = useState<Matvare>();
  const [selectedMGInput, setSelectedMGInput] = useState<string>('');
  const [selectedStoffInput, setSelectedStoffInput] = useState<string>('');
  const [showMatvaregruppe, setShowMatvaregruppe] = useState<boolean>(false);
  const [showStoff, setShowStoff] = useState<boolean>(false);
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [selectedMatvares, setSelectedMatvares] = useState<Matvare[]>([]);
  const [selectedMatvaregruppes, setSelectedMatvaregruppes] = useState<MatvaregruppeI[]>(
    []
  );
  const [selectedResultatGroup, setSelectedResultatGroup] = useState<resultatGruppeI>({
    GRUPPETYPEID: 0,
    BESKRIVELSE: '',
    LANG_BESKRIVELSE: '',
    TILGANGSKODE: 0
  });
  const [matvareRadioButton, setMatvareRadioButton] = useState<number>(0);
  const [allMatvarer, setAllMatvarer] = useState<Matvare[]>([]);
  const [selectedStoffs, setSelectedStoffs] = useState<StoffI[]>([]);
  const [stofferList, setStofferList] = useState<StoffI[]>([]);
  const [stoffgrupperList, setStoffgrupperList] = useState<StoffgruppeI[]>([]);
  const [selectedStoffgruppe, setSelectedStoffgruppe] = useState<StoffgruppeI>();
  const [showUtvalg, setShowUtvalg] = useState<boolean>(false);
  const [stoffChanged, setStoffChanged] = useState<boolean>(false);
  const [visResultat, setVisResultat] = useState<boolean>(false);
  const [selectedOptions, setSelectedOptions] = React.useState<string[]>([]);
  const [totalCounts, setTotalCounts] = React.useState<DefaultBeregningCounts>({
    TOTALMATVAREGRUPPE: 0,
    TOTALMATVARE: 0,
    TOTALFJERNDEMATVARE: 0,
    TOTALSTOFFCOUNT: 0
  });
  const [matvareCount, setMatvareCount] = React.useState<number>(0);
  const [utvalgData, setUtvalgData] = React.useState<UtvalgTableData>({
    matvaredata: [],
    matvaregroupData: [],
    stoffData: [],
    stoffGroupData: [],
    fjerndeMatvareData: []
  });
  const getInitialData = useFetchData({
    loadOnMount: true,
    clearDataOnLoad: false,
    projectURL: 'getInitialBeregningValues',
    subProjectURL: `${currentDatabase?.value}`
  });
  const allStoffs = useFetchData({
    loadOnMount: false,
    clearDataOnLoad: false,
    projectURL: 'getAllStoffs',
    subProjectURL: `${beregningObject?.databaseId}/0`
  });

  const allStoffGrupper = useFetchData({
    loadOnMount: false,
    clearDataOnLoad: false,
    projectURL: 'getStoffGroups',
    subProjectURL: `${beregningObject?.databaseId}/all`
  });
  const retrieveDataFromUtvalgTable = useFetchData({
    loadOnMount: false,
    clearDataOnLoad: false,
    projectURL: 'getUtvalgTableData',
    subProjectURL: `${beregningObject?.databaseId}/${encodeURI(
      beregningObject?.utvalgId
    )}/${beregningObject?.matvareData?.gruppetypeId}`
  });
  document.title = title;
  useEffect(() => {
    if (getInitialData.data) {
      const data = getInitialData?.data as unknown as InitialBeregningData;
      const id = currentUser + data?.UtvalgsId?.ID;
      setBeregningObject({
        ...beregningObject,
        utvalgId: id,
        databaseId: currentDatabase?.value
      });
      if (data?.defaultVerdier?.DEFAULT3 !== undefined) {
        //Defaultverdier has user specific default values saved in the DB, and DEFAULT3 has the default resultatgroup
        const groupId = parseInt(data?.defaultVerdier?.DEFAULT3) || 0;
        const defaultResultatGroup = data?.resultatgrupper?.find(
          (r) => r.GRUPPETYPEID === groupId
        );
        setSelectedResultatGroup({
          ...selectedResultatGroup,
          GRUPPETYPEID: defaultResultatGroup?.GRUPPETYPEID || 0,
          BESKRIVELSE: defaultResultatGroup?.BESKRIVELSE || ''
        });
        setBeregningObject((prevObject) => {
          const newObject = { ...prevObject };
          newObject.matvareData.gruppetypeId = defaultResultatGroup?.GRUPPETYPEID || 0;
          newObject.matvareData.resultatGroup = defaultResultatGroup?.BESKRIVELSE || '';
          return newObject;
        });
      }
    }
  }, [getInitialData.data]);
  useDidMountEffect(() => {
    if (showStoff) {
      allStoffs.loadData('getAllStoffs', `${beregningObject?.databaseId}/0`);
      allStoffGrupper.loadData('getStoffGroups', `${beregningObject?.databaseId}/all`);
    }
  }, [showStoff]);
  React.useEffect(() => {
    if (allStoffs?.data?.length > 0) {
      const data: StoffI[] = allStoffs.data as unknown as StoffI[];
      setStofferList(data);
    }
  }, [allStoffs]);
  React.useEffect(() => {
    if (allStoffGrupper?.data?.length > 0) {
      const data: StoffgruppeI[] = allStoffGrupper.data as unknown as StoffgruppeI[];
      setStoffgrupperList(data);
    }
  }, [allStoffGrupper]);
  React.useEffect(() => {
    if (allStoffs.error) {
      console.log('Error in getting allStoffs...');
    }
    if (allStoffGrupper.error) {
      console.log('Error in getting allStoffGrupper...');
    }
  }, [allStoffGrupper.error, allStoffs.error]);
  useDidMountEffect(() => {
    if (matvareRadioButton === 1) {
      saveAllMatvare();
      setBeregningObject({ ...beregningObject, inputsSaved: true });
    } else if (matvareRadioButton === 2) {
      setBeregningObject({ ...beregningObject, cleanPreviousData: false });
      const nodeNrIds = selectedMatvaregruppes?.map(
        (n: { NODENRID: number }) => n.NODENRID
      );
      if (nodeNrIds?.length > 0) {
        saveAllMatvareInMG({ data: nodeNrIds });
        setBeregningObject({ ...beregningObject, inputsSaved: true });
      }
    }
    setSelectedMGInput(selectedResultatGroup?.BESKRIVELSE + ' - Spesifisert utvalg');
  }, [matvareRadioButton]);

  useEffect(() => {
    addMatvareIfItDoesntExist(selectedMatvares);
  }, [selectedMatvares]);
  useEffect(() => {
    addMatvareIfItDoesntExist(addedMatvarerList);
    saveMatvare(addedMatvarerList);
  }, [addedMatvarerList]);

  useDidMountEffect(() => {
    setStoffChanged(true);
  }, [selectedStoffs.length]);
  useEffect(() => {
    if (removedMatvare !== undefined) {
      if (removedMatvare?.MATVAREKODEID !== 0) {
        deleteSelectedMatvare({ data: [removedMatvare?.MATVAREKODEID] });
      }
    }
  }, [removedMatvare]);

  useDidMountEffect(() => {
    setBeregningObject((prevObject) => {
      const newObject = { ...prevObject };
      newObject.matvareData.gruppetypeId = selectedResultatGroup?.GRUPPETYPEID;
      newObject.matvareData.resultatGroup = selectedResultatGroup?.BESKRIVELSE;
      return newObject;
    });
    setTotalCounts({
      TOTALMATVAREGRUPPE: 0,
      TOTALMATVARE: 0,
      TOTALFJERNDEMATVARE: 0,
      TOTALSTOFFCOUNT: 0
    });
  }, [selectedResultatGroup]);
  useDidMountEffect(() => {
    if (retrieveDataFromUtvalgTable.data) {
      const data = retrieveDataFromUtvalgTable?.data as unknown as UtvalgTableData;
      setTotalCounts({
        TOTALMATVAREGRUPPE: data?.matvaregroupData?.length,
        TOTALMATVARE: data?.matvaredata?.length,
        TOTALFJERNDEMATVARE: data?.fjerndeMatvareData?.length,
        TOTALSTOFFCOUNT: data?.stoffData?.length
      });
      setMatvareCount(data?.matvaredata?.length || 0);
      setUtvalgData(data);
    }
  }, [retrieveDataFromUtvalgTable.data]);

  useEffect(() => {
    console.log('utvalgData', utvalgData);
  }, [utvalgData]);

  const loadUtvalgData = () => {
    retrieveDataFromUtvalgTable.loadData(
      'getUtvalgTableData',
      `${beregningObject?.databaseId}/${encodeURI(beregningObject?.utvalgId)}/${
        beregningObject?.matvareData?.gruppetypeId
      }`
    );
  };
  const addMatvareIfItDoesntExist = (matvarerList: Matvare[]) => {
    for (const mat of matvarerList) {
      const found = allMatvarer?.find(
        (item: Matvare) => item.MATVAREKODEID === mat.MATVAREKODEID
      );
      if (!found) {
        setAllMatvarer((allMatvarer) => [...allMatvarer, mat]);
      }
    }
  };
  const saveMatvare = (matvaredata: Matvare[]) => {
    if (matvaredata?.length > 0) {
      saveSelectedMatvare(matvaredata);
      setBeregningObject({ ...beregningObject, cleanPreviousData: false });
    }
  };
  const saveData = () => {
    saveMatvare(allMatvarer);
  };
  const toggleShowMatvaregrupper = () => {
    setShowMatvaregruppe(!showMatvaregruppe);
  };
  const toggleShowStoff = () => {
    setShowStoff(!showStoff);
    if (selectedStoffs?.length === 0 && selectedStoffgruppe?.stoffgruppeId === 0) {
      setSelectedStoffInput('');
      deleteSelectedStoffs();
    } else {
      if (stoffChanged) {
        saveStoff(selectedStoffs);
      }
      setSelectedStoffInput(
        selectedStoffgruppe?.navn
          ? `${selectedStoffgruppe?.navn} - Spesifisert utvalg`
          : selectedStoffs?.length > 0
          ? `${selectedStoffs?.length} - Spesifisert utvalg`
          : ''
      );
      saveStoffGroup(selectedStoffgruppe?.stoffgruppeId);
    }
  };
  const showAlertComponent = (message: string, severity: any) => {
    setShowAlert(true);
    setAlertMessage(message);
    setAlertSeverity(severity);
  };
  const handleVisResultatBtnClick = () => {
    if (matvareCount === 0 && totalCounts?.TOTALSTOFFCOUNT === 0) {
      showAlertComponent('Velg matvarer og stoffer.', 'error');
      return;
    }
    if (matvareCount === 0) {
      if (addedMatvarerList?.length === 0) {
        showAlertComponent('Velg matvarer.', 'error');
        return;
      }
    }
    if (totalCounts?.TOTALSTOFFCOUNT === 0) {
      showAlertComponent('Velg stoffer.', 'error');
      return;
    } else {
      setProgressText('Henter resultat...');
      setShowProgress(true);
      setVisResultat(true);
    }
  };
  const validateInput = () => {
    let valid = true;
    if (
      beregningObject?.matvareData?.gruppetypeId === 0 ||
      beregningObject?.utvalgId === '' ||
      currentDatabase?.value === 0
    ) {
      valid = false;
    }
    return valid;
  };
  const saveStoff = (stoffs: StoffI[]) => {
    const dataurl = `${encodeURI(beregningObject?.utvalgId)}`;
    PostData({ data: stoffs }, 'saveStoffsInBU', dataurl, 'POST', token).then(
      (resp: any) => {
        if (resp.status === 200) {
          loadUtvalgData();
          console.log('Successfully saved stoffs');
          showAlertComponent(
            selectedStoffs?.length > 0
              ? `Lagret valgte stoffer.`
              : `Fjernet alle stoffer`,
            'success'
          );
        } else if (resp.status === 500) {
          console.log('An error 500', resp.status);
          showAlertComponent('Lagring av stoffer mislyktes.', 'error');
        }
      }
    );
  };
  const saveStoffGroup = (stoffGroupId: number) => {
    if (stoffGroupId === 0) {
      console.log('stoffGroupId cannot be zero!');
      return;
    }
    const dataurl = `${encodeURI(beregningObject?.utvalgId)}/${currentDatabase?.value}`;
    PostData(
      { data: [stoffGroupId] },
      'saveStoffGroupsInBU',
      dataurl,
      'POST',
      token
    ).then((resp: any) => {
      if (resp.status === 200) {
        loadUtvalgData();
        console.log('Successfully saved stoffs');
        showAlertComponent(`Lagret valgte stoffer.`, 'success');
      } else if (resp.status === 500) {
        console.log('An error 500', resp.status);
        showAlertComponent('Lagring av stoffer mislyktes.', 'error');
      }
    });
  };
  const saveAllMatvare = () => {
    const dataurl = `${encodeURI(beregningObject?.utvalgId)}/${currentDatabase?.value}/${
      beregningObject?.matvareData?.gruppetypeId
    }/3`;
    PostData({}, 'saveMatvareAndMGInButvalg', dataurl, 'POST', token).then(
      (resp: any) => {
        if (resp.status === 200) {
          loadUtvalgData();
          console.log('Successfully saved matvares');
          showAlertComponent(`Lagret alle matvarer.`, 'success');
        } else if (resp.status === 500) {
          console.log('An error 500', resp.status);
          showAlertComponent('Lagring av alle matvarer mislyktes.', 'error');
        }
      }
    );
  };
  const saveAllMatvareInMG = (data: {}) => {
    const validInputs = validateInput();
    if (!validInputs) {
      showAlertComponent('Technical error.', 'error');
      return;
    }
    const dataurl = `${currentDatabase?.value}/${
      beregningObject?.matvareData?.gruppetypeId
    }/${encodeURI(beregningObject?.utvalgId)}`;
    PostData(data, 'addAllMatvareInMG', dataurl, 'POST', token).then((resp: any) => {
      if (resp.status === 200) {
        loadUtvalgData();
        console.log('Successfully saved matvares');
        showAlertComponent(`Lagret alle matvarer inni valgte grupper.`, 'success');
        setAllMatvarer([]);
      } else if (resp.status === 500) {
        console.log('An error 500', resp.status);
        showAlertComponent(
          'Lagring av alle matvarer inni valgte grupper mislyktes.',
          'error'
        );
      }
    });
  };
  const saveSelectedMatvare = (data: Matvare[]) => {
    if (beregningObject?.utvalgId === '') {
      showAlertComponent('Technical error.', 'error');
      return;
    }
    const dataurl = `${encodeURI(beregningObject?.utvalgId)}`;
    PostData({ data: data }, 'addSelectedMatvares', dataurl, 'POST', token).then(
      (resp: any) => {
        if (resp.status === 200) {
          loadUtvalgData();
          console.log('Successfully saved matvares');
          showAlertComponent(`Lagret valgte matvarer.`, 'success');
          setAllMatvarer([]);
        } else if (resp.status === 500) {
          console.log('An error 500', resp.status);
          showAlertComponent('Lagring av valgte matvarer mislyktes.', 'error');
        }
      }
    );
  };
  const deleteSelectedMatvare = (data: {}) => {
    const validInputs = validateInput();
    if (!validInputs) {
      showAlertComponent('Technical error.', 'error');
      return;
    }
    const dataurl = `${encodeURI(beregningObject?.utvalgId)}`;
    PostData(data, 'deleteSelectedMatvaresInButvalg', dataurl, 'DELETE', token).then(
      (resp: any) => {
        if (resp.status === 200) {
          loadUtvalgData();
          console.log('Successfully deleted the selected matvare ');
          showAlertComponent(`Matvaren er fjernt.`, 'success');
        } else if (resp.status === 500) {
          console.log('An error 500', resp.status);
          showAlertComponent('error happened', 'error');
          showAlertComponent('Fjerning av matvaren mislyktes.', 'error');
        }
      }
    );
  };
  const deleteSelectedStoffs = () => {
    const dataurl = `${encodeURI(beregningObject?.utvalgId)}`;
    PostData({}, 'deleteSelectedStoffsInButvalg', dataurl, 'DELETE', token).then(
      (resp: any) => {
        if (resp.status === 200) {
          loadUtvalgData();
          console.log('Successfully deleted the selected stoffs ');
          showAlertComponent(`Stoffene er fjernt.`, 'success');
        } else if (resp.status === 500) {
          console.log('An error 500', resp.status);
          showAlertComponent('error happened', 'error');
          showAlertComponent('Fjerning av stoffene mislyktes.', 'error');
        }
      }
    );
  };
  const deleteAllMatvareInMG = (data: {}) => {
    const validInputs = validateInput();
    if (!validInputs) {
      showAlertComponent('Technical error.', 'error');
      return;
    }
    const dataurl = `${currentDatabase?.value}/${
      beregningObject?.matvareData?.gruppetypeId
    }/${encodeURI(beregningObject?.utvalgId)}`;
    PostData(data, 'deleteAllMatvareInMG', dataurl, 'DELETE', token).then((resp: any) => {
      if (resp.status === 200) {
        loadUtvalgData();
        console.log('Successfully deleted matvares in groups');
        showAlertComponent(`Fjernet alle matvarer inni valgte grupper.`, 'success');
      } else if (resp.status === 500) {
        console.log('An error 500', resp.status);
        showAlertComponent('error happened', 'error');
        showAlertComponent(
          'Fjerning av alle matvarer inni valgte grupper mislyktes.',
          'error'
        );
      }
    });
  };
  const deleteAllMatvares = () => {
    if (beregningObject?.utvalgId === '') {
      showAlertComponent('Technical error.', 'error');
      return;
    }
    const dataurl = `${encodeURI(beregningObject?.utvalgId)}`;
    PostData({}, 'deleteAllMatvaresInButvalg', dataurl, 'DELETE', token).then(
      (resp: any) => {
        if (resp.status === 200) {
          loadUtvalgData();
          console.log('Successfully all deleted matvares');
          showAlertComponent(`Fjernet alle matvarer.`, 'success');
        } else if (resp.status === 500) {
          console.log('An error 500', resp.status);
          showAlertComponent('error happened', 'error');
          showAlertComponent('Fjerning av alle matvarer mislyktes.', 'error');
        }
      }
    );
  };
  const clearForm = () => {
    setSelectedMGInput('');
    setSelectedStoffInput('');
    setAddedMatvarerList([]);
    setMatvareCount(0);
    setSelectedOptions([]);
    setBeregningObject(initialBeregningObject);
    let utvalgData: UtvalgTableData = {
      matvaredata: [],
      matvaregroupData: [],
      stoffData: [],
      stoffGroupData: [],
      fjerndeMatvareData: []
    };
    setUtvalgData(utvalgData);
    //get new id from BE
    getInitialData.loadData('getInitialBeregningValues', `${currentDatabase?.value}`);
  };
  return (
    <PageContainer>
      <Typography variant="h4">Utvalgte matvarer</Typography>
      <Grid container alignItems="center" justifyContent="center" spacing={1}>
        <Grid item xs={6}>
          <InputFields
            label={'Matvaregrupper*'}
            classes={classes}
            id={'Matvaregrupper'}
            name={'Matvaregrupper'}
            value={selectedMGInput}
            type={'text'}
            variant={'filled'}
            required={true}
            disabled={true}
          />
        </Grid>
        <Grid item xs={2}>
          <ChatBubbleButton
            name={'Matvaregrupper'}
            showPopup={showMatvaregruppe}
            setShowPopup={toggleShowMatvaregrupper}
          />
        </Grid>
        <Grid item xs={4}>
          <Grid container spacing={1} justifyContent="center" alignItems="flex-end">
            <Box justifyContent="flex-end" alignItems="flex-end">
              <Typography gutterBottom variant="h6" component="div" color="text.primary">
                Antall matvarer: {matvareCount || 0}
              </Typography>
              <Typography gutterBottom variant="h6" component="div" color="text.primary">
                Antall stoffer: {totalCounts?.TOTALSTOFFCOUNT || 0}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid container alignItems="center" justifyContent="center" spacing={1}>
        <Grid item xs={6}>
          <InputFields
            label={'Stoffer*'}
            classes={classes}
            id={'Stoffer'}
            name={'Stoffer'}
            value={selectedStoffInput}
            type={'text'}
            variant={'filled'}
            required={true}
            disabled={true}
          />
        </Grid>
        <Grid item xs={2}>
          <ChatBubbleButton
            name={'Stoffer'}
            showPopup={showStoff}
            setShowPopup={setShowStoff}
          />
        </Grid>
        <Grid item xs={4}>
          <Grid container spacing={1} justifyContent="center" alignItems="flex-end">
            <NButton children="Vis utvalg" onClick={() => setShowUtvalg(!showUtvalg)} />
          </Grid>
        </Grid>
      </Grid>

      {showMatvaregruppe && (
        <MatvareGruppeTablePopup
          showMatvaregruppe={showMatvaregruppe}
          toggleShowMatvaregrupper={toggleShowMatvaregrupper}
          matvareRadioButton={matvareRadioButton}
          setMatvareRadioButton={setMatvareRadioButton}
          setShowProgress={setShowProgress}
          setProgressText={setProgressText}
          selectedMatvares={selectedMatvares}
          setSelectedMatvares={setSelectedMatvares}
          selectedMatvaregruppes={selectedMatvaregruppes}
          setSelectedMatvaregruppes={setSelectedMatvaregruppes}
          selectedResultatGroup={selectedResultatGroup}
          setSelectedResultatGroup={setSelectedResultatGroup}
          saveData={saveData}
          deleteAllMatvareInMG={deleteAllMatvareInMG}
          deleteAllMatvares={deleteAllMatvares}
          totalCounts={totalCounts?.TOTALMATVARE}
        />
      )}

      <Grid container spacing={1} justifyContent="flex-start" alignItems="flex-start">
        <Grid item xs={8}>
          <h3>Matvarer</h3>
          <UtvalgteMatvareTable
            currentDatabase={currentDatabase}
            addedMatvarerList={addedMatvarerList}
            setRemovedMatvare={setRemovedMatvare}
            setAddedMatvarerList={setAddedMatvarerList}
            showAlertComponent={showAlertComponent}
            matvareCount={matvareCount}
            setMatvareCount={setMatvareCount}
            setShowProgress={setShowProgress}
            showProgress={showProgress}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1} justifyContent="flex-start" alignItems="flex-start">
        <Grid item xs={8}>
          <OptionalParameters
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions}
          />
        </Grid>
        <Grid item xs={4}>
          <Grid container spacing={1} justifyContent="center" alignItems="flex-end">
            <Stack direction="row" spacing={1} justifyContent={'flex-end'}>
              <NButton
                children="Vis resultat"
                rightIcon={<DownloadIcon />}
                onClick={handleVisResultatBtnClick}
              />
              <NButton children="Nullstill" variant="outlined" onClick={clearForm} />
            </Stack>
          </Grid>
        </Grid>
      </Grid>
      {showUtvalg && (
        <UtvalgTable
          beregningObject={beregningObject}
          totalCounts={totalCounts}
          setTotalCounts={setTotalCounts}
          hide={() => {
            setShowUtvalg(!showUtvalg);
          }}
          utvalgData={utvalgData}
          setUtvalgData={setUtvalgData}
        />
      )}
      {showStoff && (
        <StoffSearch
          databaseId={beregningObject.databaseId}
          stofferList={stofferList}
          setStofferList={setStofferList}
          selectedStoffs={selectedStoffs}
          setSelectedStoffs={setSelectedStoffs}
          stoffgrupperList={stoffgrupperList}
          setStoffgrupperList={setStoffgrupperList}
          selectedStoffgruppe={selectedStoffgruppe}
          setSelectedStoffgruppe={setSelectedStoffgruppe}
          utvalgData={utvalgData}
          setShowStoffOption={setShowStoff}
          showStoffOption={showStoff}
          stoffType={StoffType.both}
          toggleStoffnummer={toggleShowStoff}
        />
      )}
      {showAlert && (
        <AlertComp
          alertMessage={alertMessage}
          alertColor={alertSeverity}
          showAlert={showAlert}
          setShowAlert={setShowAlert}
        />
      )}
      {showProgress && (
        <ProgressBar
          isShowing={showProgress}
          text={progressText}
          hide={() => {
            setShowProgress(!showProgress);
          }}
        />
      )}
      {visResultat && (
        <UtvalgteMatvarerResult
          currentUser={currentUser}
          database={currentDatabase}
          calculationId={beregningObject?.utvalgId}
          resultatGruppe={beregningObject?.matvareData?.resultatGroup}
          optionalParameters={selectedOptions}
          setVisResultat={setVisResultat}
          setShowProgress={setShowProgress}
          showAlertMessage={showAlertComponent}
        />
      )}
    </PageContainer>
  );
}
