import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import PageContainer from '../home/PageContainer';
import { AlertI } from '../../../types/alertTypes';
import { ProgressBar } from '../../utilities/ProgressBar';
import AlertComp from '../../utilities/AlertComp';
import {
  Paper,
  Grid,
  Typography,
  Stack,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Box,
  CircularProgress,
  FormGroup,
  Checkbox,
  FormControlLabel
} from '@mui/material';
import { Dropdown, NButton } from '../components/Inputs';
import ReactToPrint from 'react-to-print';
import moment from 'moment';

import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import ProjectNoLink from './components/ProjectNoLink';
import {
  MatvareIUndersokelseI,
  MatvaresAndOwners,
  ItemOwner,
  MatvareKodenNavnI
} from './types/UndersokelseTypes';
import IsOwnedByUser from '../../utilities/Validate';
import { useUserContext } from '../../../context/AuthContext';
import ByttMatvare from './ByttMatvare';
import { RouteComponentProps } from 'react-router-dom';
import MatvarerTable from './MatvarerTable';
import { ApiCall } from '../../utilities/ApiCall';
interface printMatvareProps {
  data: MatvareIUndersokelseI[];
  projectId: number;
  undersokelseId: number;
  user: string;
  eier: ItemOwner;
  comment: boolean;
  ref: any;
}
const ComponentToPrint = React.forwardRef((p: printMatvareProps, ref: any) => (
  <div ref={ref} style={{ padding: '1em' }}>
    <Stack direction={'row'} spacing={6}>
      <Stack direction={'column'}>
        <Typography>Utskrift av skjermbildet: Matvarer ({p?.user})</Typography>
        <Typography>
          Utskriftsdato:
          {moment().format('YYYY-MM-DD hh:mm:ss')}
        </Typography>
        <Typography>
          Prosjekt:
          {p?.projectId}
        </Typography>
      </Stack>

      <Stack direction={'column'}>
        <Typography>
          Matvare eier:
          {p?.eier?.brukerkode}
        </Typography>
        <Typography>
          Minimum 1 kommentar:
          {p?.comment ? 'Ja' : 'Nei'}
        </Typography>
        <Typography>
          Undersøkelse:
          {p?.undersokelseId}
        </Typography>
      </Stack>
    </Stack>
    <Table className="styledTable">
      <TableHead>
        <TableRow>
          <TableCell>Kode</TableCell>
          <TableCell>Navn</TableCell>
          <TableCell>Eier</TableCell>
          <TableCell>Oppskrift</TableCell>
          <TableCell>Vektendring</TableCell>
          <TableCell>Antall</TableCell>
          <TableCell>Kommentar</TableCell>
          <TableCell>Beskrivelse</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {p.data.map((matvare) => (
          <TableRow key={matvare.matvarekode}>
            <TableCell>{matvare.matvarekode}</TableCell>
            <TableCell>{matvare.navn}</TableCell>
            <TableCell>{matvare.eier}</TableCell>
            <TableCell>{matvare.oppskrift === 'J' ? 'Ja' : 'Nei'}</TableCell>
            <TableCell>{matvare.vektendring}</TableCell>
            <TableCell>{matvare.antall}</TableCell>
            <TableCell>{matvare.komentarer}</TableCell>
            <TableCell>{matvare.beskrivelse}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </div>
));
interface RouteParams {
  prosjektId: string;
  undersokelseId: string;
  undersokelseKortnavn: string;
}
interface MatvarerProps extends RouteComponentProps<RouteParams> {}
export default function Matvarer(props: MatvarerProps) {
  const { prosjektId, undersokelseId, undersokelseKortnavn } = props.match.params;
  const { keycloak } = useKeycloak();
  const loggedInuser = useUserContext();
  const [owned, setOwned] = useState(false);
  const [matvareData, setMatvareData] = useState<MatvareIUndersokelseI[]>([]);
  const [unFilteredData, setUnFilteredData] = useState<MatvareIUndersokelseI[]>([]);

  const [matvareToChange, setMatvareToChange] = useState<MatvareKodenNavnI>(undefined);
  const [undersokelseOwners, setUndersokelseOwners] = useState<ItemOwner[]>([]);
  const [selectedUOwner, setSelectedUOwner] = useState<ItemOwner>({
    id: 0,
    brukerkode: ''
  });
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState<AlertI['alertColor']>('info');
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [isFetched, setIsFetched] = useState<boolean>(false);
  const [progressText] = useState('Bytt...');
  const [showProgress, setShowProgress] = useState(false);
  const [bytt, setBytt] = useState(false);
  const [limit] = useState(100);
  const [kommentarChecked, setKommentarChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const printRef = React.useRef();
  document.title = `Matvarer i prosjekt ${prosjektId} - undersøkelse ${undersokelseId} - Nutrifoodcalc`;

  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const loader = useRef<HTMLDivElement | null>(null);

  // Fetch data from API
  const fetchData = async (page: number, reload: boolean) => {
    setLoading(true); // Set loading state
    try {
      const fullUri = `${
        process.env.REACT_APP_BACKEND
      }/getMatvareDataInUndersokelse/${prosjektId}/${undersokelseId}/${
        selectedUOwner.id
      }/${0}/${page}/${limit}`;
      const response = await fetch(encodeURI(fullUri), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + keycloak.token
        }
      });

      const result = await response.json();

      // Append new data to existing data

      const data: MatvaresAndOwners = result as unknown as MatvaresAndOwners;
      const matvarer: MatvareIUndersokelseI[] = data?.matvarer || [];
      // Update the state with either new data or append to existing data
      if (reload) {
        setUnFilteredData(matvarer);
        setMatvareData(applyFilters(matvarer)); // Replace old data if it's a reload
      } else {
        setMatvareData((prev) => [...prev, ...applyFilters(matvarer)]);
        setUnFilteredData((prev) => [...prev, ...matvarer]);
      }
      setHasMore(data.hasMore);
      setUndersokelseOwners(data?.owmers);
      setSelectedUOwner(data?.owmers[0]);

      setOwned(IsOwnedByUser({ id: +prosjektId, u: loggedInuser }));

      // Check if there's more data to load
      if (matvarer.length === 0 || matvarer.length < limit) {
        setHasMore(false);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    setLoading(false); // Reset loading state
  };

  // Load more data when scrolling near the bottom
  const handleObserver = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const target = entries[0];
      if (target.isIntersecting && hasMore && !loading) {
        setPage((prev) => prev + 1);
      }
    },
    [hasMore, loading]
  );

  // Set up IntersectionObserver
  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver, { rootMargin: '50px' });
    if (loader.current) {
      observer.observe(loader.current);
    }
    return () => {
      if (loader.current) {
        observer.unobserve(loader.current);
      }
    };
  }, [handleObserver]);

  // Fetch data when the page changes
  useEffect(() => {
    if (hasMore) {
      fetchData(page, false);
    }
  }, [page, hasMore]);

  const loadMatvareData = () => {
    if (prosjektId && undersokelseId) {
      //setShowProgress(true);
      setIsFetched(false);
      fetchData(1, true);
      showAlertComponent('Oppdatering er fullført', 'success');
    }
  };
  useEffect(() => {
    setMatvareData(applyFilters(unFilteredData));
  }, [selectedUOwner?.id, kommentarChecked]);
  // Function to filter data based on the filterText
  const applyFilters = (data: MatvareIUndersokelseI[]) => {
    let filteredData = data;

    // First filter: Check if 'brukerkode' is not 'Alle'
    if (selectedUOwner?.brukerkode && selectedUOwner?.brukerkode?.trim() !== 'Alle') {
      filteredData = filteredData?.filter((d) =>
        d.eier?.includes(selectedUOwner?.brukerkode)
      );
      if (filteredData.length === 0) {
        setSelectedUOwner(undersokelseOwners[0]);
        showAlertComponent(
          `Ingen matvare funnet med eier: ${selectedUOwner?.brukerkode}.`,
          'warning'
        );
      }
    }

    // Second filter: Check if 'kommentarChecked' is true and filter accordingly
    if (kommentarChecked) {
      filteredData = filteredData?.filter((d) => d.komentarer > 1);
    }
    if (filteredData.length === 0) {
      return unFilteredData;
    } else {
      // Return the data that meets both criteria
      return filteredData;
    }
  };

  const showAlertComponent = (message: string, severity: any) => {
    setShowAlert(true);
    setAlertMessage(message);
    setAlertSeverity(severity);
  };
  return (
    <PageContainer>
      <Paper style={{ padding: '1.5em 15px 1.5em 15px', boxShadow: 'none' }}>
        <Grid container spacing={2}>
          <Grid item xs={9}>
            <Typography variant="h4" gutterBottom>
              Matvarer rapportert i undersøkelsen
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Stack direction="row-reverse" spacing={2}>
              <ReactToPrint
                trigger={() => (
                  <NButton
                    children="Skriv ut"
                    rightIcon={<PrintOutlinedIcon />}
                    variant="text"
                    disabled={!matvareData?.length}
                  />
                )}
                pageStyle=".styledTable{width:100%;border-collapse:collapse;text-align:left}.styledTable th,.styledTable td{border:2px solid black;padding:0.5em;}"
                content={() => printRef.current}
              />
            </Stack>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <ProjectNoLink
                projectId={prosjektId}
                undersokelseId={undersokelseId}
                kortnavn={undersokelseKortnavn}
              />
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={2} style={{ padding: '0 0 1.5em 0' }}>
                <Grid item xs={4}>
                  <Dropdown
                    header={'Eier'}
                    value={selectedUOwner || undersokelseOwners[0]}
                    options={undersokelseOwners}
                    optionLabel={(option: ItemOwner) => option?.brukerkode}
                    onChange={(v: any) => setSelectedUOwner(v)}
                  />
                </Grid>
                <Grid item xs={4} justifyContent={'center'} alignContent={'flex-end'}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={kommentarChecked}
                          onChange={(e) => setKommentarChecked(e.target.checked)}
                        />
                      }
                      label="Antall kommentar > 1"
                    />
                  </FormGroup>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {matvareData?.length ? (
          <>
            <MatvarerTable
              owned={owned}
              matvareData={matvareData}
              undersokelseOwners={undersokelseOwners}
              selectedUOwner={selectedUOwner}
              kommentarChecked={kommentarChecked}
              setSelectedUOwner={setSelectedUOwner}
              setKommentarChecked={setKommentarChecked}
              loading={loading}
              hasMore={hasMore}
              loader={loader}
              setBytt={setBytt}
              setMatvareToChange={setMatvareToChange}
              projectId={+prosjektId}
              undersokelseId={+undersokelseId}
              undersokelseKortnavn={undersokelseKortnavn}
            />
          </>
        ) : isFetched || unFilteredData.length > 0 ? (
          <Stack justifyContent={'center'} alignItems={'center'} height={'33rem'}>
            <Typography variant="body1">
              Ingen matvare funnet i denne undersøkelsen.
            </Typography>
          </Stack>
        ) : (
          <Stack justifyContent={'center'} alignItems={'center'} height={'33rem'}>
            <CircularProgress />
          </Stack>
        )}

        {/* Dialogs */}

        <Box sx={{ display: 'none' }}>
          <ComponentToPrint
            user={loggedInuser?.user?.username}
            projectId={+prosjektId}
            undersokelseId={+undersokelseId}
            data={matvareData}
            eier={selectedUOwner}
            comment={kommentarChecked}
            ref={printRef}
          />
        </Box>

        {bytt && (
          <ByttMatvare
            reloadData={loadMatvareData}
            showAlertComponent={showAlertComponent}
            projectId={+prosjektId}
            undersokelseId={+undersokelseId}
            data={matvareData}
            matvareToChange={matvareToChange}
            setBytt={setBytt}
          />
        )}
        {showProgress && (
          <ProgressBar
            isShowing={showProgress}
            text={progressText}
            hide={() => {
              setShowProgress(!showProgress);
            }}
          />
        )}
        {showAlert && (
          <AlertComp
            alertMessage={alertMessage}
            alertColor={alertSeverity}
            showAlert={showAlert}
            setShowAlert={setShowAlert}
          />
        )}
      </Paper>
    </PageContainer>
  );
}
