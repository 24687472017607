import React, { useEffect, useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { AppBar, Button, CardMedia, Chip, Grid, Typography } from '@mui/material';
import useStyles from '../../css/materialtheme';
import useStylesHeader from '../../css/header';
import logo from '../../images/logo.svg';
import { NavLink } from 'react-router-dom';
import { Logout } from '../authentication/Logout';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Stack } from '@mui/material';
import SelectDatabase from '../utilities/SelectDatabase';
import { StyledMenu, StyledMenuItem } from '../../css/styledMenu';
import { UserRole } from '../pages/admin/AdminTypes';
import { useUserContext } from '../../context/AuthContext';
import { Fallback } from '../pages/Fallback';
import { Padding } from '@mui/icons-material';
interface SimpleMenuProps {
  label: string;
  children: React.ReactNode[];
  classes: any;
  classesHeader: any;
  handleClose: () => void;
  closeAll: number;
}
const SimpleMenu = React.forwardRef(function SimpleMenu(
  props: SimpleMenuProps,
  ref: any
) {
  const [anchorEl, setAnchorEl] = React.useState<any>(null);

  useEffect(() => {
    setAnchorEl(null);
  }, [props.closeAll]);

  return (
    <React.Fragment>
      <Button
        sx={props.classes.buttonmenu}
        onClick={(e) => setAnchorEl(e.currentTarget)}
        aria-controls="fade-menu"
      >
        <Typography sx={props.classesHeader.MenuName}>{props.label}</Typography>
        <ArrowDropDownIcon />
      </Button>
      <StyledMenu
        id="fade-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={props.handleClose}
        MenuListProps={{ onMouseLeave: props.handleClose }}
      >
        {props.children}
      </StyledMenu>
    </React.Fragment>
  );
});

interface SimpleSubMenuProps extends SimpleMenuProps {
  to: string;
  exact?: boolean;
  closeAll: number;
  handleClose: () => void;
  classes: any;
}

const SimpleSubMenu = React.forwardRef(function SimpleSubMenu(
  props: SimpleSubMenuProps,
  ref: any
) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  useEffect(() => {
    setAnchorEl(null);
  }, [props.closeAll]);

  return (
    <NavLink
      style={{ textDecoration: 'none' }}
      ref={ref}
      exact={props.exact}
      to={props.to || ''}
      data-toggle="dropdown"
    >
      <StyledMenuItem
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
        }}
      >
        {props.label}
        <ArrowDropDownIcon />
      </StyledMenuItem>
      <StyledMenu
        id="fade-menu"
        sx={props.classes.subMenuMat}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={props.handleClose}
        MenuListProps={{ onMouseLeave: props.handleClose }}
      >
        {props.children}
      </StyledMenu>
    </NavLink>
  );
});

interface SimpleLinkProps {
  label: string;
  to: string;
  exact?: boolean;
  disabled?: boolean;
  handleClose: () => void;
}

const SimpleLink = React.forwardRef(function SimpleLink(
  props: SimpleLinkProps,
  ref: any
) {
  return (
    <NavLink
      ref={ref}
      exact={props.exact}
      to={props.to || ''}
      style={{ textDecoration: 'none' }}
    >
      <StyledMenuItem disabled={props.disabled} onClick={props.handleClose}>
        {props.label}
      </StyledMenuItem>
    </NavLink>
  );
});

export function NavLinks(props: any) {
  const { keycloak } = useKeycloak();
  const loggedInuser = useUserContext();
  const classes = useStyles();
  const classesHeader = useStylesHeader();

  const [closeAll, setCloseAll] = useState<number>(0);

  const ref = React.useRef(null);

  const handleClose = () => {
    setCloseAll(Math.random()); // Triggers effect
  };
  /*  if (!(loggedInuser.user.numberRange[0] > 0 && loggedInuser.user.numberRange[2] > 0)) {
     return <Fallback/>
   } */

  return (
    <AppBar sx={classesHeader.Header}>
      <Grid container>
        <Grid
          item
          xs={3}
          style={{ display: 'flex', flexDirection: 'row' }}
          alignItems="center"
        >
          <Stack direction={'row'} alignItems="center" spacing={1}>
            <NavLink to="/home" style={{ paddingLeft: 35 }}>
              <CardMedia
                sx={classesHeader.logo}
                component="img"
                image={logo}
                alt="logheder"
              />
            </NavLink>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography sx={classesHeader.mainText}>NFC</Typography>
              <Typography>NutriFoodCalc</Typography>
            </Stack>
          </Stack>
        </Grid>
        <Grid container item xs={6} justifyContent={'center'} alignContent={'center'}>
          {keycloak?.hasRealmRole(UserRole.Akf) ? (
            <Chip
              sx={classesHeader.chip}
              label="Du er logget inn som AKF"
              color="error"
            />
          ) : null}
        </Grid>
        {props.isLogin ? null : (
          <Grid container item xs={3} justifyContent="flex-end">
            <div
              style={{
                height: 60,
                padding: '12px 35px 12px 5px'
              }}
            >
              <Logout />
            </div>
          </Grid>
        )}
      </Grid>
      {props.isLogin ||
      !(
        loggedInuser.user.numberRange?.length > 0 &&
        loggedInuser.user.numberRange[0] > 0 &&
        loggedInuser.user.numberRange[1] > 0
      ) ? null : (
        <>
          <Stack direction="row" sx={classesHeader.MenuBar}>
            <SimpleMenu
              ref={ref}
              label="Kostholdsdata"
              handleClose={handleClose}
              closeAll={closeAll}
              classes={classes}
              classesHeader={classesHeader}
            >
              {/* <SimpleLink
                to="/home/kostholdsdata/hentkosthold"
                label="Hent kostholdsdata"
                handleClose={props.handleClose}
              /> */}
              <SimpleLink
                to="/home/kostholdsdata/prosjekt"
                label="Prosjekt"
                handleClose={props.handleClose}
              />
              <SimpleLink
                to="/home/kostholdsdata/tilleggsvariabler"
                label="Tilleggsvariabler"
                handleClose={props.handleClose}
              />
              <SimpleSubMenu
                ref={ref}
                exact
                to="/home/kostholdsdata/import"
                label="Import"
                classes={classes}
                closeAll={closeAll}
                handleClose={handleClose}
                classesHeader={classes}
              >
                <SimpleLink
                  to="/home/kostholdsdata/import/importKostholdsundersokelse"
                  label="Import kostholdsundersøkelse"
                  handleClose={handleClose}
                  disabled={keycloak?.hasRealmRole(UserRole.ReadOnly)}
                />
                <SimpleLink
                  to="/home/kostholdsdata/import/importPersoner"
                  label="Import personer"
                  handleClose={handleClose}
                  disabled={keycloak?.hasRealmRole(UserRole.ReadOnly)}
                />
                <SimpleLink
                  to="/home/kostholdsdata/import/fjernMatvarekoder"
                  label="Fjern matvarer uten mengde"
                  handleClose={handleClose}
                  disabled={keycloak?.hasRealmRole(UserRole.ReadOnly)}
                />
              </SimpleSubMenu>
            </SimpleMenu>
            <SimpleMenu
              ref={ref}
              label="Matvaredata"
              handleClose={handleClose}
              closeAll={closeAll}
              classes={classes}
              classesHeader={classesHeader}
            >
              <SimpleLink
                to="/home/matvaredata/matvare"
                label="Matvare"
                handleClose={handleClose}
              />
              <SimpleLink
                exact
                to="/home/matvaredata/matvaregruppe"
                label="Matvaregruppe"
                handleClose={handleClose}
              />
              <SimpleLink
                exact
                to="/home/matvaredata/stoff"
                label="Stoff"
                handleClose={handleClose}
              />
              <SimpleLink
                exact
                to="/home/matvaredata/stoffgruppe"
                label="Stoffgruppe"
                handleClose={handleClose}
              />
              {keycloak?.hasRealmRole(UserRole.Admin) ? (
                <SimpleLink
                  exact
                  to={`/home/matvaredata/utvalgtematvarer`}
                  label="Utvalgte matvarer"
                  handleClose={handleClose}
                  disabled={
                    !(
                      keycloak?.hasRealmRole(UserRole.Admin) ||
                      keycloak?.hasRealmRole(UserRole.Akf)
                    )
                  }
                />
              ) : undefined}
              <SimpleLink
                exact
                to="/home/matvaredata/hentmatvarer"
                label="Hent matvare(r)"
                handleClose={handleClose}
                disabled={keycloak?.hasRealmRole(UserRole.ReadOnly)}
              />
              <SimpleSubMenu
                ref={ref}
                exact
                to="/home/matvaredata/import"
                label="Import"
                classes={classes}
                closeAll={closeAll}
                handleClose={handleClose}
                classesHeader={classes}
              >
                <SimpleLink
                  to="/home/matvaredata/import/matvarer"
                  label="Matvarer"
                  handleClose={handleClose}
                  disabled={keycloak?.hasRealmRole(UserRole.ReadOnly)}
                />
                <SimpleLink
                  to="/home/matvaredata/import/matvarefelter"
                  label="Matvarefelter"
                  handleClose={handleClose}
                  disabled={keycloak?.hasRealmRole(UserRole.ReadOnly)}
                />
                <SimpleLink
                  to="/home/matvaredata/import/mengdeenheter"
                  label="Mengdeenheter"
                  handleClose={handleClose}
                  disabled={keycloak?.hasRealmRole(UserRole.ReadOnly)}
                />
                <SimpleLink
                  to="/home/matvaredata/import/oppskrifter"
                  label="Oppskrifter"
                  handleClose={handleClose}
                  disabled={keycloak?.hasRealmRole(UserRole.ReadOnly)}
                />
                <SimpleLink
                  to="/home/matvaredata/import/stoffkomponenterRAF"
                  label="Stoffkomponenter(regnearkformat)"
                  handleClose={handleClose}
                  disabled={keycloak?.hasRealmRole(UserRole.ReadOnly)}
                />
                <SimpleLink
                  to="/home/matvaredata/import/retensjonsfaktorer"
                  label="Retensjonsfaktorer"
                  handleClose={handleClose}
                  disabled={keycloak?.hasRealmRole(UserRole.ReadOnly)}
                />
                <SimpleLink
                  to="/home/matvaredata/import/stoff"
                  label="Stoff"
                  disabled
                  handleClose={handleClose}
                />
                <SimpleLink
                  to="/home/matvaredata/import/stoffgrupper"
                  label="Stoffgrupper"
                  disabled
                  handleClose={handleClose}
                />
                <SimpleLink
                  to="/home/matvaredata/import/koplingStoff"
                  label="Kopling stoff-stoffgrupper"
                  disabled
                  handleClose={handleClose}
                />
              </SimpleSubMenu>
            </SimpleMenu>
            <Button sx={classes.buttonmenu}>
              <NavLink exact to="/home/beregning">
                <Typography sx={classesHeader.MenuName}>Beregning</Typography>
              </NavLink>
            </Button>
            <SimpleMenu
              label="Rapport"
              handleClose={handleClose}
              closeAll={closeAll}
              classes={classes}
              classesHeader={classesHeader}
            >
              <SimpleSubMenu
                ref={ref}
                exact
                to="/home/rapport/kostholdsdata"
                label="Kostholdsdata"
                classes={classes}
                closeAll={closeAll}
                handleClose={handleClose}
                classesHeader={classes}
              >
                <SimpleLink
                  exact
                  to="/home/rapport/kostholdsdata/definerte-spørsmålogsvart"
                  label="Definerte spørsmål og svar"
                  disabled
                  handleClose={handleClose}
                />
                <SimpleLink
                  exact
                  to="/home/rapport/kostholdsdata/korrekturfil"
                  label="Korrektur"
                  handleClose={handleClose}
                  disabled={keycloak?.hasRealmRole(UserRole.ReadOnly)}
                />
                <SimpleLink
                  exact
                  to="/home/rapport/kostholdsdata/måltidsinformasjon"
                  label="Måltidsinformasjon"
                  handleClose={handleClose}
                  disabled
                />
                <SimpleLink
                  exact
                  to="/home/rapport/kostholdsdata/personenssvar-tilleggsspørsmål"
                  label="Personens svar tilleggsspørsmål"
                  handleClose={handleClose}
                  disabled
                />
                <SimpleLink
                  exact
                  to="/home/rapport/kostholdsdata/projekter"
                  label="Projekter"
                  handleClose={handleClose}
                  disabled
                />
                <SimpleLink
                  exact
                  to="/home/rapport/kostholdsdata/spørmålogsvar-undersøkelsen"
                  label="Spørsmal og svar på undersøkelse"
                  handleClose={handleClose}
                  disabled
                />
                <SimpleLink
                  exact
                  to="/home/rapport/kostholdsdata/undersøkelser-prosjektvis"
                  label="Undersøkelser prosjektvis"
                  handleClose={handleClose}
                  disabled
                />
                <SimpleLink
                  exact
                  to="/home/rapport/kostholdsdata/utbyttet-matvare"
                  label="Utbyttet matvare"
                  handleClose={handleClose}
                  disabled
                />
              </SimpleSubMenu>
              <SimpleSubMenu
                ref={ref}
                exact
                to="/home/rapport/matvaredata"
                label="Matvaredata"
                classes={classes}
                closeAll={closeAll}
                handleClose={handleClose}
                classesHeader={classes}
              >
                <SimpleLink
                  exact
                  to="/home/rapport/matvaredata/alt-matvare"
                  label="Alt om en matvare"
                  handleClose={handleClose}
                  disabled={keycloak?.hasRealmRole(UserRole.ReadOnly)}
                />
                <SimpleLink
                  exact
                  to="/home/rapport/matvaredata/erstatningsmatvarer"
                  label="Erstatningsmatvarer"
                  handleClose={handleClose}
                  disabled
                />
                <SimpleLink
                  exact
                  to="/home/rapport/matvaredata/kodebok-gruppert"
                  label="Kodebok gruppert"
                  handleClose={handleClose}
                  disabled
                />
                <SimpleLink
                  exact
                  to="/home/rapport/matvaredata/matvaregrupper"
                  label="Matvaregrupper"
                  handleClose={handleClose}
                  disabled
                />
                <SimpleLink
                  exact
                  to="/home/rapport/matvaredata/matvarekoder"
                  label="Matvarekoder"
                  handleClose={handleClose}
                  disabled
                />
                <SimpleLink
                  exact
                  to="/home/rapport/matvaredata/matvarer-dicerse"
                  label="Matvarer diverse"
                  handleClose={handleClose}
                  disabled
                />
                <SimpleLink
                  exact
                  to="/home/rapport/matvaredata/matvarer-oppskrifter"
                  label="Matvarer og oppskrifter"
                  handleClose={handleClose}
                  disabled={keycloak?.hasRealmRole(UserRole.ReadOnly)}
                />
                <SimpleLink
                  exact
                  to="/home/rapport/matvaredata/matvarer-sortertkode"
                  label="Matvarer sortert etter kode"
                  handleClose={handleClose}
                  disabled
                />
                <SimpleLink
                  exact
                  to="/home/rapport/matvaredata/matvarer-sortertnavn"
                  label="Matvarer sortert etter navn"
                  handleClose={handleClose}
                  disabled
                />
                <SimpleLink
                  exact
                  to="/home/rapport/matvaredata/stoffer"
                  label="Stoffer"
                  handleClose={handleClose}
                  disabled
                />
                <SimpleLink
                  exact
                  to="/home/rapport/matvaredata/stoffgrupper-stoffer"
                  label="Stoffgruppe stoffer"
                  handleClose={handleClose}
                  disabled
                />
              </SimpleSubMenu>
            </SimpleMenu>
            <SimpleMenu
              ref={ref}
              label="Administrasjon"
              handleClose={handleClose}
              closeAll={closeAll}
              classes={classes}
              classesHeader={classesHeader}
            >
              <SimpleLink
                to="/home/admin/defaultverdier"
                label="Defaultverdier for bruker"
                handleClose={props.handleClose}
                disabled={keycloak?.hasRealmRole(UserRole.ReadOnly)}
              />
              <SimpleLink
                to={
                  keycloak?.hasRealmRole(UserRole.Admin)
                    ? `/home/admin/brukerliste`
                    : `/home`
                }
                label="Brukerliste"
                handleClose={props.handleClose}
                disabled={
                  !(
                    keycloak?.hasRealmRole(UserRole.Admin) ||
                    keycloak?.hasRealmRole(UserRole.Akf)
                  )
                }
              />
              {/*  <SimpleLink
                to={keycloak?.hasRealmRole(UserRole.Admin) ? `/home/admin/infopage` : `/home`}
                label="Informasjon"
                handleClose={props.handleClose}
                disabled={!(keycloak?.hasRealmRole(UserRole.Admin) || keycloak?.hasRealmRole(UserRole.Akf))}
              /> */}
              <SimpleLink
                to={
                  keycloak?.hasRealmRole(UserRole.Admin)
                    ? `/home/admin/koderegister`
                    : `/home`
                }
                label="Koderegister"
                handleClose={props.handleClose}
                disabled={
                  !(
                    keycloak?.hasRealmRole(UserRole.Admin) ||
                    keycloak?.hasRealmRole(UserRole.Akf)
                  )
                }
              />
              <SimpleLink
                to="/home/admin/defaultAHV"
                label="Defaultverdier alder, høyde, vekt"
                handleClose={props.handleClose}
                disabled={keycloak?.hasRealmRole(UserRole.ReadOnly)}
              />
            </SimpleMenu>
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              sx={classes.buttonmenu}
            >
              <Typography>
                <b>Matvaredatabase</b>
              </Typography>
              <SelectDatabase />
            </Stack>
          </Stack>
        </>
      )}
    </AppBar>
  );
}
