import { Dispatch, SetStateAction } from 'react';

export async function ApiCall<T>(
  uri: string,
  method: string,
  token: string,
  setData?: Dispatch<SetStateAction<T>>,
  body?: any
) {
  const backendURI = process.env.REACT_APP_BACKEND;
  let fullUri = `${backendURI}/${uri}`;
  let response: Response = await fetch(encodeURI(fullUri), {
    method: method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
    body: body ? JSON.stringify(body) : undefined
  });
  if (response.ok) {
    if (setData) {
      const json: T = await response.json();
      setData(json);
    } else return response;
  } else console.error(response.statusText);
}
