import React from 'react';
import { DialogBox } from '../../../utilities/DialogBox';
import { UtvalgteMatvareGruppe } from '../matvaregruppe/UtvalgteMatvareGruppe';
import { Matvare, MatvaregruppeI, resultatGruppeI } from '../types/matvaregruppeTypes';
import { NButton } from '../../components/Inputs';
import { Grid } from '@mui/material';
import { removeArrayFromAnotherArray } from '../../../utilities/ArrayFunctions';

interface UtvalgteMatvareGruppeProps {
  matvareRadioButton: number;
  setMatvareRadioButton: (value: number) => void;
  showMatvaregruppe: boolean;
  toggleShowMatvaregrupper: (val: boolean) => void;
  selectedMatvares?: Matvare[];
  setSelectedMatvares?: (mat: Matvare[]) => void;
  selectedMatvaregruppes?: MatvaregruppeI[];
  setSelectedMatvaregruppes?: (mg: MatvaregruppeI[]) => void;
  selectedResultatGroup: resultatGruppeI;
  setSelectedResultatGroup: (value: resultatGruppeI) => void;
  setShowProgress: (value: boolean) => void;
  setProgressText: (value: string) => void;
  saveData: () => void;
  deleteAllMatvareInMG: (data: {}) => void;
  deleteAllMatvares: () => void;
  totalCounts: number;
}
export const MatvareGruppeTablePopup = (props: UtvalgteMatvareGruppeProps) => {
  const handleOkBtn = () => {
    props.toggleShowMatvaregrupper(false);
    props.saveData();
  };

  return (
    <DialogBox
      title={'Velg matvarer'}
      isShowing={props.showMatvaregruppe}
      hide={props.toggleShowMatvaregrupper}
    >
      <UtvalgteMatvareGruppe
        matvareRadioButton={props.matvareRadioButton}
        setMatvareRadioButton={props.setMatvareRadioButton}
        setShowProgress={props.setShowProgress}
        setProgressText={props.setProgressText}
        selectedMatvares={props.selectedMatvares}
        setSelectedMatvares={props.setSelectedMatvares}
        selectedMatvaregruppes={props.selectedMatvaregruppes}
        setSelectedMatvaregruppes={props.setSelectedMatvaregruppes}
        selectedResultatGroup={props.selectedResultatGroup}
        setSelectedResultatGroup={props.setSelectedResultatGroup}
        handleAvsluttBtn={handleOkBtn}
        deleteAllMatvareInMG={props.deleteAllMatvareInMG}
        deleteAllMatvares={props.deleteAllMatvares}
        totalCounts={props.totalCounts}
      />
      <Grid container padding={4}>
        <Grid container item xs={6} justifyContent="flex-start" alignItems="flex-end">
          <NButton children="Ok" onClick={handleOkBtn} />
        </Grid>
        <Grid container item xs={6} justifyContent="flex-end" alignItems="flex-end">
          <NButton
            children="Nullstill"
            variant="outlined"
            onClick={() => props.setSelectedMatvaregruppes([])}
          />
        </Grid>
      </Grid>
    </DialogBox>
  );
};
