import React, { useEffect, useState } from 'react';
import { Autocomplete, Button, Grid, InputLabel, TextField } from '@mui/material';
import { DialogBox } from '../../../utilities/DialogBox';
import useFetchData from '../../../utilities/useFetchData';
import { ConfirmationDialog } from '../../../utilities/ConfirmationDialog';
import { MatvareData } from '../types/MatvareTypes';
import useDidMountEffect from '../../../utilities/useDidMountEffect';
import IsOwnedByUser from '../../../utilities/Validate';
import { useUserContext } from '../../../../context/AuthContext';
import { OptionType } from '../../components/commons/CommonTypes';

interface CopyOppskriftProps {
  openCopyOppskrift: boolean;
  matvarekodeId: number;
  currentDatabase: OptionType;
  matvareNavn: string;
  showAlertComponent: any;
  setOpenCopyOppskrift?: any;
  ownedMatvares: MatvareData[];
  handleClickCopy: () => void;
}

const style = {
  width: 500,
  height: 200
};

export default function CopyOppskrift(props: CopyOppskriftProps) {
  const {
    openCopyOppskrift,
    handleClickCopy,
    matvarekodeId,
    matvareNavn,
    currentDatabase,
    setOpenCopyOppskrift,
    showAlertComponent,
    ownedMatvares
  } = props;
  const loggedInuser = useUserContext();
  const [newMatvareId, setNewMatvareId] = useState<number | string>('');
  const [newMatvareNavn, setNewMatvareNavn] = useState<string>('');
  const [notification, setNotification] = useState({ error: null, message: '' });
  const [isTouched, setIsTouched] = useState(false);
  const [showCDialog, setShowCDialog] = useState(false);
  const [dialogText, setDialogText] = useState('');
  const [copyOppskriftConfirmation, setCopyOppskriftConfirmation] = useState(false);
  //const [ownedMatvares, setOwnedMatvares] = useState<MatvareData[]>([]);
  const [ownedMatvarekodes, setOwnedMatvarekodes] = useState<number[]>([]);
  const [owned, setOwned] = useState(false);

  const matvareKodesWithNoRecipe = useFetchData({
    loadOnMount: false,
    clearDataOnLoad: false,
    projectURL: 'checkRecipe',
    subProjectURL: `${currentDatabase?.value}/${newMatvareId.toString()}`
  });
  const copyOppskrift = useFetchData({
    loadOnMount: false,
    clearDataOnLoad: false,
    projectURL: 'copyOppskrift',
    subProjectURL: `${currentDatabase?.value}/${matvarekodeId}/${newMatvareId}`
  });

  useDidMountEffect(() => {
    if (ownedMatvares?.length > 0) {
      const filteredMatvareString = ownedMatvares
        .map((row) => row.MATVAREKODEID)
        .join(',');
      matvareKodesWithNoRecipe.loadData(
        'checkRecipe',
        `${currentDatabase?.value}/${filteredMatvareString}`
      );
    }
  }, [ownedMatvares]);

  useDidMountEffect(() => {
    let ownedNumber = IsOwnedByUser({ id: +newMatvareId, u: loggedInuser });
    setOwned(ownedNumber);
    if (newMatvareId === '') {
      setNotification({
        error: false,
        message: ''
      });
    }
  }, [newMatvareId]);
  /*  useDidMountEffect(() => {
    if (matvareKodesWithNoRecipe.error) {
      showAlertComponent('Kopieringen er IKKE fullført.', 'error');
    }
  }, [matvareKodesWithNoRecipe.error]); */
  useDidMountEffect(() => {
    if (matvareKodesWithNoRecipe.data['codes']) {
      if (matvareKodesWithNoRecipe.data['codes']?.length > 0) {
        setOwnedMatvarekodes(matvareKodesWithNoRecipe.data['codes']);
        setNotification({
          error: false,
          message: ''
        });
      } else {
        setNotification({
          error: true,
          message:
            'Alle matvarene inneholder allerede oppskrift. Du må lage matvare først.'
        });
      }
    } else {
      showAlertComponent('Kopieringen er IKKE fullført.', 'error');
    }
  }, [matvareKodesWithNoRecipe.data]);

  useDidMountEffect(() => {
    if (copyOppskriftConfirmation) {
      //Kopi av innholdet i skjermbildet
      copyOppskrift.loadData(
        'copyOppskrift',
        `${currentDatabase?.value}/${matvarekodeId}/${newMatvareId}`
      );
    }
    setShowCDialog(false);
    setCopyOppskriftConfirmation(false);
  }, [copyOppskriftConfirmation]);

  useDidMountEffect(() => {
    if (copyOppskrift.error) {
      setOpenCopyOppskrift(false);
      showAlertComponent('Kopieringen er IKKE fullført.', 'error');
    }
    if (copyOppskrift.data) {
      if (copyOppskrift.data[0]) {
        if (copyOppskrift.data[0]?.SUCCESS === true) {
          setOpenCopyOppskrift(false);
          showAlertComponent('Kopieringen er fullført.', 'success');
        } else {
          setOpenCopyOppskrift(false);
          showAlertComponent('Kopieringen er IKKE fullført.', 'error');
        }
      }
    }
  }, [copyOppskrift.error, copyOppskrift.data]);

  const hadleCopyOppskrift = async (evt: React.FormEvent<HTMLFormElement>) => {
    if (notification.error === false) {
      setShowCDialog(true);
      setDialogText(
        `Vil du virkelig kopiere oppskrift fra matvare : ${matvarekodeId} - ${matvareNavn} til matvare : ${newMatvareId} - ${newMatvareNavn}?`
      );
    }
    evt.preventDefault();
  };

  const handleOnChange = (value: number | string) => {
    if (ownedMatvarekodes?.length !== 0) {
      setNotification({
        error: false,
        message: ''
      });
    }
    setNewMatvareId(value);
    const selected = ownedMatvares?.find(
      (item) => parseInt(item.MATVAREKODEID.toString()) === parseInt(value?.toString())
    );
    setNewMatvareNavn(selected?.NAVN);
    setIsTouched(true);
  };

  useEffect(() => {
    if (owned && isTouched) {
      setNotification({
        error: false,
        message: ''
      });
    } else if (!owned && isTouched) {
      setNotification({
        error: true,
        message: 'Velg matvarekode'
      });
    }
  }, [owned, isTouched]);

  return (
    <>
      {showCDialog && (
        <ConfirmationDialog
          title={'Kopi oppskrift'}
          text={dialogText}
          setConfirmationResult={setCopyOppskriftConfirmation}
          hide={() => {
            setShowCDialog(!showCDialog);
          }}
        />
      )}
      {openCopyOppskrift && (
        <DialogBox title={'Kopi av Oppskrift'} hide={handleClickCopy}>
          <div style={style}>
            <Grid container item xs={12} spacing={2}>
              <Grid item xs={12}>
                <InputLabel>Kopier innholdet i skjermbildet til : </InputLabel>
              </Grid>
            </Grid>

            <form
              onSubmit={hadleCopyOppskrift}
              style={{ width: 'inherit', display: 'inherit' }}
              id="copyOppskriftForm"
            >
              <Grid
                container
                spacing={3}
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-start"
              >
                <Grid item xs>
                  <InputLabel>Matvarekode </InputLabel>
                </Grid>
                <Grid item xs={6}>
                  <Autocomplete
                    id="combo-box-matvares"
                    size="small"
                    value={newMatvareId}
                    options={ownedMatvarekodes?.map((option) => option?.toString())}
                    onChange={(event, value) => handleOnChange(value)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Velg matvarekode"
                        variant="outlined"
                        error={notification.error}
                        helperText={notification.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={!owned}
                  >
                    Kopier
                  </Button>
                </Grid>
              </Grid>
            </form>
          </div>
        </DialogBox>
      )}
    </>
  );
}
