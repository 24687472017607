import { useCodeContext } from '../../../../context/CodeContext';

// HANDLING
export default function SelectedDropdown(
  personField: string | number | undefined,
  codeValue: number
) {
  const allCodes = useCodeContext();
  if (personField === undefined) return null;
  return allCodes?.codes?.find((a) => a.nr === personField && a.value === codeValue);
}
