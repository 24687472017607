import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps, AlertColor } from '@mui/material/Alert';
import { Box, ClickAwayListener, IconButton, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { SnackbarOrigin } from '@mui/material/Snackbar';

interface AlertI {
  alertMessage: string;
  alertColor: AlertColor;
  alertClosed?: boolean;
  autoHide?: number;
  verticalPosition?: SnackbarOrigin['vertical'];
  horizontalPosition?: SnackbarOrigin['horizontal'];
  showAlert: any;
  setShowAlert: any;
}

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = () => {
  const theme = useTheme();
  return {
    root: {
      display: 'flex',
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2)
      }
    },
    close: {
      padding: theme.spacing(0.5)
    }
  };
};

function AlertComp(props: AlertI) {
  const classes = useStyles();
  const alertCompProps: AlertI = {
    alertMessage: props.alertMessage || undefined,
    autoHide: props.autoHide || 6000,
    verticalPosition: props.verticalPosition || 'top',
    horizontalPosition: props.horizontalPosition || 'center',
    alertColor: props.alertColor || 'info',
    showAlert: props.showAlert || undefined,
    setShowAlert: props.setShowAlert || undefined
  };

  const handleClose = (event: any, reason: any) => {
    if (reason === 'clickaway') {
      return;
    }

    props.setShowAlert(false);
  };

  return (
    <Box sx={classes.root}>
      <Snackbar
        open={props.showAlert}
        key={alertCompProps.alertMessage}
        autoHideDuration={alertCompProps.autoHide}
        onClose={handleClose}
        anchorOrigin={{
          vertical: alertCompProps.verticalPosition,
          horizontal: alertCompProps.horizontalPosition
        }}
      >
        <div>
          <Alert
            severity={alertCompProps.alertColor}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                sx={classes.close}
                onClick={() => props.setShowAlert(false)}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            }
          >
            {alertCompProps.alertMessage}
          </Alert>
        </div>
      </Snackbar>
    </Box>
  );
}

export default AlertComp;
