import { useKeycloak } from '@react-keycloak/web';
import React, { useEffect } from 'react';
import useFetchData from '../../../utilities/useFetchData';
import {
  BeregningObject,
  DefaultBeregningCounts,
  UtvalgTableData
} from '../types/BeregningTypes';
import useDidMountEffect from '../../../utilities/useDidMountEffect';
import { useDatabaseContext } from '../../../../context/DatabaseContext';

interface saveMatvareInputProps {
  showAlertMessage: (messge: string, severity: any) => void;
  beregningObject: BeregningObject;
  setBeregningObject: (obj: BeregningObject) => void;
  setUtvalgData: (value: UtvalgTableData) => void;
  setTotalCounts: (value: DefaultBeregningCounts) => void;
  setShowProgress: (value: boolean) => void;
  setProgressText: (value: string) => void;
}
export const SaveInputs = (props: saveMatvareInputProps) => {
  const { keycloak } = useKeycloak();
  const token = keycloak?.token;
  const db = useDatabaseContext();
  const [isSaving, setIsSaving] = React.useState(false);
  const [saveCompleted, setSaveCompleted] = React.useState(false); // Flag for saving completion
  const {
    showAlertMessage,
    beregningObject,
    setBeregningObject,
    setTotalCounts,
    setUtvalgData,
    setShowProgress,
    setProgressText
  } = props;
  const retrieveDataFromUtvalgTable = useFetchData({
    loadOnMount: false,
    clearDataOnLoad: false,
    projectURL: 'getUtvalgTableData',
    subProjectURL: `${db.currentDatabase?.value}/${encodeURI(
      beregningObject?.utvalgId
    )}/${beregningObject?.matvareData?.gruppetypeId}`
  });
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    if (
      beregningObject.utvalgId !== '' &&
      beregningObject.databaseId !== 0 &&
      !beregningObject.inputsSaved &&
      !isSaving &&
      !saveCompleted
    ) {
      // Start the save process
      setIsSaving(true);
      setBeregningObject({ ...beregningObject, inputsSaved: true });
      saveBeregningInputs(signal);
    }
    return () => {
      // cancel the request before component unmounts
      if (saveCompleted) controller.abort();
    };
  }, [beregningObject.inputsSaved, saveCompleted]);

  useDidMountEffect(() => {
    if (retrieveDataFromUtvalgTable.data) {
      setSaveCompleted(true);
      const data = retrieveDataFromUtvalgTable?.data as unknown as UtvalgTableData;
      setTotalCounts({
        TOTALMATVAREGRUPPE: data?.matvaregroupData?.length,
        TOTALMATVARE: data?.matvaredata?.length,
        TOTALFJERNDEMATVARE: data?.fjerndeMatvareData?.length,
        TOTALSTOFFCOUNT: data?.stoffData?.length
      });
      setUtvalgData(data);
    }
  }, [retrieveDataFromUtvalgTable.data]);
  const loadUtvalgData = () => {
    retrieveDataFromUtvalgTable.loadData(
      'getUtvalgTableData',
      `${db.currentDatabase?.value}/${encodeURI(beregningObject?.utvalgId)}/${
        beregningObject?.matvareData?.gruppetypeId
      }`
    );
  };
  async function PostData(
    data: any,
    baseUrl: string,
    dataUrl: string,
    methods: string,
    token: any
  ) {
    const backendURI = process.env.REACT_APP_BACKEND;
    let url = `${backendURI}/${baseUrl}/${dataUrl}`;

    let response: Response;
    response = await fetch(encodeURI(url), {
      method: methods,
      headers: {
        Authorization: 'Bearer ' + token
      },
      body: JSON.stringify(data)
    });

    return response;
  }
  const saveBeregningInputs = async (signal: AbortSignal) => {
    console.log('saving...........');
    /**
     * We need the progressbar when we save huge number of matvare or matvaregruppe in beregning
     */
    //if(beregningObject?.matvareData?.individualMatvares?.length > 100 || beregningObject?.matvareData?.matvaregroups?.length > 100){
    setProgressText('Lagrer input data...');
    setShowProgress(true);
    try {
      const resp = await PostData(
        { data: beregningObject },
        'saveBeregningObject',
        '',
        'POST',
        token
      );
      if (!signal?.aborted) {
        /*  */
        if (resp.status === 200) {
          console.log('Beregening utvalgene er lagret.');
          loadUtvalgData();
        } else if (resp.status === 500) {
          console.log('An error 500', resp.status);
          showAlertMessage('Lagring av beregening valgene mislyktes.', 'error');
        }
      }
      setIsSaving(false);
      setShowProgress(false);
      setProgressText('');
    } catch (error) {
      console.error('Save failed', error);
      setIsSaving(false); // End the saving process if there's an error
    }
  };
  /*  const validData = () => {
   let isNotEmpty = false;
   if(beregningObject?.matvareData?.individualMatvares?.length > 0){
     isNotEmpty = true;
   }
   if(beregningObject?.matvareData?.matvaregroups?.length > 0){
     isNotEmpty = true;
   }
   if(beregningObject?.matvareData?.highLevelCategory > 0){
     isNotEmpty = true;
   }
   if(beregningObject?.matvareData?.matvaresInGroup){
     isNotEmpty = true;
   }
   if(beregningObject?.stoffData?.individualStoffs?.length > 0){
     isNotEmpty = true;
   }
   if(beregningObject?.stoffData?.stoffGroup?.length > 0){
     isNotEmpty = true;
   }
   return isNotEmpty;
 }; */
  return <div></div>;
};
