import React, { useEffect, useState, useContext } from 'react';
import { Button, Stack, TextField } from '@mui/material';
import { DialogBox } from '../../utilities/DialogBox';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Grid
} from '@mui/material';
import useFetchData from '../../utilities/useFetchData';
import { DatabaseContext } from '../../../context/DatabaseContext';
import { SingleStoff, StoffgruppeI } from '../stoff/types/StoffTypes';
import { NButton } from '../components/Inputs';
import { StoffGruppe } from '../matvare/types/StoffTypes';

interface CopyStoffgruppePopup {
  showCopyStoff: boolean;
  toggleCopygruppeStoff: () => void;
  stoffGruppe: StoffgruppeI;
  postData: any;
  showErrorAlert: any;
  navigateToStoffGroupe: (stoffrid: number) => void;
  stoffInStoffgruppe: SingleStoff[];
  addStoffToNewStoffgruppe: (stoffrid: number) => void;
  setRefetchList: (value: boolean) => void;
}
interface CopyData {
  navn: string;
  oldStoffgruppeid: number;
}
const CopyStoffgruppePopup = (props: CopyStoffgruppePopup) => {
  const { currentDatabase } = useContext(DatabaseContext);
  const backendURI = process.env.REACT_APP_BACKEND;

  const [newStoffgruppe, setNewStoffgruppe] = useState<StoffGruppe>({
    BESKRIVELSE: props.stoffGruppe.beskrivelse,
    NAVN: '',
    STOFFGRUPPEID: 0 // get a new ID here. using getNextStoffid
    // BRUTTOBEREGNING: props.stoffGruppe.BRUTTOBEREGNING
  });

  const [dataIsValid, setDataIsValid] = useState(false);

  /* const getNextStoffid = useFetchData({
    loadOnMount: true,
    clearDataOnLoad: false,
    projectURL: 'getNextStoffgruppeId',
    subProjectURL: `${currentDatabase?.value}`
  });
 */
  const doseStoffGruppeNameExist = useFetchData({
    loadOnMount: false,
    clearDataOnLoad: false,
    projectURL: 'getStoffgruppeNameCount',
    subProjectURL: `${currentDatabase?.value}/${newStoffgruppe.NAVN}`
  });

  const [navnError, setNavnError] = useState({
    error: false,
    message: ''
  });

  const [copyOptionsValue, setCopyOptionsValue] = useState('1');

  const handleChangeCopyOptions = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCopyOptionsValue((event.target as HTMLInputElement).value);
  };

  const handleChangeStoffgruppe = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setNavnError({
      error: false,
      message: ''
    });
    setNewStoffgruppe({
      ...newStoffgruppe,
      [name]: value
    });
  };
  /*   useEffect(() => {
      if (getNextStoffid.data && getNextStoffid.data[0]) {
        setNewStoffgruppe({
          ...newStoffgruppe,
          ['STOFFGRUPPEID']: getNextStoffid.data[0].NEXTID
        });
      }
    }, [getNextStoffid.isLoaded]); */

  useEffect(() => {
    if (doseStoffGruppeNameExist.data) {
      if (doseStoffGruppeNameExist.data[0]?.COUNT > 0) {
        setNavnError({
          error: true,
          message: 'Navnet eksisterer fra før. Bruke et unikt navn.'
        });
      } else {
        setNavnError({
          error: false,
          message: ''
        });
      }
    }
  }, [doseStoffGruppeNameExist.data]);

  /*   useEffect(() => {
    if (owned && isTouched) {
      setStoffsError({
        error: false,
        message: ''
      });
    } else if (!owned && isTouched) {
      setStoffsError({
        error: true,
        message: 'Nummeret er ikke i nummerområdet ditt'
      });
    }
  }, [owned, isTouched]);
 */
  /*   const navigateToStoffGroupe = (stoffgruppeId?: number) => {
    const timer = setTimeout(() => {
      history.push(`/home/matvaredata/stoffgruppe/${stoffgruppeId}`);
    }, 1000);
    return () => clearTimeout(timer);
  }; */

  useEffect(() => {
    if (navnError.error) {
      setDataIsValid(false);
    } else {
      setDataIsValid(true);
    }
  }, [navnError.error]);

  const closePopupWindow = () => {
    const timer = setTimeout(() => {
      props.toggleCopygruppeStoff();
    }, 500);
    return () => clearTimeout(timer);
  };

  const handleCopyStoffgruppe = (
    evt: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    doseStoffGruppeNameExist.loadData(
      'getStoffgruppeNameCount',
      `${currentDatabase?.value}/${newStoffgruppe.NAVN}`
    );

    let url = `${backendURI}/copyStoffgruppe/${currentDatabase?.value}`;
    try {
      props
        .postData(
          {
            data: {
              navn: newStoffgruppe.NAVN,
              oldStoffgruppeid: props.stoffGruppe.stoffgruppeId
            }
          },
          url,
          'POST'
        )
        .then((resp: any) => {
          if (resp.status === 200) {
            //props.addStoffToNewStoffgruppe(newStoffgruppe.STOFFGRUPPEID);
            props.showErrorAlert('success', 'Kopieringen er fullført');
            props.setRefetchList(true);
            //props.navigateToStoffGroupe(newStoffgruppe.STOFFGRUPPEID);
            closePopupWindow();
          } else {
            props.showErrorAlert(
              'error',
              'Kopieringen mislyktes. Det har ikke blitt gjort endringer mot databasen.'
            );
          }
        });
    } catch (error) {
      props.showErrorAlert(
        'error',
        'Det har oppstått en feil. Vennligst prøv igjen senere.'
      );
    }
    evt.preventDefault();
  };

  return (
    <DialogBox
      title={'Kopier stoffguppe'}
      isShowing={props.showCopyStoff}
      hide={props.toggleCopygruppeStoff}
    >
      <div style={{ width: 900 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box borderColor="secondary.main" padding={2}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Kopieringsmetode</FormLabel>
                <RadioGroup
                  aria-label="position"
                  name="copyOptions"
                  value={copyOptionsValue}
                  onChange={handleChangeCopyOptions}
                >
                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="Kopi av innholdet i skjermbildet"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
          </Grid>
          {/*   <form
            onSubmit={handleCopyStoffgruppe}
            style={{ width: 'inherit', display: 'inherit' }}
          > */}
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <h4>Gammel</h4>
              <p style={{ padding: '15px 0 5px 0' }}>
                Navn <b>{props.stoffGruppe.navn || ''}</b>
              </p>
            </Grid>
            <Grid item xs={4}>
              <h4>Ny</h4>
              <TextField
                id="Navn"
                label="Navn"
                variant="outlined"
                size="small"
                value={newStoffgruppe.NAVN}
                onChange={handleChangeStoffgruppe}
                name={'NAVN'}
                style={{ padding: '5px 0 5px 0' }}
                type="text"
                inputProps={{
                  maxLength: 30
                }}
                required
                error={navnError.error}
                helperText={navnError.message}
              />
            </Grid>
            <Grid item xs={12}>
              <Stack direction={'row'} spacing={2}>
                <NButton
                  children="Kopier"
                  color="primary"
                  disabled={!dataIsValid}
                  onClick={(e) => handleCopyStoffgruppe(e)}
                />
                <NButton children="Nullstill" variant="outlined" />
              </Stack>
            </Grid>
            <Grid item xs={4}></Grid>
          </Grid>
          {/*           </form>
           */}{' '}
        </Grid>
      </div>
    </DialogBox>
  );
};
export default CopyStoffgruppePopup;
