import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { matvareReducer } from '../reducers/matvares';
import { AppActions } from '../types/actions';
import { alertReducer } from '../reducers/alerts';
import { prosjektReducer } from '../reducers/prosjekts';
import { dialogReducer } from '../reducers/dialogs';
import { undersokelseReducer } from '../reducers/undersokelses';
import { locationReducer } from '../reducers/locations';
import { progressReducer } from '../reducers/progress';
import { beregningReducer } from '../reducers/beregnings';
import { matvaregruppeReducer } from '../reducers/matvaregruppe';
import { matvaresInMatvaregruppeReducer } from '../reducers/matvaresInMatvaregruppe';

export const rootReducer = combineReducers({
  matvares: matvareReducer,
  alerts: alertReducer,
  prosjekts: prosjektReducer,
  dialogs: dialogReducer,
  undersokelses: undersokelseReducer,
  locations: locationReducer,
  progress: progressReducer,
  beregnings: beregningReducer,
  matvaregruppe: matvaregruppeReducer,
  matIGruppe: matvaresInMatvaregruppeReducer
});

export type AppState = ReturnType<typeof rootReducer>;

export const store = createStore(rootReducer, applyMiddleware(thunk));
