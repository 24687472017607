import React, { useEffect, useState } from 'react';
import {
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { Matvare, MatvareResult } from '../types/matvaregruppeTypes';
import useStylesTable from '../../../../css/TableCss';
import { SingleInput } from '../components/SingleInput';
import { ChatBubbleButtonSingle } from '../../components/ChatBubbleButton';
import useFetchData from '../../../utilities/useFetchData';
import MatvareSearch from '../MatvareSearch';

interface MatvareProps {
  currentDatabase: any;
  addedMatvarerList: Matvare[];
  matvareCount: number;
  showProgress: boolean;
  setAddedMatvarerList: (list: Matvare[]) => void;
  showAlertComponent: (message: string, severity: any) => void;
  setRemovedMatvare: (mat: Matvare) => void;
  setShowProgress: (value: boolean) => void;
  setMatvareCount: (count: number) => void;
}
export const UtvalgteMatvareTable = (props: MatvareProps) => {
  const {
    currentDatabase,
    addedMatvarerList,
    setAddedMatvarerList,
    showAlertComponent,
    setRemovedMatvare,
    matvareCount,
    setMatvareCount,
    showProgress,
    setShowProgress
  } = props;
  const classesTable = useStylesTable();
  const [newMat, setNewMat] = useState<Matvare>();
  const [newMatTyped, setNewMatTyped] = useState<Matvare>();
  const [allMatvares, setAllMatvares] = useState<MatvareResult[]>();
  const [showMatvareKode, setShowMatvareKode] = useState(false);
  const [codeIsValid, setCodeIsValid] = useState(false);
  const getAllMatvares = useFetchData({
    loadOnMount: true,
    clearDataOnLoad: false,
    projectURL: 'getMatvareList',
    subProjectURL: `${currentDatabase?.value}/${'1'}/${'999999'}`
  });
  useEffect(() => {
    if (getAllMatvares.data) {
      setAllMatvares(getAllMatvares.data);
    }
  }, [getAllMatvares.data]);
  useEffect(() => {
    if (codeIsValid) {
      handleTableChange(newMatTyped);
    }
  }, [codeIsValid]);
  useEffect(() => {
    if (newMat) {
      handleTableChange(newMat);
    }
  }, [newMat]);

  const handleTableChange = (matvare: Matvare) => {
    if (matvare !== undefined) {
      if (matvare.MATVAREKODEID !== 0) {
        const found = addedMatvarerList?.find(
          (item: Matvare) => item.MATVAREKODEID === matvare.MATVAREKODEID
        );
        if (!found) {
          setAddedMatvarerList([...addedMatvarerList, matvare]);
          setNewMat({ MATVAREKODEID: 0, NAVN: '' });
        } else {
          showAlertComponent(
            `Matvare ${matvare.MATVAREKODEID} er inkludert allerede`,
            'error'
          );
        }
        setCodeIsValid(false);
        setNewMatTyped({ ...newMatTyped, MATVAREKODEID: 0 });
      }
    }
  };
  const handleEnterKeyPress = (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === 'Enter') {
      const found = allMatvares?.find(
        (mat: MatvareResult) => mat.MATVAREKODEID === newMatTyped.MATVAREKODEID
      );
      if (!(found === undefined || newMatTyped.MATVAREKODEID === 0)) {
        setNewMatTyped({ ...newMatTyped, NAVN: found.NAVN });
        setCodeIsValid(true);
      } else {
        showAlertComponent(`Matvaren finnes ikke.`, 'error');
        setCodeIsValid(false);
        setNewMatTyped({ ...newMatTyped, MATVAREKODEID: 0 });
      }
    }
  };
  const handleCancel = (kode: number) => {
    const index = addedMatvarerList?.findIndex(
      (mat: Matvare) => mat.MATVAREKODEID === kode
    );
    const item = addedMatvarerList?.splice(index, 1);
    setAddedMatvarerList(addedMatvarerList);
    setNewMat({ MATVAREKODEID: 0, NAVN: '' });
    if (item !== undefined) {
      setRemovedMatvare(item[0]);
    }
    setMatvareCount(matvareCount - 1);
  };

  const handleNewRow = (name: string, value: string | number) => {
    setNewMatTyped({ ...newMatTyped, [name]: parseInt(value.toString()) });
    setMatvareCount(matvareCount + 1);
  };
  const showAllFoodItems = () => {
    setShowMatvareKode(!showMatvareKode);
  };

  return (
    <div>
      {showMatvareKode && (
        <MatvareSearch
          setMatvare={(id, navn) => {
            setNewMat({
              MATVAREKODEID: parseInt(id.toString()),
              NAVN: navn
            });
          }}
          showing={showMatvareKode}
          showProgress={showProgress}
          setShowProgress={setShowProgress}
          hide={() => setShowMatvareKode(false)}
        />
      )}
      <Table sx={classesTable.body}>
        <TableHead sx={classesTable.head}>
          <TableRow>
            <TableCell sx={classesTable.head}>Kode</TableCell>
            <TableCell sx={classesTable.head}>Navn</TableCell>
            <TableCell sx={classesTable.head}>Fjern</TableCell>
          </TableRow>
        </TableHead>
        <TableBody sx={classesTable.root}>
          {addedMatvarerList?.length > 0 ? (
            addedMatvarerList?.map((row: Matvare) => {
              return (
                <>
                  <TableRow key={row?.MATVAREKODEID} sx={classesTable.tableRow}>
                    <TableCell padding="checkbox" sx={classesTable.tableRow}>
                      <Link
                        target="_blank"
                        href={'/home/matvaredata/matvare/' + row?.MATVAREKODEID}
                        underline={'hover'}
                      >
                        {row?.MATVAREKODEID}
                      </Link>{' '}
                    </TableCell>
                    <TableCell sx={classesTable.tableRow}>{row?.NAVN}</TableCell>
                    <TableCell sx={classesTable.tableRow}>
                      <IconButton
                        aria-label="cancel"
                        color="primary"
                        onClick={() => {
                          handleCancel(row?.MATVAREKODEID);
                        }}
                        disabled={false}
                      >
                        <ClearIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                </>
              );
            })
          ) : (
            <TableRow sx={classesTable.tableRow}>
              <SingleInput
                value={newMatTyped?.MATVAREKODEID || 0}
                name={'MATVAREKODEID'}
                owned={true}
                handleChangeTable={handleNewRow}
                handleOnEnterKeyPress={handleEnterKeyPress}
                size={'50%'}
                keyDown={true}
                placeholderText={'Legg til ny matvarekode'}
              />
              <TableCell sx={classesTable.tableRow}>
                <ChatBubbleButtonSingle
                  name={'MatvareKode'}
                  handleOpenPopup={showAllFoodItems}
                />
              </TableCell>

              <TableCell sx={classesTable.tableRow}></TableCell>
            </TableRow>
          )}
          {addedMatvarerList?.length > 0 ? (
            <TableRow sx={classesTable.tableRow}>
              <SingleInput
                value={newMatTyped?.MATVAREKODEID || 0}
                name={'MATVAREKODEID'}
                owned={true}
                handleChangeTable={handleNewRow}
                handleOnEnterKeyPress={handleEnterKeyPress}
                size={'50%'}
                keyDown={true}
                placeholderText={'Legg til ny matvarekode'}
              />
              <TableCell sx={classesTable.tableRow}>
                <ChatBubbleButtonSingle
                  name={'MatvareKode'}
                  handleOpenPopup={showAllFoodItems}
                />
              </TableCell>

              <TableCell sx={classesTable.tableRow}></TableCell>
            </TableRow>
          ) : undefined}
        </TableBody>
      </Table>
    </div>
  );
};
