import React from 'react';
import { Box, Button } from '@mui/material';
import { useHistory } from 'react-router';
import { NButton } from './components/Inputs';

export const NotFound = () => {
  const history = useHistory();
  function toHome() {
    history.push('/login');
  }
  return (
    <Box id="container">
      <h1>Not found! 404!</h1>
      <p>The page you are looking for does not exist!</p>
      <NButton children="Got to home page" onClick={toHome} />
    </Box>
  );
};
