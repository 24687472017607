import React, { useEffect, useState } from 'react';
import {
  InputLabel,
  MenuItem,
  Select,
  Grid,
  Divider,
  TextField,
  SelectChangeEvent,
  Stack,
  RadioGroup,
  Paper,
  Radio,
  FormControlLabel
} from '@mui/material';
//import Textarea from '@mui/joy/Textarea';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import useStyles from '../../BeregningCss';
import { DialogBox } from '../../../../utilities/DialogBox';
import {
  arrayInputValuesI,
  BeregnFilterValuesI,
  CodeRegisterI,
  filterOptionI,
  filterQueryDataI,
  OptionI,
  selectedOptionArrayI,
  selectedOptionI
  /* selectedVariableAndSvarsI,
  TillegsvariableI,
  TillegsvariableSvarI,
  variabelAndSvarsI */
} from '../../types/BeregningTypes';
import {
  Checkbox,
  FormControl,
  FormHelperText,
  //FormLabel,
  IconButton,
  ListItemText,
  OutlinedInput
} from '@mui/material';
import {
  bildebok,
  komsumFilterData,
  operators,
  vanligdag,
  weekdays
} from '../../../../../assets/BeregningFilterOptions';
import { DisplayActivatedOptions } from './SelectedFilterDisplay';
import { NButton } from '../../../components/Inputs';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
interface konsumfilterProps {
  beregnFilterValues: BeregnFilterValuesI;
  setFilterParams: (value: filterQueryDataI[]) => void;
  //persons: number;
  showKonsumfilter: boolean;
  showAlertMessage: (msg: string, severity: any) => void;
  activatedOptions: selectedOptionArrayI[];
  setActivatedOptions: (value: selectedOptionArrayI[]) => void;
  displayActivatedOptions: (value: selectedOptionArrayI) => void;
  konsumFilterCriteria: string[];
  setKonsumFilterCriteria: (value: string[]) => void;
  logicalOperator: string;
  setLogicalOperator: (value: string) => void;
  updateBeregningObject: (item: string, name: string, value: any) => void;
  hide: (value: boolean) => void;
}
const emptyOption: OptionI[] = [];
const emptyValues: string[] = [];
const initialOption = {
  columnName: '',
  label: '',
  kodeId: 0,
  hasPredefinedValue: false,
  predefinedValues: emptyOption,
  svars: emptyOption
};
const initialSelectedOption = {
  selectedUtvalg: initialOption,
  selectedOperatorOne: { value: 0, label: '' },
  selectedOperatorTwo: { value: 0, label: '' },
  nonCategoryValues: { parameter: '', values: emptyValues },
  categoryValues: emptyOption,
  variableSvarArray: {
    variabel: { value: 0, label: '' },
    svars: emptyOption
  }
};
export const KonsumFilter = (props: konsumfilterProps) => {
  const {
    beregnFilterValues,
    setFilterParams,
    // persons,
    showKonsumfilter,
    activatedOptions,
    setActivatedOptions,
    displayActivatedOptions,
    konsumFilterCriteria,
    setKonsumFilterCriteria,
    logicalOperator,
    setLogicalOperator,
    updateBeregningObject,
    showAlertMessage,
    hide
  } = props;
  const classes = useStyles();
  const komsumfilterOptions: filterOptionI[] = komsumFilterData;

  const [activatedOption, setActivatedOption] =
    useState<selectedOptionI>(initialSelectedOption);
  const [selectedFilterOptions, setSelectedFilterOptions] =
    useState<filterOptionI>(initialOption);
  const [selectedFilter, setSelectedFilter] = useState<filterOptionI>(initialOption);
  const [textValue, setTextValue] = useState('');
  const [firstNumericValue, setFirstNumericValue] = useState(0);
  const [secondNumericValue, setSecondNumericValue] = useState(0);
  const [firstOperator, setFirstOperator] = useState<OptionI>();
  const [secondOperator, setSecondOperator] = useState<OptionI>();
  const [showKodeRegister, setShowKodeRegister] = useState<boolean>(false);
  const [konsumFilterForm, setKonsumFilterForm] =
    useState<filterOptionI[]>(komsumfilterOptions);
  const [customizedOperator, setCustomizedOperator] = useState<OptionI[]>(operators);
  const [selectedCategoryValues, setSelectedCategoryValues] = useState<string[]>([]);
  const [isText, setIsText] = useState(false);
  const [multipleValue, setMultipleValue] = useState(false);
  const [enableActivate, setEnableActivate] = useState(false);
  const [showSecondTextBox, setShowSecondTextBox] = useState(false);

  useEffect(() => {
    if (beregnFilterValues?.CODEREGISTERS?.length > 0) {
      getPredefinedValues();
    }
  }, [beregnFilterValues]);

  useEffect(() => {
    const selectedUtvalg = activatedOption?.selectedUtvalg;
    if (!selectedUtvalg) {
      setEnableActivate(false);
    } else {
      const { hasPredefinedValue } = selectedUtvalg || {};
      const nonCategoryValues = activatedOption?.nonCategoryValues;
      const shouldDisable =
        (hasPredefinedValue && activatedOption?.categoryValues === undefined) ||
        (!hasPredefinedValue && nonCategoryValues === undefined) ||
        (!hasPredefinedValue && !nonCategoryValues?.values[0]) ||
        (!hasPredefinedValue &&
          showSecondTextBox &&
          (!nonCategoryValues?.values[1] || nonCategoryValues?.values[1] === ''));
      setEnableActivate(!shouldDisable);
    }
  }, [activatedOption]);

  useEffect(() => {
    setShowSecondTextBox(false);
    setMultipleValue(false);
  }, [activatedOption?.selectedUtvalg]);

  const getPredefinedValues = () => {
    const newKonsumFilterForm: filterOptionI[] = konsumFilterForm?.map(
      (pf: filterOptionI) => {
        if (pf.kodeId !== 0) {
          const fValues = beregnFilterValues?.CODEREGISTERS?.filter(
            (code: CodeRegisterI) => code.value === pf.kodeId
          );
          console.log('navns: ', fValues, pf.kodeId);

          pf.predefinedValues = fValues?.map((n: CodeRegisterI) => {
            return {
              value: n.nr,
              label: n.label
            };
          });
        }
        if (pf.label === 'INTERVJUER_ID') {
          pf.predefinedValues = beregnFilterValues?.INTERWIEWIDS?.map((navn) => {
            return {
              value: navn.NAVN,
              label: navn.NAVN
            };
          });
        }
        if (pf.label === 'UKEDAG') {
          pf.predefinedValues = weekdays?.map((day) => {
            return {
              value: day,
              label: day
            };
          });
        }
        if (pf.label === 'VANLIGDAG') {
          pf.predefinedValues = vanligdag;
        }
        if (pf.label === 'BILDEBOK') {
          pf.predefinedValues = bildebok;
        }
        return { ...pf, predefinedValues: pf.predefinedValues };
      }
    );
    setKonsumFilterForm(newKonsumFilterForm);
  };
  const handleKonsumOptionSelect = (event: SelectChangeEvent) => {
    const option: filterOptionI = konsumFilterForm?.find(
      (op: filterOptionI) => op.label === event.target.value
    );
    setSelectedFilterOptions(option);
    let op = [
      { value: '=', label: '=' },
      { value: '!=', label: '!=' }
    ];
    if (option?.hasPredefinedValue) {
      setShowKodeRegister(true);
      setIsText(false);
    } else {
      if (option?.label === 'IDENTITET') {
        setIsText(true);
        op.push({ value: 'Like', label: 'Ligner' });
      } else {
        setIsText(false);
        op = operators;
      }
      setShowKodeRegister(false);
    }
    setCustomizedOperator(op);

    setSelectedFilter(option);
    setActivatedOption({ ...activatedOption, selectedUtvalg: option });
  };
  const handleOperator = (value: string, singleValue: boolean) => {
    if (value?.toUpperCase() === 'BETWEEN') {
      setMultipleValue(true);
      setShowSecondTextBox(false);
    } else {
      if (!showSecondTextBox) setShowSecondTextBox(false);
    }
    const currentOperator: OptionI = customizedOperator?.find(
      (op: OptionI) => op.value === value
    );
    if (singleValue) {
      setFirstOperator(currentOperator);
      setActivatedOption({
        ...activatedOption,
        selectedOperatorOne: currentOperator,
        selectedOperatorTwo: undefined
      });
    } else {
      setSecondOperator(currentOperator);
      setActivatedOption({ ...activatedOption, selectedOperatorTwo: currentOperator });
    }
  };
  const handleCategory = (event: { target: { value: any } }) => {
    const value = event.target.value;
    const currentValuelist: OptionI[] = [];
    for (const val of value) {
      const item = activatedOption?.selectedUtvalg?.predefinedValues?.find(
        (op: OptionI) => op.value === val
      );
      const currentValue = { value: val, label: item?.label };
      currentValuelist.push(currentValue);
    }
    setSelectedCategoryValues(value);
    setActivatedOption({ ...activatedOption, categoryValues: currentValuelist });
  };
  const handleInputValues = (value: string, singleValue: boolean) => {
    const label = activatedOption?.selectedUtvalg?.label;
    const currentValuelist: arrayInputValuesI = {
      parameter: '',
      values: []
    };
    if (!singleValue) {
      let values: string[] = [firstNumericValue?.toString()];
      values.push(value);
      currentValuelist.values = values;
      currentValuelist.parameter = label;
    } else {
      currentValuelist.values = [value];
      currentValuelist.parameter = label;
    }
    setActivatedOption({ ...activatedOption, nonCategoryValues: currentValuelist });
  };
  const handleClickAktiver = () => {
    resetValues();
    const found = activatedOptions?.find(
      (element: selectedOptionArrayI) =>
        element.option?.selectedUtvalg?.label === activatedOption?.selectedUtvalg?.label
    );
    if (found !== undefined) {
      showAlertMessage(
        'Denne utvalgen er valgt allerede, du må fjerne det fra den valgte først',
        'error'
      );
      return;
    }
    setActivatedOptions([...activatedOptions, { option: activatedOption }]);
    const updatedActivatedOptions: selectedOptionArrayI[] = [...activatedOptions];
    const lastElement: selectedOptionArrayI = {
      option: activatedOption
    };
    updatedActivatedOptions.push(lastElement);
    updateFilter(updatedActivatedOptions);
    displayActivatedOptions(lastElement);
  };
  const updateSelectedOptionsOnDelete = (updatedArray: selectedOptionArrayI[]) => {
    setActivatedOptions(updatedArray);
    updateFilter(updatedArray);
  };
  const updateFilter = (selectedOptions: selectedOptionArrayI[]) => {
    const params: filterQueryDataI[] = selectedOptions?.map(
      (element: selectedOptionArrayI) => {
        const predefinedValue = element.option?.selectedUtvalg?.hasPredefinedValue;
        return {
          columnName: element.option?.selectedUtvalg?.columnName,
          operatorOne: element.option?.selectedOperatorOne?.value || '=',
          operatorTwo: element.option?.selectedOperatorTwo?.value,
          logicalOperator: logicalOperator,
          value: predefinedValue
            ? JSON.stringify(
                element.option?.categoryValues?.map((op: OptionI) => op?.value)
              )
            : JSON.stringify(element.option?.nonCategoryValues?.values)
        };
      }
    );
    setFilterParams(params);
    updateBeregningObject('kosthold', 'konsumFilterParams', params);
  };
  const resetValues = () => {
    setActivatedOption({
      selectedUtvalg: undefined,
      selectedOperatorOne: undefined,
      selectedOperatorTwo: undefined,
      nonCategoryValues: undefined,
      categoryValues: undefined
    });
    setEnableActivate(false);
    setShowSecondTextBox(false);
    setMultipleValue(false);
    setFirstNumericValue(0);
    setSecondNumericValue(0);
    setSelectedCategoryValues([]);
    setTextValue('');
    setSelectedFilter({
      columnName: '',
      label: '',
      kodeId: 0,
      hasPredefinedValue: false,
      predefinedValues: []
    });
    setFirstOperator({
      value: '',
      label: ''
    });
    setSecondOperator({
      value: '',
      label: ''
    });
    setLogicalOperator('OR');
  };
  const addTextBoxes = () => {
    setShowSecondTextBox(true);
    setMultipleValue(true);
  };
  const removeTextBoxes = () => {
    setFirstNumericValue(0);
    setShowSecondTextBox(false);
    setMultipleValue(false);
  };
  return (
    <DialogBox title={'Konsumfilter'} isShowing={showKonsumfilter} hide={hide}>
      <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end">
        {/*  <Grid>
          <InputLabel>
            <b>Antall: {persons}</b>
          </InputLabel>
        </Grid> */}
      </Grid>
      <Grid container direction="row" justifyContent="center" alignItems="center">
        <Stack direction={'row'} spacing={1}>
          <Grid item xs={5}>
            <InputLabel id="checkSelectbox-label">Velg utvalgsfelt</InputLabel>
            <FormControl sx={{ minWidth: 320 }}>
              <Select
                variant="outlined"
                sx={classes.Select}
                value={selectedFilter?.label}
                // displayEmpty= {displayEmpty}
                onChange={handleKonsumOptionSelect}
                required={true}
              >
                {konsumFilterForm?.map((option: filterOptionI) => (
                  <MenuItem key={option?.columnName} value={option?.label}>
                    {option?.label}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{''}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <InputLabel id="checkSelectbox-label">Velg operator</InputLabel>
            <FormControl sx={{ minWidth: 120 }}>
              <Select
                variant="outlined"
                sx={classes.Select}
                value={firstOperator?.value || ''}
                onChange={(e) => {
                  handleOperator(e.target.value as string, true);
                }}
                required={true}
              >
                {customizedOperator?.map((option: OptionI) => (
                  <MenuItem key={option?.value} value={option?.value}>
                    {option?.label}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{''}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            {showKodeRegister ? (
              <>
                <InputLabel id="checkbox-label">Koderegister/kategorier</InputLabel>
                <FormControl sx={{ minWidth: 120 }}>
                  <Select
                    labelId="multiple-checkbox-label"
                    id="multiple-checkbox"
                    multiple
                    value={selectedCategoryValues}
                    onChange={handleCategory}
                    input={<OutlinedInput label="include" />}
                    renderValue={(selectedOptions: any) =>
                      [...new Set(selectedOptions)]?.join(',')
                    }
                  >
                    {selectedFilterOptions?.predefinedValues?.map((option: OptionI) => (
                      <MenuItem key={option.value} value={option.value}>
                        <Checkbox
                          checked={selectedCategoryValues.indexOf(option.value) > -1}
                        />
                        <ListItemText primary={option.label} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            ) : (
              <>
                {isText ? (
                  <>
                    <InputLabel id="Angi-verdi-label">Angi verdi</InputLabel>
                    <FormControl sx={{ minWidth: 200 }}>
                      <TextField
                        sx={classes}
                        id={'verdi'}
                        name={'verdi'}
                        value={textValue || ''}
                        onChange={(e) => {
                          setTextValue(e.target.value);
                          handleInputValues(e.target.value, true);
                        }}
                        type={'text'}
                        variant={'outlined'}
                        required={false}
                        disabled={false}
                      />
                    </FormControl>
                  </>
                ) : (
                  <>
                    <>
                      <InputLabel id="Angi-verdi-label1">Angi verdi</InputLabel>
                      <Stack sx={{ minWidth: 120 }} direction={'row'}>
                        <FormControl sx={{ minWidth: 200 }}>
                          <TextField
                            sx={classes}
                            id={'verdiOne'}
                            name={'verdiOne'}
                            value={firstNumericValue === 0 ? '' : firstNumericValue}
                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                            onChange={(e) => {
                              setFirstNumericValue(+e.target.value);
                              handleInputValues(e.target.value, true);
                            }}
                            type={'text'}
                            variant={'outlined'}
                            required={false}
                            disabled={false}
                          />
                        </FormControl>
                        <IconButton color="primary" onClick={addTextBoxes}>
                          <AddCircleOutlineIcon />
                        </IconButton>
                      </Stack>
                    </>
                  </>
                )}
              </>
            )}
          </Grid>
          <Grid item container justifyContent="center" alignItems="center">
            <NButton
              children={'Aktiver'}
              leftIcon={<ToggleOnIcon />}
              disabled={!enableActivate}
              onClick={handleClickAktiver}
            />
          </Grid>
        </Stack>
      </Grid>
      <Grid
        item
        xs={12}
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        {showSecondTextBox ? (
          <Paper elevation={3} style={{ padding: '16px', borderRadius: '8px' }}>
            <RadioGroup
              row
              name="logicalOperator"
              defaultValue="AND"
              value={logicalOperator}
              onChange={(e) => setLogicalOperator(e.target.value)}
            >
              <FormControlLabel
                value={'AND'}
                control={<Radio color="primary" size="small" />}
                label="Og"
              />
              <FormControlLabel
                value={'OR'}
                control={<Radio color="primary" size="small" />}
                label="Eller"
              />
            </RadioGroup>
          </Paper>
        ) : undefined}
      </Grid>
      <Grid container direction="row" justifyContent="center" alignItems="center">
        <Stack direction={'row'} spacing={1}>
          <Grid item xs={4}></Grid>
          <Grid item xs={2}>
            {showSecondTextBox ? (
              <>
                <FormControl sx={{ minWidth: 120 }}>
                  <Select
                    variant="outlined"
                    sx={classes.Select}
                    value={secondOperator?.value || ''}
                    onChange={(e) => {
                      handleOperator(e.target.value as string, false);
                    }}
                    required={true}
                  >
                    {customizedOperator?.map((option: OptionI) => (
                      <MenuItem key={option?.value} value={option?.value}>
                        {option?.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            ) : undefined}
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={4} container justifyContent={'flex-start'}>
            {multipleValue ? (
              <>
                <Stack direction={'row'}>
                  <FormControl sx={{ minWidth: 200 }}>
                    <TextField
                      sx={classes}
                      id={'verdiTwo'}
                      name={'verdiTwo'}
                      value={secondNumericValue === 0 ? '' : secondNumericValue}
                      inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                      onChange={(e) => {
                        setSecondNumericValue(+e.target.value);
                        handleInputValues(e.target.value, false);
                      }}
                      type={'text'}
                      variant={'outlined'}
                      required={false}
                      disabled={false}
                    />
                  </FormControl>
                  <IconButton
                    color="error"
                    onClick={removeTextBoxes}
                    disabled={
                      activatedOption?.selectedOperatorOne?.label?.toUpperCase() ===
                      'BETWEEN'
                    }
                  >
                    <HighlightOffIcon />
                  </IconButton>{' '}
                </Stack>
              </>
            ) : undefined}
          </Grid>
          <Grid item xs={1}></Grid>
        </Stack>
      </Grid>
      <Divider />
      <Grid container direction="row" justifyContent="center" alignItems="center">
        <DisplayActivatedOptions
          filterCriteria={konsumFilterCriteria}
          updateSelectedOptionsOnDelete={updateSelectedOptionsOnDelete}
          activatedOptions={activatedOptions}
          setFilterCriteria={setKonsumFilterCriteria}
        />
      </Grid>
    </DialogBox>
  );
};
