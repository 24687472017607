import {
  IconButton,
  Link,
  Paper,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@mui/material';
import React, { Dispatch, SetStateAction, useMemo, useState } from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { MaaltidI } from './types/PersonTypes';
import {
  EnhancedTableHead,
  getComparator,
  Order
} from '../../utilities/EnhancedTableHead';
import { CodeRegisterI, HeadCell } from '../components/commons/CommonTypes';

interface MaaltidTableI extends Omit<MaaltidI, 'maaltidskomponenter'> {}

const headCells: HeadCell<MaaltidTableI>[] = [
  {
    id: 'maaltidsId',
    align: 'left',
    padding: 'none',
    label: 'Nr.'
  },
  {
    id: 'maaltidstype',
    align: 'left',
    padding: 'none',
    label: 'Type'
  },
  {
    id: 'klokkeslett',
    align: 'left',
    padding: 'normal',
    label: 'Kl.'
  },
  {
    id: 'spisested',
    align: 'left',
    padding: 'normal',
    label: 'Sted'
  },
  {
    id: 'notat',
    align: 'left',
    padding: 'none',
    label: 'Notat'
  }
];

interface MaaltiderTableProps {
  owned: boolean;
  maaltidsData: MaaltidTableI[];
  maaltidTypes: CodeRegisterI[];
  currentMaaltidId: number;
  setCurrentMaaltidId: Dispatch<SetStateAction<number>>;
  setShowMaaltid: Dispatch<SetStateAction<boolean>>;
  setFromExisting: Dispatch<SetStateAction<boolean>>;
  setShowKopi: (val: boolean) => void;
}

export default function LopedagMaaltiderTable(props: MaaltiderTableProps) {
  const {
    owned,
    maaltidsData,
    maaltidTypes,
    currentMaaltidId,
    setCurrentMaaltidId,
    setShowMaaltid,
    setFromExisting,
    setShowKopi
  } = props;
  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState<keyof MaaltidTableI>('klokkeslett');

  const handleRequestSort = (property: keyof MaaltidTableI) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const rows = useMemo(
    () => maaltidsData?.slice().sort(getComparator(order, orderBy)),
    [order, orderBy, maaltidsData]
  );

  return (
    <TableContainer component={Paper} sx={{ maxHeight: '21rem' }}>
      <Table stickyHeader size={'small'}>
        <EnhancedTableHead<MaaltidTableI>
          headCells={headCells}
          leftColumns={[{ name: 'Velg', padding: 'checkbox' }]}
          rightColumns={[{ name: 'Kopi', align: 'right' }]}
          sorting={{
            order: order,
            orderBy: orderBy,
            onRequestSort: handleRequestSort
          }}
        />
        <TableBody>
          {rows?.map((row, index) => (
            <TableRow
              key={row.maaltidsId}
              hover
              selected={currentMaaltidId === row.maaltidsId}
              aria-checked={currentMaaltidId === row.maaltidsId}
              tabIndex={-1}
              onClick={() => setCurrentMaaltidId(row.maaltidsId)}
              sx={{ cursor: 'pointer' }}
            >
              <TableCell padding="checkbox">
                <Radio
                  checked={currentMaaltidId === row.maaltidsId}
                  inputProps={{
                    'aria-labelledby': 'table-checkbox-' + index
                  }}
                />
              </TableCell>
              {headCells.map((cell) =>
                cell.id === 'maaltidsId' ? (
                  <TableCell
                    key={cell.id}
                    onClick={() => {
                      setShowMaaltid(true);
                      setFromExisting(true);
                    }}
                    padding={cell.padding}
                    align={cell.align}
                  >
                    <Link>{row[cell.id]}</Link>
                  </TableCell>
                ) : cell.id === 'notat' ? (
                  <TableCell
                    key={cell.id}
                    padding={cell.padding}
                    align={cell.align}
                    sx={{
                      color: row[cell.id]?.toString().trimStart().startsWith('-')
                        ? '#FF0000'
                        : ''
                    }}
                  >
                    {`${row[cell.id]?.toString().split('\n')[0] || ''}`}
                  </TableCell>
                ) : (
                  <TableCell key={cell.id} padding={cell.padding} align={cell.align}>
                    {cell.id === 'maaltidstype'
                      ? maaltidTypes?.find((m) => m.nr === row['maaltidstype'])?.label
                      : row[cell.id]}
                  </TableCell>
                )
              )}
              <TableCell align="right" sx={{ pb: 0, pt: 0 }}>
                <IconButton
                  onClick={() => {
                    setShowKopi(true);
                  }}
                  disabled={!owned}
                >
                  <ContentCopyIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
