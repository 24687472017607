import React, { useEffect } from 'react';
import { Divider, Grid, Typography, Stack, Paper, Tooltip } from '@mui/material';
import { DatabaseContext } from '../../../context/DatabaseContext';
import useStyles from '../../../css/materialtheme';
import { useSelector } from 'react-redux';
import { ProsjektI } from '../../../types/prosjektTypes';
import useFetchData from '../../utilities/useFetchData';
import IconsMenu from './components/IconsMenu';
import KostholdsData from './components/KostholdsData';
import MatvareAndStoff from './components/MatvareAndStoff';
import InformationMenu from './components/InformationMenu';
import { ProgressBar } from '../../utilities/ProgressBar';
import CalculateIcon from '@mui/icons-material/Calculate';
import PageContainer from '../home/PageContainer';
import {
  BeregnFilterValuesI,
  BeregnignInput,
  BeregningObject,
  DefaultBeregningCounts,
  FieldsValues,
  InitialBeregningData,
  ProjectSpecificBeregningData,
  ReportResultTableI,
  UtvalgTableData,
  resultatGruppeI
} from './types/BeregningTypes';
import { AlertI } from '../../../types/alertTypes';
import AlertComp from '../../utilities/AlertComp';
import BeregningReportContainer from './components/BeregningReport/BeregningReportContainer';
import useDidMountEffect from '../../utilities/useDidMountEffect';
import { useUserContext } from '../../../context/AuthContext';
import { NButton } from '../components/Inputs';
import { useProjectContext } from '../../../context/ProjectContext';
import { UserRole } from '../admin/AdminTypes';
import ReportResultTable from './components/BeregningResultTable';

const projectId = {
  value: 0,
  touched: false,
  error: false,
  errorMsg: 'Feltet er obligatorisk'
};
const surveyId = {
  value: 0,
  touched: false,
  error: false,
  errorMsg: 'Feltet er obligatorisk'
};
const reportId = {
  value: 0,
  touched: false,
  error: false,
  errorMsg: 'En rapport må være valgt før du kan starte beregningen'
};
const enhetId = {
  value: 0,
  touched: false,
  error: false,
  errorMsg: 'En enhet må være valgt før du kan starte beregningen'
};

const initialBeregningObject: BeregningObject = {
  utvalgId: '',
  databaseId: 0,
  numberOfPersons: 0,
  kostholdData: {
    projectId: projectId,
    surveyId: surveyId,
    persondivisor: 'A',
    dagdivisor: 'A',
    personFilterParams: [],
    konsumFilterParams: []
  },
  matvareData: {
    gruppetypeId: 0,
    resultatGroup: '',
    individualMatvares: [],
    excludedMatvares: [],
    matvaregroups: [],
    matvaresInGroup: false,
    highLevelCategory: 0,
    rapport: reportId,
    enhet: enhetId,
    bregningCheckboxes: {
      norskNavn: true,
      engelskNavn: false,
      utenKosttilskudd: false
    }
  },
  stoffData: {
    individualStoffs: [],
    stoffGroup: [],
    deleteStoff: false
  },
  inputsSaved: false,
  beregn: false,
  hent: false,
  cleanPreviousData: true
};

export const Beregning = (props: any) => {
  const classes = useStyles();
  const [title] = React.useState('Beregning - Nutrifoodcalc');
  document.title = title;
  const projs = useProjectContext();
  const loggedInuser = useUserContext();
  const currentUser = loggedInuser?.user?.username;
  const { currentDatabase } = React.useContext(DatabaseContext);
  const [beregningObject, setBeregningObject] =
    React.useState<BeregningObject>(initialBeregningObject);
  const projectProps: ProsjektI = useSelector(
    (checkBoxSelection: ProsjektI) => checkBoxSelection['prosjekts']
  );
  const [showAlert, setShowAlert] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState('');
  const [progressText, setProgressText] = React.useState('Beregner...');
  const [beregnReport, setBeregnReport] = React.useState<boolean>(false);
  const [alertSeverity, setAlertSeverity] = React.useState<AlertI['alertColor']>('info');
  const [initialValues, setInitialValues] = React.useState<InitialBeregningData>();
  const [surveyDatabase, setSurveyDatabase] = React.useState(0);
  const [showProgress, setShowProgress] = React.useState(false);
  const [cleared, setCleared] = React.useState(false);
  const [personDivisorvalue, setPersonDivisorValue] = React.useState('A');
  const [dagDivisorvalue, setDagDivisorValue] = React.useState('A');
  const [beregnFilterValues, setBeregnFilterValues] =
    React.useState<BeregnFilterValuesI>();
  const [personFilterCriteria, setPersonFilterCriteria] = React.useState<string[]>([]);
  const [konsumFilterCriteria, setKonsumFilterCriteria] = React.useState<string[]>([]);
  const [totalCounts, setTotalCounts] = React.useState<DefaultBeregningCounts>({
    TOTALMATVAREGRUPPE: 0,
    TOTALMATVARE: 0,
    TOTALFJERNDEMATVARE: 0,
    TOTALSTOFFCOUNT: 0
  });
  const [selectedResultatGroup, setSelectedResultatGroup] =
    React.useState<resultatGruppeI>({
      GRUPPETYPEID: 0,
      BESKRIVELSE: '',
      LANG_BESKRIVELSE: '',
      TILGANGSKODE: 0
    });
  const [utvalgData, setUtvalgData] = React.useState<UtvalgTableData>({
    matvaredata: [],
    matvaregroupData: [],
    stoffData: [],
    stoffGroupData: [],
    fjerndeMatvareData: []
  });

  const [startPolling, setStartPolling] = React.useState(true);

  const getInitialData = useFetchData({
    loadOnMount: true,
    clearDataOnLoad: false,
    projectURL: 'getInitialBeregningValues',
    subProjectURL: `${currentDatabase?.value}`
  });
  const getProjectSpecificData = useFetchData({
    loadOnMount: false,
    clearDataOnLoad: false,
    projectURL: 'getProjectSpecificBeregningData',
    subProjectURL: `${beregningObject?.kostholdData?.projectId?.value}/${beregningObject?.kostholdData?.surveyId?.value}`
  });

  useDidMountEffect(() => {
    if (getInitialData?.data) {
      const data = getInitialData?.data as unknown as InitialBeregningData;
      setInitialValues(data);
      projs.setProjects(data.projects);

      populateResultatgruppe(data);
      const username = loggedInuser?.user?.roles?.includes(UserRole.Akf)
        ? 'AKF'
        : currentUser;
      const id = username + data?.UtvalgsId?.ID;
      setBeregningObject({
        ...beregningObject,
        utvalgId: id,
        databaseId: currentDatabase?.value
      });
    }
    setCleared(false);
  }, [getInitialData.data]);
  useDidMountEffect(() => {
    const pId: FieldsValues = beregningObject?.kostholdData?.projectId;
    pId.value = projectProps.prosjektId;
    pId.error = false;
    updateBeregningObject('kosthold', 'projectId', pId);
  }, [projectProps.prosjektId]);

  useDidMountEffect(() => {
    const unId: FieldsValues = beregningObject?.kostholdData?.surveyId;
    unId.value = projectProps.undersokelseId;
    unId.error = false;
    updateBeregningObject('kosthold', 'surveyId', unId);
  }, [projectProps.undersokelseId]);

  useDidMountEffect(() => {
    if (getProjectSpecificData.data) {
      const data =
        getProjectSpecificData?.data as unknown as ProjectSpecificBeregningData;
      /*  if (
         beregningObject?.numberOfPersons === 0 ||
         beregningObject?.numberOfPersons === undefined
       ) { */
      setBeregningObject({
        ...beregningObject,
        numberOfPersons: data?.numberOfPersons
      });
      //}
      setSurveyDatabase(data?.databaseId);
      setBeregnFilterValues(data?.filterValues);
    }
  }, [getProjectSpecificData.data]);

  useDidMountEffect(() => {
    if (
      beregningObject?.kostholdData?.surveyId?.value > 0 &&
      beregningObject?.kostholdData?.projectId?.value > 0
    ) {
      getProjectSpecificData.loadData(
        'getProjectSpecificBeregningData',
        `${beregningObject?.kostholdData?.projectId?.value}/${beregningObject?.kostholdData?.surveyId?.value}`
      );
    }
  }, [beregningObject?.kostholdData?.surveyId?.value]);
  const populateResultatgruppe = (data: InitialBeregningData) => {
    if (data?.defaultVerdier?.DEFAULT3 !== undefined) {
      //Defaultverdier has user specific default values saved in the DB, and DEFAULT3 has the default resultatgroup
      const groupId = parseInt(data?.defaultVerdier?.DEFAULT3) || 0;
      const defaultResultatGroup = data?.resultatgrupper?.find(
        (r) => r.GRUPPETYPEID === groupId
      );
      setSelectedResultatGroup({
        ...selectedResultatGroup,
        GRUPPETYPEID: defaultResultatGroup?.GRUPPETYPEID || 0,
        BESKRIVELSE: defaultResultatGroup?.BESKRIVELSE || ''
      });
      updateBeregningObject(
        'matvare',
        'gruppetypeId',
        defaultResultatGroup?.GRUPPETYPEID
      );
      updateBeregningObject(
        'matvare',
        'resultatGroup',
        defaultResultatGroup?.BESKRIVELSE
      );
    }
  };
  const updateBeregningObject = (item: string, name: string, value: any) => {
    if (item === 'kosthold') {
      setBeregningObject((prevObject) => {
        const newObject = { ...prevObject };
        newObject.kostholdData[name] = value;
        return newObject;
      });
    } else if (item === 'matvare') {
      setBeregningObject((prevObject) => {
        const newObject = { ...prevObject };
        newObject.matvareData[name] = value;
        newObject.inputsSaved = false;
        return newObject;
      });
    } else if (item === 'stoff') {
      setBeregningObject((prevObject) => {
        const newObject = { ...prevObject };
        newObject.stoffData[name] = value;
        //if(value?.length > 0){
        newObject.inputsSaved = false;
        //}
        return newObject;
      });
    } else if (item === 'checkbox') {
      setBeregningObject((prevObject) => {
        const newObject = { ...prevObject };
        newObject.matvareData.bregningCheckboxes[name] = value;
        if (name === 'utenKosttilskudd') {
          newObject.inputsSaved = false;
        }
        return newObject;
      });
    } else {
      setBeregningObject({ ...beregningObject, [name]: value });
    }
  };
  const showAlertMessage = (message: string, severity: any) => {
    setShowAlert(true);
    setAlertMessage(message);
    setAlertSeverity(severity);
  };
  const checkSurveyValidity = () => {
    const surveys = projs.projects?.find(
      (p) => p.prosjektId === beregningObject?.kostholdData?.projectId?.value
    )?.undersokelser;
    // const surveys = initialValues?.projects?.find((p)=> p.prosjektId === beregningObject?.kostholdData?.projectId?.value)?.undersokelser;
    const surveyIds = surveys?.map((u) => u.undersokelseId);
    if (
      beregningObject?.kostholdData?.surveyId?.value !== undefined &&
      beregningObject?.kostholdData?.surveyId?.value !== 0 &&
      !surveyIds?.includes(beregningObject?.kostholdData?.surveyId?.value)
    ) {
      showAlertMessage('Denne undersøkelsen eksisterer ikke.', 'error');
      const unId: FieldsValues = beregningObject?.kostholdData?.surveyId;
      unId.value = 0;
      updateBeregningObject('kosthold', 'surveyId', unId);
    }
  };
  const handleOnEnterOnProsjekt = (e: React.KeyboardEvent<HTMLDivElement>) => {
    e.preventDefault(); // Prevent Enter Key from creating new line
    const projects = initialValues?.projects?.map((p) => p.prosjektId);
    if (!projects?.includes(beregningObject?.kostholdData?.projectId?.value)) {
      showAlertMessage('Denne prosjekten eksisterer ikke.', 'error');
      const pId: FieldsValues = beregningObject?.kostholdData?.projectId;
      pId.value = 0;
      const unId: FieldsValues = beregningObject?.kostholdData?.surveyId;
      unId.value = 0;
      updateBeregningObject('kosthold', 'projectId', pId);
      updateBeregningObject('kosthold', 'surveyId', unId);
    }
  };
  const handleOnEnterOnSurvey = (e: React.KeyboardEvent<HTMLDivElement>) => {
    e.preventDefault();
    checkSurveyValidity();
  };
  function clearForm() {
    setCleared(true);
    setBeregningObject(initialBeregningObject);
    //updateBeregningObject('checkbox', 'utenKosttilskudd', false)
    //setBeregningObject({ ...beregningObject, inputsSaved: false });
    setBeregningObject((initialBeregningObject) => {
      return {
        ...initialBeregningObject,
        numberOfPersons: 0,
        hent: false,
        kostholdData: {
          projectId: projectId,
          surveyId: surveyId,
          persondivisor: 'A',
          dagdivisor: 'A',
          personFilterParams: [],
          konsumFilterParams: []
        },
        matvareData: {
          ...initialBeregningObject.matvareData,
          bregningCheckboxes: {
            ...initialBeregningObject.matvareData.bregningCheckboxes
          }
        },
        inputsSaved: false
      };
    });

    updateBeregningObject('matvare', 'gruppetypeId', 0);
    let utvalgData: UtvalgTableData = {
      matvaredata: [],
      matvaregroupData: [],
      stoffData: [],
      stoffGroupData: [],
      fjerndeMatvareData: []
    };
    setUtvalgData(utvalgData);
    //get new id from BE
    getInitialData.loadData('getInitialBeregningValues', `${currentDatabase?.value}`);
  }
  useEffect(() => {
    console.log('utvalgData', utvalgData);
  }, [utvalgData]);

  const handleChangePersondivisor = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPersonDivisorValue((event.target as HTMLInputElement).value);
    updateBeregningObject(
      'kosthold',
      'persondivisor',
      (event.target as HTMLInputElement).value
    );
  };
  const handleChangeDagdivisor = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDagDivisorValue((event.target as HTMLInputElement).value);
    updateBeregningObject(
      'kosthold',
      'dagdivisor',
      (event.target as HTMLInputElement).value
    );
  };
  const needsEnhet = () => {
    if (
      beregningObject?.matvareData?.rapport.value === 7 ||
      beregningObject?.matvareData?.rapport.value === 8 ||
      beregningObject?.matvareData?.rapport.value === 12
    ) {
      return false;
    } else {
      return true;
    }
  };
  const isReadyForberegning = () => {
    if (
      beregningObject?.kostholdData.projectId.value > 0 &&
      beregningObject?.kostholdData.surveyId.value > 0 &&
      beregningObject?.matvareData.rapport.value > 0 &&
      needsEnhet &&
      beregningObject?.matvareData.enhet.value > 0
    ) {
      return true;
    } else {
      return false;
    }
  };
  const validateBeregningForm = () => {
    let ok = true;
    const report = beregningObject?.matvareData?.rapport?.value;
    const individualStoffs = beregningObject?.stoffData?.individualStoffs?.length;
    const stoffgrupp = beregningObject?.stoffData?.stoffGroup?.length;

    if (beregningObject?.kostholdData?.projectId?.value === 0) {
      ok = false;
      const pId: FieldsValues = beregningObject?.kostholdData?.projectId;
      pId.error = true;
      updateBeregningObject('kosthold', 'projectId', pId);
    }
    if (beregningObject?.kostholdData?.surveyId?.value === 0) {
      ok = false;
      const unId: FieldsValues = beregningObject?.kostholdData?.surveyId;
      unId.error = true;
      updateBeregningObject('kosthold', 'surveyId', unId);
    }
    if (!beregningObject?.databaseId) {
      ok = false;
      console.log('databaseId missing');
    }
    if (!beregningObject?.utvalgId) {
      ok = false;
      console.log('calculationId missing');
    }
    if (beregningObject?.matvareData?.rapport.value === 0) {
      ok = false;
      const rId: FieldsValues = beregningObject?.matvareData?.rapport;
      rId.error = true;
      updateBeregningObject('matvare', 'rapport', rId);
    } else {
      const rId: FieldsValues = beregningObject?.matvareData?.rapport;
      rId.error = false;
      updateBeregningObject('matvare', 'rapport', rId);
    }
    if (beregningObject?.matvareData?.enhet.value === 0) {
      if (needsEnhet()) {
        ok = false;
        const enId: FieldsValues = beregningObject?.matvareData?.enhet;
        enId.error = true;
        enId.errorMsg = 'En enhet må være valgt før du kan starte beregningen';
        updateBeregningObject('matvare', 'enhet', enId);
      } else {
        const enId: FieldsValues = beregningObject?.matvareData?.enhet;
        enId.error = false;
        updateBeregningObject('matvare', 'enhet', enId);
      }
    } else {
      const enId: FieldsValues = beregningObject?.matvareData?.enhet;
      enId.error = false;
      updateBeregningObject('matvare', 'enhet', enId);
    }
    if (!beregningObject?.matvareData?.gruppetypeId) {
      ok = false;
      console.log('En resultatgruppe må være valgt før du kan starte beregningen.');
    }
    if ((report === 10 || report === 12) && (stoffgrupp > 0 || individualStoffs != 1)) {
      ok = false;
      console.log('Nøyaktig ett stoff må være valgt før du kan starte beregningen.');
    }
    if (
      (report === 3 ||
        report === 4 ||
        report === 5 ||
        report === 6 ||
        report === 7 ||
        report === 8) &&
      stoffgrupp === 0 &&
      individualStoffs === 0
    ) {
      ok = false;
      console.log('Stoff må være valgt før du kan starte beregningen.');
    }
    if (ok) {
      if (
        (report === 3 ||
          report === 4 ||
          report === 5 ||
          report === 6 ||
          report === 7 ||
          report === 8) &&
        (stoffgrupp !== 0 || individualStoffs !== 0) &&
        utvalgData?.stoffData?.length === 0
      ) {
        showAlertMessage('Stoffutvalget ga ingen stoffer til beregningen.', 'error');
      } else {
        console.log('All the parameters are set!', beregningObject);
        setBeregningObject({ ...beregningObject, beregn: true });
      }
    } else {
      showAlertMessage('En eller flere av beregning utvalgene er tom.', 'error');
    }
  };
  const mapBeregningInputToObject = (data: BeregnignInput) => {
    let utvalgData: UtvalgTableData = {
      matvaredata: data?.matvareData?.individualMatvares,
      matvaregroupData: data?.matvareData?.matvaregroups,
      stoffData: data?.stoffData?.individualStoffs,
      stoffGroupData: data?.stoffData?.stoffGroup,
      fjerndeMatvareData: data?.matvareData?.excludedMatvares
    };
    setUtvalgData(utvalgData);
    setBeregningObject((object) => {
      return {
        ...object,
        numberOfPersons: data.numberOfPersons,
        databaseId: currentDatabase.value,
        hent: true,
        kostholdData: {
          ...beregningObject.kostholdData,
          projectId: data?.kostholdData?.projectId,
          surveyId: data?.kostholdData?.surveyId,
          persondivisor: data?.kostholdData?.persondivisor,
          dagdivisor: data?.kostholdData?.dagdivisor,
          personFilterParams: data?.kostholdData?.personFilterParams,
          konsumFilterParams: data?.kostholdData?.konsumFilterParams
        },
        matvareData: {
          ...beregningObject.matvareData,
          gruppetypeId: data?.matvareData?.gruppetypeId,
          resultatGroup: data?.matvareData?.resultatGroup,
          individualMatvares: data?.matvareData?.individualMatvares,
          matvaregroups: data?.matvareData?.matvaregroups,
          matvaresInGroup: data?.matvareData?.matvaresInGroup,
          rapport: data?.matvareData?.rapport,
          enhet: data?.matvareData?.enhet,
          bregningCheckboxes: data?.matvareData?.bregningCheckboxes,
          highLevelCategory: data?.matvareData?.highLevelCategory,
          excludedMatvares: data?.matvareData?.excludedMatvares
        },
        stoffData: {
          ...beregningObject.stoffData,
          individualStoffs: data?.stoffData?.individualStoffs,
          stoffGroup: data?.stoffData?.stoffGroup,
          deleteStoff: data?.stoffData?.deleteStoff
        },
        inputsSaved: true
      };
    });
  };
  return (
    <PageContainer size={'lg'}>
      <Paper style={{ padding: '1.5em 15px 1.5em 15px', boxShadow: 'none' }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="h4">Beregning</Typography>
          </Grid>
          <Grid item xs={6}>
            <IconsMenu
              classes={classes}
              beregningObject={beregningObject}
              showAlertMessage={showAlertMessage}
              mapBeregningInputToObject={mapBeregningInputToObject}
              personFilterCriteria={personFilterCriteria}
              setPersonFilterCriteria={setPersonFilterCriteria}
              konsumFilterCriteria={konsumFilterCriteria}
              setKonsumFilterCriteria={setKonsumFilterCriteria}
              totalCounts={totalCounts}
              setTotalCounts={setTotalCounts}
              utvalgData={utvalgData}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">* Obligatorisk felt</Typography>
          </Grid>
          <Grid container>
            <Grid item md={8}>
              <KostholdsData
                beregningObject={beregningObject}
                updateBeregningObject={updateBeregningObject}
                setBeregningObject={setBeregningObject}
                handleOnEnterOnProsjekt={handleOnEnterOnProsjekt}
                handleOnEnterOnSurvey={handleOnEnterOnSurvey}
                showAlertMessage={showAlertMessage}
                beregnFilterValues={beregnFilterValues}
                personFilterCriteria={personFilterCriteria}
                setPersonFilterCriteria={setPersonFilterCriteria}
                konsumFilterCriteria={konsumFilterCriteria}
                setKonsumFilterCriteria={setKonsumFilterCriteria}
                cleared={cleared}
                checkSurveyValidity={checkSurveyValidity}
              />
              <Divider variant="middle" sx={classes.divider} />

              <MatvareAndStoff
                totalCounts={totalCounts}
                setTotalCounts={setTotalCounts}
                beregningObject={beregningObject}
                updateBeregningObject={updateBeregningObject}
                showAlertMessage={showAlertMessage}
                setBeregningObject={setBeregningObject}
                utvalgData={utvalgData}
                setUtvalgData={setUtvalgData}
                selectedResultatGroup={selectedResultatGroup}
                setSelectedResultatGroup={setSelectedResultatGroup}
                cleared={cleared}
                setShowProgress={setShowProgress}
                setProgressText={setProgressText}
              />
            </Grid>
            <Grid container item md={4} justifyContent={'center'}>
              <InformationMenu
                classes={classes}
                personDivisorvalue={personDivisorvalue}
                dagDivisorvalue={dagDivisorvalue}
                handleChangePersondivisor={handleChangePersondivisor}
                handleChangeDagdivisor={handleChangeDagdivisor}
                showAlertMessage={showAlertMessage}
                beregningObject={beregningObject}
                setBeregningObject={setBeregningObject}
                totalCounts={totalCounts}
                setTotalCounts={setTotalCounts}
                utvalgData={utvalgData}
                setUtvalgData={setUtvalgData}
                cleared={cleared}
                setShowProgress={setShowProgress}
                setProgressText={setProgressText}
              />
              <Stack padding={4} direction="row" spacing={2} justifyContent={'flex-end'}>
                <NButton
                  children="Beregn"
                  leftIcon={<CalculateIcon />}
                  onClick={() => {
                    validateBeregningForm();
                  }}
                  disabled={!isReadyForberegning}
                />
                <NButton children="Nullstill" variant="outlined" onClick={clearForm} />
              </Stack>
              <Grid container item md={7} justifyContent={'flex-start'}>
                <BeregningReportContainer
                  surveyDatabase={surveyDatabase}
                  beregningObject={beregningObject}
                  updateBeregningObject={updateBeregningObject}
                  showAlertMessage={showAlertMessage}
                  setBeregningObject={setBeregningObject}
                  setShowProgress={setShowProgress}
                  setUtvalgData={setUtvalgData}
                  setTotalCounts={setTotalCounts}
                  setProgressText={setProgressText}
                  cleared={cleared}
                  setStartPolling={setStartPolling}
                  //beregnReport={beregnReport}
                  //setBeregnReport={setBeregnReport}
                />
              </Grid>
            </Grid>
            <Grid item md={12}>
              <ReportResultTable
                startPolling={startPolling}
                beregningObject={beregningObject}
                updateBeregningObject={updateBeregningObject}
                showAlertMessage={showAlertMessage}
                //setBeregnReport={setBeregnReport}
                // setBeregningObject={setBeregningObject}
                setShowProgress={setShowProgress}
                // setUtvalgData={setUtvalgData}
                //setTotalCounts={setTotalCounts}
                setProgressText={setProgressText}
                setStartPolling={setStartPolling}
                // setShowBeregningReport={setShowBeregningReport}
              />
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      {showAlert && (
        <AlertComp
          alertMessage={alertMessage}
          alertColor={alertSeverity}
          showAlert={showAlert}
          setShowAlert={setShowAlert}
        />
      )}
      {showProgress && (
        <ProgressBar
          text={progressText}
          isShowing={showProgress}
          hide={() => {
            setShowProgress(!showProgress);
          }}
        />
      )}
    </PageContainer>
  );
};
