import { Redirect, withRouter } from 'react-router-dom';
import React, { useCallback } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { NavLinks } from '../route/NavLinks';
import { FrontPage } from '../pages/FrontPage';
import Footer from '../pages/Footer';
import { Box, Button } from '@mui/material';
import useStyles from '../../css/IntroCss';
import useStylesButton from '../../css/ButtonCss';
import useStylesMain from '../../css/materialtheme';
import { NButton } from '../pages/components/Inputs';
import LoginIcon from '@mui/icons-material/Login';
export const Login = withRouter(({ location }) => {
  const currentLocationState: { [key: string]: any } = location.state || {
    from: { pathname: '/home' }
  };
  const commonClasses = useStylesMain();
  const { keycloak } = useKeycloak();
  const kcLoginOptions = {
    idpHint: 'FEIDE'
  };
  const login = useCallback(() => {
    return keycloak?.login(kcLoginOptions);
  }, [keycloak]);

  if (keycloak?.authenticated) {
    return <Redirect to={currentLocationState?.from as string} />;
  }
  const classes = useStyles();
  const classesButton = useStylesButton();

  return (
    <Box sx={commonClasses.flexContainerColumn}>
      <NavLinks isLogin={true} />
      <Box sx={[commonClasses.flexContainerColumnCenter, classes.mainBackgroundImage]}>
        <FrontPage />
        <NButton
          children="Logg in"
          leftIcon={<LoginIcon />}
          onClick={login}
          sx={classesButton.LoginButton}
        />
      </Box>
      <Footer />
    </Box>
  );
});
