export const customTheme = {
  components: {
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: '#E9F1F7 !important'
          }
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          backgroundColor: '#4977AF !important',
          color: '#FFFFFF',
          '& .MuiCheckbox-root': {
            color: '#FFFFFF !important'
          }
          // '& .MuiTableSortLabel-root': {
          //   color: '#FFFFFF !important',
          //   '& .MuiTableSortLabel-icon': {
          //     color: '#FFFFFF !important'
          //   }
          // }
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        outlined: {
          border: '1.5px solid',
          '&:hover': {
            border: '1.5px solid'
          }
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        flexContainer: {
          height: '3rem',
          alignItems: 'center'
        }
      }
    }
  }
};
