import React from 'react';
//import useStyles from '../../BeregningCss';
//import { savingStatusI } from '../../types/BeregningTypes';
import { Matvare, MatvaregruppeI } from '../../../matvare/types/matvaregruppeTypes';
import { Grid, Checkbox, FormControlLabel } from '@mui/material';
interface NavigateLevelI {
  nodeId: number;
  name: string;
  n: number;
}
interface EnhancedTableToolbarProps {
  calculationId: string;
  gruppetypeId: number;
  //setLoadCount: (value: boolean) => void;
  selectedMatvares: Matvare[];
  selectedMatvaregruppes: MatvaregruppeI[];
  parentsList: NavigateLevelI[];
  updateBeregningObject: (item: string, name: string, value: any) => void;
  highLevelCategory: number;
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const {
    selectedMatvares,
    selectedMatvaregruppes,
    parentsList,
    updateBeregningObject,
    highLevelCategory
  } = props;
  //const classes = useStyles();
  const [matvarerCount, setMatvarerCount] = React.useState(selectedMatvares?.length);
  const [, setNavigationText] = React.useState('');
  const [saveMatvaresInGroup, setSaveMatvaresInGroup] = React.useState(false);
  //const [saveSelectedMatvares, setSaveSelectedMatvares] = React.useState(false);
  // const [saveSelectedGroups, setSaveSelectedGroups] = React.useState(false);

  /*  const handleChangeMG = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSaveSelectedGroups(event.target.checked);
  }; */

  const handleChangeSaveAllMatvare = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateBeregningObject('matvare', 'matvaresInGroup', event.target.checked);
    updateBeregningObject('matvare', 'matvaregroups', selectedMatvaregruppes);
    updateBeregningObject('matvare', 'inputsSaved', false);
    setSaveMatvaresInGroup(event.target.checked);
  };
  /*  const handleChangeSaveSelectedMatvare = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSaveSelectedMatvares(event.target.checked);
  }; */
  React.useEffect(() => {
    setMatvarerCount(selectedMatvares?.length);
  }, [selectedMatvares?.length]);

  React.useEffect(() => {
    if (selectedMatvaregruppes?.length > 0) {
      setNavigationText(parentsList?.map((row: NavigateLevelI) => row.name)?.join('->'));
    }
  }, [selectedMatvaregruppes?.length]);

  return (
    <>
      <Grid container spacing={4} justifyContent={'flex-end'}>
        {/*  <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                checked={saveSelectedGroups}
                onChange={handleChangeMG}
                disabled={selectedMatvaregruppes?.length === 0}
              />
            }
            label="Lagre valgte grupper"
          />
        </Grid> */}
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                checked={saveMatvaresInGroup}
                onChange={handleChangeSaveAllMatvare}
                disabled={
                  !(
                    selectedMatvaregruppes?.length !== 0 &&
                    (highLevelCategory == 5 || highLevelCategory == 0)
                  )
                }
              />
            }
            label="Lagre alle matvarer i valgte grupper"
          />
        </Grid>
        {/* <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                checked={saveSelectedMatvares}
                onChange={handleChangeSaveSelectedMatvare}
                disabled={selectedMatvares?.length === 0}
              />
            }
            label="Lagre valgte matvarer"
          />
        </Grid> */}
      </Grid>
    </>
  );
};
export default EnhancedTableToolbar;
