// Prosjekt og Undersøkelse Input

import React from 'react';
import { Grid } from '@mui/material';
import { Dropdown, NButton, SlowText } from './Inputs';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import UndersokelseSearch from '../kosthold/UndersokelseSearch';

interface ProjectInputProps {
  project: string;
  projects: string[];
  setProject: (id: string) => void;

  undersokelse: string;
  setUndersokelse: (id: string) => void;
}

function ProjectInput(p: ProjectInputProps) {
  const [showUndersokelseIdList, setShowUndersokelseIdList] =
    React.useState<boolean>(false);

  return (
    <Grid container spacing={2} marginTop={2} marginBottom={2} alignItems="flex-end">
      <Grid item xs={4}>
        <Dropdown
          header="Prosjekt *"
          value={p.project}
          options={p.projects}
          onChange={(v) => p.setProject(v as string)}
        />
      </Grid>
      <Grid item xs={4}>
        <SlowText
          header="Undersøkelse *"
          value={p.undersokelse}
          onChange={(e) => p.setUndersokelse(e.target.value)}
          disabled={!p.project}
        />
      </Grid>
      <Grid item xs={4}>
        <NButton
          leftIcon={<SearchOutlinedIcon />}
          children={'Søk etter undersøkelse'}
          onClick={() => setShowUndersokelseIdList(true)}
          disabled={!p.project}
          fullWidth
        />
      </Grid>
      {showUndersokelseIdList && (
        <UndersokelseSearch
          setUndersokelse={(u) => p.setUndersokelse(u?.toString())}
          projectId={p.project}
          showing={showUndersokelseIdList}
          hide={() => setShowUndersokelseIdList(false)}
        />
      )}
    </Grid>
  );
}

export default ProjectInput;
