import React from 'react';
import { Matvare } from '../../../matvare/types/matvaregruppeTypes';
import { Grid } from '@mui/material';
import { removeArrayFromAnotherArray } from '../../../../utilities/ArrayFunctions';
import { NButton } from '../../../components/Inputs';

interface EnhancedTableToolbarProps {
  showMatvaregroups: boolean;
  setShowMatvaregroups: (value: boolean) => void;
  selectedFjerndeMatvares: Matvare[];
  fjerndeMatvareList: Matvare[];
  setFjerndeMatvareList: (list: Matvare[]) => void;
  setSelectedFjerndeMatvares: (list: Matvare[]) => void;
}
const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const {
    selectedFjerndeMatvares,
    showMatvaregroups,
    fjerndeMatvareList,
    setFjerndeMatvareList,
    setShowMatvaregroups,
    setSelectedFjerndeMatvares
  } = props;
  const removeSelectedFMatvares = () => {
    let updated: Matvare[] = [];
    updated = removeArrayFromAnotherArray(
      fjerndeMatvareList,
      selectedFjerndeMatvares,
      'MATVAREKODEID'
    );
    setFjerndeMatvareList(updated);
    setSelectedFjerndeMatvares([]);
  };
  return (
    <>
      <Grid container justifyContent="flex-end">
        <Grid item xs={6}>
          <NButton
            children={'Slett valgte fra fjernde matvarer'}
            onClick={() => removeSelectedFMatvares()}
            disabled={selectedFjerndeMatvares?.length === 0}
          />
        </Grid>
        <Grid item xs={6}>
          <NButton
            children={
              showMatvaregroups
                ? 'Skjul matvaregrupper/matvarer'
                : 'Vis matvaregrupper/matvarer'
            }
            onClick={() => {
              setShowMatvaregroups(!showMatvaregroups);
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};
export default EnhancedTableToolbar;
