import { Box, Typography } from '@mui/material';
import React from 'react';
import useStyles from '../../css/IntroCss';

export const FrontPage = () => {
  const classes = useStyles();
  return (
    <Box style={{ width: 500 }}>
      <Typography sx={classes.NFCTitle}>NutriFoodCalc</Typography>
      <Typography sx={classes.Introdata} align="left">
        NutriFoodCalc erstatter tidligere KBS. NFC inneholder flere matvaredatabaser, fra
        den første som ble laget basert på matvaretabellen fra 1976 og til de nye som
        lages, oppdateres og lanseres i dag.
      </Typography>
    </Box>
  );
};
