import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Stack,
  Typography
} from '@mui/material';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import DialogGeneral from '../../../../utilities/DialogGeneral';
import { NButton } from '../../../components/Inputs';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { LopedagI } from '../../types/PersonTypes';

interface ValiderProps {
  lopedagData: LopedagI;
  showing: boolean;
  hide: () => void;
  setLopedagData: Dispatch<SetStateAction<LopedagI>>;
  success: boolean;
  setSuccess: Dispatch<SetStateAction<boolean>>;
  handleSaveLopedag: (inputData?: LopedagI) => void;
  updatedLopedagData: boolean;
  setUpdatedLopedagData: Dispatch<SetStateAction<boolean>>;
}
interface MaalMatvareI {
  maaltidsId: number;
  matvarekode: number;
}

export default function LopedagValiderModal(props: ValiderProps) {
  const {
    lopedagData,
    showing,
    hide,
    setLopedagData,
    success,
    setSuccess,
    handleSaveLopedag,
    updatedLopedagData,
    setUpdatedLopedagData
  } = props;
  const [validationDone, setValidationDone] = useState<boolean>(false);
  const [emptyMengdes, setEmptyMengdes] = useState<MaalMatvareI[]>([]);
  const [emptyMaaltids, setEmptyMaaltids] = useState<number[]>([]);
  const [isStatusFerdig, setIsStatusFerdige] = React.useState<boolean>(false);
  useEffect(() => {
    setSuccess(false);
    setValidationDone(false);
    handleSaveLopedag(lopedagData);
  }, []);

  useEffect(() => {
    if (updatedLopedagData && lopedagData) {
      validateLopedag();
    }
  }, [updatedLopedagData]);
  useEffect(() => {
    if (isStatusFerdig) {
      setIsStatusFerdige(false);
      setLopedagData({ ...lopedagData, statuskode: 7 });
      hide();
    }
  }, [isStatusFerdig]);
  useEffect(() => {
    if (lopedagData?.maaltider?.length > 0) {
      if (emptyMengdes?.length === 0 && emptyMaaltids?.length === 0 && validationDone) {
        setSuccess(true);
      }
    }
  }, [emptyMengdes, emptyMaaltids, validationDone]);
  function validateLopedag() {
    setEmptyMengdes([]);
    setEmptyMaaltids([]);
    if (updatedLopedagData) {
      const maaltidsWithEmptyKomponenter: number[] = [];
      const maaltidsWithEmptyMengde: MaalMatvareI[] = [];
      lopedagData?.maaltider?.forEach((maaltid) => {
        // Check if maaltidskomponenter is empty
        if (maaltid.maaltidskomponenter.length === 0) {
          maaltidsWithEmptyKomponenter.push(maaltid.maaltidsId);
        }

        // Check if any maaltidskomponent has empty 'mengde'
        maaltid.maaltidskomponenter?.forEach((komponent) => {
          const mengdeValue = parseFloat(komponent.mengde);
          if (!komponent.mengde || isNaN(mengdeValue) || mengdeValue <= 0) {
            maaltidsWithEmptyMengde.push({
              maaltidsId: maaltid.maaltidsId,
              matvarekode: komponent.kode
            });
          }
        });
      });
      setEmptyMaaltids(maaltidsWithEmptyKomponenter);
      setEmptyMengdes(maaltidsWithEmptyMengde);
      setValidationDone(true);
      setUpdatedLopedagData(false);
    }
  }

  return validationDone ? (
    success ? (
      <DialogGeneral
        title="Data validert"
        titleIcon={<CheckCircleOutlineIcon sx={{ color: 'white' }} />}
        open={showing}
        hide={hide}
        fullWidth
        maxWidth="sm"
      >
        <Typography variant="body1">
          {' '}
          Det finnes ikke mangel av data i måltider eller måltidskomponenter.
        </Typography>
        <br />
        <FormControlLabel
          label='Sett løpedagsstatus til "Ferdig"'
          control={
            <Checkbox
              checked={isStatusFerdig}
              onChange={() => setIsStatusFerdige((b) => !b)}
            />
          }
        />
        <Stack direction={'row'} spacing={1} justifyContent="end" sx={{ pt: '1rem' }}>
          <NButton onClick={() => hide()} children="Ok" />
        </Stack>
      </DialogGeneral>
    ) : (
      <DialogGeneral
        title="Data mangler"
        titleIcon={<ErrorOutlineIcon sx={{ color: 'white' }} />}
        open={showing}
        hide={hide}
        fullWidth
        maxWidth="sm"
      >
        <Stack spacing={2}>
          {emptyMengdes?.length > 0 &&
            emptyMengdes?.map((m) => (
              <Typography key={m.maaltidsId + '-' + m.matvarekode} variant="body1">
                Ingen mengde angitt for måltid nr. <b>{m.maaltidsId}</b>, matvarekode{' '}
                <b>{m.matvarekode}</b>
              </Typography>
            ))}

          <br />
          {emptyMaaltids?.length > 0 && (
            <Typography variant="body1">
              Ingen måltidskomponenter funnet for måltid nr.{' '}
              <b>{emptyMaaltids?.join(',')}</b>
            </Typography>
          )}
          <br />
          {(emptyMengdes?.length > 0 || emptyMaaltids?.length > 0) &&
            lopedagData?.statuskode === 7 && (
              <Typography variant="body1">
                {' '}
                Status kan ikke settes til "Ferdig" uten at alt valideres.
              </Typography>
            )}
          <Stack direction={'row'} spacing={1} justifyContent="end" sx={{ pt: '1rem' }}>
            <NButton
              onClick={() => {
                lopedagData?.statuskode === 7
                  ? setLopedagData({ ...lopedagData, statuskode: 3 })
                  : setLopedagData({
                      ...lopedagData,
                      statuskode: lopedagData?.statuskode
                    });
                hide();
              }}
              children="Ok"
            />
          </Stack>
        </Stack>
      </DialogGeneral>
    )
  ) : (
    <DialogGeneral
      title="Validerer..."
      titleIcon={<ErrorOutlineIcon sx={{ color: 'white' }} />}
      open={showing}
      hide={hide}
      fullWidth
      maxWidth="sm"
    >
      <CircularProgress />
    </DialogGeneral>
  );
}
