import React, { useEffect, useState } from 'react';
import PageContainer from '../../../home/PageContainer';
import { CircularProgress, Grid, Paper, Typography } from '@mui/material';
import { SlowText } from '../../../components/Inputs';
import { DatabaseContext } from '../../../../../context/DatabaseContext';
import { useKeycloak } from '@react-keycloak/web';
import { AltOmMatvareI } from '../../RapportTypes';
import HTMLRapport from './HtmlRapport';
import { databaseOptions } from '../../../../../assets/DatabaseOptions';
import ExcelRapport from './ExcelRapport';
import PollingComponent from '../../../components/commons/PollingComponent';
import { AlertI } from '../../../../../types/alertTypes';
import AlertComp from '../../../../utilities/AlertComp';

export const Altmatvare = () => {
  const { keycloak } = useKeycloak();
  const token = keycloak?.token || 'Mangler token';

  const { currentDatabase } = React.useContext(DatabaseContext);
  const dblabel = React.useMemo(
    () => databaseOptions.find((d) => d.value === currentDatabase?.value)?.label || '',
    [currentDatabase?.value]
  );

  const [intervalStart, setIntervalStart] = React.useState<number>(1);
  const [intervalEnd, setIntervalEnd] = React.useState<number>(100);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState<AlertI['alertColor']>('info');
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<AltOmMatvareI[]>([]);
  const [pollingId, setPollingId] = useState('');
  const [failed, setFailed] = useState(false);
  useEffect(() => {
    if (data) {
      setLoading(false);
    }
  }, [data]);
  useEffect(() => {
    if (failed) {
      showAlertMessage('Rapport lagging mislyktes.', 'error');
    }
  }, [failed]);
  useEffect(() => {
    setLoading(true);
    fetch(
      encodeURI(
        `${process.env.REACT_APP_BACKEND}/getAltOmEnMatvare/${currentDatabase?.value}/${intervalStart}/${intervalEnd}`
      ),
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token
        }
      }
    )
      .then((resp) => {
        if (resp.status === 200) {
          resp.json().then((data) => {
            //Start pooling database
            setPollingId(data?.id);
          });
        } else if (resp.status === 500) {
          resp.json().then((data) => {
            console.log('Error happened while making rapport.');
            showAlertMessage(data, 'error');
          });
          setLoading(false);
        } else {
          showAlertMessage('Rapport laging mislyktes', 'error');
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        showAlertMessage('Rapport laging mislyktes.', 'error');
        setLoading(false);
      });
  }, [currentDatabase.value, intervalStart, intervalEnd]);
  const showAlertMessage = (message: string, severity: any) => {
    setShowAlert(true);
    setAlertMessage(message);
    setAlertSeverity(severity);
  };
  return (
    <PageContainer>
      <Paper style={{ padding: '1.5em 15px 1.5em 15px', boxShadow: 'none' }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4">Alt om en matvare</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5">Velg matvareintervall</Typography>
          </Grid>
          <Grid item xs={6} container spacing={2}>
            <Grid item xs={6}>
              <SlowText
                header="Fra matvarekode"
                value={intervalStart}
                onChange={(e) => setIntervalStart(parseInt(e.target.value))}
                type="text"
              />
            </Grid>
            <Grid item xs={6}>
              <SlowText
                header="til matvarekode"
                value={intervalEnd}
                onChange={(e) => setIntervalEnd(parseInt(e.target.value))}
                type="text"
              />
            </Grid>
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={6} container spacing={2}>
            {data.length ? (
              !loading ? (
                <>
                  <Grid item xs={12}>
                    <Typography variant="h5">Rapport</Typography>
                  </Grid>
                  <HTMLRapport data={data} matvaredb={dblabel} />
                  <ExcelRapport data={data} matvaredb={dblabel} />
                </>
              ) : (
                <Grid item xs={2}>
                  <CircularProgress />
                </Grid>
              )
            ) : null}
          </Grid>
        </Grid>
        {showAlert ? (
          <AlertComp
            alertMessage={alertMessage}
            alertColor={alertSeverity}
            showAlert={showAlert}
            setShowAlert={setShowAlert}
          />
        ) : null}
        {pollingId && (
          <PollingComponent id={pollingId} setFailed={setFailed} setResult={setData} />
        )}
      </Paper>
    </PageContainer>
  );
};
