import React from 'react';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Paper from '@mui/material/Paper';
import { Grid, TableBody, TablePagination, useTheme } from '@mui/material';
import { SingleStoff, StoffI, StoffgruppeI } from '../stoff/types/StoffTypes';
import { NButton } from '../components/Inputs';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import useStylesTable from '../../../css/TableCss';
import { useUserContext } from '../../../context/AuthContext';
import { StoffGruppe } from '../matvare/types/StoffTypes';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { IsOwnedById } from '../../utilities/Validate';
interface StoffsTableProps {
  stoffGruppe: StoffgruppeI;
  tableData: StoffI[];
  removeStoff: (stoff: StoffI) => void;
  toggleStoffnummer: (row?: StoffI) => void;
}

export default function StoffsTable(props: StoffsTableProps) {
  const classesTable = useStylesTable();
  const loggedInuser = useUserContext();
  return (
    <Grid container xs={11} style={{ paddingTop: 10, paddingBottom: 10 }}>
      <Grid container xs={6}>
        <NButton
          children="Søk etter stoff"
          leftIcon={<SearchOutlinedIcon />}
          onClick={() => props.toggleStoffnummer()}
          disabled={
            !IsOwnedById({
              tilgangskode: props.stoffGruppe?.tilgangskode,
              u: loggedInuser
            }) && props.stoffGruppe?.stoffgruppeId !== 0
          }
        />
      </Grid>
      <Grid container xs={12} style={{ paddingTop: 10, paddingBottom: 10 }}>
        <TableContainer component={Paper}>
          <Table size="medium" sx={{ border: '1px solid black' }}>
            <TableHead sx={classesTable.head}>
              <TableRow>
                <TableCell>Stoffnr</TableCell>
                <TableCell>Kortnavn</TableCell>
                <TableCell>Navn</TableCell>
                <TableCell>Regnbart</TableCell>
                <TableCell>Fjern</TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={classesTable.body}>
              {props.tableData?.map((row: StoffI, i: number) => (
                <TableRow key={`${row.navn}-${i}`}>
                  <TableCell align="center" padding="none">
                    {row.stoffnrId}
                  </TableCell>
                  <TableCell align="center" padding="none">
                    {row.kortnavn}
                  </TableCell>
                  <TableCell align="center" padding="none">
                    {row.navn}
                  </TableCell>
                  <TableCell align="center" padding="none">
                    {row.regnbart}
                  </TableCell>
                  <TableCell padding="none">
                    <NButton
                      children="Slett"
                      rightIcon={<DeleteOutlineOutlinedIcon />}
                      variant="text"
                      onClick={() => props.removeStoff(row)}
                      disabled={
                        (!IsOwnedById({
                          tilgangskode: props.stoffGruppe?.tilgangskode,
                          u: loggedInuser
                        }) &&
                          props.stoffGruppe?.stoffgruppeId !== 0) ||
                        props.stoffGruppe.navn === ''
                      }
                    />
                  </TableCell>
                </TableRow>
              ))}
              <TableRow sx={{ backgroundColor: '#4977AF' }}></TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}
