import {
  SET_DIALOG_RESPONSE,
  SET_DIALOG_CLOSED,
  DialogActionTypes
} from '../types/actions';
import { DialogI } from '../types/dialogTypes';

const dialogReducerDefaultState: DialogI = { response: 'nei', dialogClosed: false };

export const dialogReducer = (
  state = dialogReducerDefaultState,
  action: DialogActionTypes
): DialogI => {
  switch (action.type) {
    case SET_DIALOG_RESPONSE:
      console.log('dialog response: ', action.response);

      return { ...state, response: action.response };
    case SET_DIALOG_CLOSED:
      return { ...state, dialogClosed: action.dialogClosed };
    default:
      return state;
  }
};
