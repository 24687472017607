import React, {
  ChangeEvent,
  forwardRef,
  MouseEvent,
  useEffect,
  useRef,
  useState
} from 'react';
import {
  Box,
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  LinearProgress,
  Link,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography
} from '@mui/material';
import FindReplaceIcon from '@mui/icons-material/FindReplace';

import { ItemOwner, MatvareDetaljeI, MatvareKodenNavnI } from './types/UndersokelseTypes';
import {
  EnhancedTableHead,
  getComparator,
  Order
} from '../../utilities/EnhancedTableHead';
import { HeadCell } from '../components/commons/CommonTypes';
import { headCellsMIUD } from '../matvare/components/TableHeadersData';
import { FilterIconDialog, NButton } from '../components/Inputs';
import { ApiCall } from '../../utilities/ApiCall';
import { useKeycloak } from '@react-keycloak/web';
import { RouteComponentProps } from 'react-router-dom';
import IsOwnedByUser from '../../utilities/Validate';
import { useUserContext } from '../../../context/AuthContext';
import PageContainer from '../home/PageContainer';
import ProjectNoLink from './components/ProjectNoLink';
import ByttMatvare from './ByttMatvare';
import { AlertI } from '../../../types/alertTypes';
import AlertComp from '../../utilities/AlertComp';
interface RouteParams {
  prosjektId: string;
  undersokelseId: string;
  undersokelseKortnavn: string;
  kode: string;
  navn: string;
}
interface MatvareTableProps extends RouteComponentProps<RouteParams> {}

export default function MatvarerDetaljeTable(props: MatvareTableProps) {
  const { prosjektId, undersokelseId, undersokelseKortnavn, kode, navn } =
    props.match.params;
  const matvarenavn = navn?.replace(/-slash-/g, '/')?.replace(/-percent-/g, '%');
  const { keycloak } = useKeycloak();
  const loggedInuser = useUserContext();
  const [matvareDetail, setMatvareDetail] = useState<MatvareDetaljeI[]>([]);
  const [matvareToChange, setMatvareToChange] = useState<MatvareKodenNavnI>(undefined);
  const [isFetched, setIsFetched] = useState<boolean>(false);
  const [owned, setOwned] = useState(false);
  const [bytt, setBytt] = useState(false);
  const [personId, setPersonId] = useState<number>(0);
  const [lopedagId, setLopedagId] = useState<number>(0);
  const [maaltidId, setMaaltidId] = useState<number>(0);
  const [ordrnrId, setOrdrnrId] = useState<number>(0);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState<AlertI['alertColor']>('info');
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = useState<keyof MatvareDetaljeI>('personId');
  document.title = `Matvare detalje ${kode} - Nutrifoodcalc`;

  const handleRequestSort = (property: keyof MatvareDetaljeI) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const sortedRows = [...matvareDetail]?.sort((a, b) => {
    if (b[orderBy] < a[orderBy]) return order === 'asc' ? -1 : 1;
    if (b[orderBy] > a[orderBy]) return order === 'asc' ? 1 : -1;
    return 0;
  });
  useEffect(() => {
    if (!prosjektId || !undersokelseId || !kode) return;

    fetchData();
  }, []);
  const fetchData = async () => {
    const response = await ApiCall(
      `getKostholdMatvareDetail/${prosjektId}/${undersokelseId}/${kode}`,
      'GET',
      keycloak.token
    );
    if (response?.ok) {
      const data: MatvareDetaljeI[] = await response?.json();
      setMatvareDetail(data);
      setIsFetched(false);
      setOwned(IsOwnedByUser({ id: +prosjektId, u: loggedInuser }));
    } else {
      console.error('error', response?.status);
    }
  };
  const showAlertComponent = (message: string, severity: any) => {
    setShowAlert(true);
    setAlertMessage(message);
    setAlertSeverity(severity);
  };
  function handleBytt(row: MatvareDetaljeI): void {
    setPersonId(row.personId);
    setLopedagId(row.lopedagId);
    setMaaltidId(row.maaltidnrId);
    setOrdrnrId(row.ordrnrId);
    setMatvareToChange({
      kode: +kode,
      navn: matvarenavn
    });
    setBytt(true);
  }

  return (
    <PageContainer>
      <Paper style={{ padding: '1.5em 15px 1.5em 15px', boxShadow: 'none' }}>
        <Grid container spacing={2}>
          <Grid item xs={9}>
            <Typography variant="h4" gutterBottom>
              Matvarer rapportert i undersøkelsen - detaljer
            </Typography>
          </Grid>
          <Grid item xs={12} container spacing={2}>
            <Grid item xs={6} container spacing={2}>
              <ProjectNoLink
                projectId={prosjektId}
                undersokelseId={undersokelseId}
                kortnavn={undersokelseKortnavn}
              />{' '}
            </Grid>

            <Grid item xs={6} container spacing={2}>
              <Stack direction={'row'} spacing={6}>
                <Typography>
                  Matvare:{' '}
                  <b>
                    {kode} - {matvarenavn}
                  </b>
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} container spacing={2} marginTop={3} alignItems="flex-end">
          <Grid item xs={12}>
            {sortedRows?.length ? (
              <>
                <TableContainer component={Paper} sx={{ maxHeight: '55vh' }}>
                  <Table stickyHeader size="small">
                    <EnhancedTableHead<MatvareDetaljeI>
                      headCells={headCellsMIUD}
                      rightColumns={owned ? [{ name: 'Bytt' }] : []}
                      sorting={{
                        order: order,
                        orderBy: orderBy,
                        onRequestSort: handleRequestSort
                      }}
                    />
                    <TableBody>
                      {sortedRows?.map((row) => (
                        <TableRow key={row.personId} hover>
                          {headCellsMIUD.map((cell, i) => (
                            <TableCell
                              key={cell.id}
                              padding={cell.padding}
                              align={cell.align}
                            >
                              {cell.id === 'personId' ? (
                                <Link
                                  href={`/home/kostholdsdata/prosjekt/person/${prosjektId}/${undersokelseId}/${undersokelseKortnavn}/${row.personId}/${row.lopedagId}/${row.maaltidnrId}`}
                                  target="_blank"
                                >
                                  {row[cell.id]}
                                </Link>
                              ) : (
                                row[cell.id]
                              )}
                            </TableCell>
                          ))}
                          {owned && (
                            <TableCell>
                              <IconButton
                                aria-label="bytt"
                                onClick={() => handleBytt(row)}
                              >
                                <FindReplaceIcon />
                              </IconButton>
                            </TableCell>
                          )}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            ) : isFetched ? (
              <Stack justifyContent={'center'} alignItems={'center'} height={'33rem'}>
                <Typography variant="body1">
                  Ingen matvare funnet i denne undersøkelsen.
                </Typography>
              </Stack>
            ) : (
              <Stack justifyContent={'center'} alignItems={'center'} height={'33rem'}>
                <CircularProgress />
              </Stack>
            )}
          </Grid>
        </Grid>
        {bytt && (
          <ByttMatvare
            reloadData={fetchData}
            showAlertComponent={showAlertComponent}
            projectId={+prosjektId}
            undersokelseId={+undersokelseId}
            //data={matvareDetail}
            matvareToChange={matvareToChange}
            setBytt={setBytt}
            singleOccurrence={true}
            personId={personId}
            lopedagId={lopedagId}
            maaltidId={maaltidId}
            ordrnrId={ordrnrId}
          />
        )}
        {showAlert && (
          <AlertComp
            alertMessage={alertMessage}
            alertColor={alertSeverity}
            showAlert={showAlert}
            setShowAlert={setShowAlert}
          />
        )}
      </Paper>
    </PageContainer>
  );
}
