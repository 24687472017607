import React from 'react';
import { lighten, withStyles, MenuProps, MenuItem, Menu, useTheme } from '@mui/material';

const useStyles = () => {
  const theme = useTheme();
  return {
    root: {
      padding: theme.spacing(1),
      '& .MuiIconButton-root': {
        paddingLeft: theme.spacing(1),
        paddingTop: theme.spacing(2)
      }
    },
    ObligatoriskField: {
      display: 'flex',
      justifyContent: 'left',
      alignItems: 'left',
      WebkitJustifyContent: 'flex-start',
      color: '#666666',
      float: 'left',
      fontFamily: 'Arial',
      position: 'absolute',
      fontSize: '14px',
      flex: '1'
    },
    IconBoxContainer: {
      // display: "flex",
      color: '#1C5081'
    },
    Icons: {
      display: 'flex',
      justifyContent: 'right',
      alignItems: 'right',
      // WebkitJustifyContent: "flex-end",
      color: '#1C5081'
    },
    MultipleSelectFilter: {
      fontSize: '18px',
      padding: theme.spacing(1)
    },
    Select: {
      display: 'flex',
      padding: theme.spacing(1),
      margin: theme.spacing(1),
      height: 50,
      fontSize: '18px'
    },
    resize: {
      fontSize: '18px'
    },
    text: {
      fontSize: '18px'
    },
    SelectLabel: {
      padding: theme.spacing(0.5),
      margin: theme.spacing(0.5)
    },
    MgTableRoot: {
      width: '100%'
    },
    MgTablePaper: {
      width: '100%',
      marginBottom: theme.spacing(2)
    },
    MgTable: {
      minWidth: 750
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1
    },
    MgTableToolbarRoot: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1)
    },
    MgTableToolbarHighlight: theme.palette.primary.light
      ? {
          color: theme.palette.primary.main,
          backgroundColor: lighten(theme.palette.primary.light, 0.85)
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.primary.dark
        },
    MgTableToolbarTitle: {
      flex: '1 1 100%'
    },
    tableHead: {
      alignItems: 'left',
      color: theme.palette.common.white,
      backgroundColor: '4977AF',
      height: '1%',
      [theme.breakpoints.up('xl')]: {
        fontSize: 22
      }
    },
    StyledMenuItemRoot: {
      '&:focus': {
        backgroundColor: theme.palette.secondary.main,
        '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
          color: theme.palette.common.white
        }
      }
    }
  };
};
/* export const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5'
  }
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center'
    }}
    {...props}
  />
));

export const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.secondary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white
      }
    }
  }
}))(MenuItem); */

export default useStyles;
