import { LocationI } from '../types/locationTypes';
import { SET_CURRENT_LOCATION, LocationActionTypes } from '../types/actions';

const locationReducerDefaultState: LocationI = { currentLocation: {} };

export const locationReducer = (
  state = locationReducerDefaultState,
  action: LocationActionTypes
): LocationI => {
  switch (action.type) {
    case SET_CURRENT_LOCATION:
      return { ...state, currentLocation: action.currentLocation };
    default:
      return state;
  }
};
