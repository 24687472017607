import { Box, Grid, Link, Stack, Tooltip, Typography } from '@mui/material';
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { CountResult, ProsjektI } from './types/ProjectTypes';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import { Dropdown, NButton, SlowText } from '../components/Inputs';
import ReactToPrint from 'react-to-print';
import {
  LopedagI,
  MaaltBeregningResultI,
  MaaltidI,
  MaaltidskomponentI,
  PersonI,
  StoffnMengdeI
} from './types/PersonTypes';
import { useCodeContext } from '../../../context/CodeContext';
import Divider from '@mui/material/Divider';
import { CodeRegisterI, TabI } from '../components/commons/CommonTypes';
import LopedagMaaltider from './LopedagMaaltider';
import LopedagValiderModal from './components/modals/LopedagValiderModal';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { codeTypes } from '../../../assets/kodeOption';
import LopedagMaaltidsKomponenter from './LopedagMaaltidsKomponenter';
import LopedagRemindersModal from './components/modals/LopedagRemindersModal';
import LopedagNyttMaaltidModal from './components/modals/LopedagNyttMaaltidModal';
import UtskriftModal from './components/modals/UtskriftModal';
import { useUserContext } from '../../../context/AuthContext';
import IsOwnedByUser from '../../utilities/Validate';
import { useKeycloak } from '@react-keycloak/web';
import { AlertI } from '../../../types/alertTypes';
import DeleteModal from './components/modals/DeleteModal';
import selectedDropdown from './components/SelectedDropdown';
import { ApiCall } from '../../utilities/ApiCall';
import { ItemOwner } from './types/UndersokelseTypes';
import { DatabaseContext } from '../../../context/DatabaseContext';
import { UtvalgsId } from '../beregning/types/BeregningTypes';
import { UserDetailI } from '../admin/AdminTypes';
import MaaltidKopiModal from './components/modals/MaaltidKopiModal';

const ukedagOptions = [
  'Mandag',
  'Tirsdag',
  'Onsdag',
  'Torsdag',
  'Fredag',
  'Lørdag',
  'Søndag'
];

interface CodeOptions {
  label: string;
  nr: number;
}

const vanligDagOptions: CodeOptions[] = [
  { label: 'Ja', nr: 1 },
  { label: 'Nei', nr: 2 }
];

const bildeBokOptions = [
  { label: 'Ja (papir)', nr: 1 },
  { label: 'Ja (nett)', nr: 2 },
  { label: 'Nei', nr: 3 }
];

interface RouteParams {
  prosjektId: string;
  undersokelseId: string;
  undersokelseKortnavn: string;
  personId: string;
}

interface LopedagProps extends RouteComponentProps<RouteParams> {
  lopedagData: LopedagI;
  setLopedagData: Dispatch<SetStateAction<LopedagI>>;
  personData: PersonI;
  maaltidskomponentData: MaaltidskomponentI[];
  setMaaltidskomponentData: Dispatch<SetStateAction<MaaltidskomponentI[]>>;
  setMaaltidskomponentDataCopy: Dispatch<SetStateAction<MaaltidskomponentI[]>>;
  maaltidNotat: string;
  setMaaltidNotat: Dispatch<SetStateAction<string>>;
  setMaaltidNotatCopy: Dispatch<SetStateAction<string>>;
  projectObject: ProsjektI;
  startTime: Date | null;
  handleAvslutt: () => void;
  setActiveTab: Dispatch<SetStateAction<number>>;
  setShowAlert: Dispatch<SetStateAction<boolean>>;
  setAlertMessage: Dispatch<SetStateAction<string>>;
  setAlertSeverity: Dispatch<SetStateAction<AlertI['alertColor']>>;
  handleSaveLopedag: (inputData?: LopedagI) => void;
  updatedLopedagData: boolean;
  setUpdatedLopedagData: Dispatch<SetStateAction<boolean>>;
  setRefetchPerson: Dispatch<SetStateAction<boolean>>;
  setRefetchLopedag: Dispatch<SetStateAction<boolean>>;
  currentLopedag: number;
  currentModalLopedag: number;
  currentMaaltidId: number;
  setCurrentMaaltidId: Dispatch<SetStateAction<number>>;
  callAlert: (message: string, severity: AlertI['alertColor']) => void;
  setTabArray: Dispatch<SetStateAction<TabI[]>>;
  saveAndUpdateMaaltidskomponenter: (inputData: MaaltidskomponentI[]) => void;
}

export default function Lopedag(props: LopedagProps) {
  const {
    lopedagData,
    setLopedagData,
    personData,
    maaltidskomponentData,
    setMaaltidskomponentData,
    setMaaltidskomponentDataCopy,
    maaltidNotat,
    setMaaltidNotat,
    setMaaltidNotatCopy,
    projectObject,
    startTime,
    handleAvslutt,
    setActiveTab,
    setShowAlert,
    setAlertMessage,
    setAlertSeverity,
    handleSaveLopedag,
    updatedLopedagData,
    setUpdatedLopedagData,
    setRefetchPerson,
    setRefetchLopedag,
    currentLopedag,
    currentModalLopedag,
    currentMaaltidId,
    setCurrentMaaltidId,
    callAlert,
    setTabArray,
    saveAndUpdateMaaltidskomponenter
  } = props;
  const { prosjektId, undersokelseId, undersokelseKortnavn, personId } =
    props.match.params;
  const { currentDatabase } = useContext(DatabaseContext);
  const [maaltidsData, setMaaltidsData] = useState<MaaltidI[] | null>(null);
  const [showValider, setShowValider] = useState<boolean>(false);
  const [showReminders, setShowReminders] = useState<boolean>(false);
  const [showMaaltid, setShowMaaltid] = useState<boolean>(false);
  const [showUtskrift, setShowUtskrift] = useState<boolean>(false);
  const [showDeleteLopedag, setShowDeleteLopedag] = useState<boolean>(false);
  const [showDeleteMaaltid, setShowDeleteMaaltid] = useState<boolean>(false);
  const [utvalgData, setUtvalgData] = useState<StoffnMengdeI[]>([]);
  const allCodes = useCodeContext();
  const maaltidTypes: CodeRegisterI[] = allCodes.codes?.filter((f) => f.value === 3);
  const printRef = useRef();
  const [fromExisting, setFromExisting] = useState<boolean>(false);
  const [intervjuere, setIntervjuere] = useState<UserDetailI[] | null>(null);
  const [lopedagHasInfo, setLopdagHasInfo] = useState<CountResult>();
  const [maaltidHasInfo, setMaaltidHasInfo] = useState<CountResult>();
  const [utvalgId, setUtvalgId] = useState<UtvalgsId>({ ID: '' });
  const [beregningResult, setBeregningResult] = useState<MaaltBeregningResultI>();
  const [showKopi, setShowKopi] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const loggedInuser = useUserContext();
  const owned = useMemo(
    () => IsOwnedByUser({ id: +prosjektId, u: loggedInuser }),
    [prosjektId, loggedInuser]
  );
  const { keycloak } = useKeycloak();

  useEffect(() => {
    setMaaltidsData(lopedagData?.maaltider);
  }, [lopedagData?.maaltider]);

  useEffect(() => {
    ApiCall(
      `getInterviewers/${prosjektId}/${undersokelseId}`,
      'GET',
      keycloak.token,
      setIntervjuere
    );
    ApiCall('getUtvalgId', 'GET', keycloak.token, setUtvalgId).catch(console.error);
  }, []);

  const getMaaltidsBeregning = (currentMaaltidId: number) => {
    if (!utvalgId.ID) {
      callAlert('Mangler utvalgID', 'error');
    }
    currentMaaltidId = utvalgData?.length ? currentMaaltidId : 0;
    if (currentLopedag && utvalgId?.ID) {
      ApiCall(
        `getMaaltidsBeregning/${currentDatabase?.value}/${prosjektId}/${undersokelseId}/${personId}/${currentLopedag}/${currentMaaltidId}/${utvalgId?.ID}`,
        'GET',
        keycloak.token,
        setBeregningResult
      );
    }
  };

  useEffect(() => {
    // here we made default maltidid to zero, because by default(when page loads) we calculate energy and drikke only
    if (utvalgId.ID) getMaaltidsBeregning(0);
  }, [utvalgId?.ID, currentDatabase.value]);

  const checkIfLopedagHasInfo = () => {
    if (!lopedagData?.personId || !lopedagData?.lopedagId) return;
    ApiCall(
      `getLopedagRelatedInfo/${prosjektId}/${undersokelseId}/${personData?.personId}/${lopedagData?.lopedagId}`,
      'GET',
      keycloak.token,
      setLopdagHasInfo
    ).catch(console.error);
  };

  const checkIfMaaltidHasInfo = () => {
    if (!lopedagData?.personId || !currentLopedag || !currentMaaltidId) return;
    ApiCall(
      `getMaaltidsRelatedInfo/${prosjektId}/${undersokelseId}/${personData?.personId}/${lopedagData?.lopedagId}/${currentMaaltidId}`,
      'GET',
      keycloak.token,
      setMaaltidHasInfo
    ).catch(console.error);
  };

  // DELETE LØPEDAG
  const handleDeleteLopedag = async () => {
    if (!owned) return;
    const response = await ApiCall(
      `deleteLopedag/${prosjektId}/${undersokelseId}/${personData?.personId}/${currentLopedag}`,
      'DELETE',
      keycloak.token
    );
    if (response.ok) {
      callAlert('Løpedag slettet', 'success');
      // setLopedagData(null);
      setRefetchPerson(true);
      setRefetchLopedag(true);
      setActiveTab((a) => a - 1);
      setTabArray((prev) => prev.filter((p) => p.idx !== currentLopedag));
    } else {
      callAlert('Sletting av løpedag feilet', 'error');
      console.log('Klarte ikke å slette løpedag!', response.status);
    }
  };

  // DELETE MÅLTID
  const handleDeleteMaaltid = async () => {
    if (!owned) return;
    const response = await ApiCall(
      `deleteMaaltid/${prosjektId}/${undersokelseId}/${lopedagData?.personId}/${lopedagData?.lopedagId}/${currentMaaltidId}`,
      'DELETE',
      keycloak.token
    );
    if (response.ok) {
      callAlert('Måltid slettet', 'success');
      setRefetchLopedag(true);
    } else {
      callAlert('Sletting av måltid feilet', 'error');
      console.log('Klarte ikke å slette måltid!', response.status);
    }
  };

  return (
    <>
      <Grid container spacing={2} justifyContent="space-between">
        <Grid item xs={7}>
          <Typography variant="h4" gutterBottom>
            Løpedag og intervjudetaljer
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <Stack direction="row-reverse" spacing={1}>
            <NButton
              children="Skriv ut"
              rightIcon={<PrintOutlinedIcon />}
              variant="text"
              onClick={() => setShowUtskrift(true)}
              // disabled={!personData?.personId}
            />
            {/* <ReactToPrint
              trigger={() => (
                <NButton
                  children="Skriv ut"
                  rightIcon={<PrintOutlinedIcon />}
                  variant="text"
                  onClick={() => setShowUtskrift(true)}
                  // disabled={!personData?.personId}
                />
              )}
              pageStyle=".styledTable{width:100%;border-collapse:collapse;text-align:left}.styledTable th,.styledTable td{border:2px solid black;padding:0.5em;}"
              content={() => printRef.current}
            /> */}

            <Tooltip title={!owned ? 'Mangler rettigheter' : ''}>
              <span>
                <NButton
                  children="Slett løpedag"
                  rightIcon={<DeleteOutlineIcon />}
                  variant="text"
                  onClick={() => {
                    setShowDeleteLopedag(true);
                    checkIfLopedagHasInfo();
                  }}
                  disabled={!owned}
                />
              </span>
            </Tooltip>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack direction={'row'} spacing={2}>
            {projectObject && (
              <Typography variant="h6">
                Prosjekt: {projectObject?.prosjektnavn} - {prosjektId}
              </Typography>
            )}
            <Typography variant="h6">Undersøkelse: {undersokelseKortnavn}</Typography>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack direction={'row'} spacing={2}>
            <Typography variant="h6">
              Person: {lopedagData?.personId} -{' '}
              {personData?.identitet && personData?.identitet}
            </Typography>
            <Typography variant="h6">Løpedag: {lopedagData?.lopedagId}</Typography>
          </Stack>
        </Grid>
      </Grid>
      <Stack
        direction={'row'}
        spacing={2}
        justifyContent="space-between"
        sx={{ mb: '1rem', mt: '2rem' }}
      >
        <Stack spacing={1} flexGrow={1} flexBasis={''}>
          <Stack direction={'row'} spacing={1}>
            <Box flexGrow={1}>
              <Dropdown
                header={'Ukedag'}
                value={lopedagData?.ukedag ?? null}
                options={ukedagOptions}
                onChange={(value: any) =>
                  setLopedagData({ ...lopedagData, ukedag: value })
                }
                readOnly={!owned}
              />
            </Box>
            <Box flexGrow={0}>
              <Dropdown
                header={'Vanlig dag?'}
                value={
                  vanligDagOptions.find((f) => f.nr === lopedagData?.vanligdag) ?? null
                }
                options={vanligDagOptions}
                optionLabel={(option) => option?.label}
                onChange={(value: any) =>
                  setLopedagData({ ...lopedagData, vanligdag: value?.nr })
                }
                readOnly={!owned}
              />
            </Box>
            <Box flexGrow={4}>
              <Dropdown
                header={codeTypes?.find((option) => option.value === 18)?.label}
                value={selectedDropdown(lopedagData?.arsakuvanligdag, 18) ?? null}
                options={allCodes.codes?.filter((f) => f.value === 18)}
                optionLabel={(option: CodeRegisterI) => option?.label}
                onChange={(value: any) =>
                  setLopedagData({ ...lopedagData, arsakuvanligdag: value?.nr })
                }
                readOnly={!owned}
              />
            </Box>
            <Box flexGrow={1}>
              <Dropdown
                header={'Bildebok?'}
                value={
                  bildeBokOptions.find((f) => f.nr === lopedagData?.bildebok) ?? null
                }
                options={bildeBokOptions}
                optionLabel={(option) => option?.label}
                onChange={(value: any) =>
                  setLopedagData({ ...lopedagData, bildebok: value?.nr })
                }
                readOnly={!owned}
              />
            </Box>
          </Stack>
          <Stack direction={'row'} spacing={1}>
            <Dropdown
              header={'Intervjuer'}
              value={intervjuere?.find((f) => f.id === lopedagData?.intervjuerId) ?? null}
              options={intervjuere}
              optionLabel={(option: ItemOwner) => option.brukerkode}
              onChange={(value: any) =>
                setLopedagData({ ...lopedagData, intervjuerId: value?.id })
              }
              disabled={!owned}
              readOnly={!owned}
            />
            <Dropdown
              header={codeTypes?.find((option) => option.value === 14)?.label}
              value={selectedDropdown(lopedagData?.intervjustedkode, 14)}
              options={allCodes.codes?.filter((f) => f.value === 14)}
              optionLabel={(option: CodeRegisterI) => option?.label}
              onChange={(value: any) =>
                setLopedagData({ ...lopedagData, intervjustedkode: value?.nr })
              }
              readOnly={!owned}
            />
            <Dropdown
              header={codeTypes?.find((option) => option.value === 11)?.label}
              value={selectedDropdown(lopedagData?.statuskode, 11)}
              options={allCodes.codes?.filter((f) => f.value === 11)}
              optionLabel={(option: CodeRegisterI) => option?.label}
              onChange={(value: any) => {
                value?.nr === 7 && !success
                  ? setShowValider(true)
                  : setLopedagData({ ...lopedagData, statuskode: value?.nr });
              }}
              readOnly={!owned}
            />
          </Stack>
          <Box>
            <SlowText
              name="lopedagsnotat"
              header="Løpedagsnotat"
              value={lopedagData?.notat || ''}
              onChange={(event) =>
                setLopedagData({ ...lopedagData, notat: event.target.value })
              }
              multiline
              minRows={3}
              InputProps={{
                readOnly: !owned
              }}
            />
          </Box>
        </Stack>
        <Stack spacing={1} flexBasis="content" justifyContent="center">
          <Tooltip title={!owned ? 'Mangler rettigheter' : ''}>
            <span>
              <NButton
                onClick={() => setShowValider(true)}
                children="Valider"
                variant="outlined"
                sx={{ width: '100%' }}
                disabled={!(owned && lopedagData?.maaltider?.length > 0)}
              />
            </span>
          </Tooltip>
          <Tooltip title={!owned ? 'Mangler rettigheter' : ''}>
            <span>
              <NButton
                children="Person / tilleggssvar"
                onClick={() => setShowReminders(true)}
                variant="outlined"
                sx={{ width: '100%' }}
                disabled={!owned}
              />
            </span>
          </Tooltip>
          {personData?.personId ? (
            <Link
              href={`/home/rapport/kostholdsdata/korrekturfil/${prosjektId}/${undersokelseId}/personId/${personData?.personId}/${personData?.personId}`}
              target="_blank"
            >
              <NButton
                children={'Rapport'}
                leftIcon={<OpenInNewIcon />}
                variant="outlined"
                sx={{ width: '100%' }}
                disabled={lopedagData?.maaltider?.length === 0}
              />
            </Link>
          ) : (
            <NButton
              children={'Rapport'}
              leftIcon={<OpenInNewIcon />}
              variant="outlined"
              disabled={lopedagData?.maaltider?.length === 0}
            />
          )}
          <Divider sx={{ pt: 2 }} />
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography variant="body1">Energiinntak (KJ): </Typography>
            <Typography variant="body1">
              {' '}
              <b>{beregningResult?.energinDrikke?.energi}</b>
            </Typography>
          </Stack>
          <Stack direction={'row'} justifyContent={'space-between'}>
            {beregningResult?.energinDrikke?.energi &&
            !beregningResult?.energinDrikke?.drikke ? (
              <Typography variant="body1">
                <i> Finnes ikke resultatgruppe 'Drikke'</i>
              </Typography>
            ) : (
              <>
                <Typography variant="body1">Drikke (g): </Typography>
                <Typography variant="body1">
                  <b>{beregningResult?.energinDrikke?.drikke}</b>
                </Typography>
              </>
            )}
          </Stack>
        </Stack>
      </Stack>

      <LopedagMaaltider
        owned={owned}
        lopedagData={lopedagData}
        setLopedagData={setLopedagData}
        maaltidsData={maaltidsData}
        maaltidNotat={maaltidNotat}
        setMaaltidNotat={setMaaltidNotat}
        setMaaltidNotatCopy={setMaaltidNotatCopy}
        maaltidTypes={maaltidTypes}
        currentMaaltidId={currentMaaltidId}
        setCurrentMaaltidId={setCurrentMaaltidId}
        setShowMaaltid={setShowMaaltid}
        setFromExisting={setFromExisting}
        setShowAlert={setShowAlert}
        setAlertMessage={setAlertMessage}
        setAlertSeverity={setAlertSeverity}
        setShowKopi={setShowKopi}
        // prosjektId={prosjektId}
        // undersokelseId={undersokelseId}
        // callAlert={callAlert}
        {...props}
      />

      <LopedagMaaltidsKomponenter
        maaltidskomponentData={maaltidskomponentData}
        setMaaltidskomponentData={setMaaltidskomponentData}
        setMaaltidskomponentDataCopy={setMaaltidskomponentDataCopy}
        lopedagData={lopedagData}
        maaltidsData={maaltidsData}
        maaltidTypes={maaltidTypes}
        currentMaaltidId={currentMaaltidId}
        utvalgId={utvalgId}
        setCurrentMaaltidId={setCurrentMaaltidId}
        setRefetchLopedag={setRefetchLopedag}
        stoffnMengdeData={beregningResult?.stoffnMengdes}
        getMaaltidsBeregning={getMaaltidsBeregning}
        utvalgData={utvalgData}
        setUtvalgData={setUtvalgData}
        saveAndUpdateMaaltidskomponenter={saveAndUpdateMaaltidskomponenter}
        // setMaaltidskomponentDeleteArray={setMaaltidskomponentDeleteArray}
        {...props}
      />

      <Stack direction={'row'} spacing={2} useFlexGap sx={{ mt: '3rem' }}>
        <Tooltip
          title={
            !owned
              ? 'Mangler rettigheter'
              : !lopedagData?.lopedagId
              ? 'LøpedagID mangler'
              : ''
          }
        >
          <span>
            <NButton
              children={'Lagre'}
              variant="contained"
              onClick={() => handleSaveLopedag(lopedagData)}
              disabled={!owned || !lopedagData?.lopedagId}
            />
          </span>
        </Tooltip>
        <Stack direction={'row'} alignItems={'end'} spacing={2}>
          <Tooltip
            title={
              !startTime
                ? 'Tidsregistrering har ikke startet'
                : lopedagData?.lopedagId !== currentModalLopedag
                ? 'Du har started tidsregistrering på en annen løpedag'
                : ''
            }
          >
            <span>
              <NButton
                children="Avslutt intervjuet"
                variant="outlined"
                onClick={handleAvslutt}
                disabled={
                  !owned || !startTime || lopedagData?.lopedagId !== currentModalLopedag
                }
              />
            </span>
          </Tooltip>
        </Stack>
        <NButton
          children={'Nullstill'}
          variant="contained"
          sx={{ ml: 'auto' }}
          disabled
        />
      </Stack>

      {/* dialogs */}

      {showUtskrift && (
        <UtskriftModal showing={showUtskrift} hide={() => setShowUtskrift(false)} />
      )}

      {showValider && (
        <LopedagValiderModal
          lopedagData={lopedagData}
          showing={showValider}
          hide={() => setShowValider(false)}
          setLopedagData={setLopedagData}
          success={success}
          setSuccess={setSuccess}
          handleSaveLopedag={handleSaveLopedag}
          updatedLopedagData={updatedLopedagData}
          setUpdatedLopedagData={setUpdatedLopedagData}
        />
      )}

      {showReminders && (
        <LopedagRemindersModal
          showing={showReminders}
          hide={() => setShowReminders(false)}
          setActiveTab={setActiveTab}
        />
      )}

      {showMaaltid && (
        <LopedagNyttMaaltidModal
          lopedagData={lopedagData}
          setLopedagData={setLopedagData}
          projectObject={projectObject}
          showing={showMaaltid}
          hide={() => setShowMaaltid(false)}
          currentMaaltidId={currentMaaltidId}
          setCurrentMaaaltidId={setCurrentMaaltidId}
          personId={personData?.personId}
          identitet={personData?.identitet}
          fromExisting={fromExisting}
          setRefetchLopedag={setRefetchLopedag}
          setShowDeleteMaaltid={setShowDeleteMaaltid}
          checkIfMaaltidHasInfo={checkIfMaaltidHasInfo}
          {...props}
        />
      )}

      {showDeleteLopedag && (
        <DeleteModal
          showing={showDeleteLopedag}
          hide={() => {
            setLopdagHasInfo(null);
            setShowDeleteLopedag(false);
          }}
          title="Slette løpedag?"
          body={
            lopedagHasInfo?.COUNT > 0
              ? `Løpedag ${currentLopedag} har ${lopedagHasInfo?.COUNT} registrerte måltider. Ønsker du å slette den?`
              : `Ønsker du å slette løpedag ${currentLopedag}?`
          }
          handleDelete={handleDeleteLopedag}
        />
      )}

      {showDeleteMaaltid && (
        <DeleteModal
          showing={showDeleteMaaltid}
          hide={() => {
            setMaaltidHasInfo(null);
            setShowDeleteMaaltid(false);
          }}
          title="Slette måltid?"
          body={
            maaltidHasInfo?.COUNT > 0
              ? `Måltid ${currentMaaltidId} har ${maaltidHasInfo?.COUNT} registrerte måltidskomponenter. Ønsker du å slette det?`
              : `Ønsker du å slette måltid ${currentMaaltidId}?`
          }
          handleDelete={handleDeleteMaaltid}
        />
      )}

      {showKopi && (
        <MaaltidKopiModal
          maaltidId={currentMaaltidId}
          lopedagId={lopedagData?.lopedagId}
          lopedags={personData?.lopedager}
          projectObject={projectObject}
          personData={personData}
          showing={showKopi}
          hide={() => setShowKopi(false)}
          setShowAlert={setShowAlert}
          setAlertMessage={setAlertMessage}
          setAlertSeverity={setAlertSeverity}
          setRefetchLopedag={setRefetchLopedag}
          {...props}
        />
      )}
    </>
  );
}
