import { useKeycloak } from '@react-keycloak/web/lib/useKeycloak';
import { KeycloakResourceAccess, KeycloakRoles } from 'keycloak-js';
import { useEffect, useState } from 'react';

interface kcUserProps {
  email: string;
  username: string;
  family_name: string;
  given_name: string;
  name: string;
  realm_access: KeycloakRoles;
  resource_access: KeycloakResourceAccess;
}

const useGetKeycloakUser = () => {
  const { keycloak } = useKeycloak();
  const [userInfo, setUserInfo] = useState<kcUserProps>({
    email: '',
    username: '',
    family_name: '',
    given_name: '',
    name: '',
    realm_access: { roles: [] },
    resource_access: {}
  });

  useEffect(() => {
    if (keycloak.authenticated) {
      const tokenParsed = keycloak?.tokenParsed as any;
      setUserInfo({
        email: tokenParsed.email,
        username: tokenParsed.email?.split('@')[0].toUpperCase(),
        family_name: tokenParsed.family_name,
        given_name: tokenParsed.given_name,
        name: tokenParsed.name,
        realm_access: tokenParsed.realm_access,
        resource_access: tokenParsed.resource_access
      });
    }
  }, [keycloak]);

  return { userInfo };
};

export default useGetKeycloakUser;
