import React, { useContext, useEffect, useRef, useState } from 'react';
import { DialogBox } from '../../../../utilities/DialogBox';
import {
  BeregningObject,
  DefaultBeregningCounts,
  MatvareGroupData,
  UtvalgTableData
} from '../../types/BeregningTypes';
import {
  Matvare,
  MatvaregruppeI,
  TableType,
  resultatGruppeI
} from '../../../matvare/types/matvaregruppeTypes';
import { BeregningMatvareGruppe } from './BeregningMatvareGruppe';
import { FormControl, FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material';
import { DatabaseContext } from '../../../../../context/DatabaseContext';
import useStyles from '../../../../../css/materialtheme';
import useDidMountEffect from '../../../../utilities/useDidMountEffect';
import { removeArrayFromAnotherArray } from '../../../../utilities/ArrayFunctions';
import { NButton } from '../../../components/Inputs';

interface BeregningMatvareGruppeProps {
  showMatvaregruppe: boolean;
  setShowMatvaregruppe: (val: boolean) => void;
  selectedMatvares?: Matvare[];
  setSelectedMatvares?: (mat: Matvare[]) => void;
  selectedMatvaregruppes?: MatvaregruppeI[];
  setSelectedMatvaregruppes?: (mg: MatvaregruppeI[]) => void;
  setShowProgress: (value: boolean) => void;
  setTotalCounts: (value: DefaultBeregningCounts) => void;
  totalCounts: DefaultBeregningCounts;
  updateBeregningObject: (item: string, name: string, value: any) => void;
  beregningObject?: BeregningObject;
  utvalgData: UtvalgTableData;
  selectedResultatGroup: resultatGruppeI;
  setProgressText: (text: string) => void;
}
export const MatvareGruppeTablePopup = (props: BeregningMatvareGruppeProps) => {
  const {
    showMatvaregruppe,
    setShowMatvaregruppe,
    selectedMatvares,
    setSelectedMatvares,
    selectedMatvaregruppes,
    setSelectedMatvaregruppes,
    setShowProgress,
    updateBeregningObject,
    //setTotalCounts,
    totalCounts,
    beregningObject,
    utvalgData,
    selectedResultatGroup,
    setProgressText
  } = props;
  const classes = useStyles();
  const { currentDatabase } = useContext(DatabaseContext);
  const [matvarerList, setMatvarerList] = useState<Matvare[]>([]);
  const [matvaregruppeList, setMatvaregruppeList] = useState<MatvaregruppeI[]>([]);
  const [showRadioBtns, setShowRadioBtns] = useState(false);
  const [expanded, setExpanded] = useState<boolean>(false);

  const [highLevelCategory, setHighLevelCategory] = useState(
    beregningObject?.matvareData?.highLevelCategory
  );
  const [loadCount, setLoadCount] = useState(false);
  /*  const getTotalCounts = useFetchData({
     loadOnMount: false,
     clearDataOnLoad: false,
     projectURL: 'getBeregnutvalgTotals',
     subProjectURL: `${encodeURI(beregningObject?.utvalgId)}`
   }); */

  useEffect(() => {
    if (selectedResultatGroup?.GRUPPETYPEID > 0) {
      setShowRadioBtns(true);
      if (
        selectedResultatGroup?.GRUPPETYPEID !== beregningObject?.matvareData?.gruppetypeId
      ) {
        //updateBeregningObject('matvare', 'highLevelCategory', 0);
        updateBeregningObject(
          'matvare',
          'gruppetypeId',
          selectedResultatGroup?.GRUPPETYPEID
        );
        updateBeregningObject(
          'matvare',
          'resultatGroup',
          selectedResultatGroup?.BESKRIVELSE
        );
        updateBeregningObject('matvare', 'matvaresInGroup', false);
        setHighLevelCategory(0);
        //Todo : show alert that selected input is gonna change
      }
      //setHighLevelCategory(0);
    }
  }, [selectedResultatGroup]);
  useDidMountEffect(() => {
    if (selectedMatvares?.length > 0 || selectedMatvaregruppes?.length > 0) {
      //TODO: temporarily commented, find a solution for this
      //setHighLevelCategory(0);
    }
  }, [selectedMatvaregruppes, selectedMatvares]);

  const handleChangeRadioButtonSelection = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    //setMatvareFjernRadioButton(0);
    const selectedOption = +(event.target as HTMLInputElement).value;
    if (selectedOption === 5) {
      setExpanded((prevExpanded) => !prevExpanded);
    } else {
      setExpanded(false);
    }
    setHighLevelCategory(selectedOption);
    updateBeregningObject('matvare', 'highLevelCategory', selectedOption);
    updateBeregningObject('matvare', 'matvaregroups', []);
    updateBeregningObject('matvare', 'individualMatvares', []);
    updateBeregningObject('matvare', 'matvaresInGroup', false);
  };
  const MGRef = useRef<MatvareGroupData[]>([]);
  useDidMountEffect(() => {
    if (utvalgData?.matvaregroupData?.length > 0) {
      MGRef.current = utvalgData?.matvaregroupData;
    }
  }, [utvalgData?.matvaregroupData]);

  const toggleShowMatvaregrupper = () => {
    updateBeregningObject('matvare', 'individualMatvares', selectedMatvares);
    updateBeregningObject('matvare', 'matvaregroups', selectedMatvaregruppes);
    setShowMatvaregruppe(!showMatvaregruppe);
  };
  const clearSelectedMgs = () => {
    const updatedMatvaregruppes = removeArrayFromAnotherArray(
      selectedMatvaregruppes,
      matvaregruppeList,
      'NODENRID'
    );
    setSelectedMatvaregruppes(updatedMatvaregruppes);
  };
  return (
    <DialogBox
      title={'Velg matvarer'}
      isShowing={showMatvaregruppe}
      hide={toggleShowMatvaregrupper}
    >
      <Grid container justifyContent="space-between">
        <Grid item xs={12}>
          {showRadioBtns && (
            <FormControl variant="outlined" component="fieldset">
              <RadioGroup
                row={true}
                name="Velg"
                value={highLevelCategory}
                onChange={handleChangeRadioButtonSelection}
              >
                <FormControlLabel
                  value={1}
                  control={<Radio size="small" color="primary" />}
                  label="Hovedgrupper"
                  sx={{ label: classes.RadioButton }}
                  labelPlacement="end"
                />
                <FormControlLabel
                  value={2}
                  control={<Radio size="small" color="primary" />}
                  label="Alle grupper"
                  sx={{ label: classes.RadioButton }}
                  labelPlacement="end"
                />
                <FormControlLabel
                  value={3}
                  control={<Radio size="small" color="primary" />}
                  label="Alle matvarer"
                  sx={{ label: classes.RadioButton }}
                  labelPlacement="end"
                />
                <FormControlLabel
                  value={4}
                  control={<Radio size="small" color="primary" />}
                  label="Grupper + matvarer"
                  sx={{ label: classes.RadioButton }}
                  labelPlacement="end"
                />
                <FormControlLabel
                  value={5}
                  control={<Radio size="small" color="primary" />}
                  label="Velg enkelte matvaregrupper/matvarer selv"
                  sx={{ label: classes.RadioButton }}
                  labelPlacement="end"
                />
              </RadioGroup>
            </FormControl>
          )}
        </Grid>
      </Grid>
      <BeregningMatvareGruppe
        beregningObject={beregningObject}
        highLevelCategory={highLevelCategory}
        gruppetypeId={selectedResultatGroup?.GRUPPETYPEID}
        setShowProgress={setShowProgress}
        matvaregruppeList={matvaregruppeList}
        setMatvaregruppeList={setMatvaregruppeList}
        selectedMatvares={selectedMatvares}
        setSelectedMatvares={setSelectedMatvares}
        matvarerList={matvarerList}
        setMatvarerList={setMatvarerList}
        selectedMatvaregruppes={selectedMatvaregruppes}
        setSelectedMatvaregruppes={setSelectedMatvaregruppes}
        totalCounts={totalCounts}
        databaseId={currentDatabase?.value}
        setLoadCount={setLoadCount}
        updateBeregningObject={updateBeregningObject}
        setProgressText={setProgressText}
        expanded={expanded}
        // utvalgData={utvalgData}
      />
      <Grid container padding={4}>
        <Grid container item xs={6} justifyContent="flex-start" alignItems="flex-end">
          <NButton children="Ok" onClick={toggleShowMatvaregrupper} />
        </Grid>
        <Grid container item xs={6} justifyContent="flex-end" alignItems="flex-end">
          <NButton
            children="Nullstill"
            variant="outlined"
            onClick={clearSelectedMgs}
            //disabled={selectedMatvaregruppes?.length === 0}
          />
        </Grid>
      </Grid>
    </DialogBox>
  );
};
