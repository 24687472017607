import {
  Box,
  Collapse,
  IconButton,
  Link,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import React, {
  Dispatch,
  SetStateAction,
  useState,
  MouseEvent,
  useContext,
  ChangeEvent
} from 'react';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import { MaaltidskomponentI, UtbyttetI } from './types/PersonTypes';
import FindReplaceIcon from '@mui/icons-material/FindReplace';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { NoScrollTableInput, NButton, NoScrollTableInput2 } from '../components/Inputs';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { EnhancedTableHead, Order } from '../../utilities/EnhancedTableHead';
import { HeadCell } from '../components/commons/CommonTypes';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { MatvareData } from '../matvare/types/MatvareTypes';
import { AlertI } from '../../../types/alertTypes';
import { ApiCall } from '../../utilities/ApiCall';
import { DatabaseContext } from '../../../context/DatabaseContext';
import { useKeycloak } from '@react-keycloak/web';

const ingredientHeadCells: HeadCell<UtbyttetI>[] = [
  { id: 'ingrediensId', align: 'left', padding: 'normal', label: 'Kode' },
  { id: 'navn', align: 'left', padding: 'none', label: 'Navn' },
  { id: 'mengde', align: 'right', padding: 'normal', label: 'Gram' },
  { id: 'matvareinn', align: 'right', padding: 'normal', label: 'Kode' },
  { id: 'matvareinnNavn', align: 'left', padding: 'normal', label: 'Navn' }
];

interface EnhancedMatvareRowProps {
  owned: boolean;
  maaltidskomponentData: MaaltidskomponentI[];
  setMaaltidskomponentData: Dispatch<SetStateAction<MaaltidskomponentI[]>>;
  row: MaaltidskomponentI;
  headCells: HeadCell<MaaltidskomponentI>[];
  index: number;
  order: Order;
  orderBy: string;
  setSelectedKode: Dispatch<SetStateAction<number>>;
  selectedMaaltidId: number;
  setSelectedMaaltidId: Dispatch<SetStateAction<number>>;
  selectedOrdnrId: number;
  setSelectedOrdnrId: Dispatch<SetStateAction<number>>;
  setShowMengdeEnheter: Dispatch<SetStateAction<boolean>>;
  handleChange: (
    field: Extract<keyof MaaltidskomponentI, string | number>,
    value: string | number,
    set: Dispatch<SetStateAction<MaaltidskomponentI>>
  ) => void;
  handleEnhetSubmit: (
    get: MaaltidskomponentI,
    set: Dispatch<SetStateAction<MaaltidskomponentI>>,
    fromNewRow: boolean
  ) => void;
  editRowData: MaaltidskomponentI;
  setEditRowData: Dispatch<SetStateAction<MaaltidskomponentI>>;
  setShowDeleteKomponent: Dispatch<SetStateAction<boolean>>;
  checkIfKomponentHasInfo: () => void;
  currentMaaltidId: number;
  utbyttetData: UtbyttetI[];
  setUtbyttetData: Dispatch<SetStateAction<UtbyttetI[]>>;
  setFromUtbyttet: Dispatch<SetStateAction<boolean>>;
  callAlert: (message: string, severity: AlertI['alertColor']) => void;
  setShowMatvareListe: Dispatch<SetStateAction<boolean>>;
  currentIngredient: number;
  setCurrentIngredient: Dispatch<SetStateAction<number>>;
  handleSaveUtbyttet: () => void;
  openRowArray: number[];
  setOpenRowArray: Dispatch<SetStateAction<number[]>>;
  rowIdToEdit: number;
  setRowIdToEdit: Dispatch<SetStateAction<number>>;
  maaltidIdToEdit: number;
  setMaaltidIdToEdit: Dispatch<SetStateAction<number>>;
  openRowId: number;
  setOpenRowId: Dispatch<SetStateAction<number>>;
  handleBytteDelete: (inputData: UtbyttetI) => void;
}

export default function EnchancedMatvareRow(props: EnhancedMatvareRowProps) {
  const {
    owned,
    maaltidskomponentData,
    setMaaltidskomponentData,
    row,
    headCells,
    index,
    order,
    orderBy,
    setSelectedKode,
    selectedMaaltidId,
    setSelectedMaaltidId,
    selectedOrdnrId,
    setSelectedOrdnrId,
    setShowMengdeEnheter,
    handleChange,
    handleEnhetSubmit,
    editRowData,
    setEditRowData,
    setShowDeleteKomponent,
    checkIfKomponentHasInfo,
    currentMaaltidId,
    utbyttetData,
    setUtbyttetData,
    setFromUtbyttet,
    callAlert,
    setShowMatvareListe,
    currentIngredient,
    setCurrentIngredient,
    handleSaveUtbyttet,
    rowIdToEdit,
    setRowIdToEdit,
    maaltidIdToEdit,
    setMaaltidIdToEdit,
    openRowId,
    setOpenRowId,
    handleBytteDelete
  } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { currentDatabase } = useContext(DatabaseContext);
  const { keycloak } = useKeycloak();

  const singleSetUtbyttetData = (obj: Partial<Pick<UtbyttetI, keyof UtbyttetI>>) => {
    setUtbyttetData((prev) =>
      prev.map((p) => (p.ingrediensId === currentIngredient ? { ...p, ...obj } : p))
    );
  };

  const handleMove = (index: number, direction: string) => {
    maaltidskomponentData?.sort((a, b) => a.sortering - b.sortering);
    const clickedRow = maaltidskomponentData?.find((n) => n.sortering === index);
    const adjacentRow =
      maaltidskomponentData[
        maaltidskomponentData?.indexOf(clickedRow) +
          (order === 'desc'
            ? -(direction === 'up' ? -1 : 1)
            : direction === 'up'
            ? -1
            : 1)
      ];
    const newSortering = maaltidskomponentData?.map((n) =>
      n.sortering === clickedRow?.sortering
        ? { ...n, sortering: adjacentRow?.sortering }
        : n.sortering === adjacentRow?.sortering
        ? { ...n, sortering: clickedRow?.sortering }
        : n
    );
    setMaaltidskomponentData(newSortering);
  };

  const handleBytteNavnChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    singleSetUtbyttetData({ matvareinn: null, matvareinnNavn: event.target.value });
    setShowMatvareListe(true);
    setFromUtbyttet(true);
  };

  const handleBytteKodeChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: string
  ) => {
    const { value } = event.target;
    if (+value < 0) {
      callAlert(
        `${field.charAt(0).toUpperCase()}${field.slice(1)} kan ikke være negativ`,
        'error'
      );
      return;
    }
    singleSetUtbyttetData({ [field]: +value });
  };

  const handleBytteKodeSubmit = async (kode: number) => {
    if (!kode) return;
    if (
      utbyttetData?.find((m) => m.ingrediensId === currentIngredient)?.ingrediensId ===
      kode
    ) {
      callAlert(`Matvaren kan ikke erstattes med seg selv`, 'error');
      singleSetUtbyttetData({ matvareinn: null });
      return;
    }
    if (row?.kode === kode) {
      callAlert('Matvaren kan ikke være med i sin egen oppskrift', 'error');
      singleSetUtbyttetData({ matvareinn: null });
      return;
    }
    const response = await ApiCall<MatvareData>(
      `getMatvareByKode/${currentDatabase?.value}/${kode}`,
      'GET',
      keycloak.token
    );
    if (response?.ok) {
      const data = await response.json();
      if (!data?.NAVN) {
        singleSetUtbyttetData({ matvareinn: null, matvareinnNavn: null });
        callAlert(`Kode ${kode} finnes ikke`, 'error');
      } else {
        singleSetUtbyttetData({ matvareinnNavn: data?.NAVN });
      }
    } else {
      console.log('error', response);
    }
  };

  const handleBubbleClick = () => {
    singleSetUtbyttetData({
      matvareinn: null,
      matvareinnNavn: null
    });
    setFromUtbyttet(true);
    setShowMatvareListe(true);
  };

  const handleBytteNullstill = () => {
    const newData = utbyttetData?.map((b) => ({
      ...b,
      matvareinn: undefined,
      matvareinnNavn: undefined
    }));
    setUtbyttetData(newData);
  };

  const handleCopy = (kode: number, maaltidsId: number, ordnrId: number) => {
    if (row.utbyttet > 0) {
      callAlert('Kan ikke kopiere en matvarekomponent som har utbyttinger', 'error');
      return;
    }
    const objectString = JSON.stringify(
      maaltidskomponentData?.find(
        (m) => m.kode === kode && m.maaltidsId === maaltidsId && m.ordnrId === ordnrId
      )
    );
    navigator.clipboard.writeText(objectString);
    setAnchorEl(null);
  };

  return (
    <>
      <TableRow
        onClick={() => {
          setSelectedMaaltidId(row.maaltidsId);
          setSelectedKode(row.kode);
          setSelectedOrdnrId(row.ordnrId);
        }}
        sx={{
          '& > *': { borderBottom: 'unset !important' },
          bgcolor:
            openRowId === row.kode &&
            selectedMaaltidId === row.maaltidsId &&
            selectedOrdnrId === row.ordnrId
              ? 'aliceblue'
              : ''
        }}
        hover
      >
        {headCells.map((cell) =>
          cell.id === 'kode' || cell.id === 'navn' ? (
            <TableCell key={cell.id} align={cell.align} padding={cell.padding}>
              <Link href={'/home/matvaredata/matvare/' + row?.kode} target="_blank">
                {row[cell.id]}
              </Link>
            </TableCell>
          ) : cell.id === 'enhet' ? (
            <TableCell key={cell.id} align={cell.align} padding={cell.padding}>
              {rowIdToEdit === row.kode &&
              maaltidIdToEdit === row.maaltidsId &&
              selectedOrdnrId === row.ordnrId ? (
                <Stack direction={'row'} alignItems="center">
                  <IconButton
                    onClick={() => {
                      setShowMengdeEnheter(true);
                    }}
                  >
                    <ChatBubbleOutlineIcon />
                  </IconButton>
                  <TextField
                    variant="standard"
                    type="text"
                    value={editRowData?.enhet || ''}
                    sx={{ minWidth: '3rem' }}
                    onChange={(event) =>
                      handleChange(cell.id, event.target.value, setEditRowData)
                    }
                    onKeyDown={(event) =>
                      event.key === 'Enter' &&
                      handleEnhetSubmit(editRowData, setEditRowData, false)
                    }
                    onBlur={() => handleEnhetSubmit(editRowData, setEditRowData, false)}
                  />
                </Stack>
              ) : (
                row[cell.id]
              )}
            </TableCell>
          ) : cell.id === 'antall' || cell.id === 'mengde' ? (
            <TableCell key={cell.id} align={cell.align} padding={cell.padding}>
              {rowIdToEdit === row.kode &&
              maaltidIdToEdit === row.maaltidsId &&
              selectedOrdnrId === row.ordnrId ? (
                <TextField
                  variant="standard"
                  type="number"
                  value={editRowData?.[cell.id] || ''}
                  sx={{ minWidth: '3rem' }}
                  onChange={(event) =>
                    handleChange(cell.id, event.target.value, setEditRowData)
                  }
                />
              ) : (
                Number(row[cell.id])?.toFixed(2)
              )}
            </TableCell>
          ) : cell.id === 'utbyttet' ? (
            <TableCell
              key={cell.id}
              align={cell.align}
              padding={cell.padding}
              sx={{ pb: 0, pt: 0 }}
            >
              <Stack direction={'row'} alignItems="center" justifyContent={'end'}>
                {row['navn']?.includes('X') && (
                  <IconButton
                    onClick={() =>
                      setOpenRowId((prev) => (prev === row.kode ? 0 : row.kode))
                    }
                  >
                    <FindReplaceIcon />
                  </IconButton>
                )}
                {row[cell.id]}
              </Stack>
            </TableCell>
          ) : cell.id !== 'maaltidsId' ? (
            <TableCell key={cell.id} align={cell.align} padding={cell.padding}>
              {row[cell.id]}
            </TableCell>
          ) : currentMaaltidId === 0 ? (
            <TableCell key={cell.id} align="right" padding="checkbox">
              {row.maaltidsId}
            </TableCell>
          ) : null
        )}
        {owned && (
          <TableCell align="center" sx={{ pb: 0, pt: 0 }}>
            <Stack direction={'row'} alignItems="center">
              <IconButton
                disabled={orderBy !== 'sortering' || index === 0}
                onClick={() => handleMove(row.sortering, 'up')}
              >
                <ArrowUpwardIcon />
              </IconButton>
              <IconButton
                disabled={
                  orderBy !== 'sortering' || index === maaltidskomponentData?.length - 1
                }
                onClick={() => handleMove(row.sortering, 'down')}
              >
                <ArrowDownwardIcon />
              </IconButton>
            </Stack>
          </TableCell>
        )}
        {owned && (
          <TableCell padding="none" align="center">
            {rowIdToEdit === row.kode &&
            maaltidIdToEdit === row.maaltidsId &&
            selectedOrdnrId === row.ordnrId ? (
              <IconButton
                aria-label="done"
                onClick={() => {
                  setRowIdToEdit(0);
                  setMaaltidIdToEdit(0);
                  setMaaltidskomponentData((prev) =>
                    prev.map((p) =>
                      p.kode === row.kode &&
                      p.maaltidsId === row.maaltidsId &&
                      p.ordnrId === row.ordnrId
                        ? { ...editRowData }
                        : p
                    )
                  );
                }}
              >
                <CheckOutlinedIcon />
              </IconButton>
            ) : (
              <IconButton
                aria-label="edit"
                onClick={() => {
                  setRowIdToEdit(row.kode);
                  setMaaltidIdToEdit(row.maaltidsId);
                  setEditRowData(
                    maaltidskomponentData?.find(
                      (k) =>
                        k.kode === row.kode &&
                        k.maaltidsId === row.maaltidsId &&
                        k.ordnrId === row.ordnrId
                    )
                  );
                }}
                disabled={rowIdToEdit > 0}
              >
                <EditOutlinedIcon />
              </IconButton>
            )}
          </TableCell>
        )}
        {owned && (
          <TableCell align="right" sx={{ pb: 0, pt: 0 }}>
            <IconButton
              onClick={(event: MouseEvent<HTMLButtonElement>) =>
                setAnchorEl(event.currentTarget)
              }
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
            >
              <MenuItem onClick={() => handleCopy(row.kode, row.maaltidsId, row.ordnrId)}>
                <ListItemIcon>
                  <ContentCopyIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Kopier</ListItemText>
              </MenuItem>
              <MenuItem disabled>
                <ListItemIcon>
                  <ContentPasteIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Lime inn</ListItemText>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setShowDeleteKomponent(true);
                  checkIfKomponentHasInfo();
                  setAnchorEl(null);
                }}
              >
                <ListItemIcon>
                  <DeleteOutlineIcon />
                </ListItemIcon>
                <ListItemText>Slette</ListItemText>
              </MenuItem>
            </Menu>
          </TableCell>
        )}
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={currentMaaltidId > 0 ? 11 : 12}
        >
          <Collapse
            appear
            in={
              openRowId === row.kode &&
              selectedMaaltidId === row.maaltidsId &&
              selectedOrdnrId === row.ordnrId
            }
            timeout="auto"
          >
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Matvare: {row?.kode}, {row.navn}
              </Typography>
              <TableContainer component={Paper}>
                <Table size="small">
                  <EnhancedTableHead<UtbyttetI>
                    headCells={ingredientHeadCells}
                    rightColumns={[{ name: 'Fjerne', align: 'right' }]}
                  />
                  <TableBody>
                    {utbyttetData?.map((bytteRow) => (
                      <TableRow
                        key={bytteRow.ingrediensId}
                        onClick={() => setCurrentIngredient(bytteRow.ingrediensId)}
                        sx={{
                          ':not(:hover)': {
                            backgroundColor: '#F1F1F1',
                            '& > :nth-of-type(4), & > :nth-of-type(5)': {
                              backgroundColor: '#FFFFFF'
                            }
                          }
                        }}
                        hover
                      >
                        {ingredientHeadCells.map((cell) =>
                          cell.id === 'matvareinn' ? (
                            <TableCell
                              key={cell.id}
                              align="right"
                              padding={cell.padding}
                              sx={{ pb: 0, pt: 0 }}
                            >
                              <Stack direction="row" alignItems="center">
                                <IconButton
                                  onClick={handleBubbleClick}
                                  disabled={!currentMaaltidId}
                                >
                                  <ChatBubbleOutlineIcon />
                                </IconButton>
                                <NoScrollTableInput2
                                  variant="standard"
                                  type="number"
                                  value={bytteRow?.matvareinn || ''}
                                  sx={{ width: '100%' }}
                                  onChange={(event) =>
                                    handleBytteKodeChange(event, 'matvareinn')
                                  }
                                  onKeyDown={(event) =>
                                    event.key === 'Enter' &&
                                    handleBytteKodeSubmit(bytteRow?.matvareinn)
                                  }
                                  onBlur={() =>
                                    handleBytteKodeSubmit(bytteRow?.matvareinn)
                                  }
                                  inputProps={{
                                    style: {
                                      textAlign: 'right'
                                    }
                                  }}
                                  disabled={!currentMaaltidId && !selectedMaaltidId}
                                />
                              </Stack>
                            </TableCell>
                          ) : cell.id === 'matvareinnNavn' ? (
                            <TableCell
                              key={cell.id}
                              align={cell.align}
                              padding={cell.padding}
                              sx={{ pb: 0, pt: 0 }}
                            >
                              <Stack direction={'row'} alignItems="center">
                                <IconButton
                                  onClick={handleBubbleClick}
                                  disabled={!currentMaaltidId}
                                >
                                  <ChatBubbleOutlineIcon />
                                </IconButton>
                                {bytteRow?.matvareinnNavn ? (
                                  <Link
                                    href={
                                      '/home/matvaredata/matvare/' +
                                      bytteRow?.matvareinnNavn
                                    }
                                    target="_blank"
                                  >
                                    {bytteRow.matvareinnNavn}
                                  </Link>
                                ) : (
                                  <NoScrollTableInput
                                    type="text"
                                    value={bytteRow?.matvareinnNavn || ''}
                                    sx={{ width: '100%' }}
                                    onChange={(event) => handleBytteNavnChange(event)}
                                    disabled={!currentMaaltidId && !selectedMaaltidId}
                                  />
                                )}
                              </Stack>
                            </TableCell>
                          ) : (
                            <TableCell
                              key={cell.id}
                              align={cell.align}
                              padding={cell.padding}
                            >
                              {bytteRow[cell.id]}
                            </TableCell>
                          )
                        )}
                        <TableCell align="right" sx={{ pb: 0, pt: 0.35 }}>
                          <IconButton onClick={() => handleBytteDelete(bytteRow)}>
                            <HighlightOffIcon color={'action'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Stack direction={'row'} spacing={1} marginTop={1}>
                <NButton children="Lagre bytting" onClick={handleSaveUtbyttet} />
                <NButton
                  children="Nullstill"
                  onClick={handleBytteNullstill}
                  disabled={
                    !utbyttetData?.filter((b) => b.matvareinn || b.matvareinnNavn)?.length
                  }
                />
              </Stack>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
