import React, { useEffect } from 'react';
import {
  BeregningObject,
  ReportResultTableI,
  ReportTwoI
} from '../../types/BeregningTypes';
import PollingComponent from '../../../components/commons/PollingComponent';
import useDidMountEffect from '../../../../utilities/useDidMountEffect';

const report2Statistics = [
  { DBNAME: 'GJSNITT', NAME: 'Gj.snitt' },
  { DBNAME: 'STDAVV', NAME: 'Std.avvik' },
  { DBNAME: 'MAX', NAME: 'Max' },
  { DBNAME: 'MIN', NAME: 'Min' },
  { DBNAME: 'PERS05', NAME: 'Persentil 5' },
  { DBNAME: 'PERS25', NAME: 'Persentil 25' },
  { DBNAME: 'PERS50', NAME: 'Persentil 50' },
  { DBNAME: 'PERS75', NAME: 'Persentil 75' },
  { DBNAME: 'PERS90', NAME: 'Persentil 90' },
  { DBNAME: 'PERS95', NAME: 'Persentil 95' },
  { DBNAME: 'PERS975', NAME: 'Persentil 97,5' }
];
const finalStatistics = [
  { DBNAME: 'ANTALL', NAME: 'Antall' },
  { DBNAME: 'PROSENT', NAME: 'Prosent av alle' }
];
interface ReportTwoProps {
  beregningObject: BeregningObject;
  setReportData: (value: any[]) => void;
  setReportInfo: (value: Array<Array<string>>) => void;
  setErrorReportId: (value: number) => void;
  result: any;
}
export const Report2Result = (props: ReportTwoProps) => {
  const [data] = React.useState([]);
  const [info] = React.useState<Array<Array<string>>>([]);
  const { beregningObject, setReportData, setReportInfo, setErrorReportId, result } =
    props;
  useEffect(() => {
    if (result) {
      console.log(' Beregning result.', 'success');
      const data = result as unknown as ReportTwoI;
      if (
        data?.reportInfo !== undefined &&
        data?.reportInfo?.beregningInfo !== undefined &&
        data?.matvares !== undefined &&
        data?.mengdes !== undefined
      ) {
        prepareResult(data);
      }
    }
  }, [result]);

  const prepareResult = (reportTwoData: ReportTwoI) => {
    let description: string[];
    setErrorReportId(reportTwoData?.reportInfo?.reportId);
    for (let r of reportTwoData?.reportInfo?.beregningInfo) {
      description = [];
      description.push(r.TEKSTLINJE);
      info.push(description);
    }
    setReportInfo(info);
    let result: any[] = [];
    const shortNames: any[] = [];
    const names: any[] = [];
    const enames: any[] = [];
    const enhet: any[] = [];
    shortNames.push('');
    names.push('');
    enames.push('');
    enhet.push('');

    for (let mat of reportTwoData?.matvares) {
      shortNames.push(mat.KORTNAVN);
      names.push(mat.NAVN);
      enames.push(mat.NAVN_ENGELSK);
      enhet.push('g/10MJ');
    }
    data.push(shortNames);
    data.push(names);
    if (beregningObject?.matvareData?.bregningCheckboxes?.engelskNavn) {
      data.push(enames);
    }
    data.push(enhet);
    data.push(['']);
    data.push(['Totalmengder']);
    data.push(['']);
    data.push(['']);
    let m = 0;
    for (let s of report2Statistics) {
      result = [];
      result.push(s.NAME);
      for (let i = 0; i < reportTwoData?.matvares?.length; i++) {
        if (reportTwoData?.mengdes?.length > 0) {
          result.push(reportTwoData?.mengdes[i][s.DBNAME]);
          m = i;
        }
      }
      data.push(result);
    }
    data.push(['']);
    data.push(['Mengder for bare de som har spist matvare/-gruppe']);
    data.push(['']);
    data.push(['']);

    for (let s of report2Statistics) {
      result = [];
      result.push(s.NAME);
      for (let i = m + 1; i < reportTwoData?.mengdes?.length; i++) {
        if (reportTwoData?.mengdes?.length > 0) {
          result.push(reportTwoData?.mengdes[i][s.DBNAME]);
        }
      }
      data.push(result);
    }
    data.push(['']);
    data.push(['Personer som har spist matvare/-gruppe']);
    data.push(['']);
    for (let s of finalStatistics) {
      result = [];
      result.push(s.NAME);
      for (let i = m + 1; i < reportTwoData?.mengdes?.length; i++) {
        if (reportTwoData?.mengdes?.length > 0) {
          result.push(reportTwoData?.mengdes[i][s.DBNAME]);
        }
      }
      data.push(result);
    }
    setReportData(data);
  };

  return <></>;
};
