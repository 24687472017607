import {
  Link,
  Paper,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Tooltip
} from '@mui/material';
import React, { ChangeEvent, Dispatch, SetStateAction, useMemo, useState } from 'react';
import { MatvareResult } from './types/MatvareTypes';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
  EnhancedTableHead,
  getComparator,
  Order
} from '../../utilities/EnhancedTableHead';
import { HeadCell } from '../components/commons/CommonTypes';
import { MaaltidskomponentI } from '../kosthold/types/PersonTypes';

const headCells: HeadCell<MatvareResult>[] = [
  {
    id: 'MATVAREKODEID',
    align: 'left',
    padding: 'none',
    label: 'Kode'
  },
  {
    id: 'NAVN',
    align: 'left',
    padding: 'none',
    label: 'Navn'
  },
  {
    id: 'NAVN_ENGELSK',
    align: 'left',
    padding: 'none',
    label: 'Engelsk navn'
  },
  {
    id: 'BESKRIVELSE',
    align: 'left',
    padding: 'normal',
    label: 'Beskrivelse'
  },
  {
    id: 'OPPSKRIFT',
    align: 'left',
    padding: 'none',
    label: 'Oppskrift'
  },
  {
    id: 'SOKEORD',
    align: 'left',
    padding: 'normal',
    label: 'Søkeord'
  }
];

interface MatvareSearchTabellProps {
  matvareData: MatvareResult[];
  changeMatvare?: (kode: number, navn?: string) => void;
}

export default function MatvareSearchTable(props: MatvareSearchTabellProps) {
  const { matvareData, changeMatvare } = props;
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof MatvareResult>('MATVAREKODEID');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [clicked, setClicked] = useState<number[]>([]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleLinkClick = (linkId) => {
    setClicked((prevClickedLinks) => {
      if (prevClickedLinks.includes(linkId)) {
        return prevClickedLinks.filter((id) => id !== linkId);
      } else {
        return [...prevClickedLinks, linkId];
      }
    });
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - matvareData.length) : 0;

  const visibleRows = useMemo(
    () =>
      matvareData
        ?.slice()
        .sort(getComparator(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [order, orderBy, page, rowsPerPage]
  );

  const handleRequestSort = (property: keyof MatvareResult) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <>
      <TableContainer component={Paper} sx={{ height: '40rem' }}>
        <Table size="small">
          <EnhancedTableHead
            headCells={headCells}
            leftColumns={[{ name: 'Velg', padding: 'checkbox' }]}
            rightColumns={[{ name: 'Kopi', align: 'right' }]}
            sorting={{
              order: order,
              orderBy: orderBy,
              onRequestSort: handleRequestSort,
              sortColumns: ['MATVAREKODEID', 'NAVN']
            }}
          />
          <TableBody>
            {visibleRows?.map((row) => (
              <TableRow key={row.MATVAREKODEID} hover>
                <TableCell
                  padding="checkbox"
                  onClick={() => changeMatvare(row.MATVAREKODEID, row.NAVN)}
                >
                  <Radio />
                </TableCell>
                {headCells.map((cell, index) =>
                  index < 2 ? (
                    <TableCell key={cell.id} padding={cell.padding} align={cell.align}>
                      <Link
                        style={{
                          color: clicked.includes(row.MATVAREKODEID) ? 'purple' : 'blue',
                          textDecoration: 'underline',
                          cursor: 'pointer'
                        }}
                        target="_blank"
                        href={'/home/matvaredata/matvare/' + row.MATVAREKODEID}
                        underline={'hover'}
                        onClick={() => handleLinkClick(row.MATVAREKODEID)}
                      >
                        {row[cell.id]}
                      </Link>
                    </TableCell>
                  ) : (cell.id === 'BESKRIVELSE' || cell.id === 'SOKEORD') &&
                    row[cell.id]?.toString().length > 20 ? (
                    <Tooltip key={cell.id} title={row[cell.id]} placement="left">
                      <TableCell
                        key={cell.id}
                        onClick={() =>
                          navigator.clipboard.writeText(row[cell.id].toString())
                        }
                        padding={cell.padding}
                        align={cell.align}
                        sx={{ cursor: 'pointer' }}
                      >
                        {`${row[cell.id].toString().slice(0, 20)}...`}
                      </TableCell>
                    </Tooltip>
                  ) : (
                    <TableCell key={cell.id} padding={cell.padding} align={cell.align}>
                      {row[cell.id]}
                    </TableCell>
                  )
                )}
                <TableCell
                  align="right"
                  sx={{ pb: 0, pt: 0.35 }}
                  onClick={() => console.log('todo: copy')}
                >
                  <ContentCopyIcon />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count={matvareData?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}
