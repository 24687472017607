const useStylesHeader = () => {
  return {
    Header: {
      background: '#1C5081',
      minHeight: '5rem'
    },
    HeaderLogin: {
      background: '#1C5081'
    },
    MenuName: {
      textTransform: 'capitalize',
      fontFamily: 'Arial',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '1rem',
      color: '#FFFFFF'
    },

    MatvareDatabase: {
      padding: '1px',
      textAlign: 'center',
      color: 'black',
      height: 60
    },
    MenuBar: {
      backgroundColor: '#4977AF'
    },
    Logout: {
      marginLeft: 'auto',
      paddingRight: 40
    },

    logo: {
      width: '5rem',
      height: '5rem'
    },
    selectBeregnMdb: {
      minWidth: 200,
      maxWidth: 400,
      width: 300,
      fontSize: '16px',
      height: 'auto',
      margin: '0 auto',
      padding: 10,
      position: 'relative'
    },
    mainText: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '26px',
      lineHeight: '30px',
      color: '#FFFFFF',
      verticalAlign: 'center'
    },
    subText: {
      alignContent: 'center',
      fontFamily: 'Arial',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '1.6vh',
      lineHeight: '1.6vh',
      color: '#FFFFFF',
      paddingBottom: '1.5em'
    },
    chip: {
      fontFamily: 'Arial',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '18px',
      lineHeight: '30px',
      color: '#FFFFFF',
      verticalAlign: 'center'
    }
  };
};
export default useStylesHeader;
