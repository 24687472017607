import { useTheme } from '@mui/material';

const useStylesButton = () => {
  const theme = useTheme();
  return {
    LoginButton: {
      background: '#1C5081',
      color: 'white',
      right: 210,
      '&:hover': {
        background: '#1C5118',
        variant: 'contained',
        opacity: '1'
      }
    }
  };
};
export default useStylesButton;
