import React, { useContext } from 'react';
import PrintIcon from '@mui/icons-material/Print';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack
} from '@mui/material';
import moment from 'moment';
import ReactToPrint from 'react-to-print';
import useStylesTable from '../../../css/TableCss';
import DeleteIcon from '@mui/icons-material/Delete';
import { Table, TableHead, TableRow, TableCell, TableBody, Grid } from '@mui/material';
import { StoffI, StoffgruppeI } from '../stoff/types/StoffTypes';
import { useUserContext } from '../../../context/AuthContext';
import { NButton } from '../components/Inputs';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { DatabaseContext } from '../../../context/DatabaseContext';
import { IsOwnedById } from '../../utilities/Validate';
enum Brutto {
  '1n' = 'Ja',
  '2n' = 'Nei'
}

interface StoffgruppIconsMenuProps {
  classes: any;
  deleteStoffgruppe: () => void;
  stoffGruppe: StoffgruppeI;
  stoffgruppeIdFromURL: number;
  toggleCopyStoffgruppe: () => void;
  stoffInStoffgruppe: StoffI[];
}
interface ConfirmDeletingStofgruppeProps {
  stoffGruppe: StoffgruppeI;
  deleteStoffgruppe: () => void;
  toggleDeletetConfirmationPopup: () => void;
  openSletteStoffgruppe: boolean;
}

const ConfirmDeletingStofgruppe = (props: ConfirmDeletingStofgruppeProps) => {
  const handleClickJa = () => {
    props.deleteStoffgruppe();
    props.toggleDeletetConfirmationPopup();
  };
  const handleClickNei = () => {
    props.toggleDeletetConfirmationPopup();
  };

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      open={props.openSletteStoffgruppe}
      onClose={props.toggleDeletetConfirmationPopup}
      aria-labelledby="sletteStoffgruppe"
    >
      <DialogTitle id="sletteStoffgruppe">Slett Stoffgruppe</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Vil du slette stoffgrupp navn: <b>{props.stoffGruppe?.navn}</b> stoffgruppeId:{' '}
          <b>{props.stoffGruppe?.stoffgruppeId}</b>?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClickJa} color="primary">
          Ja
        </Button>
        <Button onClick={handleClickNei} color="primary" autoFocus>
          Nei
        </Button>
      </DialogActions>
    </Dialog>
  );
};

interface ComponentToPrintProps {
  data: StoffgruppeI;
  database: string;
  currentUser: string;
  classesTable: any;
  ref: any;
  stoffInStoffgruppe: StoffI[];
}

const ComponentToPrint = React.forwardRef((props: ComponentToPrintProps, ref: any) => (
  <div ref={ref}>
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <b>Utskrift av skjermbildet: Stoffgrupper ({props.currentUser})</b>
      </Grid>

      <Grid item xs={12}>
        <label>
          Utskriftsdato:
          {moment().format('YYYY-MM-DD hh:mm:ss')}
        </label>
      </Grid>

      <Grid item xs={12}>
        <label>Matvaredatabase:</label>
        <b> {props.database}</b>
      </Grid>

      <Grid item xs={12}>
        <p>
          Nr: <b> {props.data?.stoffgruppeId}</b>
        </p>
        <p>
          Navn:<b> {props.data?.navn}</b>
        </p>
        <p>
          Beskrivelse:<b> {props.data?.beskrivelse}</b>
        </p>
      </Grid>
      <Grid item xs={12}>
        <>
          <h5>Stoffer:</h5>
          <Table className="DialogTable">
            <TableHead style={{ backgroundColor: '#4977AF' }}>
              <TableRow>
                <TableCell style={{ color: 'white' }}>Stoffnr</TableCell>
                <TableCell style={{ color: 'white' }}>Kortnavn</TableCell>
                <TableCell style={{ color: 'white' }}>Navn</TableCell>
                <TableCell style={{ color: 'white' }}>Ragnbart</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.stoffInStoffgruppe?.map((row: StoffI, i: number) => (
                <TableRow key={`${row.navn}-${i}`}>
                  <TableCell align="center" padding="none">
                    {row.stoffnrId}
                  </TableCell>
                  <TableCell align="center" padding="none">
                    {row.kortnavn}
                  </TableCell>
                  <TableCell align="center" padding="none">
                    {row.navn}
                  </TableCell>
                  <TableCell align="center" padding="none">
                    {row.regnbart}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </>
      </Grid>
    </Grid>
  </div>
));

const StoffgruppIconsMenu = (props: StoffgruppIconsMenuProps) => {
  const componentRef = React.useRef();
  const classesTable = useStylesTable();
  const [openSletteStoffgruppe, setOpenSletteStoffgruppe] = React.useState(false);
  const loggedInuser = useUserContext();
  const { currentDatabase } = useContext(DatabaseContext);
  const toggleDeletetConfirmationPopup = () => {
    setOpenSletteStoffgruppe(!openSletteStoffgruppe);
  };
  return (
    <Stack direction="row-reverse" spacing={2}>
      <ReactToPrint
        trigger={() => (
          <NButton
            children="Skriv ut"
            rightIcon={<PrintOutlinedIcon />}
            variant="text"
            disabled={!(props.stoffgruppeIdFromURL > 0)}
          />
        )}
        pageStyle=".styledTable{width:100%;border-collapse:collapse;text-align:left}.styledTable th,.styledTable td{border:2px solid black;padding:0.5em;}"
        content={() => componentRef.current}
      />
      <Box sx={{ display: 'none' }}>
        <ComponentToPrint
          data={props.stoffGruppe}
          database={currentDatabase?.label}
          currentUser={loggedInuser?.user?.username}
          classesTable={classesTable}
          ref={componentRef}
          stoffInStoffgruppe={props.stoffInStoffgruppe}
        />
      </Box>
      <NButton
        children="Kopier"
        rightIcon={<ContentCopyOutlinedIcon />}
        variant="text"
        onClick={props.toggleCopyStoffgruppe}
        disabled={!(props.stoffgruppeIdFromURL > 0)}
      />
      <NButton
        children="Slett"
        rightIcon={<DeleteOutlineOutlinedIcon />}
        variant={'text'}
        disabled={
          !IsOwnedById({ tilgangskode: props.stoffGruppe?.tilgangskode, u: loggedInuser })
        }
        onClick={toggleDeletetConfirmationPopup}
      />
      {openSletteStoffgruppe && (
        <ConfirmDeletingStofgruppe
          deleteStoffgruppe={props.deleteStoffgruppe}
          stoffGruppe={props.stoffGruppe}
          openSletteStoffgruppe={openSletteStoffgruppe}
          toggleDeletetConfirmationPopup={toggleDeletetConfirmationPopup}
        />
      )}
    </Stack>
  );
};
export default StoffgruppIconsMenu;
