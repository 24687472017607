import ReactDOM from 'react-dom';
import React from 'react';
import { Box, Grid, IconButton, InputLabel } from '@mui/material';
import useFetchData from '../../../utilities/useFetchData';
import { ProsjektI } from '../../../../types/prosjektTypes';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../../../types/actions';
import { DialogBox } from '../../../utilities/DialogBox';
import useStylesTable from '../../../../css/TableCss';
import useStyles from '../../../../css/materialtheme';
import PrintIcon from '@mui/icons-material/Print';
import { UndersokelseKortnavnList } from './UndersokelseKortnavnList';
import { UndersokelseIdList } from './UndersokelseIdList';
import AlertComp from '../../../utilities/AlertComp';
import { AlertI } from '../../../../types/alertTypes';

export const UndersokelseList = (props: any) => {
  const [searchUndersokelse, setSearchUndersokelse] = React.useState('');
  const [alertMessage, setAlertMessage] = React.useState('');
  const [alertSeverity, setAlertSeverity] = React.useState<AlertI['alertColor']>('info');
  const [showAlert, setShowAlert] = React.useState(false);
  const prosjektState = useSelector((state: ProsjektI) => state['prosjekts']);
  const { prosjektId } = prosjektState;
  const newProskjektId = props.projectIdFromURL || prosjektId;
  const { searchFeild, showUndersokelseIdList, isShowing, hide, populateUndersokelse } =
    props;
  const subProjectList = useFetchData({
    loadOnMount: false,
    clearDataOnLoad: false,
    projectURL: 'ListhentUndersokelser',
    subProjectURL: newProskjektId
  });
  const dispatch = useDispatch();
  const classesTable = useStylesTable();
  const classes = useStyles();
  React.useEffect(() => {
    if (subProjectList.error === true) {
      console.log('We have an error' + subProjectList.errorMessage);
      showErrorAlert();
    }
  }, [subProjectList.error]);

  const populateData = (undersokelseId: number, uKortNavn: string) => {
    dispatch({
      type: actions.SET_UNDERSOKELSE_ID,
      undersokelseId: undersokelseId
    });
    dispatch({ type: actions.SET_UKORT_NAVN, uKortNavn: uKortNavn });
    populateUndersokelse(undersokelseId, newProskjektId);
    handleCloseUndersokelseList();
  };

  React.useEffect(() => {
    loadUndersokelser(newProskjektId);
  }, [newProskjektId]);

  const loadUndersokelser = (project: number) => {
    if (project) {
      subProjectList.loadData(`ListhentUndersokelser`, `${project}`);
    }
  };

  const handleCloseUndersokelseList = () => {
    hide();
  };
  const showErrorAlert = () => {
    setAlertSeverity('error');
    setAlertMessage('System error! Please contact technical administrator. ');
    setShowAlert(true);
  };
  const noNavigation = () => {};
  return { isShowing }
    ? ReactDOM.createPortal(
        <div>
          {showAlert && (
            <AlertComp
              alertMessage={alertMessage}
              alertColor={alertSeverity}
              showAlert={showAlert}
              setShowAlert={setShowAlert}
            />
          )}
          <DialogBox
            {...props}
            title={'Undersøkelser'}
            hide={handleCloseUndersokelseList}
          >
            <div>
              <Box sx={classes.IconBoxContainer}>
                <Grid item sx={classes.obligatoriskField}>
                  <InputLabel sx={classes.InputLabel}>Søk etter {searchFeild}</InputLabel>
                  <input
                    type="text"
                    placeholder="Søk"
                    onChange={(event) => setSearchUndersokelse(event.target.value)}
                  />
                </Grid>
                <span>
                  <IconButton aria-label="print" disabled={true} color="primary">
                    <Box sx={classes.Icons}>Skriv ut</Box>
                    <PrintIcon />
                  </IconButton>
                </span>
              </Box>
              <div>
                {showUndersokelseIdList ? (
                  <UndersokelseIdList
                    classesTable={classesTable}
                    populateData={populateData}
                    searchUndersokelseId={searchUndersokelse}
                    subProjectList={subProjectList}
                    navigateToUndersokelse={props.navigateToUndersokelse || noNavigation}
                  />
                ) : (
                  <UndersokelseKortnavnList
                    classesTable={classesTable}
                    populateData={populateData}
                    searchUndersokelseKortnavn={searchUndersokelse}
                    subProjectList={subProjectList}
                    navigateToUndersokelse={props.navigateToUndersokelse || noNavigation}
                  />
                )}
              </div>
            </div>
          </DialogBox>
        </div>,
        document.body
      )
    : null;
};
