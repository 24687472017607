import React, { useEffect, useState } from 'react';
import {
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  Typography,
  Grid,
  Stack,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { CodeRegisterI, OptionType } from '../components/commons/CommonTypes';
import PageContainer from '../home/PageContainer';
import { NButton, SlowText } from '../components/Inputs';
import useFetchData from '../../utilities/useFetchData';
import { AlertI } from '../../../types/alertTypes';
import AlertComp from '../../utilities/AlertComp';
import { ProgressBar } from '../../utilities/ProgressBar';
import { useKeycloak } from '@react-keycloak/web';
import { OptionI } from '../beregning/types/BeregningTypes';
import useStylesTable from '../../../css/TableCss';
import DeleteDialog from '../kosthold/components/DeleteDialog';
import { codeTypes } from '../../../assets/kodeOption';

export default function Koderegister() {
  const { keycloak } = useKeycloak();
  const token = keycloak?.token || 'Mangler tilgang';
  const [title] = React.useState('Koderegister - NutriFoodCalc');
  document.title = title;
  const classesTable = useStylesTable();
  const [selectedCode, setSelectedCode] = useState<OptionType>();
  const [registers, setRegisters] = useState<CodeRegisterI[]>([]);
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = React.useState<string>('');
  const [newNavn, setNewNavn] = React.useState<string>('');
  const [currentRegisters, setCurrentRegisters] = React.useState<CodeRegisterI[]>([]);
  const [newRegisters, setNewRegisters] = React.useState<CodeRegisterI[]>([]);
  const [lagreEnabled, setLagreEnabled] = useState<boolean>(false);
  const [alertSeverity, setAlertSeverity] = React.useState<AlertI['alertColor']>('info');
  const apiCall = (uri: string, method: string, body?: any) => {
    const fullUri = `${process.env.REACT_APP_BACKEND}/${uri}`;
    return fetch(encodeURI(fullUri), {
      method: method,
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      body: body ? JSON.stringify(body) : undefined
    });
  };
  const codes = useFetchData({
    loadOnMount: true,
    clearDataOnLoad: false,
    projectURL: 'getKodeRegister',
    subProjectURL: null
  });

  useEffect(() => {
    if (codes.data) {
      const data: CodeRegisterI[] = codes.data as CodeRegisterI[];
      setRegisters(data);
      setCurrentRegisters(data?.filter((f) => f.value === (selectedCode?.value || 1)));
    }
  }, [codes.data]);
  const addNewCode = () => {
    const newCode: CodeRegisterI = {
      nr: currentRegisters[currentRegisters?.length - 1]?.nr + 1,
      label: newNavn,
      value: selectedCode?.value || codeTypes[0].value
    };
    setCurrentRegisters(() => [...currentRegisters, newCode]);
    setNewRegisters(() => [...newRegisters, newCode]);
    setNewNavn('');
    setLagreEnabled(true);
  };

  const save = async () => {
    setShowProgress(true);
    var failed = 0;
    if (!failed) {
      const response = await apiCall('saveKoderegister', 'POST', { data: newRegisters });

      if (!response.ok) {
        failed += 1;
      }
    }
    if (!failed) {
      showAlertComponent('Lagringen er fullført', 'success');
    } else {
      showAlertComponent(`Lagringen er ikke fullført`, 'error');
    }
    setShowProgress(false);
  };
  const handleDelete = async (register: CodeRegisterI) => {
    setShowProgress(true);
    var failed = 0;
    let message: Promise<string>;
    if (!failed) {
      const response = await apiCall(
        `deleteKoderegister/${register?.value}/${register?.nr}`,
        'DELETE',
        { data: [] }
      );
      if (!response.ok) {
        failed += 1;
        message = await response.json();
      }
    }
    if (!failed) {
      showAlertComponent('Slettingen er fullført', 'success');
      codes.loadData('getKodeRegister', null);
    } else {
      showAlertComponent(`${message || 'Slettingen er ikke fullført.'}`, 'error');
    }
    setShowProgress(false);
  };
  const showAlertComponent = (message: string, severity: any) => {
    setShowAlert(true);
    setAlertMessage(message);
    setAlertSeverity(severity);
  };
  const handleCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedCode(codeTypes?.find((c: OptionI) => c.value === +event.target.value));
    setCurrentRegisters(registers?.filter((f) => f.value === +event.target.value));
    setNewNavn('');
  };
  return (
    <PageContainer>
      <Paper style={{ padding: '1.5em 15px 1.5em 15px', boxShadow: 'none' }}>
        <Typography variant="h4">Koderegister</Typography>
        <Grid item xs={12} sx={{ pt: 2 }} container spacing={2} justifyItems="flex-start">
          <Grid item xs={6}>
            <FormControl>
              <FormLabel id="koderegister-radio-buttons-group">Typer</FormLabel>
              <RadioGroup
                aria-labelledby="koderegister-buttons-group"
                name="koderegister-controlled-radio-buttons-group"
                defaultValue={1}
                value={selectedCode}
                onChange={handleCodeChange}
              >
                {codeTypes?.map((c: OptionType) => {
                  return (
                    <FormControlLabel
                      value={c.value}
                      control={<Radio />}
                      label={c.label}
                    />
                  );
                })}
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <Table size="small" sx={{ border: '1px solid black' }}>
              <TableHead sx={classesTable.head}>
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold', height: '3em' }}>Nr</TableCell>
                  <TableCell sx={{ fontWeight: 'bold', height: '3em' }}>Navn</TableCell>
                  <TableCell sx={{ fontWeight: 'bold', height: '3em' }}>Slett</TableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={classesTable.body}>
                {currentRegisters?.map((row) => (
                  <TableRow key={row.nr}>
                    <TableCell>{row.nr}</TableCell>
                    <TableCell>{row.label}</TableCell>
                    <TableCell>
                      <DeleteDialog
                        title="Slett kode"
                        message="Vil du slette denne koden?"
                        deleteFn={() => handleDelete(row)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow
                  key={currentRegisters[currentRegisters?.length - 1]?.nr + 1 || 1}
                >
                  <TableCell>
                    {currentRegisters[currentRegisters?.length - 1]?.nr + 1 || 1}
                  </TableCell>
                  <TableCell>
                    <SlowText
                      header=""
                      value={newNavn}
                      onChange={(e) => setNewNavn(e.target.value)}
                      fullWidth
                    />
                  </TableCell>
                  <TableCell>
                    <NButton
                      children="Legg til"
                      rightIcon={<AddCircleOutlineIcon />}
                      variant="text"
                      disabled={newNavn === ''}
                      onClick={() => addNewCode()}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        </Grid>
        <Grid item xs={12} container spacing={2} alignItems="flex-end">
          <Grid item xs={12}>
            <Stack direction="row" spacing={1} justifyContent={'flex-end'}>
              <NButton children="Lagre" onClick={() => save()} disabled={!lagreEnabled} />
            </Stack>
          </Grid>
        </Grid>
        {showAlert && (
          <AlertComp
            alertMessage={alertMessage}
            alertColor={alertSeverity}
            showAlert={showAlert}
            setShowAlert={setShowAlert}
          />
        )}
        {showProgress && (
          <ProgressBar
            isShowing={showProgress}
            hide={() => {
              setShowProgress(!showProgress);
            }}
          />
        )}
      </Paper>
    </PageContainer>
  );
}
