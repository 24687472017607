import { MatvareI } from '../types/matvareTypes';
import {
  SET_KODE_FROM,
  SET_MATVARE_NAVN,
  SET_STATUS,
  SET_INGREDIENS_ID,
  SET_RESULTAT_GRUPPE_TYPE,
  SET_RESULTAT_GRUPPER,
  SET_NODE_NR_ID,
  SET_SELECTED_MATVARER,
  SET_SELECTED_MATVAREGRUPPER,
  MatvareActionTypes
} from '../types/actions';

const matvareReducerDefaultState: MatvareI = {
  kodeFrom: 0,
  matvareNavn: null,
  status: -1,
  ingrediensId: 0,
  nodeNrId: 0,
  resultatgruppeType: { gruppeTypeId: 0, beskrivelse: '' },
  resultatgrupper: [{ gruppeTypeId: 0, beskrivelse: '' }],
  selectedMatvarer: [{ matvarekode: 0, navn: '' }],
  selectedMatvaregrupper: [{ nodenrid: 0, kortnavn: '' }]
};

export const matvareReducer = (
  state = matvareReducerDefaultState,
  action: MatvareActionTypes
): MatvareI => {
  switch (action.type) {
    case SET_KODE_FROM:
      return { ...state, kodeFrom: action.kodeFrom };
    case SET_MATVARE_NAVN:
      return { ...state, matvareNavn: action.matvareNavn };
    case SET_STATUS:
      return { ...state, status: action.status };
    case SET_INGREDIENS_ID:
      return { ...state, ingrediensId: action.ingrediensId };
    case SET_RESULTAT_GRUPPE_TYPE:
      return { ...state, resultatgruppeType: action.resultatgruppeType };
    case SET_NODE_NR_ID:
      return { ...state, nodeNrId: action.nodeNrId };
    case SET_RESULTAT_GRUPPER:
      return { ...state, resultatgrupper: action.resultatgrupper };
    case SET_SELECTED_MATVARER:
      return { ...state, selectedMatvarer: action.selectedMatvarer };
    case SET_SELECTED_MATVAREGRUPPER:
      return { ...state, selectedMatvaregrupper: action.selectedMatvaregrupper };
    default:
      return state;
  }
};
