import React, { useContext, useEffect, useState } from 'react';
import { Grid, Paper, Stack, Typography } from '@mui/material';
import { databaseOptions } from '../../../assets/DatabaseOptions';
import { OptionType } from '../components/commons/CommonTypes';
import PageContainer from '../home/PageContainer';
import { Dropdown, NButton } from '../components/Inputs';
import useFetchData from '../../utilities/useFetchData';
import { DatabaseContext, useDatabaseContext } from '../../../context/DatabaseContext';
import { StoffGruppeI, StoffGrupper } from '../matvare/types/StoffTypes';
import { DefaultId } from './AdminTypes';
import { AlertI } from '../../../types/alertTypes';
import AlertComp from '../../utilities/AlertComp';
import { ProgressBar } from '../../utilities/ProgressBar';
import { useKeycloak } from '@react-keycloak/web';

export default function Defaultverdier() {
  const { keycloak } = useKeycloak();
  const token = keycloak?.token || 'Mangler tilgang';
  const [title] = React.useState('Defaultverdier - NutriFoodCalc');
  document.title = title;
  const db = useDatabaseContext();
  const dbOptions: OptionType[] = databaseOptions;
  const [mainDatabase, setMainDatabase] = useState<OptionType>({ value: 0, label: '' });
  const [stoffDatabase, setStoffDatabase] = useState<OptionType>({ value: 0, label: '' });
  const [stoffgruppe, setStoffgruppe] = useState<StoffGruppeI>({
    stoffgruppeId: -1,
    navn: ''
  });
  const [stoffGroupList, setStoffGroupList] = useState<StoffGruppeI[]>([]);
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = React.useState<string>('');
  const [alertSeverity, setAlertSeverity] = React.useState<AlertI['alertColor']>('info');
  const [postDbUrl] = React.useState<string>('saveDefaultDatabase');
  const [postSgUrl, setPostSgUrl] = React.useState<string>(
    `saveDefaultStoffgruppe/${stoffDatabase?.value}`
  );
  const apiCall = (uri: string, method: string, body?: any) => {
    const fullUri = `${process.env.REACT_APP_BACKEND}/${uri}`;

    return fetch(encodeURI(fullUri), {
      method: method,
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      body: body ? JSON.stringify(body) : undefined
    });
  };
  const databaseDatabase = useFetchData({
    loadOnMount: true,
    clearDataOnLoad: false,
    projectURL: 'getDefaultDatabase',
    subProjectURL: null
  });
  const stoffgrupper = useFetchData({
    loadOnMount: false,
    clearDataOnLoad: false,
    projectURL: 'getStoffgrupper',
    subProjectURL: `${db.currentDatabase?.value}`
  });
  useEffect(() => {
    if (databaseDatabase.data) {
      const id: DefaultId = databaseDatabase.data as unknown as DefaultId;
      setMainDatabase(
        databaseOptions?.find((d) => d.value === id.ID) || db.currentDatabase
      );
    }
  }, [databaseDatabase.data]);
  useEffect(() => {
    if (stoffgrupper.data) {
      const data: StoffGrupper = stoffgrupper.data as unknown as StoffGrupper;
      setStoffGroupList(data?.stoffgruppe);
      setStoffgruppe(data?.stoffgruppe?.find((s) => s.stoffgruppeId === data?.defaultId));
    }
  }, [stoffgrupper.data]);

  useEffect(() => {
    if (stoffDatabase !== undefined && stoffDatabase?.value > 0) {
      stoffgrupper.loadData('getStoffgrupper', `${stoffDatabase?.value}`);
    }
  }, [stoffDatabase]);

  const handleStoffDatabaseChange = (selected: OptionType) => {
    setStoffDatabase(selected);
    setPostSgUrl(`saveDefaultStoffgruppe/${selected?.value}`);
  };
  const save = async (url: string, data: number, isDatabase: boolean) => {
    setShowProgress(true);
    const response = await apiCall(url, 'POST', { data: data });
    if (response.ok) {
      if (isDatabase) {
        db.setCurrentDatabase(databaseOptions?.find((d) => d.value === data));
      }
      showAlertComponent('Lagringen er fullført', 'success');
    } else {
      showAlertComponent(`Lagringen er ikke fullført`, 'error');
    }
    setShowProgress(false);
  };
  const showAlertComponent = (message: string, severity: any) => {
    setShowAlert(true);
    setAlertMessage(message);
    setAlertSeverity(severity);
  };
  return (
    <PageContainer>
      <Paper style={{ padding: '1.5em 15px 1.5em 15px', boxShadow: 'none' }}>
        <Typography variant="h4">Defaultverdier for bruker</Typography>
        <Grid item xs={12} sx={{ pt: 2 }} container spacing={2} alignItems="center">
          <Grid item xs={3}>
            <Typography>{' Default matvaredatabase: '}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Dropdown
              header=""
              defaultValue={db.currentDatabase}
              value={mainDatabase}
              options={dbOptions}
              optionLabel={(o) => o.label}
              onChange={(v) => setMainDatabase(v as OptionType)}
            />
          </Grid>
          <Grid item xs={4}>
            <Stack direction="row" spacing={1}>
              <NButton
                children="Lagre"
                disabled={mainDatabase?.value === undefined}
                onClick={() => save(postDbUrl, mainDatabase?.value, true)}
              />
            </Stack>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ pt: 4 }} container spacing={2} alignItems="flex-end">
          <Grid item xs={12} spacing={2} alignItems="flex-end">
            <Typography variant="h6">
              {'Default stoffgruppe for matvaredatabase'}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography>{' Matvaredatabase: '}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Dropdown
              header=""
              value={stoffDatabase || db.currentDatabase}
              options={dbOptions}
              optionLabel={(o) => o.label}
              onChange={(v) => handleStoffDatabaseChange(v as OptionType)}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} container spacing={2} alignItems="flex-end">
          <Grid item xs={3}>
            <Typography>{' Stoffgruppe: '}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Dropdown
              header=""
              value={stoffgruppe}
              options={stoffGroupList || []}
              optionLabel={(o) => o.navn}
              onChange={(v) => setStoffgruppe(v as StoffGruppeI)}
            />
          </Grid>
          <Grid item xs={4}>
            <Stack direction="row" spacing={1}>
              <NButton
                children="Lagre"
                onClick={() => save(postSgUrl, stoffgruppe?.stoffgruppeId, false)}
                disabled={
                  !(
                    stoffDatabase?.value !== undefined &&
                    stoffgruppe?.stoffgruppeId !== undefined
                  )
                }
              />
            </Stack>
          </Grid>
        </Grid>
        {showAlert && (
          <AlertComp
            alertMessage={alertMessage}
            alertColor={alertSeverity}
            showAlert={showAlert}
            setShowAlert={setShowAlert}
          />
        )}
        {showProgress && (
          <ProgressBar
            isShowing={showProgress}
            hide={() => {
              setShowProgress(!showProgress);
            }}
          />
        )}
      </Paper>
    </PageContainer>
  );
}
