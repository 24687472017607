import React from 'react';
import {
  keycloakDev4,
  /* keycloakDev,
  keycloakTest, */
  keycloakTest4,
  keycloakProd
} from '../keycloak';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { AppRouter } from './route/AppRouter';

export function App() {
  const kcInitiOptions = {
    onLoad: 'check-sso',
    checkLoginIframe: false
  };

  let keycloak = keycloakProd;
  console.log('environment ', process.env.REACT_APP_ENVIRONMENT);

  if (process.env.REACT_APP_ENVIRONMENT === 'DEV') {
    keycloak = keycloakDev4;
  }
  if (process.env.REACT_APP_ENVIRONMENT === 'TEST') {
    keycloak = keycloakTest4;
  }
  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={kcInitiOptions}
      /* onEvent={(event: any, error: any) => {
        if (event === 'onAuthSuccess') {
          getUsername(keycloak);
        } else if (event === 'onAuthLogout') {
          localStorage.clear();
        }
      }} */
      /*  onTokens={(tokens: any) => {}} */
    >
      <AppRouter />
    </ReactKeycloakProvider>
  );
}
