import React, { useEffect, useState, MouseEvent, ChangeEvent } from 'react';
import {
  Paper,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import { MonoSearchField } from '../../../components/Inputs';
import useFetchData from '../../../../utilities/useFetchData';
import { PersonSearchI } from '../../types/PersonTypes';
import { RouteComponentProps } from 'react-router-dom';
import DialogGeneral from '../../../../utilities/DialogGeneral';
import { ProsjektI } from '../../types/ProjectTypes';
import { ApiCall } from '../../../../utilities/ApiCall';
import { useKeycloak } from '@react-keycloak/web';

interface RouteParams {
  prosjektId: string;
  undersokelseId: string;
  undersokelseKortnavn: string;
}

interface PersonSearchProps extends RouteComponentProps<RouteParams> {
  open: boolean;
  hide: () => void;
  setPerson: (id: number) => void;
  projectObject: ProsjektI;
}

export default function PersonSearchModal(props: PersonSearchProps) {
  const { setPerson, projectObject, open, hide } = props;
  const { prosjektId, undersokelseId, undersokelseKortnavn } = props.match.params;
  const [loading, setLoading] = useState<boolean>(true);
  const [reload, setReload] = useState<boolean>(true);
  const [personer, setPersoner] = useState<PersonSearchI[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { keycloak } = useKeycloak();

  const translateHeader = {
    personId: 'PersonID',
    identitet: 'Identitet',
    personStatus: 'Personstatus',
    kjonn: 'Kjønn'
  };
  const columnsOrder = Object.keys(translateHeader);

  const getPersoner = useFetchData({
    loadOnMount: true,
    clearDataOnLoad: false,
    projectURL: 'hentPersoner',
    subProjectURL: `${+prosjektId}/${+undersokelseId}/1/2/0/N/0/N/0/N/0/0/0/J`
  });

  useEffect(() => {
    if (!prosjektId) return;
    getPersoner.loadData(
      'hentPersoner',
      `${+prosjektId}/${+undersokelseId}/1/2/0/N/0/N/0/N/0/0/0/J`
    );
  }, [prosjektId]);

  useEffect(() => {
    if (!getPersoner.data) return;
    const data = getPersoner.data;
    setPersoner(data);
    setReload(false);
    setLoading(false);
  }, [getPersoner.data, reload]);

  const searchWithId = (no?: string) => {
    if (no) {
      setPersoner(personer?.filter((i) => i.personId?.toString()?.includes(no)));
      setPage(0);
    } else {
      setReload(true);
    }
  };

  const searchWithIdentity = (name: string) => {
    if (name) {
      setPersoner(
        personer?.filter((j) => j.identitet?.toLowerCase()?.includes(name?.toLowerCase()))
      );
      setPage(0);
    } else {
      setReload(true);
    }
  };

  // handling
  const changePerson = (personId: number) => {
    setPerson(personId);
    hide();
  };

  const handleChangePage = (
    event: MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <DialogGeneral
      title="Liste over personer"
      open={open}
      hide={hide}
      fullWidth
      maxWidth={'md'}
    >
      {/* <Grid container spacing={2}> */}
      <Stack spacing={2}>
        <Stack direction={'row'} spacing={2}>
          {projectObject && (
            <Typography variant="h6">
              Prosjekt: {projectObject?.prosjektnavn} - {prosjektId}
            </Typography>
          )}
          <Typography variant="h6">Undersøkelse: {undersokelseKortnavn}</Typography>
        </Stack>
        <Stack direction={'row'} spacing={2} flexBasis={'100%'} alignItems={'end'}>
          <MonoSearchField
            header="Søk etter personID"
            searchFn={(n) => searchWithId(n)}
          />
          <MonoSearchField
            header="Søk etter identitet"
            searchFn={(n) => searchWithIdentity(n)}
          />
          {/* <Typography marginLeft={'auto'}>
            {'Antall personer: '}
            {personer?.length - 1}
          </Typography> */}
        </Stack>
        {/* <Grid item xs={12}> */}
        {!loading || personer.length < 1 ? (
          <>
            <TableContainer component={Paper} sx={{ maxHeight: '55vh' }}>
              <Table stickyHeader size="small">
                <TableHead>
                  <TableRow>
                    {columnsOrder.map((columnKey) => (
                      <TableCell key={columnKey}>{translateHeader[columnKey]}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody sx={{ overflowY: 'scroll' }}>
                  {personer &&
                    personer
                      ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row) => (
                        <TableRow key={row.personId} hover>
                          {columnsOrder.map((columnKey, i) => (
                            <TableCell key={columnKey}>
                              {i < 2 ? (
                                <a
                                  style={{
                                    color: 'blue',
                                    textDecoration: 'underline',
                                    cursor: 'pointer'
                                  }}
                                  onClick={() => changePerson(row.personId)}
                                  children={row[columnKey]}
                                />
                              ) : (
                                row[columnKey]
                              )}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              //sx={classesTable.Pagination}
              component="div"
              count={personer?.length - 1}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              // showFirstButton={true}
              // showLastButton={true}
            />
          </>
        ) : (
          <Skeleton variant="rectangular" width="90vw" height="10em" />
        )}
      </Stack>
      {/* </Grid> */}
      {/* </Grid> */}
    </DialogGeneral>
  );
}
