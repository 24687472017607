import React, { useEffect, useState } from 'react';
import {
  InputLabel,
  MenuItem,
  Select,
  Grid,
  Divider,
  TextField,
  SelectChangeEvent,
  Stack,
  RadioGroup,
  FormControlLabel,
  Radio,
  Paper
} from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import useStyles from '../../BeregningCss';
import { DialogBox } from '../../../../utilities/DialogBox';
import {
  arrayInputValuesI,
  BeregnFilterValuesI,
  CodeRegisterI,
  filterOptionI,
  filterQueryDataI,
  OptionI,
  selectedOptionArrayI,
  selectedOptionI,
  selectedVariableAndSvarsI,
  TillegsvariableI,
  TillegsvariableSvarI,
  variabelAndSvarsI
} from '../../types/BeregningTypes';
import {
  Checkbox,
  FormControl,
  FormHelperText,
  IconButton,
  ListItemText,
  OutlinedInput
} from '@mui/material';
import {
  operators,
  personFilterData
} from '../../../../../assets/BeregningFilterOptions';
import { DisplayActivatedOptions } from './SelectedFilterDisplay';
import { NButton } from '../../../components/Inputs';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
interface personfilterProps {
  beregnFilterValues: BeregnFilterValuesI;
  setFilterParams: (value: filterQueryDataI[]) => void;
  persons: number;
  showPersonfilter: boolean;
  showAlertMessage: (msg: string, severity: any) => void;
  activatedOptions: selectedOptionArrayI[];
  setActivatedOptions: (value: selectedOptionArrayI[]) => void;
  displayActivatedOptions: (value: selectedOptionArrayI) => void;
  personFilterCriteria: string[];
  setPersonFilterCriteria: (value: string[]) => void;
  logicalOperator: string;
  setLogicalOperator: (value: string) => void;
  updateBeregningObject: (item: string, name: string, value: any) => void;
  hide: (value: boolean) => void;
}
const emptyOption: OptionI[] = [];
const emptyValues: string[] = [];
const initialOption = {
  columnName: '',
  label: '',
  kodeId: 0,
  hasPredefinedValue: false,
  predefinedValues: emptyOption,
  svars: emptyOption
};
const initialSelectedOption = {
  selectedUtvalg: initialOption,
  selectedOperatorOne: { value: 0, label: '' },
  selectedOperatorTwo: { value: 0, label: '' },
  nonCategoryValues: { parameter: '', values: emptyValues },
  categoryValues: emptyOption,
  variableSvarArray: {
    variabel: { value: 0, label: '' },
    svars: emptyOption
  }
};
export const PersonFilter = (props: personfilterProps) => {
  const {
    beregnFilterValues,
    setFilterParams,
    persons,
    showPersonfilter,
    showAlertMessage,
    activatedOptions,
    setActivatedOptions,
    displayActivatedOptions,
    personFilterCriteria,
    setPersonFilterCriteria,
    logicalOperator,
    setLogicalOperator,
    updateBeregningObject,
    hide
  } = props;
  const classes = useStyles();
  const personfilterOptions: filterOptionI[] = personFilterData;
  const [activatedOption, setActivatedOption] =
    useState<selectedOptionI>(initialSelectedOption);
  const [selectedFilterOptions, setSelectedFilterOptions] =
    useState<filterOptionI>(initialOption);
  const [selectedFilter, setSelectedFilter] = useState<filterOptionI>(initialOption);
  const [variabelSvarSet, setVariableSvarSet] = useState<TillegsvariableI[]>([]);
  const [svars, setSvars] = useState<TillegsvariableSvarI[]>([]);
  const [selectedVariabel, setSelectedVariabel] = useState<OptionI>({
    value: 0,
    label: ''
  });
  const [textValue, setTextValue] = useState('');
  const [firstNumericValue, setFirstNumericValue] = useState<number>(0);
  const [secondNumericValue, setSecondNumericValue] = useState<number>(0);
  const [showKodeRegister, setShowKodeRegister] = useState<boolean>(false);
  const [personFilterForm, setPersonFilterForm] =
    useState<filterOptionI[]>(personfilterOptions);
  const [customizedOperator, setCustomizedOperator] = useState<OptionI[]>(operators);
  const [firstOperator, setFirstOperator] = useState<OptionI>();
  const [secondOperator, setSecondOperator] = useState<OptionI>();
  const [selectedCategoryValues, setSelectedCategoryValues] = useState<string[]>([]);
  const [selectedSvars, setSelectedSvars] = useState<string[]>([]);
  const [isText, setIsText] = useState(false);
  const [multipleValue, setMultipleValue] = useState(false);
  const [enableActivate, setEnableActivate] = useState(false);
  const [showSecondTextBox, setShowSecondTextBox] = useState(false);
  const [showSvarBox, setShowSvarBox] = useState(false);
  const [categoricalSvar, setCategoricalSvar] = useState(false);
  const [typedSvar, setTypedSvar] = useState(0);
  const [variabelError, setVariabelError] = useState('');

  useEffect(() => {
    if (beregnFilterValues?.CODEREGISTERS?.length > 0) {
      getPredefinedValues();
    }
  }, [beregnFilterValues]);

  useEffect(() => {
    const selectedUtvalg = activatedOption?.selectedUtvalg;
    if (!selectedUtvalg) {
      setEnableActivate(false);
    } else {
      const { hasPredefinedValue, columnName } = selectedUtvalg || {};
      const nonCategoryValues = activatedOption?.nonCategoryValues;
      const shouldDisable =
        (hasPredefinedValue && activatedOption?.categoryValues === undefined) ||
        (columnName === 'T.SVAR' &&
          !(activatedOption?.variableSvarArray?.svars?.length ?? 0)) ||
        (!hasPredefinedValue && nonCategoryValues === undefined) ||
        (!hasPredefinedValue && !nonCategoryValues?.values[0]) ||
        (!hasPredefinedValue &&
          showSecondTextBox &&
          (!nonCategoryValues?.values[1] || nonCategoryValues?.values[1] === ''));
      setEnableActivate(!shouldDisable);
    }
  }, [activatedOption]);

  useEffect(() => {
    setShowSecondTextBox(false);
    setMultipleValue(false);
  }, [activatedOption?.selectedUtvalg]);

  const getPredefinedValues = () => {
    const newPersonFilterForm: filterOptionI[] = personFilterForm?.map(
      (pf: filterOptionI) => {
        if (pf.kodeId !== 0) {
          const fValues = beregnFilterValues?.CODEREGISTERS?.filter(
            (code: CodeRegisterI) => code.value === pf.kodeId
          );
          console.log('navns: ', fValues, pf.kodeId);
          pf.predefinedValues = fValues?.map((n: CodeRegisterI) => {
            return {
              value: n.nr,
              label: n.label
            };
          });
        }
        if (pf.label === 'TILLEGGSVARIABEL') {
          const newTillegsvariable = beregnFilterValues?.TILLEGGSVARIABEL?.map(
            (variabel: TillegsvariableI) => {
              const variableNameAndId = {
                value: variabel.VARIABELID,
                label: variabel.NAVN?.split('-')[1]
              };
              const variableSvar: TillegsvariableI = {
                NAVN: variabel.NAVN?.split('-')[1],
                VARIABELID: variabel.VARIABELID,
                TILLEGGSVARIABELSVAR: []
              };
              if (variabel?.TILLEGGSVARIABELSVAR?.length > 0) {
                const newTillegsvariableSvar = variabel?.TILLEGGSVARIABELSVAR?.map(
                  (svar: TillegsvariableSvarI) => {
                    return {
                      KODE: svar.KODE,
                      BESKRIVELSE: svar.BESKRIVELSE?.split('-')[1]
                    };
                  }
                );
                variableSvar.TILLEGGSVARIABELSVAR = newTillegsvariableSvar;
                setVariableSvarSet((variabelSvarSet) => [
                  ...variabelSvarSet,
                  variableSvar
                ]);
              }
              return variableNameAndId;
            }
          );
          pf.predefinedValues = newTillegsvariable;
        }
        return { ...pf, predefinedValues: pf.predefinedValues };
      }
    );
    setPersonFilterForm(newPersonFilterForm);
  };
  const handlePersonOptionSelect = (event: SelectChangeEvent) => {
    const option: filterOptionI = personFilterForm?.find(
      (op: filterOptionI) => op.label === event.target.value
    );
    setSelectedFilterOptions(option);
    let op = [
      { value: '=', label: '=' },
      { value: '!=', label: '!=' }
    ];
    if (option?.hasPredefinedValue) {
      setShowKodeRegister(true);
      setIsText(false);
      if (option?.label === 'TILLEGGSVARIABEL:SVARS') {
        setShowSvarBox(true);
        setVariabelError('Velg tilleggsvariabel');
        //setVariableSvarSet(personFilterForm?.find((op: filterOptionI) => op.label === '')?.);
      } else {
        setShowSvarBox(false);
        setVariabelError('');
      }
    } else {
      if (option?.label === 'IDENTITET') {
        setIsText(true);
        op.push({ value: 'Like', label: 'Ligner' });
      } else {
        setIsText(false);
        op = operators;
      }
      setShowKodeRegister(false);
    }
    setCustomizedOperator(op);

    setSelectedFilter(option);
    setActivatedOption({ ...activatedOption, selectedUtvalg: option });
  };
  const handleOperator = (value: string, singleValue: boolean) => {
    // const item = customizedOperator?.find((op: OptionI) => op.value === value);
    // setActivatedOption({ ...activatedOption, selectedOperator: { value: value, label: item?.label } })
    if (value?.toUpperCase() === 'BETWEEN') {
      setMultipleValue(true);
      setShowSecondTextBox(false);
    } else {
      if (!showSecondTextBox) setShowSecondTextBox(false);
    }
    const currentOperator: OptionI = customizedOperator?.find(
      (op: OptionI) => op.value === value
    );
    if (singleValue) {
      setFirstOperator(currentOperator);
      setActivatedOption({
        ...activatedOption,
        selectedOperatorOne: currentOperator,
        selectedOperatorTwo: undefined
      });
    } else {
      setSecondOperator(currentOperator);
      setActivatedOption({ ...activatedOption, selectedOperatorTwo: currentOperator });
    }
  };
  const handleCategory = (event: { target: { value: any } }) => {
    const value = event.target.value;
    const currentValuelist: OptionI[] = [];
    if (activatedOption?.selectedUtvalg?.label === 'TILLEGGSVARIABEL') {
      //setSvarOptions(activatedOption?.selectedUtvalg?.svars);
      setVariabelError('');
    }
    for (const val of value) {
      const item = activatedOption?.selectedUtvalg?.predefinedValues?.find(
        (op: OptionI) => op.value === val
      );
      const currentValue = { value: val, label: item?.label };
      currentValuelist.push(currentValue);
    }
    setSelectedCategoryValues(value);
    setActivatedOption({ ...activatedOption, categoryValues: currentValuelist });
  };
  const handelVariabel = (value: number) => {
    setVariabelError('');
    const item = variabelSvarSet?.find((op: TillegsvariableI) => op.VARIABELID === value);
    setSvars(item?.TILLEGGSVARIABELSVAR);
    if (item?.TILLEGGSVARIABELSVAR?.length === 0) {
      setCategoricalSvar(false);
    } else {
      setCategoricalSvar(true);
    }
    setSelectedVariabel({ label: item?.NAVN, value: value });
  };
  const handleSvar = (event: { target: { value: any } }) => {
    const value = event.target.value;
    const currentSvarlist: OptionI[] = [];
    for (const val of value) {
      const item = svars?.find((op: TillegsvariableSvarI) => op.KODE === val);
      const currentValue = { value: val, label: item?.BESKRIVELSE };
      currentSvarlist.push(currentValue);
    }
    const currentSvar: variabelAndSvarsI = {
      variabel: selectedVariabel,
      svars: currentSvarlist
    };
    setSelectedSvars(value);
    setActivatedOption({
      ...activatedOption,
      categoryValues: [
        { label: selectedVariabel?.label, value: selectedVariabel?.value }
      ],
      variableSvarArray: currentSvar
    });
  };
  const handleTypedSvar = (value: string) => {
    const currentValue = { value: value, label: value };
    const currentSvar: variabelAndSvarsI = {
      variabel: selectedVariabel,
      svars: [currentValue]
    };
    setTypedSvar(parseInt(value));
    setActivatedOption({
      ...activatedOption,
      categoryValues: [
        { label: selectedVariabel?.label, value: selectedVariabel?.value }
      ],
      variableSvarArray: currentSvar
    });
  };
  const handleInputValues = (value: string, singleValue: boolean) => {
    const label = activatedOption?.selectedUtvalg?.label;
    const currentValuelist: arrayInputValuesI = {
      parameter: '',
      values: []
    };
    if (!singleValue) {
      let values: string[] = [firstNumericValue?.toString()];
      values.push(value);
      currentValuelist.values = values;
      currentValuelist.parameter = label;
    } else {
      currentValuelist.values = [value];
      currentValuelist.parameter = label;
    }
    setActivatedOption({ ...activatedOption, nonCategoryValues: currentValuelist });
  };
  const handleClickAktiver = () => {
    resetValues();
    const found = activatedOptions?.find(
      (element: selectedOptionArrayI) =>
        element.option?.selectedUtvalg?.label === activatedOption?.selectedUtvalg?.label
    );
    if (
      found !== undefined &&
      found?.option?.selectedUtvalg?.label !== 'TILLEGGSVARIABEL:SVARS'
    ) {
      showAlertMessage(
        'Denne utvalgen er valgt allerede, først må du fjerne den fra de valgte.',
        'error'
      );
      return;
    }
    setActivatedOptions([...activatedOptions, { option: activatedOption }]);
    const updatedActivatedOptions: selectedOptionArrayI[] = [...activatedOptions];
    const lastElement: selectedOptionArrayI = {
      option: activatedOption
    };
    updatedActivatedOptions.push(lastElement);
    updateFilter(updatedActivatedOptions);
    displayActivatedOptions(lastElement);
  };
  const updateSelectedOptionsOnDelete = (updatedArray: selectedOptionArrayI[]) => {
    setActivatedOptions(updatedArray);
    updateFilter(updatedArray);
  };
  const updateFilter = (selectedOptions: selectedOptionArrayI[]) => {
    const params: filterQueryDataI[] = selectedOptions?.map(
      (element: selectedOptionArrayI) => {
        const varId = element.option?.variableSvarArray?.variabel?.value;
        const svarList: string[] = element.option?.variableSvarArray?.svars?.map(
          (svar: OptionI) => svar.value
        );

        const variableSvarlist: selectedVariableAndSvarsI = {
          variabelId: varId,
          svars: svarList
        };
        const predefinedValue = element.option?.selectedUtvalg?.hasPredefinedValue;
        return {
          columnName: element.option?.selectedUtvalg?.columnName,
          operatorOne: element.option?.selectedOperatorOne?.value || '=',
          operatorTwo: element.option?.selectedOperatorTwo?.value,
          logicalOperator: logicalOperator,
          value: predefinedValue
            ? JSON.stringify(
                element.option?.categoryValues?.map((op: OptionI) => op?.value)
              )
            : JSON.stringify(element.option?.nonCategoryValues?.values),
          variableSvarSet: JSON.stringify(variableSvarlist)
        };
      }
    );
    setFilterParams(params);
    updateBeregningObject('kosthold', 'personFilterParams', params);
  };
  const resetValues = () => {
    setActivatedOption({
      selectedUtvalg: undefined,
      selectedOperatorOne: undefined,
      selectedOperatorTwo: undefined,
      nonCategoryValues: undefined,
      categoryValues: undefined
    });
    setEnableActivate(false);
    setShowSecondTextBox(false);
    setMultipleValue(false);
    setFirstNumericValue(0);
    setSecondNumericValue(0);
    setSelectedCategoryValues([]);
    setTextValue('');
    setSelectedSvars([]);
    setSelectedFilter({
      columnName: '',
      label: '',
      kodeId: 0,
      hasPredefinedValue: false,
      predefinedValues: []
    });
    setFirstOperator({
      value: '',
      label: ''
    });
    setSecondOperator({
      value: '',
      label: ''
    });
    setLogicalOperator('AND');
  };
  const addTextBoxes = () => {
    setShowSecondTextBox(true);
    setMultipleValue(true);
  };
  const removeTextBoxes = () => {
    setSecondNumericValue(0);
    setShowSecondTextBox(false);
    setMultipleValue(false);
  };

  return (
    <DialogBox title={'Personfilter'} isShowing={showPersonfilter} hide={hide}>
      <Grid container justifyContent="flex-end" alignItems="flex-end">
        <Grid>
          <InputLabel>
            <b>Antall: {persons}</b>
          </InputLabel>
        </Grid>
      </Grid>
      <Grid container direction="row" justifyContent="center" alignItems="center">
        <Stack direction={'row'} spacing={1}>
          <Grid item xs={5}>
            <InputLabel id="checkSelectbox-label">Velg utvalgsfelt</InputLabel>
            <FormControl sx={{ minWidth: 300 }}>
              <Select
                variant="outlined"
                sx={classes.Select}
                value={selectedFilter?.label}
                // displayEmpty= {displayEmpty}
                onChange={handlePersonOptionSelect}
                required={true}
              >
                {personFilterForm?.map((option: filterOptionI) => (
                  <MenuItem key={option?.columnName} value={option?.label}>
                    {option?.label}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{''}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <InputLabel id="checkSelectbox-label">Velg operator</InputLabel>
            <FormControl sx={{ minWidth: 120 }}>
              <Select
                variant="outlined"
                sx={classes.Select}
                value={firstOperator?.value || ''}
                onChange={(e) => {
                  handleOperator(e.target.value as string, true);
                }}
                required={true}
              >
                {customizedOperator?.map((option: OptionI) => (
                  <MenuItem key={option?.value} value={option?.value}>
                    {option?.label}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{''}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            {showKodeRegister ? (
              <>
                {showSvarBox ? (
                  <>
                    <InputLabel id="checkSelectbox-label">Tilleggsvariabel*</InputLabel>
                    <FormControl sx={{ minWidth: 200 }}>
                      <Select
                        variant="outlined"
                        sx={classes.Select}
                        value={selectedVariabel?.value}
                        onChange={(e) => {
                          handelVariabel(e.target.value as number);
                        }}
                      >
                        {variabelSvarSet?.map((option: TillegsvariableI) => (
                          <MenuItem key={option?.VARIABELID} value={option?.VARIABELID}>
                            {option?.NAVN}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText color={'secondary'}>{variabelError}</FormHelperText>
                    </FormControl>
                    {categoricalSvar ? (
                      <>
                        <InputLabel id="checkbox-label">
                          Svar utvalg til {selectedVariabel?.label}
                        </InputLabel>
                        <FormControl sx={{ minWidth: 200 }}>
                          <Select
                            variant="outlined"
                            labelId="multiple-checkbox-label"
                            id="multiple-checkbox"
                            multiple
                            value={selectedSvars}
                            onChange={handleSvar}
                            input={<OutlinedInput label="include" />}
                            renderValue={(selectedOptions: any) =>
                              [...new Set(selectedOptions)]?.join(',')
                            }
                          >
                            {svars?.map((option: TillegsvariableSvarI) => (
                              <MenuItem key={option.KODE} value={option.KODE}>
                                <Checkbox
                                  checked={selectedSvars.indexOf(option.KODE) > -1}
                                />
                                <ListItemText primary={option?.BESKRIVELSE} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </>
                    ) : (
                      <>
                        <InputLabel id="Angi-verdi-label1">Angi verdi</InputLabel>
                        <FormControl sx={{ minWidth: 200 }}>
                          <TextField
                            sx={classes}
                            id={'verdisvar'}
                            name={'verdisvar'}
                            value={typedSvar}
                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                            onChange={(e) => handleTypedSvar(e.target.value as string)}
                            type={'text'}
                            variant={'outlined'}
                            required={false}
                            disabled={false}
                          />
                        </FormControl>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <InputLabel id="checkbox-label">Koderegister/kategorier</InputLabel>
                    <FormControl sx={{ minWidth: 120 }}>
                      <Select
                        labelId="multiple-checkbox-label"
                        id="multiple-checkbox"
                        multiple
                        value={selectedCategoryValues}
                        onChange={handleCategory}
                        input={<OutlinedInput label="include" />}
                        renderValue={(selectedOptions: any) =>
                          [...new Set(selectedOptions)]?.join(',')
                        }
                      >
                        {selectedFilterOptions?.predefinedValues?.map(
                          (option: OptionI) => (
                            <MenuItem key={option.value} value={option.value}>
                              <Checkbox
                                checked={
                                  selectedCategoryValues.indexOf(option.value) > -1
                                }
                              />
                              <ListItemText primary={option.label} />
                            </MenuItem>
                          )
                        )}
                      </Select>
                    </FormControl>
                  </>
                )}
              </>
            ) : (
              <>
                {isText ? (
                  <>
                    <InputLabel id="Angi-verdi-label">Angi verdi</InputLabel>
                    <FormControl sx={{ minWidth: 200 }}>
                      <TextField
                        sx={classes}
                        id={'verdi'}
                        name={'verdi'}
                        value={textValue || ''}
                        onChange={(e) => {
                          setTextValue(e.target.value);
                          handleInputValues(e.target.value, true);
                        }}
                        type={'text'}
                        variant={'outlined'}
                        required={false}
                        disabled={false}
                      />
                    </FormControl>
                  </>
                ) : (
                  <>
                    <>
                      <InputLabel id="Angi-verdi-label1">Angi verdi</InputLabel>
                      <Stack sx={{ minWidth: 120 }} direction={'row'}>
                        <FormControl sx={{ minWidth: 200 }}>
                          <TextField
                            sx={classes}
                            id={'verdiOne'}
                            name={'verdiOne'}
                            value={firstNumericValue === 0 ? '' : firstNumericValue}
                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                            onChange={(e) => {
                              setFirstNumericValue(+e.target.value);
                              handleInputValues(e.target.value, true);
                            }}
                            type={'text'}
                            variant={'outlined'}
                            required={false}
                            disabled={false}
                          />
                        </FormControl>
                        <IconButton color="primary" onClick={addTextBoxes}>
                          <AddCircleOutlineIcon />
                        </IconButton>
                      </Stack>
                    </>
                  </>
                )}
              </>
            )}
          </Grid>
          <Grid item container justifyContent="center" alignItems="center">
            <NButton
              children={'Aktiver'}
              leftIcon={<ToggleOnIcon />}
              disabled={!enableActivate}
              onClick={handleClickAktiver}
            />
          </Grid>
        </Stack>
      </Grid>
      <Grid
        item
        xs={12}
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        {showSecondTextBox ? (
          <Paper elevation={3} style={{ padding: '16px', borderRadius: '8px' }}>
            <RadioGroup
              row
              name="logicalOperator"
              defaultValue="AND"
              value={logicalOperator}
              onChange={(e) => setLogicalOperator(e.target.value)}
            >
              <FormControlLabel
                value={'AND'}
                control={<Radio color="primary" size="small" />}
                label="Og"
              />
              <FormControlLabel
                value={'OR'}
                control={<Radio color="primary" size="small" />}
                label="Eller"
              />
            </RadioGroup>
          </Paper>
        ) : undefined}
      </Grid>
      <Grid container direction="row" justifyContent="center" alignItems="center">
        <Stack direction={'row'} spacing={1}>
          <Grid item xs={4}></Grid>
          <Grid item xs={2}>
            {showSecondTextBox ? (
              <>
                <FormControl sx={{ minWidth: 120 }}>
                  <Select
                    variant="outlined"
                    sx={classes.Select}
                    value={secondOperator?.value || ''}
                    onChange={(e) => {
                      handleOperator(e.target.value as string, false);
                    }}
                    required={true}
                  >
                    {customizedOperator?.map((option: OptionI) => (
                      <MenuItem key={option?.value} value={option?.value}>
                        {option?.label}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{''}</FormHelperText>
                </FormControl>
              </>
            ) : undefined}
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={4} container justifyContent={'flex-start'}>
            {multipleValue ? (
              <>
                <Stack direction={'row'}>
                  <FormControl sx={{ minWidth: 200 }}>
                    <TextField
                      sx={classes}
                      id={'verdiTwo'}
                      name={'verdiTwo'}
                      value={secondNumericValue === 0 ? '' : secondNumericValue}
                      inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                      onChange={(e) => {
                        setSecondNumericValue(+e.target.value);
                        handleInputValues(e.target.value, false);
                      }}
                      type={'text'}
                      variant={'outlined'}
                      required={false}
                      disabled={false}
                    />
                  </FormControl>
                  <IconButton
                    color="error"
                    onClick={removeTextBoxes}
                    disabled={
                      activatedOption?.selectedOperatorOne?.label?.toUpperCase() ===
                      'BETWEEN'
                    }
                  >
                    <HighlightOffIcon />
                  </IconButton>{' '}
                </Stack>
              </>
            ) : undefined}
          </Grid>
          <Grid item xs={1}></Grid>
        </Stack>
      </Grid>
      <Divider />
      <Grid container direction="row" justifyContent="center" alignItems="center">
        <DisplayActivatedOptions
          filterCriteria={personFilterCriteria}
          updateSelectedOptionsOnDelete={updateSelectedOptionsOnDelete}
          activatedOptions={activatedOptions}
          setFilterCriteria={setPersonFilterCriteria}
        />
      </Grid>
    </DialogBox>
  );
};
