import React, { useContext, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import useFetchData from '../../../utilities/useFetchData';
import { DatabaseContext } from '../../../../context/DatabaseContext';
import { resultatGruppeI } from '../types/matvaregruppeTypes';
import { Dropdown } from '../../components/Inputs';
interface ResultatGruppePrpops {
  selectedResultatGroup: resultatGruppeI;
  setSelectedResultatGroup: (value: resultatGruppeI) => void;
  disabled?: boolean;
  cleared?: boolean;
}
export const ResultatGruppe = (props: ResultatGruppePrpops) => {
  const { selectedResultatGroup, setSelectedResultatGroup, disabled, cleared } = props;
  const { currentDatabase } = useContext(DatabaseContext);
  const [resultatGroups, setResultatGroups] = useState<resultatGruppeI[]>([
    {
      GRUPPETYPEID: 0,
      BESKRIVELSE: '',
      LANG_BESKRIVELSE: '',
      TILGANGSKODE: 0
    }
  ]);
  const getResultatGroup = useFetchData({
    loadOnMount: true,
    clearDataOnLoad: false,
    projectURL: 'getResultatGruppe',
    subProjectURL: `${currentDatabase?.value}`
  });
  useEffect(() => {
    if (getResultatGroup.data) {
      const data: resultatGruppeI[] =
        getResultatGroup?.data as unknown as resultatGruppeI[];
      cleared ? setResultatGroups([]) : setResultatGroups(data);
    }
  }, [getResultatGroup.data]);
  return (
    <Box style={{ textAlign: 'left' }}>
      <Dropdown
        header="Resultatgruppe"
        defaultValue={selectedResultatGroup}
        value={selectedResultatGroup}
        options={resultatGroups || []}
        optionLabel={(o) => o.BESKRIVELSE}
        onChange={(v) => setSelectedResultatGroup(v as resultatGruppeI)}
        disabled={disabled || false}
      />
    </Box>
  );
};
