import { AlertColor } from '@mui/material';
import {
  Matvare,
  MatvaregruppeI
} from '../components/pages/matvare/types/matvaregruppeTypes';

export const SET_KODE_FROM = 'SET_KODE_FROM';
export const SET_MATVARE_NAVN = 'SET_MATVARE_NAVN';
export const SET_STATUS = 'SET_STATUS';
export const SET_INGREDIENS_ID = 'SET_INGREDIENS_ID';
export const SET_RESULTAT_GRUPPE_TYPE = 'SET_RESULTAT_GRUPPE_TYPE';
export const SET_RESULTAT_GRUPPER = 'SET_RESULTAT_GRUPPER';
export const SET_SELECTED_MATVARER = 'SET_SELECTED_MATVARER';
export const SET_SELECTED_MATVAREGRUPPER = 'SET_SELECTED_MATVAREGRUPPER';
export const SET_SELECTED_MATVARER_COUNT = 'SET_SELECTED_MATVARER_COUNT';

export const SET_NODE_NR_ID = 'SET_NODE_NR_ID';

export const SET_ALERT_MESSAGE = 'SET_MESSAGE';
export const SET_ALERT_COLOR = 'SET_COLOR';
export const SET_ALERT_CLOSED = 'SET_ALERT_CLOSED';

export const SET_PROSJEKT_ID = 'SET_PROSJEKT_ID';
export const SET_PROSJEKT_NAVN = 'SET_PROSJEKT_NAVN';
export const SET_KOSTHOLD_PROSJEKT_ID = 'SET_KOSTHOLD_PROSJEKT_ID';
export const SET_UNDERSOKELSE_ID = 'SET_UNDERSOKELSE_ID';
export const SET_KORT_NAVN = 'SET_KORT_NAVN';

export const SET_DIALOG_RESPONSE = 'SET_DIALOG_RESPONSE';
export const SET_DIALOG_CLOSED = 'SET_DIALOG_CLOSED';

export const SET_UKORT_NAVN = 'SET_UKORT_NAVN';

export const SET_CURRENT_LOCATION = 'SET_CURRENT_LOCATION';

export const SET_PROGRESS_STATUS = 'SET_PROGRESS_STATUS';

export const SET_CALCULATION_ID = 'SET_CALCULATION_ID';
export const SET_CLEAR = 'SET_CLEAR';
export const SET_TOTAL_COUNTS = 'SET_TOTAL_COUNTS';

export const SET_MATVAREGRUPPELIST = 'SET_MATVAREGRUPPELIST';
export const SET_MATVARELIST = 'SET_MATVARELIST';

export interface setKodeFromAction {
  type: typeof SET_KODE_FROM;
  kodeFrom: number;
}
export interface setMatvareNavnAction {
  type: typeof SET_MATVARE_NAVN;
  matvareNavn: string;
}
export interface setStatusAction {
  type: typeof SET_STATUS;
  status: number;
}

export interface setIngrediensIdAction {
  type: typeof SET_INGREDIENS_ID;
  ingrediensId: number;
}

export interface setResultatGruppeTypeAction {
  type: typeof SET_RESULTAT_GRUPPE_TYPE;
  resultatgruppeType: { gruppeTypeId: number; beskrivelse: string };
}
export interface setResultatGrupperAction {
  type: typeof SET_RESULTAT_GRUPPER;
  resultatgrupper: [{ gruppeTypeId: 0; beskrivelse: null }];
}
export interface setNodeNrIdAction {
  type: typeof SET_NODE_NR_ID;
  nodeNrId: number;
}
export interface setCalculationIdAction {
  type: typeof SET_CALCULATION_ID;
  calculationId: string;
}
export interface setClearAction {
  type: typeof SET_CLEAR;
  clear: boolean;
}
export interface setTotalCountsAction {
  type: typeof SET_TOTAL_COUNTS;
  totalCounts: {
    matvareGroupCount: 0;
    matvareCount: 0;
    fjerndeMatvareCount: 0;
    stoffCount: 0;
  };
}
export interface setSelectedMatvarerAction {
  type: typeof SET_SELECTED_MATVARER;
  selectedMatvarer: [{ matvarekode: number; navn: string }];
}

export interface setSelectedMatvaregupperAction {
  type: typeof SET_SELECTED_MATVAREGRUPPER;
  selectedMatvaregrupper: [{ nodenrid: number; kortnavn: string }];
}

export type MatvareActionTypes =
  | setKodeFromAction
  | setMatvareNavnAction
  | setStatusAction
  | setIngrediensIdAction
  | setResultatGruppeTypeAction
  | setResultatGrupperAction
  | setNodeNrIdAction
  | setSelectedMatvarerAction
  | setSelectedMatvaregupperAction;
export interface setAlertMessageAction {
  type: typeof SET_ALERT_MESSAGE;
  alertMessage: string;
}
export interface setAlertColorAction {
  type: typeof SET_ALERT_COLOR;
  alertColor: AlertColor;
}

export interface setAlertClosedAction {
  type: typeof SET_ALERT_CLOSED;
  alertClosed: boolean;
}

export type AlertActionTypes =
  | setAlertMessageAction
  | setAlertColorAction
  | setAlertClosedAction;

export interface setProsjektIdAction {
  type: typeof SET_PROSJEKT_ID;
  prosjektId: number;
}

export interface setProsjektNavnAction {
  type: typeof SET_PROSJEKT_NAVN;
  prosjektNavn: string;
}
export interface setKostholdProsjektIdAction {
  type: typeof SET_KOSTHOLD_PROSJEKT_ID;
  kostholdProsjektId: number;
}
export interface setUndersokelseIdAction {
  type: typeof SET_UNDERSOKELSE_ID;
  undersokelseId: number;
}
export interface setKortNavnAction {
  type: typeof SET_KORT_NAVN;
  kortNavn: string;
}
export interface setDialogResponseActionType {
  type: typeof SET_DIALOG_RESPONSE;
  response: string;
}
export interface setDialogClosedActionType {
  type: typeof SET_DIALOG_CLOSED;
  dialogClosed: boolean;
}

export interface setUKortNavnAction {
  type: typeof SET_UKORT_NAVN;
  uKortNavn: string;
}

export interface setCurrentLocationAction {
  type: typeof SET_CURRENT_LOCATION;
  currentLocation: {};
}

export interface setProgressStatusAction {
  type: typeof SET_PROGRESS_STATUS;
  showProgress: boolean;
}

export type ProsjektActionTypes =
  | setProsjektIdAction
  | setProsjektNavnAction
  | setKostholdProsjektIdAction
  | setUndersokelseIdAction
  | setKortNavnAction;

export type DialogActionTypes = setDialogResponseActionType | setDialogClosedActionType;

export type UndersokelseActionTypes = setUndersokelseIdAction | setUKortNavnAction;

export type LocationActionTypes = setCurrentLocationAction;

export type ProgressActionTypes = setProgressStatusAction;

export type BeregningActionTypes =
  | setCalculationIdAction
  | setClearAction
  | setTotalCountsAction;

export type AppActions =
  | MatvareActionTypes
  | AlertActionTypes
  | ProsjektActionTypes
  | DialogActionTypes
  | UndersokelseActionTypes
  | LocationActionTypes
  | ProgressActionTypes
  | BeregningActionTypes;

export interface setMatvaregruppeListAction {
  type: typeof SET_MATVAREGRUPPELIST;
  matvaregruppeList: [
    {
      gruppetypeId: number;
      linkup: number;
      matvaregrupper: MatvaregruppeI[];
    }
  ];
}
export interface setMatvareListAction {
  type: typeof SET_MATVARELIST;
  matvareList: [
    {
      gruppetypeId: number;
      linkup: number;
      matvarer: Matvare[];
    }
  ];
}
export type MatvaregruppeActionTypes = setMatvaregruppeListAction | setMatvareListAction;
