import React from 'react';
import { IconButton, InputBase, TableCell, TableRow } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { Mengdeenhet } from '../types/MatvareTypes';
import { NButton } from '../../components/Inputs';

interface EmptyTableRowProps {
  newRowData: Mengdeenhet;
  index: number;
  handleChangeTable: any;
  selectMendeToDelete: (mengde: string) => void;
  owned: boolean;
  setInputTableIndex?: React.Dispatch<React.SetStateAction<number>>;
}
interface rowDataProp {
  value: string | number;
  name: string;
  size: string;
  type?: string;
}
interface SingleInputProps {
  value: number | string;
  handleChangeTable: (
    name: string,
    value: string | number,
    index: number,
    type: string
  ) => any;
  name: string;
  size?: string;
  index?: number;
  isDisabled?: boolean;
  handleOnEnterKeyPress?: (e: any) => void;
  inputType?: string;
  setInputTableIndex?: React.Dispatch<React.SetStateAction<number>>;
}
const SingleInput = (singleProps: SingleInputProps) => {
  const {
    value,
    handleChangeTable,
    name,
    size,
    index,
    isDisabled,
    inputType,
    setInputTableIndex,
    handleOnEnterKeyPress
  } = singleProps;
  return (
    <TableCell style={{ padding: 0, margin: 0, width: size }}>
      <InputBase
        //This is a hack to make the field accept 0 as a valid value(for decimal inputs)
        value={inputType === 'number' && value === 0 ? undefined : value || ''}
        //value={value || ''}
        name={name}
        inputProps={{
          min: 0,
          style: {
            textAlign: 'left',
            borderRight: '1px solid lightgrey',
            borderLeft: '1px solid lightgrey',
            borderRadius: 0,
            padding: 4
          }
          //step: 4
        }}
        style={{ width: '100%' }}
        type={inputType || 'text'}
        autoComplete="off"
        disabled={isDisabled || false}
        onChange={(e) =>
          handleChangeTable(e.target.name, e.target.value, index, inputType)
        }
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleOnEnterKeyPress(e);
          }
        }}
      />
    </TableCell>
  );
};
export const EmptyTableRowMengde = (props: EmptyTableRowProps) => {
  const { newRowData, index, handleChangeTable, owned, setInputTableIndex } = props;
  let rowData: rowDataProp[] = [
    { value: +newRowData.sortering, name: 'sortering', size: '10%', type: 'number' },
    { value: newRowData.mengdeenhetid, name: 'mengdeenhetid', size: '35%' },
    { value: newRowData.mengde, name: 'mengde', size: '10%', type: 'number' },
    { value: newRowData.referanse, name: 'referanse', size: '35%' }
  ];
  return (
    <TableRow>
      {rowData?.map((r: rowDataProp) => (
        <SingleInput
          value={r.value}
          handleChangeTable={handleChangeTable}
          name={r.name}
          size={r.size}
          //handleOnEnterKeyPress={handleOnEnterKeyPress}
          inputType={r.type}
          index={index}
          setInputTableIndex={setInputTableIndex}
        />
      ))}
      {newRowData.mengdeenhetid ? (
        <TableCell style={{ borderRight: '1px solid lightgrey' }}>
          <NButton
            children="Slett"
            rightIcon={<DeleteOutlineOutlinedIcon />}
            variant="text"
            onClick={() => props.selectMendeToDelete(newRowData.mengdeenhetid)}
            disabled={!owned}
          />
        </TableCell>
      ) : (
        <TableCell style={{ borderRight: '1px solid lightgrey' }}></TableCell>
      )}
    </TableRow>
  );
};
