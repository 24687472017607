import React, { useState } from 'react';
import { toString } from 'lodash';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  TableContainer,
  Paper,
  IconButton,
  TextField,
  Grid,
  Alert
} from '@mui/material';
import { IngredientData } from '../types/MatvareTypes';
import { ChatBubbleButtonSingle } from '../../components/ChatBubbleButton';
import { NButton } from '../../components/Inputs';
import ClearIcon from '@mui/icons-material/Clear';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
interface GenericTableProps<T extends IngredientData> {
  data: T[];
  owned: boolean;
  newRow: IngredientData;
  handleNewRow: (name: string, value: string | number) => void;
  handleOnEnterKeyPress: (event: React.KeyboardEvent<HTMLElement>) => void;
  handleBlur: () => void;
  showEnhetOptions: (row: IngredientData) => void;
  handleDelete: (val: number) => void;
  setRows: (val: IngredientData[]) => void;
  setNewRow: (val: IngredientData) => void;
  addNewItem: () => void;
  setSelectedRow: (val: IngredientData) => void;
  setDataChanged: (v: boolean) => void;
  showAllFoodItems: () => void;
}

const OppskriftTable = <T extends IngredientData>({
  data,
  owned,
  newRow,
  setSelectedRow,
  setRows,
  handleNewRow,
  setNewRow,
  handleOnEnterKeyPress,
  handleBlur,
  handleDelete,
  addNewItem,
  showEnhetOptions,
  setDataChanged,
  showAllFoodItems
}: GenericTableProps<T>) => {
  const [error, setError] = React.useState('');
  const initialRow: IngredientData = {
    INGREDIENSID: 0,
    NAVN: '',
    GRAM: '',
    SORT: 0,
    ORDNRID: 0,
    ENHETER: [],
    ANTALL: '',
    gPerEnhet: 0
  };
  const [antallEnabled, setAntallEnabled] = useState(true);
  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: 'asc' | 'desc';
  }>({
    key: 'SORT',
    direction: 'asc'
  });
  let columns = [
    { key: 'SORT', label: 'Sort', editable: false, width: '5%', type: 'number' },
    { key: 'INGREDIENSID', label: 'Kode', editable: false, width: '10%', type: 'number' },
    { key: 'NAVN', label: 'Navn', editable: false, width: '30%' },
    { key: 'GRAM', label: 'Gram', editable: true, width: '10%', type: 'number' },
    { key: 'MENGDEENHET', label: 'Enhet', editable: true, width: '15%' },
    { key: 'ANTALL', label: 'Antall', editable: true, width: '5%', type: 'number' }
  ];

  const handleSort = (columnKey: string) => {
    setSortConfig((prev) => {
      if (prev && prev.key === columnKey) {
        return { key: columnKey, direction: prev.direction === 'asc' ? 'desc' : 'asc' };
      } else {
        return { key: columnKey, direction: 'asc' };
      }
    });
  };
  const sortedData = React.useMemo(() => {
    if (!sortConfig) return data;

    return [...data].sort((a, b) => {
      const aValue = a[sortConfig.key];
      const bValue = b[sortConfig.key];

      // Check for empty values
      if (aValue === '' || aValue === null || aValue === undefined) return 1;
      if (bValue === '' || bValue === null || bValue === undefined) return -1;

      if (typeof aValue === 'number' && typeof bValue === 'number') {
        return sortConfig.direction === 'asc' ? aValue - bValue : bValue - aValue;
      } else {
        const aString = toString(aValue);
        const bString = toString(bValue);

        return sortConfig.direction === 'asc'
          ? aString.localeCompare(bString, undefined, { align: 'right' })
          : bString.localeCompare(aString, undefined, { align: 'right' });
      }
    });
  }, [data, sortConfig]);
  const enableAntall = (row: IngredientData) => {
    if (row.MENGDEENHET) {
      setAntallEnabled(true);
    } else {
      setAntallEnabled(false);
    }
  };

  const handleChange = (
    row: IngredientData,
    name: string,
    value: string | number | boolean
  ) => {
    const rowIndex = data?.findIndex((d) => d.INGREDIENSID === row.INGREDIENSID);
    const updatedRows = [...data];
    if (rowIndex !== -1) {
      if (name === 'isEditMode') {
        enableAntall(row);
      }
      if (name === 'ANTALL') {
        let mengde = 0;
        const gPerEnhet = row.ENHETER?.find(
          (e) => e.MENGDEENHETID === row.MENGDEENHET
        )?.MENGDE;
        const gramValue = parseFloat(
          (row?.gPerEnhet || gPerEnhet)?.toString()?.trim()?.replace(/,/g, '.')
        );
        if (+value) {
          //ANTALL is updated only if MENGDEENHET has value, then we also update GRAM by multiplying gPerEnhet with ANTALL
          if (row.MENGDEENHET !== '') {
            mengde = Number((+value * gramValue)?.toFixed(2));
            updatedRows[rowIndex] = {
              ...updatedRows[rowIndex],
              ANTALL: value ? +value : '',
              GRAM: mengde || +row.GRAM
            };
          }
        } else {
          if (row.MENGDEENHET !== '') {
            updatedRows[rowIndex] = {
              ...updatedRows[rowIndex],
              ANTALL: '',
              GRAM: gramValue
            };
          }
        }
      } else if (name === 'GRAM') {
        if (value) {
          const newValue = parseFloat(value.toString());

          updatedRows[rowIndex] = {
            ...updatedRows[rowIndex],
            ANTALL: '',
            MENGDEENHET: '',
            GRAM: isNaN(newValue) ? '' : newValue
          };
        }
      } else {
        updatedRows[rowIndex] = {
          ...updatedRows[rowIndex],
          [name]: value ? value : ''
        };
      }
      setRows(updatedRows);
      setSelectedRow(row);
      setDataChanged(true);
    }
  };

  return (
    <TableContainer component={Paper} style={{ maxHeight: 'auto' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Alert severity="info" sx={{ marginTop: '1em' }}>
            Bruk punktum(.) i stedet for komma(,) for desimalverdier.
          </Alert>
        </Grid>
      </Grid>
      <Table size="small" stickyHeader>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={String(column.key)}
                style={{ background: '#4977AF', color: 'white', width: column.width }}
              >
                <TableSortLabel
                  active={sortConfig?.key === column.key}
                  direction={sortConfig?.direction}
                  onClick={() => handleSort(column.key)}
                >
                  {column.label}
                </TableSortLabel>
              </TableCell>
            ))}
            {owned ? (
              <>
                <TableCell style={{ background: '#4977AF', color: 'white', width: '5%' }}>
                  Rediger
                </TableCell>
                <TableCell style={{ background: '#4977AF', color: 'white', width: '5%' }}>
                  Slett
                </TableCell>
              </>
            ) : null}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedData?.map((row: IngredientData, index: number) => (
            <TableRow
              key={`${row.INGREDIENSID}-${index}`}
              onClick={() => {
                //setSelectedRow(highlighted ? null : row);
                setSelectedRow(row);
              }}
            >
              {columns.map((column) => (
                <TableCell key={String(column.key)}>
                  {owned && column.editable && row.isEditMode ? (
                    column.key === 'MENGDEENHET' ? (
                      <ChatBubbleButtonSingle
                        name={'Enhet..'}
                        handleOpenPopup={() => showEnhetOptions(row)}
                      />
                    ) : (
                      <TextField
                        type={column.type || 'text'}
                        value={row[column.key] || ''}
                        variant="standard"
                        onChange={(e) => handleChange(row, column.key, e.target.value)}
                        disabled={column.key === 'ANTALL' && !antallEnabled}
                        inputProps={{ step: 'any' }}
                      />
                    )
                  ) : (
                    row[column.key]
                  )}
                </TableCell>
              ))}
              {owned ? (
                <>
                  <TableCell>
                    {row.isEditMode ? (
                      <IconButton
                        children={<CheckOutlinedIcon />}
                        onClick={() => handleChange(row, 'isEditMode', !row.isEditMode)}
                      />
                    ) : (
                      <IconButton
                        children={<EditOutlinedIcon />}
                        onClick={() => handleChange(row, 'isEditMode', !row.isEditMode)}
                        disabled={!owned}
                      />
                    )}
                  </TableCell>
                  <TableCell>
                    <NButton
                      children=""
                      leftIcon={<DeleteOutlinedIcon />}
                      variant="text"
                      disabled={!owned}
                      onClick={() => handleDelete(row?.INGREDIENSID)}
                    />
                  </TableCell>
                </>
              ) : null}
            </TableRow>
          ))}
          {owned ? (
            <TableRow>
              <TableCell>
                <NButton
                  children="Matvarer"
                  leftIcon={<SearchOutlinedIcon />}
                  onClick={showAllFoodItems}
                  fullWidth
                />
              </TableCell>
              <TableCell>
                <TextField
                  type="number"
                  value={newRow?.INGREDIENSID || ''}
                  variant="standard"
                  name="INGREDIENSID"
                  onChange={(e) => handleNewRow(e.target.name, e.target.value)}
                  onKeyDown={handleOnEnterKeyPress}
                  onBlur={handleBlur}
                  required
                  error={!!error}
                  helperText={error}
                />
              </TableCell>
              <TableCell>
                <TextField
                  value={newRow?.NAVN || ''}
                  variant="standard"
                  name="NAVN"
                  onChange={(e) => handleNewRow(e.target.name, e.target.value)}
                />
              </TableCell>
              <TableCell>
                <TextField
                  type="number"
                  value={newRow?.GRAM || ''}
                  variant="standard"
                  name="GRAM"
                  onChange={(e) => handleNewRow(e.target.name, e.target.value)}
                  inputProps={{ step: 'any' }}
                  disabled={!newRow.INGREDIENSID}
                />
              </TableCell>
              <TableCell>
                {newRow?.INGREDIENSID ? (
                  <ChatBubbleButtonSingle
                    name={'Enhet..'}
                    handleOpenPopup={() => showEnhetOptions(newRow)}
                  />
                ) : null}
              </TableCell>
              <TableCell>
                <TextField
                  type="number"
                  value={newRow?.ANTALL || ''}
                  variant="standard"
                  name="ANTALL"
                  onChange={(e) => handleNewRow(e.target.name, e.target.value)}
                  disabled={!newRow?.MENGDEENHET}
                />
              </TableCell>
              {newRow?.INGREDIENSID ? (
                <TableCell colSpan={2}>
                  <IconButton aria-label="done" onClick={addNewItem} disabled={false}>
                    <CheckOutlinedIcon />
                  </IconButton>
                  <IconButton
                    aria-label="clear"
                    onClick={() => {
                      setNewRow(initialRow);
                      setError('');
                    }}
                    disabled={false}
                  >
                    <ClearIcon />
                  </IconButton>
                </TableCell>
              ) : null}
            </TableRow>
          ) : null}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default OppskriftTable;
