import React, { useState } from 'react';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Grid,
  CircularProgress
} from '@mui/material';
import useFetchData from '../../../utilities/useFetchData';
import { DialogBox } from '../../../utilities/DialogBox';
import { TilleggsvariableI } from '../types/TilleggsvariableTypes';
import { MonoSearchField } from '../../components/Inputs';
import { Typography } from '@mui/material';

interface TilleggsvariableListProps {
  tilleggsvariablerList: TilleggsvariableI[];
  //setTilleggsvariablerList: (list: TilleggsvariableI[]) => void;
  hide: () => void;
  showAlertMessage: (a: string, b: string) => void;
  setVariabelId: (n: number) => void;
}

export const TilleggsvariableList = (props: TilleggsvariableListProps) => {
  const { tilleggsvariablerList, showAlertMessage, setVariabelId } = props;

  const [variabels, setVariabels] = useState<TilleggsvariableI[]>(tilleggsvariablerList);
  const title = 'Tilleggsvariabler';
  const [showDialogbox, setShowDialogbox] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const getTilleggsvariablerList = useFetchData({
    loadOnMount: false,
    clearDataOnLoad: false,
    projectURL: 'getTilleggsvariablerList'
  });

  React.useEffect(() => {
    setVariabels(tilleggsvariablerList);
  }, [tilleggsvariablerList.length]);

  React.useEffect(() => {
    if (getTilleggsvariablerList.error) {
      showAlertMessage('Det har oppstått en feil. Vennligst prøv igjen senere.', 'error');
    }
  }, [getTilleggsvariablerList.error]);

  const handleCloseTilleggsVaribaleList = () => {
    //setOpenProsjektList(false);
    //setTilleggsvariablerList([]);
    props.hide();
  };

  /*   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
    };
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    }; */

  /* const filteredTilleggsvariables = tilleggsvariablerList
    ? tilleggsvariablerList.filter((row) => {
      return row.kortnavn?.toLowerCase().includes(searchKortnavn?.toLowerCase());
    })
    : null; */
  const setSearchKortnavn = (s?: string) => {
    s
      ? setVariabels(
          tilleggsvariablerList?.filter((r) => r.kortnavn?.toString()?.includes(s))
        )
      : setVariabels(tilleggsvariablerList);
  };

  const LinkCell = (p: { row: TilleggsvariableI; text: string | number }) => (
    <Typography
      sx={{
        textDecoration: 'underline',
        color: 'blue',
        cursor: 'pointer'
      }}
      children={p.text}
      onClick={() => {
        setVariabelId(p.row.variabelId);
        handleCloseTilleggsVaribaleList();
      }}
    />
  );

  return (
    <DialogBox
      {...props}
      title={title}
      isShowing={showDialogbox}
      hide={handleCloseTilleggsVaribaleList}
      size="lg"
    >
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <MonoSearchField
            header="Søk etter kortnavn"
            searchFn={(s) => setSearchKortnavn(s)}
          />
        </Grid>
        <Grid item xs={9} />
        <Grid item xs={12}>
          <Table size="small" sx={{ border: '1px solid black' }}>
            <TableHead>
              <TableRow>
                <TableCell>Id</TableCell>
                <TableCell>Kortnavn</TableCell>
                <TableCell>Spørsmål</TableCell>
                <TableCell>Svartype</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {variabels?.length ? (
                variabels
                  // ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((row) => (
                    <TableRow key={row.variabelId} hover>
                      <TableCell>
                        <LinkCell row={row} text={row.variabelId} />
                      </TableCell>
                      <TableCell>
                        <LinkCell row={row} text={row.kortnavn} />
                      </TableCell>
                      <TableCell>{row.sporsmal}</TableCell>
                      <TableCell>{row.svartype}</TableCell>
                    </TableRow>
                  ))
              ) : (
                <CircularProgress />
              )}
            </TableBody>
          </Table>
        </Grid>
        <Grid item xs={6} />
        {/*  <Grid item xs={6}>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 100]}
                  component="div"
                  count={tilleggsvariablerList?.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Grid> */}
      </Grid>
    </DialogBox>
  );
};
