import React from 'react';
import { Grid } from '@mui/material';
import { Matvare, MatvaregruppeI, TableType } from '../types/matvaregruppeTypes';
import { useEffect, useState } from 'react';
import DialogComponent from '../../../utilities/DialogComponent';
import { MatvaregruppeTableContainer } from './MatvaregruppeTableContainer';
import PostData from '../../../utilities/postData';
import { NButton } from '../../components/Inputs';
interface NavigateLevelI {
  nodeId: number;
  name: string;
  n: number;
}
interface MGTableProps {
  token: string;
  databaseId: number;
  gruppetypeId: number;
  owned: boolean;
  showAlertComponent: (message: string, severity: any) => void;
  setShowProgress: (value: boolean) => void;
  setProgressText: (value: string) => void;
  cleanMatvareFromReduxStore: (nodeIdFrom: number, nodeIdTo: number) => void;
  cleanMatvaregruppeFromReduxStore: (nodeIdFrom: number, nodeIdTo: number) => void;
}

export default function MoveMatvaregruppe(props: MGTableProps) {
  const {
    token,
    databaseId,
    gruppetypeId,
    owned,
    showAlertComponent,
    setShowProgress,
    cleanMatvareFromReduxStore,
    cleanMatvaregruppeFromReduxStore,
    setProgressText
  } = props;

  const [activateMove, setActivateMove] = useState(false);
  const [showMoveConfirmation, setShowMoveConfirmation] = useState(false);
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const [selectedRowFrom, setSelectedRowFrom] = useState<MatvaregruppeI>();
  const [selectedRowTo, setSelectedRowTo] = useState<MatvaregruppeI>();
  const [parentsListFrom, setParentsListFrom] = useState<NavigateLevelI[]>([
    { nodeId: 0, name: 'Hovedgrupper', n: 1 }
  ]);
  const [parentsListTo, setParentsListTo] = useState<NavigateLevelI[]>([
    { nodeId: 0, name: 'Hovedgrupper', n: 1 }
  ]);
  const [reloadMatvare, setReloadMatvare] = useState(false);

  const [parentFrom, setParentFrom] = useState('');
  const [parentTo, setParentTo] = useState('');
  const [nivaaFrom, setNivaaFrom] = useState(1);
  const [linkupFrom, setLinkupFrom] = useState(0);
  const [nivaaTo, setNivaaTo] = useState(1);
  const [linkupTo, setLinkupTo] = useState(0);
  const [matvaregruppeListFrom, setMatvaregruppeListFrom] = useState<MatvaregruppeI[]>(
    []
  );
  const [matvarerListFrom, setMatvarerListFrom] = useState<Matvare[]>([]);
  const [matvaregruppeListTo, setMatvaregruppeListTo] = useState<MatvaregruppeI[]>([]);
  const [matvarerListTo, setMatvarerListTo] = useState<Matvare[]>([]);
  const [showMatvareFrom, setShowMatvareFrom] = useState(false);
  const [showMatvareTo, setShowMatvareTo] = useState(false);
  const [selectedMatvares, setSelectedMatvares] = useState<Matvare[]>([]);
  //const [selectedMatvaresCount, setSelectedMatvaresCount] = useState(0);
  const [selectedMatvaregruppes, setSelectedMatvaregruppes] = useState<MatvaregruppeI[]>(
    []
  );
  // const [selectedMatvaregruppesCount, setSelectedMatvaregruppesCount] = useState(0);

  useEffect(() => {
    console.log('selectedMatvares?.length ', selectedMatvares?.length);
    if (owned && selectedMatvares?.length > 0) {
      setActivateMove(true);
    } else {
      setActivateMove(false);
    }
  }, [
    //selectedRowFrom,
    //selectedRowTo,
    selectedMatvares?.length
    //selectedMatvaregruppes?.length
  ]);

  const handleClickFlytt = () => {
    const destinationId = getParentFromParentList('to')?.nodeId;
    const parentItem = matvaregruppeListTo?.find(
      (item: MatvaregruppeI) => item.NODENRID === destinationId
    );
    setSelectedRowTo(parentItem);
    if (!parentItem) {
      showAlertComponent('Velg ny plassering', 'error');
    } else if (parentItem?.HARMATVARE || !parentItem?.HARUNDERGRUPP) {
      if (selectedMatvares?.length) {
        setTitle('Flytte matvarer');
        setMessage(
          `Vil du flytte ${
            selectedMatvares?.length
          } valgte matvarer fra matvaregruppe ${parentFrom} til matvaregruppe ${
            parentTo || parentItem?.NAVN
          }`
        );
        setShowMoveConfirmation(true);
      } else {
        showAlertComponent('Du kan ikke flytte matvaregrupper til matvarer', 'warning');
      }
    } else {
      if (selectedMatvaregruppes?.length) {
        const from = getParentFromParentList('from')?.name;
        const destination = getParentFromParentList('to')?.name;
        setTitle('Flytte matvaregruppe');
        setMessage(
          `Vil du flytte ${selectedMatvaregruppes?.length} valgte matvaregruppe fra matvaregruppe ${from} til matvaregruppe ${destination}`
        );
        setShowMoveConfirmation(true);
      } else {
        showAlertComponent('Du kan ikke flytte matvarer til matvaregrupper ', 'warning');
      }
    }
  };
  const moveMatvare = () => {
    closeDialog();
    let newMatvarerListFrom: Matvare[] = matvarerListFrom;
    for (const sItem of selectedMatvares) {
      newMatvarerListFrom = newMatvarerListFrom?.filter(
        (item: Matvare) => item.MATVAREKODEID !== sItem.MATVAREKODEID
      );
      const found = matvarerListTo?.find(
        (item: Matvare) => item.MATVAREKODEID === sItem.MATVAREKODEID
      );
      if (!found) {
        matvarerListTo.push(sItem);
      }
    }
    setMatvarerListFrom(newMatvarerListFrom);
    setMatvarerListTo(matvarerListTo);
    setSelectedMatvares([]);
    //setSelectedMatvaresCount(0);
    const nodeIdFrom = getParentFromParentList('from')?.nodeId || 0;
    const nodeIdTo = getParentFromParentList('to')?.nodeId || 0;
    const destination = getParentFromParentList('to')?.name;
    moveMatvaregruppeAndMatvare(
      selectedMatvares,
      nodeIdFrom,
      nodeIdTo,
      destination,
      nivaaTo
    );
  };
  const moveMatvaregruppe = () => {
    closeDialog();
    let newMatvarergruppeListFrom: MatvaregruppeI[] = matvaregruppeListFrom;
    for (const sItem of selectedMatvaregruppes) {
      newMatvarergruppeListFrom = newMatvarergruppeListFrom?.filter(
        (item: MatvaregruppeI) => item.NODENRID !== sItem.NODENRID
      );
      const found = matvaregruppeListTo?.find(
        (item: MatvaregruppeI) => item.NODENRID === sItem.NODENRID
      );
      if (!found) {
        matvaregruppeListTo.push(sItem);
      }
    }
    setMatvaregruppeListFrom(newMatvarergruppeListFrom);
    setMatvaregruppeListTo(matvaregruppeListTo);
    setSelectedMatvaregruppes([]);
    //setSelectedMatvaregruppesCount(0);
  };
  const getParentFromParentList = (tableType: string) => {
    //get the parents of an item in From and To tables
    let parent: NavigateLevelI;
    if (tableType === 'from') {
      const from = parentsListFrom[parentsListFrom?.length - 1]?.name;
      parent = parentsListFrom?.find((p: NavigateLevelI) => p.name === from);
    } else if (tableType === 'to') {
      const destination = parentsListTo[parentsListTo?.length - 1]?.name;
      parent = parentsListTo?.find((p: NavigateLevelI) => p.name === destination);
    }
    return parent;
  };

  const moveMatvaregruppeAndMatvare = async (
    selectedMatvares: Matvare[],
    nodeIdFrom: number,
    nodeIdTo: number,
    name: string,
    nivaa: number
  ) => {
    let data: Matvare[] = [];
    for (const item of selectedMatvares) {
      const newData = {
        MATVAREKODEID: item.MATVAREKODEID,
        NAVN: item.NAVN || ''
      };
      data.push(newData);
    }

    const dataurl = `${databaseId}/${props.gruppetypeId}/${nodeIdFrom}/${nodeIdTo}`;
    PostData(data, 'moveMatvaregruppe', dataurl, 'POST', token).then((resp: any) => {
      if (resp.status === 200) {
        cleanReduxStoreAfterMove(nodeIdFrom, nodeIdTo);
        setLinkupTo(nodeIdTo);
        setParentTo(name);
        setNivaaTo(nivaa);
        setReloadMatvare(true);
        showAlertComponent(`Flytting er fullført`, 'success');
      } else if (resp.status === 500) {
        console.log('An error 500', resp);
        const msg =
          resp.error ||
          'Flytting mislyktes. Det har ikke blitt gjort endringer mot databasen.';
        showAlertComponent(msg, 'error');
        setShowProgress(false);
      }
    });
  };
  const cleanReduxStoreAfterMove = (nodeIdFrom: number, nodeIdTo: number) => {
    cleanMatvareFromReduxStore(nodeIdFrom, nodeIdTo);
    //get the parents of the parents in From and To tables to update them
    nodeIdFrom = getNodeIdFromParentList('from');
    nodeIdTo = getNodeIdFromParentList('to');
    cleanMatvaregruppeFromReduxStore(nodeIdFrom, nodeIdTo);
  };
  const getNodeIdFromParentList = (tableType: string) => {
    //get the parents of the parents in From and To tables
    //(length-2 gives the graandparents ofthe the list that is being displayed in the tables currently)
    let nodeId = 0;
    if (tableType === 'from') {
      const from = parentsListFrom[parentsListFrom?.length - 2]?.name;
      nodeId = parentsListFrom?.find(
        (parent: NavigateLevelI) => parent.name === from
      )?.nodeId;
    } else if (tableType === 'to') {
      const destination = parentsListTo[parentsListTo?.length - 2]?.name;
      nodeId = parentsListTo?.find(
        (parent: NavigateLevelI) => parent.name === destination
      )?.nodeId;
    }
    return nodeId;
  };
  const closeDialog = () => {
    setShowMoveConfirmation(false);
  };
  const handleClickJa = () => {
    if (selectedMatvares?.length) {
      moveMatvare();
    } else {
      moveMatvaregruppe();
    }
  };
  const handleClickNei = () => {
    closeDialog();
  };

  return (
    <div>
      {showMoveConfirmation && (
        <DialogComponent
          title={title}
          message={message}
          open={showMoveConfirmation}
          close={closeDialog}
          handleClickJa={handleClickJa}
          handleClickNei={handleClickNei}
        />
      )}
      <Grid container direction="row" justifyContent="center" alignItems="center">
        <Grid item xs={5}>
          <MatvaregruppeTableContainer
            databaseId={databaseId}
            gruppetypeId={gruppetypeId}
            owned={owned}
            selectedRow={selectedRowFrom}
            setSelectedRow={setSelectedRowFrom}
            nivaa={nivaaFrom}
            linkup={linkupFrom}
            setNivaa={setNivaaFrom}
            setLinkup={setLinkupFrom}
            parent={parentFrom}
            setParent={setParentFrom}
            parentsList={parentsListFrom}
            setParentsList={setParentsListFrom}
            matvaregruppeList={matvaregruppeListFrom}
            setMatvaregruppeList={setMatvaregruppeListFrom}
            matvarerList={matvarerListFrom}
            setMatvarerList={setMatvarerListFrom}
            move={true}
            main={false}
            setShowProgress={setShowProgress}
            showMatvare={showMatvareFrom}
            setShowMatvare={setShowMatvareFrom}
            tableType={TableType.From}
            selectedMatvares={selectedMatvares}
            setSelectedMatvares={setSelectedMatvares}
            selectedMatvaregruppes={selectedMatvaregruppes}
            setSelectedMatvaregruppes={setSelectedMatvaregruppes}
            setProgressText={setProgressText}
            /*  utvalgData={utvalgData}
            highLevelCategory={highLevelCategory} */
          ></MatvaregruppeTableContainer>
        </Grid>
        <Grid item xs={1}>
          <Grid container spacing={1} justifyContent="center" alignItems="flex-end">
            <NButton
              children="Flytt ->"
              onClick={handleClickFlytt}
              disabled={!activateMove}
            />
          </Grid>
        </Grid>
        <Grid item xs={5}>
          <MatvaregruppeTableContainer
            databaseId={databaseId}
            gruppetypeId={gruppetypeId}
            owned={owned}
            selectedRow={selectedRowTo}
            setSelectedRow={setSelectedRowTo}
            nivaa={nivaaTo}
            linkup={linkupTo}
            setNivaa={setNivaaTo}
            setLinkup={setLinkupTo}
            parent={parentTo}
            setParent={setParentTo}
            parentsList={parentsListTo}
            setParentsList={setParentsListTo}
            matvaregruppeList={matvaregruppeListTo}
            setMatvaregruppeList={setMatvaregruppeListTo}
            matvarerList={matvarerListTo}
            setMatvarerList={setMatvarerListTo}
            move={true}
            main={false}
            setShowProgress={setShowProgress}
            showMatvare={showMatvareTo}
            setShowMatvare={setShowMatvareTo}
            tableType={TableType.To}
            reloadMatvare={reloadMatvare}
            setReloadMatvare={setReloadMatvare}
            setProgressText={setProgressText}
            /*  utvalgData={utvalgData}
            highLevelCategory={highLevelCategory} */
          ></MatvaregruppeTableContainer>
        </Grid>
      </Grid>
    </div>
  );
}
