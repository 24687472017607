import React from 'react';
import XLSX from 'xlsx';
import { MatvareAndOppskriftI } from '../../RapportTypes';
import { NButton } from '../../../components/Inputs';

import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Grid } from '@mui/material';
import { useUserContext } from '../../../../../context/AuthContext';

const ExcelRapport = ({
  data,
  matvaredb
}: {
  data: MatvareAndOppskriftI[];
  matvaredb: string;
}) => {
  const exportToExcel = (user: string, info: MatvareAndOppskriftI[], db: string) => {
    const workbook = XLSX.utils.book_new();

    const dbsheet = XLSX.utils.aoa_to_sheet([['Matvaredatabase', db]]);
    XLSX.utils.book_append_sheet(workbook, dbsheet, 'Matvaredatabase');

    info.map((matvare) => {
      const sheet = XLSX.utils.aoa_to_sheet([
        ['Kode', matvare.matvarekodeid],
        ['Navn', matvare.navn],
        ['Vektendring', matvare.vektendring && `${matvare.vektendring}%`],
        [],
        ['Kode (ingrediens)', 'Navn (ingrediens)', 'Gram'],
        ...matvare.ingredienser.map((ingrediens) => [
          ingrediens.ingrediensid,
          ingrediens.navn,
          ingrediens.mengde
        ])
      ]);
      XLSX.utils.book_append_sheet(workbook, sheet, matvare.matvarekodeid.toString());
    });

    XLSX.writeFile(workbook, `${user}-matvareoppskrifter.xlsx`);
  };
  const loggedInuser = useUserContext();
  const username = loggedInuser?.user?.username.toLowerCase();

  return (
    <Grid item xs={6}>
      <NButton
        leftIcon={<FileDownloadOutlinedIcon />}
        children="Last ned xlsx"
        onClick={() => exportToExcel(username, data, matvaredb)}
        fullWidth
      />
    </Grid>
  );
};

export default ExcelRapport;
