import React, { useState } from 'react';
import {
  Radio,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Divider,
  Grid,
  Stack
} from '@mui/material';
import { UtvalgTableComponent } from './BeregningUtvalg';
import { InputLabel } from '@mui/material';
import {
  BeregningObject,
  DefaultBeregningCounts,
  UtvalgTableData
} from '../types/BeregningTypes';
import { NButton } from '../../components/Inputs';
import VisibilityIcon from '@mui/icons-material/Visibility';
interface InformationMenuProps {
  personDivisorvalue: string;
  dagDivisorvalue: string;
  handleChangePersondivisor: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeDagdivisor: (event: React.ChangeEvent<HTMLInputElement>) => void;
  showAlertMessage: (message: string, severity: any) => void;
  classes: any;
  beregningObject: BeregningObject;
  setBeregningObject: (obj: BeregningObject) => void;
  totalCounts: DefaultBeregningCounts;
  setTotalCounts: (value: DefaultBeregningCounts) => void;
  utvalgData: UtvalgTableData;
  setUtvalgData: (value: UtvalgTableData) => void;
  cleared: boolean;
  setShowProgress: (value: boolean) => void;
  setProgressText: (value: string) => void;
}
const InformationMenu = (props: InformationMenuProps) => {
  const {
    handleChangePersondivisor,
    handleChangeDagdivisor,
    showAlertMessage,
    classes,
    beregningObject,
    setBeregningObject,
    totalCounts,
    setTotalCounts,
    utvalgData,
    setUtvalgData,
    cleared,
    setShowProgress,
    setProgressText
  } = props;
  const [showBeregningsUtvalg, setShowBeregningsUtvalg] = useState(false);

  React.useEffect(() => {
    if (cleared) {
      beregningObject.kostholdData.persondivisor = 'A';
      beregningObject.kostholdData.dagdivisor = 'A';
      beregningObject.numberOfPersons = 0;
      //cleared ? setMatvaregruppeCount(0) : setMatvaregruppeCount(totalCounts?.TOTALMATVAREGRUPPE);
    }
  }, [cleared]);
  return (
    <>
      <Grid container item justifyContent={'center'} alignContent={'flex-end'}>
        <InputLabel>Persondivisor</InputLabel>
        <FormControl
          variant="outlined"
          component="fieldset"
          disabled={
            !(
              beregningObject?.matvareData?.rapport?.value === 0 ||
              beregningObject?.matvareData?.rapport?.value === 2 ||
              beregningObject?.matvareData?.rapport?.value === 4 ||
              beregningObject?.matvareData?.rapport?.value === 6 ||
              beregningObject?.matvareData?.rapport?.value === 8
            )
          }
        >
          <RadioGroup
            row
            aria-label="position"
            name="personDivisor"
            defaultValue="A"
            value={beregningObject?.kostholdData?.persondivisor}
            onChange={handleChangePersondivisor}
          >
            <FormControlLabel
              value={'A'}
              control={<Radio color="primary" size="small" />}
              label="Alle"
              labelPlacement="bottom"
              disabled={beregningObject?.kostholdData?.surveyId?.value === 0}
            />
            <FormControlLabel
              value={'B'}
              control={<Radio color="primary" size="small" />}
              label="Etter konsumfilter"
              labelPlacement="bottom"
              disabled={beregningObject?.kostholdData?.surveyId?.value === 0}
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Divider sx={classes.divider} />
      <Grid container item justifyContent={'center'} alignContent={'center'}>
        <InputLabel>Dagdivisor</InputLabel>
        <FormControl variant="outlined" component="fieldset">
          <RadioGroup
            row
            aria-label="position"
            name="dagDivisor"
            defaultValue="A"
            value={beregningObject?.kostholdData?.dagdivisor}
            onChange={handleChangeDagdivisor}
          >
            <FormControlLabel
              value={'A'}
              control={<Radio color="primary" size="small" />}
              label="Alle"
              labelPlacement="bottom"
              disabled={beregningObject?.kostholdData?.surveyId?.value === 0}
            />
            <FormControlLabel
              value={'B'}
              control={<Radio color="primary" size="small" />}
              label="Etter konsumfilter"
              labelPlacement="bottom"
              disabled={beregningObject?.kostholdData?.surveyId?.value === 0}
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid sx={{ paddingTop: 2 }}>
        <NButton
          children="Beregningsutvalg"
          leftIcon={<VisibilityIcon />}
          onClick={() => {
            setShowBeregningsUtvalg(!showBeregningsUtvalg);
            setBeregningObject(beregningObject);
          }}
          disabled={beregningObject?.matvareData?.rapport?.value === 0}
        />

        {showBeregningsUtvalg && (
          <UtvalgTableComponent
            beregningObject={beregningObject}
            setBeregningObject={setBeregningObject}
            showAlertMessage={showAlertMessage}
            isBeregning={true}
            totalCounts={totalCounts}
            setTotalCounts={setTotalCounts}
            utvalgData={utvalgData}
            setUtvalgData={setUtvalgData}
            setShowProgress={setShowProgress}
            setProgressText={setProgressText}
            hide={() => {
              setShowBeregningsUtvalg(!showBeregningsUtvalg);
            }}
          />
        )}
      </Grid>
      <Grid
        container
        item
        xs={12}
        spacing={2}
        justifyContent={'center'}
        alignContent={'flex-end'}
      >
        <Stack direction="column" spacing={1}>
          <InputLabel>Antall</InputLabel>
          <InputLabel>Personer: {beregningObject?.numberOfPersons || 0}</InputLabel>
          <InputLabel>Matvaregrupper: {totalCounts?.TOTALMATVAREGRUPPE || 0}</InputLabel>
          <InputLabel>Matvarer: {totalCounts?.TOTALMATVARE || 0}</InputLabel>
          <InputLabel>Stoffer: {totalCounts?.TOTALSTOFFCOUNT || 0}</InputLabel>
          <InputLabel>
            Fjernede matvarer: {totalCounts?.TOTALFJERNDEMATVARE || 0}
          </InputLabel>
        </Stack>
      </Grid>
    </>
  );
};
export default InformationMenu;
