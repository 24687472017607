import React, { ChangeEvent, Dispatch, SetStateAction, useEffect } from 'react';
import { Stack } from '@mui/system';
import { LopedagI, MaaltidI } from './types/PersonTypes';
import { Typography } from '@mui/material';
import { NButton } from '../components/Inputs';
import LopedagMaaltiderTable from './LopedagMaaltiderTable';
import AddIcon from '@mui/icons-material/Add';
import { CodeRegisterI } from '../components/commons/CommonTypes';
import StyledTextarea from '../components/StyledTextarea';

interface MaaltiderProps {
  owned: boolean;
  lopedagData: LopedagI;
  setLopedagData: Dispatch<SetStateAction<LopedagI>>;
  maaltidsData: MaaltidI[];
  maaltidNotat: string;
  setMaaltidNotat: Dispatch<SetStateAction<string>>;
  setMaaltidNotatCopy: Dispatch<SetStateAction<string>>;
  maaltidTypes: CodeRegisterI[];
  currentMaaltidId: number;
  setCurrentMaaltidId: Dispatch<SetStateAction<number>>;
  setShowMaaltid: Dispatch<SetStateAction<boolean>>;
  setFromExisting: Dispatch<SetStateAction<boolean>>;
  setShowKopi: (val: boolean) => void;
}

export default function LopedagMaaltider(props: MaaltiderProps) {
  const {
    owned,
    lopedagData,
    setLopedagData,
    maaltidsData,
    maaltidNotat,
    setMaaltidNotat,
    setMaaltidNotatCopy,
    maaltidTypes,
    currentMaaltidId,
    setCurrentMaaltidId,
    setShowMaaltid,
    setFromExisting,
    setShowKopi
  } = props;

  useEffect(() => {
    const parsedNotat = maaltidsData
      ?.find((i) => i.maaltidsId === currentMaaltidId)
      ?.notat?.split('\n')
      .filter((n) => !!n)
      .join('\n');
    setMaaltidNotat(parsedNotat);
    setMaaltidNotatCopy(parsedNotat);
  }, [maaltidsData, currentMaaltidId]);

  const handleNyttMaaltid = () => {
    setShowMaaltid(true);
    setFromExisting(false);
    setCurrentMaaltidId(
      lopedagData?.maaltider?.length
        ? Math.max(...lopedagData?.maaltider?.map((m) => m.maaltidsId)) + 1
        : 1
    );
  };

  return (
    <Stack direction={'column'} spacing={2} mt={'2rem'}>
      <Typography variant="h6" gutterBottom>
        Måltider:
      </Typography>
      <Stack direction={'row'} spacing={2}>
        <LopedagMaaltiderTable
          owned={owned}
          maaltidsData={maaltidsData}
          maaltidTypes={maaltidTypes}
          currentMaaltidId={currentMaaltidId}
          setCurrentMaaltidId={setCurrentMaaltidId}
          setShowMaaltid={setShowMaaltid}
          setFromExisting={setFromExisting}
          setShowKopi={setShowKopi}
        />
        <Stack
          spacing={1}
          sx={{
            alignItems: 'end'
          }}
        >
          <NButton
            children="Nytt måltid"
            onClick={handleNyttMaaltid}
            leftIcon={<AddIcon />}
            variant="outlined"
            disabled={!(lopedagData?.lopedagId && owned)}
          />
          <StyledTextarea
            owned={owned}
            name="maaltidnotat"
            value={maaltidNotat}
            onChange={(event) => setMaaltidNotat(event.target.value)}
            disabled={!currentMaaltidId}
          />
          {/* <MeldedInputs
            owned={owned}
            onChange={() => {}}
            maaltidsData={maaltidsData}
            currentMaaltidId={currentMaaltidId}
            prosjektId={prosjektId}
            undersokelseId={undersokelseId}
            personId={lopedagData?.personId}
            lopedagId={lopedagData?.lopedagId}
            callAlert={callAlert}
            setRefetchLopedag={setRefetchLopedag}
          /> */}
        </Stack>
      </Stack>
      {/* <FullHeightTextField
        name="maaltid"
        value={maaltidNotat ? maaltidNotat : '[Notat ikke funnet]'}
        onChange={(event) => handleChangeNotat(event, currentMaaltidId)}
        sx={{ minWidth: { xs: '12rem', lg: '20rem' } }}
        owned={owned}
      /> */}
    </Stack>
  );
}
