import React from 'react';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TableHead
} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { UndersokelseListData } from '../types/UndersokelseTypes';

export const UndersokelseKortnavnList = (props: any) => {
  const [selectedRow, setSelectedRow] = React.useState(null);
  const [radioButtonSelect, setRadioButtonSelect] = React.useState('');
  const { classesTable, subProjectList, populateData, searchUndersokelseKortnavn } =
    props;
  const filteredUndersokelseKortnavn = subProjectList?.data
    ? subProjectList?.data?.filter((row: UndersokelseListData) => {
        return row.KORTNAVN?.toLowerCase().includes(
          searchUndersokelseKortnavn?.toLowerCase()
        );
      })
    : null;

  if (subProjectList?.data === null || filteredUndersokelseKortnavn === null) {
    return null;
  }
  return (
    <div>
      <Table sx={classesTable.body}>
        <TableHead sx={classesTable.head}>
          <TableRow>
            <TableCell sx={classesTable.head}>Velg</TableCell>
            <TableCell sx={classesTable.head}>Kortnavn</TableCell>
            <TableCell sx={classesTable.head}>Nr.</TableCell>
            <TableCell sx={classesTable.head}>Leder</TableCell>
            <TableCell sx={classesTable.head}>Oprettet</TableCell>
            <TableCell sx={classesTable.head}>Endret</TableCell>
            <TableCell sx={classesTable.head}>Matvaredatabase</TableCell>
            <TableCell sx={classesTable.head}>Antall Pers.</TableCell>
          </TableRow>
        </TableHead>
        <TableBody sx={classesTable.root}>
          {filteredUndersokelseKortnavn?.map((row: UndersokelseListData) => {
            const highlighted =
              selectedRow && selectedRow.UNDERSOKELSEID === row.UNDERSOKELSEID;
            return (
              <TableRow
                id="dialogTable"
                hover={true}
                key={row.UNDERSOKELSEID}
                onClick={() => {
                  setSelectedRow(highlighted ? null : row);
                  populateData(row.UNDERSOKELSEID, row.KORTNAVN);
                }}
                style={{
                  backgroundColor: highlighted ? 'lightgray' : 'white'
                }}
                sx={classesTable.tableRow}
              >
                <TableCell sx={classesTable.tableRow}>
                  <FormControl component="fieldset">
                    <RadioGroup
                      key={row.UNDERSOKELSEID}
                      value={radioButtonSelect}
                      onChange={() => {
                        setRadioButtonSelect(row.UNDERSOKELSEID?.toString());
                        props.navigateToUndersokelse(row.UNDERSOKELSEID);
                      }}
                    >
                      <FormControlLabel
                        value={row.UNDERSOKELSEID}
                        control={<Radio size="small" />}
                        label=""
                      />
                    </RadioGroup>
                  </FormControl>
                </TableCell>
                <TableCell sx={classesTable.tableRow}>{row.KORTNAVN}</TableCell>
                <TableCell sx={classesTable.tableRow}>{row.UNDERSOKELSEID}</TableCell>
                <TableCell sx={classesTable.tableRow}>{row.UNDERSOKELSELEDER}</TableCell>
                <TableCell sx={classesTable.tableRow}>{row.OPPRETTET}</TableCell>
                <TableCell sx={classesTable.tableRow}>{row.ENDRET}</TableCell>
                <TableCell sx={classesTable.tableRow}>{row.NAVN}</TableCell>
                <TableCell sx={classesTable.tableRow}>{row.COUNT}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
};
