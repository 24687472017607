import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
interface ProgressProps {
  text: string;
}
function ProgressWithLabel(props: ProgressProps) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '70%', mr: 2, padding: 2 }}>
        <LinearProgress />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">
          {' '}
          {props.text}
        </Typography>
      </Box>
    </Box>
  );
}

export default ProgressWithLabel;
