import {
  Paper,
  Radio,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import React, { Dispatch, SetStateAction, useState } from 'react';
import DialogGeneral from '../../../../utilities/DialogGeneral';
import { HeadCell } from '../../../components/commons/CommonTypes';
import { NButton } from '../../../components/Inputs';
import { PersonI } from '../../types/PersonTypes';
import { TilleggsvariableSvarI } from '../../types/TilleggsvariableTypes';

const headCells: readonly HeadCell<TilleggsvariableSvarI>[] = [
  {
    id: 'kode',
    align: 'right',
    padding: 'normal',
    label: 'Kode'
  },
  {
    id: 'beskrivelse',
    align: 'left',
    padding: 'normal',
    label: 'Beskrivelse'
  }
];

interface PersonSvarModalProps {
  //   setPerson: (id: number) => void;
  personData: PersonI;
  setPersonData: Dispatch<SetStateAction<PersonI>>;
  svarKategorier: TilleggsvariableSvarI[] | null;
  variabelId: number;
  kortnavn: string;
  open: boolean;
  hide: () => void;
}

export default function PersonSvarModal(props: PersonSvarModalProps) {
  const { personData, setPersonData, svarKategorier, variabelId, kortnavn, open, hide } =
    props;

  const [svarKode, setSvarKode] = useState<number>(null);

  function handleSubmit() {
    const newAnswers = personData?.tilleggssvar?.map((svar) => {
      if (svar.variabelId === variabelId) {
        return {
          ...svar,
          svar: +svarKode,
          kategori: svarKategorier.find((svar) => svar.kode === +svarKode)?.beskrivelse
        };
      }
      return svar;
    });
    setPersonData({ ...personData, tilleggssvar: newAnswers });

    hide();
  }

  function handleAbort() {
    setSvarKode(null);
    hide();
  }

  return (
    <DialogGeneral
      title="Liste over lovlige svarkategorier"
      open={open}
      hide={hide}
      fullWidth
      maxWidth="xs"
    >
      <Stack spacing={2}>
        <Typography variant="h6">Bakgrunnsvariabel: {kortnavn && kortnavn} </Typography>

        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">Velg</TableCell>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    align={headCell.align}
                    padding={headCell.padding}
                  >
                    {headCell.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {svarKategorier?.map((row, index) => (
                <TableRow key={row.kode} onClick={() => setSvarKode(row.kode)} hover>
                  <TableCell padding="checkbox">
                    <Radio
                      checked={svarKode === row.kode}
                      inputProps={{
                        'aria-labelledby': 'table-checkbox-' + index
                      }}
                    />
                  </TableCell>
                  {headCells.map((cell) => (
                    <TableCell key={cell.id} align={cell.align} padding={cell.padding}>
                      {row[cell.id]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Stack direction={'row'} justifyContent="space-between">
          <NButton children={'OK'} onClick={handleSubmit} />
          <NButton
            children={'Avbryt'}
            disabled={svarKode === null}
            onClick={handleAbort}
          />
        </Stack>
      </Stack>
    </DialogGeneral>
  );
}
