import React, { ReactNode } from 'react';
import useFetchData from '../../utilities/useFetchData';
import { Checkbox, Grid } from '@mui/material';
import moment from 'moment';
import * as XLSX from 'xlsx';
import { DatabaseContext } from '../../../context/DatabaseContext';
import { Alert, FormControlLabel } from '@mui/material';
import { NButton } from '../components/Inputs';
import { DialogBox } from '../../utilities/DialogBox';
import { useUserContext } from '../../../context/AuthContext';
import { AlertI } from '../../../types/alertTypes';
interface Description {
  label: string;
  id: string;
  sqlId: string;
}
interface DetailsToExcelProps {
  header: ReactNode;
  projectId: string;
  undersokelseId: string;
  owned: boolean;
  showing: boolean;
  showAlertComponent: (a: string, b: AlertI['alertColor']) => void;
  setProgressBar: (a: boolean) => void;
  hide: () => void;
}
export default function ExportDetailsToExcel(props: DetailsToExcelProps) {
  const details = [
    {
      id: 0,
      person: { label: 'PersonId', id: 'PERSONID', sqlId: 'p.personid' },
      moltid: { label: 'Løpedag', id: 'LOPEDAGID', sqlId: 'l.lopedagid' },
      detail: { label: 'Sortering', id: 'SORTERING', sqlId: 'k.sortering' }
    },
    {
      id: 1,
      person: { label: 'Initialer', id: 'IDENTITET', sqlId: 'p.identitet' },
      moltid: { label: 'Ukedag', id: 'UKEDAG', sqlId: 'l.ukedag' },
      detail: { label: 'Engelsk navn', id: 'NAVN_ENGELSK', sqlId: 'm.navn_engelsk' }
    },
    {
      id: 2,
      person: { label: 'Kjønn', id: 'KJØNN', sqlId: 'kk.navn kjønn' },
      moltid: { label: 'Vanlig dag', id: 'VANLIGDAG', sqlId: 'l.vanligdag' },
      detail: { label: 'Mengde', id: 'MENGDE', sqlId: 'k.mengde' }
    },
    {
      id: 3,
      person: { label: 'Alder', id: 'ALDER', sqlId: 'p.alder' },
      moltid: { label: 'Måltidnr', id: 'MAALTIDNRID', sqlId: 'mt.maaltidnrid' },
      detail: { label: 'Antall', id: 'ANTALL', sqlId: 'k.mengde / f.mengde antall' }
    },
    {
      id: 4,
      person: { label: 'Høyde', id: 'HOYDE', sqlId: 'p.hoyde' },
      moltid: { label: 'Klokkeslett', id: 'KLOKKESLETT', sqlId: 'mt.klokkeslett' },
      detail: { label: 'Mengdeenhet', id: 'MENGDEENHET', sqlId: 'k.mengdeenhet' }
    },
    {
      id: 5,
      person: { label: 'Vekt', id: 'VEKT', sqlId: 'p.vekt' },
      moltid: { label: 'Måltidstype', id: 'MÅLTIDSTYPE', sqlId: 'mk.navn måltidstype' },
      detail: {
        label: 'Kommentar',
        id: 'KOMMENTAR',
        sqlId: "nvl(k.kommentar,'#¤#') kommentar"
      }
    },
    {
      id: 6,
      person: { label: 'BMI', id: 'BMI', sqlId: 'p.bmi' },
      moltid: { label: 'Spisested', id: 'SPISESTED', sqlId: 'sk.navn spisested' },
      detail: {
        label: 'Ikke standardkommentarer',
        id: 'MATVARE_KOMMENTAR',
        sqlId: "nvl(m.kommentar,'#¤#') matvare_kommentar"
      }
    }
  ];
  const initialState = {
    PERSONID: false,
    LOPEDAGID: false,
    SORTERING: false,
    IDENTITET: false,
    UKEDAG: false,
    NAVN_ENGELSK: false,
    KJØNN: false,
    VANLIGDAG: false,
    MENGDE: false,
    ALDER: false,
    MAALTIDNRID: false,
    ANTALL: false,
    HOYDE: false,
    KLOKKESLETT: false,
    VEKT: false,
    MÅLTIDSTYPE: false,
    KOMMENTAR: false,
    BMI: false,
    SPISESTED: false,
    MATVARE_KOMMENTAR: false
  };
  const [state, setState] = React.useState(initialState);
  const { header, owned, projectId, undersokelseId, showAlertComponent, setProgressBar } =
    props;
  const [UtvalgButn, setUtvalgButn] = React.useState(false);
  const loggedInuser = useUserContext();
  const currentUser = loggedInuser?.user?.username;
  const { currentDatabase } = React.useContext(DatabaseContext);
  const [sqlVariabels] = React.useState([]);
  const [selectedItems, setSelectedItems] = React.useState<Description[]>([
    { label: 'Matvare', id: 'MATVAREKODEID', sqlId: '' },
    { label: 'Navn', id: 'NAVN', sqlId: '' }
  ]);
  const [info, setInfo] = React.useState([
    ['Intervjudetaljer for undersøkelse'],
    ['Matvaredatabase: ' + currentDatabase['label']],
    ['Dato: ' + moment().format('DD-MM-YYYY hh:mm')],
    ['Prosjekt: ' + projectId],
    ['Undersøkelse: ' + undersokelseId]
  ]);
  const getDetailsToBeExported = useFetchData({
    loadOnMount: false,
    clearDataOnLoad: false,
    projectURL: 'getDetailsToBeExported',
    subProjectURL: `${projectId}/${undersokelseId}/${sqlVariabels}`
  });

  React.useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed && UtvalgButn) {
      loadDetailsToBeExported();
    }
    return () => {
      isSubscribed = false;
    };
  }, [UtvalgButn]);

  const loadDetailsToBeExported = () => {
    if (projectId && undersokelseId) {
      const variabels = JSON.stringify(sqlVariabels);
      getDetailsToBeExported.loadData(
        'getDetailsToBeExported',
        `${projectId}/${undersokelseId}/${variabels}`
      );
    }
  };

  const setVariables = () => {
    setState(initialState);
    setUtvalgButn(false);
    setSelectedItems([
      { label: 'Matvare', id: 'MATVAREKODEID', sqlId: '' },
      { label: 'Navn', id: 'NAVN', sqlId: '' }
    ]);
    setInfo([
      ['Intervjudetaljer for undersøkelse'],
      ['Matvaredatabase: ' + currentDatabase['label']],
      ['Dato: ' + moment().format('DD-MM-YYYY hh:mm')],
      ['Prosjekt: ' + projectId],
      ['Undersøkelse: ' + undersokelseId]
    ]);
  };

  React.useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      if (getDetailsToBeExported.data) {
        if (getDetailsToBeExported.data?.length > 0) {
          //setDataToBeExported(getDetailsToBeExported.data);
          prepareExport(getDetailsToBeExported.data);
        } else {
          setProgressBar(false);
          // showAlertComponent('Ingen data til å eksportere.', 'error');
        }
      }
    }
    return () => {
      isSubscribed = false;
    };
  }, [getDetailsToBeExported.data]);

  React.useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      if (getDetailsToBeExported.error) {
        setProgressBar(false);
        showAlertComponent(
          'Det har oppstått en feil. Vennligst prøv igjen senere.',
          'error'
        );
      }
    }
    return () => {
      isSubscribed = false;
    };
  }, [getDetailsToBeExported.error]);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    item: Description
  ) => {
    setState({ ...state, [event.target.name]: event.target.checked });
    if (selectedItems.length === 0) {
      selectedItems.push(item);
      sqlVariabels.push(item.sqlId);
    } else {
      const removeIndex = selectedItems
        ?.map((row) => {
          return row.id;
        })
        .indexOf(item.id);
      if (removeIndex === -1) {
        selectedItems.push(item);
        sqlVariabels.push(item.sqlId);
      } else {
        selectedItems.splice(removeIndex, 1);
        //sqlVariabels.splice(removeIndex, 1);
      }
    }
  };

  const handleSelectGroup = (group: string) => {
    const descriptions = Object.fromEntries(
      details.map((d) => [d[group].label, d[group]])
    );
    setState({ ...state, ...{ descriptions } });

    Object.values(descriptions).map((d) => {
      const item = d as Description;
      const prevIndex = selectedItems?.findIndex((i) => i.id === item.id);

      if (prevIndex === -1) {
        selectedItems.push(item);
        sqlVariabels.push(item.sqlId);
      } else {
        selectedItems.splice(prevIndex, 1);
        sqlVariabels.splice(prevIndex, 1);
      }
    });
  };

  const visUtvalg = () => {
    if (undersokelseId) {
      setProgressBar(true);
      setUtvalgButn(true);
      //prepareExport();
    }
  };

  const prepareExport = (dataToBeExported: any[]) => {
    let data: any[] = [];
    let labels = [];
    for (let item of selectedItems) {
      labels.push(item.label);
    }
    data.push(labels);
    for (let element of dataToBeExported) {
      let result = [];
      for (let item of selectedItems) {
        result.push(element[item.id]);
      }
      data.push(result);
    }
    if (data?.length) {
      console.log('**************Result is ready to be printed out***********');
      exportToExcel(currentUser, info, data);
      setProgressBar(false);
      setVariables();
    }
  };

  const exportToExcel = (user: string, info: any[], data: any[]) => {
    const worksheet = XLSX.utils.aoa_to_sheet(info);
    XLSX.utils.sheet_add_aoa(worksheet, data, { origin: -1 });
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'SheetJS');
    XLSX.writeFile(workbook, user + '.xlsx');
  };

  return (
    <DialogBox
      title="Detaljer til excel"
      isShowing={props.showing}
      hide={props.hide}
      size="lg"
    >
      <Grid item xs={12} container>
        <Grid item xs={8} container spacing={1}>
          {header}
        </Grid>
        <Grid item xs={6} />
        <Grid item xs={12}>
          <Alert severity="info" sx={{ marginTop: '1em' }}>
            Velg hvilke detaljer som skal være med og trykk "Vis utvalg" knappen nederst.
            Knappen blir aktiv når minst en er avkrysset.
          </Alert>
        </Grid>
        <Grid item xs={10} container>
          <Grid item xs={6} container alignItems="center">
            <Grid item xs={4}>
              <h2>Person</h2>
            </Grid>
            <Grid item xs={8}>
              <FormControlLabel
                label="Velg alle"
                control={<Checkbox onChange={() => handleSelectGroup('person')} />}
              />
            </Grid>
          </Grid>
          <Grid item xs={8} />
          <Grid item xs={12} container>
            {details.map((d) => (
              <Grid item xs={3}>
                <FormControlLabel
                  label={d.person.label}
                  control={
                    <Checkbox
                      onChange={(e) => handleChange(e, d.person)}
                      checked={Boolean(selectedItems.find((s) => s.id === d.person.id))}
                    />
                  }
                />
              </Grid>
            ))}
          </Grid>
          <Grid item xs={6} container alignItems="center">
            <Grid item xs={4}>
              <h2>Måltid</h2>
            </Grid>
            <Grid item xs={8}>
              <FormControlLabel
                label="Velg alle"
                control={<Checkbox />}
                onChange={() => handleSelectGroup('moltid')}
              />
            </Grid>
          </Grid>
          <Grid item xs={8} />
          <Grid item xs={12} container>
            {details.map((d) => (
              <Grid item xs={3}>
                <FormControlLabel
                  label={d.moltid.label}
                  control={
                    <Checkbox
                      onChange={(e) => handleChange(e, d.moltid)}
                      checked={Boolean(selectedItems.find((s) => s.id === d.moltid.id))}
                    />
                  }
                />
              </Grid>
            ))}
          </Grid>
          <Grid item xs={6} container alignItems="center">
            <Grid item xs={4}>
              <h2>Detalj</h2>
            </Grid>
            <Grid item xs={8}>
              <FormControlLabel
                label="Velg alle"
                control={<Checkbox onChange={() => handleSelectGroup('detail')} />}
              />
            </Grid>
          </Grid>
          <Grid item xs={8} />
          <Grid item xs={12} container>
            {details.map((d) => (
              <Grid item xs={3}>
                <FormControlLabel
                  label={d.detail.label}
                  control={
                    <Checkbox
                      onChange={(e) => handleChange(e, d.detail)}
                      checked={Boolean(selectedItems.find((s) => s.id === d.detail.id))}
                    />
                  }
                />
              </Grid>
            ))}
          </Grid>
          <NButton children="Vis utvalg" onClick={() => visUtvalg()} />
        </Grid>
      </Grid>
    </DialogBox>
  );
}
