import React from 'react';
import { BeregningObject, ReportOneI } from '../../types/BeregningTypes';
import useDidMountEffect from '../../../../utilities/useDidMountEffect';

interface ReportOneProps {
  beregningObject: BeregningObject;
  setReportData: (value: any[]) => void;
  setReportInfo: (value: Array<Array<string>>) => void;
  setErrorReportId: (value: number) => void;
  result: any;
}
export const Report1Result = (props: ReportOneProps) => {
  const [data] = React.useState([]);
  const [info] = React.useState<Array<Array<string>>>([]);

  //TODO: make sure the db is not changed after beregn button is presssed and Doublecheck the default mgtype
  //TODO: find a way to validate the parameters
  const { beregningObject, setReportData, setReportInfo, setErrorReportId, result } =
    props;
  useDidMountEffect(() => {
    if (result) {
      const data = result as unknown as ReportOneI;
      if (
        data?.reportInfo &&
        data?.reportInfo?.beregningInfo &&
        data?.matvares &&
        data?.persons &&
        data?.personsAndMengdes
      ) {
        prepareResult(data);
      }
    }
  }, [result]);

  const prepareResult = (reportOnedata: ReportOneI) => {
    let description: string[];
    setErrorReportId(reportOnedata?.reportInfo?.reportId);
    for (let r of reportOnedata?.reportInfo?.beregningInfo) {
      description = [];
      description.push(r.TEKSTLINJE);
      info.push(description);
    }
    setReportInfo(info);

    let result: any[] = [];
    const shortNames: string[] = [];
    const names: string[] = [];
    const enames: string[] = [];
    const enhet: string[] = [];
    const headers = ['Nr', 'Id'];
    for (let h of headers) {
      shortNames.push(h);
      names.push('');
      enames.push('');
      enhet.push('');
    }

    for (let mat of reportOnedata?.matvares) {
      shortNames.push(mat.KORTNAVN);
      names.push(mat.NAVN);
      enames.push(mat.NAVN_ENGELSK);
      enhet.push('g');
    }
    data.push(shortNames);
    data.push(names);
    if (beregningObject?.matvareData?.bregningCheckboxes?.engelskNavn) {
      data.push(enames);
    }
    data.push(enhet);

    for (let person of reportOnedata?.persons) {
      result = [];
      result.push(person.PERSONID);
      result.push(person.IDENTITET || null);
      for (let m of reportOnedata?.personsAndMengdes) {
        if (person.PERSONID === m.PERSONID) {
          result.push(m.MENGDE);
        }
      }
      data.push(result);
    }
    setReportData(data);
  };
  return <></>;
};
