import React, { useState, useEffect } from 'react';
import useFetchData from '../../utilities/useFetchData';
import { useKeycloak } from '@react-keycloak/web';
import { AlertColor } from '@mui/material';
import { MatvareIUndersokelseI, MatvareKodenNavnI } from './types/UndersokelseTypes';
import MatvareSearch from '../matvare/MatvareSearch';
import { CountResult } from './types/ProjectTypes';
import useDidMountEffect from '../../utilities/useDidMountEffect';
import DialogComponent from '../../utilities/DialogComponent';

interface ByttMatvareProps {
  projectId: number;
  undersokelseId: number;
  matvareToChange: MatvareKodenNavnI;
  data?: MatvareIUndersokelseI[];
  singleOccurrence?: boolean;
  personId?: number;
  lopedagId?: number;
  maaltidId?: number;
  ordrnrId?: number;
  showAlertComponent: (message: string, severity: AlertColor) => void;
  reloadData?: () => void;
  setBytt: (val: boolean) => void;
}

export default function ByttMatvare(props: ByttMatvareProps) {
  const {
    projectId,
    undersokelseId,
    data,
    matvareToChange,
    singleOccurrence,
    personId,
    lopedagId,
    maaltidId,
    ordrnrId,
    showAlertComponent,
    reloadData,
    setBytt
  } = props;
  const { keycloak } = useKeycloak();
  const token = keycloak?.token;
  const backendURI = process.env.REACT_APP_BACKEND;
  const [, setMatvareFromUtbyttet] = useState(0);
  const [showMatvareKode, setShowMatvareKode] = useState(true);
  const [newMatvare, setNewMatvare] = useState<MatvareKodenNavnI>({
    kode: 0,
    navn: ''
  });
  const [showCDialog, setShowCDialog] = useState(false);
  const [showKommentarDialog, setShowKommentarDialog] = useState(false);
  const [dialogText, setDialogText] = useState('');
  const [dialogTitle, setDialogTitle] = useState('');
  const [showProgress, setShowProgress] = useState(false);

  const getUtbyttetCount = useFetchData({
    loadOnMount: false,
    clearDataOnLoad: false,
    projectURL: 'checkIfMatvareHasUtbyttet',
    subProjectURL: `${projectId}/${undersokelseId}/${matvareToChange?.kode}`
  });
  useEffect(() => {
    setShowMatvareKode(true);
  }, []);

  useDidMountEffect(() => {
    const data: CountResult = getUtbyttetCount.data as unknown as CountResult;
    checkUtbyttetCount(data?.COUNT);
  }, [getUtbyttetCount.data]);

  useDidMountEffect(() => {
    if (getUtbyttetCount.error) {
      showAlertComponent(
        'Det har oppstått en feil. Vennligst prøv igjen senere.',
        'error'
      );
    }
  }, [getUtbyttetCount.error]);

  useEffect(() => {
    setShowProgress(true);
    if (singleOccurrence) {
      fetchUtbyttetCount();
    } else {
      checkExistingMatvare();
    }
  }, [newMatvare]);
  const checkExistingMatvare = () => {
    let found = data?.find(
      (el: MatvareIUndersokelseI) => el.matvarekode === newMatvare.kode
    );
    if (found === undefined) {
      fetchUtbyttetCount();
    } else {
      setShowProgress(false);
      showAlertComponent(
        'Matvare ' +
          newMatvare.kode +
          ' benyttet i samme måltid som ' +
          matvareToChange?.kode +
          ' (Utbyttingen må utføres manuelt)',
        'warning'
      );
      setBytt(false);
    }
  };
  const fetchUtbyttetCount = () => {
    if (matvareToChange?.kode) {
      if (projectId && undersokelseId) {
        getUtbyttetCount.loadData(
          'checkIfMatvareHasUtbyttet',
          `${projectId}/${undersokelseId}/${matvareToChange?.kode}`
        );
      }
    }
  };

  const checkUtbyttetCount = (count: number) => {
    setMatvareFromUtbyttet(count);
    if (!(matvareToChange?.kode === 0 || newMatvare.kode === 0)) {
      if (count > 0) {
        showAlertComponent(
          'Matvare ' +
            matvareToChange?.kode +
            ' ' +
            matvareToChange?.navn +
            ' kan ikke byttes - det eksisterer utbyttede ingredienser!',
          'warning'
        );
        setBytt(false);
      } else {
        const occerrence = singleOccurrence ? `denne` : `alle`;
        showChangeConfirmation(
          `Vil du bytte ${occerrence} forekomster av matvarekode ${matvareToChange?.kode} ${matvareToChange?.navn} med ${newMatvare.kode} ${newMatvare.navn}?`
        );
      }
    }
  };

  async function postData(data: any, url: string, methods: string) {
    let response = await fetch(encodeURI(url), {
      method: methods,
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      body: JSON.stringify(data)
    });
    return response;
  }

  const updateMaaltidkomponent = (kommentar?: number) => {
    if (newMatvare.kode) {
      setShowProgress(true);
      let url = `${backendURI}/updateMaaltidkomponent/${projectId}/${undersokelseId}/${matvareToChange?.kode}`;
      if (singleOccurrence) {
        url = `${backendURI}/updateMaaltidkomponentInLopedag/${projectId}/${undersokelseId}/${personId}/${lopedagId}/${maaltidId}/${ordrnrId}/${matvareToChange?.kode}/${kommentar}`;
      }
      postData({ data: newMatvare.kode }, url, 'PUT')
        .then((resp) => {
          if (resp.status === 200) {
            reloadData();
            setBytt(false);
          } else if (resp.status === 500) {
            console.log('An error 500', resp.status);
            showAlertComponent(
              'Oppdateringen mislyktes. Det har ikke blitt gjort endringer mot databasen.',
              'error'
            );
          }
          //setShowProgress(false);
          setBytt(false);
        })
        .catch((err) => {
          console.log('An error', err);
          showAlertComponent(
            'Oppdateringen mislyktes. Det har ikke blitt gjort endringer mot databasen.',
            'error'
          );
          setShowProgress(false);
          setBytt(false);
        });
    }
  };
  const showChangeConfirmation = (text: string) => {
    setDialogTitle('Bytt matvare');
    setDialogText(text);
    setShowCDialog(true);
  };
  const handleClickJa = () => {
    setShowCDialog(false);
    singleOccurrence ? showKommentarConfirmation() : updateMaaltidkomponent();
  };
  const handleClickNei = () => {
    setShowProgress(false);
    setShowCDialog(false);
    setBytt(false);
  };
  const showKommentarConfirmation = () => {
    setDialogTitle('Kommentar');
    setDialogText('Vil du blanke ut kommentaren?');
    setShowKommentarDialog(true);
  };
  const handleClickJaKommentar = () => {
    setShowKommentarDialog(false);
    updateMaaltidkomponent(0);
  };
  const handleClickNeiKomentar = () => {
    setShowProgress(false);
    setShowKommentarDialog(false);
    updateMaaltidkomponent(1);
  };
  return (
    <>
      {showMatvareKode && (
        <MatvareSearch
          setMatvare={(id, navn) => {
            setNewMatvare({
              kode: +id,
              navn: navn
            });
          }}
          showing={showMatvareKode}
          showProgress={showProgress}
          setShowProgress={setShowProgress}
          hide={() => {
            //setBytt(false);
            setShowMatvareKode(false);
          }}
        />
      )}
      {showCDialog && (
        <DialogComponent
          title={dialogTitle}
          message={dialogText}
          open={showCDialog}
          close={() => {
            setShowCDialog(!showCDialog);
          }}
          handleClickJa={handleClickJa}
          handleClickNei={handleClickNei}
        />
      )}
      {showKommentarDialog && (
        <DialogComponent
          title={dialogTitle}
          message={dialogText}
          open={showKommentarDialog}
          close={() => {
            setShowKommentarDialog(!showKommentarDialog);
          }}
          handleClickJa={handleClickJaKommentar}
          handleClickNei={handleClickNeiKomentar}
        />
      )}
    </>
  );
}
