import React, { useEffect, useState } from 'react';
import { Grid, Stack } from '@mui/material';
import { ChatBubbleButton } from '../../components/ChatBubbleButton';
import {
  BeregningObject,
  DefaultBeregningCounts,
  FieldsValues,
  UtvalgTableData
} from '../types/BeregningTypes';
import { InputLabel, TextField } from '@mui/material';
import useStyles from '../../../../css/materialtheme';
import useDidMountEffect from '../../../utilities/useDidMountEffect';
import { StoffSearch } from '../../stoff/StoffSearch';
import { StoffI, StoffType, StoffgruppeI } from '../../stoff/types/StoffTypes';

type BeregningStoffProps = {
  beregningObject: BeregningObject;
  stoffStatus: boolean;
  showStoff: boolean;
  setShowStoff: (value: boolean) => void;
  setTotalCounts: (value: DefaultBeregningCounts) => void;
  updateBeregningObject: (item: string, name: string, value: any) => void;
  utvalgData: UtvalgTableData;
  selectedStoffs: StoffI[];
  setSelectedStoffs: (value: StoffI[]) => void;
  selectedStoffgruppe: StoffgruppeI;
  setSelectedStoffgruppe: (value: StoffgruppeI) => void;
};
const BeregningStoff = (props: BeregningStoffProps) => {
  const {
    beregningObject,
    stoffStatus,
    showStoff,
    setShowStoff,
    updateBeregningObject,
    utvalgData,
    selectedStoffs,
    setSelectedStoffs,
    selectedStoffgruppe,
    setSelectedStoffgruppe
  } = props;
  const classes = useStyles();
  const [stofferList, setStofferList] = useState<StoffI[]>([]);
  const [stoffgrupperList, setStoffgrupperList] = useState<StoffgruppeI[]>([]);
  const [currentSCount] = useState(beregningObject?.stoffData?.individualStoffs?.length);
  const [currentSGName] = useState(
    beregningObject?.stoffData?.stoffGroup?.length > 0
      ? beregningObject?.stoffData?.stoffGroup[0]?.navn
      : ''
  );
  const [stoffInput, setStoffInput] = useState<FieldsValues>({
    value:
      currentSGName !== ''
        ? currentSGName
        : currentSCount > 0
        ? currentSCount + ' - Spesifisert utvalg'
        : '',
    touched: false,
    error: false,
    errorMsg: 'Nøyaktig et stoff må være valgt før du kan starte beregningen'
  });
  const [, setTotalStoffer] = useState(0);
  const [stoffType, setStoffType] = useState<StoffType>(StoffType.both);
  React.useEffect(() => {
    if (!beregningObject?.hent) {
      setSelectedStoffs([]);
      setSelectedStoffgruppe({
        beskrivelse: '',
        navn: '',
        stoffgruppeId: 0
        // BRUTTOBEREGNING: '2'
      });
    }
  }, [beregningObject.matvareData.rapport.value]);

  useDidMountEffect(() => {
    if (beregningObject?.matvareData?.rapport?.value !== 0) {
      validateStoff(beregningObject?.matvareData?.rapport?.value);
      if (
        beregningObject?.matvareData?.rapport?.value === 10 ||
        beregningObject?.matvareData?.rapport?.value === 12
      ) {
        setStoffType(StoffType.single);
      } else {
        setStoffType(StoffType.both);
      }
    }
  }, [beregningObject?.matvareData?.rapport?.value, beregningObject?.beregn, utvalgData]);
  const toggleShowStoff = (stoff?: StoffI) => {
    setShowStoff(!showStoff);
    if (selectedStoffs?.length === 0 && selectedStoffgruppe?.stoffgruppeId === 0) {
      updateBeregningObject('stoff', 'deleteStoff', true);
      updateBeregningObject('stoff', 'stoffGroup', []);
      updateBeregningObject('stoff', 'individualStoffs', []);
    } else {
      updateBeregningObject('stoff', 'deleteStoff', false);
      if (
        (beregningObject?.matvareData?.rapport?.value === 10 ||
          beregningObject?.matvareData?.rapport?.value === 12) &&
        (selectedStoffgruppe?.stoffgruppeId > 0 || !stoff)
      ) {
        setStoffInput({
          value: '',
          touched: false,
          error: true,
          errorMsg: 'Nøyaktig ett stoff må være valgt før du kan starte beregningen'
        });
        updateBeregningObject('stoff', 'stoffGroup', []);
        updateBeregningObject('stoff', 'individualStoffs', []);
      } else {
        updateStoffgroup();
        updateStoff(stoff);
      }
    }
  };
  const updateStoffgroup = () => {
    if (selectedStoffgruppe?.stoffgruppeId !== 0) {
      setStoffInput({
        ...stoffInput,
        value: selectedStoffgruppe?.navn,
        error: false
      });
    }
    updateBeregningObject(
      'stoff',
      'stoffGroup',
      selectedStoffgruppe?.stoffgruppeId === 0 ? [] : [selectedStoffgruppe]
    );
    updateBeregningObject(
      'stoff',
      'individualStoffs',
      selectedStoffs?.length === 0 ? [] : selectedStoffs
    );
  };
  const updateStoff = (stoff?: StoffI) => {
    if (stoff) {
      setTotalStoffer(1);
    }
    setTotalStoffer(selectedStoffs?.length);
    if (selectedStoffs?.length > 0 || stoff) {
      setStoffInput({
        ...stoffInput,
        value: selectedStoffs?.length || 1 + ' - Spesifisert utvalg',
        error: false
      });
      //The following condition is added because stoffnr 3(calorie) is not regnbart so, stoffnr 2(energi) is used instead.
      const found = selectedStoffs?.find((x) => x.stoffnrId === 3);
      if (
        (beregningObject?.matvareData?.rapport?.value === 7 ||
          beregningObject?.matvareData?.rapport?.value === 8) &&
        (found || stoff?.stoffnrId === 3)
      ) {
        selectedStoffs.push({
          stoffnrId: 2,
          kortnavn: 'Energi',
          navn: 'Energi',
          sortering: 2000,
          regnbart: 'J',
          enhet: 'g'
        });
      }
    }
    updateBeregningObject(
      'stoff',
      'individualStoffs',
      stoff ? [stoff] : selectedStoffs?.length === 0 ? [] : selectedStoffs
    );
    updateBeregningObject(
      'stoff',
      'stoffGroup',
      selectedStoffgruppe?.stoffgruppeId === 0 ? [] : [selectedStoffgruppe]
    );
  };
  const setStoffStatus = () => {
    if (
      beregningObject?.stoffData?.individualStoffs === undefined &&
      beregningObject?.stoffData?.stoffGroup === undefined &&
      utvalgData?.stoffGroupData === undefined &&
      utvalgData?.stoffData === undefined
    ) {
      setStoffInput({ ...stoffInput, value: '', error: true });
    } else if (
      beregningObject?.stoffData?.stoffGroup?.length === 0 &&
      beregningObject?.stoffData?.individualStoffs?.length === 0 &&
      utvalgData?.stoffGroupData?.length === 0 &&
      utvalgData?.stoffData?.length === 0
    ) {
      setStoffInput({ ...stoffInput, value: '', error: true });
    } else {
      setStoffInput({ ...stoffInput, errorMsg: '', error: false });
    }
  };
  const validateStoff = (reportId: number) => {
    if (
      reportId === 3 ||
      reportId === 4 ||
      reportId === 5 ||
      reportId === 6 ||
      reportId === 7 ||
      reportId === 8
    ) {
      setStoffStatus();
    } else if (
      (reportId === 10 || reportId === 12) &&
      (beregningObject?.stoffData?.stoffGroup?.length > 0 ||
        beregningObject?.stoffData?.individualStoffs?.length != 1 ||
        utvalgData?.stoffGroupData?.length > 0 ||
        utvalgData?.stoffData?.length != 1)
    ) {
      setStoffInput({
        value: '',
        touched: false,
        error: true,
        errorMsg: 'Nøyaktig ett stoff må være valgt før du kan starte beregningen'
      });
    } else {
      setStoffInput({ ...stoffInput, error: false, errorMsg: '' });
    }
  };

  return (
    <>
      <Grid container spacing={1} alignItems={'flex-end'}>
        <Grid item xs={12}>
          <InputLabel sx={classes.InputLabel}>Stoffer</InputLabel>
          <Stack direction={'row'} spacing={1}>
            <Grid item xs={8}>
              <TextField
                id="outlined-uncontrolled-Stoff"
                name="Stoff"
                value={
                  utvalgData?.stoffGroupData?.length > 0
                    ? utvalgData?.stoffGroupData[0]?.navn + ' - Spesifisert utvalg'
                    : utvalgData?.stoffData?.length > 0
                    ? utvalgData?.stoffData?.length + ' - Spesifisert utvalg'
                    : ''
                }
                onFocus={() => {
                  setStoffStatus();
                }}
                type={'text'}
                error={stoffInput?.error}
                helperText={
                  stoffInput?.error
                    ? beregningObject?.matvareData?.rapport?.value === 10 ||
                      beregningObject?.matvareData?.rapport?.value === 12
                      ? 'Nøyaktig ett stoff må være valgt før du kan starte beregningen'
                      : 'Stoff må være valgt før du kan starte beregningen'
                    : ''
                }
                required={false}
                disabled={!stoffStatus}
                inputProps={{
                  min: 1,
                  maxLength: 6
                }}
                style={{ width: '100%' }}
                InputProps={{ readOnly: true, sx: { input: classes.resize } }}
                variant="outlined"
                size="small"
              />
            </Grid>
            <Grid item xs={4}>
              <ChatBubbleButton
                name={'Stoffer'}
                showPopup={showStoff}
                setShowPopup={setShowStoff}
                isDisabled={
                  !stoffStatus || beregningObject?.matvareData?.rapport?.value === 0
                }
              />
            </Grid>
          </Stack>
        </Grid>
        {showStoff && (
          <StoffSearch
            databaseId={beregningObject.databaseId}
            stofferList={stofferList}
            setStofferList={setStofferList}
            selectedStoffs={selectedStoffs}
            setSelectedStoffs={setSelectedStoffs}
            selectedStoffgruppe={selectedStoffgruppe}
            stoffgrupperList={stoffgrupperList}
            setStoffgrupperList={setStoffgrupperList}
            setSelectedStoffgruppe={setSelectedStoffgruppe}
            stoffType={stoffType}
            utvalgData={utvalgData}
            setShowStoffOption={setShowStoff}
            showStoffOption={showStoff}
            toggleStoffnummer={toggleShowStoff}
          />
        )}
      </Grid>
    </>
  );
};
export default BeregningStoff;
