import React, { useEffect, useState } from 'react';
import { Grid, Breadcrumbs, Link } from '@mui/material';
import {
  Matvare,
  MatvaregruppeI,
  MatvaregruppeParam,
  TableType
} from '../types/matvaregruppeTypes';
import useFetchData from '../../../utilities/useFetchData';

import * as actions from '../../../../types/actions';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useDispatch, useSelector } from 'react-redux';
import {
  MatvaregruppeListType,
  MatvaregruppeType,
  MatvareListType,
  MatvareType
} from '../../../../types/reduxMatvaregruppeTypes';
import { MatvareGruppeTable } from './MatvaregruppeTable';
import { UtvalgTableData } from '../../beregning/types/BeregningTypes';

interface NavigateLevelI {
  nodeId: number;
  name: string;
  n: number;
}
interface MatvaregruppeTableContainerI {
  databaseId: number;
  gruppetypeId: number;
  owned: boolean;
  selectedRow?: MatvaregruppeI;
  setSelectedRow?: (value: MatvaregruppeI) => void;
  nivaa: number;
  linkup: number;
  setNivaa: (value: number) => void;
  setLinkup: (value: number) => void;
  parent: string;
  setParent: (value: string) => void;
  parentsList: NavigateLevelI[];
  setParentsList: (list: NavigateLevelI[]) => void;
  matvarerList: Matvare[];
  matvaregruppeList: MatvaregruppeI[];
  setMatvarerList: (list: Matvare[]) => void;
  setMatvaregruppeList: (list: MatvaregruppeI[]) => void;
  setShowProgress: (value: boolean) => void;
  showMatvare: boolean;
  setShowMatvare: (value: boolean) => void;
  move: boolean;
  main: boolean;
  tableType: TableType;
  reloadMatvare?: boolean;
  reloadMatvaregruppe?: boolean;
  setOrderNr?: (value: number) => void;
  setReloadMatvare?: (value: boolean) => void;
  setReloadMatvaregruppe?: (value: boolean) => void;
  newGroup?: MatvaregruppeParam;
  setNewGroup?: (object: MatvaregruppeParam) => void;
  selectedMatvares?: Matvare[];
  setSelectedMatvares?: (mat: Matvare[]) => void;
  selectedMatvaregruppes?: MatvaregruppeI[];
  setSelectedMatvaregruppes?: (mg: MatvaregruppeI[]) => void;
  highLevelCategory?: number;
  setProgressText: (text: string) => void;

  //utvalgData?: UtvalgTableData;
}
export const MatvaregruppeTableContainer = (props: MatvaregruppeTableContainerI) => {
  const {
    highLevelCategory,
    databaseId,
    gruppetypeId,
    owned,
    nivaa,
    linkup,
    setOrderNr,
    setNivaa,
    setLinkup,
    parent,
    setParent,
    parentsList,
    setParentsList,
    matvaregruppeList,
    setMatvaregruppeList,
    matvarerList,
    setMatvarerList,
    selectedRow,
    setSelectedRow,
    setShowProgress,
    reloadMatvaregruppe,
    reloadMatvare,
    showMatvare,
    setShowMatvare,
    move,
    main,
    tableType,
    newGroup,
    setNewGroup,
    setReloadMatvare,
    setReloadMatvaregruppe,
    selectedMatvares,
    setSelectedMatvares,
    selectedMatvaregruppes,
    setSelectedMatvaregruppes,
    setProgressText

    //utvalgData,
  } = props;
  const dispatch = useDispatch();
  const [allMatvaregruppeList, setAllMatvaregruppeList] = useState<MatvaregruppeI[]>([]);
  const matvaregruppeState = useSelector(
    (state: MatvaregruppeType) => state['matvaregruppe']
  );
  const matvareState = useSelector((state: MatvareType) => state['matIGruppe']);
  const matvaregrupp = useFetchData({
    loadOnMount: false,
    clearDataOnLoad: false,
    projectURL: 'getGrupper',
    subProjectURL: `${databaseId}/${gruppetypeId}`
  });
  const matvarer = useFetchData({
    loadOnMount: false,
    clearDataOnLoad: false,
    projectURL: 'getMatvarer',
    subProjectURL: `${databaseId}/${gruppetypeId}`
  });
  useEffect(() => {
    if (gruppetypeId) {
      openHovedGrupper('none');
    }
  }, [gruppetypeId]);

  useEffect(() => {
    if (reloadMatvaregruppe) {
      loadMatvaregrupp(nivaa, linkup, true);
      setReloadMatvaregruppe(false);
      setShowProgress(false);
    }
  }, [reloadMatvaregruppe]);

  useEffect(() => {
    if (reloadMatvare) {
      loadMatvare(linkup, parent, nivaa, true);
      setReloadMatvare(false);
    }
  }, [reloadMatvare]);

  useEffect(() => {
    if (matvaregrupp.isLoaded) {
      if (linkup === 0) {
        setAllMatvaregruppeList(matvaregrupp.data);
      }
      setMatvaregruppeList(matvaregrupp.data);
      if (main) {
        setOrderNr(matvaregrupp.data[matvaregrupp.data?.length - 1]?.ORDNR || 0);
      }
      storeCurrentMatvaregruppeDataInRedux();
      setShowProgress(false);
    }
  }, [matvaregrupp.isLoaded, matvaregrupp.data]);

  useEffect(() => {
    if (matvarer.isLoaded) {
      setMatvarerList(matvarer.data);
      storeCurrentMatvareDataInRedux();
      setShowProgress(false);
    }
  }, [matvarer.isLoaded, matvarer.data]);

  const loadMatvaregrupp = (nivaa: number, linkup: number, update: boolean) => {
    if (update) {
      loadMGFromDatabase(nivaa, linkup);
    } else {
      const listFromReduxStore: MatvaregruppeListType = matvaregruppeState;
      const currentList = listFromReduxStore?.matvaregruppeList?.find(
        (item) => item.gruppetypeId === gruppetypeId && item.linkup === linkup
      );
      if (currentList?.matvaregrupper?.length > 0) {
        setMatvaregruppeList(currentList?.matvaregrupper);
      } else {
        loadMGFromDatabase(nivaa, linkup);
      }
    }
  };

  const loadMGFromDatabase = (nivaa: number, linkup: number) => {
    matvaregrupp.loadData(
      'getGrupper',
      `${databaseId}/${gruppetypeId}/${nivaa}/${linkup}`
    );
    setProgressText('Loading...');
    setShowProgress(true);
  };
  const storeCurrentMatvaregruppeDataInRedux = () => {
    const currentMGList: MatvaregruppeType[] = [
      {
        gruppetypeId: gruppetypeId,
        linkup: linkup,
        matvaregrupper: matvaregrupp.data
      }
    ];
    dispatch({ type: actions.SET_MATVAREGRUPPELIST, matvaregruppeList: currentMGList });
  };
  const storeCurrentMatvareDataInRedux = () => {
    const currentMatList: MatvareType[] = [
      {
        gruppetypeId: gruppetypeId,
        linkup: linkup,
        matvarer: matvarer.data
      }
    ];
    dispatch({ type: actions.SET_MATVARELIST, matvareList: currentMatList });
  };

  const setAppropriateParentList = (nodeId: number, name: string, n: number) => {
    setParent(name);
    setParentsList([...parentsList, { nodeId: nodeId, name: name, n: n }]);
  };
  const setAppropriateHovedGruppeNav = () => {
    setParent('Hovedgrupper');
    setParentsList([{ nodeId: 0, name: 'Hovedgrupper', n: 1 }]);
  };

  const removeNSetAppropriateParentList = (name: string) => {
    setParent(name);
    const index = parentsList?.findIndex((x) => x.name === name);
    setParentsList([...parentsList?.slice(0, index + 1)]);
  };
  const checkAppropriateParentList = (name: string) => {
    let existingName = false;

    if (parentsList[parentsList?.length - 1]?.name === name) {
      existingName = true;
    }

    return existingName;
  };
  const openHovedGrupper = (tableType: string) => {
    setShowMatvare(false);
    setAppropriateHovedGruppeNav();
    setNivaa(1);
    setLinkup(0);
    setMatvaregruppeList(allMatvaregruppeList);
    if (main) {
      setOrderNr(allMatvaregruppeList[allMatvaregruppeList?.length - 1]?.ORDNR || 0);
    }
    loadMatvaregrupp(1, 0, false);
  };
  const openPrevGrupper = (
    nodeId: number,
    name: string,
    nivaa: number,
    tableType: string
  ) => {
    const existingName = checkAppropriateParentList(name);
    if (existingName) {
      return;
    }
    if (name === 'Hovedgrupper') {
      openHovedGrupper(tableType);
    } else {
      setShowMatvare(false);
      removeNSetAppropriateParentList(name);
      setNivaa(nivaa);
      setLinkup(nodeId);
      loadMatvaregrupp(nivaa, nodeId, false);
    }
  };
  const openUndergrupper = (
    nodeId: number,
    name: string,
    nivaa: number,
    hasNext: boolean,
    undergrupp: number
  ) => {
    setShowMatvare(false);
    setLinkup(nodeId);
    let level = parseInt(nivaa?.toString()) + 1;
    setNivaa(level);
    if (hasNext === true) {
      if (undergrupp > 0) {
        setAppropriateUndergruppeList(nodeId, name);
      } else {
        loadMatvaregrupp(level, nodeId, false);
      }
    } else {
      setMatvaregruppeList([]);
      if (main) {
        setOrderNr(1);
      }
    }
    setAppropriateParentList(nodeId, name, level);
  };
  const setAppropriateUndergruppeList = (nodeId: number, name: string) => {
    let newMGlist: MatvaregruppeI[] = [];

    setParent(name);
    newMGlist =
      matvaregruppeList?.filter(
        (element: MatvaregruppeI) => element.NODENRID === nodeId
      )[0]?.UNDERGURPPER || [];
    setMatvaregruppeList(newMGlist);
    if (main) {
      setOrderNr(newMGlist[newMGlist?.length - 1]?.ORDNR || 0);
    }
  };

  const loadMatvare = (nodeId: number, name: string, nivaa: number, update: boolean) => {
    if (update) {
      loadMatvarerFromDatabase(nodeId);
    } else {
      setShowMatvare(true);
      setAppropriateParentList(nodeId, name, nivaa);
      const listFromReduxStore: MatvareListType = matvareState;
      const currentList = listFromReduxStore?.matvareList?.find(
        (item) => item.gruppetypeId === gruppetypeId && item.linkup === nodeId
      );
      if (currentList?.matvarer?.length > 0) {
        setMatvarerList(currentList?.matvarer);
      } else {
        loadMatvarerFromDatabase(nodeId);
      }
    }
  };
  const loadMatvarerFromDatabase = (nodeId: number) => {
    setLinkup(nodeId);
    matvarer.loadData('getMatvarer', `${databaseId}/${gruppetypeId}/${nodeId}`);
    setProgressText('Loading...');
    setShowProgress(true);
  };

  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <Breadcrumbs
            aria-label="breadcrumb"
            separator={<NavigateNextIcon fontSize="large" />}
          >
            {parentsList?.map((row: NavigateLevelI) => {
              return (
                <Link
                  key={`Grupper${row?.name}`}
                  underline={'hover'}
                  component="button"
                  variant="body2"
                  onClick={() => {
                    openPrevGrupper(row?.nodeId, row?.name, row?.n, '');
                  }}
                >
                  <h3>{row?.name}</h3>
                </Link>
              );
            })}
          </Breadcrumbs>
        </Grid>
        <Grid item xs={12}>
          <MatvareGruppeTable
            highLevelCategory={highLevelCategory}
            owned={owned}
            nivaa={nivaa}
            showMatvare={showMatvare}
            parent={parent}
            matvarerList={matvarerList}
            openUndergrupper={openUndergrupper}
            loadMatvare={loadMatvare}
            selectedRow={selectedRow}
            setSelectedRow={setSelectedRow}
            matvaregruppeList={matvaregruppeList || []}
            setMatvaregruppeList={setMatvaregruppeList}
            newGroup={newGroup}
            setNewGroup={setNewGroup}
            move={move}
            tableType={tableType}
            selectedMatvares={selectedMatvares}
            setSelectedMatvares={setSelectedMatvares}
            selectedMatvaregruppes={selectedMatvaregruppes}
            setSelectedMatvaregruppes={setSelectedMatvaregruppes}
            //utvalgData={utvalgData}
          ></MatvareGruppeTable>
        </Grid>
      </Grid>
    </div>
  );
};
