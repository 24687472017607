import { useEffect, useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';

export interface FetchDataProps {
  data: any[];
  isLoading: boolean;
  isLoaded: boolean;
  error: boolean;
  errorMessage: string;
  loadData: (projectURL: string, subProjectURL: string) => Promise<void>;
  clearData: () => void;
  clearAll: () => void;
}

const useFetchData = ({
  loadOnMount = false,
  clearDataOnLoad = false,
  projectURL = '',
  subProjectURL = ''
} = {}) => {
  const { keycloak } = useKeycloak();
  const token = keycloak?.token;

  const [data, setData] = useState([]);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsloaded] = useState(false);
  const clearData = () => setData([]);
  const clearAll = () => {
    setData([]);
    setError(false);
    setErrorMessage('');
    setIsLoading(false);
    setIsloaded(false);
  };
  const loadData = async (projectURL: string, subProjectURL: string) => {
    const url = `${process.env.REACT_APP_BACKEND}/${projectURL}/${
      subProjectURL ? subProjectURL : ''
    }`;
    setIsLoading(true);
    setError(false);

    if (clearDataOnLoad === true) setData([]);

    try {
      const resp = await fetch(encodeURI(url), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token
        }
      });
      resp
        .json()
        .then((data) => {
          if (resp.status === 500) {
            setData([]);
            setIsLoading(false);
            setIsloaded(false);
            setError(true);
            setErrorMessage(resp.statusText);
          }
          //console.log('data: ', data);
          setData(data['rows'] || data);
          setIsLoading(false);
          setIsloaded(true);
        })
        .catch((err) => {
          setData([]);
          setIsLoading(false);
          setIsloaded(false);
          setError(true);
          setErrorMessage(err);
        });
    } catch (e: any) {
      setError(true);
      setErrorMessage(e);
      setIsloaded(false);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (loadOnMount !== false) loadData(projectURL, subProjectURL);
  }, [projectURL, subProjectURL]);

  return {
    data,
    isLoading,
    isLoaded,
    error,
    errorMessage,
    loadData,
    clearData,
    clearAll
  };
};
export default useFetchData;
