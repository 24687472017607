import React, { useState } from 'react';
import { DialogBox } from '../../utilities/DialogBox';
import {
  Grid,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import { MonoSearchField } from '../components/Inputs';
import { useProjectContext } from '../../../context/ProjectContext';
import { UndersokelseSearchI } from './types/ProjectTypes';
import { databaseOptions } from '../../../assets/DatabaseOptions';

interface UndersokelseSearchProps {
  setUndersokelse: (id: number | string) => void;
  projectId: string;
  showing: boolean;
  hide: () => void;
}

function UndersokelseSearch(p: UndersokelseSearchProps) {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [reload, setReload] = React.useState<boolean>(true);
  const projs = useProjectContext();
  const [undersokelser, setUndersokelser] = useState<UndersokelseSearchI[]>([]);
  const changeUndersokelse = (undersokelse: string | number) => {
    p.hide();
    p.setUndersokelse(`${undersokelse}`);
  };
  const translateHeader = {
    undersokelseId: 'Undersøkelsenr.',
    kortNavn: 'Kortnavn',
    ansvarlig: 'Leder',
    opprettet: 'Opprettet',
    endret: 'Endret',
    matvareDatabaseId: 'Matvaredatabase',
    antallPersoner: 'Antall pers.'
  };
  const columnsOrder = Object.keys(translateHeader);

  React.useEffect(() => {
    const data = projs.projects?.find(
      (obj) => obj.prosjektId === +p.projectId
    )?.undersokelser;
    const searchData: UndersokelseSearchI[] = [];
    data?.forEach((originalObj) => {
      const current: UndersokelseSearchI = {
        undersokelseId: '',
        ansvarlig: '',
        matvareDatabaseId: ''
      };
      Object.keys(originalObj).forEach((key) => {
        if (Object.keys(translateHeader).includes(key)) {
          if (key === 'antallPersoner') {
            current[key] = originalObj[key]['ANTALL'] as unknown as number;
          } else if (key === 'matvareDatabaseId') {
            current[key] = databaseOptions?.find(
              (d) => d.value === originalObj[key]
            )?.label;
          } else {
            current[key] = originalObj[key];
          }
        }
      });
      searchData.push(current);
    });
    setUndersokelser(searchData);
    setReload(false);
  }, [reload]);
  const searchWithNumber = (no?: string) => {
    no
      ? setUndersokelser(
          undersokelser?.filter((r) => r.undersokelseId?.toString()?.includes(no))
        )
      : setReload(true);
  };
  const searchWithName = (name: string) => {
    name
      ? setUndersokelser(
          undersokelser?.filter((d) =>
            d.kortNavn?.toLowerCase()?.includes(name?.toLowerCase())
          )
        )
      : setReload(true);
  };

  return (
    <DialogBox title="Liste over undersøkelser" isShowing={p.showing} hide={p.hide}>
      <Grid container spacing={2}>
        <Grid item xs={12} container spacing={2}>
          <Grid item xs={3} container alignItems="flex-end">
            <MonoSearchField
              header="Søk etter undersøkelsesnummer"
              searchFn={(n) => searchWithNumber(n)}
            />
          </Grid>
          <Grid item xs={3} container alignItems="flex-end">
            <MonoSearchField
              header="Søk etter kortnavn"
              searchFn={(n) => searchWithName(n)}
            />
          </Grid>
          <Grid item xs={6} container alignItems="flex-end" justifyContent={'flex-end'}>
            <Typography>
              {'Antall undersokelser: '}
              {undersokelser?.length}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {!loading || undersokelser.length < 1 ? (
            <>
              <Table size="small" sx={{ border: '1px solid black' }}>
                <TableHead>
                  <TableRow>
                    {columnsOrder.map((columnKey) => (
                      <TableCell key={columnKey}>{translateHeader[columnKey]}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {undersokelser?.map((row) => (
                    <TableRow hover>
                      {columnsOrder.map((columnKey, i) => (
                        <TableCell key={columnKey} sx={{ height: '3em' }}>
                          {i < 2 ? (
                            <a
                              style={{
                                color: 'blue',
                                textDecoration: 'underline',
                                cursor: 'pointer'
                              }}
                              onClick={() => changeUndersokelse(row.undersokelseId)}
                              children={row[columnKey]}
                            />
                          ) : (
                            row[columnKey]
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              {/*    <TablePagination
            //sx={classesTable.Pagination}
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={undersokelser?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            showFirstButton={true}
            showLastButton={true}
          />     */}
            </>
          ) : (
            <Skeleton variant="rectangular" width="90vw" height="10em" />
          )}
        </Grid>
      </Grid>
    </DialogBox>
  );
}

export default UndersokelseSearch;
