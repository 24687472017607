import React from 'react';
import { AltOmMatvareI } from '../../RapportTypes';
import { NButton } from '../../../components/Inputs';
import { Box, Grid } from '@mui/material';

import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import ReactToPrint from 'react-to-print';

const ComponentToPrint = React.forwardRef(
  (p: { data: AltOmMatvareI[]; matvaredb: string }, ref: any) => (
    <div ref={ref} style={{ padding: '1em' }}>
      <div style={{ textAlign: 'center' }}>
        <h1>Alt om en matvare</h1>
        <h3>Matvaredatabase: {p.matvaredb}</h3>
      </div>

      {p.data.map((matvare, i) => (
        <div className={i && 'pagebreak'}>
          <Grid container marginTop={4}>
            <Grid item xs={3}>
              <b>Kode:</b>
            </Grid>
            <Grid item xs={5}>
              {matvare.kode}
            </Grid>
            <Grid item xs={2}>
              <b>Opprettet:</b>
            </Grid>
            <Grid item xs={2}>
              {matvare.opprettet}
            </Grid>
            <Grid item xs={3}>
              <b> Navn:</b>
            </Grid>
            <Grid item xs={5}>
              {matvare.navn}
            </Grid>
            <Grid item xs={2}>
              <b>Endret:</b>
            </Grid>
            <Grid item xs={2}>
              {matvare.endret}
            </Grid>
            <Grid item xs={3}>
              <b> Beskrivelse:</b>
            </Grid>
            <Grid item xs={9}>
              {matvare.beskrivelse}
            </Grid>
            <Grid item xs={3}>
              <b> Referanse:</b>
            </Grid>
            <Grid item xs={9}>
              {matvare.referanse}
            </Grid>

            <Grid item xs={12} height="16px" />

            <Grid item xs={2}>
              <b> Maks. mengde:</b>
            </Grid>
            <Grid item xs={2}>
              {matvare.maksimalmengde}
            </Grid>
            <Grid item xs={2}>
              <b>Spiselig del:</b>
            </Grid>
            <Grid item xs={2}>
              {matvare.spiselig && `${matvare.spiselig}%`}
            </Grid>
            <Grid item xs={2}>
              <b>Vektendring:</b>
            </Grid>
            <Grid item xs={2}>
              {matvare.vektendring && `${matvare.vektendring}%`}
            </Grid>
            <Grid item xs={2}>
              <b>MVT-nr:</b>
            </Grid>
          </Grid>
          <div className="sh">
            <b>Mengdeenheter</b>
          </div>
          <table width="100%">
            {matvare.mengdeenheter.map((enhet) => (
              <tr>
                <td>{enhet.mengdeenhetid}</td>
                <td>{enhet.mengde}</td>
              </tr>
            ))}
          </table>
          <div className="sh">
            <b>Matvaregrupper</b>
          </div>
          <table width="100%">
            {matvare.matvaregrupper.map((mgruppe) => (
              <tr>
                <td>{mgruppe.gruppetypeid}</td>
                <td>{mgruppe.beskrivelse}</td>
                <td>{mgruppe.nodenrid}</td>
                <td>{mgruppe.navn}</td>
              </tr>
            ))}
          </table>

          <div className="sh">
            <b>Ingredienser</b>
          </div>
          <table width="60%">
            {matvare.ingredienser.map((ingrediens) => (
              <tr>
                <td>{ingrediens.ingrediensid}</td>
                <td>{ingrediens.navn}</td>
                <td style={{ textAlign: 'end' }}>{ingrediens.mengde}</td>
                <td>{ingrediens?.mengdeenhet || 'g'}</td>
              </tr>
            ))}
          </table>
          <div className="sh">
            <b>Stoffkomponenter pr 100 g</b>
          </div>
          <div style={{ display: 'flex', gap: '5vw' }}>
            {[...Array(3).keys()].map((i) => (
              <table style={{ flex: 1, height: 'fit-content' }}>
                {matvare.stoffkomponenter
                  .slice(
                    Math.floor(matvare.stoffkomponenter.length / 3) * i,
                    Math.floor(matvare.stoffkomponenter.length / 3) * (i + 1)
                  )
                  .map((stoff) => (
                    <tr>
                      <td>{stoff.kortnavn}</td>
                      <td style={{ textAlign: 'end' }}>{stoff.mengde}</td>
                      <td>{stoff.enhet}</td>
                      <td>{stoff.kilde}</td>
                    </tr>
                  ))}
              </table>
            ))}
          </div>
        </div>
      ))}
    </div>
  )
);

const HTMLRapport = ({
  data,
  matvaredb
}: {
  data: AltOmMatvareI[];
  matvaredb: string;
}) => {
  const printRef = React.useRef();

  return (
    <>
      <Box sx={{ display: 'none' }}>
        <ComponentToPrint data={data} matvaredb={matvaredb} ref={printRef} />
      </Box>
      <Grid item xs={6}>
        <ReactToPrint
          trigger={() => (
            <NButton
              leftIcon={<PrintOutlinedIcon />}
              children="Skriv ut matvarer"
              fullWidth
            />
          )}
          pageStyle="@media print{.pagebreak{page-break-before:always}}*{border:none}table>tr>td{font-size:small}.sh{font-weight:bold;margin-top:16px}"
          content={() => printRef.current}
        />
      </Grid>
    </>
  );
};

export default HTMLRapport;
