import React, { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Grid
} from '@mui/material';
import useStyles from '../../../../../css/materialtheme';
import {
  Matvare,
  MatvaregruppeI,
  TableType
} from '../../../matvare/types/matvaregruppeTypes';
import { MatvaregruppeTableContainer } from '../../../matvare/matvaregruppe/MatvaregruppeTableContainer';
import {
  BeregningObject,
  DefaultBeregningCounts,
  UtvalgTableData
} from '../../types/BeregningTypes';
import EnhancedTableToolbar from './EnhancedTableToolbar';

interface NavigateLevelI {
  nodeId: number;
  name: string;
  n: number;
}

interface BeregningMatvareGruppeProps {
  beregningObject: BeregningObject;
  gruppetypeId: number;
  matvaregruppeList: MatvaregruppeI[];
  setMatvaregruppeList?: (list: MatvaregruppeI[]) => void;
  selectedMatvares?: Matvare[];
  setSelectedMatvares?: (mat: Matvare[]) => void;
  matvarerList: Matvare[];
  setMatvarerList: (list: Matvare[]) => void;
  selectedMatvaregruppes?: MatvaregruppeI[];
  setSelectedMatvaregruppes?: (mg: MatvaregruppeI[]) => void;
  setShowProgress: (value: boolean) => void;
  totalCounts: DefaultBeregningCounts;
  databaseId: number;
  setLoadCount: (value: boolean) => void;
  updateBeregningObject: (item: string, name: string, value: any) => void;
  highLevelCategory: number;
  setProgressText: (text: string) => void;
  expanded: boolean;
  //utvalgData: UtvalgTableData;
}
export const BeregningMatvareGruppe = (props: BeregningMatvareGruppeProps) => {
  const {
    beregningObject,
    gruppetypeId,
    matvaregruppeList,
    setMatvaregruppeList,
    selectedMatvares,
    setSelectedMatvares,
    selectedMatvaregruppes,
    setSelectedMatvaregruppes,
    matvarerList,
    setMatvarerList,
    setShowProgress,
    databaseId,
    setLoadCount,
    updateBeregningObject,
    highLevelCategory,
    setProgressText,
    expanded
    //utvalgData
  } = props;
  const classes = useStyles();
  const [parentsList, setParentsList] = useState<NavigateLevelI[]>([
    { nodeId: 0, name: 'Hovedgrupper', n: 1 }
  ]);
  const [showMatvare, setShowMatvare] = useState(false);
  const [parent, setParent] = useState('');
  const [reloadMatvaregruppe] = useState(false);
  const [nivaa, setNivaa] = useState(1);
  const [linkup, setLinkup] = useState(0);
  const [selectedRow, setSelectedRow] = useState(null);

  return (
    <Accordion style={{ width: '100%' }} expanded={expanded}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={classes.arrow} />}
        aria-controls="panel1c-content"
        id="panel1bh-header"
        sx={{ root: classes.ExpansionPanel, expanded: classes.expandedExpansionPanel }}
        style={{ height: 80 }}
        disabled={highLevelCategory !== 5}
      >
        <h3>Enkelte Matvaregrupper/Matvarer</h3>
      </AccordionSummary>
      <AccordionDetails sx={classes.ExpansionDetail}>
        <Grid container justifyContent="space-between">
          <EnhancedTableToolbar
            calculationId={beregningObject?.utvalgId}
            gruppetypeId={gruppetypeId}
            selectedMatvares={selectedMatvares}
            selectedMatvaregruppes={selectedMatvaregruppes}
            //setLoadCount={setLoadCount}
            parentsList={parentsList}
            updateBeregningObject={updateBeregningObject}
            highLevelCategory={highLevelCategory}
          />
          <Divider />
          <Grid item xs={12}>
            <MatvaregruppeTableContainer
              highLevelCategory={highLevelCategory}
              databaseId={databaseId}
              gruppetypeId={gruppetypeId}
              owned={false}
              selectedRow={selectedRow}
              setSelectedRow={setSelectedRow}
              nivaa={nivaa}
              linkup={linkup}
              setNivaa={setNivaa}
              setLinkup={setLinkup}
              parent={parent}
              setParent={setParent}
              parentsList={parentsList}
              setParentsList={setParentsList}
              matvaregruppeList={matvaregruppeList}
              setMatvaregruppeList={setMatvaregruppeList}
              matvarerList={matvarerList}
              setMatvarerList={setMatvarerList}
              move={false}
              main={false}
              tableType={TableType.Beregning}
              setShowProgress={setShowProgress}
              reloadMatvaregruppe={reloadMatvaregruppe}
              showMatvare={showMatvare}
              setShowMatvare={setShowMatvare}
              selectedMatvares={selectedMatvares}
              setSelectedMatvares={setSelectedMatvares}
              selectedMatvaregruppes={selectedMatvaregruppes}
              setSelectedMatvaregruppes={setSelectedMatvaregruppes}
              setProgressText={setProgressText}
              // utvalgData={utvalgData}
            ></MatvaregruppeTableContainer>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
