import { BeregningI } from '../types/beregningTypes';
import {
  SET_CALCULATION_ID,
  SET_CLEAR,
  SET_TOTAL_COUNTS,
  BeregningActionTypes
} from '../types/actions';

const beregningReducerDefaultState: BeregningI = {
  calculationId: '',
  clear: false,
  totalCounts: {
    matvareGroupCount: 0,
    matvareCount: 0,
    fjerndeMatvareCount: 0,
    stoffCount: 0
  }
};

export const beregningReducer = (
  state = beregningReducerDefaultState,
  action: BeregningActionTypes
): BeregningI => {
  switch (action.type) {
    case SET_CALCULATION_ID:
      return { ...state, calculationId: action.calculationId };
    case SET_CLEAR:
      return { ...state, clear: action.clear };
    case SET_TOTAL_COUNTS:
      return { ...state, totalCounts: action.totalCounts };
    default:
      return state;
  }
};
