import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState
} from 'react';
import { Box, Stack } from '@mui/system';
import DialogGeneral from '../../../../utilities/DialogGeneral';
import { Grid, Typography } from '@mui/material';
import { ProsjektI } from '../../types/ProjectTypes';
import { RouteComponentProps } from 'react-router-dom';
import { LopedagI, MaaltidI } from '../../types/PersonTypes';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Dropdown, FormInput, NButton } from '../../../components/Inputs';
import AddIcon from '@mui/icons-material/Add';
import { useCodeContext } from '../../../../../context/CodeContext';
import { codeTypes } from '../../../../../assets/kodeOption';
import { CodeRegisterI } from '../../../components/commons/CommonTypes';
import { useUserContext } from '../../../../../context/AuthContext';
import IsOwnedByUser from '../../../../utilities/Validate';
import { useKeycloak } from '@react-keycloak/web';
import { AlertI } from '../../../../../types/alertTypes';
import selectedDropdown from '../SelectedDropdown';
import StyledTextarea from '../../../components/StyledTextarea';
import { ApiCall } from '../../../../utilities/ApiCall';

interface RouteParams {
  prosjektId: string;
  undersokelseId: string;
  undersokelseKortnavn: string;
}

interface LopedagNyttMaaltidProps extends RouteComponentProps<RouteParams> {
  lopedagData: LopedagI;
  projectObject: ProsjektI;
  personId: number;
  identitet: string;
  showing: boolean;
  hide: () => void;
  currentMaaltidId: number;
  setCurrentMaaaltidId: Dispatch<SetStateAction<number>>;
  fromExisting: boolean;
  setShowAlert: Dispatch<SetStateAction<boolean>>;
  setAlertMessage: Dispatch<SetStateAction<string>>;
  setAlertSeverity: Dispatch<SetStateAction<AlertI['alertColor']>>;
  setRefetchLopedag: Dispatch<SetStateAction<boolean>>;
  setShowDeleteMaaltid: Dispatch<SetStateAction<boolean>>;
  checkIfMaaltidHasInfo: (id: number) => void;
}

export default function LopedagNyttMaaltidModal(props: LopedagNyttMaaltidProps) {
  const {
    lopedagData,
    projectObject,
    personId,
    identitet,
    showing,
    hide,
    currentMaaltidId,
    setCurrentMaaaltidId,
    fromExisting,
    setShowAlert,
    setAlertMessage,
    setAlertSeverity,
    setRefetchLopedag,
    setShowDeleteMaaltid,
    checkIfMaaltidHasInfo
  } = props;
  const { prosjektId, undersokelseId, undersokelseKortnavn } = props.match.params;
  const [nyttMaaltid, setNyttMaaltid] = useState<MaaltidI>({
    maaltidsId: currentMaaltidId
  });
  const [timeError, setTimeError] = useState(false);
  const [showError, setShowError] = useState(false);
  const allCodes = useCodeContext();
  const loggedInuser = useUserContext();
  const owned = useMemo(
    () => IsOwnedByUser({ id: +prosjektId, u: loggedInuser }),
    [prosjektId, loggedInuser]
  );
  const { keycloak } = useKeycloak();

  useEffect(() => {
    if (fromExisting) {
      setNyttMaaltid({
        ...lopedagData?.maaltider?.find((m) => m.maaltidsId === currentMaaltidId)
      });
    }
  }, [lopedagData, currentMaaltidId]);

  // HANDLING
  function handleTimeChange(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const { value } = event.target;
    if (value === '') {
      setNyttMaaltid({ ...nyttMaaltid, klokkeslett: null });
      return;
    }
    if (
      !/^\d{4}$/.test(value) ||
      parseInt(value) > 2359 ||
      parseInt(value.split('')[2]) >= 6
    ) {
      console.log('invalid time format');
      setTimeError(true);
      return;
    } else {
      setTimeError(false);
      setShowError(false);
    }
    setNyttMaaltid({ ...nyttMaaltid, klokkeslett: value, maaltidsId: currentMaaltidId });
  }

  // LAGRE OG OPPDATERE MÅLTID
  const saveAndUpdateMaaltid = async () => {
    if (!owned || !lopedagData?.personId || !lopedagData?.lopedagId) return;
    delete nyttMaaltid?.spisested;
    const response = await ApiCall(
      `saveAndUpdateMaaltid/${prosjektId}/${undersokelseId}/${lopedagData?.personId}/${lopedagData?.lopedagId}`,
      'POST',
      keycloak.token,
      undefined,
      { data: nyttMaaltid }
    );
    if (response?.ok) {
      setShowAlert(true);
      setAlertMessage('Måltid lagret');
      setAlertSeverity('success');
    } else {
      setShowAlert(true);
      setAlertMessage('Lagring av måltid feilet');
      setAlertSeverity('error');
    }
  };

  function handleSubmit(close?: boolean) {
    if (timeError) {
      setShowError(true);
      return;
    }
    saveAndUpdateMaaltid();
    setRefetchLopedag(true);
    if (close) {
      hide();
    } else {
      setCurrentMaaaltidId(lopedagData?.maaltider.length + (fromExisting ? 1 : 2));
      setNyttMaaltid({
        maaltidsId: lopedagData?.maaltider.length + (fromExisting ? 1 : 2)
      });
      document.getElementById('klokkeslett')?.focus();
    }
  }

  return (
    <DialogGeneral title="Måltid" open={showing} hide={hide} fullWidth maxWidth="md">
      <Stack spacing={2}>
        <Stack direction={'row'} justifyContent={'space-between'} alignItems="baseline">
          <Stack spacing={1}>
            <Typography variant="body1">
              <b>Prosjekt: </b>
              {projectObject?.prosjektnavn && projectObject?.prosjektnavn + ' - '}
              {prosjektId}
            </Typography>
            <Typography variant="body1">
              <b>Undersøkelse: </b> {undersokelseKortnavn}
            </Typography>
            <Typography variant="body1">
              <b>Person: </b>
              {personId} - {identitet}
            </Typography>
          </Stack>
          <NButton
            children="Slett"
            rightIcon={<DeleteOutlineIcon />}
            variant="text"
            onClick={() => {
              setShowDeleteMaaltid(true);
              checkIfMaaltidHasInfo(currentMaaltidId);
              hide();
            }}
            disabled={!owned || !fromExisting}
          />
        </Stack>
        <Grid container>
          <Grid item container xs={5} rowSpacing={1} paddingRight={2}>
            <Grid item xs={12} sx={{ my: 2 }}>
              <Typography variant="body1">
                <b>Måltidsnr.:</b> {currentMaaltidId}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <FormInput
                id="klokkeslett"
                header="Klokkeslett"
                placeholder="[HHMM]"
                value={nyttMaaltid?.klokkeslett || ''}
                onChange={(event) => handleTimeChange(event)}
                error={timeError}
                owned={owned}
              />
            </Grid>
            <Grid item xs={12}>
              <Dropdown
                header={codeTypes?.find((option) => option.value === 3)?.label}
                value={selectedDropdown(nyttMaaltid?.maaltidstype, 3)}
                options={allCodes.codes?.filter((f) => f.value === 3)}
                optionLabel={(option: CodeRegisterI) => option?.label}
                onChange={(value: any) =>
                  setNyttMaaltid({ ...nyttMaaltid, maaltidstype: value?.nr })
                }
                readOnly={!owned}
              />
            </Grid>
            <Grid item xs={12}>
              <Dropdown
                header={codeTypes?.find((option) => option.value === 12)?.label}
                value={selectedDropdown(nyttMaaltid?.spisestedkode, 12)}
                options={allCodes.codes?.filter((f) => f.value === 12)}
                optionLabel={(option: CodeRegisterI) => option?.label}
                onChange={(value: any) =>
                  setNyttMaaltid({ ...nyttMaaltid, spisestedkode: value?.nr })
                }
                readOnly={!owned}
              />
            </Grid>
            <Grid item xs={12}>
              <Dropdown
                header={codeTypes?.find((option) => option.value === 19)?.label}
                value={selectedDropdown(nyttMaaltid?.sammenMed, 19)}
                options={allCodes.codes?.filter((f) => f.value === 19)}
                optionLabel={(option: CodeRegisterI) => option?.label}
                onChange={(value: any) =>
                  setNyttMaaltid({ ...nyttMaaltid, sammenMed: value?.nr })
                }
                readOnly={!owned}
              />
            </Grid>
          </Grid>
          <Grid item container xs={7}>
            <StyledTextarea
              owned={owned}
              header="Notat"
              name="notat"
              value={nyttMaaltid?.notat || ''}
              onChange={(event) =>
                setNyttMaaltid({ ...nyttMaaltid, notat: event.target.value })
              }
            />
            {/* <FullHeightTextField
              header="Notat"
              name="notat"
              value={nyttMaaltid?.notat || ''}
              onChange={(event) =>
                setNyttMaaltid({ ...nyttMaaltid, notat: event.target.value })
              }
              owned={owned}
              minRows={10}
            /> */}
          </Grid>
        </Grid>

        {showError && (
          <Box>
            <Typography variant="body2" color={'error'}>
              Ugyldig klokkeslett
            </Typography>
          </Box>
        )}

        <Stack direction={'row'} spacing={1} useFlexGap>
          <NButton
            children="Lagre"
            onClick={() => handleSubmit(true)}
            disabled={!owned}
          />
          <NButton
            children="Lagre og opprett nytt måltid"
            onClick={() => handleSubmit()}
            leftIcon={<AddIcon />}
            variant="outlined"
            disabled={!owned}
          />
          <NButton
            children="Nullstill"
            disabled={nyttMaaltid === null || !owned}
            onClick={() => setNyttMaaltid(null)}
            sx={{ ml: 'auto' }}
          />
        </Stack>
        <Box>
          <NButton children="Intervjudetaljer" variant="outlined" />
        </Box>
      </Stack>
    </DialogGeneral>
  );
}
