import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import useStyles from '../../../css/IntroCss';
import useFetchData from '../../utilities/useFetchData';
import { UserValues } from '../admin/AdminTypes';
import { useDatabaseContext } from '../../../context/DatabaseContext';
import { databaseOptions } from '../../../assets/DatabaseOptions';
import { AlertI } from '../../../types/alertTypes';
import AlertComp from '../../utilities/AlertComp';
import { useUserContext } from '../../../context/AuthContext';
import { useProjectContext } from '../../../context/ProjectContext';
import useGetKeycloakUser from '../../authentication/KeycloakObject';

export const Intro = () => {
  const classes = useStyles();
  const db = useDatabaseContext();
  const loggedInuser = useUserContext();
  const projs = useProjectContext();
  const { userInfo } = useGetKeycloakUser();

  const [showAlert, setShowAlert] = React.useState<boolean>(false);
  const [alertMessage, setAlertMessage] = React.useState<string>('');
  const [alertSeverity, setAlertSeverity] = React.useState<AlertI['alertColor']>('info');
  const defaultValues = useFetchData({
    loadOnMount: true,
    clearDataOnLoad: false,
    projectURL: 'getDefaultUserValues',
    subProjectURL: null
  });

  useEffect(() => {
    if (defaultValues.data) {
      const values: UserValues = defaultValues.data as unknown as UserValues;
      db.setCurrentDatabase(
        databaseOptions?.find((d) => d.value === values?.databaseId) || databaseOptions[0]
      );
      loggedInuser.setUser({
        username: userInfo?.username,
        id: values?.numberRange?.id,
        numberRange: [values?.numberRange?.from, values?.numberRange?.to],
        roles: userInfo?.realm_access.roles
      });
      projs.setProjects([]);
      /* if (!(values?.numberRange?.from > 0 && values?.numberRange?.to > 0)) {
        showAlertComponent('Feil nummerområdet. Vennligst prøv igjen senere.', 'error');
      } */
    }
  }, [defaultValues.data]);
  useEffect(() => {
    if (defaultValues.error) {
      showAlertComponent(
        'Det har oppstått en feil. Vennligst prøv igjen senere.',
        'error'
      );
    }
  }, [defaultValues.error]);
  const showAlertComponent = (message: string, severity: any) => {
    setShowAlert(true);
    setAlertMessage(message);
    setAlertSeverity(severity);
  };
  return (
    <>
      {showAlert && (
        <AlertComp
          alertMessage={alertMessage}
          alertColor={alertSeverity}
          showAlert={showAlert}
          setShowAlert={setShowAlert}
        />
      )}
      <Box style={{ maxWidth: '50rem' }}>
        <Typography sx={classes.NFCTitle}>NutriFoodCalc</Typography>
        <Typography sx={classes.Introdata} align="left">
          Velkommen til NutriFoodCalc. NutriFoodCalc (NFC) er et kostberegningssystem ved
          Avdeling for ernæringsvitenskap, Universitetet i Oslo.
        </Typography>
      </Box>
    </>
  );
};
