import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from '@mui/material';
import { Stack } from '@mui/system';
import React, { useEffect, useState } from 'react';
import DialogGeneral from '../../../../utilities/DialogGeneral';
import { EnhancedTableHead } from '../../../../utilities/EnhancedTableHead';
import { CodeRegisterI, HeadCell } from '../../../components/commons/CommonTypes';
import { NButton } from '../../../components/Inputs';
import { MaaltidI, StoffnMengdeI } from '../../types/PersonTypes';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';

interface Beregning {
  stoff: string;
  mengde: number;
  enhet: string;
}

const headCells: HeadCell<StoffnMengdeI>[] = [
  {
    id: 'kortnavn',
    align: 'left',
    padding: 'normal',
    label: 'Stoff'
  },
  {
    id: 'mengde',
    align: 'right',
    padding: 'normal',
    label: 'Mengde'
  },
  {
    id: 'enhet',
    align: 'left',
    padding: 'normal',
    label: 'Enhet'
  }
];

interface LopedagMaaltidsberegningModalProps {
  showing: boolean;
  hide: () => void;
  maaltidsData: MaaltidI[];
  stoffData: StoffnMengdeI[];
  maaltidTypes: CodeRegisterI[];
  currentMaaltidId: number;
}

export default function LopedagMaaltidsberegningModal(
  props: LopedagMaaltidsberegningModalProps
) {
  const { showing, hide, maaltidsData, currentMaaltidId, stoffData, maaltidTypes } =
    props;
  const [currentMaaltidsData, setCurrentMaaltidsData] = useState<MaaltidI | null>(null);
  useEffect(() => {
    setCurrentMaaltidsData(
      maaltidsData.find((maaltid) => maaltid.maaltidsId === currentMaaltidId)
    );
  }, [currentMaaltidId, maaltidsData]);

  return (
    <DialogGeneral
      title="Måltidsberegning"
      open={showing}
      hide={hide}
      fullWidth
      maxWidth="md"
    >
      <Stack spacing={2}>
        <Stack direction={'row'} alignItems="center" justifyContent="space-between">
          <Typography variant="body1">
            <b>Måltid: </b>
            {`${currentMaaltidsData?.klokkeslett} ${
              maaltidTypes?.find((m) => m.nr === currentMaaltidsData?.maaltidstype)?.label
            }
            `}
          </Typography>

          <NButton
            children="Skriv ut"
            rightIcon={<PrintOutlinedIcon />}
            variant="text"
            // onClick={() => setShowUtskrift(true)}
            disabled={!currentMaaltidsData}
          />
        </Stack>

        <TableContainer component={Paper} sx={{ maxHeight: '55vh' }}>
          <Table size="small">
            <EnhancedTableHead<StoffnMengdeI> headCells={headCells} />
            <TableBody>
              {stoffData?.map((row: StoffnMengdeI) => (
                <TableRow>
                  {headCells?.map((cell) => (
                    <TableCell key={cell.id} align={cell.align} padding={cell.padding}>
                      {row[cell.id]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </DialogGeneral>
  );
}
