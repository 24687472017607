export const databaseOptions = [
  {
    value: 20,
    label: 'AE-22'
  },
  {
    value: 18,
    label: 'AE-18'
  },
  {
    value: 22,
    label: 'N4'
  },
  {
    value: 21,
    label: 'N4 pilot'
  },
  {
    value: 16,
    label: 'N3 nøkkelhull'
  },
  {
    value: 15,
    label: 'AE-14'
  },
  {
    value: 14,
    label: 'MVT-13'
  },
  {
    value: 13,
    label: 'AE-13'
  },
  {
    value: 12,
    label: 'MVT-12'
  },
  {
    value: 11,
    label: 'N3'
  },
  {
    value: 10,
    label: 'AE-10'
  },
  {
    value: 7,
    label: 'AE-07'
  },
  {
    value: 6,
    label: 'MVT-06'
  },
  {
    value: 4,
    label: 'IE95'
  },
  {
    value: 3,
    label: 'IE77'
  },
  {
    value: 2,
    label: 'IE91'
  },
  {
    value: 1,
    label: 'IE96'
  }
];
