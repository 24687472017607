import React, { useEffect, useState } from 'react';
import { DialogBox } from '../../../utilities/DialogBox';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import useFetchData from '../../../utilities/useFetchData';
import useStylesTable from '../../../../css/TableCss';
import { Matvare } from '../types/matvaregruppeTypes';
import {
  BeregningObject,
  DefaultBeregningCounts,
  UtvalgTableData
} from '../../beregning/types/BeregningTypes';
import useDidMountEffect from '../../../utilities/useDidMountEffect';
import { StoffI } from '../../stoff/types/StoffTypes';
interface UtvalgTableDataProps {
  beregningObject: BeregningObject;
  totalCounts: DefaultBeregningCounts;
  setTotalCounts: (value: DefaultBeregningCounts) => void;
  utvalgData: UtvalgTableData;
  setUtvalgData: (value: UtvalgTableData) => void;
  hide: () => void;
}
export const UtvalgTable = (props: UtvalgTableDataProps) => {
  const { beregningObject, setTotalCounts, utvalgData, setUtvalgData, hide } = props;
  const [showDialogbox, setShowDialogbox] = useState(false);
  const title = 'Vis utvalg';
  const classesTable = useStylesTable();
  const [pageM, setPageM] = useState(0);
  const [rowsPerPageM, setRowsPerPageM] = useState(20);
  const [pageS, setPageS] = useState(0);
  const [rowsPerPageS, setRowsPerPageS] = useState(20);

  const [loadUtvalgData, setLoadUtvalgData] = useState(false);
  const retrieveDataFromUtvalgTable = useFetchData({
    loadOnMount: false,
    clearDataOnLoad: false,
    projectURL: 'getUtvalgTableData',
    subProjectURL: `${beregningObject?.databaseId}/${encodeURI(
      beregningObject?.utvalgId
    )}/${beregningObject?.matvareData?.gruppetypeId}`
  });
  /*   useEffect(() => {
    if (!beregningObject?.beregn) {
      if (!beregningObject?.inputsSaved) {
        setShowSaveBeregningInput(true);
      }
    }
  }, [beregningObject?.inputsSaved, beregningObject?.beregn]); */
  useDidMountEffect(() => {
    if (loadUtvalgData) {
      retrieveDataFromUtvalgTable.loadData(
        'getUtvalgTableData',
        `${beregningObject?.databaseId}/${encodeURI(beregningObject?.utvalgId)}/${
          beregningObject?.matvareData?.gruppetypeId
        }`
      );
    }
  }, [loadUtvalgData]);
  useEffect(() => {
    if (retrieveDataFromUtvalgTable.data) {
      const data = retrieveDataFromUtvalgTable?.data as unknown as UtvalgTableData;
      setDataTodisplay(data);
    }
  }, [retrieveDataFromUtvalgTable.data]);
  useEffect(() => {
    if (utvalgData) {
      setDataTodisplay(utvalgData);
    }
  }, [utvalgData]);
  const setDataTodisplay = (data: UtvalgTableData) => {
    setTotalCounts({
      TOTALMATVAREGRUPPE: data?.matvaregroupData?.length,
      TOTALMATVARE: data?.matvaredata?.length,
      TOTALFJERNDEMATVARE: data?.fjerndeMatvareData?.length,
      TOTALSTOFFCOUNT: data?.stoffData?.length
    });
    setUtvalgData(data);
    setShowDialogbox(true);
    setLoadUtvalgData(false);
  };
  const handleCloseMatavreGruppe = () => {
    setShowDialogbox(false);
    hide();
  };
  const handleChangePageM = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    newPage: number
  ) => {
    setPageM(newPage);
  };
  const handleChangeRowsPerPageM = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPageM(+event.target.value);
    setPageM(0);
  };
  const handleChangePageS = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    newPage: number
  ) => {
    setPageS(newPage);
  };
  const handleChangeRowsPerPageS = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPageS(+event.target.value);
    setPageS(0);
  };
  return (
    <>
      {/*  {showSaveBeregningInput && (
        <SaveInputs
          beregningObject={beregningObject}
          showAlertMessage={showAlertMessage}
          setBeregningObject={setBeregningObject}
          setTotalCounts={setTotalCounts}
          setUtvalgData={setUtvalgData}
          setShowProgress={setShowProgress}
          setProgressText={setProgressText}
        />
      )} */}
      {showDialogbox ? (
        <DialogBox
          title={title}
          isShowing={showDialogbox}
          hide={handleCloseMatavreGruppe}
        >
          <Grid container spacing={3} padding={2}>
            <Grid container>
              <Grid item xs={5}>
                <Typography>
                  Beregningsutvalg: <b>{beregningObject?.utvalgId}</b>
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography>
                  ResultatGruppe:{' '}
                  <b>{beregningObject?.matvareData?.resultatGroup || ''}</b>
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}></Grid>
            <Grid container spacing={1}>
              {/* {showTableMatvarer && ( */}
              <Grid item xs={6}>
                Matvarer: <b>{utvalgData?.matvaredata?.length || 0}</b>
                <Table sx={classesTable.body}>
                  <TableHead sx={classesTable.head}>
                    <TableRow>
                      <TableCell>Kode</TableCell>
                      <TableCell>Navn</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {}
                    {utvalgData?.matvaredata
                      ?.slice(pageM * rowsPerPageM, pageM * rowsPerPageM + rowsPerPageM)
                      .map((row: Matvare) => (
                        <TableRow key={row.MATVAREKODEID}>
                          <TableCell>{row.MATVAREKODEID}</TableCell>
                          <TableCell>{row.NAVN}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[]}
                  component="div"
                  count={utvalgData?.matvaredata?.length || 0}
                  rowsPerPage={rowsPerPageM}
                  page={pageM}
                  onPageChange={handleChangePageM}
                  onRowsPerPageChange={handleChangeRowsPerPageM}
                />
              </Grid>

              <Grid item xs={6}>
                Enkeltstoffer : <b>{utvalgData?.stoffData?.length || 0}</b>
                <Table sx={classesTable.body}>
                  <TableHead sx={classesTable.head}>
                    <TableRow>
                      <TableCell>Nr</TableCell>
                      <TableCell>Navn</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {}
                    {utvalgData?.stoffData
                      ?.slice(pageS * rowsPerPageS, pageS * rowsPerPageS + rowsPerPageS)
                      .map((row: StoffI) => {
                        return (
                          <TableRow key={row.stoffnrId}>
                            <TableCell>{row.stoffnrId}</TableCell>
                            <TableCell>{row.navn}</TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[]}
                  component="div"
                  count={utvalgData?.stoffData?.length || 0}
                  rowsPerPage={rowsPerPageS}
                  page={pageS}
                  onPageChange={handleChangePageS}
                  onRowsPerPageChange={handleChangeRowsPerPageS}
                />
              </Grid>
            </Grid>
          </Grid>
        </DialogBox>
      ) : undefined}
    </>
  );
};
