import React from 'react';
import { Box, Typography } from '@mui/material';
const useStylesFooter = () => {
  return {
    footer: {
      background: '#1C5081',
      minHeight: '5rem'
    },
    footerText: {
      color: '#FFFFFF',
      top: '0px',
      textAlign: 'center',
      justifyItems: 'center'
    },
    bottomFooter: {
      background: '#4977AF'
    },
    largeText: {
      fontSize: '26px',
      color: '#FFFFFF',
      fontFamily: 'Roboto',
      fontWeight: 'bold',
      fontStyle: 'normal',
      lineHeight: '30px'
    },
    otherText: {
      fontFamily: 'Roboto',
      fontWeight: 'bold',
      fontStyle: 'normal',
      color: '#FFFFFF'
    }
  };
};

export default function Footer() {
  const classes = useStylesFooter();
  return (
    <>
      <Box sx={classes.footer}>
        <div>
          <div style={{ padding: '10px 0 0 35px', float: 'left' }}>
            <Typography sx={classes.largeText}>NFC</Typography>
            <Typography sx={classes.otherText}>NutriFoodCalc</Typography>
          </div>
          <div style={{ padding: '10px 35px 0 0', float: 'right' }}>
            <Typography sx={classes.largeText}>Kontakt oss</Typography>
            <Typography sx={classes.otherText}>nfc-drift@usit.uio.no</Typography>
          </div>
        </div>
      </Box>
      <Box sx={classes.bottomFooter}>
        <Typography sx={classes.footerText}>&copy;2021 Universitetet i Oslo</Typography>
      </Box>
    </>
  );
}
