import React from 'react';
import { useRef, useState } from 'react';
import {
  Box,
  Grid,
  InputLabel,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';
import ReactToPrint from 'react-to-print';
import moment from 'moment';
import CopyOppskrift from './CopyOppskrift';
import { IngredientData, MatvareData } from '../types/MatvareTypes';
import useDidMountEffect from '../../../utilities/useDidMountEffect';
import IsOwnedByUser from '../../../utilities/Validate';
import { tilberedningKodes } from '../../../../assets/OppskriftOptions';
import { Dropdown, NButton } from '../../components/Inputs';
import { OptionType, OptionTypeS } from '../../components/commons/CommonTypes';
import { useUserContext } from '../../../../context/AuthContext';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
interface ComponentToPrintProps {
  currentUser: string;
  data: IngredientData[];
  currentDatabase: OptionType;
  kode: number;
  navn: string;
  vektEndring: number;
  tilberedning: string;
  sumIngredienser: string;
}
const ComponentToPrint = React.forwardRef((props: ComponentToPrintProps, ref: any) => (
  <div ref={ref}>
    <p>
      Utskriftsdato:
      {moment().format('YYYY-MM-DD hh:mm:ss')}
    </p>
    <p>Matvaredatabase : {props.currentDatabase.label}</p>
    <br></br>
    <p>
      Matvare: {props.kode}-{props.navn}
    </p>
    <p>Tilberedning : {props.tilberedning}</p>
    <p>Vektendring : {props.vektEndring}</p>
    <br></br>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>{'Sortering'}</TableCell>
          <TableCell>{'Kode'}</TableCell>
          <TableCell>{'Navn'}</TableCell>
          <TableCell>{'Gram'}</TableCell>
          <TableCell>{'Enhet'}</TableCell>
          <TableCell>{'Antall'}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {props.data?.map((row: IngredientData) => {
          return (
            <TableRow hover role="checkbox" key={`${row.SORT}`}>
              <TableCell>{row?.SORT}</TableCell>
              <TableCell>{row?.INGREDIENSID}</TableCell>
              <TableCell>{row?.NAVN}</TableCell>
              <TableCell>{row?.GRAM}</TableCell>
              <TableCell>{row?.MENGDEENHET}</TableCell>
              <TableCell>{row?.ANTALL}</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
    <Box display="flex" justifyContent="flex-end">
      <label style={{ textAlign: 'left' }}>
        Sum ingredienser: {props.sumIngredienser}
      </label>
    </Box>
  </div>
));

const tilberedningOptions: OptionTypeS[] = tilberedningKodes;
interface IconsMenuOppskriftProps {
  classes: any;
  kodeFromURL: number;
  navnFromURL: string;
  vektEndring: number;
  currentDatabase: OptionType;
  owned: boolean;
  ingredientsList: IngredientData[];
  sumIngredienser: string;
  matvareList: MatvareData[];
  tilberedning: OptionTypeS;
  showAlertComponent: (message: string, severity: any) => void;
  updateTilberedning: (value: OptionTypeS) => void;
}

export default function IconsMenuOppskrift(props: IconsMenuOppskriftProps) {
  const {
    classes,
    kodeFromURL,
    navnFromURL,
    vektEndring,
    currentDatabase,
    owned,
    ingredientsList,
    showAlertComponent,
    sumIngredienser,
    matvareList,
    tilberedning,
    updateTilberedning
  } = props;
  const loggedInuser = useUserContext();
  const [openCopyOppskrift, setOpenCopyOppskrift] = useState(false);
  const [ownedMatvares, setOwnedMatvares] = useState<MatvareData[]>([]);
  const componentRef = useRef();
  const currentUser = loggedInuser?.user?.username;
  const toggleShowCopy = () => {
    if (ownedMatvares?.length > 0) {
      setOpenCopyOppskrift(!openCopyOppskrift);
    } else {
      //TODO: Make sure this message makes sense
      showAlertComponent(
        'Du har ikke matvare som du eier til å kopiere oppskriften til. ',
        'error'
      );
    }
  };
  useDidMountEffect(() => {
    if (matvareList?.length > 0) {
      const filteredMatvares = matvareList?.filter((row) => {
        return IsOwnedByUser({ id: +row.MATVAREKODEID, u: loggedInuser });
      });
      setOwnedMatvares(filteredMatvares);
    } else {
      console.log('No matvare data...');
    }
  }, [matvareList]);
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <InputLabel style={{ textAlign: 'left' }} sx={classes.InputLabel}>
            Matvare:{' '}
            <b>
              {kodeFromURL} - {navnFromURL}
            </b>
          </InputLabel>
        </Grid>
        <Grid item xs={4}>
          <Box display="flex" justifyContent="flex-start">
            <InputLabel style={{ textAlign: 'left' }} sx={classes.InputLabel}>
              Tilberedning:{' '}
            </InputLabel>
            <Dropdown
              header=""
              value={tilberedning || tilberedningOptions[0]}
              options={tilberedningOptions}
              optionLabel={(o) => o.label}
              onChange={(v) => updateTilberedning(v as OptionTypeS)}
              disabled={!owned}
              disableClearable={true}
              width={'70%'}
            />
          </Box>
        </Grid>
        <Grid item xs={4}>
          <InputLabel style={{ textAlign: 'right' }} sx={classes.InputLabel}>
            Vektendring: {vektEndring || 0}
          </InputLabel>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} justifyContent="flex-end">
          <Stack direction="row-reverse" spacing={2}>
            <ReactToPrint
              trigger={() => (
                <NButton
                  children="Skriv ut"
                  rightIcon={<PrintOutlinedIcon />}
                  variant="text"
                  disabled={ingredientsList?.length < 1}
                />
              )}
              pageStyle=".styledTable{width:100%;border-collapse:collapse;text-align:left}.styledTable th,.styledTable td{border:2px solid black;padding:0.5em;}"
              content={() => componentRef.current}
            />
            <Box sx={{ display: 'none' }}>
              {ingredientsList && (
                <ComponentToPrint
                  data={ingredientsList}
                  currentUser={currentUser}
                  currentDatabase={currentDatabase}
                  ref={componentRef}
                  kode={kodeFromURL}
                  navn={navnFromURL}
                  vektEndring={vektEndring}
                  tilberedning={tilberedning?.label}
                  sumIngredienser={sumIngredienser}
                />
              )}
            </Box>
            <NButton
              children="Kopier"
              rightIcon={<ContentCopyOutlinedIcon />}
              variant="text"
              onClick={toggleShowCopy}
              disabled={ingredientsList?.length < 1}
            />
            <CopyOppskrift
              openCopyOppskrift={openCopyOppskrift}
              handleClickCopy={toggleShowCopy}
              setOpenCopyOppskrift={setOpenCopyOppskrift}
              matvarekodeId={kodeFromURL}
              matvareNavn={navnFromURL}
              currentDatabase={currentDatabase}
              showAlertComponent={showAlertComponent}
              ownedMatvares={ownedMatvares}
            />
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}
