import Keycloak from 'keycloak-js';

// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'

/* export const keycloakDev = Keycloak({
  realm: 'nfc-test',
  url: 'https://keycloak-nfc-test.okd-pilot-apps.uio.no/auth/',
  clientId: 'nfc-frontend-dev'
}); */

export const keycloakDev4 = Keycloak({
  realm: 'nfc-test',
  url: 'https://keycloak-nutrifoodcalc-utv.apps.okd-dev.uio.no/auth/',
  clientId: 'nfc-frontend-dev'
});

/* export const keycloakTest = Keycloak({
  realm: 'nfc-test',
  url: 'https://keycloak-nfc-test.okd-pilot-apps.uio.no/auth/',
  clientId: 'nfc-frontend'
}); */

export const keycloakTest4 = Keycloak({
  realm: 'nfc-test',
  url: 'https://keycloak-nutrifoodcalc-utv.apps.okd-dev.uio.no/auth/',
  clientId: 'nfc-frontend'
});

export const keycloakProd = Keycloak({
  realm: 'nfc-prod',
  url: 'https://keycloak-nfc.uio.no/auth/',
  clientId: 'nfc-frontend-prod'
});
