import React from 'react';
import { Grid, Box, Divider } from '@mui/material';
import moment from 'moment';
import { useUserContext } from '../../../../context/AuthContext';
/*
ToDo:
Createing a matvare report. Needed:
Page numbering? Þetta er óvíst eins og staðan er núna.
1 skýrslu fyrir hverja valda matvöru.
þá þarf Map yfir fylkið
Hér þarf að vera main container líka
*/

export interface SingleMatvareReport {
  ANNENKODE: String | null;
  BESKRIVELSE: String | null;
  BRUTTOBEREGNING: String | null;
  ENDRET: String | null;
  KORTNAVN: String | null;
  MAKSIMALMENGDE: String | null;
  MATVAREKODEID: number | null;
  MDB_NAVN: String | null;
  NAVN: String | null;
  OPPRETTET: String | null;
  OPPSKRIFT: String | null;
  REFERANSE: String | null;
  SEDON: String | null;
  SENR: String | null;
  SPISELIG: String | null;
  SSBNR: String | null;
  STATUS: String | null;
  VEKTENDRING: String | null;
}
interface MendeenheterDataProps {
  SORTERING: number | null;
  MENGDEENHETID: string | null;
  MENGDE: number | null;
  REFERANSE: string | null;
}
export interface MatvaregruppeProps {
  BESKRIVELSE: string | null;
  GRUPPETYPEID: number | null;
  MDB_NAVN: string | null;
  NAVN: string | null;
  NODENRID: number | null;
}
interface MatvareIngredienserProps {
  INGREDIENSID: number | null;
  MDB_NAVN: string | null;
  MENGDE: number | null;
  MENGDEENHET: string | null;
  NAVN: string | null;
  ORDNRID: number | null;
}
interface MatvareStoffProps {
  ENHET: string | null;
  KILDE: string | null;
  KORTNAVN: string | null;
  MATVAREKODEID: number | null;
  MDB_NAVN: string | null;
  MENGDE: string | null;
  NAVN: string | null;
  STOFFNRID: number | null;
}
interface MatvareReport {
  reportData: SingleMatvareReport[];
  reportMengdeenheter: MendeenheterDataProps[];
  reportMatvaregruppe: MatvaregruppeProps[];
  reportMatvareIngredianse: MatvareIngredienserProps[];
  reportMatvareStoff: MatvareStoffProps[];
}
const Field = (props: any) => {
  return (
    <Grid item xs={props.size}>
      <p style={{ fontSize: 13 }}>
        <b>{props.name}</b> {props.value}
      </p>
    </Grid>
  );
};

const MatvareReport = React.forwardRef((props: MatvareReport, ref: any) => {
  const {
    reportData,
    reportMengdeenheter,
    reportMatvaregruppe,
    reportMatvareIngredianse,
    reportMatvareStoff
  } = props;
  const loggedInuser = useUserContext();
  const username = loggedInuser?.user?.username;

  return (
    <div ref={ref}>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
          <Grid item xs={3}></Grid>
          <Grid item xs={6}></Grid>
          <Grid item xs={3}>
            <p style={{ fontSize: 10 }}>Bruker {username}</p>
          </Grid>

          <Grid item xs={3}></Grid>
          <Grid item xs={6}>
            <h2>Alt om Matvare</h2>
            <h4>Matvaredatabase: {reportData[0].MDB_NAVN}</h4>
          </Grid>
          <Grid item xs={3}></Grid>

          <Divider />

          <Field name={'Kode'} value={reportData[0].MATVAREKODEID} size={3} />

          <Field name={'Navn'} value={reportData[0].NAVN} size={3} />

          <Field
            name={'Opprettet:'}
            value={moment(`${reportData[0].OPPRETTET}`, 'YYYY-MM-DD').format(
              'YYYY-MM-DD'
            )}
            size={3}
          />

          <Field
            name={'Endret:'}
            value={moment(`${reportData[0].ENDRET}`, 'YYYY-MM-DD').format('YYYY-MM-DD')}
            size={3}
          />

          <Divider />

          <Field name={'Beskrivelse:'} value={reportData[0].BESKRIVELSE} size={12} />

          <Field name={'Referanse:'} value={reportData[0].REFERANSE} size={12} />

          <Divider />

          <Field name={'Maks.mengde:'} value={reportData[0].MAKSIMALMENGDE} size={4} />

          <Field name={'Spiselig del:'} value={reportData[0].SPISELIG} size={4} />

          <Field name={'Vektendring:'} value={reportData[0].VEKTENDRING} size={4} />

          <Divider />

          <Field name={'Status:'} value={reportData[0].STATUS} size={4} />

          <Field name={'Bruttober.:'} value={reportData[0].BRUTTOBEREGNING} size={4} />

          <Field name={'Annen kode:'} value={reportData[0].ANNENKODE} size={4} />

          <Divider />

          <Field name={'MVT-nr:'} value={reportData[0].REFERANSE} size={4} />

          <Field name={'SSBnr:'} value={reportData[0].SSBNR} size={4} />

          <Field name={'Sedon'} value={reportData[0].SEDON} size={4} />

          <Divider />

          <Grid item xs={12} style={{ paddingTop: 15 }}>
            <b>Mengdeenheter</b>
          </Grid>
          {reportMengdeenheter.map((item: MendeenheterDataProps, i: number) => (
            <React.Fragment key={`${item.MENGDEENHETID}-${i}`}>
              <Grid item xs={2}>
                {item.MENGDEENHETID}
              </Grid>
              <Grid item xs={2}>
                {item.MENGDE}
              </Grid>
            </React.Fragment>
          ))}

          <Grid item xs={12} style={{ paddingTop: 15 }}>
            <b>Matvaregrupper</b>
          </Grid>
          {reportMatvaregruppe.map((item: MatvaregruppeProps, i: number) => (
            <React.Fragment key={`${item.NAVN}-${i}`}>
              <Grid item xs={1}>
                {item.GRUPPETYPEID}
              </Grid>
              <Grid item xs={3}>
                {item.BESKRIVELSE}
              </Grid>
              <Grid item xs={2}></Grid>
              <Grid item xs={1}>
                {item.NODENRID}
              </Grid>
              <Grid item xs={3}>
                {item.NAVN}
              </Grid>
              <Grid item xs={2}></Grid>
            </React.Fragment>
          ))}

          <Divider />

          <Grid item xs={12} style={{ paddingTop: 15 }}>
            <b>Ingredienser</b>
          </Grid>
          {reportMatvareIngredianse.map((item: MatvareIngredienserProps) => (
            <React.Fragment key={`${item.NAVN}-${item.INGREDIENSID}`}>
              <Grid item xs={1}>
                {item.INGREDIENSID}
              </Grid>
              <Grid item xs={3}>
                {item.NAVN}
              </Grid>
              <Grid item xs={2}></Grid>
              <Grid item xs={1}>
                {item.MENGDE}
              </Grid>
              <Grid item xs={3}>
                {item.MENGDEENHET}
              </Grid>
              <Grid item xs={2}></Grid>
            </React.Fragment>
          ))}

          <Divider />

          <Grid item xs={12} style={{ paddingTop: 15 }}>
            <b>Stoffkompoenter pr 100 g matvare</b>
          </Grid>

          {reportMatvareStoff.map((item: MatvareStoffProps, i: number) => (
            <React.Fragment key={`${item.NAVN}-${i}`}>
              <Grid item xs={2}>
                {item.KORTNAVN}
              </Grid>
              <Grid item xs={2}>
                {`${item.MENGDE} ${item.ENHET}`}
              </Grid>
              <Grid item xs={2}>
                {item.KILDE}
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      </Box>
    </div>
  );
});
export default MatvareReport;
