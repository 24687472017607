import ReactDOM from 'react-dom';
import React, { useState, useEffect, useRef } from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText,
  Typography,
  IconButton,
  useTheme,
  withStyles
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MuiDialogTitle from '@mui/material/DialogTitle';
import { WithStyles } from '@mui/styles';
// import green from "@mui/material/colors/green";
// import PrintIcon from '@mui/icons-material/Print';
// import DeleteIcon from '@mui/icons-material/Delete';
// import SaveIcon from '@mui/icons-material/Save';

const styles = () => {
  //const theme = useTheme();
  return {
    root: {
      // padding: theme.spacing(1),
      background: '#4977AF',
      color: 'white',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    closeButton: {
      color: 'white'
    }
  };
};

export interface DialogTitleProps {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;
  const classes = styles();
  return (
    <MuiDialogTitle sx={classes.root} {...other}>
      <Typography variant="h6" component={'div'}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton aria-label="close" sx={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};
export const DialogBox = (props: any) => {
  //const [text] = useState(props.text);
  //const [title] = useState(props.title);
  const [openCDialog, setOpenCDialog] = useState(false);

  //const { data } = useFetch(backendURI + '/getMatvareEndringslog/' + currentUser + '/' + currentDatabase["value"] + '/' + selectedCode);
  const isShowing = props.isShowing;
  useEffect(() => {
    setOpenCDialog(true);
  }, []);

  const handleCloseDialog = () => {
    console.log('CLOSING');
    setOpenCDialog(false);
    props.hide();
  };

  const componentRef = useRef();

  return { isShowing }
    ? ReactDOM.createPortal(
        <div>
          <Dialog
            //disableBackdropClick={true}
            open={openCDialog}
            aria-labelledby="customized-dialog-title"
            onClose={(event: any, reason: string) => (
              handleCloseDialog(), 'backdropClick'
            )}
            maxWidth={props.size || 'xl'}
            ref={componentRef}
          >
            <DialogContent>
              <DialogTitle id="customized-dialog-title" onClose={handleCloseDialog}>
                {props.title}
              </DialogTitle>
              <DialogContent dividers>
                <DialogActions>
                  <DialogContentText component={'div'} className="MuiDialogContentText">
                    {props.children}
                  </DialogContentText>
                </DialogActions>
              </DialogContent>
            </DialogContent>
          </Dialog>
        </div>,

        document.body
      )
    : null;
};
