import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { useFetchNonGET } from '../../utilities/useFetchNonGET';
import { DatabaseContext } from '../../../context/DatabaseContext';
import { useHistory } from 'react-router';
import { useFetch } from '../../utilities/useFetch';
//import useFetchData from '../../utilities/useFetchData';
/*
 * This is the original list of things to do:
 * checkundersokelse
 * check oppskrift
 * check stoff/oppskriftlinje
 * Press confirm
 * delete
 * Show text. It works or It fails.
 */
export default function NewSletteMatvare(props: any) {
  const history = useHistory();
  const sletteId = props.matvarekodeId; //props.matvarekodeId;
  const [sletteMatvareComformation, setSletteMatvareComformation] = React.useState(true);

  const [matvareIUndersokelseState, doFetchMatvareIUndersokelse] = useFetch(); //refetchU
  const [matvareIOppskriftState, doFetchMatvareIOppskrift] = useFetch(); //, refetchO
  const [matvareIStoffState, doFetchMatvareIStoff] = useFetch(); //refetchS

  const [deleteingMatvare, setDeleteingMatvare] = useFetchNonGET();
  const backendURI = process.env.REACT_APP_BACKEND;

  const { currentDatabase } = React.useContext(DatabaseContext);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      if (deleteingMatvare.status === 200) {
        history.push('/home/matvaredata/matvare');
      }
    }, 3000);
    return () => clearTimeout(timer);
  }, [deleteingMatvare.status]);

  const checkundersokelse = async () => {
    doFetchMatvareIUndersokelse(
      `${backendURI}/checkMatvareInUndersokelse/${currentDatabase?.value}/${sletteId}`
    );
    doFetchMatvareIOppskrift(
      `${backendURI}/checkMatvareInOppskrift/${currentDatabase?.value}/${sletteId}`
    );
    doFetchMatvareIStoff(
      `${backendURI}/checkMatvareInStoff/${currentDatabase?.value}/${sletteId}`
    );
  };

  const deleteMatvare = () => {
    let url = `${backendURI}/deleteMatvare/${currentDatabase?.value}/${props.matvarekodeId}`;
    let method = 'DELETE';
    let data = {};
    setDeleteingMatvare({ url, data, method });
  };

  const handleClickJa = () => {
    setSletteMatvareComformation(false);
    checkundersokelse();
  };
  const handleClickComfirmDelete = () => {
    deleteMatvare();
  };
  const handleClickNei = () => {
    props.close();
  };

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      open={props.open}
      onClose={props.close}
      aria-labelledby="sletteMatvare"
    >
      <DialogTitle id="sletteMatvare">Slett Matvare</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Vil du slette disse matvate? MatvareId: <b>{sletteId}</b>
        </DialogContentText>
      </DialogContent>

      {sletteMatvareComformation && (
        <DialogActions>
          <Button onClick={handleClickJa} color="primary">
            Ja
          </Button>
          <Button onClick={handleClickNei} color="primary" autoFocus>
            Nei
          </Button>
        </DialogActions>
      )}

      <DialogContent>
        <div>Steg 1. Sjekker oppskrifter </div>

        {matvareIOppskriftState.loading && (
          <>
            <CircularProgress size={12} color="primary" /> Sjekker alle oppskrifter...
          </>
        )}
        {matvareIOppskriftState.data &&
          matvareIOppskriftState.data[0]['COUNT(MATVAREKODEID)'] === 0 && (
            <>
              <DoneIcon htmlColor="green" /> Matvare er ikke i oppskrifter.
            </>
          )}
        {matvareIOppskriftState.data &&
          matvareIOppskriftState.data[0]['COUNT(MATVAREKODEID)'] > 0 && (
            <>
              <CloseIcon htmlColor="red" /> 'Matvaren inneholder underliggende informasjon
              (oppskrift eller stoffkomponenter).'. matvareKode:{' '}
              {matvareIOppskriftState.data[0]['COUNT(MATVAREKODEID)']}
            </>
          )}

        <div>Steg 2. Sjekker stoff/oppskriftlinje</div>
        {matvareIStoffState.loading && (
          <>
            <CircularProgress size={12} color="primary" /> Sjekker alle
            stoff/oppskriftlinje...
          </>
        )}
        {matvareIStoffState.data &&
          matvareIStoffState.data[0]['COUNT(MATVAREKODEID)'] === 0 && (
            <>
              <DoneIcon htmlColor="green" /> Matvare er ikke i stoff/oppskriftlinje.
            </>
          )}
        {matvareIStoffState.data &&
          matvareIStoffState.data[0]['COUNT(MATVAREKODEID)'] > 0 && (
            <>
              <CloseIcon htmlColor="red" /> Matvaren er brukt i følgende
              stoff/oppskriftlinje undersokelser: matvareKode:
              {matvareIStoffState.data[0]['COUNT(MATVAREKODEID)']}
            </>
          )}

        <div>Steg 3. Sjekker undersokelse </div>
        {matvareIUndersokelseState.loading && (
          <>
            <CircularProgress size={12} color="primary" /> Sjekker alle undersokelse...
          </>
        )}
        {matvareIUndersokelseState.data &&
          matvareIUndersokelseState.data.length === 0 && (
            <>
              <DoneIcon htmlColor="green" /> Matvare er ikke i undersokelse.
            </>
          )}
        {matvareIUndersokelseState.data && matvareIUndersokelseState.data.length > 0 && (
          <>
            <CloseIcon htmlColor="red" /> Matvaren er brukt i følgende
            kostholdsundersøkelser:{' '}
            {matvareIUndersokelseState.data.map((row: any) => (
              <div key={row.PROSJEKTID}>
                {' '}
                {`ProsjektId: ${row.PROSJEKTID}; UndersoklseId: ${row.UNDERSOKELSEID} `}{' '}
              </div>
            ))}
            undersokelser. Vil du slette denne matvaren?'
          </>
        )}
        {deleteingMatvare.loading === false && deleteingMatvare.status === 200 && (
          <div style={{ border: ' thick solid green' }}>
            <>
              {' '}
              <DoneIcon htmlColor="green" />
              Slettingen er fullført{' '}
            </>
          </div>
        )}
        {deleteingMatvare.loading === false && deleteingMatvare.error && (
          <div style={{ border: ' thick solid red' }}>
            <>
              {' '}
              <CloseIcon htmlColor="red" />
              lettingen mislyktes. Ingen sletting er utført mot databasen.{' '}
            </>
          </div>
        )}
        {/* We have 2 comformation windows. First is to say 'JA' to start deleteing data. 
          Second is to comfirm delete data.
        */}
        {!sletteMatvareComformation && (
          <DialogActions>
            <Button
              variant="contained"
              onClick={handleClickComfirmDelete}
              color="error"
              style={{ float: 'left' }}
            >
              Slett
            </Button>
            <Button
              variant="contained"
              onClick={handleClickNei}
              color="primary"
              autoFocus
            >
              Avbryt
            </Button>
          </DialogActions>
        )}
      </DialogContent>
    </Dialog>
  );
}
