import React, { ChangeEvent, forwardRef, MouseEvent, useRef, useState } from 'react';
import {
  Box,
  Checkbox,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { PersonIUndersokelse } from './types/UndersokelseTypes';
import { TableComponents, TableVirtuoso } from 'react-virtuoso';
import { getComparator, Order } from '../../utilities/EnhancedTableHead';
import { HeadCell } from '../components/commons/CommonTypes';

interface PersonerTableI
  extends Omit<PersonIUndersokelse, 'maal' | 'lopedager' | 'tilleggsSvar'> {}

const headCells: HeadCell<PersonerTableI>[] = [
  { id: 'personId', label: 'Personid', align: 'left', width: 48 },
  { id: 'identitet', label: 'Ident', align: 'left', width: 25 },
  { id: 'kjonn', label: 'Kjønn', align: 'left', width: 35 },
  { id: 'alder', label: 'Alder', align: 'left', width: 25 },
  { id: 'personStatus', label: 'Status person', align: 'left', width: 112 },
  { id: 'intervjuer1', label: 'Intervj.1', align: 'left', width: 58 },
  { id: 'status1', label: 'Status dag 1', align: 'left', width: 90 },
  { id: 'dato1', label: 'Siste oppr', align: 'left', width: 55 },
  { id: 'intervjuer2', label: 'Intervj.2', align: 'left', width: 58 },
  { id: 'status2', label: 'Status dag 2', align: 'left', width: 98 },
  { id: 'dato2', label: 'Siste oppr.', align: 'left', width: 55 },
  { id: 'skjemaStatus', label: 'Status skjema', align: 'left', width: 95 },
  { id: 'skjemaSendt', label: 'Skjema sendt', align: 'left', width: 50 },
  { id: 'purringSendt', label: 'Purring sendt', align: 'left', width: 55 },
  { id: 'skjemaMottatt', label: 'Skjema mottatt', align: 'left', width: 55 },
  {
    id: 'tilbakemeldingsSkjema',
    label: 'Status tilbakemelding',
    align: 'left',
    width: 110
  },
  {
    id: 'tilbakemeldingSendt',
    label: 'Tilb.m. sendt',
    align: 'left',
    width: 55
  },
  { id: 'notat', label: 'Notat', align: 'left', width: 140 }
];

const VirtuosoTableComponents: TableComponents<PersonerTableI> = {
  Scroller: forwardRef<HTMLDivElement>((props, ref) => (
    <TableContainer component={Paper} {...props} ref={ref} />
  )),
  Table: (props) => (
    <Table
      {...props}
      size={'small'}
      sx={{ borderCollapse: 'separate', tableLayout: 'auto' }}
    />
  ),
  TableHead,
  TableRow: ({ item: _item, ...props }) => (
    <TableRow {...props} hover sx={{ cursor: 'pointer' }} />
  ),
  TableBody: forwardRef<HTMLTableSectionElement>((props, ref) => (
    <TableBody {...props} ref={ref} />
  ))
};

interface FixedHeaderContentProps {
  numSelected: number;
  onRequestSort: (property: keyof PersonerTableI) => void;
  onSelectAllClick: (event: ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function FixedHeaderContent(props: FixedHeaderContentProps) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;

  return (
    <TableRow>
      <TableCell padding="checkbox">
        <Checkbox
          indeterminate={numSelected > 0 && numSelected < rowCount}
          checked={rowCount > 0 && numSelected === rowCount}
          onChange={onSelectAllClick}
          inputProps={{
            'aria-label': 'select all persons'
          }}
        />
      </TableCell>
      {headCells.map((cell) => (
        <TableCell
          key={cell.id}
          variant="head"
          align={cell.align}
          sortDirection={orderBy === cell.id ? order : false}
          style={{ width: cell.width }}
        >
          <TableSortLabel
            active={orderBy === cell.id}
            direction={orderBy === cell.id ? order : 'asc'}
            onClick={() => onRequestSort(cell.id)}
          >
            {cell.label}
            {orderBy === cell.id ? (
              <Box component="span" sx={visuallyHidden}>
                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
              </Box>
            ) : null}
          </TableSortLabel>
        </TableCell>
      ))}
    </TableRow>
  );
}

interface RowContentProps {
  prosjektId: number;
  undersokelseId: number;
  undersokelseKortnavn: string;
  _index: number;
  row: PersonerTableI;
  isSelected: (id: number) => boolean;
  handleClick: (event: MouseEvent<unknown>, id: number) => void;
}

function RowContent(props: RowContentProps) {
  const {
    prosjektId,
    undersokelseId,
    undersokelseKortnavn,
    _index,
    row,
    isSelected,
    handleClick
  } = props;
  return (
    <>
      <TableCell onClick={(event) => handleClick(event, row.personId)} padding="checkbox">
        <Checkbox
          color="primary"
          checked={isSelected(row.personId)}
          inputProps={{
            'aria-labelledby': `table-checkbox-${_index}`
          }}
        />
      </TableCell>
      {headCells.map((cell) =>
        cell.id === 'personId' ? (
          <TableCell
            key={cell.id}
            //onClick={(event) => handleClick(event, row.personId)}
            padding={cell.padding}
            align={cell.align}
            sx={{
              minWidth: `${cell.width}px !important`
            }}
          >
            <Link
              href={`/home/kostholdsdata/prosjekt/person/${prosjektId}/${undersokelseId}/${undersokelseKortnavn}/${row.personId}`}
              target="_blank"
            >
              {row[cell.id]}
            </Link>
          </TableCell>
        ) : (
          <TableCell
            key={cell.id}
            //onClick={(event) => handleClick(event, row.personId)}
            padding={cell.padding}
            align={cell.align}
            sx={{
              minWidth: `${cell.width}px !important`
            }}
          >
            {cell.id === 'notat' && row[cell.id]?.toString().length > 15
              ? `${row[cell.id].toString().slice(0, 15)}...`
              : row[cell.id]}
          </TableCell>
        )
      )}
    </>
  );
}

interface PersonerTableProps {
  filteredData: PersonerTableI[];
  prosjektId: number;
  undersokelseId: number;
  undersokelseKortnavn: string;
}

export default function PersonerTable(props: PersonerTableProps) {
  const { filteredData, prosjektId, undersokelseId, undersokelseKortnavn } = props;
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof PersonerTableI>('personId');
  const [selected, setSelected] = useState<readonly number[]>([]);
  const tableVirtuosoRef = useRef();

  const handleRequestSort = (property: keyof PersonerTableI) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = filteredData.map((n) => n.personId);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: MouseEvent<unknown>, id: number) => {
    let newSelected: readonly number[] = [];

    if (!selected.includes(id)) {
      newSelected = [...selected, id];
    } else {
      newSelected = selected.filter((item) => item !== id);
    }

    setSelected(newSelected);
  };

  const isSelected = (id: number) => selected.indexOf(id) !== -1;

  const sortedRows = filteredData?.slice().sort(getComparator(order, orderBy));

  return (
    <Paper style={{ height: '33rem', width: '100%' }}>
      <TableVirtuoso
        key={filteredData.length || 'table-virtuoso'}
        ref={tableVirtuosoRef}
        data={sortedRows}
        components={VirtuosoTableComponents}
        fixedHeaderContent={() =>
          // EnhancedTableHead<PersonerTableI>({
          //   headCells,
          //   sorting: {
          //     order: order,
          //     orderBy: orderBy,
          //     onRequestSort: handleRequestSort,
          //     sortColumns: ['maaltidsId', 'sortering', 'navn']
          //   },
          //   checkbox: {
          //     numSelected: selected.length,
          //     onSelectAllClick: handleSelectAllClick,
          //     rowCount: filteredData.length
          //   }
          // })
          FixedHeaderContent({
            order,
            orderBy,
            onRequestSort: handleRequestSort,
            numSelected: selected.length,
            onSelectAllClick: handleSelectAllClick,
            rowCount: filteredData.length
          })
        }
        itemContent={(_index, row) =>
          RowContent({
            prosjektId,
            undersokelseId,
            undersokelseKortnavn,
            _index,
            row,
            isSelected,
            handleClick
          })
        }
      />
    </Paper>
  );
}
