import React, { useEffect, useState, useContext } from 'react';
import { InputLabel, Stack, TextField, Typography } from '@mui/material';
import { DialogBox } from '../../utilities/DialogBox';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Grid
} from '@mui/material';
import useFetchData from '../../utilities/useFetchData';
import { DatabaseContext } from '../../../context/DatabaseContext';
import IsOwnedByUser from '../../utilities/Validate';
import { StoffI } from './types/StoffTypes';
import { AlertI } from '../../../types/alertTypes';
import { useUserContext } from '../../../context/AuthContext';
import { NButton } from '../components/Inputs';

interface CopyStoffProps {
  showCopyStoff: boolean;
  setShowCopyStuff: React.Dispatch<React.SetStateAction<boolean>>;
  toggleCopyStoff: () => void;
  stoffData: StoffI;
  postData: any;
  showErrorAlert: (a: string, b: AlertI['alertColor']) => void;
  navigateToStoff: any;
  setShowProgress: (v: boolean) => void;
}

interface newStoff {
  stoffnrId: number | string;
  kortnavn: string;
  navn: string;
  NAVN_ENGELSK: string;
  DESIMALER: number | string;
  ENHET: string;
  SORTERING: string;
  ENERGIFAKTOR: number | string;
  REGNBART: string;
  INNGAAR_ENERGI: string;
  BESKRIVELSE: string;
}

const CopyStoffPopup = (props: CopyStoffProps) => {
  const { currentDatabase } = useContext(DatabaseContext);
  const initialStoffData = {
    stoffnrId: +'',
    kortnavn: '',
    navn: '',
    engelskNavn: '',
    desimaler: 1,
    enhet: 'g',
    sortering: +'',
    regnbart: props.stoffData.regnbart || '',
    ingaari: props.stoffData.ingaari || '',
    energifaktor: props.stoffData.energifaktor || +'',
    beskrivelse: props.stoffData.beskrivelse || ''
  };
  const backendURI = process.env.REACT_APP_BACKEND;
  const loggedInuser = useUserContext();
  const [newStoffDetails, setNewStoffDetails] = useState<StoffI>(initialStoffData);
  const [dataIsValid, setDataIsValid] = useState(false);
  const doseStoffNumberExist = useFetchData({
    loadOnMount: false,
    clearDataOnLoad: false,
    projectURL: 'countStoffs',
    subProjectURL: `${currentDatabase?.value}/${newStoffDetails.stoffnrId}`
  });
  const doesStoffNameExist = useFetchData({
    loadOnMount: false,
    clearDataOnLoad: false,
    projectURL: 'countStoffNavn',
    subProjectURL: `${currentDatabase?.value}/${newStoffDetails.navn}`
  });
  const doesStoffKortNavnExist = useFetchData({
    loadOnMount: false,
    clearDataOnLoad: false,
    projectURL: 'countStoffKortNavn',
    subProjectURL: `${currentDatabase?.value}/${newStoffDetails.kortnavn}`
  });
  const [stoffsError, setStoffsError] = useState({
    error: false,
    message: ''
  });
  const [sorteringError, setSorteringError] = useState({
    error: false,
    message: ''
  });
  const [navnError, setNavnError] = useState({
    error: false,
    message: ''
  });
  const [kortnavnError, setKortNavnError] = useState({
    error: false,
    message: ''
  });
  const [stoffDesimallerError, setStoffDesimallerError] = useState({
    error: false,
    message: ''
  });

  const [copyOptionsValue, setCopyOptionsValue] = useState('1');
  const handleChangeCopyOptions = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCopyOptionsValue((event.target as HTMLInputElement).value);
  };
  const [owned, setOwned] = React.useState(false);
  const [isTouched, setIsTouched] = React.useState(false);

  useEffect(() => {
    let ownedNumber = IsOwnedByUser({ id: +newStoffDetails.stoffnrId, u: loggedInuser });
    setOwned(ownedNumber);
    const timer = setTimeout(() => {
      if (+newStoffDetails?.stoffnrId > 0)
        doseStoffNumberExist.loadData(
          'countStoffs',
          `${currentDatabase?.value}/${newStoffDetails.stoffnrId}`
        );
    }, 500);
    return () => clearTimeout(timer);
  }, [newStoffDetails.stoffnrId]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (newStoffDetails?.kortnavn !== undefined && newStoffDetails?.kortnavn !== '')
        doesStoffKortNavnExist.loadData(
          `countStoffKortNavn`,
          `${currentDatabase?.value}/${newStoffDetails.kortnavn}`
        );
    }, 500);
    return () => clearTimeout(timer);
  }, [newStoffDetails?.kortnavn]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (newStoffDetails?.navn !== undefined && newStoffDetails?.navn !== '')
        doesStoffNameExist.loadData(
          `countStoffNavn`,
          `${currentDatabase?.value}/${newStoffDetails.navn}`
        );
    }, 500);
    return () => clearTimeout(timer);
  }, [newStoffDetails?.navn]);

  const handleChangeStoff = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === 'stoffnrId') {
      if (!event.target.value) {
        setNewStoffDetails({
          ...newStoffDetails,
          [event.target.name]: +''
        });
        setStoffsError({
          error: false,
          message: ''
        });
      } else {
        if (isNaN(+event.target.value)) {
          setStoffsError({
            error: true,
            message: 'Ugyldige tegn. Bruk bare tall'
          });
        } else {
          if (event.target.value.length <= 6) {
            setNewStoffDetails({
              ...newStoffDetails,
              [event.target.name]: +event.target.value
            });
            setIsTouched(true);
          }
        }
      }
    }
    if (event.target.name === 'desimaler') {
      if (+event.target.value > 5) {
        setStoffDesimallerError({
          error: true,
          message: 'Desimaler kan ikke være større enn 5!'
        });
      } else if (+event.target.value < 0) {
        setStoffDesimallerError({
          error: true,
          message: 'Desimaler kan ikke være mindre enn 0!'
        });
      } else {
        setNewStoffDetails({
          ...newStoffDetails,
          [event.target.name]: +event.target.value
        });
        setStoffDesimallerError({ error: false, message: '' });
      }
    }
    if (event.target.name === 'sortering') {
      if (event.target.value.length <= 6) {
        setNewStoffDetails({
          ...newStoffDetails,
          [event.target.name]: +event.target.value
        });
        setIsTouched(true);
      }
    }
    if (event.target.value === '') {
      setNewStoffDetails({
        ...newStoffDetails,
        [event.target.name]: event.target.value
      });
      setSorteringError({
        error: false,
        message: ''
      });
    } else {
      setNewStoffDetails({
        ...newStoffDetails,
        [event.target.name]: event.target.value
      });
      setIsTouched(true);
    }
    event.preventDefault();
  };

  useEffect(() => {
    if (doseStoffNumberExist.data) {
      if (doseStoffNumberExist.data[0]?.COUNT > 0) {
        setStoffsError({
          error: true,
          message: 'Stoffnummeret eksisterer fra før. Bruke et unikt nummer.'
        });
      } else {
        setStoffsError({
          error: false,
          message: ''
        });
      }
    }
    if (doesStoffNameExist.data) {
      if (doesStoffNameExist.data[0]?.COUNT > 0) {
        setNavnError({
          error: true,
          message: 'Navnet eksisterer fra før. Bruke et unikt navn.'
        });
      } else {
        setNavnError({
          error: false,
          message: ''
        });
      }
    }
    if (doesStoffKortNavnExist.data) {
      if (doesStoffKortNavnExist.data[0]?.COUNT > 0) {
        setKortNavnError({
          error: true,
          message: 'Kortnavnet eksisterer fra før. Bruke et unikt kortnavn.'
        });
      } else {
        setKortNavnError({
          error: false,
          message: ''
        });
      }
    }
  }, [doseStoffNumberExist.data, doesStoffNameExist.data, doesStoffKortNavnExist.data]);

  useEffect(() => {
    if (owned && isTouched) {
      setStoffsError({
        error: false,
        message: ''
      });
    } else if (!owned && isTouched) {
      setStoffsError({
        error: true,
        message: 'Nummeret er ikke i nummerområdet ditt'
      });
    }
  }, [owned, isTouched]);

  useEffect(() => {
    if (
      stoffsError.error ||
      sorteringError.error ||
      navnError.error ||
      kortnavnError.error
    ) {
      setDataIsValid(false);
    } else {
      setDataIsValid(true);
    }
  }, [
    stoffsError.error,
    sorteringError.error,
    navnError.error,
    kortnavnError.error,
    newStoffDetails.stoffnrId
  ]);
  const copyUnderlayingStoffData = () => {
    try {
      let url = `${backendURI}/copyUnderlayingStoffData/${currentDatabase?.value}/${newStoffDetails.stoffnrId}/${props.stoffData.stoffnrId}`;
      props.postData(newStoffDetails, url, 'POST').then(async (resp: any) => {
        if (resp.status === 200) {
          props.showErrorAlert('Kopering er fullført', 'success');
          props.toggleCopyStoff();
          props.navigateToStoff(newStoffDetails.stoffnrId);
        } else {
          const error = await resp.json();
          props.showErrorAlert(
            `Kopiert stoffkomponenter, men ikke stoffgruppeelementer: ${error}`,
            'error'
          );
        }
      });
    } catch (error) {
      props.showErrorAlert(
        'Det har oppstått en feil. Vennligst prøv igjen senere.' + error,
        'error'
      );
    }
  };

  const handleCopyStoff = (evt: React.FormEvent<HTMLFormElement>) => {
    props.setShowProgress(true);
    let url = `${backendURI}/copyStoff/${currentDatabase?.value}/${props.stoffData.stoffnrId}/${copyOptionsValue}`;
    try {
      props.postData({ data: newStoffDetails }, url, 'POST').then(async (resp: any) => {
        if (resp.status === 200) {
          if (copyOptionsValue === '1') {
            props.showErrorAlert('Kopering er fullført', 'success');
            props.toggleCopyStoff();
            props.navigateToStoff(newStoffDetails.stoffnrId);
          } else {
            copyUnderlayingStoffData();
          }
        } else {
          const error = await resp.json();
          props.showErrorAlert(`Kopering mislyktes. ${error}`, 'error');
        }
        props.setShowProgress(false);
      });
    } catch (error) {
      props.showErrorAlert(
        'Det har oppstått en feil. Vennligst prøv igjen senere.' + error,
        'error'
      );
      props.setShowProgress(false);
    }

    evt.preventDefault();
  };
  const clear = () => {
    clearAllErrors();
    setNewStoffDetails(initialStoffData);
  };
  const clearAllErrors = () => {
    setStoffsError({
      error: false,
      message: ''
    });
    setSorteringError({
      error: false,
      message: ''
    });
    setNavnError({
      error: false,
      message: ''
    });
    setStoffDesimallerError({
      error: false,
      message: ''
    });
    setKortNavnError({
      error: false,
      message: ''
    });
  };

  return (
    <DialogBox
      title={'Kopi Stoff'}
      isShowing={props.showCopyStoff}
      hide={props.toggleCopyStoff}
    >
      <Box style={{ width: 900 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box borderColor="secondary.main" padding={2}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Kopieringsmetode</FormLabel>
                <RadioGroup
                  aria-label="position"
                  name="copyOptions"
                  value={copyOptionsValue}
                  onChange={handleChangeCopyOptions}
                >
                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="Kopi av innholdet i skjermbildet"
                  />
                  <FormControlLabel
                    value="2"
                    control={<Radio />}
                    label="Kopi av stoffet med alle underliggende informasjon"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
          </Grid>
          <form
            onSubmit={handleCopyStoff}
            style={{ width: 'inherit', display: 'inherit' }}
          >
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <Grid container spacing={2} xs={12}>
                  <Grid item xs={6}>
                    <Typography variant="h6">Gammel</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="h6">Ny</Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={2} xs={12}>
                  <Grid item xs={6}>
                    <InputLabel style={{ padding: '7px 0 5px 0' }}>
                      Stoffnr: <b>{props.stoffData?.stoffnrId}</b>
                    </InputLabel>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="Stoffnr"
                      label="Stoffnr"
                      variant="outlined"
                      size="small"
                      value={newStoffDetails?.stoffnrId}
                      onChange={handleChangeStoff}
                      name={'stoffnrId'}
                      error={stoffsError.error}
                      helperText={stoffsError.message}
                      style={{ padding: '5px 0 5px 0' }}
                      type="text"
                      required
                      inputProps={{
                        min: 1,
                        maxLength: 6,
                        inputMode: 'numeric',
                        pattern: '[0-9]*'
                      }}
                      autoComplete="off"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} xs={12}>
                  <Grid item xs={6}>
                    <InputLabel style={{ padding: '7px 0 5px 0' }}>
                      Kortnavn <b>{props.stoffData?.kortnavn}</b>
                    </InputLabel>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="Kortnavn"
                      label="Kortnavn"
                      variant="outlined"
                      size="small"
                      value={newStoffDetails?.kortnavn}
                      onChange={handleChangeStoff}
                      name={'kortnavn'}
                      inputProps={{
                        min: 1,
                        maxLength: 8
                      }}
                      style={{ padding: '5px 0 5px 0' }}
                      type="text"
                      autoComplete="off"
                      required
                      error={kortnavnError.error}
                      helperText={kortnavnError.message}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} xs={12}>
                  <Grid item xs={6}>
                    <InputLabel style={{ padding: '7px 0 5px 0' }}>
                      Navn <b>{props.stoffData?.navn}</b>
                    </InputLabel>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="Navn"
                      label="Navn"
                      variant="outlined"
                      size="small"
                      value={newStoffDetails?.navn}
                      onChange={handleChangeStoff}
                      name={'navn'}
                      style={{ padding: '5px 0 5px 0' }}
                      inputProps={{
                        min: 1,
                        maxLength: 40
                      }}
                      type="text"
                      autoComplete="off"
                      required
                      error={navnError.error}
                      helperText={navnError.message}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} xs={12}>
                  <Grid item xs={6}>
                    <InputLabel style={{ padding: '7px 0 5px 0' }}>
                      Engelsk navn <b>{props.stoffData?.engelskNavn}</b>
                    </InputLabel>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="Engelsk_navn"
                      label="Engelsk Navn"
                      variant="outlined"
                      size="small"
                      value={newStoffDetails?.engelskNavn}
                      onChange={handleChangeStoff}
                      name={'engelskNavn'}
                      style={{ padding: '5px 0 5px 0' }}
                      type="text"
                      autoComplete="off"
                      required
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2} xs={12}>
                  <Grid item xs={6}>
                    <InputLabel style={{ padding: '7px 0 5px 0' }}>
                      Sortering <b>{props.stoffData?.sortering}</b>
                    </InputLabel>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="Sortering"
                      label="Sortering"
                      variant="outlined"
                      size="small"
                      value={newStoffDetails?.sortering}
                      onChange={handleChangeStoff}
                      name={'sortering'}
                      style={{ padding: '5px 0 5px 0' }}
                      type="text"
                      autoComplete="off"
                      required
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2} xs={12}>
                  <Grid item xs={6}>
                    <InputLabel style={{ padding: '7px 0 5px 0' }}>
                      Enhet <b>{props.stoffData?.enhet}</b>
                    </InputLabel>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="Enhet"
                      label="Enhet"
                      variant="outlined"
                      size="small"
                      value={newStoffDetails?.enhet || 'g'}
                      onChange={handleChangeStoff}
                      name={'enhet'}
                      style={{ padding: '5px 0 5px 0' }}
                      type="text"
                      autoComplete="off"
                      required
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2} xs={12}>
                  <Grid item xs={6}>
                    <InputLabel style={{ padding: '7px 0 5px 0' }}>
                      Desimaler <b>{props.stoffData?.desimaler}</b>
                    </InputLabel>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="Desimaler"
                      label="Desimaler"
                      variant="outlined"
                      size="small"
                      value={newStoffDetails?.desimaler || '1'}
                      onChange={handleChangeStoff}
                      name={'desimaler'}
                      style={{ padding: '5px 0 5px 0' }}
                      type="number"
                      autoComplete="off"
                      required
                      error={stoffDesimallerError.error}
                      helperText={stoffDesimallerError.message}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Stack direction={'row'} spacing={2}>
                  <NButton
                    children="Kopier"
                    type="submit"
                    disabled={!owned || !dataIsValid}
                  />
                  <NButton children="Nullstill" variant="outlined" onClick={clear} />
                </Stack>
              </Grid>
              <Grid item xs={4}></Grid>
            </Grid>
          </form>
        </Grid>
      </Box>
    </DialogBox>
  );
};
export default CopyStoffPopup;
