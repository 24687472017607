export interface MatvaregruppeResult {
  NODENRID: number; // NUMBER
  NAVN: string; // VARCHAR2
  KORTNAVN: string; // VARCHAR2
  NAVN_ENGELSK: string; // VARCHAR2
  UNDERGURPPER: MatvaregruppeResult[];
}
export interface MatvaregruppeParam {
  NODENRID: number; // NUMBER
  NIVAA: number; // NUMBER
  ORDNR: number; // NUMBER
  LINKUP: number; // NUMBER
  NAVN: string; // VARCHAR2
  KORTNAVN: string; // VARCHAR2
  NAVN_ENGELSK: string; // VARCHAR2
}
export interface MatvaregruppeData {
  NODENRID: number; // NUMBER
  NIVAA: number; // NUMBER
  ORDNR: number; // NUMBER
  LINKUP: number; // NUMBER
  NAVN: string; // VARCHAR2
  KORTNAVN: string; // VARCHAR2
  NAVN_ENGELSK: string; // VARCHAR2
}
export interface MatvaregruppeI {
  MATVAREDBID?: number; // NUMBER
  GRUPPETYPEID?: number; // NUMBER
  NODENRID: number; // NUMBER
  NIVAA?: number; // NUMBER
  ORDNR?: number; // NUMBER
  LINKUP?: number; // NUMBER
  NAVN: string; // VARCHAR2
  KORTNAVN?: string; // VARCHAR2
  TILGANGSKODE?: number; // NUMBER
  NAVN_ENGELSK?: string; // VARCHAR2
  RAAVAREGRUPPE?: number; // NUMBER  default: 2
  HARUNDERGRUPP?: boolean;
  HARMATVARE?: boolean;
  UNDERGURPPER?: MatvaregruppeI[];
  MATVARER?: Matvare[];
  selected?: boolean;
}
export interface Matvare {
  MATVAREKODEID: number; // NUMBER
  NAVN: string; // VARCHAR2
  selected?: boolean;
}
export interface MatvareResult {
  MATVAREKODEID: number; // NUMBER
  NAVN?: string; // VARCHAR2
  BESKRIVELSE?: string; // VARCHAR2
  OPPSKRIFT?: string; // VARCHAR2
  KOMMENTAR?: string;
  UTBYTTING: string;
}
export interface resultatGruppeI {
  BESKRIVELSE: string;
  GRUPPETYPEID: number;
  LANG_BESKRIVELSE: string;
  TILGANGSKODE: number;
}
export enum TableType {
  Show = 'vis matvaregruppe',
  From = 'flytt fra',
  To = 'flytt til',
  Utvalg = 'utvalg matvarer',
  Fjern = 'fjern matvarer',
  Beregning = 'beregning utvalg'
}
