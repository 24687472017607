import React from 'react';
import { Box, Stack } from '@mui/material';
import moment from 'moment';
import ReactToPrint from 'react-to-print';
import useStylesTable from '../../../../css/TableCss';
import { Table, TableHead, TableRow, TableCell, TableBody, Grid } from '@mui/material';
import DeleteStoff from '../DeleteStoff';
import { FetchDataProps } from '../../../utilities/useFetchData';
import { AlertI } from '../../../../types/alertTypes';
import { useUserContext } from '../../../../context/AuthContext';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { NButton } from '../../components/Inputs';
import { StoffI } from '../types/StoffTypes';
import { OptionType } from '../../components/commons/CommonTypes';

interface printStoffProps {
  currentUser: string;
  currentDatabase: string;
  data: StoffI;
  nutrientGroupIdAndName: any;
}
const ComponentToPrint = React.forwardRef((props: printStoffProps, ref: any) => (
  <Box ref={ref}>
    <Grid container spacing={1}>
      <h5>Utskrift av skjermbildet: Stoff {props.currentUser}</h5>
      <Grid item xs={12}>
        <label>
          Utskriftsdato:
          {moment().format('YYYY-MM-DD hh:mm:ss')}
        </label>
      </Grid>

      <Grid item xs={12}>
        <label>Matvaredatabase: {props.currentDatabase}</label>
      </Grid>

      <Grid item xs={12}>
        <p>
          Stoffnr: <b> {props.data?.stoffnrId}</b>
        </p>
        <p>
          Kortnavn:<b> {props.data?.kortnavn}</b>
        </p>
        <p>
          Navn:<b> {props.data?.navn}</b>
        </p>
        <p>
          Engelsk Navn:<b> {props.data?.engelskNavn}</b>
        </p>
        <p>
          Desimaler:<b> {props.data?.desimaler}</b>
        </p>
        <p>
          Enhet:<b> {props.data?.enhet}</b>
        </p>
        <p>
          Sorteringsnr:<b> {props.data?.sortering}</b>
        </p>
        <p>
          Engergifaktor:<b> {props.data?.energifaktor}</b>
        </p>
        <p>
          Inngår i energiberegning:<b> {props.data?.ingaari}</b>
        </p>
        <p>
          Beskrivelse:<b> {props.data?.beskrivelse}</b>
        </p>
      </Grid>
      <Grid item xs={12}>
        <>
          <h5>Stoffgrupper for stoffet</h5>
          <Table className="DialogTable">
            <TableHead style={{ backgroundColor: '#4977AF' }}>
              <TableRow>
                <TableCell style={{ color: 'white' }}>X</TableCell>
                <TableCell style={{ color: 'white' }}>Navn</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.nutrientGroupIdAndName?.map((row: { NAVN: string }) => (
                <TableRow hover role="checkbox" key={row.NAVN}>
                  <TableCell>X</TableCell>
                  <TableCell>{row.NAVN}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </>
      </Grid>
    </Grid>
  </Box>
));
interface StoffIconsMenuProps {
  classes: any;
  currentDatabase: OptionType;
  singleNutrient: FetchDataProps | any;
  stoffData: any;
  nutrientGroupIdAndName: FetchDataProps | any;
  owned: boolean;
  toggleCopyStoff: () => void;
  showErrorAlert: (message: string, severity: AlertI['alertColor']) => void;
  setShowProgress: (val: boolean) => void;
  postData: any;
  stoffIdFromURL: number;
}
const StoffIconsMenu = (props: StoffIconsMenuProps) => {
  const componentRef = React.useRef();
  const [openSletteStoff, setOpenSletteStoff] = React.useState(false);
  const loggedInuser = useUserContext();

  return (
    <Stack direction="row-reverse" spacing={2}>
      <ReactToPrint
        trigger={() => (
          <NButton
            children="Skriv ut"
            rightIcon={<PrintOutlinedIcon />}
            variant="text"
            disabled={!props.stoffIdFromURL}
          />
        )}
        pageStyle=".styledTable{width:100%;border-collapse:collapse;text-align:left}.styledTable th,.styledTable td{border:2px solid black;padding:0.5em;}"
        content={() => componentRef.current}
      />
      <Box sx={{ display: 'none' }}>
        <ComponentToPrint
          data={props.singleNutrient.data || []}
          currentUser={loggedInuser?.user?.username}
          nutrientGroupIdAndName={props.nutrientGroupIdAndName.data || []}
          ref={componentRef}
          currentDatabase={props.currentDatabase.label}
        />
      </Box>
      <NButton
        children="Kopier"
        rightIcon={<ContentCopyOutlinedIcon />}
        variant="text"
        onClick={props.toggleCopyStoff}
        disabled={!props.stoffIdFromURL}
      />
      <NButton
        children="Slett"
        rightIcon={<DeleteOutlineOutlinedIcon />}
        variant={'text'}
        disabled={!(props.stoffIdFromURL && props.owned)}
        onClick={() => setOpenSletteStoff(true)}
      />
      {openSletteStoff && (
        <DeleteStoff
          stoffData={props.stoffData}
          openSletteStoff={openSletteStoff}
          setOpenSletteStoff={setOpenSletteStoff}
          databaseId={props.currentDatabase.value}
          showErrorAlert={props.showErrorAlert}
          setShowProgress={props.setShowProgress}
          postData={props.postData}
        />
      )}
    </Stack>
  );
};
export default StoffIconsMenu;
