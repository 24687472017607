import React from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { ConfirmationDialog } from '../../../utilities/ConfirmationDialog';
import { NButton } from '../../components/Inputs';
import { AlertColor } from '@mui/material';
interface NullstillUndProps {
  projectId: string;
  undersokelseId: string;
  personCount: number;
  setPersonCount: (val: number) => void;
  setShowProgress: (val: boolean) => void;
  setProgressText: (val: string) => void;
  showAlertComponent: (message: string, severity: AlertColor) => void;
  setRefetchSurvey: (val: boolean) => void;
}
export const NullstillUndersokelse = (props: NullstillUndProps) => {
  const {
    projectId,
    undersokelseId,
    personCount,
    setPersonCount,
    setShowProgress,
    setProgressText,
    showAlertComponent,
    setRefetchSurvey
  } = props;
  const { keycloak } = useKeycloak();
  const token = keycloak?.token;

  const [showCDialog, setShowCDialog] = React.useState(false);
  const [dialogText, setDialogText] = React.useState('');
  const [dialogTitle] = React.useState('NFC notification!');
  const [confirmationResult, setConfirmationResult] = React.useState(null);

  async function fetchData(mainurl: string, secondurl: string) {
    const url = `${process.env.REACT_APP_BACKEND}/${mainurl}/${
      secondurl ? secondurl : ''
    }`;
    const response = await fetch(encodeURI(url), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    });
    return response.json();
  }

  //Dette vil slette alle data i unders�kelsen,\ndvs. alle personer, l�pedager, m�ltider og variable.\nVil du fortsette likevel?", 52, "Advarsel")

  const showConfirmation = () => {
    setDialogText(
      'Dette vil slette alle data i undersøkelsen,dvs. alle personer, løpedager, måltider og variable.Vil du fortsette likevel?'
    );
    setShowCDialog(true);
  };

  React.useEffect(() => {
    if (confirmationResult) {
      emptyUndersokelse();
    } else {
      setShowProgress(false);
    }
  }, [confirmationResult]);

  const emptyUndersokelse = () => {
    if (projectId && undersokelseId) {
      setProgressText('Nullstiller undersøkelse...');
      setShowProgress(true);
      fetchData(`logEmptiedUndersokelse`, `${projectId}/${undersokelseId}`)
        .then(() => {
          setPersonCount(0);
          setRefetchSurvey(true);
          showAlertComponent('Undersøkelsen nullstilt', 'success');
          setShowProgress(false);
          setConfirmationResult(null);
        })
        .catch((err) => {
          console.log('Error', err);
          showAlertComponent('Feil ved nullstilling.', 'error');
          setShowProgress(false);
        });
    }
  };

  return (
    <>
      {showCDialog && (
        <ConfirmationDialog
          title={dialogTitle}
          text={dialogText}
          setConfirmationResult={setConfirmationResult}
          hide={() => {
            setShowCDialog(!showCDialog);
          }}
        />
      )}
      <NButton disabled={!personCount} onClick={showConfirmation}>
        Nullstill undersøkelse
      </NButton>
    </>
  );
};
