import { LoggedInUser } from '../../context/AuthContext';
import { UserRole } from '../pages/admin/AdminTypes';

export function validateNumber(num: number) {
  if (!num || num === 0 || num.toString()?.length <= 0 || typeof num === 'undefined') {
    return false;
  } else {
    return true;
  }
}

export function validateString(text: string) {
  if (!text || text === '' || text?.length <= 0 || typeof text === undefined) {
    return false;
  } else {
    return true;
  }
}
export const IsOwnedById = (props: {
  tilgangskode: number;
  u: Partial<LoggedInUser>;
}) => {
  if (props.u?.user?.numberRange?.length === 0) {
    throw new Error('Empty numberRange.');
  }

  if (props.u?.user?.roles?.includes(UserRole.Akf)) {
    return true;
  }
  return props.tilgangskode === props.u?.user?.id;
};

const IsOwnedByUser = (props: { id: number; u: Partial<LoggedInUser> }) => {
  if (props.u?.user?.numberRange?.length === 0) {
    throw new Error('Empty numberRange.');
  }

  if (props.u?.user?.roles?.includes(UserRole.Akf)) {
    return true;
  }
  const isOwned =
    (props.id - props.u?.user?.numberRange[0]) *
      (props.id - props.u?.user?.numberRange[1]) <=
    0;
  return isOwned;
};

export default IsOwnedByUser;
