import React from 'react';
import { useFetchNonGET } from '../../../utilities/useFetchNonGET';
import DialogComponent from '../../../utilities/DialogComponent';
import { IngredientData } from '../types/MatvareTypes';
interface DeleteOppskriftProps {
  matvarekode: number;
  ingredienseList: any[];
  row: IngredientData;
  open: boolean;
  close: () => void;
  setDeleted: (value: boolean) => void;
  showAlertMessage: (msg: string, type: any) => void;
}
export default function DeleteOppskrift(props: DeleteOppskriftProps) {
  const { matvarekode, ingredienseList, row, open, close, setDeleted, showAlertMessage } =
    props;
  const [deleteingIngredient, setDeleteingIngredient] = useFetchNonGET();
  const backendURI = process.env.REACT_APP_BACKEND;

  React.useEffect(() => {
    if (deleteingIngredient.status === 200) {
      close();
      setDeleted(true);
    }
  }, [deleteingIngredient.status]);

  const deleteIngredient = () => {
    if (row?.INGREDIENSID === 0) {
      showAlertMessage('Ingrediensid kan ikke være 0', 'error');
      return;
    }
    const ids = ingredienseList?.map((row: IngredientData) => row.INGREDIENSID);
    const found = ids?.indexOf(row.INGREDIENSID);
    if (found === -1) {
      showAlertMessage(
        `Ingrediensid ${row.INGREDIENSID} er ikke inni oppskriften.`,
        'error'
      );
      return;
    }
    let url = `${backendURI}/deleteOppskrift/${matvarekode}/${row.INGREDIENSID}/${row.ORDNRID}`;
    let method = 'DELETE';
    let data = {};
    setDeleteingIngredient({ url, data, method });
  };
  const handleClickJa = () => {
    deleteIngredient();
  };
  const handleClickNei = () => {
    close();
  };

  return (
    <DialogComponent
      title={'Slett ingrediens'}
      message={`Vil du slette ingrediensen med kode ${row.INGREDIENSID}?`}
      open={open}
      close={close}
      handleClickJa={handleClickJa}
      handleClickNei={handleClickNei}
    />
  );
}
