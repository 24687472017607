import React, { ReactNode, useEffect } from 'react';
import DialogGeneral from '../../../../utilities/DialogGeneral';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { CircularProgress, Stack, Typography } from '@mui/material';
import { NButton } from '../../../components/Inputs';

interface PersonDeleteProps {
  showing: boolean;
  hide: () => void;
  handleDelete: () => void;
  title: string;
  body: string;
}

export default function PersonDeleteModal(props: PersonDeleteProps) {
  const { showing, hide, handleDelete, title, body } = props;

  return (
    <DialogGeneral
      title={title}
      open={showing}
      hide={hide}
      titleIcon={<ErrorOutlineIcon sx={{ color: 'white' }} />}
      fullWidth
      maxWidth="xs"
    >
      <Stack sx={{ height: '7rem' }} justifyContent="space-between">
        <Typography variant="body1" paddingBottom={2}>
          {body}
        </Typography>
        <Stack direction={'row'} spacing={1} justifyContent="end">
          <NButton
            children="Ja"
            onClick={() => {
              handleDelete();
              hide();
            }}
          />
          <NButton children="Nei" variant="outlined" onClick={() => hide()} />
        </Stack>
      </Stack>
    </DialogGeneral>
  );
}
