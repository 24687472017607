import {
  Box,
  Collapse,
  IconButton,
  Link,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from '@mui/material';
import React, { SetStateAction, Dispatch, useMemo } from 'react';
import { HeadCell } from '../components/commons/CommonTypes';
import { SvarKategoriI, TilleggsvariableI } from './types/TilleggsvariableTypes';
import { EnhancedTableHead } from '../../utilities/EnhancedTableHead';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

interface EnhancedTilleggsvariabelRowProps {
  row: TilleggsvariableI;
  index: number;
  headCells: HeadCell<TilleggsvariableI>[];
  variablerData: TilleggsvariableI[];
  allVariabler: TilleggsvariableI[];
  orderBy: string;
  handleMove: (index: number, direction: string) => void;
  checkIfVariableHasInfo: (variabelId: number) => void;
  setDeleteId: Dispatch<SetStateAction<number | null>>;
  setShowDeleteVariabel: Dispatch<SetStateAction<boolean>>;
  openRowId: number;
  setOpenRowId: Dispatch<SetStateAction<number>>;
  SvarKategoriHeadCells: HeadCell<SvarKategoriI>[];
}

export default function EnhanceTilleggsvariabelRow(
  props: EnhancedTilleggsvariabelRowProps
) {
  const {
    row,
    index,
    headCells,
    variablerData,
    allVariabler,
    orderBy,
    handleMove,
    checkIfVariableHasInfo,
    setDeleteId,
    setShowDeleteVariabel,
    openRowId,
    setOpenRowId,
    SvarKategoriHeadCells
  } = props;

  const kategoriData = useMemo(() => {
    if (!allVariabler || !openRowId) return;
    return allVariabler?.find((b) => b.variabelId === openRowId)?.tilleggssvar;
  }, [allVariabler, openRowId]);

  return (
    <>
      <TableRow
        hover
        sx={{
          ':not(:hover)': {
            backgroundColor: '#F1F1F1',
            '& > :nth-of-type(n+6)': {
              backgroundColor: '#FFFFFF'
            }
          },
          '& > *': {
            borderBottom: 'unset !important',
            bgcolor: openRowId === row.variabelId ? 'aliceblue !important' : ''
          }
        }}
      >
        {headCells.map((cell) =>
          cell.id === 'variabelId' || cell.id === 'kortnavn' ? (
            <TableCell key={cell.id} align={cell.align} padding={cell.padding}>
              <Link
                href={'/home/kostholdsdata/tilleggsvariabler/' + row?.variabelId}
                target="_blank"
              >
                {row[cell.id]}
              </Link>
            </TableCell>
          ) : cell.id === 'svartype' && row[cell.id] === 'Kategorier' ? (
            <TableCell
              key={cell.id}
              align={cell.align}
              padding={cell.padding}
              sx={{ pb: 0, pt: 0, cursor: 'pointer' }}
              onClick={() =>
                setOpenRowId(openRowId === row.variabelId ? 0 : row.variabelId)
              }
            >
              <Stack direction={'row'} alignItems="center">
                <IconButton>
                  <ChevronRightIcon />
                </IconButton>
                {row[cell.id]}
              </Stack>
            </TableCell>
          ) : (
            <TableCell key={cell.id} align={cell.align} padding={cell.padding}>
              {row[cell.id]}
            </TableCell>
          )
        )}

        <TableCell padding="none" align="center">
          <Stack direction={'row'} alignItems="center" justifyContent="center">
            <IconButton
              disabled={orderBy !== 'sortering' || index === 0}
              onClick={() => handleMove(row.sortering, 'up')}
            >
              <ArrowUpwardIcon />
            </IconButton>
            <IconButton
              disabled={orderBy !== 'sortering' || index === variablerData?.length - 1}
              onClick={() => handleMove(row.sortering, 'down')}
            >
              <ArrowDownwardIcon />
            </IconButton>
          </Stack>
        </TableCell>

        <TableCell padding="none" align="center">
          <IconButton
            onClick={() => {
              setShowDeleteVariabel(true);
              checkIfVariableHasInfo(row.variabelId);
              setDeleteId(row.variabelId);
            }}
          >
            <DeleteOutlineIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse appear in={openRowId === row.variabelId} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Bakgrunnsvariabel: {row.kortnavn}
              </Typography>
              <TableContainer component={Paper}>
                <Table size="small">
                  <EnhancedTableHead<SvarKategoriI> headCells={SvarKategoriHeadCells} />
                  <TableBody>
                    {kategoriData?.map((row) => (
                      <TableRow
                        key={row.kode}
                        sx={{
                          ':not(:hover)': {
                            backgroundColor: '#F1F1F1'
                          }
                        }}
                        hover
                      >
                        {SvarKategoriHeadCells.map((cell) => (
                          <TableCell
                            key={cell.id}
                            align={cell.align}
                            padding={cell.padding}
                          >
                            {row[cell.id]}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
