import { Typography } from '@mui/material';
import React from 'react';

interface ErrorMessageProps {
  showing: boolean;
  message: string;
}

export default function ErrorMessage(props: ErrorMessageProps) {
  const { showing, message } = props;
  return (
    <>
      {showing && (
        <Typography variant="caption" color={'error'}>
          {message}
        </Typography>
      )}
    </>
  );
}
