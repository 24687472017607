import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography
} from '@mui/material';
import React from 'react';
import DialogGeneral from '../../../../utilities/DialogGeneral';
import { NButton } from '../../../components/Inputs';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

interface UtskriftProps {
  showing: boolean;
  hide: () => void;
}

export default function UtskriftModal(props: UtskriftProps) {
  const { showing, hide } = props;

  return (
    <DialogGeneral
      title="Velg utskriftformat"
      titleIcon={<ErrorOutlineIcon sx={{ color: 'white' }} />}
      open={showing}
      hide={hide}
    >
      <Stack spacing={2}>
        <Typography variant="body1">
          Dette skjermbildet bør skrives ut i liggende format!
        </Typography>
        <FormControl>
          <RadioGroup row aria-labelledby="velg-fil-eller-papir" name="utskrift-format">
            <FormControlLabel value="fil" control={<Radio />} label="Fil" />
            <FormControlLabel value="papir" control={<Radio />} label="Papir" />
          </RadioGroup>
        </FormControl>

        <Stack direction={'row'} spacing={1} justifyContent={'end'}>
          <NButton children="Ok" />
          <NButton children="Avbryt" variant="outlined" />
        </Stack>
      </Stack>
    </DialogGeneral>
  );
}
