import React from 'react';
import { NButton } from '../../components/Inputs';

import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { ConfirmationDialog } from '../../../utilities/ConfirmationDialog';

interface DeleteDialogProps {
  title: string;
  message: string;
  deleteFn: (id?: number) => void;
  disabled?: boolean;
  variant?: any;
}

function DeleteDialog(p: DeleteDialogProps) {
  const [showDialog, setShowDialog] = React.useState<boolean>(false);
  const [confirmed, setConfirmed] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (confirmed) {
      setConfirmed(false);
      p.deleteFn();
    }
  }, [confirmed]);

  return (
    <>
      <NButton
        children="Slett"
        rightIcon={<DeleteOutlineOutlinedIcon />}
        variant={p.variant || 'text'}
        disabled={p.disabled}
        onClick={() => setShowDialog(true)}
      />
      {showDialog ? (
        <ConfirmationDialog
          title={p.title}
          text={p.message}
          setConfirmationResult={setConfirmed}
          hide={() => setShowDialog(false)}
        />
      ) : null}
    </>
  );
}

export default DeleteDialog;
