import React, { useEffect } from 'react';
import { DialogBox } from '../../utilities/DialogBox';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import { MonoSearchField } from '../components/Inputs';
import { useProjectContext } from '../../../context/ProjectContext';
import { ProsjektSearchI } from './types/ProjectTypes';

interface ProjectSearchProps {
  setProject: (id: number | string) => void;
  showing: boolean;
  hide: () => void;
}

function ProjectSearch(p: ProjectSearchProps) {
  const projs = useProjectContext();
  const [projects, setProjects] = React.useState<ProsjektSearchI[]>([]);
  const [allProjects, setAllProjects] = React.useState<ProsjektSearchI[]>([]);

  const changeProject = (project: string | number) => {
    p.hide();
    p.setProject(`${project}`);
  };
  const convertProjectData = () => {
    const convertedProjects: ProsjektSearchI[] = projs.projects?.map((prosjekt) => {
      // Extract only the properties needed for ProsjektSearchI
      const { prosjektId, kortNavn, prosjektnavn, ansvarlig, antallUndersokelser } =
        prosjekt;

      // Create a new ProsjektSearchI object
      const convertedProsjekt: ProsjektSearchI = {
        prosjektId,
        kortNavn,
        prosjektnavn,
        ansvarlig,
        antallUndersokelser
      };

      return convertedProsjekt;
    });
    setAllProjects(convertedProjects);
    setProjects(convertedProjects);
  };
  useEffect(() => {
    convertProjectData();
  }, []);

  const searchById = (id: string): void => {
    const searchResults = id
      ? allProjects?.filter((project) =>
          String(project.prosjektId)?.includes(id as string)
        )
      : allProjects;
    searchResults?.length === 0 ? setProjects([]) : setProjects(searchResults);
  };
  const searchByName = (name: string): void => {
    const lowerCaseName = name?.toLowerCase();
    const searchResults = lowerCaseName
      ? allProjects?.filter((project) =>
          project.kortNavn?.toLowerCase().includes(lowerCaseName)
        )
      : allProjects;
    searchResults?.length === 0 ? setProjects([]) : setProjects(searchResults);
  };
  const translateHeader = {
    prosjektId: 'Prosjektsnummer',
    kortNavn: 'Kortnavn',
    prosjektnavn: 'Navn',
    ansvarlig: 'Prosjektleder',
    antallUndersokelser: 'Antall undersøkelser'
  };

  return (
    <DialogBox title="Liste over prosjekt" isShowing={p.showing} hide={p.hide}>
      <Grid container spacing={2}>
        <Grid item xs={12} container spacing={2}>
          <Grid item xs={3} container alignItems="flex-end">
            <MonoSearchField
              header="Søk etter prosjektnummer"
              searchFn={(n) => searchById(n)}
            />
          </Grid>
          <Grid item xs={3} container alignItems="flex-end">
            <MonoSearchField
              header="Søk etter kortnavn"
              searchFn={(n) => searchByName(n)}
            />
          </Grid>
          <Grid item xs={6} container alignItems="flex-end" justifyContent={'flex-end'}>
            <Typography>
              {'Antall prosjekter: '}
              {projects?.length}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Table size="small" sx={{ border: '1px solid black' }}>
            <TableHead>
              <TableRow>
                {projects?.length &&
                  Object.keys(projects[0]).map((header) => (
                    <TableCell sx={{ fontWeight: 'bold', height: '3em' }}>
                      {translateHeader[header] || header}
                    </TableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {projects?.map((row) => (
                <TableRow hover key={row.prosjektId}>
                  {Object.values(row).map((cell, i) => (
                    <TableCell sx={{ height: '3em' }}>
                      {i < 2 ? (
                        <a
                          style={{
                            color: 'blue',
                            textDecoration: 'underline',
                            cursor: 'pointer'
                          }}
                          onClick={() => changeProject(row.prosjektId)}
                          children={cell}
                        />
                      ) : (
                        cell
                        //cell === row.undersokelser ? row.undersokelser?.length : cell
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {/* <TablePagination
              //sx={classesTable.Pagination}
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={projects?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              showFirstButton={true}
              showLastButton={true}
            />     */}
        </Grid>
      </Grid>
    </DialogBox>
  );
}

export default ProjectSearch;
