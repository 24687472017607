import React, { useState, useEffect } from 'react';
import {
  DialogTitle,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button
} from '@mui/material';

interface ConfirmationDialogProp {
  text: string;
  title: string;
  setConfirmationResult: (value: boolean) => void;
  hide: () => void;
}

export const ConfirmationDialog = (props: ConfirmationDialogProp) => {
  const { text, title, setConfirmationResult, hide } = props;
  const [openCDialog, setOpenCDialog] = useState(false);
  useEffect(() => {
    handleOpenCDialog();
  }, []);

  const handleOpenCDialog = () => {
    setOpenCDialog(true);
  };
  const handleClickJa = () => {
    console.log('handleClickJa');

    setConfirmationResult(true);
    handleCloseCDialog();
  };

  const handleClickNei = () => {
    setConfirmationResult(false);
    handleCloseCDialog();
  };

  const handleCloseCDialog = () => {
    setOpenCDialog(false);
    hide();
  };
  return (
    <Dialog
      open={openCDialog}
      onClose={handleCloseCDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClickJa} color="primary">
          {'Ja'}
        </Button>
        <Button onClick={handleClickNei} color="primary" autoFocus>
          {'Nei'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
