import React from 'react';
import { ProgressBar } from '../../../utilities/ProgressBar';
import { useKeycloak } from '@react-keycloak/web';
import PageContainer from '../../home/PageContainer';
import { Paper, Typography, Alert, Grid } from '@mui/material';
import { GenericImport, NButton } from '../../components/Inputs';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import PollingComponent from '../../components/commons/PollingComponent';
import { AlertI } from '../../../../types/alertTypes';
import AlertComp from '../../../utilities/AlertComp';
import { CleanedFileResultI } from '../types/ImportTypes';
const initialData: CleanedFileResultI = {
  validData: [],
  personsCount: 0,
  errorText: '',
  errorCount: 0
};

export const FjernMatvarekoder = () => {
  const { keycloak } = useKeycloak();
  const token = keycloak?.token;
  const [title] = React.useState('Fjern matvarer uten mengde');
  const [showProgress, setShowProgress] = React.useState(false);
  const [progressText, setProgressText] = React.useState('Prosesser ...');
  const [alertMessage, setAlertMessage] = React.useState('');
  const [alertSeverity, setAlertSeverity] = React.useState<AlertI['alertColor']>('info');
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [downloadFileLink, setDownloadFileLink] = React.useState('');
  const [newFileName, setNewFileName] = React.useState('');
  const [fileReady, setFileReady] = React.useState(false);
  const [showAlert, setShowAlert] = React.useState(false);
  const [pollingId, setPollingId] = React.useState('');
  const [failed, setFailed] = React.useState(false);
  const [importResult, setImportResult] = React.useState<CleanedFileResultI>(initialData);
  document.title = title;
  React.useEffect(() => {
    if (importResult?.validData?.length > 0) {
      showAlertMessage(
        'Fjernet ' +
          importResult?.errorCount +
          ' matvarekoder fra filen ' +
          selectedFile?.name +
          '.' +
          importResult?.personsCount +
          ' personer prosessert.',
        'success'
      );
      createNewFile(importResult?.validData);
    } else if (importResult?.errorText) {
      showAlertMessage(importResult?.errorText + '. Prosessering avbrutt.', 'error');
    }
  }, [importResult?.validData]);

  const showAlertMessage = (message: string, severity: any) => {
    setShowAlert(true);
    setAlertMessage(message);
    setAlertSeverity(severity);
  };
  const changeHandler = async (file: File) => {
    setFileReady(false);
    setSelectedFile(file);
    console.log('file size...', file?.size);
  };

  React.useEffect(() => {
    setNewFileName('Ny-' + selectedFile?.name);
  }, [selectedFile]);

  const handleFileContent = () => {
    const reader = new FileReader();
    reader.readAsText(selectedFile);
    reader.onload = function (event) {
      let fileContent = event.target.result as string;
      if (fileContent?.length) {
        const firstChar = fileContent.substring(0, 5);
        if (firstChar === '#AKFP' || firstChar === '#AKFK') {
          console.log('correct file', firstChar);
          setProgressText('Fjerner matvarekoder uten mengde...');
          setShowProgress(true);
          removeInvalidMatvares(fileContent);
        } else {
          console.log('in correct file', firstChar);
          showAlertMessage(
            'Filen ' +
              selectedFile?.name +
              ' er ikke en gyldig KBS importfil (starter ikke med #AKFP)!',
            'error'
          );
        }
      } else {
        showAlertMessage('Filen ' + selectedFile?.name + ' er tomt.', 'error');
        console.log('File is empty');
      }
    };
  };
  async function postData(data: any, url: string, methods: string) {
    let response = await fetch(encodeURI(url), {
      method: methods,
      headers: {
        Authorization: 'Bearer ' + token
      },
      body: JSON.stringify(data)
    });
    return response;
  }

  const removeInvalidMatvares = async (data: {}) => {
    let url = `${process.env.REACT_APP_BACKEND}/processUncleanedFile`;
    await postData(data, url, 'POST')
      .then((resp) => {
        if (resp.status === 200) {
          resp.json().then((data) => {
            //Start pooling backend
            setPollingId(data?.id);
          });
        } else if (resp.status === 500) {
          showAlertMessage('Fjerning av matvarekoder uten mengde mislyktes.', 'error');
          setShowProgress(false);
        }
      })
      .catch((error) => {
        console.log(error);
        showAlertMessage('Fjerning av matvarekoder uten mengde mislyktes.', 'error');
      });
  };

  const createNewFile = (text: any[]) => {
    const data = new Blob([text.join('\n')], { type: 'text/plain' });
    if (downloadFileLink !== '') window.URL.revokeObjectURL(downloadFileLink);
    setDownloadFileLink(window.URL.createObjectURL(data));
    setFileReady(true);
    setShowProgress(false);
  };

  const clear = () => {
    setSelectedFile(null);
    setFileReady(false);
  };

  return (
    <PageContainer>
      <Paper style={{ padding: '1.5em 15px 1.5em 15px', boxShadow: 'none' }}>
        <Typography variant="h4" marginBottom="1em">
          Fjerning av matvarekoder uten mengde i importfil
        </Typography>
        <Grid container>
          <Grid item xs={5}>
            <GenericImport
              help={() => showAlertMessage('Ingen hjelp her', 'info')}
              onChange={(f) => changeHandler(f as File)}
              selected={selectedFile?.name}
              accept=".txt"
              actionLabel="prosessere"
            />
          </Grid>
          <Grid item xs={7} />

          <Grid item xs={12} container spacing={1} sx={{ marginTop: 1 }}>
            <Grid item xs={1}>
              <NButton
                children="Utfør"
                disabled={!selectedFile}
                onClick={() => handleFileContent()}
              />
            </Grid>
            <Grid item xs={1}>
              <NButton children="Nullstill" variant="outlined" onClick={clear} />
            </Grid>
          </Grid>

          {fileReady ? (
            <Grid item xs={12} sx={{ marginTop: 1 }}>
              <Typography variant="h5">Prossesert fil for nedlasting:</Typography>
              <ul>
                <li>
                  <Typography variant="h6" children={newFileName} />
                </li>
              </ul>
              <a download={newFileName} href={downloadFileLink}>
                <NButton
                  children="Last ned fil"
                  leftIcon={<FileDownloadOutlinedIcon />}
                />
              </a>
            </Grid>
          ) : null}
        </Grid>
        {pollingId && (
          <PollingComponent
            id={pollingId}
            setFailed={setFailed}
            setResult={setImportResult}
          />
        )}
        {showProgress && (
          <ProgressBar
            isShowing={showProgress}
            text={progressText}
            hide={() => {
              setShowProgress(!showProgress);
            }}
          />
        )}
        {showAlert && (
          <AlertComp
            alertMessage={alertMessage}
            alertColor={alertSeverity}
            showAlert={showAlert}
            setShowAlert={setShowAlert}
          />
        )}
      </Paper>
    </PageContainer>
  );
};
