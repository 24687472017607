import { FormControl } from '@mui/base';
import {
  Box,
  FormControlLabel,
  OutlinedInput,
  Radio,
  RadioGroup,
  Stack,
  Typography
} from '@mui/material';
import { useKeycloak } from '@react-keycloak/web';
import React, { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { AlertI } from '../../../../../types/alertTypes';
import DialogGeneral from '../../../../utilities/DialogGeneral';
import PostData from '../../../../utilities/postData';
import useFetchData from '../../../../utilities/useFetchData';
import { Dropdown, NButton } from '../../../components/Inputs';
import { LopedagI, MaaltidI, PersonI } from '../../types/PersonTypes';
import { CountResult, ProsjektI, UndersokelseI } from '../../types/ProjectTypes';

interface RouteParams {
  prosjektId: string;
  undersokelseId: string;
  undersokelseKortnavn: string;
  personId: string;
}

interface KopiModalProps extends RouteComponentProps<RouteParams> {
  maaltidId: number;
  lopedagId: number;
  lopedags: LopedagI[];
  showing: boolean;
  hide: () => void;
  projectObject: ProsjektI;
  setShowAlert: Dispatch<SetStateAction<boolean>>;
  setAlertMessage: Dispatch<SetStateAction<string>>;
  setAlertSeverity: Dispatch<SetStateAction<AlertI['alertColor']>>;
  setRefetchLopedag: Dispatch<SetStateAction<boolean>>;
}

export default function MaaltidKopiModal(props: KopiModalProps) {
  const {
    showing,
    hide,
    lopedagId,
    lopedags,
    projectObject,
    maaltidId,
    setShowAlert,
    setAlertMessage,
    setAlertSeverity,
    setRefetchLopedag
  } = props;
  const { prosjektId, undersokelseId, undersokelseKortnavn, personId } =
    props.match.params;
  const [selectedLopedag, setSelectedLopedag] = useState<LopedagI['lopedagId'] | null>(
    null
  );
  const [selectedMaaltid, setSelectedMaaltid] = useState<MaaltidI['maaltidsId'] | null>(
    null
  );
  const [relatedInfo, setRelatedInfo] = useState<number | null>(null);
  const [maaltidExists, setMaaltidExists] = useState<boolean>(false);
  const { keycloak } = useKeycloak();

  // GET SURVEYS
  const getSurveys = useFetchData({
    loadOnMount: true,
    clearDataOnLoad: false,
    projectURL: 'hentPUndersokelser',
    subProjectURL: `${prosjektId}`
  });

  const checkIfMaaltidIdExists = useFetchData({
    loadOnMount: false,
    clearDataOnLoad: false,
    projectURL: 'checkIfMaaltidIdExists',
    subProjectURL: `${prosjektId}/${undersokelseId}/${personId}/${selectedLopedag}/${selectedMaaltid}`
  });

  useEffect(() => {
    if (selectedLopedag && selectedMaaltid) {
      checkIfMaaltidIdExists.loadData(
        'checkIfMaaltidIdExists',
        `${prosjektId}/${undersokelseId}/${personId}/${selectedLopedag}/${selectedMaaltid}`
      );
    }
  }, [selectedLopedag, selectedMaaltid]);

  useEffect(() => {
    if (checkIfMaaltidIdExists.data) {
      const data = checkIfMaaltidIdExists.data as unknown as CountResult;
      if (data?.COUNT > 0) {
        setMaaltidExists(true);
      } else {
        setMaaltidExists(false);
      }
    }
  }, [checkIfMaaltidIdExists.data]);

  const handleCopy = async () => {
    const backendURI = process.env.REACT_APP_BACKEND;

    let url = `${backendURI}/copyMaaltid/${prosjektId}/${undersokelseId}/${personId}/${lopedagId}/${maaltidId}/${selectedLopedag}/${selectedMaaltid}/${relatedInfo}`;
    let response: Response = await fetch(encodeURI(url), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + keycloak.token
      }
    });
    if (response.ok) {
      setShowAlert(true);
      setAlertMessage('Måltid kopiert');
      setAlertSeverity('success');
      setRefetchLopedag(true);
      console.log('success', response.status);
      hide();
    } else {
      console.log('error', response.status);
      setShowAlert(true);
      setAlertMessage('Kopiering av måltid feilet');
      setAlertSeverity('error');
    }
  };

  return (
    <DialogGeneral
      title="Kopi av Måltid"
      open={showing}
      hide={hide}
      fullWidth
      maxWidth="sm"
    >
      <Stack spacing={2}>
        <Stack spacing={1} paddingBottom={2}>
          <Typography variant="body1">
            <b>Prosjekt: </b> {projectObject?.prosjektnavn} - {prosjektId}
          </Typography>
          <Typography variant="body1">
            <b>Undersøkelse: </b> {undersokelseKortnavn} - {undersokelseId}
          </Typography>
          <Typography variant="body1">
            <b>Person: </b> {personId}
          </Typography>
          <Typography variant="body1">
            <b>Løpedag: </b> {lopedagId}
          </Typography>
          <Typography variant="body1">
            <b>Måltid: </b>
            {maaltidId}
          </Typography>
        </Stack>
        <Box>
          <Typography variant="h6">Kopieringsalternativ</Typography>
          <FormControl>
            <RadioGroup
              aria-labelledby="velg-relatedInfo"
              value={relatedInfo}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                setRelatedInfo(+event.target.value)
              }
            >
              <FormControlLabel
                value={0}
                control={<Radio />}
                label="Kopi av innholdet i skjermbildet"
              />
              <FormControlLabel
                value={1}
                control={<Radio />}
                label="Kopi av måltidet med all underliggende informasjon"
              />
            </RadioGroup>
          </FormControl>
        </Box>
        <Stack direction={'row'} spacing={1} justifyContent={'end'} alignItems={'end'}>
          <Dropdown
            header="Løpedag*"
            value={selectedLopedag}
            onChange={(value: any) => setSelectedLopedag(value)}
            options={lopedags?.map((l) => l.lopedagId)}
            optionLabel={(value) => {
              const lopedag = lopedags?.find((l) => l.lopedagId === value);
              return lopedag ? `Dag  ${lopedag.lopedagId}` : '';
            }}
          />
          <Box sx={{ width: '100%' }}>
            <FormControl>
              <Typography sx={{ whiteSpace: 'nowrap' }}>Måltid</Typography>
              <OutlinedInput
                value={selectedMaaltid || ''}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  setSelectedMaaltid(+event.target.value)
                }
                fullWidth
                size="small"
                type="number"
              />
            </FormControl>
          </Box>
          <Box>
            <NButton
              children="Kopier"
              disabled={
                !selectedLopedag ||
                !selectedMaaltid ||
                selectedMaaltid < 1 ||
                relatedInfo === null ||
                maaltidExists
              }
              onClick={handleCopy}
            />
          </Box>
        </Stack>
        <Stack direction={'row'} justifyContent={'end'}>
          {maaltidExists && selectedMaaltid ? (
            <Box height={5}>
              <Typography variant="caption" color="error">
                Måltid finnes allerede
              </Typography>
            </Box>
          ) : (
            <Box height={5} />
          )}
        </Stack>
      </Stack>
    </DialogGeneral>
  );
}
