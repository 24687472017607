import React from 'react';
import useFetchData from '../../../utilities/useFetchData';
import { Grid, IconButton, InputAdornment, InputLabel } from '@mui/material';
import { DialogBox } from '../../../utilities/DialogBox';
import { ConfirmationDialog } from '../../../utilities/ConfirmationDialog';
import { NButton, SlowText } from '../../components/Inputs';
import { VariabelIdI, TilleggsvariableI } from '../types/TilleggsvariableTypes';
import ClearIcon from '@mui/icons-material/Clear';

interface copyillegsvariableProps {
  //newVariableId: VariabelIdI;
  tilleggsvariable: TilleggsvariableI;
  tilleggsvariablerList: TilleggsvariableI[];
  showCopy: boolean;
  setShowCopy: (value: boolean) => void;
  setProgressBar: (value: boolean) => void;
  setRefetchVariableList: (value: boolean) => void;
  showAlert: (message: string, severity: any) => void;
}
export const CopyTilleggsvariable = (props: copyillegsvariableProps) => {
  const title = 'Kopi av tilleggsvariabel';
  const {
    //newVariableId,
    tilleggsvariable,
    tilleggsvariablerList,
    setProgressBar,
    setRefetchVariableList,
    showAlert,
    showCopy,
    setShowCopy
  } = props;
  const [notification, setNotification] = React.useState({ error: false, message: '' });
  const [newVariableId, setNewVariabelId] = React.useState<VariabelIdI>({ id: 0 });
  const [confirmationResult, setConfirmationResult] = React.useState(null);
  //const [showAlert, setShowAlert] = React.useState(false);
  // const [showDialogbox, setShowDialogbox] = React.useState(false);
  const [newKortnavn, setNewKortnavn] = React.useState('');
  const [showCDialog, setShowCDialog] = React.useState(false);
  const [dialogText, setDialogText] = React.useState('');
  const [dialogTitle, setDialogTitle] = React.useState('');

  const getNewVariabelId = useFetchData({
    loadOnMount: true,
    clearDataOnLoad: false,
    projectURL: 'getNewVariabelId'
  });

  const copyTilleggsvariabel = useFetchData({
    loadOnMount: false,
    clearDataOnLoad: false,
    projectURL: 'copyTilleggsvariabel',
    subProjectURL: `${newVariableId.id}/${newKortnavn}/${tilleggsvariable?.variabelId}`
  });

  React.useEffect(() => {
    if (getNewVariabelId.data) {
      const data: VariabelIdI = getNewVariabelId.data as unknown as VariabelIdI;
      setNewVariabelId(data);
    }
  }, [getNewVariabelId.data]);

  React.useEffect(() => {
    if (getNewVariabelId.error) {
      showAlert('Det har oppstått en feil. Vennligst prøv igjen senere.', 'error');
    }
  }, [getNewVariabelId.error]);

  React.useEffect(() => {
    if (copyTilleggsvariabel.data) {
      if (copyTilleggsvariabel.data[0]) {
        if (copyTilleggsvariabel.data[0]?.SUCCESS) {
          setProgressBar(false);
          showAlert('Kopieringen er fullført.', 'success');
          setRefetchVariableList(true);
          setShowCopy(!showCopy);
        }
      }
    }
  }, [copyTilleggsvariabel.data]);

  React.useEffect(() => {
    if (copyTilleggsvariabel.error) {
      setProgressBar(false);
      showAlert(
        'Kopieringen mislyktes. Det har ikke blitt gjort endringer mot databasen.',
        'error'
      );
      // setShowCopy(!showCopy)
    }
  }, [copyTilleggsvariabel.error]);

  React.useEffect(() => {
    checkConfirmation();
  }, [confirmationResult]);

  const hadleOnClickCopy = () => {
    showCopyConfirmation(
      'Vil du virkelig kopiere tilleggsvariabel ' +
        tilleggsvariable?.kortnavn +
        ' til tilleggsvariabel ' +
        newKortnavn +
        '?'
    );
  };
  const copy = () => {
    if (newVariableId.id && newKortnavn && tilleggsvariable?.variabelId) {
      setProgressBar(true);
      copyTilleggsvariabel.loadData(
        `copyTilleggsvariabel`,
        `${newVariableId.id}/${newKortnavn}/${tilleggsvariable?.variabelId}`
      );
    } else {
      showAlert('Mangler noen parameter', 'error');
      console.log('One of the variables are missing.');
    }
  };

  const checkConfirmation = () => {
    if (confirmationResult) {
      copy();
      //setProgressText('Kopierer...');
    } else {
      setConfirmationResult(null);
      setShowCDialog(false);
      setProgressBar(false);
    }
  };

  const showCopyConfirmation = (text: string) => {
    setDialogTitle('Kopi av tilleggsvariabel');
    setDialogText(text);
    setProgressBar(false);
    setShowCDialog(true);
  };

  const handleChangeNewKortnavn = (k: string) => {
    const kortnavn = k?.trim();
    setNewKortnavn(kortnavn);
    if (kortnavn) {
      const allKortnavn = tilleggsvariablerList?.map((t) => t.kortnavn?.toLowerCase());
      if (
        tilleggsvariable?.kortnavn?.toLowerCase() === kortnavn?.toLowerCase() ||
        allKortnavn?.some((str: string) => str.toLowerCase() === kortnavn?.toLowerCase())
      ) {
        setNotification({
          error: true,
          message: 'Denne tilleggsvariabelen eksisterer fra før.'
        });
      } else {
        setNotification({ error: false, message: '' });
      }
    }
  };

  return (
    <DialogBox
      title={title}
      isShowing={showCopy}
      hide={() => {
        setShowCopy(!showCopy);
      }}
    >
      {showCDialog && (
        <ConfirmationDialog
          title={dialogTitle}
          text={dialogText}
          setConfirmationResult={setConfirmationResult}
          hide={() => {
            setShowCDialog(!showCDialog);
          }}
        />
      )}
      <Grid container item xs={12} spacing={2} alignItems={'center'}>
        <Grid item xs={8} container alignItems={'center'}>
          <InputLabel>Kortnavn</InputLabel>
          <SlowText
            header=""
            value={newKortnavn}
            onChange={(e) => handleChangeNewKortnavn(e.target.value)}
            inputProps={{
              min: 1,
              maxLength: 8
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    size="small"
                    onClick={() => {
                      handleChangeNewKortnavn('');
                      setNotification({ error: false, message: '' });
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={notification.error}
            helperText={notification.message}
          />
        </Grid>
        <Grid item xs={4} container alignItems={'center'}>
          <NButton
            children="Kopier"
            disabled={newKortnavn?.trim() === '' || notification.error}
            onClick={hadleOnClickCopy}
            fullWidth
          />
        </Grid>
      </Grid>
    </DialogBox>
  );
};
