import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  InputLabel,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';

import useFetchData from '../../utilities/useFetchData';
import { MatvareData, Mengdeenhet } from './types/MatvareTypes';
import useStyles from '../../../css/materialtheme';
import AlertComp from '../../utilities/AlertComp';
import { AlertI } from '../../../types/alertTypes';
import { DatabaseContext } from '../../../context/DatabaseContext';
import PageContainer from '../home/PageContainer';
//import { LagreButton, NullstillButton } from '../components/Buttons';
import PostData from '../../utilities/postData';
import { useKeycloak } from '@react-keycloak/web';
//import DeleteIcon from '@mui/icons-material/Delete';
import ReactToPrint from 'react-to-print';
import moment from 'moment';
import { useFetchNonGET } from '../../utilities/useFetchNonGET';
import { InputTableMengde } from './InputTableMengde';
import { NButton } from '../components/Inputs';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import { useUserContext } from '../../../context/AuthContext';
import IsOwnedByUser from '../../utilities/Validate';
import useDidMountEffect from '../../utilities/useDidMountEffect';

interface MengdeenheterProps {
  matvareData: MatvareData;
  currentDatabase?: any;
  showMengdeenheter: boolean;
  match: any;
}

/* interface Mengdeenhet {
  MENGDE: number | string; //g/enhet
  MENGDEENHETID: string;
  REFERANSE: string;
  SORTERING: number | string;
} */

export default function Mengdeenheter(props: MengdeenheterProps) {
  const { currentDatabase } = React.useContext(DatabaseContext);
  const classes = useStyles();
  const [title, setTitle] = useState('Mengdeenheter - Nutrifoodcalc');
  const kodeFromURL = +props.match.params.kode || 0;
  const navnFromURL = props.match.params.navn || '';
  const matvarenavn = navnFromURL?.replace(/-slash-/g, '/')?.replace(/-percent-/g, '%');
  const { keycloak } = useKeycloak();
  const token = keycloak?.token;
  //const backendURI = process.env.REACT_APP_BACKEND;
  const [owned, setOwned] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const loggedInuser = useUserContext();
  const currentUser = loggedInuser?.user?.username;
  const [alertSeverity, setAlertSeverity] = useState<AlertI['alertColor']>('info');
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const componentRef = React.useRef();
  const mengdeenheter = useFetchData({
    loadOnMount: false,
    clearDataOnLoad: false,
    projectURL: 'fetchMengdeenheter',
    subProjectURL: `${currentDatabase?.value}/${kodeFromURL}`
  });
  const [isDeleted, setIsDeleted] = useState<boolean>(false);
  const [mengdeToDelete, setMengdeToDelete] = useState('');
  const [comfirmDeletingMengde, setComfirmDeletingMengde] = useState(false);
  const [comfirmNullstillTable, setComfirmNullstillTable] = useState(false);
  //const [deleteMengde, setDeleteingMengde] = useFetchNonGET();
  const [mengdeenheterData, setMengdeenheterData] = useState<any[]>([
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {}
  ]);

  document.title = title;

  // This is MatvareKodeId that is in our URL
  useEffect(() => {
    if (kodeFromURL > 0) {
      setTitle(`Mengdeenheter - ${kodeFromURL} - Nutrifoodcalc`);
      mengdeenheter.loadData(
        'fetchMengdeenheter',
        `${currentDatabase?.value}/${kodeFromURL}`
      );
      setOwned(IsOwnedByUser({ id: +kodeFromURL, u: loggedInuser }));
    }
  }, [kodeFromURL]);

  useEffect(() => {
    if (mengdeenheter.data) {
      const data: Mengdeenhet[] = mengdeenheter.data as unknown as Mengdeenhet[] | [];
      const modifiedData = data?.map((row: Mengdeenhet) => {
        return {
          ...row,
          mengde: parseFloat(row.mengde.toString()?.replace(/[,]/g, '.'))
        };
      });
      setMengdeenheterData(modifiedData);
      if (isDeleted) {
        setIsDeleted(false);
        callAlert('Slettingen er fullført', 'success');
      }
    }
  }, [mengdeenheter.data]);

  useEffect(() => {
    if (mengdeenheter.error) {
      showErrorAlert(
        'error',
        'Det har oppstått en feil. Vennligst prøv igjen senere.' +
          mengdeenheter.errorMessage
      );
    }
  }, [mengdeenheter.error]);

  const showErrorAlert = (severity: any, message: string) => {
    setAlertSeverity(severity);
    setAlertMessage(message);
    setShowAlert(true);
  };

  const objectIsPopulated = (currentValue: any) => {
    return Object.keys(currentValue).length > 0;
  };

  const selectMendeToDelete = (mengde: string) => {
    setMengdeToDelete(mengde);
    setComfirmDeletingMengde(true);
  };

  // We moniter the mengdeenheterData and add a empty line to it, if its full.
  useEffect(() => {
    const isTableArrayFull = mengdeenheterData.every(objectIsPopulated);

    //We might need to add more empty rows here.
    if (isTableArrayFull) {
      setMengdeenheterData([...mengdeenheterData, {}]);
      //scrollToTableEnd();
    }
  }, [mengdeenheterData]);

  const callAlert = (message: string, info: AlertI['alertColor']) => {
    setAlertMessage(message);
    setAlertSeverity(info);
    setShowAlert(true);
  };

  const isInputValid = (value: string | number) => {
    if (value === '') {
      return false;
    } else if (value === undefined) {
      return false;
    } else if (value === null) {
      return false;
    } else {
      return true;
    }
  };
  function findDuplicates(arr: Mengdeenhet[]) {
    const countMap: Record<string, number> = {};
    for (const obj of arr) {
      countMap[obj.mengdeenhetid] = (countMap[obj.mengdeenhetid] || 0) + 1;
    }
    const duplicates: Mengdeenhet[] = arr.filter(
      (obj) => countMap[obj.mengdeenhetid] > 1
    );
    return duplicates;
  }

  const handleSaveAll = async () => {
    let allValid: boolean = false;
    const duplicateObjects = findDuplicates(mengdeenheterData);
    mengdeenheterData?.forEach((mengde: Mengdeenhet) => {
      if (objectIsPopulated(mengde) && mengde?.mengdeenhetid !== '') {
        allValid =
          isInputValid(mengde.mengdeenhetid) &&
          isInputValid(mengde.mengde) &&
          isInputValid(mengde.sortering);
      }
    });
    if (duplicateObjects.length) {
      callAlert('Denne enheten er allerede lagt inn i listen.', 'error');
    } else if (allValid) {
      let data: Mengdeenhet[] = [];
      const dataurl = `${currentDatabase?.value}/${kodeFromURL}`;
      mengdeenheterData?.map((mengde: Mengdeenhet) => {
        if (mengde.mengdeenhetid) {
          const reqbody: Mengdeenhet = {
            mengde: mengde.mengde || '',
            mengdeenhetid: mengde.mengdeenhetid || '',
            referanse: mengde.referanse,
            sortering: +mengde.sortering
          };
          data.push(reqbody);
        }
      });
      PostData({ data: data }, 'saveMengdeenheter', dataurl, 'POST', token).then(
        (resp) => {
          if (resp.status === 200) {
            callAlert('Lagringen er fullført', 'success');
          } else if (resp.status === 500) {
            console.log('An error 500', resp.status);
            callAlert(
              'Lagringen mislyktes. Det har ikke blitt gjort endringer mot databasen.',
              'error'
            );
          }
        }
      );
    } else {
      callAlert('Sortering, Mengde og mengdeenhetid kan ikke være tomme.', 'error');
    }
  };
  const handleDeleteMengde = () => {
    const dataurl = `${currentDatabase?.value}/${kodeFromURL}/${mengdeToDelete}`;
    PostData({}, 'deleteMengdeenhet', dataurl, 'DELETE', token)
      .then((resp) => {
        if (resp.status === 200) {
          mengdeenheter.loadData(
            'fetchMengdeenheter',
            `${currentDatabase?.value}/${kodeFromURL}`
          );
          setIsDeleted(true);
        } else {
          callAlert(
            'Slettingen mislyktes. Ingen sletting er utført mot databasen.',
            'error'
          );
        }
      })
      .catch((err) => {
        console.log('We are in the catch', err);
        callAlert(
          'Slettingen mislyktes. Ingen sletting er utført mot databasen.',
          'error'
        );
      });
  };
  const closeDialog = () => {
    setComfirmDeletingMengde(false);
  };

  const removeFromMatvareLocal = async () => {
    setMengdeenheterData((prevState) =>
      prevState.filter((mengde: any) => mengde.MENGDEENHETID !== mengdeToDelete)
    );
  };

  const handleClickJa = async () => {
    //Do something. and then close.
    await handleDeleteMengde();
    await removeFromMatvareLocal();
    waitAndFetchStoffinnhold();
    closeDialog();
  };

  const handleClickNei = () => {
    closeDialog();
  };

  const waitAndFetchStoffinnhold = async () => {
    const timer = setTimeout(() => {
      mengdeenheter.loadData(
        'fetchMengdeenheter',
        `${currentDatabase?.value}/${kodeFromURL}`
      );
    }, 500);
    return () => clearTimeout(timer);
  };

  const closeNullstillDialog = () => {
    setComfirmNullstillTable(false);
  };

  const handleClickNullstillJa = async () => {
    handleSaveAll();
    closeNullstillDialog();
  };

  const handleClickNullstillNei = () => {
    mengdeenheter.loadData(
      'fetchMengdeenheter',
      `${currentDatabase?.value}/${kodeFromURL}`
    );
    closeNullstillDialog();
  };

  return (
    <PageContainer>
      <Container
        id="MengdeenheterGruppe"
        style={{
          minWidth: 800,
          minHeight: '80vh',
          paddingTop: 55
        }}
      >
        <Typography variant="h4">Mengdeenheter</Typography>
        <Grid container spacing={2}>
          <Grid item xs={6} container alignItems={'flex-start'}>
            <InputLabel style={{ fontSize: 18 }}>
              Matvare: {kodeFromURL} -{matvarenavn}
            </InputLabel>
          </Grid>
          <Grid item xs={6}>
            <Stack direction="row-reverse" spacing={2}>
              <ReactToPrint
                trigger={() => (
                  <NButton
                    children="Skriv ut"
                    rightIcon={<PrintOutlinedIcon />}
                    variant="text"
                    disabled={mengdeenheter.data?.length === 0}
                  />
                )}
                pageStyle=".styledTable{width:100%;border-collapse:collapse;text-align:left}.styledTable th,.styledTable td{border:2px solid black;padding:0.5em;}"
                content={() => componentRef.current}
              />
            </Stack>
          </Grid>
        </Grid>
        <Box style={{ display: 'none' }}>
          <ComponentToPrint
            classes={classes}
            data={mengdeenheter.data}
            matvareKode={kodeFromURL}
            currentUser={currentUser}
            ref={componentRef}
            currentDatabase={currentDatabase}
          />
        </Box>
        {mengdeenheter.isLoaded ? (
          <InputTableMengde
            mengdeenheterData={mengdeenheterData}
            setMengdeenheterData={setMengdeenheterData}
            selectMendeToDelete={selectMendeToDelete}
            owned={owned}
          />
        ) : (
          <div>...loading</div>
        )}

        <Stack direction={'row'} spacing={2} style={{ paddingTop: 50 }}>
          <NButton children="Lagre" onClick={handleSaveAll} disabled={!owned} />
          <NButton
            children="Nullstil"
            variant="outlined"
            disabled={!owned}
            onClick={() => setMengdeenheterData([])}
          />
        </Stack>

        {comfirmDeletingMengde && (
          <Dialog
            maxWidth="xs"
            fullWidth
            open={comfirmDeletingMengde}
            onClose={closeDialog}
            aria-labelledby="sletteMengdeenhet"
          >
            <DialogTitle id="sletteMengdeenhet">Slett mengde</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Vil du slette markerte mengdeenheten? Enhet - <b>{mengdeToDelete}</b>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClickJa} color="primary">
                Ja
              </Button>
              <Button onClick={handleClickNei} color="primary" autoFocus>
                Nei
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Container>
      {showAlert && (
        <AlertComp
          alertMessage={alertMessage}
          alertColor={alertSeverity}
          showAlert={showAlert}
          setShowAlert={setShowAlert}
        />
      )}
    </PageContainer>
  );
}

interface ComponentToPrintProps {
  currentUser: string;
  classes: any;
  data: any;
  matvareKode: number | string;
  currentDatabase: any;
}
const ComponentToPrint = React.forwardRef((props: ComponentToPrintProps, ref: any) => (
  <div ref={ref}>
    <b>Utskrift av skjermbildet: Mengdeenheter ({props.currentUser})</b>
    <br></br>
    <p>
      Utskriftsdato:
      {moment().format('YYYY-MM-DD hh:mm:ss')}
    </p>
    <p>Matvaredatabase : {props.currentDatabase?.label}</p>
    <br></br>
    <p>Matvare: {props.matvareKode}</p>
    <br></br>

    <Table sx={props.classes.body}>
      <TableHead sx={props.classes.head}>
        <TableRow>
          <TableCell sx={props.classes.head}>{'Sortering'}</TableCell>
          <TableCell sx={props.classes.head}>{'Enhet'}</TableCell>
          <TableCell sx={props.classes.head}>{'g/enhet'}</TableCell>
          <TableCell sx={props.classes.head}>{'Referanse'}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody sx={props.classes.body}>
        {props.data?.map((row: Mengdeenhet) => {
          return (
            <TableRow hover role="checkbox" key={`${row.sortering}-${row.mengdeenhetid}`}>
              <TableCell>{row?.sortering}</TableCell>
              <TableCell>{row?.mengdeenhetid}</TableCell>
              <TableCell>{row?.mengde}</TableCell>
              <TableCell>{row?.referanse}</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  </div>
));
