import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { Button, Grid, Tooltip, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { Dropdown, FormInput, NButton } from '../components/Inputs';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import AddIcon from '@mui/icons-material/Add';
import { OppringingI, PersonI } from './types/PersonTypes';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { CodeRegisterI } from '../components/commons/CommonTypes';
import { codeTypes } from '../../../assets/kodeOption';
import { useCodeContext } from '../../../context/CodeContext';
import PersonSearchModal from './components/modals/PersonSearchModal';
import PersonLopedager from './PersonLopedager';
import PersonOppringinger from './PersonOppringinger';
import { RouteComponentProps } from 'react-router-dom';
import { GyldighetsOmrade, ProsjektI, UndersokelseI } from './types/ProjectTypes';
import PersonTilleggssvar from './PersonTilleggssvar';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import PersonKopiModal from './components/modals/PersonKopiModal';
import UtskriftModal from './components/modals/UtskriftModal';
import PersonOppringingModal from './components/modals/PersonOppringingModal';
import { useUserContext } from '../../../context/AuthContext';
import IsOwnedByUser from '../../utilities/Validate';
import { useKeycloak } from '@react-keycloak/web';
import AlertComp from '../../utilities/AlertComp';
import { AlertI } from '../../../types/alertTypes';
import PersonDeleteModal from './components/modals/PersonDeleteModal';
import PostData from '../../utilities/postData';
import selectedDropdown from './components/SelectedDropdown';
import { ApiCall } from '../../utilities/ApiCall';
import ChangesModal from './components/modals/ChangesModal';
import StyledTextarea from '../components/StyledTextarea';

interface RouteParams {
  prosjektId: string;
  undersokelseId: string;
  undersokelseKortnavn: string;
  personId: string;
}

interface PersonProps extends RouteComponentProps<RouteParams> {
  personData: PersonI;
  setPersonData: Dispatch<SetStateAction<PersonI>>;
  projectObject: ProsjektI;
  handleNewLopedag: () => void;
  setActiveTab: Dispatch<SetStateAction<number>>;
  startTime: Date | null;
  setStartTime: Dispatch<SetStateAction<Date | null>>;
  currentOppringingId: number;
  setCurrentOppringingId: Dispatch<SetStateAction<number>>;
  handleAvslutt: () => void;
  handleSaveOppringing: (oppringing: OppringingI, lopedagId: number) => void;
  currentModalLopedag: number;
  setCurrentModalLopedag: Dispatch<SetStateAction<number>>;
  redirectTo: (
    prosjektId: number,
    undersokelseId: number,
    undersokelseKortnavn: string,
    personId: number
  ) => void;
  callAlert: (message: string, severity: AlertI['alertColor']) => void;
  handleSavePerson: () => void;
  setGyldighetsOmraade: Dispatch<SetStateAction<GyldighetsOmrade>>;
  isAlderValid: boolean;
  isHoydeValid: boolean;
  isVektValid: boolean;
}

export default function Person(props: PersonProps) {
  const {
    personData,
    setPersonData,
    projectObject,
    handleNewLopedag,
    setActiveTab,
    startTime,
    setStartTime,
    currentOppringingId,
    setCurrentOppringingId,
    handleAvslutt,
    handleSaveOppringing,
    currentModalLopedag,
    setCurrentModalLopedag,
    redirectTo,
    callAlert,
    handleSavePerson,
    setGyldighetsOmraade,
    isAlderValid,
    isHoydeValid,
    isVektValid
  } = props;
  const { prosjektId, undersokelseId, undersokelseKortnavn, personId } =
    props.match.params;
  const [showKopi, setShowKopi] = useState<boolean>(false);
  const [showUtskrift, setShowUtskrift] = useState<boolean>(false);
  const [showPersonSearch, setShowPersonSearch] = useState<boolean>(false);
  const [showOppringing, setShowOppringing] = useState<boolean>(false);
  const [ringOpp, setRingOpp] = useState<boolean>(false);
  const [oppringingIds, setOppringingIds] = useState<Set<number>>(new Set());
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>('');
  const [alertSeverity, setAlertSeverity] = useState<AlertI['alertColor']>('info');
  const [showDelete, setShowDelete] = useState<boolean>(false);
  const [oppringingModalChanges, setOppringingModalChanges] = useState<boolean>(false);
  const [showChangesModal, setShowChangesModal] = useState<boolean>(false);
  const [svarErrorArray, setSvarErrorArray] = useState<number[]>([]);
  const [oppringingData, setOppringingData] = useState<OppringingI>();
  const allCodes = useCodeContext();
  const loggedInuser = useUserContext();
  document.title = `Person ${personId || 0} - Nutrifoodcalc`;

  const owned = useMemo(
    () => IsOwnedByUser({ id: +prosjektId, u: loggedInuser }),
    [prosjektId, loggedInuser]
  );
  const { keycloak } = useKeycloak();

  // DELETE PERSON
  const handleDeletePerson = () => {
    if (owned) {
      PostData(
        {},
        'deletePerson',
        `${prosjektId}/${undersokelseId}/${personData?.personId}`,
        'DELETE',
        keycloak.token
      ).then((resp: Response) => {
        if (resp.ok) {
          callAlert('Person slettet', 'success');
          setPersonData(null);
          redirectTo(+prosjektId, +undersokelseId, undersokelseKortnavn, 0);
          window.scrollTo(0, 0);
        } else {
          setShowAlert(true);
          setAlertMessage('Sletting av person mislyktes');
          setAlertSeverity('error');
          console.log('Klarte ikke å slette person!', resp.status);
        }
      });
    }
  };

  const handleChange = (
    field: Extract<keyof PersonI, string | number>,
    value: string | number
  ) => {
    if (field === 'personId' && isNaN(+value)) {
      callAlert('Personid må være et nummer', 'error');
      setPersonData((prev) => ({ ...prev, personId: null }));
      return;
    }
    setPersonData((prev) => ({ ...prev, [field]: value }));
  };

  useEffect(() => {
    if (!prosjektId || !undersokelseId) return;
    const fetchData = async () => {
      const response = await ApiCall(
        `hentPUndersokelser/${prosjektId}`,
        'GET',
        keycloak.token
      );
      if (response?.ok) {
        const json: UndersokelseI[] = await response?.json();
        setGyldighetsOmraade(
          json?.find((u) => u.undersokelseId === +undersokelseId)?.gyldighetsOmraade
        );
      } else {
        console.error('error', response?.status);
      }
    };
    fetchData();
  }, [prosjektId, undersokelseId]);

  // SET OPPRINGINGING IDS
  useEffect(() => {
    if (personData?.oppringinger) {
      const ids = new Set<number>();
      personData.oppringinger.forEach((o) => ids.add(o.nr));
      setOppringingIds(ids);
    }
  }, [personData?.oppringinger]);

  const handleJa = () => {
    handleSaveOppringing(oppringingData, currentModalLopedag);
    setOppringingModalChanges(false);
    setShowChangesModal(false);
    setShowOppringing(false);
    setOppringingData(null);
  };

  const handleNei = () => {
    setOppringingModalChanges(false);
    setShowChangesModal(false);
    setShowOppringing(false);
    setOppringingData(null);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography variant="h4" gutterBottom>
            Person
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Stack direction="row-reverse" spacing={1}>
            <NButton
              children="Skriv ut"
              rightIcon={<PrintOutlinedIcon />}
              variant="text"
              onClick={() => setShowUtskrift(true)}
              disabled={!personData?.personId}
            />
            <NButton
              children="Kopi / duplikat"
              rightIcon={<ContentCopyOutlinedIcon />}
              variant="text"
              onClick={() => setShowKopi(true)}
              disabled={!personData?.personId}
            />

            <Tooltip
              title={
                !owned
                  ? 'Mangler rettigheter'
                  : !personData?.personId
                  ? 'PersonID mangler'
                  : ''
              }
            >
              <span>
                <Button
                  children={
                    <Stack direction={'row'} spacing={1} alignItems="center" width="100%">
                      <Typography>Ny person</Typography>
                      <AddIcon />
                    </Stack>
                  }
                  variant="text"
                  style={{
                    textTransform: 'none',
                    fontSize: 'medium',
                    height: '2.5em',
                    borderRadius: '0.2em',
                    boxShadow: 'none'
                  }}
                  href={`/home/kostholdsdata/prosjekt/person/${prosjektId}/${undersokelseId}/${undersokelseKortnavn}/0`}
                  target="_blank"
                  disabled={!owned || !personData?.personId}
                />
              </span>
            </Tooltip>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack direction={'row'} spacing={2}>
            {projectObject && (
              <Typography variant="h6">
                Prosjekt: {projectObject?.prosjektnavn} - {prosjektId}
              </Typography>
            )}
            <Typography variant="h6">Undersøkelse: {undersokelseKortnavn}</Typography>
          </Stack>
        </Grid>
      </Grid>
      <Grid container item spacing={2} sx={{ mb: '1rem', mt: '2rem' }}>
        <Grid item xs={6} sm={2}>
          <FormInput
            header="Personid *"
            value={personData?.personId || ''}
            onChange={(event) => handleChange('personId', +event.target.value)}
            owned={owned}
          />
        </Grid>
        <Grid item xs={6} sm={2}>
          <FormInput
            header="Identitet"
            value={personData?.identitet || ''}
            onChange={(event) => handleChange('identitet', event.target.value.trim())}
            owned={owned}
          />
        </Grid>
        <Grid item xs={6} sm={5} display={'flex'} alignItems={'end'}>
          <NButton
            children="Søk etter person"
            leftIcon={<SearchOutlinedIcon />}
            onClick={() => setShowPersonSearch(true)}
          />
        </Grid>
        <Grid item xs={6} sm={3}>
          <Dropdown
            header={codeTypes?.find((option) => option.value === 10)?.label + ' *'}
            value={selectedDropdown(personData?.personStatus, 10)}
            options={allCodes.codes?.filter((f) => f.value === 10)}
            optionLabel={(option: CodeRegisterI) => option?.label}
            onChange={(value: any) => handleChange('personStatus', value?.nr)}
            readOnly={!owned}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid container item xs={3} sm={2} direction={'column'} spacing={1}>
          <Grid item>
            <FormInput
              header="Alder (år)"
              type="number"
              value={personData?.alder || ''}
              onChange={(event) => handleChange('alder', +event.target.value)}
              error={!isAlderValid}
              helperText={!isAlderValid ? 'Alder er ikke gyldig' : ''}
              owned={owned}
            />
          </Grid>
          <Grid item>
            <FormInput
              header="Høyde (cm)"
              type="number"
              value={personData?.hoyde || ''}
              onChange={(event) => handleChange('hoyde', +event.target.value)}
              error={!isHoydeValid}
              helperText={!isHoydeValid ? 'Høyde er ikke gyldig' : ''}
              owned={owned}
            />
          </Grid>
          <Grid item>
            <FormInput
              header="Vekt (kg)"
              type="number"
              value={personData?.vekt || ''}
              onChange={(event) => handleChange('vekt', +event.target.value)}
              error={!isVektValid}
              helperText={!isVektValid ? 'Vekt er ikke gyldig' : ''}
              owned={owned}
            />
          </Grid>
          <Grid item>
            <Stack direction={'row'}>
              <Typography variant="body1" marginRight={'auto'}>
                BMI:
              </Typography>
              <Typography variant="body1">{personData?.bmi?.toFixed(2) || ''}</Typography>
            </Stack>
          </Grid>
        </Grid>

        <Grid container item xs={5} sm={4} direction={'column'} spacing={1}>
          <Grid item>
            <Dropdown
              header={codeTypes?.find((option) => option.value === 1)?.label}
              value={selectedDropdown(personData?.kjonn, 1)}
              options={allCodes.codes?.filter((f) => f.value === 1)}
              optionLabel={(option: CodeRegisterI) => option?.label}
              onChange={(value: any) => handleChange('kjonn', value?.nr)}
              readOnly={!owned}
            />
          </Grid>
          <Grid item>
            <Dropdown
              header={codeTypes?.find((option) => option.value === 2)?.label}
              value={selectedDropdown(personData?.bostedType, 2)}
              options={allCodes.codes?.filter((f) => f.value === 2)}
              optionLabel={(option: CodeRegisterI) => option?.label}
              onChange={(value: any) => handleChange('bostedType', value?.nr)}
              readOnly={!owned}
            />
          </Grid>
          <Grid item>
            <Dropdown
              header={codeTypes?.find((option) => option.value === 15)?.label}
              value={selectedDropdown(personData?.landsdel, 15)}
              options={allCodes.codes?.filter((f) => f.value === 15)}
              optionLabel={(option: CodeRegisterI) => option?.label}
              onChange={(value: any) => handleChange('landsdel', value?.nr)}
              readOnly={!owned}
            />
          </Grid>
          <Grid item>
            <Dropdown
              header={codeTypes?.find((option) => option.value === 16)?.label}
              value={selectedDropdown(personData?.skjemaStatus, 16)}
              options={allCodes.codes?.filter((f) => f.value === 16)}
              optionLabel={(option: CodeRegisterI) => option?.label}
              onChange={(value: any) => handleChange('skjemaStatus', value?.nr)}
              readOnly={!owned}
            />
          </Grid>
          <Grid item>
            <Dropdown
              header={codeTypes?.find((option) => option.value === 17)?.label}
              value={selectedDropdown(personData?.tilbakemeldingStatus, 17)}
              options={allCodes.codes?.filter((f) => f.value === 17)}
              optionLabel={(option: CodeRegisterI) => option?.label}
              onChange={(value: any) => handleChange('tilbakemeldingStatus', value?.nr)}
              readOnly={!owned}
            />
          </Grid>
        </Grid>
        <Grid item xs={4} sm={6}>
          <StyledTextarea
            header="Notat"
            value={personData?.notat || ''}
            onChange={(e) => handleChange('notat', e.target.value)}
            owned={owned}
          />
          {/* <FullHeightTextField
            name="notat"
            header="Notat"
            value={personData?.notat || ''}
            onChange={(e) => handleChange('notat', e.target.value)}
            owned={owned}
          /> */}
        </Grid>
      </Grid>

      <PersonLopedager
        personData={personData}
        handleChange={handleChange}
        handleNewLopedag={handleNewLopedag}
        setShowOppringing={setShowOppringing}
        setCurrentModalLopedag={setCurrentModalLopedag}
        setCurrentOppringingId={setCurrentOppringingId}
        setActiveTab={setActiveTab}
        setRingOpp={setRingOpp}
        oppringingIds={oppringingIds}
        handleSaveOppringing={handleSaveOppringing}
        {...props}
      />
      <PersonOppringinger
        personData={personData}
        setShowOppringing={setShowOppringing}
        setRingOpp={setRingOpp}
        setCurrentModalLopedag={setCurrentModalLopedag}
        setCurrentOppringingId={setCurrentOppringingId}
        {...props}
      />
      <PersonTilleggssvar
        personData={personData}
        setPersonData={setPersonData}
        svarErrorArray={svarErrorArray}
        setSvarErrorArray={setSvarErrorArray}
        {...props}
      />
      <Stack direction={'row'} spacing={2} useFlexGap sx={{ mt: '3rem' }}>
        <Tooltip
          title={
            !owned
              ? 'Mangler rettigheter'
              : !personData?.personId
              ? 'PersonID mangler'
              : !personData?.personStatus
              ? 'Personstatus mangler'
              : !!svarErrorArray?.length
              ? 'Ugyldige tilleggssvar'
              : ''
          }
        >
          <span>
            <NButton
              children="Lagre"
              variant="contained"
              onClick={handleSavePerson}
              disabled={
                !owned ||
                !personData?.personId ||
                !personData?.personStatus ||
                !!svarErrorArray?.length
              }
            />
          </span>
        </Tooltip>
        <Stack direction={'row'} alignItems={'end'} spacing={2}>
          <Tooltip title={!startTime ? 'Tidsregistrering har ikke startet' : ''}>
            <span>
              <NButton
                children="Avslutt intervjuet"
                variant="outlined"
                onClick={handleAvslutt}
                disabled={!owned || !startTime}
              />
            </span>
          </Tooltip>
        </Stack>
        <Tooltip
          title={
            !owned
              ? 'Mangler rettigheter'
              : !personData?.personId
              ? 'PersonID mangler'
              : ''
          }
        >
          <span style={{ marginLeft: 'auto' }}>
            <NButton
              children="Slett person"
              variant="contained"
              onClick={() => setShowDelete(true)}
              leftIcon={<DeleteOutlineIcon />}
              disabled={!owned || !personData?.personId}
            />
          </span>
        </Tooltip>
      </Stack>

      {/* dialogs */}

      {/* {showKopi && ( */}
      <PersonKopiModal
        projectObject={projectObject}
        personData={personData}
        showing={showKopi}
        hide={() => setShowKopi(false)}
        setShowAlert={setShowAlert}
        setAlertMessage={setAlertMessage}
        setAlertSeverity={setAlertSeverity}
        {...props}
      />
      {/* )} */}

      {showUtskrift && (
        <UtskriftModal showing={showUtskrift} hide={() => setShowUtskrift(false)} />
      )}

      {showPersonSearch && (
        <PersonSearchModal
          setPerson={(id) =>
            setPersonData((prev) => ({
              ...prev,
              personId: id
            }))
          }
          open={showPersonSearch}
          hide={() => setShowPersonSearch(false)}
          {...props}
        />
      )}

      {showOppringing && (
        <PersonOppringingModal
          owned={owned}
          showing={showOppringing}
          hide={() => {
            setShowOppringing(false);
            setOppringingData(null);
          }}
          checkAndHide={() => {
            if (!oppringingModalChanges) {
              setShowOppringing(false);
              setOppringingData(null);
            } else {
              setShowChangesModal(true);
            }
          }}
          personData={personData}
          setPersonData={setPersonData}
          currentModalLopedag={currentModalLopedag}
          currentOppringingId={currentOppringingId}
          projectObject={projectObject}
          ringOpp={ringOpp}
          setActiveTab={setActiveTab}
          startTime={startTime}
          setStartTime={setStartTime}
          handleSaveOppringing={handleSaveOppringing}
          oppringingModalChanges={oppringingModalChanges}
          setOppringingModalChanges={setOppringingModalChanges}
          oppringingData={oppringingData}
          setOppringingData={setOppringingData}
          callAlert={callAlert}
          {...props}
        />
      )}

      {showChangesModal && (
        <ChangesModal
          showing={showChangesModal}
          hide={() => setShowChangesModal(false)}
          handleJa={handleJa}
          handleNei={handleNei}
          disabled={oppringingData?.varighet < 0}
        />
      )}

      {showDelete && (
        <PersonDeleteModal
          showing={showDelete}
          hide={() => setShowDelete(false)}
          handleDeletePerson={handleDeletePerson}
        />
      )}

      {showAlert && (
        <AlertComp
          alertMessage={alertMessage}
          alertColor={alertSeverity}
          showAlert={showAlert}
          setShowAlert={setShowAlert}
        />
      )}
    </>
  );
}
