import { FormControl, MenuItem, Select, Tooltip, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { DatabaseContext } from '../../../context/DatabaseContext';
import { AlertI } from '../../../types/alertTypes';
import AlertComp from '../../utilities/AlertComp';
import { CodeRegisterI } from '../components/commons/CommonTypes';
import { NButton } from '../components/Inputs';
import LopedagMaaltidsberegningModal from './components/modals/LopedagMaaltidsberegningModal';
import LopedagStoffgrupperModal from './components/modals/LopedagStoffgrupperModal';
import LopedagMaaltidsKomponenterTable from './LopedagMaaltidsKomponenterTable';
import {
  LopedagI,
  MaaltidI,
  MaaltidskomponentI,
  StoffnMengdeI
} from './types/PersonTypes';
import { UtvalgsId } from '../beregning/types/BeregningTypes';

interface RouteParams {
  prosjektId: string;
  undersokelseId: string;
  personId: string;
}

interface MaaltidsKomponenterProps extends RouteComponentProps<RouteParams> {
  lopedagData: LopedagI;
  maaltidskomponentData: MaaltidskomponentI[];
  setMaaltidskomponentData: Dispatch<SetStateAction<MaaltidskomponentI[]>>;
  setMaaltidskomponentDataCopy: Dispatch<SetStateAction<MaaltidskomponentI[]>>;
  maaltidsData: MaaltidI[];
  maaltidTypes: CodeRegisterI[];
  currentMaaltidId: number;
  utvalgId: UtvalgsId;
  stoffnMengdeData: StoffnMengdeI[];
  setCurrentMaaltidId: Dispatch<SetStateAction<number>>;
  setRefetchLopedag: Dispatch<SetStateAction<boolean>>;
  getMaaltidsBeregning: (mId: number) => void;
  utvalgData: StoffnMengdeI[];
  setUtvalgData: Dispatch<React.SetStateAction<StoffnMengdeI[]>>;
  saveAndUpdateMaaltidskomponenter: (inputData: MaaltidskomponentI[]) => void;
}

export default function LopedagMaaltidsKomponenter(props: MaaltidsKomponenterProps) {
  const {
    lopedagData,
    maaltidskomponentData,
    setMaaltidskomponentData,
    maaltidsData,
    maaltidTypes,
    currentMaaltidId,
    setCurrentMaaltidId,
    setRefetchLopedag,
    stoffnMengdeData,
    utvalgId,
    getMaaltidsBeregning,
    utvalgData,
    setUtvalgData,
    setMaaltidskomponentDataCopy,
    saveAndUpdateMaaltidskomponenter
  } = props;
  const [valgOptions, setValgOptions] = useState<CodeRegisterI[]>([
    { label: 'Alle', nr: 0 }
  ]);
  const [showStoffgrupper, setShowStoffgrupper] = useState<boolean>(false);
  const [showMaaltidsberegning, setShowMaaltidsberegning] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>('');
  const [alertSeverity, setAlertSeverity] = useState<AlertI['alertColor']>('info');
  const [stoffgrupperTemp, setStoffgrupperTemp] = useState<number[]>([]);
  const { currentDatabase } = useContext(DatabaseContext);

  useEffect(() => {
    if (currentMaaltidId === 0) {
      const allComponents = lopedagData?.maaltider?.flatMap((m) => m.maaltidskomponenter);
      setMaaltidskomponentData(allComponents);
      setMaaltidskomponentDataCopy(allComponents);
    } else {
      const maaltidComponents = lopedagData?.maaltider?.find(
        (maaltid) => maaltid?.maaltidsId === currentMaaltidId
      )?.maaltidskomponenter;
      setMaaltidskomponentData(maaltidComponents);
      setMaaltidskomponentDataCopy(maaltidComponents);
    }
  }, [lopedagData?.maaltider, currentMaaltidId]);

  useEffect(() => {
    //This happens at page load, save & delete måltidakomponenter, save & delete byttIngerediens
    if (currentMaaltidId && utvalgId?.ID) {
      getMaaltidsBeregning(currentMaaltidId);
    }
  }, [currentMaaltidId, utvalgId?.ID, utvalgData]);

  useEffect(() => {
    if (!lopedagData?.maaltider) return;
    setValgOptions([
      { label: 'Alle', nr: 0 },
      ...lopedagData?.maaltider
        ?.map((maaltid) => {
          return { label: maaltid?.maaltidsId.toString(), nr: maaltid?.maaltidsId };
        })
        .sort((a, b) => a.nr - b.nr)
    ]);
  }, [lopedagData]);

  const selectedOption = valgOptions?.find((option) => option.nr === currentMaaltidId);

  return (
    <>
      <Stack spacing={2} sx={{ pt: '4rem' }}>
        <Typography variant="h6">Måltidskomponenter:</Typography>
        <Stack direction={'row'} alignItems={'end'} spacing={1} useFlexGap>
          <FormControl sx={{ minWidth: '7.5rem' }}>
            <Typography>Velg måltid</Typography>
            <Select
              value={selectedOption?.nr}
              onChange={(event) => setCurrentMaaltidId(+event.target.value)}
              size="small"
            >
              {valgOptions.map((option) => (
                <MenuItem key={option.nr} value={option.nr}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box>
            <Tooltip title={!utvalgData?.length ? 'Stoffutvalg mangler' : ''}>
              <span>
                <NButton
                  children="Måltidsberegning"
                  variant="outlined"
                  onClick={() => setShowMaaltidsberegning(true)}
                  disabled={!currentMaaltidId || !utvalgData?.length}
                />
              </span>
            </Tooltip>
          </Box>
          <Box>
            <NButton
              children={'Velg stoff / gruppe'}
              variant="outlined"
              onClick={() => setShowStoffgrupper(true)}
              disabled={!(currentMaaltidId && lopedagData.maaltider.length > 0)}
            />
          </Box>
          <Tooltip
            title={
              utvalgData?.length > 1 ? utvalgData?.map((u) => u.kortnavn)?.join(', ') : ''
            }
          >
            <Typography sx={{ textWrap: 'balance' }}>
              Valgte stoffer:{' '}
              {utvalgData?.map((u) => u.kortnavn)?.length > 1
                ? `${utvalgData[0]?.kortnavn} og ${utvalgData?.length - 1} andre`
                : utvalgData?.map((u) => u.kortnavn)?.join(', ')}
            </Typography>
          </Tooltip>
        </Stack>
        <Box sx={{ mt: '1rem' }}>
          <LopedagMaaltidsKomponenterTable
            maaltidskomponentData={maaltidskomponentData}
            setMaaltidskomponentData={setMaaltidskomponentData}
            lopedagData={lopedagData}
            currentMaaltidId={currentMaaltidId}
            setShowAlert={setShowAlert}
            setAlertMessage={setAlertMessage}
            setAlertSeverity={setAlertSeverity}
            setRefetchLopedag={setRefetchLopedag}
            saveAndUpdateMaaltidskomponenter={saveAndUpdateMaaltidskomponenter}
            {...props}
          />
        </Box>
      </Stack>

      {/* dialogs */}

      {showMaaltidsberegning && (
        <LopedagMaaltidsberegningModal
          showing={showMaaltidsberegning}
          hide={() => setShowMaaltidsberegning(false)}
          stoffData={stoffnMengdeData}
          maaltidTypes={maaltidTypes}
          maaltidsData={maaltidsData}
          currentMaaltidId={currentMaaltidId}
        />
      )}

      {showStoffgrupper && (
        <LopedagStoffgrupperModal
          open={showStoffgrupper}
          utvalgId={utvalgId}
          hide={() => setShowStoffgrupper(false)}
          databaseId={currentDatabase?.value}
          utvalgData={utvalgData}
          setUtvalgData={setUtvalgData}
          stoffgrupperTemp={stoffgrupperTemp}
          setStoffgrupperTemp={setStoffgrupperTemp}
        />
      )}

      {showAlert && (
        <AlertComp
          alertMessage={alertMessage}
          alertColor={alertSeverity}
          showAlert={showAlert}
          setShowAlert={setShowAlert}
        />
      )}
    </>
  );
}
