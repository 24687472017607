import ReactDOM from 'react-dom';
import React, { useState, useEffect } from 'react';
import MuiAlert, { AlertColor, AlertProps } from '@mui/material/Alert';
import {
  IconButton,
  Collapse,
  Snackbar,
  SnackbarOrigin,
  useTheme,
  Box
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../types/actions';
import Button from '@mui/material/Button';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const useStyles = () => {
  const theme = useTheme();
  return {
    root: {
      display: 'flex',
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2)
      }
    },
    button: {
      textTransform: 'none',
      fontFamily: 'font-family: Arial, Helvetica, sans-serif'
    }
  };
};
export const AlertPopUp = (props: any) => {
  const alertState = useSelector((state: AlertI) => state['alerts']);
  //console.log("alert called:", alertState);
  const [alertMsg, setAlertMsg] = useState('');
  const [openErrorAlert, setOpenErrorAlert] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [alertType, setAlertType] = useState<AlertColor>('error');
  const classes = useStyles();
  const dispatch = useDispatch();
  let isShowing = props.isShowing;

  useEffect(() => {
    showAlert();
  }, []);

  const showAlert = () => {
    dispatch({ type: actions.SET_ALERT_CLOSED, alertClosed: false });
    setOpenSnackBar(true);
    setAlertMsg(alertState['alertMessage']);
    setAlertType(alertState['alertColor']);
    setOpenErrorAlert(true);
  };

  const handleCloseSnackBar = () => {
    dispatch({ type: actions.SET_ALERT_CLOSED, alertClosed: true });
    setOpenSnackBar(false);
    props.hide();
  };

  return { isShowing }
    ? ReactDOM.createPortal(
        <Box sx={classes.root}>
          <Collapse in={openErrorAlert}>
            <Snackbar
              open={openSnackBar}
              autoHideDuration={5000}
              onClose={handleCloseSnackBar}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center'
              }}
            >
              <div>
                <Alert
                  severity={alertType}
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setOpenErrorAlert(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  {alertMsg}
                </Alert>
              </div>
            </Snackbar>
          </Collapse>
        </Box>,
        document.body
      )
    : null;
};
interface AlertI {
  alertMessage: string;
  alertColor: AlertColor;
  alertClosed?: boolean;
  autoHide?: number;
  verticalPosition?: SnackbarOrigin['vertical'];
  horizontalPosition?: SnackbarOrigin['horizontal'];
  showAlert: any;
  setShowAlert: any;
}
export function AlertWithAction(props: AlertI) {
  const classes = useStyles();
  const alertCompProps: AlertI = {
    alertMessage: props.alertMessage || undefined,
    autoHide: props.autoHide || 6000,
    verticalPosition: props.verticalPosition || 'top',
    horizontalPosition: props.horizontalPosition || 'center',
    alertColor: props.alertColor || 'info',
    showAlert: props.showAlert || undefined,
    setShowAlert: props.setShowAlert || undefined
  };
  const handleClose = (event: any, reason: any) => {
    if (reason === 'clickaway') {
      return;
    }

    props.setShowAlert(false);
  };

  return (
    <Box sx={classes.root}>
      <Snackbar
        open={props.showAlert}
        key={alertCompProps.alertMessage}
        autoHideDuration={null}
        onClose={handleClose}
        style={{ height: '100%' }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <div>
          <Alert
            style={{ whiteSpace: 'pre-wrap' }}
            severity={alertCompProps.alertColor}
            action={
              <Button
                aria-label="close"
                size="large"
                color="primary"
                onClick={() => {
                  props.setShowAlert(false);
                }}
              >
                Ok
              </Button>
            }
          >
            {alertCompProps.alertMessage}
          </Alert>
        </div>
      </Snackbar>
    </Box>
  );
}
