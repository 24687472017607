import React, { useContext, useEffect, useState } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import useStyles from '../../../css/materialtheme';
import { AlertI } from '../../../types/alertTypes';
import AlertComp from '../../utilities/AlertComp';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import PageContainer from '../home/PageContainer';
import { InputFields } from '../components/InputFields';
import { Matvare, MatvareResult } from './types/matvaregruppeTypes';
import {
  Grid,
  IconButton,
  InputLabel,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';
import { DatabaseContext } from '../../../context/DatabaseContext';
import useFetchData from '../../utilities/useFetchData';
import { databaseOptions } from '../../../assets/DatabaseOptions';
import { HentMatvareResult, Ingrediens } from './types/MatvareTypes';
import { AlertWithAction } from '../../utilities/AlertPopUp';
import useStylesTable from '../../../css/TableCss';
import MatvareSearch from './MatvareSearch';
import { ProgressBar } from '../../utilities/ProgressBar';
import { Dropdown, NButton } from '../components/Inputs';
import { OptionType } from '../components/commons/CommonTypes';

export default function HentMatvarer(props: any) {
  const kodeFromURL = props.match.params.matvarekode || 0; // This is MatvareKodeId that is in URL
  const navnFromURL = props.match.params.navn || ''; // This is Matvarenavn that is in URL
  const dbOptions: OptionType[] = databaseOptions;
  const { currentDatabase } = useContext(DatabaseContext);
  const classes = useStyles();
  const classesTable = useStylesTable();
  const [title] = useState('Hent Matvarer - Nutrifoodcalc');
  const [showAlert, setShowAlert] = useState(false);
  const [showAlertWA, setShowAlertWA] = useState(false);
  const [showProgress, setShowProgress] = useState(false);
  const [alertMessage, setAlertMessage] = React.useState('');
  const [alertSeverity, setAlertSeverity] = React.useState<AlertI['alertColor']>('info');
  const [fraDatabase, setFraDatabase] = useState<OptionType>({ value: 0, label: '' });
  const [allMatvares, setAllMatvares] = useState<MatvareResult[]>();
  const [typedMatvare, setTypedMatvare] = useState<Matvare>({
    MATVAREKODEID: kodeFromURL,
    NAVN: navnFromURL
  });
  const [showMatvarenavn, setShowMatvarenavn] = useState(false);
  const [checked, setChecked] = React.useState(true);
  const [arv, setArv] = React.useState('J');
  const [showIngeridensTable, setShowIngeridensTable] = useState(false);
  const [hentMatvareRes, setHentMatvareRes] = useState<HentMatvareResult>();
  document.title = title;
  const getAllMatvares = useFetchData({
    loadOnMount: false,
    clearDataOnLoad: false,
    projectURL: 'getMatvareList',
    subProjectURL: `${fraDatabase?.value}/${'1'}/${'999999'}` //HEre the database should be the one we are going to copy matvare from
  });
  const hentMatvare = useFetchData({
    loadOnMount: false,
    clearDataOnLoad: false,
    projectURL: 'hentMatvare',
    subProjectURL: `${currentDatabase?.value}`
  });
  useEffect(() => {
    if (getAllMatvares.data) {
      setAllMatvares(getAllMatvares.data);
    }
  }, [getAllMatvares.data]);
  useEffect(() => {
    if (hentMatvare.data) {
      const res = hentMatvare.data as unknown as HentMatvareResult;
      setHentMatvareRes(res);
      if (res?.MESSAGE) {
        if (res?.INGREDIENTS?.length > 0) {
          const msg = res?.MESSAGE + '. Se ingrediensene i tabellen nedenfor.';
          showAlertMessage(msg, 'error');
          setShowIngeridensTable(true);
        } else {
          res?.SUCCESS
            ? showAlertMessage(res?.MESSAGE, 'success')
            : showAlertMessage(res?.MESSAGE, 'error');
          setShowIngeridensTable(false);
        }
      }
    }
  }, [hentMatvare.data]);
  useEffect(() => {
    if (hentMatvare.error) {
      showAlertMessage(`Kan ikke hente matvaren, noe galt har skjedd .`, 'error');
    }
  }, [hentMatvare.error]);
  useEffect(() => {
    setShowIngeridensTable(false);
  }, [typedMatvare.MATVAREKODEID]);
  const showAlertMessage = (message: string, severity: any) => {
    setShowAlert(true);
    setAlertMessage(message);
    setAlertSeverity(severity);
  };
  const showAlertMessageWA = (message: string, severity: any) => {
    setShowAlertWA(true);
    setAlertMessage(message);
    setAlertSeverity(severity);
  };

  const handleSelectChange = (selectedDb: OptionType) => {
    setFraDatabase(selectedDb);
    getAllMatvares.loadData('getMatvareList', `${selectedDb?.value}/${'1'}/${'999999'}`);
  };

  const handleMatvareChange = (name: string, value: string | number) => {
    setTypedMatvare({ ...typedMatvare, [name]: value.toString() });
  };
  const handleEnterKeyPressOnKode = (event: React.KeyboardEvent<HTMLElement>) => {
    const value = parseInt(typedMatvare?.MATVAREKODEID?.toString());
    if (value !== 0 && event.key === 'Enter') {
      const found = allMatvares?.find(
        (mat: MatvareResult) => mat.MATVAREKODEID === value
      );
      if (!(found === undefined || value === 0)) {
        setTypedMatvare({
          ...typedMatvare,
          MATVAREKODEID: found.MATVAREKODEID,
          NAVN: found.NAVN
        });
      } else {
        showAlertMessage(`Matvaren finnes ikke i ${fraDatabase.label}.`, 'error');
      }
    }
  };
  const handleEnterKeyPressOnNavn = (event: React.KeyboardEvent<HTMLElement>) => {
    if (typedMatvare?.NAVN === '' && event.key === 'Enter') {
      const found = allMatvares?.find(
        (mat: MatvareResult) => mat.NAVN === typedMatvare.NAVN
      );
      if (!(found === undefined || typedMatvare.NAVN === '')) {
        setTypedMatvare({ ...typedMatvare, MATVAREKODEID: found.MATVAREKODEID });
      } else {
        showAlertMessage(`Matvaren finnes ikke i ${fraDatabase.label}.`, 'error');
      }
    }
  };

  const handleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    if (checked) {
      setArv('J');
    } else {
      setArv('N');
    }
  };
  const handleHentBtnClick = () => {
    if (fraDatabase?.value > currentDatabase?.value) {
      showAlertMessage(
        'Matvaren skal hentes uten retensjonsfaktorer (Det er ikke lov å hente retensjonsfaktorer til eldre database)',
        'warning'
      );
    }
    hentMatvare.loadData(
      'hentMatvare',
      `${currentDatabase?.value}/${fraDatabase?.value}/${typedMatvare.MATVAREKODEID}/${currentDatabase['label']}/${fraDatabase?.label}/${arv}`
    );
  };
  const helpOnClick = () => {
    showAlertMessageWA(
      `Henting (kopiering) av en eller flere mavarer fra en matvaredatabase til en annen, ev. med arv. Hvis man skal hente en enkelt matvare, kan denne søkes fram på vanlig måte ved hjelp av matvarekode eller navn, deretter trykker man 'Hent'.`,
      'info'
    );
  };
  return (
    <PageContainer>
      <Typography variant="h4">Hent matvarer</Typography>
      <Grid container alignItems="center" justifyContent="flex-start" spacing={2}>
        <Grid item xs={2}>
          <Typography>Fra matvaredatabase *</Typography>
        </Grid>
        <Grid item xs={4}>
          <Dropdown
            header=""
            value={fraDatabase}
            options={dbOptions?.filter((db) => db.value !== currentDatabase?.value)}
            onChange={(v: any) => handleSelectChange(v)}
            optionLabel={(o) => o.label}
          />
        </Grid>
        <Grid item xs={2}>
          <Typography>(til {currentDatabase.label})</Typography>{' '}
        </Grid>
        <Grid item xs={2}>
          <IconButton color="primary" onClick={helpOnClick}>
            <InfoIcon />
            Hjelp
          </IconButton>
        </Grid>
      </Grid>
      <Grid container alignItems="flex-start" justifyContent="flex-start" spacing={2}>
        <Grid item xs={6}>
          <FormControlLabel
            label="Etabler arv"
            control={
              <Checkbox
                checked={checked}
                onChange={handleCheckBoxChange}
                defaultChecked={true}
              />
            }
          />
        </Grid>
      </Grid>
      <Grid item xs={12} container spacing={2} alignItems="flex-end">
        <Grid item xs={3}>
          <InputLabel sx={classes.InputLabel}>Matvarekode*</InputLabel>
          <TextField
            id="Kode"
            maxRows="1"
            value={typedMatvare.MATVAREKODEID || ''}
            onChange={(e) => handleMatvareChange('MATVAREKODEID', e.target.value)}
            style={{ paddingTop: 0, marginTop: 0 }}
            name="MATVAREKODEID"
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleEnterKeyPressOnKode(e);
              }
            }}
            required
            variant="outlined"
            size="small"
            disabled={fraDatabase?.value === 0}
          />
        </Grid>
        <Grid item xs={3}>
          <InputFields
            classes={classes}
            id={'navn'}
            name={'NAVN'}
            value={typedMatvare?.NAVN || ''}
            type={'text'}
            handleChange={handleMatvareChange}
            handleOnEnterKeyPress={handleEnterKeyPressOnNavn}
            required={true}
            disabled={fraDatabase?.value === 0}
            label={' Matvarenavn'}
          />
        </Grid>
        <Grid item xs={3}>
          <NButton
            children="Søk etter matvare"
            leftIcon={<SearchOutlinedIcon />}
            onClick={() => setShowMatvarenavn(true)}
            fullWidth
            disabled={fraDatabase?.value === 0}
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        justifyContent="flex-start"
        alignItems="flex-end"
        sx={{ paddingTop: 2, paddingBottom: 2 }}
      >
        <Grid item xs={6}>
          {showIngeridensTable && (
            <>
              <InputLabel>Manglende ingredienser:</InputLabel>
              <Table sx={classesTable.body}>
                <TableHead>
                  <TableRow>
                    <TableCell sx={classesTable.head}>Kode</TableCell>
                    <TableCell sx={classesTable.head}>Navn</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody sx={classesTable.root}>
                  {hentMatvareRes?.INGREDIENTS?.map((row: Ingrediens) => {
                    return (
                      <TableRow key={row?.INGREDIENSID} sx={classesTable.tableRow}>
                        <TableCell sx={classesTable.tableRow}>
                          {row?.INGREDIENSID}
                        </TableCell>
                        <TableCell sx={classesTable.tableRow}>{row?.NAVN}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </>
          )}
        </Grid>
        <Grid item xs={2}></Grid>
      </Grid>
      <Grid container spacing={2} justifyContent="flex-start" alignItems="flex-end">
        <Grid item xs={4}>
          <NButton
            children="Hent"
            onClick={handleHentBtnClick}
            disabled={
              typedMatvare?.MATVAREKODEID === 0 ||
              fraDatabase.value === 0 ||
              showIngeridensTable
            }
          />
        </Grid>
      </Grid>
      {showAlert && (
        <AlertComp
          alertMessage={alertMessage}
          alertColor={alertSeverity}
          showAlert={showAlert}
          setShowAlert={setShowAlert}
        />
      )}
      {showAlertWA && (
        <AlertWithAction
          alertMessage={alertMessage}
          alertColor={alertSeverity}
          showAlert={showAlertWA}
          setShowAlert={setShowAlertWA}
        />
      )}
      {showMatvarenavn && (
        <MatvareSearch
          setMatvare={(id, navn) => {
            setTypedMatvare({
              MATVAREKODEID: parseInt(id.toString()),
              NAVN: navn
            });
          }}
          databaseId={fraDatabase?.value}
          showing={showMatvarenavn}
          showProgress={showProgress}
          setShowProgress={setShowProgress}
          hide={() => setShowMatvarenavn(false)}
        />
      )}
      {showProgress && (
        <ProgressBar
          isShowing={showProgress}
          hide={() => {
            setShowProgress(!showProgress);
          }}
        />
      )}
    </PageContainer>
  );
}
