import React from 'react';
import { useDatabaseContext } from '../../context/DatabaseContext';
import { FormControl, useTheme } from '@mui/material';
import { databaseOptions } from '../../assets/DatabaseOptions';
import { Dropdown } from '../pages/components/Inputs';
import { OptionType } from '../pages/components/commons/CommonTypes';

const useStyles = () => {
  const theme = useTheme();
  return {
    selectBeregnMdbMenu: {
      width: 200,
      backgroundColor: 'white'
    }
  };
};
export default function SelectDatabase() {
  const classes = useStyles();
  const initialDb = {
    label: databaseOptions[0]?.label || '',
    value: databaseOptions[0]?.value || 0
  };
  const db = useDatabaseContext();

  const changeDatabase = (option: OptionType) => {
    if (db.currentDatabase) {
      db.setCurrentDatabase(option);
    }
  };

  return (
    <FormControl sx={classes.selectBeregnMdbMenu}>
      <Dropdown
        header=""
        defaultValue={initialDb}
        value={db.currentDatabase || initialDb}
        options={databaseOptions}
        optionLabel={(o) => o.label}
        onChange={(v) => changeDatabase(v as OptionType)}
        disableClearable={true}
      />
    </FormControl>
  );
}
