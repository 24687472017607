import React from 'react';
import { Grid, Typography } from '@mui/material';
import { SingleStoff, StoffI } from '../types/StoffTypes';

interface StoffNoLinkProps {
  stoff: StoffI;
}

function StoffNoLink(p: StoffNoLinkProps) {
  return (
    <>
      <Grid item xs={6}>
        <Typography>
          Stoff:{' '}
          <b>
            {p.stoff?.stoffnrId}- {p.stoff?.kortnavn}
          </b>
        </Typography>
      </Grid>
    </>
  );
}

export default StoffNoLink;
