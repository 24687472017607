import React from 'react';
import { InputBase, TableCell } from '@mui/material';

interface SingleInputProps {
  value: number | string;
  handleChangeTable: (name: string, value: string | number, index: number) => void;
  name: string;
  owned?: boolean;
  maxLength?: number;
  size?: string;
  index?: number;
  isDisabled?: boolean;
  keyDown?: boolean;
  handleOnEnterKeyPress?: (event: React.KeyboardEvent<HTMLElement>) => void;
  inputType?: string;
  setInputTableIndex?: React.Dispatch<React.SetStateAction<number>>;
  placeholderText?: string;
  required?: boolean;
  endAdornment?: any;
}
export const SingleInput = (singleProps: SingleInputProps) => {
  const {
    value,
    owned,
    maxLength,
    handleChangeTable,
    name,
    size,
    index,
    isDisabled,
    inputType,
    placeholderText,
    /*     setInputTableIndex,*/
    keyDown,
    handleOnEnterKeyPress,
    required,
    endAdornment
  } = singleProps;
  return (
    <TableCell
      style={{
        paddingBottom: 10,
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: 10,
        margin: 0,
        width: size
      }}
    >
      <InputBase
        value={value || ''}
        name={name}
        readOnly={!owned}
        inputProps={{
          min: 0,
          maxLength: maxLength,
          style: {
            textAlign: 'left',
            borderRight: '1px solid lightgrey',
            borderLeft: '1px solid lightgrey',
            borderRadius: 0,
            padding: 4
          }
        }}
        endAdornment={endAdornment}
        style={{ width: '100%' }}
        type={inputType || 'text'}
        autoComplete="off"
        disabled={isDisabled || false}
        onChange={(e) => handleChangeTable(e.target.name, e.target.value, index)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            keyDown ? handleOnEnterKeyPress(e) : null;
          }
        }}
        placeholder={placeholderText || ''}
        required={required}
      />
    </TableCell>
  );
};
