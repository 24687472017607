export const tilberedningKodes = [
  {
    value: 'NONE',
    label: 'Ingen tilberedning'
  },
  {
    value: 'KOK',
    label: 'Koking'
  },
  {
    value: 'STEK',
    label: 'Steking'
  },
  {
    value: 'BAK',
    label: 'Baking'
  }
];
