import React, { useEffect } from 'react';
import { BeregningObject, ReportSevenI } from '../../types/BeregningTypes';
import useDidMountEffect from '../../../../utilities/useDidMountEffect';
interface ReportSevenProps {
  beregningObject: BeregningObject;
  setReportData: (value: any[]) => void;
  setReportInfo: (value: Array<Array<string>>) => void;
  setErrorReportId: (value: number) => void;
  result: any;
}

export const Report7Result = (props: ReportSevenProps) => {
  const [data] = React.useState([]);
  const [info] = React.useState<Array<Array<string>>>([]);
  const { beregningObject, setReportData, setReportInfo, setErrorReportId, result } =
    props;
  useEffect(() => {
    if (result) {
      console.log(' Beregning result.', 'success');
      const data = result as unknown as ReportSevenI;
      if (
        data?.reportInfo !== undefined &&
        data?.reportInfo?.beregningInfo !== undefined &&
        data?.stoffProsents !== undefined &&
        data?.persons !== undefined &&
        data?.stoffs !== undefined
      ) {
        prepareResult(data);
      }
    }
  }, [result]);

  const prepareResult = (reportSevenData: ReportSevenI) => {
    let description: string[];
    setErrorReportId(reportSevenData?.reportInfo?.reportId);
    for (let r of reportSevenData?.reportInfo?.beregningInfo) {
      description = [];
      description.push(r.TEKSTLINJE);
      info.push(description);
    }
    setReportInfo(info);
    let result: any[] = [];
    const shortNames: any[] = [];
    const enhet: any[] = [];
    shortNames.push('Nr');
    shortNames.push('Id');
    enhet.push('');
    enhet.push('');

    for (let name of reportSevenData?.stoffs) {
      shortNames.push(name.KORTNAVN);
      enhet.push(name.ENHET);
    }
    data.push(['']);
    data.push(shortNames);
    data.push(enhet);
    let i = 0;
    for (let person of reportSevenData?.persons) {
      result = [];
      result.push(person.PERSONID);
      result.push(person.IDENTITET);
      for (let s = 0; s < reportSevenData?.stoffs?.length; s++) {
        if (reportSevenData?.stoffProsents?.length > 0) {
          result.push(reportSevenData?.stoffProsents[i + s].PROSENT);
        }
      }
      i = i + reportSevenData?.stoffs?.length;
      data.push(result);
    }
    setReportData(data);
  };
  return <></>;
};
