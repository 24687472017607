import React, { useEffect } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import postData from '../../../utilities/postData';
import { StatusTypes } from '../../components/commons/CommonTypes';

interface PollingProps {
  setFailed: (val: boolean) => void;
  setResult: (result: any) => void;
  setResponse?: (result: any) => void;
  startPolling: boolean;
  setStartPolling: (v: boolean) => void;
}

const BResultPollingComponent: React.FC<PollingProps> = ({
  setFailed,
  setResult,
  setResponse,
  startPolling,
  setStartPolling
}) => {
  const { keycloak } = useKeycloak();
  const token = keycloak?.token;
  useEffect(() => {
    const callApiUntilResultReady = async () => {
      if (!startPolling) return;
      try {
        const baseUrl = process.env.REACT_APP_BACKEND;
        const url = `${baseUrl}/getAllBeregningResultsByUser`;
        const response = await fetch(encodeURI(url), {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
          }
        });
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        } else {
          const data = await response.json();
          setResponse && setResponse(data);
          if (response.status === 200) {
            if (data) {
              setResult(data);
              const processingData = data?.find(
                (d) => d.status === StatusTypes.Processing || d.status === StatusTypes.New
              );
              if (processingData) {
                // Wait for 5 seconds and then call the function again recursively
                await new Promise((resolve) => setTimeout(resolve, 5000));
                await callApiUntilResultReady();
              } else {
                setStartPolling(false);
              }
            }
          }
        }
        //}
      } catch (error) {
        console.error('Error calling API:', error);
        setFailed(true);
        return;
      }
    };

    callApiUntilResultReady();

    // Cleanup function (optional)
    return () => {
      // Add any cleanup logic here
    };
  }, [startPolling]);

  return null; // Since this component is only for functionality, it doesn't render anything
};

export default BResultPollingComponent;
