import React from 'react';
import XLSX from 'xlsx';
import { NButton } from '../../../components/Inputs';

import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Grid } from '@mui/material';
import { ExcludertMatvarer } from '../../types/BeregningTypes';
import { useUserContext } from '../../../../../context/AuthContext';

const ExcelRapport = ({
  data,
  projectId,
  surveyId
}: {
  data: ExcludertMatvarer[];
  projectId: number;
  surveyId: number;
}) => {
  const exportToExcel = (
    user: string,
    info: ExcludertMatvarer[],
    projectId: number,
    surveyId: number
  ) => {
    const workbook = XLSX.utils.book_new();
    const dbsheet = XLSX.utils.aoa_to_sheet([
      ['Prosjekt', projectId],
      ['Undersøkelse', surveyId]
    ]);
    XLSX.utils.book_append_sheet(workbook, dbsheet, 'Prosjekt info');

    info.map((matvare) => {
      const sheet = XLSX.utils.aoa_to_sheet([
        ['Matvare: '],
        ['Kode', matvare.MATVAREKODEID],
        ['Navn', matvare.NAVN],
        ['Antall', matvare.MAALTIDSKOMPONENTER?.length],
        ['Måltidskomponenter: '],
        ['Person', 'Løpedag', 'Måltidnr', 'Antall'],
        ...matvare.MAALTIDSKOMPONENTER.map((maltidkomponent) => [
          maltidkomponent.PERSONID,
          maltidkomponent.MAALTIDNRID,
          maltidkomponent.LOPEDAGID,
          maltidkomponent.ANTALL
        ])
      ]);
      XLSX.utils.book_append_sheet(workbook, sheet, matvare.MATVAREKODEID.toString());
    });

    XLSX.writeFile(workbook, `${user}-ekskludert-fra-beregning.xlsx`);
  };
  const loggedInuser = useUserContext();
  const username = loggedInuser?.user?.username.toLowerCase();

  return (
    <Grid item xs={6}>
      <NButton
        rightIcon={<FileDownloadOutlinedIcon />}
        children="Last ned"
        onClick={() => exportToExcel(username, data, projectId, surveyId)}
        fullWidth
      />
    </Grid>
  );
};

export default ExcelRapport;
