import React from 'react';
import {
  Container,
  TableBody,
  TableHead,
  TableRow,
  Table,
  TableCell,
  InputLabel,
  Box,
  TableContainer,
  Paper
} from '@mui/material';
import useStylesTable from '../../../../css/TableCss';
import useStyles from '../../../../css/materialtheme';
import { EnhetData, IngredientData } from '../types/MatvareTypes';
import { useEffect } from 'react';
import useFetchData from '../../../utilities/useFetchData';
import DialogGeneral from '../../../utilities/DialogGeneral';

interface EnhetProps {
  showing: boolean;
  hide: () => void;
  databaseId: number;
  selectedIngeredient: IngredientData;
  newRow: IngredientData;
  ingredientsList: IngredientData[];
  setRowWithNewEnhet: (newEnhet: IngredientData) => void;
  setRows: (data: IngredientData[]) => void;
  setNewRow: (data: IngredientData) => void;
  setSelectedEnhet: (data: EnhetData) => void;
}

export default function EnhetList(props: EnhetProps) {
  const {
    showing,
    hide,
    databaseId,
    selectedIngeredient,
    newRow,
    ingredientsList,
    setRowWithNewEnhet,
    setRows,
    setNewRow,
    setSelectedEnhet
  } = props;
  const classes = useStyles();
  const classesTable = useStylesTable();
  const [selectedRow, setSelectedRow] = React.useState(null);
  const [enhetData, setEnhetData] = React.useState<EnhetData[]>([]);

  const getEnheter = useFetchData({
    loadOnMount: false,
    clearDataOnLoad: false,
    projectURL: 'getEnheter',
    subProjectURL: `${databaseId}/${selectedIngeredient?.INGREDIENSID}`
  });

  useEffect(() => {
    if (selectedIngeredient?.INGREDIENSID > 0) {
      const enhets = ingredientsList?.find(
        (row: IngredientData) => row === selectedIngeredient
      )?.ENHETER;

      if (enhets?.length > 0) {
        setEnhetData(enhets);
      } else {
        getEnheter.loadData(
          'getEnheter',
          `${databaseId}/${newRow?.INGREDIENSID || selectedIngeredient?.INGREDIENSID}`
        );
      }
    }
  }, [selectedIngeredient]);

  useEffect(() => {
    if (newRow?.INGREDIENSID > 0) {
      getEnheter.loadData('getEnheter', `${databaseId}/${newRow?.INGREDIENSID}`);
    }
  }, [newRow]);

  useEffect(() => {
    if (getEnheter.data) {
      const data: EnhetData[] = getEnheter.data as unknown as EnhetData[];
      if (data?.length > 0) {
        setEnhetData(data);
      }
    }
  }, [getEnheter.data]);

  const handleClick = (highlighted: boolean, selectedEnhet: EnhetData) => {
    setSelectedRow(highlighted ? selectedEnhet : null);
    setSelectedEnhet(selectedEnhet);
    handleChange(selectedIngeredient, selectedEnhet);
    hide();
  };

  const handleChange = (row: IngredientData, selectedEnhet: EnhetData) => {
    const rowIndex = ingredientsList?.findIndex(
      (d) => d.INGREDIENSID === row.INGREDIENSID
    );
    if (rowIndex !== -1) {
      const updatedRows = [...ingredientsList];
      updatedRows[rowIndex] = {
        ...updatedRows[rowIndex],
        GRAM: parseFloat(selectedEnhet.MENGDE?.toString()?.replace(/,/g, '.')),
        MENGDEENHET: selectedEnhet.MENGDEENHETID,
        gPerEnhet: selectedEnhet.MENGDE,
        ANTALL: ''
      };
      setRows(updatedRows);
      setSelectedRow(row);
    } else {
      const updatedNewRow = {
        ...newRow,
        GRAM: parseFloat(selectedEnhet.MENGDE?.toString()?.replace(/,/g, '.')),
        MENGDEENHET: selectedEnhet.MENGDEENHETID,
        gPerEnhet: selectedEnhet.MENGDE,
        ANTALL: ''
      };
      setNewRow(updatedNewRow);
      /*  setNewRow('GRAM', parseFloat(selectedEnhet.MENGDE?.toString()));
      handleNewRow('MENGDEENHET', selectedEnhet.MENGDEENHETID);
      handleNewRow('gPerEnhet', selectedEnhet.MENGDE); */
    }
  };

  return (
    <DialogGeneral
      title={'Mengdeenheter'}
      open={showing}
      hide={hide}
      fullWidth
      maxWidth="md"
    >
      {/* <Container id="EnhetList" style={{ minWidth: 800 }}> */}
      <InputLabel sx={classes.InputLabel}>
        Matvare: {selectedIngeredient?.NAVN}
      </InputLabel>
      {/* <Box> */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Sort</TableCell>
              <TableCell>Enhet</TableCell>
              <TableCell>g/enhet</TableCell>
              <TableCell>Ref.</TableCell>
            </TableRow>
          </TableHead>
          {
            <TableBody>
              {enhetData?.map((row: EnhetData, index: number) => {
                const highlighted =
                  selectedRow && selectedRow.SORTERING === row.SORTERING;
                return (
                  <TableRow
                    key={row.SORTERING}
                    onClick={() => {
                      handleClick(highlighted, row);
                    }}
                  >
                    <TableCell>{row.SORTERING}</TableCell>
                    <TableCell>{row.MENGDEENHETID}</TableCell>
                    <TableCell>{row.MENGDE?.toString()?.replace(/,/g, '.')}</TableCell>
                    <TableCell>{row.REF}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          }
        </Table>
      </TableContainer>
      {/* </Box> */}
      {/* </Container> */}
    </DialogGeneral>
  );
}
