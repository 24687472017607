import React, { useState } from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  TablePagination,
  Link,
  Checkbox
} from '@mui/material';
import useStylesTable from '../../../../css/TableCss';
import { Matvare, TableType } from '../types/matvaregruppeTypes';
import {
  areAllElementsInArray,
  removeArrayFromAnotherArray,
  someButNotAllElementsInArray
} from '../../../utilities/ArrayFunctions';

interface MatvareTableProps {
  owned: boolean;
  parent: string;
  tableType: TableType;
  matvarerList: Matvare[];
  page: number;
  rowsPerPage: number;
  handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangePage: (event: any, newPage: number) => void;
  selectedMatvares?: Matvare[];
  setSelectedMatvares?: (mat: Matvare[]) => void;
  highLevelCategory?: number;
}

export const MatvareTable = (props: MatvareTableProps) => {
  const {
    owned,
    parent,
    tableType,
    matvarerList,
    page,
    rowsPerPage,
    handleChangeRowsPerPage,
    handleChangePage,
    selectedMatvares,
    setSelectedMatvares,
    highLevelCategory
  } = props;
  const classesTable = useStylesTable();
  const [selectedRow, setSelectedRow] = useState(null);
  const [newMat, setNewMat] = useState<Matvare>();
  const handleNewRow = (name: string, value: string | number) => {
    setNewMat({ ...newMat, [name]: value as number });
  };
  const handleSelectMatvare = (matvare: Matvare) => {
    const index = selectedMatvares?.findIndex(
      (n: Matvare) => n.MATVAREKODEID === matvare?.MATVAREKODEID
    );

    index > -1
      ? setSelectedMatvares(
          selectedMatvares?.filter((item) => item.MATVAREKODEID !== matvare.MATVAREKODEID)
        )
      : setSelectedMatvares([...selectedMatvares, matvare]);
  };
  const handleSelectAllMatvare = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const selecteds = removeArrayFromAnotherArray(
        matvarerList,
        selectedMatvares,
        'MATVAREKODEID'
      );
      setSelectedMatvares([...selectedMatvares, ...selecteds]);
    } else {
      const updatedMatvares = removeArrayFromAnotherArray(
        selectedMatvares,
        matvarerList,
        'MATVAREKODEID'
      );
      setSelectedMatvares(updatedMatvares);
    }
  };
  const isSelected = (row: Matvare) => {
    let selected = false;
    const found: boolean = selectedMatvares
      ?.map((u) => u.MATVAREKODEID)
      ?.includes(row.MATVAREKODEID);
    if (found || highLevelCategory === 3 || highLevelCategory === 4) {
      selected = true;
    }
    return selected;
  };
  return (
    <div>
      <b>{parent}</b>
      <TableContainer
        style={{
          height: 800,
          overflowY: 'auto'
        }}
        id="matvareTable"
      >
        <Table sx={classesTable.body}>
          <TableHead sx={classesTable.head}>
            <TableRow>
              {tableType === TableType.From ||
              tableType === TableType.Utvalg ||
              tableType === TableType.Beregning ||
              tableType === TableType.Fjern ? (
                <TableCell padding="checkbox">
                  <Checkbox
                    indeterminate={someButNotAllElementsInArray(
                      selectedMatvares,
                      matvarerList,
                      'MATVAREKODEID'
                    )}
                    checked={areAllElementsInArray(
                      matvarerList,
                      selectedMatvares,
                      'MATVAREKODEID'
                    )}
                    onChange={handleSelectAllMatvare}
                  />
                </TableCell>
              ) : undefined}
              <TableCell>Kode</TableCell>
              <TableCell>Navn</TableCell>
            </TableRow>
          </TableHead>
          <TableBody
            sx={classesTable.root}
            style={{
              maxHeight: 500,
              overflowY: 'auto'
            }}
          >
            {matvarerList
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.map((row: Matvare) => {
                const highlighted =
                  selectedRow && selectedRow.MATVAREKODEID === row.MATVAREKODEID;
                //const isItemSelected = isSelected(row);
                return (
                  <TableRow
                    key={row.MATVAREKODEID}
                    hover
                    role="checkbox"
                    onClick={() => {
                      setSelectedRow(highlighted ? null : row);
                    }}
                    style={{
                      backgroundColor: highlighted ? '#d2ecfd' : 'white'
                    }}
                    sx={classesTable.tableRow}
                  >
                    {tableType === TableType.From ||
                    tableType === TableType.Utvalg ||
                    tableType === TableType.Beregning ||
                    tableType === TableType.Fjern ? (
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isSelected(row)}
                          onClick={() => handleSelectMatvare(row)}
                          disabled={
                            !(highLevelCategory == 5 || highLevelCategory == 0) &&
                            tableType === TableType.Beregning
                          }
                        />
                      </TableCell>
                    ) : undefined}
                    <TableCell padding="checkbox" sx={classesTable.tableRow}>
                      <Link
                        target="_blank"
                        href={'/home/matvaredata/matvare/' + row.MATVAREKODEID}
                        underline={'hover'}
                      >
                        {row.MATVAREKODEID}
                      </Link>{' '}
                    </TableCell>
                    <TableCell padding="checkbox" sx={classesTable.tableRow}>
                      {row.NAVN}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        <TablePagination
          sx={classesTable.Pagination}
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={matvarerList?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </div>
  );
};
