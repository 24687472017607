import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

const EmptyTable = () => (
  <>
    <Typography>Måltidskomponenter</Typography>
    <Table classes="DialogTable">
      <TableHead>
        <TableRow>
          <TableCell>Person</TableCell>
          <TableCell>Løpedag</TableCell>
          <TableCell>Måltidnr</TableCell>
          <TableCell>Antall</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow hover role="checkbox"></TableRow>

        <TableRow id="dialogTable"></TableRow>
      </TableBody>
    </Table>
  </>
);
export default EmptyTable;
