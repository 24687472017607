import React, { useEffect } from 'react';
import { BeregningObject, ReportSixI } from '../../types/BeregningTypes';
import useDidMountEffect from '../../../../utilities/useDidMountEffect';
interface ReportSixProps {
  beregningObject: BeregningObject;
  setReportData: (value: any[]) => void;
  setReportInfo: (value: Array<Array<string>>) => void;
  setErrorReportId: (value: number) => void;
  result: any;
  unitId: number;
}
export const Report6Result = (props: ReportSixProps) => {
  const [data] = React.useState([]);
  const [info] = React.useState<Array<Array<string>>>([]);
  const {
    beregningObject,
    setReportData,
    setReportInfo,
    setErrorReportId,
    result,
    unitId
  } = props;
  useEffect(() => {
    if (result) {
      console.log(' Beregning result.', 'success');
      const data = result as unknown as ReportSixI;
      if (
        data?.reportInfo !== undefined &&
        data?.reportInfo?.beregningInfo !== undefined &&
        data?.stoffAndMengdes !== undefined &&
        data?.matvares !== undefined &&
        data?.stoffs !== undefined
      ) {
        prepareResult(data);
      }
    }
  }, [result]);

  const prepareResult = (reportSixData: ReportSixI) => {
    let description: string[];
    setErrorReportId(reportSixData?.reportInfo?.reportId);
    for (let r of reportSixData?.reportInfo?.beregningInfo) {
      description = [];
      description.push(r.TEKSTLINJE);
      info.push(description);
    }
    setReportInfo(info);
    let result: any[] = [];
    const shortNames: any[] = [];
    const enhet: any[] = [];
    shortNames.push('');
    enhet.push('');

    if (unitId === 11) {
      for (let stoff of reportSixData?.stoffs) {
        shortNames.push(stoff.KORTNAVN);
        enhet.push(stoff.ENHET + '/%');
      }
      data.push(['']);
      data.push(['']);
      data.push(shortNames);
      data.push(enhet);
      for (let mat of reportSixData?.matvares) {
        result = [];
        result.push(mat.NAVN);
        for (let mengde of reportSixData?.stoffAndMengdes) {
          if (mat.REKKEFOLGE === mengde.REKKEFOLGE) {
            result.push(mengde.PROSENT);
          }
        }
        data.push(result);
      }
    } else {
      shortNames.push('Matinntak');
      enhet.push('g');

      for (let stoff of reportSixData?.stoffs) {
        shortNames.push(stoff.KORTNAVN);
        enhet.push(stoff.ENHET);
      }
      data.push(['']);
      data.push(['']);
      data.push(shortNames);
      data.push(enhet);
      for (let mat of reportSixData?.matvares) {
        result = [];
        result.push(mat.NAVN);
        for (let mengde of reportSixData?.stoffAndMengdes) {
          if (mat.REKKEFOLGE === mengde.REKKEFOLGE) {
            result.push(mengde.MENGDE);
          }
        }
        data.push(result);
      }
    }
    setReportData(data);
  };
  return <></>;
};
