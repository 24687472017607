import React, { ChangeEvent, forwardRef, useEffect, useRef, useState } from 'react';
import {
  Box,
  TextField,
  Typography,
  StandardTextFieldProps,
  Autocomplete,
  Button,
  ButtonProps,
  Stack,
  IconButton,
  Popover,
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid,
  InputAdornment,
  FormControl,
  OutlinedInput,
  OutlinedInputProps,
  BaseTextFieldProps,
  Input,
  TextFieldProps
} from '@mui/material';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { useUserContext } from '../../../context/AuthContext';
import { UserRole } from '../admin/AdminTypes';
import ClearIcon from '@mui/icons-material/Clear';
import { maxWidth, styled, width } from '@mui/system';
interface InputBaseProps {
  header: string;
  input?: React.ReactNode;
  required?: boolean; // Add required prop here
}

function InputBase(p: InputBaseProps) {
  return (
    <Box>
      <Typography children={p.header} sx={{ whiteSpace: 'nowrap' }} />
      {p.input || undefined}
    </Box>
  );
}

interface TextProps extends StandardTextFieldProps, InputBaseProps {}

export function Text(p: TextProps) {
  return (
    <InputBase
      header={p.header}
      input={
        <TextField
          {...p}
          size="small"
          fullWidth
          sx={{ background: p.disabled && 'rgba(0, 0, 0, 0.04)' }}
          required={p.required || false} // Pass required prop to TextField
        />
      }
    />
  );
}

export function SlowText(props: TextProps) {
  const { value, onChange, ...p } = props;

  const [tempValue, setTempValue] = React.useState<any>(undefined);

  React.useEffect(() => setTempValue(value !== undefined ? value : undefined), [value]);

  return (
    <Text
      {...p}
      value={tempValue}
      onChange={(e) => setTempValue(e.target.value)}
      onBlur={(e) => onChange(e as any)}
      onKeyDown={(e) => e.key === 'Enter' && onChange(e as any)}
      required={p.required}
    />
  );
}

interface MonoSearchFieldProps extends TextProps {
  searchFn: (a: string) => void;
  preValue?: string;
  // setPreValue?: Dispatch<SetStateAction<string>>;
}

export function MonoSearchField(props: MonoSearchFieldProps) {
  const { searchFn, preValue, ...p } = props;
  const [searchText, setSearchText] = React.useState<string>('');
  const [startedTyping, setStartedTyping] = useState<boolean>(false);
  const clearSearchText = () => {
    setSearchText('');
  };

  useEffect(() => {
    if (preValue) {
      searchFn(preValue);
    } else {
      searchFn(searchText);
    }
  }, []);

  return (
    <InputBase
      header={p.header}
      input={
        <Stack direction="row" width="100%">
          <Box sx={{ '& *': { borderRadius: '4px 0 0 4px' } }}>
            <TextField
              {...p}
              value={startedTyping ? searchText : preValue}
              onChange={(e) => setSearchText(e.target.value)}
              onKeyDown={(e) => {
                setStartedTyping(true);
                e.key === 'Enter' && searchFn(searchText);
              }}
              size="small"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      size="small"
                      onClick={() => {
                        setStartedTyping(true);
                        clearSearchText();
                        searchFn('');
                      }}
                    >
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              sx={{ background: p.disabled && 'rgba(0, 0, 0, 0.04)' }}
            />
          </Box>
          <Box sx={{ '& *': { borderRadius: '0 4px 4px 0', height: '100%' } }}>
            <Button
              variant="contained"
              children={<SearchOutlinedIcon />}
              disabled={p.disabled}
              onClick={() => searchFn(searchText)}
            />
          </Box>
        </Stack>
      }
    />
  );
}

interface DropdownProps<T> {
  header: string;
  value: T;
  options: T[];
  optionLabel?: (value: T) => string;
  defaultValue?: T;
  multiple?: boolean;
  onChange?: (value: T | readonly T[]) => void;
  disabled?: boolean;
  disableClearable?: boolean;
  width?: string;
  readOnly?: boolean;
  isOptionEqualToValue?: (option: T, value: T) => boolean;
}

export function Dropdown<T>(p: DropdownProps<T>) {
  return (
    <Autocomplete
      renderInput={(params) => <Text {...params} header={p.header} />}
      options={p.options}
      multiple={p.multiple}
      value={p.value}
      defaultValue={p.value}
      getOptionLabel={p.optionLabel || ((o) => o as string)}
      onChange={(e, v) => p.onChange(v)}
      size="small"
      disabled={p.disabled}
      disableClearable={p.disableClearable}
      sx={{ width: p.width || '100%' }}
      readOnly={p.readOnly}
      isOptionEqualToValue={p.isOptionEqualToValue}
    />
  );
}

interface NButtonProps extends ButtonProps {
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
}

// export function NButton(props: NButtonProps) {
const NButton = forwardRef<HTMLButtonElement, NButtonProps>((props, ref) => {
  const { leftIcon, rightIcon, children, ...p } = props;
  const loggedinuser = useUserContext();
  const text = (
    <Stack direction={'row'} spacing={1} alignItems="center" width="100%">
      {leftIcon}
      <Typography>{children}</Typography>
      {rightIcon}
    </Stack>
  );
  const readOnly = loggedinuser.user.roles?.includes(UserRole.ReadOnly);
  const disabled =
    p.disabled || (readOnly && !(leftIcon !== undefined || p.variant === 'outlined'));
  return (
    <Button
      ref={ref}
      variant="contained"
      color="primary"
      style={{
        textTransform: 'none',
        fontSize: 'medium',
        height: '2.5em',
        borderRadius: '0.2em',
        boxShadow: 'none'
      }}
      children={text}
      {...p}
      disabled={disabled || false}
    />
  );
});

export { NButton };

// export const NButton = forwardRef((props, ref) => <NButtonPre {...props} ref={ref} />);

interface FileImportProps {
  onDrop: (file: File | FileList) => void;
  accept?: string;
  multiple?: boolean;
  disabled?: boolean;
  actionLabel?: string;
}

export function FileImport(p: FileImportProps) {
  const [dragging, setDragging] = React.useState<boolean>(false);

  const handleDrag = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragging(true);
    } else if (e.type === 'dragleave') {
      setDragging(false);
    }
  };

  const handleDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files.length) {
      if (p.multiple) {
        p.onDrop(e.dataTransfer.files);
      } else {
        p.onDrop(e.dataTransfer.files[0]);
      }
    }
    setDragging(false);
  };

  const handleChange = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.target.files.length) {
      if (p.multiple) {
        p.onDrop(e.target.files);
      } else {
        p.onDrop(e.target.files[0]);
      }
    }
  };

  return (
    <div
      onDragEnter={handleDrag}
      onDragOver={handleDrag}
      onDragLeave={handleDrag}
      onDrop={!p.disabled && handleDrop}
      style={{
        height: '100%',
        width: '100%'
      }}
    >
      <Button
        sx={{
          border: `0.15em ${dragging ? 'solid' : 'dashed'} ${
            !p.disabled ? '#1976d2' : '#000000'
          }`,
          background: p.disabled
            ? 'rgba(0, 0, 0, 0.04)'
            : dragging && 'rgba(25, 118, 210, 0.04)',
          height: '100%',
          width: '100%',
          textTransform: 'none'
        }}
        component="label"
        disabled={p.disabled}
      >
        <input
          type="file"
          accept={p.accept}
          multiple={p.multiple}
          style={{ display: 'none' }}
          onChange={(e) => handleChange(e)}
        />
        <Stack alignItems="center">
          <FileUploadOutlinedIcon />
          <Typography>
            <u>Velg en {p.accept ? `${p.accept} fil` : 'fil'}</u> eller dra og slipp en
            fil til å {p.actionLabel ? p.actionLabel : 'importere'}
          </Typography>
        </Stack>
      </Button>
    </div>
  );
}

interface GenericImportProps {
  help: () => void;
  onChange: (f: File) => void;
  disabled?: boolean;
  selected?: string;
  accept?: string;
  actionLabel?: string;
}

export function GenericImport(p: GenericImportProps) {
  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={1} alignItems="center">
        <FileDownloadOutlinedIcon />
        <Typography style={{ textDecoration: 'underline', cursor: 'pointer' }}>
          Last ned en mal ({p.accept || 'Ubestemt format'})
        </Typography>
        <InfoOutlinedIcon style={{ cursor: 'pointer' }} onClick={p.help} />
      </Stack>
      <Box sx={{ width: '450px', height: '100px' }}>
        <FileImport
          onDrop={(f) => p.onChange(f as File)}
          accept={p.accept}
          disabled={p.disabled}
          actionLabel={p.actionLabel}
        />
      </Box>
      {p.selected ? (
        <>
          <Typography variant="h5">
            Valgt fil til å {p.actionLabel ? p.actionLabel : 'importere'}
          </Typography>
          <ul>
            <li>
              <Typography variant="h6" children={p.selected} />
            </li>
          </ul>
        </>
      ) : undefined}
    </Stack>
  );
}

interface FilterIconDialogProps<T> {
  title: string;
  subtitle?: string;
  value: any;
  defaultValue: any;
  setValue: (o: any) => void;
  options: T[];
  optionLabel?: (o: T) => string;
  optionValue?: (o: T) => any;
}

export function FilterIconDialog<T>(p: FilterIconDialogProps<T>) {
  const [anchorEl, setAnchorEl] = React.useState<any>();
  const [internalValue, setInternalValue] = React.useState<any>(p.value);

  React.useEffect(() => setInternalValue(p.value), [p.value]);

  return (
    <>
      <IconButton
        children={<FilterAltOutlinedIcon />}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      />
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        onClose={() => setAnchorEl(undefined)}
      >
        <Box padding={2}>
          <Typography children={p.title} />
          {p.subtitle ? <Typography variant="body2" children={p.subtitle} /> : undefined}
          <RadioGroup
            value={internalValue}
            onChange={(e) => {
              setInternalValue(e.target.value);
            }}
          >
            {p.options?.map((o) => (
              <FormControlLabel
                label={p.optionLabel ? p.optionLabel(o) : o}
                value={p.optionValue ? p.optionValue(o) : o}
                control={<Radio />}
              />
            ))}
          </RadioGroup>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <NButton
                children="Utfør"
                onClick={() => {
                  p.setValue(internalValue);
                  setAnchorEl(undefined);
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <NButton
                children="Nullstill"
                variant="outlined"
                onClick={() => {
                  p.setValue(p.defaultValue);
                  setAnchorEl(undefined);
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </Popover>
    </>
  );
}

interface FormInputProps extends OutlinedInputProps {
  header?: string;
  helperText?: string;
  owned?: boolean;
}

export function FormInput(props: FormInputProps) {
  const { header, value, type, helperText, owned, onChange, ...inputProps } = props;
  const [tempValue, setTempValue] = useState<any>('');
  const inputRef = useRef(null);

  useEffect(() => setTempValue(value !== undefined ? value : undefined), [value]);

  useEffect(() => {
    if (type !== 'number') return;
    const handleWheel = (event) => event.preventDefault();
    inputRef?.current?.addEventListener('wheel', handleWheel);
    return () => {
      inputRef?.current?.removeEventListener('wheel', handleWheel);
    };
  }, []);

  return (
    <FormControl>
      <Typography sx={{ whiteSpace: 'nowrap' }}>{header}</Typography>
      <OutlinedInput
        {...inputProps}
        ref={inputRef}
        value={tempValue}
        onChange={(event) => setTempValue(event.target.value)}
        onBlur={(event) => onChange(event as any)}
        onKeyDown={(event) => event.key === 'Enter' && onChange(event as any)}
        size="small"
        type={type}
        inputProps={{
          style: {
            textAlign: `${type === 'number' ? 'right' : 'left'}`
          },
          readOnly: !owned
        }}
      />
      <Typography variant="caption" color="error">
        {helperText}
      </Typography>
    </FormControl>
  );
}

export function FormInput2(props: FormInputProps) {
  const { header, value, type, helperText, owned, onChange, onBlur, ...inputProps } =
    props;
  const [tempValue, setTempValue] = useState<any>('');
  const inputRef = useRef(null);

  useEffect(() => setTempValue(value !== undefined ? value : undefined), [value]);

  useEffect(() => {
    if (type !== 'number') return;
    const handleWheel = (event) => event.preventDefault();
    inputRef?.current?.addEventListener('wheel', handleWheel);
    return () => {
      inputRef?.current?.removeEventListener('wheel', handleWheel);
    };
  }, []);

  return (
    <FormControl>
      <Typography sx={{ whiteSpace: 'nowrap' }}>{header}</Typography>
      <OutlinedInput
        {...inputProps}
        ref={inputRef}
        value={tempValue}
        onChange={(event) => {
          setTempValue(event.target.value);
          onChange(event as any);
        }}
        onBlur={onBlur}
        onKeyDown={(event) => event.key === 'Enter' && onBlur(event as any)}
        size="small"
        type={type}
        inputProps={{
          style: {
            textAlign: `${type === 'number' ? 'right' : 'left'}`
          }
        }}
      />
      <Typography variant="caption" color="error">
        {helperText}
      </Typography>
    </FormControl>
  );
}

export function TableInput(props: StandardTextFieldProps) {
  const { value, type, onChange, onBlur, ...p } = props;
  const [tempValue, setTempValue] = useState<any>('');
  useEffect(() => setTempValue(value !== undefined ? value : undefined), [value]);

  return (
    <TextField
      {...p}
      value={tempValue}
      onChange={(event) => {
        setTempValue(event.target.value);
        onChange(event as any);
      }}
      onBlur={onBlur}
      onKeyDown={(event) => event.key === 'Enter' && onBlur(event as any)}
      variant="standard"
      fullWidth
      type={type}
      inputProps={{
        style: {
          textAlign: `${type === 'number' ? 'right' : 'left'}`,
          padding: 0
        }
      }}
    />
  );
}

export function NoScrollTableInput(props: StandardTextFieldProps) {
  const { value, type, onChange, ...p } = props;
  const [tempValue, setTempValue] = useState<any>('');
  const inputRef = useRef(null);

  useEffect(() => setTempValue(value !== undefined ? value : undefined), [value]);

  useEffect(() => {
    if (type !== 'number') return;
    const handleWheel = (event) => event.preventDefault();
    inputRef?.current?.addEventListener('wheel', handleWheel);
    return () => {
      inputRef?.current?.removeEventListener('wheel', handleWheel);
    };
  }, []);

  return (
    <TextField
      {...p}
      ref={inputRef}
      id="custom-table-input"
      value={tempValue}
      onChange={(event) => setTempValue(event.target.value)}
      onBlur={(event) => onChange(event as any)}
      onKeyDown={(event) => event.key === 'Enter' && onChange(event as any)}
      variant="standard"
      fullWidth
      type={type}
      inputProps={{
        style: {
          textAlign: `${type === 'number' ? 'right' : 'left'}`,
          padding: 0
        }
      }}
    />
  );
}

export function NoScrollTableInput2(props: StandardTextFieldProps) {
  const { value, type, ...p } = props;
  const inputRef = useRef(null);

  useEffect(() => {
    if (type !== 'number') return;
    const handleWheel = (event) => event.preventDefault();
    inputRef?.current?.addEventListener('wheel', handleWheel);
    return () => {
      inputRef?.current?.removeEventListener('wheel', handleWheel);
    };
  }, []);

  return (
    <TextField
      {...p}
      ref={inputRef}
      id="custom-table-input"
      value={value}
      variant="standard"
      fullWidth
      type={type}
      inputProps={{
        style: {
          textAlign: `${type === 'number' ? 'right' : 'left'}`
        }
      }}
    />
  );
}
