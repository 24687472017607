import React, { createContext, useContext, useState } from 'react';
import { ProsjektI } from '../components/pages/kosthold/types/ProjectTypes';
import { returnInitialState } from './DatabaseContext';

export function useProjectStorage(storageKey: string) {
  const [projects, setStoredValue] = useState(returnInitialState(storageKey));

  const setProjects = (value) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(projects) : value;
      // Save to local storage
      window.localStorage.setItem(storageKey, JSON.stringify(valueToStore));
      // Save state
      setStoredValue(valueToStore);
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };
  return [projects, setProjects];
}
interface ProjectContextProps {
  projects: ProsjektI[];
  setProjects: (project: ProsjektI[]) => void;
}
const initialValue: ProjectContextProps = {
  projects: [],
  setProjects: () => {}
};
export const ProjectContext = createContext<ProjectContextProps>(initialValue);
export const useProjectContext = () => useContext(ProjectContext);
