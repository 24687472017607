import React, { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';

import { Box, Dialog, DialogContent, useTheme } from '@mui/material';

const useStyles = () => {
  const theme = useTheme();
  return {
    root: {
      display: 'flex',
      '& > * + *': {
        marginLeft: theme.spacing(2)
      }
    }
  };
};
interface ProgressBarProps {
  isShowing: boolean;
  text?: string;
  hide: () => void;
}
export const ProgressBar = (props: ProgressBarProps) => {
  const classes = useStyles();
  const [openProgress, setOpenProgress] = useState(false);
  const { isShowing, text, hide } = props;
  useEffect(() => {
    if (isShowing) {
      setOpenProgress(true);
    } else {
      handleCloseProgress();
    }
  }, [isShowing]);

  const handleCloseProgress = () => {
    setOpenProgress(false);
    hide();
  };
  return (
    <Dialog
      open={openProgress}
      aria-labelledby="customized-dialog-title"
      onClose={handleCloseProgress}
      maxWidth="xl"
    >
      <DialogContent>
        <Box sx={classes.root}>
          <CircularProgress />
          <p>{text || 'Loading ...'}</p>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
