import React, {
  Dispatch,
  ReactElement,
  ReactNode,
  SetStateAction,
  SyntheticEvent,
  useContext
} from 'react';
import { AppBar, Box, Tab, Tabs } from '@mui/material';
import { ChangesContext } from '../pages/kosthold/PersonLayout';

interface TabI {
  idx: number;
  tabType: string;
  label: string;
}

interface TabComponentProps {
  panel?: (tabType: string) => ReactNode | null;
  tabArray: TabI[];
  activeTab: number;
  setActiveTab: Dispatch<SetStateAction<number>>;
  setTempActiveTab?: Dispatch<SetStateAction<number>>;
  tabIcon?: (tabType: string) => ReactElement | null;
  appBar?: boolean;
  changes?: boolean;
  setShowChangesModal?: Dispatch<SetStateAction<boolean>>;
}

export default function TabComponent(props: TabComponentProps) {
  const {
    panel,
    tabArray,
    activeTab,
    setActiveTab,
    setTempActiveTab,
    tabIcon,
    appBar,
    setShowChangesModal,
    ...other
  } = props;
  const { changes } = useContext(ChangesContext);

  const handleTabChange = (event: SyntheticEvent, tabIndex: number) => {
    if (changes) {
      setShowChangesModal(true);
      setTempActiveTab(tabIndex);
    } else {
      setActiveTab(tabIndex);
    }
  };

  return (
    <>
      {appBar ? (
        <AppBar
          position="fixed"
          sx={{
            top: '140px',
            backgroundColor: 'white',
            zIndex: '100'
          }}
        >
          <Tabs
            variant="scrollable"
            scrollButtons="auto"
            value={activeTab}
            onChange={handleTabChange}
          >
            {tabArray.map((tab) => (
              <Tab
                icon={tabIcon ? tabIcon(tab.tabType) : null}
                iconPosition="start"
                label={tab.label}
                key={tab.idx}
              />
            ))}
          </Tabs>
        </AppBar>
      ) : (
        <Tabs
          variant="scrollable"
          scrollButtons="auto"
          value={activeTab}
          onChange={handleTabChange}
        >
          {tabArray.map((tab) => (
            <Tab
              icon={tabIcon ? tabIcon(tab.tabType) : null}
              iconPosition="start"
              label={tab.label}
              key={tab.idx}
            />
          ))}
        </Tabs>
      )}
      {tabArray.map((tab, index) => (
        <div
          key={tab.idx}
          role="tabpanel"
          hidden={activeTab !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}
        >
          {activeTab === index && (
            <Box sx={{ pt: 3 }}>{panel ? panel(tab.tabType) : null}</Box>
          )}
        </div>
      ))}
    </>
  );
}
