import React from 'react';
import { MatvareData, Session } from './types/MatvareTypes';
import useFetchData from '../../utilities/useFetchData';
import { Link, Stack } from '@mui/material';
import * as XLSX from 'xlsx';
import { NButton } from '../components/Inputs';
import DownloadIcon from '@mui/icons-material/Download';
import useDidMountEffect from '../../utilities/useDidMountEffect';
import { AlertI } from '../../../types/alertTypes';
import { useUserContext } from '../../../context/AuthContext';
interface InngaariProps {
  matvareData: MatvareData;
  currentDatabase?: any;
  classes: any;
  showAlertComponent: (message: string, severity: AlertI['alertColor']) => void;
}
export default function Inngaari(props: InngaariProps) {
  const { matvareData, currentDatabase, showAlertComponent } = props;
  const [sessionNumber, setSessionNumber] = React.useState(0);
  const [fetchData, setFetchData] = React.useState(false);
  const getSessionNumber = useFetchData({
    loadOnMount: false,
    clearDataOnLoad: false,
    projectURL: 'getSession',
    subProjectURL: null
  });
  const beginOppskriftberegn = useFetchData({
    loadOnMount: false,
    clearDataOnLoad: false,
    subProjectURL: `${currentDatabase?.value}/${sessionNumber}/${matvareData.MATVAREKODEID}`,
    projectURL: 'beginOppskriftberegn'
  });

  const ingaari = useFetchData({
    loadOnMount: false,
    clearDataOnLoad: true,
    subProjectURL: `${currentDatabase?.value}/${sessionNumber}`,
    projectURL: 'checkInngarI'
  });

  const getTime = () => {
    const now = new Date();
    const day = now.getDate(); //19
    const month = now.getMonth() + 1; //1
    const year = now.getFullYear(); //2021
    const hours = now.getHours(); //10
    const minutes = now.getMinutes(); //
    const timeFormat = `_${hours}${minutes}-${day}-${month}-${year}`;
    return timeFormat;
  };
  const getSession = () => {
    if (+matvareData.MATVAREKODEID > 0) {
      getSessionNumber.loadData('getSession', null);
    }
  };
  useDidMountEffect(() => {
    if (getSessionNumber.data) {
      const data: Session = getSessionNumber.data as unknown as Session;
      setSessionNumber(data?.SESSIONID);
      getInngaarI(data?.SESSIONID);
    }
  }, [getSessionNumber.data]);

  const getInngaarI = async (sessionNumber: number) => {
    await beginOppskriftberegn.loadData(
      'beginOppskriftberegn',
      `${currentDatabase?.value}/${sessionNumber}/${matvareData.MATVAREKODEID}`
    );
    await ingaari.loadData('checkInngarI', `${currentDatabase?.value}/${sessionNumber}`);
    setFetchData(true);
  };
  const createExcelData = async (currentUser: string) => {
    // Create Headers and metadata for the files
    const currentTime = getTime();
    const xcelFileName = currentUser + currentTime;
    const stoffVerdierArray = await convertObjToArray(ingaari.data);
    const xcelFileInfo = [
      [
        `Oppskrifter hvor ${matvareData.MATVAREKODEID} - ${matvareData.NAVN} inngår som ingrediens`
      ],
      [`Matvaredatabase ${props.currentDatabase['label']}`]
    ];

    await exportToExcel(xcelFileName, xcelFileInfo, stoffVerdierArray);
  };

  const convertObjToArray = async (object: any) => {
    let newArray = await object?.map((row: any) => {
      return [row.MATVAREKODEID, row.NAVN, row.NIVAA];
    });
    newArray.unshift(['Matvarekode', 'Navn', 'Nivå']);
    return newArray;
  };

  const exportToExcel = async (user: string, info: any, data: any) => {
    const worksheet = XLSX.utils.aoa_to_sheet(info);
    XLSX.utils.sheet_add_aoa(worksheet, data, { origin: -1 });
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'SheetJS');
    //generate XLSX file and send to client
    XLSX.writeFile(workbook, user + '.xlsx');
  };
  const loggedInuser = useUserContext();

  useDidMountEffect(() => {
    if (fetchData) {
      if (ingaari.data?.length > 0) {
        createExcelData(loggedInuser?.user?.username);
      } else {
        // alert('Matvaren er ikke brukt som ingrediens.')
        showAlertComponent('Matvaren er ikke brukt som ingrediens.', 'info');
      }
      setFetchData(false);
    }
  }, [ingaari.data]);

  React.useEffect(() => {
    // If matvareKode changes we need to clear the old data
    // and then refetch data
    ingaari.clearData();
  }, [matvareData.MATVAREKODEID]);
  return (
    <>
      <Stack spacing={1} position="sticky" top="10rem">
        <Link onClick={getSession} sx={{ width: '100%', color: 'inherit' }}>
          <NButton
            children="Inngår i"
            rightIcon={<DownloadIcon />}
            variant="text"
            color="inherit"
            fullWidth
          />
        </Link>
      </Stack>
    </>
  );
}
