import React, { useEffect } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { Button, CircularProgress } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Stack, Typography } from '@mui/material';
import { StyledMenu, StyledMenuItem } from '../../css/styledMenu';
import { useUserContext } from '../../context/AuthContext';
import { UserRole } from '../pages/admin/AdminTypes';

export const Logout = () => {
  const { keycloak } = useKeycloak();
  const kcLogoutOptions = {
    redirectUri: process.env.REACT_APP_FRONTEND + '/login'
  };
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const loggedInuser = useUserContext();
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      {keycloak ? (
        <>
          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
            style={{ color: '#FFF' }}
          >
            <Stack>
              <Stack direction="row-reverse">
                <Typography>
                  {keycloak?.hasRealmRole(UserRole.Akf)
                    ? 'AKF'
                    : loggedInuser?.user?.username}
                </Typography>
                <PersonOutlineIcon style={{ color: 'white' }} />
              </Stack>
              {loggedInuser.user.numberRange?.length > 0 &&
              loggedInuser.user.numberRange[0] !== undefined ? (
                <Typography fontWeight="lighter" whiteSpace="nowrap">
                  Nummerområde:{' '}
                  {`${loggedInuser?.user?.numberRange[0]} - ${loggedInuser?.user?.numberRange[1]}`}
                </Typography>
              ) : (
                <CircularProgress />
              )}
            </Stack>
            <ArrowDropDownIcon style={{ color: 'white' }} />
          </Button>
          <StyledMenu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <div onClick={() => keycloak?.logout(kcLogoutOptions)}>
              <StyledMenuItem onClick={handleClose}>
                <span
                  style={{
                    color: '#3f51b5',
                    backgroundColor: 'white',
                    width: 130,
                    fontSize: '16px',
                    fontWeight: 400,
                    textAlign: 'center'
                  }}
                >
                  Logg ut
                </span>
              </StyledMenuItem>
            </div>
          </StyledMenu>
        </>
      ) : (
        <p>You are not logged in.</p>
      )}
    </>
  );
};
