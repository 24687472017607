export const rapportOption = [
  {
    value: 0,
    label: ''
  },
  {
    value: 1,
    label: 'Matinntak for hver person'
  },
  {
    value: 2,
    label: 'Matinntak for gruppen'
  },
  {
    value: 3,
    label: 'Stoffinntak for hver person'
  },
  {
    value: 4,
    label: 'Stoffinntak for gruppen'
  },
  {
    value: 5,
    label: 'Matvarers bidrag av stoffer for hver person'
  },
  {
    value: 6,
    label: 'Matvarers bidrag av stoffer for gruppen'
  },
  {
    value: 7,
    label: 'Energiprosentfordeling for hver person'
  },
  {
    value: 8,
    label: 'Energiprosentfordeling for gruppen'
  },
  {
    value: 9,
    label: 'Personliste med energiinntak'
  },
  {
    value: 10,
    label: 'Matvarebidrag for ett stoff for hver person'
  },
  {
    value: 11,
    label: 'Matvarer/stoffer pr. måltid'
  },
  {
    value: 12,
    label: 'Stoffinnhold for matvarer/ingredienser'
  }
];
