import React, { ChangeEvent, useEffect, useState } from 'react';
import './StyledTextarea.css';

interface StyledTextareaProps {
  name?: string;
  header?: string;
  rows?: number;
  cols?: number;
  value?: string;
  owned: boolean;
  onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  disabled?: boolean;
  minRows?: number;
  style?: Object;
}

const StyledTextarea = (props: StyledTextareaProps) => {
  const { name, header, rows, cols, value, owned, onChange, disabled, minRows, style } =
    props;
  const [tempValue, setTempValue] = useState<string>(value || '');

  useEffect(() => {
    setTempValue(!value ? '' : value);
  }, [value]);

  return (
    <div className="styled-textarea-container">
      {header && <div className="header">{header}</div>}
      <textarea
        className={`${disabled ? 'disabled' : ''}`}
        id="styled-textarea"
        name={name}
        rows={rows}
        cols={cols}
        value={tempValue}
        onChange={(event) => setTempValue(event.target.value)}
        onBlur={(event) => onChange(event as any)}
        onKeyDown={(event) => event.key === 'Enter' && onChange(event as any)}
        readOnly={!owned}
        style={{
          minHeight: `${minRows * 1.5}em`,
          ...style
        }}
        disabled={disabled}
      />
    </div>
  );
};

export default StyledTextarea;
