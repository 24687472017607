import { ProsjektI } from '../types/prosjektTypes';
import {
  SET_PROSJEKT_ID,
  SET_PROSJEKT_NAVN,
  SET_KOSTHOLD_PROSJEKT_ID,
  SET_UNDERSOKELSE_ID,
  SET_KORT_NAVN,
  ProsjektActionTypes
} from '../types/actions';

const prosjektReducerDefaultState: ProsjektI = {
  prosjektId: 0,
  prosjektNavn: '',
  kostholdProsjektId: 0,
  undersokelseId: 0,
  kortNavn: null,
  operationStatus: null
};

export const prosjektReducer = (
  state = prosjektReducerDefaultState,
  action: ProsjektActionTypes
): ProsjektI => {
  switch (action.type) {
    case SET_PROSJEKT_ID:
      return { ...state, prosjektId: action.prosjektId };
    case SET_PROSJEKT_NAVN:
      return { ...state, prosjektNavn: action.prosjektNavn };
    case SET_KOSTHOLD_PROSJEKT_ID:
      return { ...state, kostholdProsjektId: action.kostholdProsjektId };
    case SET_UNDERSOKELSE_ID:
      return { ...state, undersokelseId: action.undersokelseId };
    case SET_KORT_NAVN:
      return { ...state, kortNavn: action.kortNavn };
    default:
      return state;
  }
};
