import React from 'react';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import { DialogBox } from '../../../../utilities/DialogBox';
import useFetchData from '../../../../utilities/useFetchData';
import EmptyTable from '../EmptyTable';
import Maaltidskomponenter from './Maaltidskomponenter';
import { ExcludertMatvarer, Maaltidskomponent } from '../../types/BeregningTypes';
import DialogComponent from '../../../../utilities/DialogComponent';
import useDidMountEffect from '../../../../utilities/useDidMountEffect';
import ExcelRapport from './ExcelRapport';
import { useKeycloak } from '@react-keycloak/web';
interface ExcludedMatvareProps {
  projectId: number;
  surveyId: number;
  databaseId: number;
  groupTypeId: number;
  excludedMatvares: ExcludertMatvarer[];
  showExcluded: boolean;
  // setShowBeregningReport: (value: boolean) => void;
  setShowProgress: (value: boolean) => void;
  setProgressText: (value: string) => void;
  setShowExcluded: (value: boolean) => void;
  showAlertMessage: (message: string, severity: any) => void;
}
const ExcludedMatvare = (props: ExcludedMatvareProps) => {
  const {
    projectId,
    surveyId,
    databaseId,
    groupTypeId,
    //excludedMatvares,
    showExcluded,
    //setShowBeregningReport,
    setShowProgress,
    setProgressText,
    setShowExcluded,
    showAlertMessage
  } = props;
  const { keycloak } = useKeycloak();
  const token = keycloak?.token || 'Mangler tilgang';
  const title = 'Matvarer ekskludert fra beregning';
  const [excludedMatvares, setExcludedMatvares] = React.useState<ExcludertMatvarer[]>([]);
  const [selectedRow, setSelectedRow] = React.useState(null);
  const [showDialogbox, setShowDialogbox] = React.useState(false);
  const [showMaaltidskomponenter, setShowMaaltidskomponenter] = React.useState(false);
  const [maaltidkomponent, setMaaltidkomponent] = React.useState<Maaltidskomponent[]>([]);
  const apiCall = (uri: string, method: string, body?: any) => {
    const fullUri = `${process.env.REACT_APP_BACKEND}/${uri}`;

    return fetch(encodeURI(fullUri), {
      method: method,
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    });
  };
  const getExcludedMatvares = async () => {
    setShowProgress(true);
    const response = await apiCall(
      `getExcludedMatvares/${projectId}/${surveyId}/${databaseId}/${groupTypeId}`,
      'GET'
    );
    if (response.ok) {
      const data: ExcludertMatvarer[] =
        (await response.json()) as unknown as ExcludertMatvarer[];
      setExcludedMatvares(data);
      if (showExcluded) {
        if (data?.length > 0) {
          setShowDialogbox(true);
        } else {
          showAlertMessage('Ingen ekskludert matvarer.', 'info');
        }
      }
      setShowProgress(false);
      setProgressText('');
    } else {
      console.log('Error in retrieving excluded matvare count.');
    }
    setShowProgress(false);
  };
  React.useEffect(() => {
    if (excludedMatvares) {
      setProgressText('Henter ekskludert matvarer...');
      setShowProgress(true);
      getExcludedMatvares();
    }
  }, [showExcluded]);

  useDidMountEffect(() => {
    if (!showDialogbox) {
      //setShowBeregningReport(false);
      setShowExcluded(false);
    }
  }, [showDialogbox]);
  return (
    <>
      {showDialogbox && (
        <DialogBox
          {...props}
          title={title}
          isShowing={showDialogbox}
          hide={() => {
            setShowDialogbox(!showDialogbox);
          }}
        >
          <Grid container>
            <Grid item xs={6}>
              <Typography>Prosjekt: {projectId}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>Undersøkelse: {surveyId}</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Typography>Matvarer</Typography>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Kode</TableCell>
                    <TableCell>Navn</TableCell>
                    <TableCell>Antall</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {excludedMatvares?.map((row: ExcludertMatvarer) => {
                    const highlighted =
                      selectedRow && selectedRow.MATVAREKODEID === row.MATVAREKODEID;
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        onClick={() => {
                          setSelectedRow(highlighted ? null : row);
                          setShowMaaltidskomponenter(true);
                          setMaaltidkomponent(row.MAALTIDSKOMPONENTER);
                        }}
                        style={{
                          backgroundColor: highlighted ? '#d2ecfd' : 'white'
                        }}
                      >
                        <TableCell>{row.MATVAREKODEID}</TableCell>
                        <TableCell>{row.NAVN}</TableCell>
                        <TableCell>{row.MAALTIDSKOMPONENTER?.length}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Grid>
            <Grid item xs={6}>
              {showMaaltidskomponenter ? (
                <Maaltidskomponenter maaltidkomponent={maaltidkomponent} />
              ) : (
                <EmptyTable />
              )}
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={6}></Grid>
            <Grid container item xs={6} justifyContent={'flex-end'}>
              <ExcelRapport
                data={excludedMatvares}
                projectId={projectId}
                surveyId={surveyId}
              />
            </Grid>
          </Grid>
        </DialogBox>
      )}
    </>
  );
};
export default ExcludedMatvare;
