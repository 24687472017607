import React from 'react';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
interface CustomTabProps {
  tabValue: number;
  label1: string;
  label2: string;
  handleChange: any;
  disabled: boolean;
}

export function CustomTab(props: CustomTabProps) {
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    };
  }
  const StyledTab = styled(Tab)({
    textTransform: 'none',
    fontWeight: 'bolder',
    fontSize: 'large'
  });
  return (
    <Tabs
      value={props.tabValue}
      onChange={props.handleChange}
      variant="scrollable"
      scrollButtons="auto"
      aria-label="tabs"
    >
      <StyledTab label={props.label1} {...a11yProps(0)} />
      <StyledTab label={props.label2} {...a11yProps(1)} disabled={props.disabled} />
    </Tabs>
  );
}
