import React, { useContext, useEffect, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup
} from '@mui/material';
import useStyles from '../../../../css/materialtheme';
import { ResultatGruppe } from './ResultatGruppe';
import { MatvaregruppeTableContainer } from './MatvaregruppeTableContainer';
import { DatabaseContext } from '../../../../context/DatabaseContext';
import {
  Matvare,
  MatvaregruppeI,
  TableType,
  resultatGruppeI
} from '../types/matvaregruppeTypes';
import { useKeycloak } from '@react-keycloak/web';
import { UserRole } from '../../admin/AdminTypes';
interface NavigateLevelI {
  nodeId: number;
  name: string;
  n: number;
}
interface UtvalgteMatvareGruppeProps {
  matvareRadioButton: number;
  setMatvareRadioButton: (value: number) => void;
  selectedMatvares?: Matvare[];
  setSelectedMatvares?: (mat: Matvare[]) => void;
  selectedMatvaregruppes?: MatvaregruppeI[];
  setSelectedMatvaregruppes?: (mg: MatvaregruppeI[]) => void;
  selectedResultatGroup: resultatGruppeI;
  setSelectedResultatGroup: (value: resultatGruppeI) => void;
  setShowProgress: (value: boolean) => void;
  setProgressText: (value: string) => void;
  handleAvsluttBtn: () => void;
  deleteAllMatvareInMG: (data: {}) => void;
  deleteAllMatvares: () => void;
  totalCounts: number;
}
export const UtvalgteMatvareGruppe = (props: UtvalgteMatvareGruppeProps) => {
  const {
    matvareRadioButton,
    setMatvareRadioButton,
    selectedMatvares,
    setSelectedMatvares,
    selectedMatvaregruppes,
    setSelectedMatvaregruppes,
    selectedResultatGroup,
    setSelectedResultatGroup,
    setShowProgress,
    setProgressText,
    handleAvsluttBtn,
    deleteAllMatvareInMG,
    deleteAllMatvares,
    totalCounts
  } = props;
  const classes = useStyles();
  const { keycloak } = useKeycloak();
  const { currentDatabase } = useContext(DatabaseContext);
  const [parentsList, setParentsList] = useState<NavigateLevelI[]>([
    { nodeId: 0, name: 'Hovedgrupper', n: 1 }
  ]);
  const [matvarerList, setMatvarerList] = useState<Matvare[]>([]);
  const [showMatvare, setShowMatvare] = useState(false);
  const [parent, setParent] = useState('');
  const [matvaregruppeList, setMatvaregruppeList] = useState<MatvaregruppeI[]>([]);
  const [reloadMatvaregruppe] = useState(false);
  const [nivaa, setNivaa] = useState(1);
  const [linkup, setLinkup] = useState(0);
  const [selectedRow, setSelectedRow] = useState(null);
  const [showMatvareHovedGruppe, setShowMatvareHovedGruppe] = useState(false);
  const [showRadioBtns, setShowRadioBtns] = useState(false);
  const [disableVelg, setDisableVelg] = useState(false);
  const [disableFjernAlle, setDisableFjernAlle] = useState(false);
  const [disableFjern, setDisableFjern] = useState(false);
  const [fjernRadioButton, setMatvareFjernRadioButton] = useState(0);
  useEffect(() => {
    if (selectedResultatGroup?.GRUPPETYPEID > 0) {
      setShowRadioBtns(true);
      setShowMatvareHovedGruppe(true);
      setMatvareRadioButton(0);
      handleFjernRadioBtnVisibility();
    }
  }, [selectedResultatGroup]);
  useEffect(() => {
    handleFjernRadioBtnVisibility();
  }, [selectedMatvaregruppes?.length, totalCounts]);

  useEffect(() => {
    if (matvareRadioButton === 0) {
      if (selectedResultatGroup?.GRUPPETYPEID > 0) {
        setShowMatvareHovedGruppe(true);
      }
      setMatvareFjernRadioButton(0);
    } else if (matvareRadioButton === 1) {
      setShowMatvareHovedGruppe(false);
      setDisableFjern(true);
    } else if (matvareRadioButton === 2) {
      setDisableFjern(false);
    }
  }, [matvareRadioButton]);

  useEffect(() => {
    if (fjernRadioButton === 1) {
      deleteAllMatvares();
    } else if (fjernRadioButton === 2) {
      const nodeNrIds = selectedMatvaregruppes?.map(
        (n: { NODENRID: number }) => n.NODENRID
      );
      deleteAllMatvareInMG({ data: nodeNrIds });
    }
    if (fjernRadioButton !== 0) {
      setMatvareRadioButton(0);
      setSelectedMatvaregruppes([]);
      setSelectedMatvares([]);
    }
  }, [fjernRadioButton]);
  const handleChangeRadioButtonSelection = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setMatvareFjernRadioButton(0);
    setMatvareRadioButton(parseInt((event.target as HTMLInputElement).value));
    handleAvsluttBtn();
  };
  const handleChangeFjernRadioButtonSelection = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setMatvareFjernRadioButton(parseInt((event.target as HTMLInputElement).value));
    //TODO: show confirmation dialog
  };
  const handleFjernRadioBtnVisibility = () => {
    if (totalCounts === undefined || totalCounts === 0) {
      setDisableFjernAlle(true);
      setDisableFjern(true);
      if (selectedMatvaregruppes?.length === 0) {
        setDisableVelg(true);
      } else {
        setDisableVelg(false);
      }
    } else {
      setDisableFjernAlle(false);
      if (selectedMatvaregruppes?.length === 0) {
        setDisableVelg(true);
        setDisableFjern(true);
      } else {
        setDisableVelg(false);
      }
    }
  };

  return (
    <Accordion defaultExpanded style={{ width: '100%' }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={classes.arrow} />}
        aria-controls="panel1c-content"
        id="panel2bh-header"
        sx={{ root: classes.ExpansionPanel, expanded: classes.expandedExpansionPanel }}
        style={{ height: 75 }}
      >
        <h3>Matvaregrupper/Matvarer</h3>
      </AccordionSummary>
      <AccordionDetails sx={classes.ExpansionDetail}>
        <Grid container justifyContent="space-between">
          <Grid item xs={4}>
            <ResultatGruppe
              selectedResultatGroup={selectedResultatGroup}
              setSelectedResultatGroup={setSelectedResultatGroup}
            />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={3}>
            {showRadioBtns && (
              <FormControl variant="outlined" component="fieldset">
                <RadioGroup
                  row={false}
                  name="Velg"
                  value={matvareRadioButton}
                  onChange={handleChangeRadioButtonSelection}
                >
                  <FormControlLabel
                    value={1}
                    control={<Radio size="small" color="primary" />}
                    label="Velg alle matvarer"
                    sx={{ label: classes.RadioButton }}
                    labelPlacement="end"
                    disabled={!keycloak?.hasRealmRole(UserRole?.Admin)}
                  />
                  <FormControlLabel
                    value={2}
                    control={<Radio size="small" color="primary" />}
                    label="Velg alle matvarer i valgte grupper"
                    sx={{ label: classes.RadioButton }}
                    labelPlacement="end"
                    disabled={disableVelg}
                  />
                </RadioGroup>
              </FormControl>
            )}
          </Grid>
          <Grid item xs={3}>
            {showRadioBtns && (
              <FormControl variant="outlined" component="fieldset">
                <RadioGroup
                  row={false}
                  name="Fjern"
                  value={fjernRadioButton}
                  onChange={handleChangeFjernRadioButtonSelection}
                >
                  <FormControlLabel
                    value={1}
                    control={<Radio size="small" color="primary" />}
                    label="Fjern alle matvarer"
                    sx={{ label: classes.RadioButton }}
                    labelPlacement="end"
                    disabled={disableFjernAlle}
                  />
                  <FormControlLabel
                    value={2}
                    control={<Radio size="small" color="primary" />}
                    label="Fjern alle matvarer i valgte grupper"
                    sx={{ label: classes.RadioButton }}
                    labelPlacement="end"
                    disabled={disableFjern}
                  />
                </RadioGroup>
              </FormControl>
            )}
          </Grid>
          <Divider />
          <Grid item xs={12}>
            {showMatvareHovedGruppe && (
              <MatvaregruppeTableContainer
                databaseId={currentDatabase?.value}
                gruppetypeId={selectedResultatGroup?.GRUPPETYPEID}
                owned={false}
                selectedRow={selectedRow}
                setSelectedRow={setSelectedRow}
                nivaa={nivaa}
                linkup={linkup}
                setNivaa={setNivaa}
                setLinkup={setLinkup}
                parent={parent}
                setParent={setParent}
                parentsList={parentsList}
                setParentsList={setParentsList}
                matvaregruppeList={matvaregruppeList}
                setMatvaregruppeList={setMatvaregruppeList}
                matvarerList={matvarerList}
                setMatvarerList={setMatvarerList}
                move={false}
                main={false}
                tableType={TableType.Utvalg}
                setShowProgress={setShowProgress}
                setProgressText={setProgressText}
                reloadMatvaregruppe={reloadMatvaregruppe}
                showMatvare={showMatvare}
                setShowMatvare={setShowMatvare}
                selectedMatvares={selectedMatvares}
                setSelectedMatvares={setSelectedMatvares}
                selectedMatvaregruppes={selectedMatvaregruppes}
                setSelectedMatvaregruppes={setSelectedMatvaregruppes}
              ></MatvaregruppeTableContainer>
            )}
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
