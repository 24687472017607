//Created by chat gpt
export function removeArrayFromAnotherArray(
  mainArray: any[],
  objectsToRemove: any[],
  property: keyof any
): any[] {
  return mainArray?.filter(
    (item) => !objectsToRemove?.some((obj) => obj[property] === item[property])
  );
}
export function someButNotAllElementsInArray(
  array1: any[],
  array2: any[],
  property: keyof any
): boolean {
  return (
    array1?.some((obj1) => !array2?.every((obj2) => obj2[property] !== obj1[property])) &&
    array1?.every((obj1) => array2?.some((obj2) => obj2[property] === obj1[property]))
  );
}
export function areAllElementsInArray(
  array1: any[],
  array2: any[],
  property: keyof any
): boolean {
  return array1?.every((obj1) =>
    array2?.some((obj2) => obj2[property] === obj1[property])
  );
}
export function addObjectArrayIfNotExists(
  fromArray: any[],
  toArray: any[],
  property: keyof any
): any[] {
  const existingIds = new Set(toArray?.map((obj) => obj[property]));
  const newArray2 = [...toArray];

  for (const obj1 of fromArray) {
    if (!existingIds?.has(obj1[property])) {
      newArray2?.push(obj1);
      existingIds?.add(obj1[property]);
    }
  }

  return newArray2;
}
export function isObjectInArray(object: any, array: any[]): boolean {
  return array?.some((item) => JSON.stringify(item) === JSON.stringify(object));
}
// A generic function to compare objects
export function objectsAreEqual<T>(objA: T, objB: T): boolean {
  return JSON.stringify(objA) === JSON.stringify(objB);
}
export function hasDataChanged<T extends Record<string, any>>(
  formData: T,
  originalFormData: T
): boolean {
  for (const key in formData) {
    if (typeof formData[key] === 'string') {
      // Ensure formData[key] is of type 'string'
      const stringValue = formData[key] as string;
      // Check if the value is not whitespace or an empty string
      if (stringValue.trim() !== '' && stringValue !== '') {
        if (stringValue !== originalFormData[key]) {
          return true; // If a difference is found, return true
        }
      }
    } else {
      // For non-string fields (numbers, booleans, etc.), compare directly
      if (formData[key] !== originalFormData[key]) {
        return true; // If a difference is found, return true
      }
    }
  }
  return false; // If no differences are found, return false
}
