import React from 'react';
import * as XLSX from 'xlsx';
interface ExportToExcelProps {
  user: string;
  reportInfo: Array<Array<string>>;
  reportData: any[];
  setError: (value: boolean) => void;
  setSuccess?: (value: boolean) => void;
  setErrorMessage?: (value: string) => void;
}
export const ExportToExcel = (props: ExportToExcelProps) => {
  const { user, reportInfo, reportData, setError, setSuccess, setErrorMessage } = props;
  React.useEffect(() => {
    console.log('user && info && data', user, reportInfo, reportData);
    if (user && reportInfo && reportData) {
      try {
        exportToExcel(user, reportInfo, reportData);
        setSuccess(true);
        console.log('Success in the result data !!!!!!!!!!!');
      } catch {
        console.log('Error in the result data !!!!!!!!!!!');
        setErrorMessage('Feil: Fikk ikke skrevet til rapportfil');
        setError(true);
      }
    }
  }, []);
  const exportToExcel = (user: string, info: any, data: any) => {
    const worksheet = XLSX.utils.aoa_to_sheet(info);
    XLSX.utils.sheet_add_aoa(worksheet, data, { origin: -1 });
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'SheetJS');
    /* generate XLSX file and send to client */
    XLSX.writeFile(workbook, user + '.xlsx');
  };

  return <div></div>;
};
