import React, { useState } from 'react';
import {
  Grid,
  Box,
  AccordionSummary,
  AccordionDetails,
  Accordion,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Stack,
  Typography
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useStyles from '../../../css/materialtheme';
import { AlertPopUp } from '../../utilities/AlertPopUp';
import ResultgruppeMatvare from './ResultgruppeMatvare';
import PageContainer from '../home/PageContainer';
import moment from 'moment';
import ReactToPrint from 'react-to-print';
import useStylesTable from '../../../css/TableCss';
import { DatabaseContext } from '../../../context/DatabaseContext';
import useFetchData from '../../utilities/useFetchData';
import { Matvaregruppe } from './matvaregruppe/Matvaregruppe';
import { resultatGruppeI } from './types/matvaregruppeTypes';
import { useUserContext } from '../../../context/AuthContext';
import { NButton } from '../components/Inputs';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import { IsOwnedById } from '../../utilities/Validate';
const ComponentToPrint = React.forwardRef((props: any, ref: any) => (
  <div ref={ref}>
    <h3>Utskrift av skjermbildet: Resultatgrupper ({props.currentUser})</h3>
    <Grid container spacing={8}>
      <Grid item xs={6}>
        <label>
          Utskriftsdato:
          {moment().format('YYYY-MM-DD hh:mm:ss')}
        </label>
      </Grid>
    </Grid>
    <Grid container spacing={8}>
      <Grid item xs={6}>
        <label>Alle resultatgruppene i KBS Matvaredatabase {props.database}</label>
      </Grid>
      <Grid item xs={6}>
        <label></label>
      </Grid>
    </Grid>
    <Table sx={props.classesTable.body}>
      <TableHead sx={props.classesTable.head}>
        <TableRow>
          <TableCell sx={props.classesTable.head}>NAVN</TableCell>
          <TableCell sx={props.classesTable.head}>BESKRIVELSE</TableCell>
        </TableRow>
      </TableHead>
      <TableBody sx={props.classesTable.body}>
        {props.resultGruppe?.map((row: resultatGruppeI) => {
          return (
            <TableRow hover role="checkbox" key={row.BESKRIVELSE}>
              <TableCell>{row.BESKRIVELSE}</TableCell>
              <TableCell>{row.LANG_BESKRIVELSE}</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  </div>
));
export const MatvaregruppeContainer = () => {
  const classes = useStyles();
  const [openHovedgruppen, setopenHovedgruppen] = useState(false);
  const [showHovedGruppe, setShowHovedGruppe] = useState(true);
  const [expandedsub, setExpandedsub] = useState<string | false>(false);
  const [showAlert, setShowAlert] = useState(false);
  const [title, setTitle] = useState('Matvaregruppe - Nutrifoodcalc');
  const [comfirmKopi, setConfirmKopi] = useState(false);
  const [isNotSelected, setIsNotSelected] = useState(true);
  const [selectedResultagruppe, setSelectedResultagruppe] = useState({
    NAVN: '',
    GRUPPETYPEID: 0,
    TILGANGSKODE: 0
  });
  const componentRef = React.useRef();
  const classesTable = useStylesTable();
  const loggedInuser = useUserContext();
  const currentUser = loggedInuser?.user?.username;
  const { currentDatabase } = React.useContext(DatabaseContext);
  const [owned, setOwned] = useState(false);
  const getResultGruppe = useFetchData({
    loadOnMount: true,
    clearDataOnLoad: false,
    projectURL: 'getResultatgruppe',
    subProjectURL: `${currentDatabase?.value}`
  });

  document.title = title;
  React.useEffect(() => {
    setTitle('Matvaregruppe - Nutrifoodcalc');
  }, []);

  React.useEffect(() => {
    if (
      IsOwnedById({ tilgangskode: selectedResultagruppe?.TILGANGSKODE, u: loggedInuser })
    ) {
      setOwned(true);
    } else {
      setOwned(false);
    }
  }, [selectedResultagruppe]);

  const handleClickHovedgruppe = () => {
    if (openHovedgruppen) {
      setExpandedsub(false);
    }
    setopenHovedgruppen((prevOpen) => !prevOpen);
    setShowHovedGruppe(true);
  };

  const handleChangeExpandedsub =
    (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      if (panel === 'panel2') {
        handleClickHovedgruppe();
      }
      setExpandedsub(isExpanded ? panel : false);
    };

  const openGrupper = () => {
    setExpandedsub(true ? 'panel2' : false);
  };

  return (
    <PageContainer>
      {showAlert && (
        <AlertPopUp
          isShowing={showAlert}
          hide={() => {
            setShowAlert(!showAlert);
          }}
        />
      )}

      <Grid container>
        <Grid item xs={6}>
          <Typography variant="h4">Resultatgruppe</Typography>
        </Grid>
        <Grid item xs={6}>
          <Stack direction="row-reverse" spacing={2}>
            <ReactToPrint
              trigger={() => (
                <NButton
                  children="Skriv ut"
                  rightIcon={<PrintOutlinedIcon />}
                  variant="text"
                  disabled={isNotSelected}
                />
              )}
              pageStyle=".styledTable{width:100%;border-collapse:collapse;text-align:left}.styledTable th,.styledTable td{border:2px solid black;padding:0.5em;}"
              content={() => componentRef.current}
            />
            <Box sx={{ display: 'none' }}>
              <ComponentToPrint
                resultGruppe={getResultGruppe.data}
                database={currentDatabase?.value}
                currentUser={currentUser}
                classesTable={classesTable}
                ref={componentRef}
              />
            </Box>
            <NButton
              children="Kopier"
              rightIcon={<ContentCopyOutlinedIcon />}
              variant="text"
              onClick={() => setConfirmKopi(true)}
              disabled={isNotSelected}
            />
          </Stack>
        </Grid>

        <Grid item xs={12}>
          <ResultgruppeMatvare
            beregning="false"
            openGrupper={openGrupper}
            setSelectedResultagruppe={setSelectedResultagruppe}
            comfirmKopi={comfirmKopi}
            setConfirmKopi={setConfirmKopi}
            setIsNotSelected={setIsNotSelected}
            owned={owned}
          />
          <Box id="Hovedgrupper" m={1} p={1}>
            <Accordion
              expanded={expandedsub === 'panel2'}
              onChange={handleChangeExpandedsub('panel2')}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={classes.arrow} />}
                aria-controls="panel1c-content"
                id="panel2bh-header"
                sx={{
                  root: classes.ExpansionPanel,
                  expanded: classes.expandedExpansionPanel
                }}
              >
                <Grid container xs={12} alignContent={'center'}>
                  <Grid item xs={6}>
                    <Typography variant="h4"> Matvaregruppe</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="h6">
                      Resultatgruppe: <b>{selectedResultagruppe?.NAVN || ''}</b>
                    </Typography>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails sx={classes.ExpansionDetail}>
                <div style={{ width: '100%' }}>
                  {showHovedGruppe && (
                    <Matvaregruppe
                      gruppetypeId={selectedResultagruppe.GRUPPETYPEID}
                      resultagruppe={selectedResultagruppe.NAVN}
                      owned={owned}
                    ></Matvaregruppe>
                  )}
                </div>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Grid>
      </Grid>
    </PageContainer>
  );
};
