import React, { useContext, useState } from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  Grid,
  Stack
} from '@mui/material';
import { DatabaseContext } from '../../../../context/DatabaseContext';
import { ProgressBar } from '../../../utilities/ProgressBar';
import useStylesTable from '../../../../css/TableCss';
import useStyles from '../../../../css/materialtheme';
import {
  Matvare,
  MatvaregruppeI,
  MatvaregruppeParam,
  TableType
} from '../types/matvaregruppeTypes';
import PostData from '../../../utilities/postData';
import { AlertI } from '../../../../types/alertTypes';
import AlertComp from '../../../utilities/AlertComp';
import { useKeycloak } from '@react-keycloak/web';
import * as actions from '../../../../types/actions';
import moment from 'moment';
import ReactToPrint from 'react-to-print';
import DeleteMatvaregruppe from './DeleteMatvaregruppe';
import { useDispatch, useSelector } from 'react-redux';
import {
  MatvaregruppeListType,
  MatvaregruppeType,
  MatvareListType,
  MatvareType
} from '../../../../types/reduxMatvaregruppeTypes';
import { MatvaregruppeTableContainer } from './MatvaregruppeTableContainer';
import MoveMatvaregruppe from './MoveMatvaregruppe';
import { CustomTab, TabPanel } from '../../../utilities/TabPanel';
import { NButton } from '../../components/Inputs';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
const MGComponentToPrint = React.forwardRef((props: any, ref: any) => (
  <div ref={ref}>
    <h3>Utskrift av skjermbildet</h3>
    <Grid container spacing={8}>
      <Grid item xs={6}>
        <label>
          Utskriftsdato:
          {moment().format('YYYY-MM-DD hh:mm:ss')}
        </label>
      </Grid>
      <Grid item xs={6}>
        <label>Matvaredatabase: {props.database}</label>
      </Grid>
    </Grid>
    <Grid container spacing={8}>
      <Grid item xs={6}>
        <label>Resultatgruppe: {props.resultagruppe}</label>
      </Grid>
      <Grid item xs={6}>
        <label>Matvaregruppene i {props.groupName} </label>
      </Grid>
    </Grid>
    <Table sx={props.classesTable.body}>
      <TableHead sx={props.classesTable.head}>
        <TableRow>
          <TableCell sx={props.classesTable.head}>Navn</TableCell>
          <TableCell sx={props.classesTable.head}>Kortnavn</TableCell>
          <TableCell sx={props.classesTable.head}>Engelsk navn</TableCell>
        </TableRow>
      </TableHead>
      <TableBody sx={props.classesTable.body}>
        {props.matvaregruppe?.map((row: MatvaregruppeI) => {
          return (
            <TableRow hover role="checkbox" key={row?.NODENRID}>
              <TableCell>{row?.NAVN}</TableCell>
              <TableCell>{row?.KORTNAVN}</TableCell>
              <TableCell>{row?.NAVN_ENGELSK}</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  </div>
));
const MatComponentToPrint = React.forwardRef((props: any, ref: any) => (
  <div ref={ref}>
    <h3>Utskrift av skjermbildet</h3>
    <Grid container spacing={8}>
      <Grid item xs={6}>
        <label>
          Utskriftsdato:
          {moment().format('YYYY-MM-DD hh:mm:ss')}
        </label>
      </Grid>
      <Grid item xs={6}>
        <label>Matvaredatabase: {props.database}</label>
      </Grid>
    </Grid>
    <Grid container spacing={8}>
      <Grid item xs={6}>
        Resultatgruppe: {props.resultagruppe}
      </Grid>
      <Grid item xs={6}>
        <label> Matvarer i {props.groupName} </label>
      </Grid>
    </Grid>
    <Table sx={props.classesTable.body}>
      <TableHead sx={props.classesTable.head}>
        <TableRow>
          <TableCell sx={props.classesTable.head}>Matvarekode</TableCell>
          <TableCell sx={props.classesTable.head}>Navn</TableCell>
        </TableRow>
      </TableHead>
      <TableBody sx={props.classesTable.body}>
        {props.matvarer?.map((row: Matvare) => {
          return (
            <TableRow hover role="checkbox" key={row?.MATVAREKODEID}>
              <TableCell>{row?.MATVAREKODEID}</TableCell>
              <TableCell>{row?.NAVN}</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  </div>
));

interface NavigateLevelI {
  nodeId: number;
  name: string;
  n: number;
}
interface MatvaregruppeProps {
  gruppetypeId: number;
  resultagruppe: string;
  owned: boolean;
}
export const Matvaregruppe = (props: MatvaregruppeProps) => {
  const { gruppetypeId, resultagruppe, owned } = props;
  const { currentDatabase } = useContext(DatabaseContext);
  const { keycloak } = useKeycloak();
  const token = keycloak?.token;
  const componentRef = React.useRef();
  const dispatch = useDispatch();
  const [selectedRow, setSelectedRow] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = React.useState('');
  const [alertSeverity, setAlertSeverity] = React.useState<AlertI['alertColor']>('info');
  const [move, setMove] = useState(false);
  const [progressText, setProgressText] = React.useState('Loading...');
  const [showProgress, setShowProgress] = useState(false);
  const [showMatvare, setShowMatvare] = useState(false);
  const [parent, setParent] = useState('');
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [nivaa, setNivaa] = useState(1);
  const [linkup, setLinkup] = useState(0);
  const [orderNr, setOrderNr] = useState(0);
  const [nodeId] = useState(0);
  const [parentsList, setParentsList] = useState<NavigateLevelI[]>([
    { nodeId: 0, name: 'Hovedgrupper', n: 1 }
  ]);
  const classesTable = useStylesTable();
  const classes = useStyles();
  const [newGroup, setNewGroup] = useState<MatvaregruppeParam>();
  const [matvaregruppeList, setMatvaregruppeList] = useState<MatvaregruppeI[]>([]);
  const [matvarerList, setMatvarerList] = useState<Matvare[]>([]);
  const [reloadMatvaregruppe, setReloadMatvaregruppe] = useState(false);
  const [tabValue, setTabValue] = React.useState(0);
  const matvareState = useSelector((state: MatvareType) => state['matIGruppe']);
  const matvaregruppeState = useSelector(
    (state: MatvaregruppeType) => state['matvaregruppe']
  );
  const showAlertComponent = (message: string, severity: any) => {
    setShowAlert(true);
    setAlertMessage(message);
    setAlertSeverity(severity);
  };
  const setVariablesAndReload = (nivaa: number, linkup: number) => {
    setNivaa(nivaa);
    setLinkup(linkup);
    setReloadMatvaregruppe(true);
    setSelectedRow(null);
  };
  const saveMatvaregruppe = async () => {
    setShowProgress(true);
    let data: MatvaregruppeParam[] = [];
    let updatedItems: MatvaregruppeParam;
    for (const item of matvaregruppeList) {
      updatedItems = {
        NODENRID: item.NODENRID,
        ORDNR: item.ORDNR,
        LINKUP: item.LINKUP,
        NIVAA: item.NIVAA,
        NAVN: item.NAVN || '',
        KORTNAVN: item.KORTNAVN || '',
        NAVN_ENGELSK: item.NAVN_ENGELSK || ''
      };
      data.push(updatedItems);
    }
    if (newGroup?.NAVN !== '') {
      const newData = {
        NODENRID: nodeId,
        ORDNR: orderNr + 1,
        LINKUP: linkup,
        NIVAA: nivaa,
        NAVN: newGroup?.NAVN || '',
        KORTNAVN: newGroup?.KORTNAVN || '',
        NAVN_ENGELSK: newGroup?.NAVN_ENGELSK || ''
      };
      data.push(newData);
    }

    const dataurl = `${currentDatabase?.value}/${gruppetypeId}`;
    PostData(data, 'saveMatvaregruppe', dataurl, 'POST', token).then((resp: any) => {
      if (resp.status === 200) {
        cleanMatvaregruppeFromReduxStore(linkup, 0);
        setReloadMatvaregruppe(true);
        showAlertComponent(`Lagringen er fullført`, 'success');
      } else if (resp.status === 500) {
        console.log('An error 500', resp);
        const msg =
          resp.error ||
          'Lagringen mislyktes. Det har ikke blitt gjort endringer mot databasen.';
        showAlertComponent(msg, 'error');
        setShowProgress(false);
      }
    });
    // Clear the new line.
    setNewGroup({
      NODENRID: 0,
      ORDNR: 0,
      NAVN: '',
      KORTNAVN: '',
      NAVN_ENGELSK: '',
      LINKUP: 0,
      NIVAA: 0
    });
  };

  const cleanMatvareFromReduxStore = (nodeIdFrom: number, nodeIdTo: number) => {
    const listFromMatReduxStore: MatvareListType = matvareState;
    const currentMatList = listFromMatReduxStore?.matvareList?.map(
      (item: MatvareType) => {
        if (
          item?.gruppetypeId === gruppetypeId &&
          (item?.linkup === nodeIdFrom || item?.linkup === nodeIdTo)
        ) {
          return { ...item, matvarer: [] };
        } else {
          return item;
        }
      }
    );
    dispatch({ type: actions.SET_MATVARELIST, matvareList: currentMatList });
  };
  const cleanMatvaregruppeFromReduxStore = (nodeIdFrom: number, nodeIdTo: number) => {
    const listFromMGReduxStore: MatvaregruppeListType = matvaregruppeState;
    const currentMGList = listFromMGReduxStore?.matvaregruppeList?.map(
      (item: MatvaregruppeType) => {
        if (
          item?.gruppetypeId === gruppetypeId &&
          (item?.linkup === nodeIdFrom || item?.linkup === nodeIdTo)
        ) {
          return { ...item, matvaregrupper: [] };
        } else {
          return item;
        }
      }
    );
    dispatch({ type: actions.SET_MATVAREGRUPPELIST, matvaregruppeList: currentMGList });
  };

  const handleDeleteConfirmation = () => {
    if (selectedRow) {
      setShowDeleteDialog(true);
    }
  };
  const handleCloseDeleteMatvaregruppe = () => {
    setShowDeleteDialog(false);
  };
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
    newValue === 0 ? setMove(false) : setMove(true);
  };
  return (
    <>
      {showAlert && (
        <AlertComp
          alertMessage={alertMessage}
          alertColor={alertSeverity}
          showAlert={showAlert}
          setShowAlert={setShowAlert}
        />
      )}
      {showProgress && (
        <ProgressBar
          text="Loading...."
          isShowing={showProgress}
          hide={() => {
            setShowProgress(!showProgress);
          }}
        />
      )}
      {showDeleteDialog && (
        <DeleteMatvaregruppe
          token={token}
          database={currentDatabase?.value}
          gruppeTypeId={gruppetypeId}
          selectedMatvaregruppe={selectedRow}
          setVariablesAndReload={setVariablesAndReload}
          open={showDeleteDialog}
          close={handleCloseDeleteMatvaregruppe}
          showAlertComponent={showAlertComponent}
          cleanMatvaregruppeFromReduxStore={cleanMatvaregruppeFromReduxStore}
        />
      )}
      <CustomTab
        tabValue={tabValue}
        label1={'Vis matvaregruppe'}
        label2={'Flytt matvare'}
        handleChange={handleChange}
        disabled={!owned}
      />
      <TabPanel value={tabValue} index={0}>
        <Grid container>
          <Grid item xs={12}>
            <Stack direction="row-reverse" spacing={2}>
              <ReactToPrint
                trigger={() => (
                  <NButton
                    children="Skriv ut"
                    rightIcon={<PrintOutlinedIcon />}
                    variant="text"
                  />
                )}
                pageStyle=".styledTable{width:100%;border-collapse:collapse;text-align:left}.styledTable th,.styledTable td{border:2px solid black;padding:0.5em;}"
                content={() => componentRef.current}
              />
              <Box sx={{ display: 'none' }}>
                {showMatvare ? (
                  <MatComponentToPrint
                    matvarer={matvarerList}
                    database={currentDatabase?.value}
                    groupName={parent}
                    resultagruppe={resultagruppe}
                    classesTable={classesTable}
                    ref={componentRef}
                  />
                ) : (
                  <MGComponentToPrint
                    matvaregruppe={matvaregruppeList}
                    database={currentDatabase?.value}
                    groupName={parent}
                    resultagruppe={resultagruppe}
                    classesTable={classesTable}
                    ref={componentRef}
                  />
                )}
              </Box>
              <NButton
                children="Slett"
                rightIcon={<DeleteOutlineOutlinedIcon />}
                variant={'text'}
                disabled={!(owned && selectedRow)}
                onClick={handleDeleteConfirmation}
              />
            </Stack>
          </Grid>
        </Grid>
        <MatvaregruppeTableContainer
          databaseId={currentDatabase?.value}
          gruppetypeId={gruppetypeId}
          owned={owned}
          selectedRow={selectedRow}
          setSelectedRow={setSelectedRow}
          nivaa={nivaa}
          linkup={linkup}
          setOrderNr={setOrderNr}
          setNivaa={setNivaa}
          setLinkup={setLinkup}
          parent={parent}
          setParent={setParent}
          parentsList={parentsList}
          setParentsList={setParentsList}
          matvaregruppeList={matvaregruppeList}
          setMatvaregruppeList={setMatvaregruppeList}
          matvarerList={matvarerList}
          setMatvarerList={setMatvarerList}
          move={false}
          main={true}
          tableType={TableType.Show}
          newGroup={newGroup}
          setNewGroup={setNewGroup}
          setShowProgress={setShowProgress}
          reloadMatvaregruppe={reloadMatvaregruppe}
          setReloadMatvaregruppe={setReloadMatvaregruppe}
          showMatvare={showMatvare}
          setShowMatvare={setShowMatvare}
          setProgressText={setProgressText}
        ></MatvaregruppeTableContainer>
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <MoveMatvaregruppe
          token={token}
          databaseId={currentDatabase?.value}
          gruppetypeId={gruppetypeId}
          owned={owned}
          showAlertComponent={showAlertComponent}
          setShowProgress={setShowProgress}
          setProgressText={setProgressText}
          cleanMatvareFromReduxStore={cleanMatvareFromReduxStore}
          cleanMatvaregruppeFromReduxStore={cleanMatvaregruppeFromReduxStore}
        ></MoveMatvaregruppe>
      </TabPanel>
      {!move ? (
        <NButton
          children="Lagre"
          onClick={saveMatvaregruppe}
          color={owned ? 'primary' : undefined}
          disabled={!owned}
        />
      ) : undefined}
    </>
  );
};
