import React from 'react';
import XLSX from 'xlsx';
import { KorrekturfilI } from '../../RapportTypes';
import { FormControlLabel, Radio, RadioGroup, Grid } from '@mui/material';
import { NButton } from '../../../components/Inputs';

import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { useUserContext } from '../../../../../context/AuthContext';

const deepFlat = (a: KorrekturfilI) => {
  const A = [];
  if (a.personer.length > 0) {
    a.personer.forEach((p) => {
      if (p.lopedager.length > 0) {
        p.lopedager.forEach((l) => {
          if (l.maaltider.length > 0) {
            l.maaltider.forEach((m) => {
              if (m.matvarer.length > 0) {
                m.matvarer.forEach((ma) => {
                  A.push({
                    databasenavn: a.databasenavn,
                    prosjekt: a.prosjekt.navn,
                    prosjektId: a.prosjekt.id,
                    prosjektkortnavn: a.prosjekt.kortnavn,
                    undersokelse: a.undersokelse.navn,
                    undersokelseId: a.undersokelse.id,
                    undersokelsekortnavn: a.undersokelse.kortnavn,
                    personId: p.id,
                    personIdentitet: p.identitet,
                    alder: p.alder,
                    vekt: p.vekt,
                    hoyde: p.hoyde,
                    lopedagId: l.lopedagid,
                    ukedag: l.ukedag,
                    vanligDag: l.vanligdag ? 'Ja' : 'Nei',
                    maaltidId: m.id,
                    klokkeslett: m.klokkeslett,
                    maaltid: m.navn,
                    spisested: m.spisested,
                    matvarekode: ma.kode,
                    matvare: ma.navn,
                    mengde: ma.mengde,
                    mengdeenhet: ma.mengdeenhet,
                    matvareinn: ma.matvareinn,
                    matvareut: ma.matvareut,
                    ordnrid: ma.ordnrid
                  });
                });
              } else
                A.push({
                  databasenavn: a.databasenavn,
                  prosjekt: a.prosjekt.navn,
                  prosjektId: a.prosjekt.id,
                  prosjektkortnavn: a.prosjekt.kortnavn,
                  undersokelse: a.undersokelse.navn,
                  undersokelseId: a.undersokelse.id,
                  undersokelsekortnavn: a.undersokelse.kortnavn,
                  personId: p.id,
                  personIdentitet: p.identitet,
                  alder: p.alder,
                  vekt: p.vekt,
                  hoyde: p.hoyde,
                  lopedagId: l.lopedagid,
                  ukedag: l.ukedag,
                  vanligDag: l.vanligdag ? 'Ja' : 'Nei',
                  maaltidId: m.id,
                  klokkeslett: m.klokkeslett,
                  maaltid: m.navn,
                  spisested: m.spisested,
                  matvarekode: undefined,
                  matvare: undefined,
                  mengde: undefined,
                  mengdeenhet: undefined,
                  matvareinn: undefined,
                  matvareut: undefined,
                  ordnrid: undefined
                });
            });
          } else
            A.push({
              databasenavn: a.databasenavn,
              prosjekt: a.prosjekt.navn,
              prosjektId: a.prosjekt.id,
              prosjektkortnavn: a.prosjekt.kortnavn,
              undersokelse: a.undersokelse.navn,
              undersokelseId: a.undersokelse.id,
              undersokelsekortnavn: a.undersokelse.kortnavn,
              personId: p.id,
              personIdentitet: p.identitet,
              alder: p.alder,
              vekt: p.vekt,
              hoyde: p.hoyde,
              lopedagId: l.lopedagid,
              ukedag: l.ukedag,
              vanligDag: l.vanligdag ? 'Ja' : 'Nei',
              maaltidId: undefined,
              klokkeslett: undefined,
              maaltid: undefined,
              spisested: undefined,
              matvarekode: undefined,
              matvare: undefined,
              mengde: undefined,
              mengdeenhet: undefined,
              matvareinn: undefined,
              matvareut: undefined,
              ordnrid: undefined
            });
        });
      } else
        A.push({
          databasenavn: a.databasenavn,
          prosjekt: a.prosjekt.navn,
          prosjektId: a.prosjekt.id,
          prosjektkortnavn: a.prosjekt.kortnavn,
          undersokelse: a.undersokelse.navn,
          undersokelseId: a.undersokelse.id,
          undersokelsekortnavn: a.undersokelse.kortnavn,
          personId: p.id,
          personIdentitet: p.identitet,
          alder: p.alder,
          vekt: p.vekt,
          hoyde: p.hoyde,
          lopedagId: undefined,
          ukedag: undefined,
          vanligDag: undefined,
          maaltidId: undefined,
          klokkeslett: undefined,
          maaltid: undefined,
          spisested: undefined,
          matvarekode: undefined,
          matvare: undefined,
          mengde: undefined,
          mengdeenhet: undefined,
          matvareinn: undefined,
          matvareut: undefined,
          ordnrid: undefined
        });
    });
  } else
    A.push({
      databasenavn: a.databasenavn,
      prosjekt: a.prosjekt.navn,
      prosjektId: a.prosjekt.id,
      prosjektkortnavn: a.prosjekt.kortnavn,
      undersokelse: a.undersokelse.navn,
      undersokelseId: a.undersokelse.id,
      undersokelsekortnavn: a.undersokelse.kortnavn,
      personId: undefined,
      personIdentitet: undefined,
      alder: undefined,
      vekt: undefined,
      hoyde: undefined,
      lopedagId: undefined,
      ukedag: undefined,
      vanligDag: undefined,
      maaltidId: undefined,
      klokkeslett: undefined,
      maaltid: undefined,
      spisested: undefined,
      matvarekode: undefined,
      matvare: undefined,
      mengde: undefined,
      mengdeenhet: undefined,
      matvareinn: undefined,
      matvareut: undefined,
      ordnrid: undefined
    });
  return A;
};

const ExcelReport = ({ data }: { data: KorrekturfilI }) => {
  const [exportModus, setExportModus] = React.useState<'FLAT' | 'GROUPED'>('FLAT');
  const flatten = (d: KorrekturfilI) => {
    const flattendata = deepFlat(d);
    return flattendata;
  };

  const exportKorreturFilTilExcelUtflatet = (user: string, info: KorrekturfilI) => {
    const data = flatten(info);

    const worksheet = XLSX.utils.json_to_sheet(data);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'SheetJS');
    /* generate XLSX file and send to client */
    XLSX.writeFile(workbook, user + '-flattened.xlsx');
  };

  const exportKorrekturfilToExcelGruppert = (user: string, info: KorrekturfilI) => {
    const worksheet = XLSX.utils.aoa_to_sheet([['DATABASENAVN:', info.databasenavn]]);

    XLSX.utils.sheet_add_aoa(worksheet, [[undefined, ...Object.keys(info.prosjekt)]], {
      origin: -1
    });

    XLSX.utils.sheet_add_aoa(
      worksheet,
      [['PROSJEKT: ' as any, ...Object.values(info.prosjekt)]],
      { origin: -1 }
    );
    XLSX.utils.sheet_add_aoa(
      worksheet,
      [['UNDERSØKELSE', ...Object.values(info.undersokelse)]],
      { origin: -1 }
    );
    info.personer.forEach((p) => {
      const { lopedager, ...filtrert } = p;
      XLSX.utils.sheet_add_aoa(
        worksheet,
        [['PERSON: ', ...Object.keys(filtrert).map((k) => k.toUpperCase())]],
        {
          origin: -1
        }
      );
      XLSX.utils.sheet_add_aoa(worksheet, [[, ...Object.values(filtrert)]], {
        origin: -1
      });
      p.lopedager.forEach((l) => {
        const { maaltider, ...filtrert } = l;
        XLSX.utils.sheet_add_aoa(
          worksheet,
          [[, 'LØPEDAG: ', ...Object.keys(filtrert).map((k) => k.toUpperCase())]],
          {
            origin: -1
          }
        );
        XLSX.utils.sheet_add_aoa(worksheet, [[, , ...Object.values(filtrert)]], {
          origin: -1
        });
        l.maaltider.forEach((m) => {
          const { matvarer, ...filtrert } = m;
          XLSX.utils.sheet_add_aoa(
            worksheet,
            [[, , , 'MÅLTIDER:', ...Object.keys(filtrert).map((k) => k.toUpperCase())]],
            {
              origin: -1
            }
          );
          XLSX.utils.sheet_add_aoa(worksheet, [[, , , , ...Object.values(filtrert)]], {
            origin: -1
          });
          XLSX.utils.sheet_add_aoa(
            worksheet,
            [
              [
                ,
                ,
                ,
                ,
                'MATVARER: ',
                ...Object.keys(m.matvarer[0]).map((k) => k.toUpperCase())
              ]
            ],
            {
              origin: -1
            }
          );

          m.matvarer.forEach((ma) => {
            XLSX.utils.sheet_add_aoa(worksheet, [[, , , , , ...Object.values(ma)]], {
              origin: -1
            });
          });
        });
      });
    });

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'SheetJS');
    /* generate XLSX file and send to client */
    XLSX.writeFile(workbook, user + '-grouped.xlsx');
  };
  const loggedInuser = useUserContext();
  const username = loggedInuser?.user?.username.toLowerCase();

  return (
    <>
      <Grid item xs={4}>
        <NButton
          leftIcon={<FileDownloadOutlinedIcon />}
          children="Last ned xlsx"
          onClick={() =>
            exportModus === 'GROUPED'
              ? exportKorrekturfilToExcelGruppert(username, data)
              : exportKorreturFilTilExcelUtflatet(username, data)
          }
          fullWidth
        />
      </Grid>
      <Grid item xs={4}>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={exportModus}
          onChange={(e) => setExportModus(e.target.value as 'FLAT' | 'GROUPED')}
          row
        >
          <FormControlLabel value="FLAT" control={<Radio />} label="Utflatet" />
          <FormControlLabel value="GROUPED" control={<Radio />} label="Gruppert" />
        </RadioGroup>
      </Grid>
    </>
  );
};

export default ExcelReport;
